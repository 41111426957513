//var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');
const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

export function updateLocalPreferences(givenPreferences) {
    if (!givenPreferences) return;
    try {
      let gPref = JSON.parse(givenPreferences);
      for (let g in gPref) {
        if (typeof gPref[g] == 'object') {
          for (let p in gPref[g]) {
            try {
              if (g == 'p2p') {
                if (p == 'allowInvitations') this.player.invitable = gPref[g][p];
                if (p == 'visible') this.player.visible = gPref[g][p];  
                if (p == 'watchable') this.player.watchable = gPref[g][p];  
                if (p == 'allowedGameTypes') this.player.allowedGameTypes = gPref[g][p];
                if (p == 'allowVisitors') this.player.watchable = gPref[g][p];
              }  
            }
            catch(error) {
              console.log(error);
            }

            this.preferences[g][p] = gPref[g][p];
          }
        }
        else {
          this.preferences[g] = gPref[g];
        }
      }
      if (this.preferences.ui.locale!='')
        this.$i18n.locale=this.preferences.ui.locale;
    }
    catch (error) {
      //console.log
      }
  }

  export function savePreferences() {
    //console.log("savePreferences");
    if (!this.player.authToken) return;
    let postData = {
      playerID: this.player.id,
      authToken: this.player.authToken,
      preferences: this.preferences
      }
    this.axios.post(
      `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/set/preferences/`,
      postData).then(() => {
      //console.log(response.data);            
    }).catch(console.error);        
  }

  export function playerUpdateError(error,type) {
    this.uIstate.userMenu.error = { error:error, type:type };
    if (error) {
      setTimeout(()=>{this.uIstate.userMenu.error= { error:false, type:0 };},2000);
    }

  }

  export function updatePlayerFlags(data) {
    let postData = {
      playerID: this.player.id,
      authToken: this.player.authToken,
      playerData: data
    }
    this.axios.post(
      `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/set/data`,
      postData).then((response,error) => {
        if (error) {
          this.playerUpdateError(true,1);
          console.log(error);
          return;
        }});
  }

  export function updatePlayer(newPlayerData) {
    //console.log("save",newPlayerData);
    let emailChanged = (this.player.email != newPlayerData.email || newPlayerData.resend ) ? true: false;
    let playerType = !newPlayerData.email || newPlayerData.email.length<3 ? 'Guest' : 'kGuest';
    //console.log("updatePlayer");
    if (!this.player.authToken) return;
    let postData = {
      playerID: this.player.id,
      authToken: this.player.authToken,
      emailChanged:emailChanged,
      playerData: { 
        name: newPlayerData.name, 
        image: newPlayerData.image,
        email: newPlayerData.email,
        type: playerType }
      }
    this.axios.post(
      `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/set/data`,
      postData).then((response,error) => {
        if (error) {
          this.playerUpdateError(true,1);
          console.log(error);
          return;
        }
        if  (response.data.status == 0) {
          this.playerUpdateError(true,2);
          console.log('error2');
          return;
        }
        if  (response.data.status == 2) {
          this.playerUpdateError(true,2);
          console.log('used email');
          return;
        }
        if (emailChanged) this.player.validated = 0;
        this.player.name = newPlayerData.name;
        this.player.image = newPlayerData.image;
        this.player.email = newPlayerData.email;
        this.uIstate.userMenu.show=false;
        this.playerUpdateError(false,0);
        this.zone = "lobby";

        let emailSendPost = { 
          email: this.player.email, 
          player_id: this.player.id,
          authToken: this.player.authToken,
        };
        if(emailChanged && this.player.email) this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/sendurl`,emailSendPost);
        //console.log(response.data);            
      }).catch(console.error);        
  }
