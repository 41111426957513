export function x(position,direction) {
    const xPos = position[0];
    let result = 0;
    if (position[0]==26 || position[0]==27) {
        result = 7;
    }
    else
        if (xPos<13)
            if (xPos<=6)
                result = 14-xPos;
            else 
                result = 13-xPos;
        else
            if (xPos>18)
                result = xPos-11;
            else
                result = xPos-12;
    if (direction==1)
        return result;
    else return 14-result;
}

export function y(position,playerID) {
    const xPos = position[0];
    const yPos = position[1];
    if ((xPos==27 && playerID==1) || (xPos==26 && playerID==0) ) return yPos;
    if ((xPos==26 && playerID==1) || (xPos==27 && playerID==0) ) return 13-yPos;
    if ((xPos==25 && playerID==1) || (xPos==0 && playerID==0) ) return yPos/3+0.33;
    if ((xPos==0 && playerID==1) || (xPos==25 && playerID==0) ) return (13-yPos/3)-0.66;
    if ((xPos <= 12 && playerID==0) || (xPos > 12 && playerID==1))
        return yPos;
    else 
        return 13-yPos;
}      

export function targetY(column,playerID) {
    let nY = 0;
    if (column===null) nY = 12;
    else {
        if ((column<=12 && playerID==0)||column>12 && playerID==1) {
            nY = 7.4;
        }
        else {
            nY = 18;
        }
    }     
    return nY;
}


export function path(p1,p2) {
    let altoJ = 1;
    let alto = altoJ*Math.abs(p1.x-p2.x)/3;

    let nx = this.x([p2.x,p2.y]);
    let ny = this.y([p2.x,p2.y]);                
    let ox = this.x([p1.x,p1.y]);
    let oy = this.y([p1.x,p1.y]);   
    //console.log(ox,oy,nx,ny);
    let cnx = (nx*this.colWidth)*this.XunitSize;
    let cny = (this.maxTop-(ny)*this.colWidth*1.5)*this.YunitSize;
    let cox = (ox*this.colWidth)*this.XunitSize;
    let coy = (this.maxTop-(oy)*this.colWidth*1.5)*this.YunitSize;

    let direccionCurva = 1; 
    //let separacionColumna = 1;               
    let str1 = `M ${cox} ${coy} 
                C ${cox} ${coy+direccionCurva*alto*this.YunitSize}  `;
    let str2 = `${cnx} ${cny+direccionCurva*alto*this.YunitSize} 
                ${cnx} ${cny} `;
    return `${str1 + str2}`
    
}