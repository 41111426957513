const { IArespondDouble } = require('./responseDouble');

const atemptDouble = function(G,ctx) {  
    if (parseInt(ctx.playerID) === G.doubleCube.holder || G.doubleCube.holder === null ) {
        G.timers.turnDoubledAt = Date.now();
        /* Push Log */
        let turnTime = G.timers.turnDoubledAt - G.timers.turnStartedAt;
        G.turnslog.log.push(
          {
            playerID: ctx.currentPlayer, 
            diceRoll:[0,0],
            maxMovements:0,
            doubleCube:JSON.parse(JSON.stringify(G.doubleCube)),            
            type:'double_attempt',            
            turnTime: parseInt(turnTime/1000)
          });
        /* IF CPU HAS TO ACCEPT */
        if (G.opponent.type == "CPU" && ctx.currentPlayer=="0") {                          
          
          
          let response = IArespondDouble(G,ctx,1);
          //console.log(response);
          //let probWin = response[2].split(":")[1];
          let Equity = response.aEq;
          
          if (Equity > -0.25 || G.opponent.level == 3) {
            G.doubleCube.value= parseInt(G.doubleCube.value)*2;
            let otherPlayer = (parseInt(ctx.currentPlayer)+1)%2;
            G.doubleCube.holder=otherPlayer;                              
            ctx.events.setActivePlayers(
              {
                currentPlayer: 'rollorDouble',
              });  

            G.timers.turnDoubleRespondedAt = Date.now();           

            G.turnslog.log.push(
              {
                playerID: "1", 
                diceRoll:[0,0],
                maxMovements:0,
                doubleCube:G.doubleCube,
                type:'double_accepted',            
                turnTime: 0
              });                 
          }
          else {
            G.turnslog.log.push(
              {
                playerID: "1", 
                diceRoll:[0,0],
                maxMovements:0,
                type:'double_rejected',   
                doubleCube:G.doubleCube,         
                turnTime: 0
              });            
            G.points.current[ctx.currentPlayer]+=G.doubleCube.value;
            G.points.games_record.push({
              'turns': G.turnslog.log.length,
              'winner':ctx.currentPlayer,
              'points':1,
              'doubleCube':G.doubleCube,
              'reason':'declinedDouble'});
            ctx.events.setPhase('endedGame');
          }
        }
        /* IF PLAYER HAS TO ACCEPT */
        else {
          ctx.events.setActivePlayers(
            {
              currentPlayer: 'doublingWait',
              others: 'respondToDouble'
            });  
        }
    }
}

module.exports = { atemptDouble }