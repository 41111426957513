<template>
    <div class="share-game-result-modal" v-if="show">
      <div class="modal-background"></div>
      <div class="modal-content">

        <div class="header" @click="closeModal"> 
            <div>
                <h3>{{$t('lobby.action.shareboard')}}</h3>
            </div>
            <div class="close_button">✖</div>
        </div>
        <div v-if="!shareLink">
            <div class="preshare"> 
                <div><p>{{$t('lobby.action.howtoshare')}}</p></div>
                <div><textarea rows="4" cols="30" v-model="description_user"></textarea></div>
                <div><p></p></div>
                <div>
                    <div class="button" @click="shareGameResult">Preview</div>
                </div>
            </div>
        </div>
        <div v-if="shareLink && shareImage">
          <div class="frame">
            <div> 
                <div class="share_preview">
                    <div> {{ description_user }}</div>
                    <img :src="shareImage" />
                </div>
            </div>
            <div> 
                <div class="button_title">{{$t('lobby.action.sharein')}}</div>
                <div class="social-media-buttons">
                    <a :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`" target="_blank" class="social-media-button facebook-button">Facebook</a>
                    <a :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareLink)}&text=Check%20out%20my%20game%20result!`" target="_blank" class="social-media-button twitter-button">Twitter</a>
                    <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareLink)}`" target="_blank" class="social-media-button linkedin-button">LinkedIn</a>
                </div>
                <div class="button_title">{{$t('lobby.action.cpypasteshare')}}</div>
                <div class="link_holder"> 
                    <div>
                        <div v-if="share_message">{{share_message}}</div>
                        <a v-else :href="shareLink" target="_blank">{{ shareLink }}</a>
                    </div>
                    <div @click="copyToClipboard()"><img :src="require('@/assets/img/buttons/copy.svg')"></div>
                </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  

  import axios from 'axios';
  const { APIPORT, APIURL, WEBPROTOCOL } = require('../../assets/js/enviroment.js');

  export default {
    name: 'ShareGameResult',
    props: {
      matchID: {
         type: String,
         required: true,
      },
      checkers_map: {
        type: Object,
        required: true,
      },
      show: {
        type: Boolean,
        default: false,
      },
      points: {
        type: Object,
        required: true,
      },
      roll: {
        type: Object,
        required: true,
      },
      cube: {
        type: Object,
        required: true,
      },
      players: {
        type: Object,
        required: true,
      },                        
      player_id: {
        type: Number,
        required: true,
      },                        
    },
    data() {
      return {
        shareLink: '',
        shareImage: '',
        description_user: '',
        share_message:'',
      };
    },
    emits: ['close'],
    methods: {
      async shareGameResult() {
        console.log(this.convertCheckersMap());
        try {

            let data = {
                table_map:this.convertCheckersMap(),
                player_id:this.player_id,
                cube: { owner: this.cube.holder , value:this.cube.value  },
                roll: this.roll,
                description:this.description_user,
                match: {
                    players: this.players, 
                    id : this.matchID, 
                    goal: this.points.goal, 
                    points: this.points.current
                }                
            }
            const response = await axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/game/share/`, data);

            if (response.status === 200 && response.data.code) {
                this.shareImage = `${WEBPROTOCOL}${APIURL}:${APIPORT}/share/img/${response.data.code}.png`;
                setTimeout( () => {
                    this.shareLink = `${WEBPROTOCOL}${APIURL}:${APIPORT}/share/${response.data.code}.html`;
                    //this.shareLink ="https://play.abak.me";
                    }, 500);
            } else {
            console.error('Error sharing game result:', response);
            }
        } catch (error) {
            console.error('Error sharing game result:', error);
        }
      },
      closeModal() {
        this.shareImage = "";
        this.shareLink = "";
        this.$emit('close-share');
      },
      convertCheckersMap() {
        //[x,y,color,class]
        let converted = [];
        for (let c in this.checkers_map) {
            let ck = this.checkers_map[c]
            converted.push([ck.column,ck.height,ck.owner+1,ck.cclass]);
        }
        return(converted);
      },
      copyToClipboard() {
        var copyText = this.shareLink;        
        navigator.clipboard.writeText(copyText);
        this.share_message="Copied";
        setTimeout(() => this.share_message="",500);
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .share-game-result-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background: $g5;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    position: relative;
    margin:2rem;
    z-index: 10000;
  }

  .share_preview {
    img {
        max-width: 100%;
        height: auto;
    }
  }

  .social-media-buttons {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    display: flex;
    background-color:$c1;
    padding:0.4em;
    border-radius: 0.3em;
    }

.social-media-button {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 0.3em;
  font-size: 1.5em;
}

.facebook-button {
  background-color: #3b5998;
}

.twitter-button {
  background-color: #1da1f2;
}

.linkedin-button {
  background-color: #0077b5;
}

.frame {
    display:flex;
    align-items: center;
    >div {
        width:100%;
    }
}
.link_holder {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 0.5rem;
    background-color:$c0;
    align-items: center;
    padding: 0.2em;
    border-radius: 0.5em;
    div {
        margin: 0 1em;
    }

    a {
        color:aliceblue;
    }

    img {
        height: 1.5em;
    }
}

.button {
    background:$c9;   
    width: 10em; 
    border-radius: 1em;  
    height: 2em;  
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background:$c8;   
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        margin:0;
    }
}

.close_button {
    background-color: $c0;
    padding:0.5em;
    border-radius: 1em;
}

.preshare {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button_title {
    margin-top: 0.5em;
    font-size: 1.2em;
}

@media(orientation: landscape )  {
    .social-media-buttons {
        flex-direction: column;
        a {
            margin:0.2em;
        }
    }

    .frame>div:nth-child(2) {
        margin-left: 1em;
    }
}

@media(orientation: portrait )  {
    .frame {
        flex-wrap: wrap;
    }
}


  </style>
  