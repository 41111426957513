<template>
    <div v-if="analyseGame.on">
        <div class="frameContainer" v-if="!stateMinimal"> </div>
        
            <div class="container" :class="{containerMinimal : stateMinimal }">
            
            <!--- LOADING ------------------------------->
            <div v-if="analyseGame.loading" class="loadingView">
                <div v-if="!analyseGame.enabled" style="display:flex;flex-direction: column;align-items: center;max-width: 15em;font-size:1.5em;"> 
                    {{t('ui.game.message.analysysnotavailable')}}
                    <GameButton 
                        :type="'none'"
                        :button_id = "'AnalysisClose'"
                        :text="t('ui.game.action.close')"
                        :image="'close'"
                        @click="$emit('close-game-analysis');stateMinimal=false;"
                    />
                </div>
                <div v-else> 
                    <div style="font-size:1.5em;">{{t('ui.game.messages.analysisgame')}}</div>
                    <div>{{t('ui.game.messages.analysisgamewait')}}.</div>
                    <div> 

                        <div>{{turnsLog.length}} Turns</div>
                        <div>{{Math.min(100*timePassed/timeExpected,100).toFixed(0)}}%</div>
                        <div>ETA</div> 
                        <div>{{Math.max(timeExpected - timePassed,0).toFixed(0)}} s </div> 
                    </div>                
                    <div>
                        <div class="progress">
                            <div class="color"></div>
                        </div>
                    </div>
                </div>                
            </div>      

            <!--- CONTENT AFET LOAD ------------------------------->

            <div v-else class="graphContainer" :class="{graphContainerMinimal : stateMinimal}" >

            
                <div v-if="!stateMinimal" @click="$emit('close-game-analysis');stateMinimal=false;" style="width:100%;text-align:center;padding-top:0.5em;"> 
                    {{ t('ui.game.subject.gameevolution') }} 
                    ( {{ dotValue == 'equity' ? t('ui.game.subject.equity') : t('ui.game.subject.winner') }} )
                </div>
            

            <!-- Filters Begin -->

                    <div class="filtersMenu" v-if="!stateMinimal">  
                        <div style="width:80%">
                                <DoubleRange 
                                    :min="limits[0]" 
                                    :max="limits[1]" 
                                    :minThreshold="0"
                                    :maxThreshold="analyseGame.data.length"
                                    @update:min="value => updateLimits(0,value)" 
                                    @update:max="value => updateLimits(1,value)"
                                />
                        </div>
                            <div>

                                <GameButton                                 
                                    :type="'none'"
                                    :button_id = "'switchDotValue'"
                                    :text="''"
                                    :image="'graphswitchvalue'"
                                    @click="dotValue = dotValue=='winner' ? 'equity': 'winner'"
                                />

                                <GameButton 
                                    v-for="(dp,i) in displayPlayer" :key=i
                                    :type="'none'"
                                    :button_id ="'TurnWatchButton'+i"
                                    :image="'checker_col'+playerColor(i)"
                                    :prefValue="dp"
                                    @click="currentPoint=null;displayPlayer[i]=!displayPlayer[i];if (i==1) showOpponentEquityRange=false;"
                                />
                                
                                <GameButton 
                                    :type="'none'"
                                    :button_id ="'TurnEqOpponent'"
                                    :image="'visibility'"
                                    :prefValue="showOpponentEquityRange"
                                    @click="currentPoint=null;showOpponentEquityRange=!showOpponentEquityRange;displayPlayer[1]=true;"
                                />   
                                <GameButton                                 
                                    :type="'none'"
                                    :key="'back11'"
                                    id = "back11"
                                    :button_id = "'StateBack1'"
                                    :text="''"
                                    :image="'backward'"
                                    @click="clickCurrentPoint(Math.max(currentPoint-1,0))"
            
                                />
                                <GameButton                                 
                                        :type="'none'"
                                        :key="'back12'"
                                        :button_id = "'StateForwad1'"
                                        :text="''"
                                        :image="'forward'"
                                        @click="clickCurrentPoint(currentPoint+1,limits[1]-limits[0])"
                                />                     
                            </div>

                    </div>

            <!-- Filters End -->
            
            <div class="mainContainer">  
                <!-- Graph Begin -->
                <svg 
                    class="graphSVG" 
                    :class="{
                        graphSVGMinimal : stateMinimal ,
                        graphTablet: screenMode == 'tablet',
                        graphVertical: screenMode == 'vertical'
                        }" 
                    xmlns="http://www.w3.org/2000/svg" fill="white"
                    >                   
                    <rect x="0" y1=0 width="100%" height="100%" class="graphBackground" ></rect>
                    
                    
                    <!-- Selected Turn -->
                        <line v-if="currentPoint"
                            :x1="graphData.left+graphData.r[currentPoint][0]*graphData.ptXd+'%'" 
                            :y1="'0%'"  
                            :x2="graphData.left+graphData.r[currentPoint][0]*graphData.ptXd+'%'" 
                            :y2="'100%'" 
                            style="stroke:#00770040;stroke-width:5" />


                    <!-- Horizontal Axis -->
                    <line v-for="i in [25,50,75]" :key="i"
                        :x1="'0%'" 
                        :y1="i+'%'"  
                        :x2="'100%'" 
                        :y2="i+'%'" 
                        style="stroke:rgb(80,0,0);stroke-width:1" />                    
                    
                    <!-- Horizontal Axis On 0 -->
                    <line 
                        :x1="'0%'" 
                        :y1="graphData.top+((graphData.maxEq)*graphData.ptYd)+'%'"  
                        :x2="'100%'" 
                        :y2="graphData.top+((graphData.maxEq)*graphData.ptYd)+'%'"  
                        style="stroke:rgb(0,80,0);stroke-width:1" />                           
                    
                    <!-- Vertical Axis -->
                    <line v-for="i in [25,50,75]" :key="i"
                        :x1="i+'%'" 
                        :y1="'0%'"  
                        :x2="i+'%'" 
                        :y2="'100%'" 
                        style="stroke:rgb(100,30,0);stroke-width:1" />   

                    <!-- Line -->
                    <template v-for="(d,index) in graphData.r"  >
                        <line v-if="index>0" :key="d"
                            :x1="graphData.left+graphData.r[index-1][0]*graphData.ptXd+'%'" 
                            :y1="graphData.top+(graphData.maxEq-graphData.r[index-1][1])*graphData.ptYd+'%'"  
                            :x2="graphData.left+d[0]*graphData.ptXd+'%'" 
                            :y2="graphData.top+((graphData.maxEq-d[1])*graphData.ptYd)+'%'" 
                            style="stroke:rgb(200,220,60);stroke-width:2" />
                    </template>

                    <!-- Equity Range Lines -->
                    <template v-for="(d,index) in graphData.r" >
                        <line v-if="index>0" :key="d"
                            :x1="graphData.left+d[0]*graphData.ptXd+'%'" 
                            :y1="graphData.top+(graphData.maxEq-d[2])*graphData.ptYd+'%'"  
                            :x2="graphData.left+d[0]*graphData.ptXd+'%'" 
                            :y2="graphData.top+((graphData.maxEq-d[3])*graphData.ptYd)+'%'" 
                            style="stroke-width:1%;"
                            :class="{
                                bigError: d[2]-d[1] > 0.1,
                                errorMedium: d[2]-d[1] > 0.05 && d[2]-d[1] <= 0.1,
                                errorModerate: d[2]-d[1] > 0.00 && d[2]-d[1] <= 0.05,
                                errorPerfect: d[2]== d[1]
                            }"/>
                    </template>                    

                    <!-- Double Lines -->
                    <template v-for="(d,index) in graphData.doubles" >
                        <line v-if="index>0"  :key="d"
                            :x1="graphData.left+d[0]*graphData.ptXd+'%'" 
                            :y1="'0%'"  
                            :x2="graphData.left+d[0]*graphData.ptXd+'%'" 
                            :y2="'100%'" 
                            style="stroke:#45FFFF50;stroke-width:1" />
                    </template>                    



                    <!-- Dots -->

                    <circle v-for="(d,i) in graphData.r" :key="d" 
                        :cx="graphData.left+d[0]*graphData.ptXd+'%'" 
                        :cy="graphData.top+((graphData.maxEq-d[1])*graphData.ptYd)+'%'" :r="dotSize()" 
                        :style="'fill:'+dotColor(d[7])+';stroke:'+dotStroke(d[5])+';stroke-width:1px'"
                        @click="clickCurrentPoint(i)"
                        />

                    <!-- Texts -->
                    <template v-for="(d,i) in graphData.r" >                                            
                        <text v-if="i%10==0 && i<graphData.r.length-10"
                            :x="graphData.left+d[0]*graphData.ptXd+'%'" 
                            :y="'98%'"                            
                            @click="currentPoint=i"
                            style="font-size:0.5em"
                            :key="d"
                        >{{d[4]}}</text>
                    </template>
                    <text v-for="i in [25,50,75]" :key="i" x="5%" :y="(i+1)+'%'" style="font-size:0.5em"> 
                        {{(graphData.maxEq-graphData.diff*(i/100)  ).toFixed(1)}} </text>
                </svg>

            <div> 
                
                <div v-if="currentPoint && !stateMinimal && false">
                    
                    <div class="firstContainer"> 
                        <div>#{{graphData.r[currentPoint][4]}}</div>                
                        <div style="display:flex;">
                            <img style="width:2em;margin:0"
                                            :src="require(`@/assets/img/dados/d${playerColor(graphData.r[currentPoint][7])+1}${graphData.r[currentPoint][6][0]}.svg`)"
                            >    
                            <img style="width:2em;margin:0"
                                            :src="require(`@/assets/img/dados/d${playerColor(graphData.r[currentPoint][7])+1}${graphData.r[currentPoint][6][1]}.svg`)"
                            >    
                        </div>
                        <div> 
                            {{graphData.r[currentPoint][5]}}
                        </div>
                        <div style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
                            <ShowEquityError 
                                :values="
                                    [
                                    graphData.r[currentPoint][3],
                                    graphData.r[currentPoint][1],
                                    graphData.r[currentPoint][2]
                                    ]"
                                :type="'numeric'"
                            />
                        </div>
                    </div>


                </div>                
            </div>

            <!-- Graph End -->
            </div>

            <!-- Minimal UI Begin -->

            <div v-if="!analyseGame.loading && stateMinimal " style="display:flex;flex-direction:column;align-items:center;justify-content:center;">  
                <div>
                    <div style="display:flex;">

                    <GameButton                                 
                        :type="'none'"
                        :button_id = "'stateMinimalOff'"
                        :text="''"
                        :image="'fullscreen'"
                        @click="stateMinimal=!stateMinimal"
                    />
                    <GameButton 
                        :type="'none'"
                        :button_id = "'AnalysisClose'"
                        :text="''"
                        :image="'close'"
                        @click="$emit('close-game-analysis');stateMinimal=false;"
                    />
                    </div>
                    <div style="display:flex;">
                    <GameButton                                 
                            :type="'none'"
                            :button_id = "'StateBack2'"
                            :text="''"
                            :image="'slide_backward'"
                            :imageSize="'0.5em'"
                            @click="clickCurrentPoint(Math.max(currentPoint-1,0))"

                        />
                    <GameButton                                 
                            :type="'none'"
                            :button_id = "'StateForward2'"
                            :text="''"
                            :image="'slide_forward'"
                            :imageSize="'0.5em'"
                            @click="clickCurrentPoint(currentPoint+1,limits[1]-limits[0])"
                        />
                    </div>
                </div>  
                <div v-if="currentPoint">
                    <div style="display:flex;">
                        <img style="width:1em;margin:0"
                                        :src="require(`@/assets/img/dados/d${playerColor(graphData.r[currentPoint][7])+1}${graphData.r[currentPoint][6][0]}.svg`)"
                        >    
                        <img style="width:1em;margin:0"
                                        :src="require(`@/assets/img/dados/d${playerColor(graphData.r[currentPoint][7])+1}${graphData.r[currentPoint][6][1]}.svg`)"
                        >    
                    </div>                        
                </div>                     
            </div>

            <!-- Minimal UI End -->

        </div>

            <!-- Normal UI Begin -->

            <div style="margin-top:1em;">
                <div v-if="!analyseGame.loading && !stateMinimal" style="display:flex;justify-content:space-around;">
                <GameButton 
                    :type="'softwarn'"
                    :button_id = "'StateMinimalOn'"
                    :text="''"
                    :image="'fullscreen'"
                    @click="stateMinimal=!stateMinimal"
                />          
                <GameButton 
                    :type="'softwarn'"
                    :button_id = "'showHelp'"
                    :text="''"
                    :image="'help'"
                    @click="showHelp=!showHelp"
                />

                <GameButton 
                    :type="'softwarn'"
                    :button_id = "'AnalysisClose'"
                    :text="''"
                    :image="'close'"
                    @click="$emit('close-game-analysis');stateMinimal=false;"
                    style="align-self:flex-end;"
                />
                </div>
                <div class="Movebox" v-if="!analyseGame.loading && !stateMinimal && !showHelp ">
                    <div class="boardsBox" v-if="currentPoint">
                        
                        <div class="moveMenu">
                            <div 
                                @click="moveSelected=0"
                                :class="{ moveNotSelected : moveSelected==1 }"
                                >Yours
                            </div>
                            <div 
                                @click="moveSelected=1"
                                :class="{ moveNotSelected : moveSelected==0 }"
                                >Best
                            </div>

                            <!--- Dice Set --->

                            <div class="diceset">

                                <div>#{{graphData.r[currentPoint][4]}}</div>                
                                <div style="display:flex;">
                                    <img style="width:2em;margin:0"
                                                    :src="require(`@/assets/img/dados/d${playerColor(graphData.r[currentPoint][7])+1}${graphData.r[currentPoint][6][0]}.svg`)"
                                    >    
                                    <img style="width:2em;margin:0"
                                                    :src="require(`@/assets/img/dados/d${playerColor(graphData.r[currentPoint][7])+1}${graphData.r[currentPoint][6][1]}.svg`)"
                                    >    
                                </div>


                            </div>

                        </div>
                        
                        <div class="movesBox">

                            <div v-if="moveSelected==0||graphData.r[currentPoint][2]==graphData.r[currentPoint][1]"> 

                                <div class="boardTitleMine">

                                    <div> {{graphData.r[currentPoint][5]}} </div>
                                    <div>{{graphData.r[currentPoint][1].toFixed(2)}}</div>
                                    <div>{{graphData.r[currentPoint][2].toFixed(2)}}</div>
                                    <div>{{graphData.r[currentPoint][3].toFixed(2)}}</div>
                                    <div>{{(graphData.r[currentPoint][1]-graphData.r[currentPoint][2]).toFixed(2)}}</div>
                                </div>
                                <AbakMiniBoard                     
                                    :checkers_map="turnsLog[graphData.r[currentPoint][4]].checkers_map"
                                    :checkers_map_initial="currentMoveMap"
                                    :boardImageSize="boardImageSize*boardImageSizeCorrection"
                                    :playerID="parseInt(playerID)"
                                    :checkersColor="0"
                                    :objectId = "'tplayer'"
                                    :moves ="normalizeMoves(turnsLog[graphData.r[currentPoint][4]].moves)"
                                />
                            </div>

                            <div v-if="graphData.r[currentPoint][2]!=graphData.r[currentPoint][1]&&moveSelected==1"> 
                                <div class="boardTitleBest">
                                    <div>{{graphData.r[currentPoint][2].toFixed(2)}}</div>
                                </div>
                                <AbakMiniBoard                     
                                    :checkers_map="bestMoveMap"
                                    :checkers_map_initial="currentMoveMap"
                                    :boardImageSize="boardImageSize*boardImageSizeCorrection"
                                    :playerID="parseInt(playerID)"
                                    :checkersColor="0"
                                    :objectId = "'tbest'"
                                    :moves = bestMove
                                />
                            </div>
                            <ShowEquityError 
                                style="width:10em;"
                                :values="
                                    [
                                    graphData.r[currentPoint][3],
                                    graphData.r[currentPoint][1],
                                    graphData.r[currentPoint][2]
                                    ]"
                                :type="'graphic'"
                            />




                        </div>
                    </div>                

                    <div v-else>
                        <div class="seeMore">
                            {{t('ui.game.messages.clickturndetails')}}
                        </div>
                    </div>                         
                    <div class="legendBox" v-if="!currentPoint"> 
                        <div> {{t('ui.game.tag.legend')}} </div>
                        <div> <div> <div>&nbsp;</div><div>&nbsp;</div>  </div> <div> {{t('ui.game.tag.playersmove')}} </div> </div>
                        <div> <div>&nbsp;</div> <div> {{t('ui.game.tag.novalidmoves')}} </div> </div>
                        <div> <div>&nbsp;</div> <div> {{t('ui.game.tag.forcedmove')}} </div> </div>
                        <div> <div><div>|</div></div><div> {{t('ui.game.tag.gamedoubled')}} </div> </div>
                        <div> <div>--</div><div> {{t('ui.game.tag.tieline')}} </div> </div>
                    </div>

                </div>
                <div v-if="(showHelp||analyseGame.loading)&&!stateMinimal">
                    <AnalysisHelp 
                        :type="'board'" 
                        @close-analysis-help="showHelp=false;" 
                        :fontsize="'1.1em;'"
                    />
                </div>
            </div>

        </div>

        <!-- Normal UI End -->

    </div>
</template>

<script>
    import DoubleRange from "../elements/DoubleRange.vue";
    import AnalysisHelp from "../cajas/AnalysisHelp.vue"
    import ShowEquityError from "../elements/ShowEquityError.vue";
    import GameButton from "../buttons/GameButton.vue";
    import AbakMiniBoard from "../AbakMiniBoard.vue";
    var abakLib = require('../../assets/js/AbakEvolutionLibrary.js');

    import { useI18n } from 'vue-i18n'

	export default {
		name: 'Chart',

        components: {DoubleRange,ShowEquityError,GameButton,AbakMiniBoard,AnalysisHelp},
        props: ['title','analyseGame','playerID','showStatus','checkersColor',
                'iturnsLog','boardImageSize','loadingData','screenMode','gameType','classesSet'],
        emits: ['close-game-analysis','click-turn'],
        data: function() {
            return {
                currentPoint: null,
                onlyShowOwnMoves: true,
                dotValue: 'equity',
                analysisStart: 0,  
                timePassed: 0,
                timeExpected: 0,
                limits: [0,0],
                sliderMin:0,
                sliderMax:100,
                displayPlayer: [true,false],
                stateMinimal:false,
                showHelp: false,
                bestMoveMap: {},
                bestMove : [],
                currentMoveMap: {},      
                showOpponentEquityRange: false,          
                boardImageSizeCorrection: 0.3,
                moveSelected: 0,
            }
        },
        computed: {     
            turnsLog: function() {
                console.log("Calculate Chart TurnsLog");
                return abakLib.expandLog(this.iturnsLog,this.gameType,this.classesSet);
            },
            graphData: function() {
                let r = [[0,0,0,0]];
                let maxEq = -3;
                let minEq = 3;
                let doubles = [[0,1,null]];                
                if (this.analyseGame.loading || !this.analyseGame.on || !this.analyseGame.data ) {
                    return r;
                }
                else {
                    let index = 0;
                    for (let d in this.analyseGame.data) {
                        if (
                            ( this.limits[0]==null || d>this.limits[0] ) && 
                            ( this.limits[1]==null || d<this.limits[1] )
                            ) {
                            let data = this.analyseGame.data[d];
                            if (data.type == "move" &&
                                 (data.playerID==0 && this.displayPlayer[0]) || 
                                 (data.playerID==1 && this.displayPlayer[1]) 
                                 )  {
                                let mult = data.playerID==parseInt(this.playerID) ? 1 : -1;
                                let p = data.turnEq;
                                let s = data.limits;
                                //console.log("eqmine",d,p.eq,s.mine);

                                
                                let smint = 0 , smaxt = 0;

                                let dotMaxVal = this.dotValue == 'equity' ? s.maxe : s.maxw - 0.5;
                                let dotMinVal = this.dotValue == 'equity' ? s.mine : s.minw - 0.5;

                                let dotVal = this.dotValue == 'equity' ? p.eq : p.w - 0.5;

                                if (!this.showOpponentEquityRange) {
                                    smint = mult<0 ? dotVal*mult : dotMinVal*mult;
                                    smaxt = mult>0 ? dotMaxVal*mult : dotVal*mult;
                                }
                                else {
                                    smint = dotMinVal*mult;
                                    smaxt = dotMaxVal*mult;
                                }
                                minEq = Math.min(minEq,dotVal*mult,smint);
                                maxEq = Math.max(maxEq,dotVal*mult,smaxt);
                                r.push([
                                    index+1,
                                    dotVal*mult,
                                    smaxt,
                                    smint,
                                    d,
                                    data.validmoves,
                                    data.diceRoll,
                                    data.playerID,
                                    data.bestMove]);
                                index++;
                            }
                            if (data.type=="double_accepted") {
                                //console.log("double");
                                doubles.push([index+1,doubles[doubles.length-1][1]*2,null]);
                            }
                        }
                    }
                }
                return { 
                    maxEq: maxEq, 
                    minEq:minEq, 
                    r:r ,
                    ptXd:99/r.length ,  
                    ptYd: 90/(maxEq-minEq),
                    top: 5/(maxEq-minEq),
                    diff: (maxEq-minEq),
                    left: 100/r.length,
                    doubles:doubles
                };
            }
            
        },
        methods: {	
            goToSelectedTurn: function(closeView) {
                let turnID = parseInt(this.graphData.r[this.currentPoint][4]);
                this.$emit("click-turn",{turnID:turnID, close: closeView });
            },
            clickCurrentPoint(i) {
                this.currentPoint=i;
                if (this.stateMinimal) this.goToSelectedTurn(false);
            },
            playerColor: function(playerID) {
                return this.checkersColor == 0 ? parseInt(playerID) : (parseInt(playerID)+1)%2; 
            },
            dotColor: function(playerID) {
                let colors = ["#d8ba89ff","#663300ff"];
                return colors[this.playerColor(playerID)];
            },
            dotStroke: function(maxLength) {
                if (maxLength==0) return "#FF0000";
                if (maxLength==1) return "#FFFF00";
            },
            dotSize: function() {
                return this.stateMinimal ? '1%' : '1%';
            },
            actualizaPorcentaje: function() {
                if (this.analyseGame.loading) {
                    console.log("updateGA",this.analysisStart,this.analyseGame.requestComplexity);
                    this.timePassed = ((Date.now() - this.analysisStart)/1000).toFixed(1);
                    this.timeExpected = (this.analyseGame.requestComplexity * 0.05).toFixed();
                    setTimeout(()=> this.actualizaPorcentaje(),500);
                }
            },
            updateLimits(index,value ) {
                this.currentPoint=null;                
                this.limits[index] = value;
            },
            normalizeMoves: function(moves) {
                let rmoves = [];
                for (let m in moves) {
                    rmoves.push([moves[m].startColumn,moves[m].startHeight,moves[m].endColumn,moves[m].dice]);
                }
                //console.log(moves);
                return rmoves;
            }
        },
        watch: {
            showStatus: function() {
                if (this.analyseGame.loading) {
                    this.analysisStart = Date.now();
                    this.actualizaPorcentaje();
                }
            },
            analyseGame: {
                handler: function(newValue) {
                    if (this.showStatus && newValue.loading==false && this.analyseGame.data ) {
                        this.limits[0]=0;
                        this.limits[1]=this.analyseGame.data.length;
                    }
                },
                deep: true
            },
            currentPoint: function() {                
                if (!this.currentPoint) {
                    this.currentMoveMap = {};
                    this.bestMoveMap = {};
                    this.bestMove = [];
                    return;
                }
                let currentTurnId = this.graphData.r[this.currentPoint][4];
                //let currentTurnId = this.graphData.r[this.currentPoint][7];
                //let currentTurnId = this.graphData.r[this.currentPoint][4];
                let idPrevMove = currentTurnId-1;
                //console.log(idPrevMove,currentTurnId);

                while (idPrevMove>1) {
                    if (this.turnsLog[idPrevMove].type=="move" || this.turnsLog[idPrevMove].type=="roll_start_starts" ) {
                        this.currentMoveMap = this.turnsLog[idPrevMove].checkers_map;
                        let moves = this.graphData.r[this.currentPoint][8];
                        let playerID = this.graphData.r[this.currentPoint][7];
                        this.bestMove = moves;
                        this.bestMoveMap = abakLib.processMoves(this.currentMoveMap,moves,playerID);
                        return;
                    }                    
                    idPrevMove--;                    
                }
                this.currentMoveMap = {};
                this.bestMoveMap = {};     
                this.bestMove = [];
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },         
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .frameContainer {
        position:absolute;
        width: 100%;
        height:100%;
        background:#00000070;
        left:0;
        top:0;
    }

    .container { 
        position:absolute;
        width:95%;
        height:95%;
        background-color:$c14;
        border-radius: 0.5em;
        left:2.5%;
        top:2.5%;
        display:flex;
        justify-content: space-around;
        /*align-items: stretch;*/
        box-shadow: $box-shadow-normal;
    }

    .containerMinimal {
        height:20%;
        top:40%;
        width: 50%;
        left:20%;
        justify-content: center;
        background:none;
        box-shadow: none;  
    }

    .chart {

    }

    .Movebox {
        display: flex;      
        flex-direction: column;
        align-content: center;
        justify-content: center;
        background:$c16;
        padding:0.2em;
        box-sizing: border-box;
        border-radius: 0.5em;
        margin: 0.5em 0;
        box-shadow: $box-shadow-minimal;

    }

    .firstContainer {
        display:flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;

        >div:nth-child(1) {
            background: $c5;
            border-radius: 0.3em;
            padding: 0 0.3em;
        }
    }

    .mainContainer {         
        flex-grow:1;   
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .graphContainer {
        width: 65%;
        background:$c4;
        border-radius: 0.5em;
        margin: 0.5em 0;
        box-shadow: $box-shadow-minimal;
    }

    .graphContainerMinimal {
        display: flex;
        justify-content: space-around;  
        width:100%;      
    }



    .graphSVG {
        height:12em;
        width:90%;
        border-radius:0.5em;
        box-shadow: $box-shadow-minimal;
    }

    .graphVertical {
        height:15em;
        margin-bottom: 1em;
    }

    .graphTablet {
        height:20em;
        margin-bottom: 1em;
    }

    .graphSVGMinimal {
        height:85%;
        width:90%;

    }

    .graphBackground {
        fill:#252525;
    }

    .filtersMenu {
        display: flex;
        font-size: 2em;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom:0.5em;

        >div:nth-child(2) {
            display: flex;
            font-size: 0.8em;
            margin-left:1em;
            div {           
                border-radius: 0.5em;     
                width:1em;
                text-align: center;
                margin:0.1em;
                background:$c7;
                padding: 0.1em;
            }
        }

    }

    .legendBox {

        font-size: 0.6em;
        margin:1em;
        background:$c8;        
        padding:0.5em;
        border-radius: 0.5em;

        >div {            
            display:flex;            
            justify-content: space-between;   
            align-items: center;         

            div {
                display:flex;

                div {
                    width: 1em;
                    height:1em;
                    border-radius: 50%;
                    border:0.1em solid #ffffff00;               
                }

                div:nth-child(1) {
                    width: 1em;
                    height:1em;
                    border:0.1em solid #ffffff00;               
                    border-radius: 50%;
                    background:$checkerwhite-background;
                }

                div:nth-child(2) {
                    background:$checkerblack-background;
                    margin-left: 0.2em; 
                }
            }

        }

        >div:nth-child(n+3) {
            margin-top:0.3em;            
        }

        >div:nth-child(n+3) >div:nth-child(1) { 
            display: flex;
            width: 1em;
            height:1em;
            border-radius: 50%;            
            border:0.1em solid #ffffff00;                           
        }

        >div:nth-child(1) {
            background:$c6;
            text-align: center;
            width: 100%;
            margin:-0.5em -0.5em 0.5em -0.5em;
            padding:0.5em;
            text-align: center;
            display: block;
            border-radius: 0.5em 0.5em 0 0;

        }

        

        >div:nth-child(3) div:nth-child(1) { border:0.1em solid red; }
        >div:nth-child(4) div:nth-child(1) { border:0.1em solid yellow; }
        >div:nth-child(5) div:nth-child(1) { background: #ffffff00; color:lightblue; }
        >div:nth-child(6) div:nth-child(1) { background: #ffffff00; color:green; }


    }

    .seeMore {
        text-align:center;
        background:$c3;
        color:$c0;
        font-size:0.8em;        
        border-radius: 0.5em;
        padding:0.5em;        
        margin-top:5%;
        width:80%;
        margin-left:5%;
    }
    

    .progress {
        height: 2em;
        width: 100%;
        border-radius: 0.5em;
        margin-top:1em;   
        position:relative;
    }

    .progress .color{
        position: absolute;
        background-color: #ffffff;
        width: 0px;
        height: 0.5em;
        border-radius: 15px;
        animation: progres 10s infinite linear;    
    }

    @keyframes progres{
        0%{
            width: 0%;
        }
        25%{
            width: 25%;
        }
        50%{
            width: 50%;
        }
        75%{
            width: 75%;
        }
        100%{
            width: 100%;
        }
    };    

    .loadingView {
        text-align:center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        >div:nth-child(3) {
            display:flex;
            justify-content: space-around;
        }
    }

    .boardsBox {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-around;
        margin:0.5 0.5em;
        font-size:0.7em;
        >div {
            display:flex;
            flex-direction: column;
            align-items: center;
            width:100%;
            >div {
                margin:0.2em;
            }
        }
    }

    .errorPerfect {
        background: green;
        stroke-width: 1%;
        stroke:#00FF0088;
    } 
    .errorModerate {
        background: greenyellow;
        color:black;        
        stroke-width: 1%;
        stroke:#667700AA;
    }
    .errorMedium {
        background: orange;
        color:black;
        stroke-width: 1%;
        stroke:#FF8800AA;

    }
    .bigError {
        background:#FF0000;
        stroke-width: 1%;
        stroke:#FF0000AA;
    }

    .boardTitleMine {
        display:flex;        
        >div {
            margin:0.2em;
            border-radius: 0.3em;
            padding: 0.2em 0.5em;
            background:$c5;
        }

    }
    .boardTitleBest {
        display:flex;        
        >div {
            margin:0.2em;
            border-radius: 0.3em;
            padding: 0.2em 0.5em;
            background:$c5;
        }

    }


    .moveMenu {
        width:100%;
        flex-direction: row !important;
        justify-content: space-around;
        align-content: flex-end;
        >div {
            padding:0.5em 1em;
            border-radius: 0.5em 0.5em 0 0;
            background-color:$c4;
            margin-bottom:0 !important;
            font-size:1.3em;
        }
        .diceset {            
            background-color:#FFFFFF00;
            display:flex;    
            align-content: center;  
            justify-content: space-around; 
            padding: 0;
            >div:nth-child(1) {
                display: flex;
                height: 1.3em;
                padding:0.2em;
                border-radius: 0.5em;
                background-color: $c9;
                color:$c0;
            }  
            >div:nth-child(2) {
                font-size: 0.7em;
                margin:0.2em;
                >div {
                    margin:0.2em;
                }
            }         
        }
    }

    .movesBox {
        background-color: $c4;
    }

    .moveNotSelected {
        background-color: $c5 !important;
    }

    @media (orientation:portrait) {

        .container {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size:1.4em;
            width: 100%;
            left:0%;    
        }

        .graphContainer {
            width:95%;        
        }


    }


</style>
