<template>
    <div class="gameDetailsContainer">
        <div style="text-align:center;" class="gameDetails"> {{title}}</div>
        <div class="gameDetails" style="text-align: center;">
            <div><div>#</div></div>
            <div><div>$</div></div>
            <div><div>S</div></div>
            <div><div>T</div></div>
            <div><div>R</div></div>
        </div>
        <div v-for="(p,i) in points.games_record.slice().reverse()" :key="p" class="gameDetails">
            <div style="display:flex;align-items:center;">
                <div>{{numberOfGames-i}}</div>
                <img :src="players[p.winner].image">
            </div>                    
            <div style="display:flex;align-items:center;">
                <div>{{p.points}}</div>
                <span>x</span>
                <img :src="doubleCubeSrc(p.doubleCube)">
            </div>
            <div class="pointsCurrent">
                <div class="colorWhite">{{addedPoints(i)[0]}}</div>
                <div class="colorBlack">{{addedPoints(i)[1]}}</div>
            </div>
            <div style="display:flex;align-items: center;">
                <div>
                    {{p.turns}}
                </div> 
            </div>
            <img :src="reasonSrc(p.reason)">
        </div>
    </div>   
</template>

<script>
	export default {
		name: 'MatchLog',
        props: ['title','points','players'],
        data: function() {
            return {   
            }
        },
        computed: {
            numberOfGames: function() {
                return this.points.games_record.length;
            },
        },
        emits: [],
        methods: {	
            doubleCubeSrc: function(doubleCube) {
                if (doubleCube.holder===null) {
                    return require("@/assets/img/dados/dd00.svg")
                }
                else {
                    return require(`@/assets/img/dados/dd${doubleCube.value}.svg`);
                }
            },
            reasonSrc: function(reason) {
                return require(`@/assets/img/actions/${reason}.svg`);
            },
            addedPoints: function(index) {                
                //console.log(index);
                let score = [0,0];                
                var gd = JSON.parse(JSON.stringify(this.points.games_record)).reverse();                
                for (let i=gd.length-1;i>=index;i--) {                    
                    //console.log(index,"adding",gd[i].doubleCube.value,gd[i].points);
                    score[gd[i].winner] = score[gd[i].winner] + (gd[i].points * gd[i].doubleCube.value)
                } 
                return score;
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .pointsCurrent {
        display:flex;
        align-items:center;
        div {            
            display:flex;
            border-radius: 0.2em;
            padding:0em 0.2em;
            margin: 0em 0.2em;
        }
    }

    .gameDetailsContainer img {
        width:1.5em;
        margin:0.2em;
    }

    .gameDetails {
        display:flex;
        justify-content: space-around;
        border-bottom: 0.1em solid white;
        margin-bottom:0.1em;
    }

    .gameDetails > div {
        margin:0 0.5em;
    }

    .gameDetailsContainer {
        font-size:1em;
        max-height: 8em;
        overflow: auto;
        flex-grow: 0.3;
    }

    .colorWhite {
            background:$checkerwhite-background;
            color:$checkerwhite-color;
        }

        .colorBlack {
            background:$checkerblack-background;
            color:$checkerblack-color;
        }
    
</style>
