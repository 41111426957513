
const resetTurn = function(G) {  
    G.turnslog.currentMoves = [];
    G.diceRoll = [0,0];
    G.diceAvailable = [0,0,0,0];
    G.generalDirection = 0;
    G.maxMovements = null;
    G.currentMovements = 0;       
}

module.exports = { resetTurn };