import { createApp } from 'vue'
import App from './App.vue'
import vueNextAnimejs from '@maybecode/vue-next-animejs';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueCookieNext } from 'vue-cookie-next'
import i18n from './i18n'
import router from './router'
//import VueSocketIO from 'vue-3-socket.io'
//import SocketIO from 'socket.io-client'
import VueGtag from "vue-gtag-next";
import CountryFlag from 'vue-country-flag-next'

//import { GA4TRACK } from "../../enviroment.js"
import {GA4TRACK,GA4ID,APIURL,APIPORT,WEBPROTOCOL} from '../../enviroment.js';

//import './registerServiceWorker'

let gTagConfig = { 
  isEnabled: GA4TRACK, 
  property: {
    id: GA4ID,
  }
}

VueCookieNext.config({ expire: '3y' });
axios.defaults.withCredentials = true;

const app =createApp(App);

app.mixin({
  methods: {
    sendLog: function (type,section,content) {
      axios.post(WEBPROTOCOL+APIURL+":"+APIPORT+"/error/log", { type:type, section:section, content:content.toString(), timestamp:new Date() } );
    },
  },
})

app.use(router);
app.use(i18n);
app.use(vueNextAnimejs);
app.use(VueAxios, axios);
app.use(VueCookieNext);
app.use(VueGtag, gTagConfig);
app.use(CountryFlag);
app.mount('#app');
