<template>
    <div @click="animateClick()"
        :key="button_id" :id="button_id" 
        class="game-button"
        :class="{ 
            default: type=='default' || type=='normal' ,
            light: type=='light',
            softwarn: type=='softwarn',
            white: type=='white',
            warning: type=='warning',
            nonenegative: type=='none-negative',
            none: type=='none',
            valueOff: prefValue!=null && prefValue===false

            }"
        >
        <div style="display:flex;align-items:center;">
            <div v-if="text && text!=''"
                style="margin-right:1em;margin-left:0.5em;"
                >{{ text }}</div>
            
            <img alt="button" v-if="!imageValue" 
                :src="require(`@/assets/img/buttons/${image}.svg`)"
                :style ="{ width: calcImageSize , height: calcImageSize }"                               
            />
            <img alt="button" v-if="imageValue" 
                :src="require(`@/assets/${imageValue}${prefValue}.svg`)"
                :style ="{ width: calcImageSize , height: calcImageSize }"                               
            />
        </div>
    </div>
</template>

<script>
	export default {
		name: 'GameButton',
        props: [ 'button_id','text', 'image', 'imageSize' , 'type' ,'prefValue' ,'imageValue' , 'theme'],
        data: function() {
            return {
            }
        },
        computed: {
            calcClass: function() {
                if (this.type) {
                    return this.type;
                }
                return "default";
            },
            calcImageSize: function() {
                if (typeof (this.imageSize)== 'string') return this.imageSize;
                if (typeof (this.imageSize) == 'number') return this.imageSize + 'px';
                return "1em";
            },
            colors: function() {
                if (this.theme) return this.theme;
                else return {
                        default: {
                            background:'#000',
                            hover:"grey",
                            border:"none",
                            off:"yellow"
                        },
                        light: {
                            background:'#000',
                            hover:"grey",
                            border:"none"
                        },
                        soft: {
                            background:'#020',
                            hover:"grey",
                            border:"none"
                        },
                        warning: {
                            background:'#400',
                            hover:"grey",
                            border:"none"
                        }
                }
            }

        },
        methods: {	
            animateClick: function() {
                //console.log("animateclick",this.id);
                let jsonAnime = {
                    targets:`#${this.button_id}`,
                    keyframes: [
                        {scale: 0.3,invert:1},
                        {scale: 1.1,invert:0},
                        {scale: 1},
                    ],                    
                    duration:300,
                    easing: 'easeInOutSine',                                                
                };
                this.$anime(jsonAnime);  
            }
        },
        mounted: function() {
            this.$nextTick(function () {                
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
 
    .game-button {
        border-radius:1em;
        background:v-bind('colors.default.background') ;
        padding:0.3em;
        color:white;
        display:flex;
        box-shadow:$box-shadow-normal;
        cursor:pointer;
        filter:scale(1);
        user-select: none;
    }

    @media (hover: hover) {
        .game-button:hover {
            background-color:v-bind('colors.default.hover')
        }        
    }

    .default {

    }

    .light {
        background: v-bind('colors.light.background');
    }

    .softwarn {
        background: v-bind('colors.soft.background');
        border: 0.1em solid v-bind('colors.soft.border');    
        //padding:0.1em !important;
    }

    .white {
        background: $c1;
        border: 0.1em solid $c0;        
        img {
            filter:invert(1)
        }
    }
    .warning {
        background: v-bind('colors.warning.background');
        border: 0.1em solid v-bind('colors.warning.border');        
    }


    .nonenegative {
        background:none;
        filter:grayscale(1);
        box-shadow: none;
    }

    .game-button img {
        width:2em;
    }

    .none {
        background:none;
        box-shadow: none;
        filter:drop-shadow(0.01em 0.2em 0.13em black);
    }

    .click-enter-active, .click-leave-active {
         transition: opacity 0.25s ease-out;
    }

    .click-enter, .click-leave-to {
        opacity: 0;
    }


    .valueOff {
        background: v-bind('colors.default.off') !important;
    }


</style>
