
<template>
    <div class="OptionListFrame">
      <!-- Display the selected option or a placeholder -->

      <div           
        v-if="matchID && zone=='game'"
        :class="['selected-display', { 'selected': selectedOptionId === matchID }]"
        @click="selectOption(matchID)">
        <div>
          <div class="option-item-description">
            <div><img :src="require('@/assets/img/symbols/match.svg')"></div>
            <div> {{$t('lobby.subject.match')}} </div>
            <div v-if="newMessages[matchID]" class="newMessageIcon" > ! </div>
          </div>
        </div>
      </div>


      <div 
          :class="['selected-display', { 'selected': selectedOptionId === '0' }]"
          @click="selectOption('0')">
        <div>
          <div class="option-item-description">
            <div><img :src="require('@/assets/img/symbols/world.svg')"></div>
            <div> {{$t('lobby.subject.public')}} </div>
            <div v-if="newMessages['0']" class="newMessageIcon" > ! </div>
          </div>
        </div>
      </div>

      <div 
          :class="['selected-display', { 'selected': selectedOption && selectedOption!=='0' && selectOption != matchID }]"
          @click="showList = !showList">
        <div v-if="selectedOption && selectedOption!=='0' && selectOption != matchID">
          <div class="option-item-description">
            <div><img :src="selectedOption.image"></div>
            <div> {{ selectedOption.name }}</div>
            <div v-if="newMessages[selectedOption.friend_id]" class="newMessageIcon" > ! </div>
          </div>
        </div>
        <div class="option-item-description" v-else>
          <div><img :src="require('@/assets/img/symbols/friendsgroup.svg')"></div>
          <div>{{ $t('lobby.subject.friends') }}</div>
          <div v-if="newMessagesFromFriends" class="newMessageIcon" > ! </div>
        </div>
      </div>

      <!-- Options List and Cancel Button -->
      <div v-if="showList" class="listFrame">
        <!-- Search Bar -->
        <div class="searchbox"> 
            <!-- Cancel Button -->
            <div> 
              <input v-model="searchText" :placeholder="$t('lobby.subject.search')" />
              <button @click="searchText=''">🚫</button>
            </div>
            <button @click="cancelSelection">⨯</button>
        </div>
  
        <!-- Options -->
        <div> 
            <div 
            v-for="option,oKey in filteredOptions" 
            :key="'opt'+oKey" 
            :class="['option-item', { 'selected': selectedOptionId === option.friend_id.toString() }]"
            @click="selectOption(option.friend_id)"
            >
              <div class="option-item-description">
                  <div><img :src="option.image"></div>
                  <div> {{ option.name }}</div>
                  <div v-if="newMessages[option.friend_id]" class="newMessageIcon">!</div>
              </div>
            </div>
            <div v-if="filteredOptions.length==0" class="emptyMessage"> 
              {{ $t('lobby.subject.friendship.notyet') }}
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        default: () => [],
      },
      initValue:String,
      newMessages: Object,
      matchID:String,
      zone:String
    },
    data() {
      return {
        searchText: "",
        selectedOptionId: 0,
        showList: false
      };
    },
    emits: ['option-selected'],
    computed: {
      selectedOption() {
        return this.options.find(option => option.friend_id == this.selectedOptionId)
      },
      filteredOptions() {
        if (this.searchText == "") 
          return this.options;
        else {
          return this.options.filter(person => person.name.toLowerCase().includes(this.searchText.toLowerCase()));
        }
      },
      newMessagesFromFriends() {
        for (const n in this.newMessages) {
          if (n != '0' && /^\d+$/.test(n))
            return true
        }
        return false;
      }
    },
    watch: {
      initValue: function(newValue) {
        this.selectedOptionId = newValue;
      }
    },
    methods: {
      selectOption(id) {
        this.selectedOptionId = id;
        this.$emit('option-selected', id);
        this.showList = false;
      },
      cancelSelection() {
        this.showList = false;
      }
    },
    mounted: function() {     
        this.$nextTick(function () {
          if (this.initValue)
            this.selectedOptionId = this.initValue;

        });
    }
  };
  </script>
  
  <style scoped lang="scss">

  .OptionListFrame {
    display: flex;
  }
  .selected-display, .option-item {
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .option-item:hover, .selected-display:hover {
  }
  
  .newMessageIcon {
    font-size: 0.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red !important;
    font-weight: bold;
    width: 2em;
    height:2em;
    border-radius: 50%;

  }

  .selected-display {
    >div {
      display: flex;
      align-items: center;
    }
  }

  .listFrame {
    position:absolute;    
    background:$c10;
    padding:0.5em;
    border-radius: 0.5em;
    box-shadow: $box-shadow-normal;
    width:20em;
    left: -5em;
    z-index:10;
  }

  .searchbox {
    display: flex;
    justify-content: space-between;

    >div {
      display: flex;
      justify-content: space-between;
    }

    input: {
        font-size: 1em;
    }

  }

  .option-item-description {
        display:flex;
        img {
            width:1em;
        }
        border-radius: 0.5em;
        background:$c17;
        margin:0.2em;
        padding:0.3em;
        justify-content: flex-start;
        align-items: center;
        >div:first-child { 
            background:$c18;
            border-radius: 50%;
            padding:0.2em;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        >div:nth-child(3) {
          margin-left: 2em;
          font-size: 0.5em;
        }
    }      

.option-item.selected {
    >div {
        background-color: $c15 !important;
    }
  }

  .selected-display.selected {
    >div>div {
        background-color: $c15 !important;
    }
  }

  input {
    font-size:1em;
  }

  button {
    margin-left: 0.3em;
    font-size:1em;
    background-color: $c15;
    border:none;
    border-radius: 1em;
    min-width: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;    
    box-shadow: $box-shadow-normal;
  }

  .emptyMessage {
    text-align: center;
    margin-top:1em;
    color:black;
  }
  
    
  </style>
  