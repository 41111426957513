<template>


  <div style="width:100%;">
    
    <div class="container"> 
      <div class="headerLogin">
            <div>
              <img alt="abak-logo"  class="logo" 
                :style="{ width: 5*imageSize+'px' }" :src="require('@/assets/img/abaklogo.svg')">
            </div>
            <div class="introBox">
              <div style='font-size:1.5em;margin-bottom:0.5em;' v-html="t('intro.content.classbased')"></div>
              <div>
                <TeamLogoAnimated 
                  :style="{ width: 7*imageSize+'px' }" 
                />
              </div>
            </div>        
      </div>    
      
      <!-- Login Init -->
      <template v-if="localPlayer.login.auth.status=='none'"> 

        <div class="typeBoxes">
        
            <!--- Play as Guest  --->
          
            <div class="cajaTipoLogin">
              <div>{{t('ui.lobby.welcome.guest')}}</div>
              <div style="max-width:20em;">{{t('ui.lobby.welcome.guest_instructions')}}</div>
              <UIButton
                  style="margin:1em;"
                  :button_id="'ButtonAuthGuest'"
                  :key="'ButtonAuthGuest'"
                  :id="'ButtonAuthGuest'"
                  :text="t('ui.lobby.action.loginguest')" 
                  :image="'person'" 
                  :imageSize="imageSize"  
                  @click="clickGuest()"
              />
              <div class="warning">{{t('ui.lobby.welcome.changelater')}}</div>
            </div>

            <!--- Play as Email Registered  --->

            <div class="cajaTipoLogin">
              <div>{{t('ui.lobby.welcome.create')}}</div>
              <div style="max-width:20em;">{{t('ui.lobby.welcome.create_instructions')}}</div>
              <div class="emailBox"> 
                <input 
                  style="font-size:1.4em;width:80%;margin-left:10%;margin-top:2%;border-radius:0.5em;" 
                  :class= "{ inputError: !validEmail && errorMessage }"
                  id="auth_email" type="email" v-model="localPlayer.login.auth.email">
                <img v-if="!validEmail" :src="require('@/assets/img/buttons/no.svg')" />
                <img v-else :src="require('@/assets/img/buttons/si.svg')" />
              </div>
              <div v-if="!errorMessage" style="display:flex;justify-content: center;" >
                  <UIButton
                    style="margin:1em;"
                    :button_id="'ButtonAuthEmail'"
                    :key="'ButtonAuthEmail'"
                    :id="'ButtonAuthEmail'"
                    :text="t('ui.lobby.action.loginemail')" 
                    :image="'verified'" 
                    :imageSize="imageSize" 
                    @click="clickSignOn()"
                  />
              </div>
              <div v-else class="message"> 
                Malformed Email!
              </div>
            </div>
        </div>
      </template>

      <div v-if="localPlayer.login.auth.status=='attempting-auth'" 
        style="display:flex;justify-content:center;min-height:10em;
        text-align:center;flex-direction:column;align-items: center;">
        <MiniLoader />
      </div>

      <div v-if="localPlayer.login.auth.status=='attempting-email'" 
        style="display:flex;justify-content:center;min-height:10em;
        text-align:center;flex-direction:column;align-items: center;">
        <MiniLoader />
      </div>


      <div v-if="localPlayer.login.auth.status=='waiting-code'" 
        style="display:flex;justify-content:center;text-align:center;flex-direction:column;">
        <div class="codeIntro"> 
          <div style="font-size:1.5em;">{{t('ui.lobby.auth.entercode')}}</div>
          <div style="margin-bottom:1em;">{{localPlayer.login.auth.email}}</div>
        </div>
        <div style="display:flex;justify-content:center;text-align:center;margin:0.5em;">
          <div><input 
            onkeyup="if (document.querySelector('#cid_0').value.length==1) document.getElementById('cid_1').focus();" id="cid_0" style="font-size:3em;max-width:1em;text-align:center;border-radius:0.3em;" size="1" maxlength="1" v-model="localPlayer.login.auth.code[0]" type="number">
          </div>
          <div><input 
            onkeyup="if (document.querySelector('#cid_1').value.length==1) document.getElementById('cid_2').focus();" id="cid_1" style="font-size:3em;max-width:1em;text-align:center;border-radius:0.3em;" size="1" maxlength="1" v-model="localPlayer.login.auth.code[1]" type="number">
          </div>
          <div><input 
            onkeyup="if (document.querySelector('#cid_2').value.length==1) document.getElementById('cid_3').focus();" id="cid_2" style="font-size:3em;max-width:1em;text-align:center;border-radius:0.3em;" size="1" maxlength="1" v-model="localPlayer.login.auth.code[2]" type="number">
          </div>
          <div><input 
            onkeyup="if (document.querySelector('#cid_3').value.length==1) document.getElementById('cid_0').focus();" id="cid_3" style="font-size:3em;max-width:1em;text-align:center;border-radius:0.3em;" size="1" maxlength="1" v-model="localPlayer.login.auth.code[3]" type="number">
          </div>
          <div @click="localPlayer.login.auth.code=['','','','']">
            <img alt="close" :src="require(`@/assets/img/buttons/close.svg`)" />
          </div>
        </div>
        <div v-if="errorMessage">
          You must provide 4 digits.
        </div>
        <div style="display:flex;justify-content:center;margin-top:1em;">
          <UIButton
                  style="margin:0.5em;font-size:1.2em;"
                  :button_id="'ButtonValidateCAncel'"
                  :key="'ButtonValidateCAncel'"
                  :id="'ButtonValidateCAncel'"
                  :text="t('ui.lobby.auth.cancel')" 
                  :image="'no'" 
                  :imageSize="imageSize*2"  
                  @click="$emit('cancel-validate')" 
              />            
          <UIButton
                  style="margin:0.5em;font-size:1.2em;"
                  :button_id="'ButtonValidateValidate'"
                  :key="'ButtonValidateValidate'"
                  :id="'ButtonValidateValidate'"
                  :text="t('ui.lobby.auth.validate')" 
                  :image="'si'" 
                  :imageSize="imageSize*2"  
                  @click="requestValidation()" 
              />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

//import LocaleSelector from "./elements/LocaleSelector.vue";
import { useI18n } from 'vue-i18n'
import UIButton from "./buttons/UIButton.vue";
import MiniLoader from './elements/MiniLoader.vue';
import TeamLogoAnimated from './elements/TeamLogoAnimated.vue';

    //var lobbyLib = require('../assets/js/lobby/lobbyLib.js');

export default {
		name: 'UserAuth',
        components:{ UIButton, MiniLoader, TeamLogoAnimated },
        props: ['player','imageSize'],
        data: function() {
            return {         
                localPlayer: {
                    login: {
                        auth: {
                          code: ["","","",""],
                          email: "",
                          status:"none",
                          error:false,
                        },
                    }
                },
                errorMessage: false
            }
        },
        setup() {
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
            });
            return { t }
        },          
        emits: [
            'attempt-guest-auth',
            'attempt-generate-code',
            'attempt-validate',
            'cancel-validate'
            ],
        watch: {          
          'player.login.auth.status': function(newStatus) {
            this.localPlayer.login.auth.status = newStatus;
          },  
          'player.login.auth.error': function(newError) {
            this.localPlayer.login.auth.error = newError;
          }  
        },
        computed: {
          validEmail: function() {
            let validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            let email = this.localPlayer.login.auth.email;
            if (email.match(validRegex)) return true;
            return false;
          }
        },
        methods: {	
          clickSignOn: function() {
            if (this.validEmail)
              this.$emit('attempt-generate-code',{ email: this.localPlayer.login.auth.email} );
            else {
              this.errorMessage = true;
              setTimeout(()=>{this.errorMessage=false},2000);
              console.log("error");

            }
          },
          clickGuest: function() {
            this.$emit('attempt-guest-auth');
          },
          requestValidation: function() {
            let valid = true;
            for (let i in this.localPlayer.login.auth.code) {
              let code = this.localPlayer.login.auth.code[i];
              console.log(code);
              if ( (code<=0 && code>9) || parseInt(code) != code ) valid = false;
            }
            if (valid)
               this.$emit('attempt-validate', this.localPlayer.login.auth );
            else {              
              this.errorMessage = true;
              setTimeout(()=>{this.errorMessage=false},2000);
            }
          }
        },
        mounted: function() {
            this.$nextTick( () => {   
                //this.localPlayer = this.player;
                
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>  


.cajaTipoLogin {
  background-color: $c14;
  border-radius: 1em;
  padding: 1em;
  display:flex;
  flex-direction: column;
  box-shadow: $box-shadow-normal;

  >div:nth-child(1) {
    font-size:1.5em;
    background-color:$c5;
    text-align: center;
    padding:0.2em;
    border-radius: 0.5em;
    margin-bottom:0.5em;
  }

  >.warning {
    background-color:$c17;
    padding:0.3em;
    text-align: center;
    border-radius: 0.5em;
  }

}

.introBox {
  max-width:35em;
  display:flex;
  flex-direction: column;
  align-items: center;
  span {
    color:red;
  }

}

.typeBoxes {
  position:relative;
  display:flex;
  margin:1em 0;
  justify-content:space-around;
  width:100%;

}

.message {
  margin-top:1em;
  width:90%;
  margin:auto;
  min-height: 2em;
  padding:0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background:$colorno;
  border-radius: 0.5em;
  }

.inputError {
  background:#FF9999;
}

.headerLogin {
  display:flex;
  justify-content:space-around;
  align-items:center;
  width:100%;
  text-align: center;
  background:$g2;
  border-radius: 0.5em 0.5em 0 0;
  padding: 1em;
  box-sizing: border-box;
  border-bottom: 0.2em solid $c9;
}

.emailBox {
  display:flex;
  align-items: center;

  img {
    width:1em;
    margin-left:-1.5em;
    margin-top:0.5em;
  }
}

.codeIntro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:#0005;
  padding: 0.4em;
  margin:0.5em;
  border-radius: 0.5em;
  >div:first-child {

  }
}

.container {
  background:$g5;
  margin:0.5em 1.5em;
  border-radius: 0.5em;
  box-shadow: $box-shadow-normal;
  display: flex;
  flex-direction: column;
}

@media (orientation: portrait) {

  .container {
    margin:1.5em 0.5em;
  }
  .typeBoxes {
    flex-direction: column;
    max-width: 90%;
    font-size:1.35em;
    margin-left: 5%;
  }

  .cajaTipoLogin {
    margin-bottom: 1em;
  }

  .headerLogin {
  }
}


</style>
