Android.svg
Apple.svg
app.svg
ChromeOS.svg
Desktop.svg
iPad.svg
iPhone.svg
iPod.svg
KindleFire.svg
Linux.svg
list.txt
Microsoft.svg
Phone.svg
Tablet.svg
unknown.svg
web.svg
WindowsPhone.svg
