<template>
    <div v-if="markData"
    :class="{ noevents: markData.dice }"
    :style="{ 
        width: colWidth*XunitSize+'px' , 
        transform: `translate(${ vx }px,${ vy }px)`
        }">
         <img style="width:100%;"
                :src="require(`@/assets/img/piedras/${ markData.cclass }.svg`)"      
                
        />                 
        <img style="width:50%;" v-if="markData.dice"  :src="require(`@/assets/img/dados/d1${ markData.dice }.svg`)" />
    </div>
</template>

<script>

    var posHelper = require('../../assets/js/positionHelpers');

	export default {
		name: 'CheckerMark',
        props: ['markData','colWidth',
                'XunitSize','YunitSize','maxTop','playerID','direction'],
        data: function() {
            return {
            }
        },
        computed: {
            vx: function() {
                if (!this.markData) return null;
                return this.x(this.markData.position,this.direction)*this.colWidth*this.XunitSize;
            },
            vy: function() {
                let playerID = this.playerID || 0;
                if (!this.markData) return null;
                return this.maxTop-this.y(this.markData.position,playerID)*this.colWidth*this.XunitSize;
            }     
        },
        methods: {	
            x: posHelper.x,
            y: posHelper.y,       
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    div {
        position:absolute;
        
    }
    img {
        position: absolute;
    }

    .noevents {
        pointer-events:none;
    }

</style>
