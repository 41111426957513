<template>
    <div class="mainBox">
        <div style="width:100%;max-width: 1024px;">
            <EncabezadoPrincipal                
                :screenMode="'vertical'"
                :title="'Remove Your User From Abak'"
                :showAbak="false"
            />
            <div class="body">
                <div v-if="response && response.data" class="dataInfo">
                    <div v-if="response.data.email">
                        <div> Confirm to permanently delete your user: </div>
                        <div class="userData"> 
                            <div><div>User:</div><div>{{ response.data.name }}</div></div>
                            <div><div>Email:</div><div>{{ response.data.email }}</div></div>
                        </div>
                        <div style="margin-top:1em;text-align: center;display: flex;justify-content: space-around;"> 
                            <button @click="deleteUser();"> <img :src="require('@/assets/img/buttons/delete.svg')" /> <div>Delete</div> </button>
                            <button @click="privacy.show=true;"> <img :src="require('@/assets/img/admin/user.svg')" /> <div>Privacy Policy</div> </button>
                        </div>
                    </div>
                    <div v-else style="
                            min-height: 5em;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;"> 
                        <div> 
                            You user is not registered with email. <br>
                            There is no need to delete it. <br>
                            Regardless, if you logout, this session will be removed.
                        </div>
                        <div style="display: flex;justify-content: space-around;width: 100%;margin-top:1em;"> 
                            <button @click="logoutUser();"> <img :src="require('@/assets/img/buttons/logout.svg')" /> <div>Logout</div> </button>
                            <button @click="gotoUrl('/');"> <img :src="require('@/assets/img/buttons/roll.svg')" /> <div>Play</div> </button>
                            <button @click="privacy.show=true;"> <img :src="require('@/assets/img/admin/user.svg')" /> <div>Privacy Policy</div> </button>
                        </div>
                    </div>
                </div>
                <div class="message">
                    <div v-if="userDeleted"> 
                            {{userDeleted}}
                    </div>
                    <div v-if="noCookie" style="
                            min-height: 5em;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;"> 
                        <div> 
                            There is no user registered in this device.
                            In order to delete an existing user, yo must login in <b @click="gotoUrl('/')">play.abak.me</b>
                            and then return to this screen.
                        </div>
                        <div style="margin-top:1em;text-align: center;display: flex;justify-content: space-around;"> 
                            <button @click="gotoUrl('/');"> <img :src="require('@/assets/img/buttons/roll.svg')" /> <div>Play</div></button>
                            <button @click="privacy.show=true;"> <img :src="require('@/assets/img/admin/user.svg')" /> <div>Privacy Policy</div> </button>
                        </div>
                    </div>
                </div>
                <PrivacyPolicy style="font-size:1.5em;" v-if="privacy.show" @colse-privacy="privacy.show=false;"/>
            </div>
        </div>
    </div>
</template>

<script>
    
    const { APIURL,APIPORT,WEBPROTOCOL } = require('../assets/js/enviroment.js');
    import EncabezadoPrincipal from '../components/cajas/EncabezadoPrincipal.vue';
    import PrivacyPolicy from '../components/cajas/PrivacyPolicy.vue';

	export default {
		name: 'AuthCodeView',
        props: [],
        components: {EncabezadoPrincipal,PrivacyPolicy},
        data: function() {
            return {
                response: null,
                userDeleted:null,
                noCookie:false,
                privacy: { show:false }
            }
        },
        computed: {
        },
        methods: {	
            getUserInfo: function() {
                if (this.$cookie.getCookie("abk_")) {
                    this.axios
                    .get(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/info`)
                    .then((response) => {
                        console.log("connected");
                        this.response = response; 
                    }).catch((error) => {
                        console.log(error);
                    });
                }
                else {
                    this.noCookie = true;
                }
            },
            deleteUser: function() {
                let data = {
                    id: this.response.data.id,
                    authToken: this.response.data.authtoken
                }
                localStorage.removeItem("abk_");
                this.axios
                    .post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/delete`,data)
                    .then((response) => {
                        this.userDeleted = response; 
                    }).catch((error) => {
                        console.log(error);
                });
            },
            logoutUser: function() {
                let postData = { id:0 };
                        this.axios.post(
                            `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/logout`,
                            postData).then((response) => {
                                if (response.data.r) {
                                    localStorage.removeItem("abk_");
                                    location.reload();
                                }   
                                else {
                                    console.log("error");
                                }                         
                }).catch(console.error);                          
            },
            gotoUrl: function(url) {
                window.location.href = url;
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                    this.getUserInfo();      
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .mainBox {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        overflow-y: hidden;
        >div {
            background:$g5;
            height:97vh;
            border-radius: 0.5em;
        }
    }

    .title {
        font-size: 2em;
        text-align: center;
        margin-bottom:0.5em;
    }

    .dataInfo {
        display: flex;
        justify-content: space-around;
        width: 90%;
        >div {
            background-color: $c4;
            padding:0.2em 0.5em;
            border-radius: 0.5em;
        }
    }   

    .message {
        text-align: center;
        width: 80%;
        margin-left:10%;
        margin-top:1em;
        >div {
            padding:0.5em;
            border-radius: 0.5em;
            font-size: 1.5em;
        }
        .error {
            background: rgb(136, 15, 15);
        }
        .success {
            background: $c11;
            >div:nth-child(2) {
                font-size: 0.8em;                
                color:$c13;
            }
        }
        .working {
            background: $c5;
        }
    }

    .goto {
        padding:0.3em;
    }


    .body {
        padding:1em;
    }

    .userData {
        div {
            display: flex;
            div {
                padding:0.3em;
            }
            div:nth-child(1) {
                background:$c5;
                padding-right: 2em;
                min-width: 5em;
            }
        }
    }

    button {
        background:$c7;
        display: flex;
        border-radius: 0.5em;
        padding:0.3em;
        font-size:1.3em;
        align-items: center;
        img {
            width: 1.5em;
            margin-right: 0.3em;
        }
    }


</style>



