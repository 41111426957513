<template>
  <div class="ynFrame">
   <div         
       :class ="{ stateOn: value }"
        class="switchYesNo"
    >
       <div 
       class="switchBall"
       >&nbsp;</div>
   </div>
</div>
</template>

<script>
	export default {
		name: 'YesNoSwitch',
        props: ['value'],
        data: function() {
            return {
            }
        },
        computed: {
        },
        methods: {	

        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .switchBall {
        width:1.3em;
        border-radius: 1em;
        margin:0.1em;
        background:$colorno;
        border:0.1em solid $c5;
    }

    $localColor: $c9;
    
    .switchYesNo {
        width:3em;
        background: $localColor;
        border-radius: 1em;
        border:0.1em solid $c5;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .stateOn {        
        justify-content: flex-end;
    }

    .stateOn > div {
        background:adjust-color($coloryes,$lightness:0);
    }

    .ynFrame {
        margin:0.2em;
        padding:0.2em;
        border:0.1em solid $c5;
        background-color:adjust-color($localColor, $lightness:-15);
        border-radius: 1em;
    }



</style>
