<template>
<div :style="{ top: chronoPosition + '%' , left: chronoLeft + '%' }">{{timeFormated}}</div>
</template>



<script>
export default {
name: 'Chrono',
data: function() {
            return {
            }
        },
computed: {
            chronoPosition: function() {
                if (this.chronoPlayerID == "2") {
                    if (
                        this.currentPlayer == this.playerID || 
                        this.stage =="respondToDouble") 
                        return 75;
                    else 
                        return 20;
                }
                if (this.chronoPlayerID != this.playerID) return 15;
                if (this.chronoPlayerID == this.playerID) return 80;
                return 50;
            },
            chronoLeft: function() {
                if (this.direction == -1) return 93.5;
                else return -0.5;
            },
            userTimeOut: function() {
                if (this.time > 30) return true;
                return false;
            },
            timeFormated: function() {
                let secconds = parseInt(this.time);
                if (secconds >= 60*60-1) return "99:99";
                let m=parseInt(secconds/60).toString();
                let s=(secconds % 60).toString();
                return `${m.padStart(2,"0") }:${s.padStart(2,"0")}`
            },
            calcTheme: function() {
                if (this.theme) return this.theme;
                else return "default";

            }
        },
methods: {	
        },
mounted: function() {
            this.$nextTick(function () {
            });
        },
props: [
            'chronoPlayerID',
            'time',
            'playerID',
            'currentPlayer',
            'theme',
            'stage',
            'direction',
            'colors'
        ],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    img {
        width:100%;
    }

    div {
        background-color:v-bind('colors.background');
        color:v-bind('colors.color');
        font-size:0.7em;
        width:6.5%;
        position:absolute; 
        height:5%;
        box-sizing: border-box;
        display:flex;
        justify-content: center;
        align-items: center;       
    }

</style>
