const clickRollStartDie = function(G,ctx) {  

  //console.log(G.opponent.type,ctx.playerID,G.diceRoll[1]);
  
  /*
  This code allows to play a desired roll at start.
  G.diceRoll = [5,5];

  const startPlayer = G.diceRoll[0] > G.diceRoll[1] ? 1 : 0 ; // When enter play stage this will change, so it is backwards. (I think!)
  const realStartPlayer = G.diceRoll[0] > G.diceRoll[1] ? 0 : 1 ; // This is the real player, to show in logs.
  G.turnslog.log.push(
    {
      type:"roll_start_starts",
      playerID: realStartPlayer, 
      diceRoll:G.diceRoll,

    });        
  G.diceAvailable = [G.diceRoll[0],G.diceRoll[1],0,0];                      
  ctx.events.endTurn({ next: `${startPlayer}` });
  ctx.events.endPhase();                                   
  */
  

  /* Roll Dice if is not rolled. */
  if (G.diceRoll[ctx.playerID]==0) {
    G.diceRoll[ctx.playerID] = ctx.random.D6();    
    G.turnslog.log.push(
      {
        type:"roll_start_roll",
        playerID: ctx.playerID, 
        diceRoll:G.diceRoll[ctx.playerID],
      });        
  }
  // If CPU is playing, roll. 
  else if (G.opponent.type=="CPU" && ctx.playerID == "0" && G.diceRoll[1]==0) {
      //console.log("Si debo yirar por cpu");
      G.diceRoll[1] = ctx.random.D6();
      //G.diceRoll[1] = 1;
      G.turnslog.log.push(
        {
          type:"roll_start_roll",
          playerID: "1", 
          diceRoll:G.diceRoll[1],
        });        
  
  }

  //console.log("DICE ROLL START : ", G.diceRoll[0], G.diceRoll[1], ctx.currentPlayer);

  // If dice are equal, reset to 0. 
  if (G.diceRoll[0]==G.diceRoll[1] && G.diceRoll[0]>0 && G.diceRoll[1]>0 ) {
      G.turnslog.log.push(
        {
          type:"roll_start_equals",
          playerID: "1", 
          diceRoll:G.diceRoll,
        });        
      G.diceRoll = [0,0];
  }

  // If dice aren't equal and are rolled,  define who starts and change phase. 
  if (G.diceRoll[0]!=G.diceRoll[1] && G.diceRoll[0]>0 && G.diceRoll[1]>0 ) {
    const startPlayer = G.diceRoll[0]>G.diceRoll[1] ? 1 : 0 ; // When enter play stage this will change, so it is backwards. (I think!)
    const realStartPlayer = G.diceRoll[0]>G.diceRoll[1] ? 0 : 1 ; // This is the real player, to show in logs.
    G.turnslog.log.push(
      {
        type:"roll_start_starts",
        playerID: realStartPlayer, 
        diceRoll:G.diceRoll,

      });        
    G.diceAvailable = [G.diceRoll[0],G.diceRoll[1],0,0];                      
    ctx.events.endTurn({ next: `${startPlayer}` });
    ctx.events.endPhase();                      
  }             
  //console.log("dicerollstartend");
}

module.exports = { clickRollStartDie };