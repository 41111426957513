<template>
<div>
    <div v-show="!confirm.on">
        <div class="contenedor-acciones" :class=" {timeTraveling: timeTravel.on}">

                <div style="display:flex;">
                <div style="display: flex;flex-direction: column; align-items: center;">
                <!--************************************* Surrender  ********************************-->
                <GameButton 
                        v-show="!timeTravel.on && ['defineWhoStarts','rollorDouble','moveCheckers'].includes(playerStage)"  
                        :text="''" :imageSize="imageSize*0.8" :image="'surrender'" @click="clickAction('quit-game')"
                        :button_id="'ButtonSurrender'" :type="'none'"
                        :theme="theme.buttons"
                />            
                <!--************************************* Pause  ********************************-->
                <GameButton 
                        v-show="opponent.type == 'CPU' && !isVisitor && !timeTravel.on"
                        :text="''" :imageSize="imageSize*0.5" :image="'pause'" 
                        @click="$emit('pause-game')"
                        :button_id="'ButtonPause'"
                        :theme="theme.buttons"
                />            
                </div>
                <!--************************************* Claim  ********************************-->
                <GameButton 
                        v-show=" !timeTravel.on && opponent.type=='HUMAN' && !isVisitor && canclaim " 
                        :text="''" :imageSize=" imageSize * 0.8 " :image="'claim'" @click="$emit('claim-dialog')"
                        :type=" canclaim ? 'none' : 'none-negative' "
                        :button_id="'ButtonClaim'" 
                        :theme="theme.buttons"
                />            

                <TimeOutButton v-if=" !timeTravel.on && opponent.type=='HUMAN' && !isVisitor "
                        :counting=" !playerStage "
                        :imageSize="imageSize"
                        :timeTotal=this.timers.maxTimeOut
                        :timeStarted="localTimers.turnstarted"
                        :myturn="typeof playerStage != 'undefined' || playerStage == 'defineWhoStarts' "
                />

                <!---
                <CircularProgress 
                    :timeTotal="this.timers.maxTimeOut"
                    :timeStarted="localTimers.turnstarted"
                    :size="imageSize"
                />
                -->

                <!--************************************* Tips  ********************************-->
                
                <GameButton 
                        v-show="preferences.helpers.showAdvices && !timeTravel.on && ['rollorDouble','moveCheckers'].includes(playerStage)"  
                        :text="''" :imageSize="imageSize*0.8" 
                        :image="tipsAvailable ? 'idea_on' : 'idea_off'" 
                        @click="clickTips()"
                        :button_id="'ButtonTips'" :type="'none'"                        
                        :theme="theme.buttons"
                />  
                </div>          

            <!---TIME TRAVEL ON ACTIONS --->

            <div v-show="timeTravel.on" style="display:flex;flex-grow:0.5;justify-content:space-around;">
                <GameButton 
                    :button_id="'shareBoard'"
                    :id="'shareBoardBtn'"
                    text="" 
                    image='share'  
                    :imageSize="imageSize" 
                    :theme="theme.buttons"
                    @click="$emit('share-board')"
                />
                <GameButton 
                    :button_id="'TimeTravelBack'"
                    :id="'TimeTravelBack'"
                    text="" 
                    image='backward' 
                    :imageSize="imageSize" 
                    :theme="theme.buttons"
                    @click="$emit('set-time-travel',{turnID: timeTravel.position-1})"
                />
                <GameButton 
                    :button_id="'TimeTravelForward'"
                    :id="'TimeTravelForward'"
                    text="" image='forward'  
                    :imageSize="imageSize" 
                    :theme="theme.buttons"
                    @click="$emit('set-time-travel',{turnID: timeTravel.position+1})"
                />

                <GameButton 
                    :button_id="'TimeTravelExit'"
                    :id="'TimeTravelExit'"
                    text="" 
                    image='quit'  
                    :imageSize="imageSize" 
                    :theme="theme.buttons"
                    @click="$emit('end-time-travel')"
                />
            </div>

            <!-- *********************** Undo Buttons ***************************-->

            <div v-show="!timeTravel.on && playerStage=='moveCheckers'">
                <div style="display:flex;flex-direction: column;align-items: center;justify-items: center;">
                    <div>
                        <GameButton 
                            :key="'ButtonUndoAll'"                      
                            :text="''"
                            :imageSize="imageSize*0.7" 
                            image='undoall' 
                            :button_id="'ButtonUndoAll'"
                            :id="'ButtonUndoAll'"
                            :theme="theme.buttons"
                            @click="$emit('undo-clicked',{all:true})" />
                    </div>                        
                    <div>
                        <GameButton 
                            :button_id="'ButtonUndo'"
                            :id="'ButtonUndo'"
                            :key="'ButtonUndo'"                    
                            style="margin-top:0.2em;"
                            :text="''" 
                            :imageSize="imageSize*0.7" 
                            image='undo' 
                            :theme="theme.buttons"
                            @click="$emit('undo-clicked',{all:false})"/>
                    </div>                        
                </div>
            </div>


            <!--************************************* Dados  ********************************-->
                <div style="display:flex;flex-direction: column;align-items:center;"> 
                    
                    <DadosTiro :key="'DiceElement'" :id="'DiceElement'"
                        v-show="playerStage!='doublingWait'"
                        :diceRoll=diceRoll 
                        :currentPlayer = currentPlayer 
                        :diceAvailable = diceAvailable
                        :playerStage="playerStage"
                        :checkersColor="preferences.ui.checkersColor"                        
                        :opponentStage="opponentStage"
                        :maxMovements ="maxMovements"
                        :currentMovements ="currentMovements"
                        :imageSize ="imageSize"
                        :rollNumber ="rollNumber"
                        :playerID ="playerID"
                        :theme="theme.dice"
                        :timeTravel ="timeTravel"
                        :iaPlayStage="iaPlayStage"
                        :tipoAnimacion="preferences.ui.tipoAnimacionDados"
                    />
                </div>

            <!-- ************************** IA Error Action *************************************-->

            <div class="iaErrorContainer" v-if="iaPlayOnError.error && iaPlayOnError.tries>iaErrorMaxRetries"> 
                <div> IA Error. </div>
                <div @click="requestIAretry()"> Retry </div>
            </div>

            <!--************************************* Visitor Buttons  ********************************-->

            <div v-if="isVisitor"> 
                <GameButton :key="'ButtonVisitorQuit'" :id="'ButtonVisitorQuit'" :text="''" :theme="theme.buttons" :imageSize="imageSize" image='quit' @click="$emit('quit-visitor')" />
            </div>

            <!--************************************* Double Buttons  ********************************-->

            <div v-show="playerStage=='respondToDouble'">
                <GameButton :key="'ButtonReject'" :id="'ButtonReject'" :text="''" :theme="theme.buttons" :imageSize="imageSize" image='no' @click="moves.clickCancelDouble()" />
            </div>
            <div v-show="playerStage=='respondToDouble'" class="messageBox" style="display:flex;justify-content:center;margin:0.5em;">
                <div style="margin:0.3em;text-align:center;">{{t('ui.game.dialogs.oppentdoubles')}}</div>
                <img alt="dice" style="width:2em;margin:0.3em;" :src="require(`@/assets/img/dados/dd${doubleCube.value*2}.svg`)">                
            </div>
            <div v-show="playerStage=='respondToDouble'">
                <GameButton :key="'ButtonAcept'" :id="'ButtonAcept'" :text="''" :theme="theme.buttons" :imageSize="imageSize" image='si' @click="moves.clickConfirmDouble()" />
            </div>

            <!--************************************* Central Messaging  ********************************-->

            <!-- Opponent Playing -->
            <div v-show="
                opponent.type == 'HUMAN' &&
                (playerStage=='doublingWait' || 
                (playerStage=='defineWhoStarts' && diceRoll[playerID]>0) ||
                (playerStage!=='respondToDouble' && playerStage!='defineWhoStarts' && currentPlayer!=playerID && diceRoll[0]==0))  " class="messageBox">
                {{t('ui.game.messages.waintingforopponent')}}
            </div>

            <!-- Opponent Not Conected -->
            <div class="messageBox" v-show="waitingForOpponent">
                <div>{{t('ui.game.messages.waintingforopponent')}}</div>
                <div v-show="uIstate.invitations.state == 'invitationSent'" class="invitationSent">
                    {{t('ui.game.messages.invitationsent')}}
                    {{uIstate.invitations.responseTick+1}}
                </div>                
                <div v-show="uIstate.invitations.state == 'exaustedInvitations'" class="exaustedInvitations">
                    {{t('ui.game.messages.exaustedinvitations')}}
                </div>
            </div>



            <!--************************************* Right Buttons  ********************************-->


            <div v-show="!timeTravel.on && playerStage=='moveCheckers'">
                <GameButton 
                    :key="'ButtonDone'" 
                    :button_id="'ButtonDone'"                    
                    :id="'ButtonDone'" 
                    :text="''" 
                    :imageSize="imageSize" 
                    image='done' 
                    @click="clickDone()"
                    :theme="theme.buttons"
                     />
            </div>

            <div style="display:flex;margin-left:1em;" >


            <!---ROLL TO START --->

            <div v-show="playerStage=='defineWhoStarts' && !waitingForOpponent" style="display:flex;">
                <GameButton 
                    :key="'ButtonRollStart'" 
                    :id="'ButtonRollStart'" 
                    :button_id="'ButtonRollStart'" 
                    :text="''" :imageSize="imageSize" image='roll' @click="clickRollToStartDie();"
                    :theme="theme.buttons"
                    />
            </div>

            <!---ROLL OR DOUBLE --->
            <div v-show="playerStage=='rollorDouble'">
                
                <div v-show="!timeTravel.on && !waitingForOpponent" style="display:flex;">
                    <GameButton 
                        :key="'ButtonRoll'" 
                        :imageSize="imageSize" 
                        :id="'ButtonRoll'"
                        :button_id="'ButtonRoll'"
                        :text="''" 
                        image='roll' 
                        :theme="theme.buttons"
                        @click="clickRoll();"/>
                </div>

            </div>




                <!--************************************* Time Travel  ********************************-->

                <!--div v-show="!timeTravel.on && playerStage=='rollorDouble'"  style="display:flex;margin-left:0.5em;">                
                    <GameButton 
                        :text="''" 
                        :button_id="'ButtonTimeTravelStart'"
                        :key="'ButtonTimeTravelStart'"
                        :imageSize="imageSize*0.8" 
                        :image="'timetravel'" 
                        @click="$emit('start-time-travel')"/>
                </div -->
            </div>
        </div>     
    </div>
    <!--************************************* Confirm Surrender  ********************************-->

    <div v-show="confirm.on" class="contenedor-acciones" style="z-index:1000;">
                <GameButton :text="''"  type="light" :imageSize="imageSize" :image="'no'" @click="confirm.on=false"/>
                    <div class="messageBox"> 
                        <div class="smallPlayers">    
                            <div class="smallplayer" :class="{ winner: surrenderPointsResult.winner===0 }">
                                <img alt="player avatar" style="width:2em;" :src='players[0].image'/>
                                <div>{{surrenderPointsResult.pointsResult[0]}}</div>
                            </div>
                            <div v-if="!waitingForOpponent" class="smallplayer" :class="{ winner: surrenderPointsResult.winner===1 }">
                                <img alt="player avatar" style="width:2em;" :src='players[1].image'/>
                                <div>{{surrenderPointsResult.pointsResult[1]}}</div>
                            </div>
                        </div>         
                        <div v-if="waitingForOpponent">
                            {{t("ui.game.messages.harmlessdisconnect")}}                            
                        </div>       
                        <div v-show="surrenderPointsResult.winner!==null && !waitingForOpponent">
                            <div style="text-align:center;">
                                {{t('ui.game.message.youwilllosematch')}}
                            </div>
                            <div class="surrenderRanking" v-if="gameType=='abak' && stake>0 && turnsCount>0">
                                <div style="display:flex;">
                                    <img alt="down" :src="require('@/assets/img/symbols/down.svg')" />
                                    {{ players[thisPlayer].ranking - newRanking[0][playerID] }}
                                </div>
                                <div>{{newRanking[0][playerID]}} </div>                           
                            </div>
                        </div>
                        
                    </div>
                <GameButton :text="''" type="light" :imageSize="imageSize" :image="'si'" @click="clickConfirm()"/>
    </div>  
</div>     
</template>

<script>

    import GameButton from "../buttons/GameButton.vue";
    import TimeOutButton from "../buttons/TimeOutButton.vue";
    import DadosTiro from "./DadosTiro.vue";
    import { useI18n } from 'vue-i18n';
    //import CircularProgress from "../elements/CircularProgress.vue";

    var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');
    //import { DEFAULT_MAX_INACTIVITY } from '../../assets/js/enviroment.js'

	export default {
		name: 'ActionContainer',
        props: ['playerStage','opponentStage','gameType',
                'currentPlayer','diceRoll','diceAvailable',
                'playerID','matchID','moves','nextMoves','maxMovements','currentMovements',
                'opponent','timeTravel','preferences','doubleCube','imageSize','rollNumber',
                'points','surrenderPoints','matchData','players','tips','canclaim','isVisitor',
                'uIstate','localTimers','timers','stake','turnsCount','theme','iaPlayStage','iaPlayOnError'],
        components: { DadosTiro, GameButton, TimeOutButton },
        emits: [
             'done-clicked',
            'undo-clicked',
            'quit-match',
            'quit-visitor',
            'claim-dialog',
            'quit-game',
            'click-roll',
            'click-roll-start',
            'click-done',
            'start-time-travel',
            'set-time-travel',
            'end-time-travel',
            'opponent-disconnected',
            'tips-requested',
            'toast',
            'pause-game',
            'share-board'],
        data: function() {
            return {
                movingAI:false,
                previousPahse:null,
                confirm: {
                    on:false,
                    action:""
                },
                state: {
                    rolling:false,                    
                },
                turn: {
                    pointsToLoose:0,
                    /*justRolled:false*/
                },
                newRanking: [[0,0],[0,0]],
                timeOutIAPlay: null,
                iaErrorMaxRetries:5,
                requested: {
                    calculateMoves:false,
                    decideDouble:false,
                    rollDice:false,
                },
            }
        },
        computed: {
            thisPlayer: function() {
                return this.playerID ? parseInt(this.playerID) : 0;
            },
            otherPlayer: function() {
                let playerID = this.playerID ? this.playerID : 0;
                return  (parseInt(playerID)+1)%2;
            },
            surrenderPointsResult: function() {
                let spt = this.points.current.slice();                                
                let loosePoints = this.surrenderPoints*this.doubleCube.value;
                spt[this.otherPlayer]+=loosePoints;
                let winner = null;
                if (spt[0]>=this.points.goal) winner=0;
                if (spt[1]>=this.points.goal) winner=1;
                return {
                    pointsResult: spt,
                    winner: winner ,
                    points:loosePoints
                    };
            },
            tipsAvailable: function() {
                return this.tips.ammount>0;
            },
            waitingForOpponent: function() {
                if (!this.playerID) return false;
                if (this.opponent.type == "CPU") return false;
                if (typeof this.matchData[this.otherPlayer].isConnected == "undefined") return true;
                return false;
            },
            
            

        },
        methods: {	
            clickRoll: function () {                     
                /* Emito Click Roll para en la tabla borrar los targets Positions */
                this.state.rolling = true;
                setTimeout(()=> {
                    this.$emit("click-roll");           
                    this.movingAI=false;
                },100);
                //this.moves.clickRoll();
            },
            clickTips: function() {
                if (this.tipsAvailable) {
                    this.$emit('tips-requested');
                }
                else {
                    this.$emit('toast',{text:this.t('ui.game.alerts.notips'),duration:1500})
                }
            },
            clickRollToStartDie: function() {
                this.$emit("click-roll-start");
                if (this.opponent.type == "CPU") {
                    setTimeout( ()=>{
                        this.$emit("click-roll-start");
                        },1000);
                }
            },
            execIAPlay: function() {
                if (this.currentPlayer == "1" && this.opponent.type == "CPU" && this.playerStage == "IAPlay") {
                    let timeOutLength = 200;
                    switch(this.iaPlayStage) {
                        case "decideDouble":
                            if (!this.requested.decideDouble) {
                                this.requested.decideDouble = true;
                                this.moves.decideDouble();
                            }
                            else {
                                timeOutLength = 300;     
                            }
                            break;
                        case "rollDice":
                            this.requested.decideDouble = false;
                            if (!this.requested.rollDice) {
                                this.requested.rollDice = true;
                                this.moves.rollDice();      
                                timeOutLength = 600;
                            }
                            else {
                                timeOutLength = 300;     
                            }
                            break;
                        case "calculateMoves":
                            this.requested.rollDice = false;
                            if (!this.requested.calculateMoves) {
                                this.requested.calculateMoves = true;
                                timeOutLength = 200;     
                                this.moves.calculateMoves();
                            }
                            else {
                                timeOutLength = 300;     
                            }
                            break;
                        case "calculatingMoves":
                            timeOutLength = 100;   
                            this.requested.calculateMoves = false;
                            break;
                        case "playMoves":
                            this.requested.calculateMoves = false;
                            timeOutLength = 1000;     
                            this.moves.playNextMove();
                            break;


                    }
                    if (this.iaPlayStage!="notPlaying" && this.playerStage == "IAPlay") {
                        if (this.iaPlayOnError.error) {
                            this.requested.calculateMoves = false;
                            if (this.iaPlayOnError.tries < this.iaErrorMaxRetries ) {
                                timeOutLength = this.iaPlayOnError.tries*1000;
                                let timeOutLengthSeconds = parseInt(timeOutLength/1000);
                                console.log("IA Error, retrying");
                                this.$emit('toast',{text:`IA Error, retrying in ${timeOutLengthSeconds} seconds`,duration:1000});
                                clearTimeout(this.timeOutIAPlay); 
                                this.timeOutIAPlay = setTimeout(()=>{this.execIAPlay()},timeOutLength);    
                            }
                            else {
                                this.$emit('toast',{text:`IA Error: exausted. Not retrying any more.`,duration:10000});
                                clearTimeout(this.timeOutIAPlay); 
                                console.log("IA Error, Exausted");
                            }
                        }
                        else {
                            clearTimeout(this.timeOutIAPlay); 
                            this.timeOutIAPlay = setTimeout(()=>{this.execIAPlay()},timeOutLength);    
                        }
                    }    
                    else {
                        this.movingAI=false;
                        this.requested.decideDouble = false;
                        this.requested.rollDice = false;
                        this.requested.calculateMoves = false;
                    }                
                }
            },
            calculateNewRanking: function() {
                let rl = lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,this.otherPlayer,this.points.goal,this.stake);
                let rw = lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,this.thisPlayer,this.points.goal,this.stake);
                return [rl,rw];
                },          
            
            clickDone: function() {                
                this.$emit('done-clicked',false);
            },
            clickAction: function (action) {
                if (action == 'quit-game') {
                    this.newRanking = this.calculateNewRanking();
                }
                this.confirm.on = true;
                this.confirm.action = action;
                //this.ctx.events.setplayerStage("defineWhoStarts");
            },
            clickConfirm: function() {     
                //console.log("confirm",this.confirm.action);
                if (this.confirm.action=="quit-game")
                    this.$emit("quit-game");
                if (this.confirm.action=="next")
                    this.moves.clickNewMatch();     
                this.confirm.on = false;
                this.confirm.action = "";                                               
            },
            clickCancel: function() {
                this.confirm.on = false;
                this.confirm.action = "";
            },
            requestIAretry: function() {
                this.moves.resetIAError();
                console.log("Request Retrying IA Error");
                clearTimeout(this.timeOutIAPlay); 
                this.$emit('toast',{text:'Preparing to retry IA Move.',duration:1000})
                this.timeOutIAPlay = setTimeout(()=>{this.execIAPlay()},1200);
            }
        },
        watch: {
            playerStage: function() {
                if (this.previousPahse != this.playerStage) {
                    if (this.playerStage == "IAPlay") {   
                        clearTimeout(this.timeOutIAPlay);   
                        this.requested.decideDouble = false;
                        this.requested.rollDice = false;
                        this.requested.calculateMoves = false;
                        this.timeOutIAPlay = setTimeout(()=>{this.execIAPlay()},500);
                    }
                }
                if (this.previousPahse != this.playerStage) {
                    if (this.playerStage == "rollorDouble") {
                        if (this.preferences.acelerators.autoRollForce ||
                            ( this.preferences.acelerators.autoRoll && 
                              this.doubleCube.holder!=this.playerID && this.doubleCube.holder!== null ) ) {
                            this.clickRoll();
                        }
                    }
                }
            },
            maxMovements: function (newValue,oldValue) { 
                if (oldValue===null && newValue===0) {
                    if (this.currentPlayer==this.playerID) {
                        setTimeout(() => this.$emit('toast',{text:this.t('ui.game.alerts.nomovesturn'),duration:600}),600);
                        if (this.preferences.acelerators.autoDoneOnNoValids) {
                            setTimeout(()=>{this.$emit('done-clicked',true)},1000);
                        }
                    }
                }
                    
            },
            matchData: function(data) {
                //console.log("MatchData Changed");
                let playerID = this.playerID || 0;

                let otherPlayer = (parseInt(playerID)+1)%2;
                if (
                    typeof(data[otherPlayer].isConnected) != 'undefined' &&
                    data[otherPlayer].isConnected == false) {                    
                    this.$emit("opponent-disconnected");
                }
            },

        },
        mounted: function() {
            this.$nextTick((function () {
                //console.log(this.playerStage ,this.movingAI,this.currentPlayer);
                if (
                    this.playerStage == "IAPlay" 
                    && !this.movingAI
                    && this.currentPlayer == "1") {
                        clearTimeout(this.timeOutIAPlay); 
                        setTimeout(()=>{this.execIAPlay()},500);
                        //setTimeout(()=>{this.execMove()},500);
                    }
            }));
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },          
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.contenedor-acciones {
    position:absolute;
    width:85%;
    height:20%;
    left:7.5%;
    top:40%;
    display:flex;
    justify-content: space-around;
    align-items: center;
}


.smallplayer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5em;
    padding:0.3em;
    margin:0.2em;
}
.smallplayer:nth-child(1) {
    background: $checkerwhite-background;
    color:$checkerwhite-color;
}
.smallplayer:nth-child(2) {
    background: $checkerblack-background;
    color:$checkerblack-color;
}


.winner {
    border: 0.2em solid black;
}

.smallPlayers {
    display:flex;    
    justify-content: center;

}

.messageBox {
    background: $c8;    
    box-shadow:$box-shadow-normal;
    padding:0.5em;
    border-radius:1em;
}

.surrenderRanking {
    display:flex;
    justify-content: space-around;
}
.surrenderRanking div {
    background:$c0;
    border-radius: 0.5em;
    padding:0.3em 0.7em;
    margin:0.3em;
}

.exaustedInvitations {
    font-size: 0.7em;
}

.invitationSent {
    font-size: 0.7em;
}

.timeTraveling {
    background: v-bind('theme.elements.box1.background');
    border-radius: 1em;
}

.iaErrorContainer {
    width: 70%;
    display: flex;
    background:rgb(49, 11, 11);
    padding:0.5em;
    align-content: center;
    align-items: center;
    border-radius: 1em;
    justify-content: space-around;
    > div:nth-child(2) {
        padding:0.3em;
        background: rgb(10, 71, 10);
        border-radius: 0.5em;
        cursor: pointer;
        box-shadow: $box-shadow-normal;
    } 

}


</style>
