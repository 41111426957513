export default {
  "background": "rgb(53, 53, 53)",
  "path": "",
  "board": {
    "background": "rgb(179, 149, 73)",
    "border": "#4d3025",
    "bar": "#4d3025",
    "points": [
      {
        "background": "#ac784a",
        "border": "#230d07"
      },
      {
        "background": "rgb(161, 79, 55)",
        "border": "#230d07"
      }
    ]
  },
  "dice": [
    {
      "background": "rgb(235, 232, 206)",
      "border": "rgb(104, 102, 64)",
      "dot": "rgb(75, 75, 75)",
      "dotborder": "black"
    },
    {
      "background": "#492a02",
      "border": "rgb(56, 0, 0)",
      "dot": "white",
      "dotborder": ""
    },
    {
      "background": "#666",
      "border": "#333",
      "dot": "#333",
      "dotborder": "#111"
    },
    {
      "background": "#A00",
      "dot": "black",
      "dotborder": "#300",
      "border": "#300"
    }
  ],
  "cube": {
    "background": "#ceca9d",
    "color": "#2e130c"
  },
  "checkers": [
    {
      "background": "rgb(235, 232, 206)",
      "line": "#986941",
      "object1": "rgb(46, 26, 0)",
      "object2": "#40190f",
      "color": "#2e130c"
    },
    {
      "background": "#2e130c",
      "line": "rgb(158, 99, 62)",
      "object1": "rgb(219, 197, 149)",
      "object2": "#c89159",
      "color": "#ceca9d"
    }
  ],
  "buttons": {
    "default": {
      "background": "rgb(6, 36, 7)",
      "border": "red",
      "hover": "gray",
      "off": "#300"
    },
    "light": {
      "background": "#69621c",
      "border": "red",
      "hover": "gray",
      "off": "#000"
    },
    "soft": {
      "background": "#69621c",
      "border": "black",
      "hover": "gray",
      "off": "#000"
    },
    "warning": {
      "background": "#ada224",
      "border": "black",
      "hover": "grey",
      "off": "#000"
    }
  },
  "elements": {
    "box1": {
      "background": "rgb(97, 51, 11)",
      "color": "white"
    },
    "box2": {
      "background": "rgb(53, 33, 9)",
      "color": "white"
    },
    "scroll": {
      "bar": "#555555",
      "hover": "#999999"
    },
    "chrono": {
      "total": {
        "background": "#9e8863",
        "color": "white"
      },
      "current": {
        "background": "#4e4833",
        "color": "white"
      }
    }
  }
}