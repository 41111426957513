<template>
    <div>
        <div class='contenedor-tabla'
            :style="{ width: anchoCanvas + 'px', height: altoCanvas + 'px' }"
            >
            
            <svg :key="'movePath'" id="'movePath'" :ref="'movePath'" style="position:absolute;pointer-events:none;"
                    :style="{ width: anchoCanvas + 'px',height: altoCanvas + 'px' }"
                    >
                        <path id="path" :d="calculatedPath" stroke="transparent" fill="transparent"/>
            </svg>

            <!-- *********************************  Columnas de la tabla Grande  ***************************************************-->
            <Point :type="'c'" :typeColor="null" 
                   :playerID ="getPlayerID"
                   :index=" preferences.ui.direction == 1 ? '' : '25' "
                   :anchoColumna="anchoColumna"
                   :left ="0" :top="0" :inverted=false
                   :showPoints = preferences.helpers.showPoints
                   :colors="theme.board"
            />
            <Point :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="preferences.ui.direction == 1 ? '' : '0' " 
                   :anchoColumna="anchoColumna"
                   :left ="0" :top="50" :inverted=false
                   :showPoints = preferences.helpers.showPoints
                   :colors="theme.board"
            />

            <Point :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'26'" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*7" :top="50" :inverted=false
                   :showPoints = preferences.helpers.showPoints
                   :colors="theme.board"
            />
            <Point :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'27'" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*7" :top="0" :inverted=false
                   :showPoints = preferences.helpers.showPoints
                   :colors="theme.board"
            />

            <Point :type="'c'" :typeColor="null" 
                   :playerID ="getPlayerID"
                   :index="preferences.ui.direction == 1 ? '25' : ''" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*14" :top="0" :inverted=false
                   :showPoints = preferences.helpers.showPoints
                   :colors="theme.board"
            />
            <Point :type="'c'" :typeColor="null" 
                   :playerID ="getPlayerID"
                   :index="preferences.ui.direction == 1 ? '0' : ''" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*14" :top="50" :inverted=false
                   :showPoints = preferences.helpers.showPoints
                   :colors="theme.board"
            />

            <!--img alt="backgammon point"  @click="resetTargetPositions()" class="pos1" src="@/assets/img/board/capilla2.svg"   style="height:50%;" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*0 + '%' }" >            
            <img alt="backgammon point" @click="resetTargetPositions()" class="pos2" src="@/assets/img/board/capilla2.svg"   style="height:50%;top:50%;" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*0 + '%' }">            

            <img alt="backgammon point"  @click="resetTargetPositions()" class="pos1" src="@/assets/img/board/capilla2.svg"   style="height:50%;" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*14 + '%' }">            
            <img alt="backgammon point"  @click="resetTargetPositions()" class="pos2" src="@/assets/img/board/capilla2.svg"   style="height:50%;top:50%;transform:scaleY(1);" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*14 + '%' }" >            

            <img alt="backgammon point"  @click="resetTargetPositions()" class="pos1" src="@/assets/img/board/capilla2.svg"   style="height:50%;" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*7 + '%' }">            
            <img alt="backgammon point"  @click="resetTargetPositions()" class="pos2" src="@/assets/img/board/capilla2.svg"   style="height:50%;top:50%;transform:scaleY(1);" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*7 + '%' }" -->            

            <Point 
                v-for="i in [1,2,3,4,5,6]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="i%2+1"                 
                :index="i+12" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="0" :inverted="true"
                :showPoints = preferences.helpers.showPoints
                :direction="preferences.ui.direction"
                :colors="theme.board"
            />

            <Point 
                v-for="i in [1,2,3,4,5,6]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="(i+1)%2+1"                 
                :index="19-(i+6)" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="50" :inverted="false"
                :showPoints = preferences.helpers.showPoints
                :direction="preferences.ui.direction"
                :colors="theme.board"
            />

            <Point 
                v-for="i in [8,9,10,11,12,13]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="(i+1)%2+1"                 
                :index="i+11" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="0" :inverted="true"
                :showPoints = preferences.helpers.showPoints
                :direction="preferences.ui.direction"
                :colors="theme.board"
            />

            <Point 
                v-for="i in [8,9,10,11,12,13]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="i%2+1"                 
                :index="8-(i-6)" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="50" :inverted="false"
                :showPoints = preferences.helpers.showPoints
                :direction="preferences.ui.direction"
                :colors="theme.board"
            />
            <!--img @click="resetTargetPositions()" @dragstart="'preventDefault'" 
                v-for="i in [1,2,3,4,5,6]" :key="'pos11'+i" 
                class="pos2" :src="require('@/assets/img/board/posicion'+(i%2+1)+'.svg')"  style="height:50%;transform:scaleY(-1);" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*i + '%'}">

            <img @click="resetTargetPositions()" v-for="i in [8,9,10,11,12,13]" :key="'pos12'+i" class="pos2" :src="require('@/assets/img/board/posicion'+((i+1)%2+1)+'.svg')"  style="height:50%;transform:scaleY(-1);" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*i + '%'}">
            <img @click="resetTargetPositions()" v-for="i in [1,2,3,4,5,6]"        :key="'pos21'+i" class="pos2" :src="require('@/assets/img/board/posicion'+((i+1)%2+1)+'.svg')"  style="height:50%;top:50%;" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*i + '%'}">
            <img @click="resetTargetPositions()" v-for="i in [8,9,10,11,12,13]"    :key="'pos22'+i" class="pos2" :src="require('@/assets/img/board/posicion'+((i)%2+1)+'.svg')"  style="height:50%;top:50%;" :style="{ width : anchoColumna + '%', left: (anchoColumna+separacionColumna2)*i + '%'}" -->
     




            <!-- *********************************  Piedras  ***************************************************-->
            
            <CheckerComp 
                v-for="checker in getCheckersMap"
                    :key ="'chk_'+checker.id"
                    :chkId="'chk_'+checker.id"
                    
                    :position="[checker.column,checker.height]"
                    :cclass="checker.cclass"
                    :owner="checker.owner"
                    
                    :direction="preferences.ui.direction"
                    :checkersColor="preferences.ui.checkersColor"

                    :tips ="tips.tips.checkers[checker.id] ? tips.tips.checkers[checker.id] : null"
                    
                    :colWidth=anchoColumna
                    :colHeight=altoPosicion
                    :XunitSize=unidadX
                    :YunitSize=unidadY
                    :maxTop=maxTop
                    
                    :playerID="getPlayerID"
                    
                    :starting="starting"
                    :selectedChecker="selectedChecker"

                    :colors="theme.checkers"

                    @click="clickChecker(checker.id)" 
                    @path-change="changePath($event.xpath)"
                    @dragstart="'preventDefault'"
                    @contextmenu.prevent="$emit('change-menu',{
                            menu: 'configMenu',
                            data: {
                                show:true,section:'rules',
                                stoneId:checker.cclass,
                                stonePage:0
                                }
                            });"
            />


            <MoveArrows v-for="(c,i) in checkersMarks"
                :id="'MoverArrow'+i"
                :playerID="playerID"
                :key="'MoverArrow'+i"
                :markData="c"
                :XunitSize="unidadX"
                :YunitSize="unidadY"
                :direction="preferences.ui.direction"
                :colWidth="anchoColumna"
                :maxTop="maxTop"                
            />

            <ActionContainer 
                    :key="'ActionContainer'"
                    :id="'ActionContainer'"
                    
                    @undo-clicked="clickUndo($event.all);"                    
                    @quit-match="this.resetTargetPositions();$emit('quit-match')"
                    @quit-game="this.resetTargetPositions();$emit('quit-game')"
                    @quit-visitor="$emit('quit-visitor')"
                    @claim-dialog="clickClaim()"
                    @pause-game="$emit('pause-game')"
                    @opponent-disconnected="canclaim=true"

                    @done-clicked="clickDone($event)"

                    @start-time-travel ="$emit('start-time-travel')"
                    @set-time-travel ="$emit('set-time-travel',$event)"
                    @end-time-travel ="$emit('end-time-travel')"
                    @click-roll ="clickRoll()"
                    @click-roll-start ="clickRollStart()"

                    @toast="showToast($event.text,$event.duration);"
                    @tips-requested="showTipsBox=true"

                    @share-board="showShareGame=true"

                    :diceRoll ="getDiceRoll"
                    :diceAvailable ="getdiceAvailable"
                    :playerStage ="getplayerStage"
                    :opponentStage="getOpponentStage"
                    :isVisitor="isVisitor"
                    :playerID ="getPlayerID"
                    :matchID ="getMatchID"
                    :matchData="matchData"
                    :currentPlayer ="getCurrentPlayer"
                    :moves ="getMoves"
                    :rollNumber = "getRollNumber"
                    :nextMoves = "getNextMoves"
                    :maxMovements = "getMaxMovements"
                    :currentMovements = "getCurrentMovements"
                    :opponent="getOpponent"
                    :timeTravel = "timeTravel"
                    :preferences="preferences"
                    :doubleCube="getDoubleCube"
                    :imageSize="getImageSize"
                    :points="getPoints"
                    :surrenderPoints="surrenderPoints"
                    :players ="players"
                    :tips ="tips.tips"
                    :canclaim="canclaim"
                    :uIstate="uIstate"
                    :localTimers="localTimers"
                    :timers="G.timers"
                    :gameType="G.type"
                    :turnsCount="G.turnslog.log.length"
                    :stake="G.stake"
                    :theme="theme"
                    :iaPlayStage="G.iaPlayStage"
                    :iaPlayOnError="G.iaPlayOnError"
            />
                        
            
            <TargetPosition v-for="pp in [0,1,2,3,4]"
                :ppid="pp"
                :key="'selectedPosition_'+pp"
                id="'selectedPosition_'+pp"
                :options='targetPositions[pp]'
                :colWith=anchoColumna
                :colHeight=altoPosicion
                :XunitSize=unidadX
                :YunitSize=unidadY
                :maxTop=maxTop
                :direction="preferences.ui.direction"
                :checkersColor="preferences.ui.checkersColor"
                :playerID="getPlayerID"
                z-Index=1000
                @click="clickTarget(pp)" 
                :colors="theme.dice"
            />


            <CheckerMark v-for="(c,i) in checkersMarks"
                :id="'checkerMark'+i"
                :playerID="playerID"
                :key="'checkerMark'+i"
                :markData="c"
                :XunitSize="unidadX"
                :YunitSize="unidadY"
                :direction="preferences.ui.direction"
                :colWidth="anchoColumna"
                :maxTop="maxTop"                
            />

            <Cube 
                :key="'CubeButton'"
                :id="'CubeButton'"
                :doubleCube='G.doubleCube'
                :PlayerID="getPlayerID"
                @click="attemptDouble()"
                :direction="preferences.ui.direction"
                :colWidth="anchoColumna*unidadX"
                :colors="theme.cube"
            />

            <Toast 
                :text ="toast.text"
                :active ="toast.active"
                :type ="toast.type"
                :image ="toast.image"
                :playerID ="toast.playerID"
                @click ="toast.active=false"
            />

            <Chrono :time="G.timers.game[0]" 
                    :chronoPlayerID="'0'"
                    :playerID="playerID"
                    :currentPlayer="getCurrentPlayer" 
                    :stage="getplayerStage"
                    :direction="preferences.ui.direction"
                    :colors="theme.elements.chrono.current"
                    />
            <Chrono :time="G.timers.game[1]" 
                    :chronoPlayerID="'1'"
                    :playerID="playerID"
                    :currentPlayer="getCurrentPlayer" 
                    :stage="getplayerStage"
                    :direction="preferences.ui.direction"
                    :colors="theme.elements.chrono.current"
                    />
            <Chrono :time="localTimers.turnTime" 
                    :chronoPlayerID="'2'"
                    :playerID="playerID"            
                    :currentPlayer="getCurrentPlayer" 
                    :theme="'turn'"
                    :stage="getplayerStage"
                    :direction="preferences.ui.direction"
                    :colors="theme.elements.chrono.total"
                    />

            <div 
                class="pointsBox0" 
                :style="{ left: preferences.ui.direction==1 ? '-0.5%' : '93.5%' }"
                @click="showMatchInfo=true"
                > 
                <div class="distanceBox">{{stats.distance[1]}}</div>
                <div>{{getPoints.current[1]-getPoints.goal}}</div>
            </div>
            <div 
                class="pointsBox1" 
                :style="{ left: preferences.ui.direction==1 ? '-0.5%' : '93.5%' }"
                @click="showMatchInfo=true"                
                > 
                <div>{{getPoints.current[0]-getPoints.goal}}</div>                
                <div class="distanceBox">{{stats.distance[0]}}</div>
            </div>
            <CubeConfirmation 
                :state="showCubeConfirmation ? 'show' : ''"
                :doubleCube="G.doubleCube"
                :opponent="G.opponent"
                :elementsColors="theme.elements"
                :buttonsColors="theme.buttons"
                @cube-cancel="showCubeConfirmation=false"
                @cube-confirm="showCubeConfirmation=false;clickDouble()"
            />           
    </div>                  
    <Loading v-if="starting" 
        :key="'engineLoader'"
        id="'engineLoader'"
        :text="t('ui.game.messages.drawingboard')" 
        style="z-index:10000"
    />

    <!--------------------------- OUT DIALOGS ------------------->

        <TipsMessages 
            v-if = "showTipsBox"
            :tips = "tips.tips.checkers"
            @close-tips="showTipsBox=false"
        />

        <transition name="slide-fade">
            <EndDialog 
                v-if=" (this.getPhase=='endedMatch' || this.getPhase=='endedGame') && !timeTravel.on " 
                :points="getPoints"
                :matchData="matchData"
                :players ="players"
                :phase ="ctx.phase"
                :playerID ="playerID"
                :surrenderPoints="surrenderPoints"
                :imageSize="getImageSize"
                :doubleCube="getDoubleCube"
                :opponent="getOpponent"
                :stake="G.stake"
                :gameType="G.type"
                @quit-match="$emit('quit-match',$event)"
                @quit-visitor="$emit('quit-visitor')"
                @start-time-travel ="$emit('start-time-travel')"
                @new-game="$emit('new-game')"
                @new-match="$emit('new-match')"
                @pause-game="$emit('pause-game')"
            />
        </transition>
        <transition name="slide-fade">
            <MatchInfo 
                v-if="showMatchInfo" 
                :points="getPoints"
                :matchData="matchData"
                :players ="players"
                :phase ="ctx.phase"
                :playerID ="playerID"
                :surrenderPoints="surrenderPoints"
                :imageSize="getImageSize"
                :doubleCube="getDoubleCube"
                :opponent="getOpponent"
                :stake="G.stake"
                :gameType="G.type"
                @close-match-info="showMatchInfo=false"
            />
        </transition>

        <transition name="slide-fade">
            <ClaimDialog v-if="canclaim && showclaim"
                :points="getPoints"
                :players ="players"
                :phase ="ctx.phase"
                :playerID ="playerID"
                :imageSize="getImageSize"
                :screenMode="uIstate.screenMode"
                @claim-close="showclaim=false"
                @claim-match="$emit('claim-match')"
            />
        </transition>

        <ShareBoard 
            :show="showShareGame"
            :checkers_map="getCheckersMap" 
            :points="getPoints"
            :roll="getDiceRoll"
            :cube="getDoubleCube"
            :player_id="parseInt(playerID)"
            :players="players"
            :matchID="matchID"
            @close-share="showShareGame=false"
        /> 


    </div>
</template>

<script>
    import ActionContainer from "./cajas/ActionContainer.vue";
    import CheckerComp from "./elements/CheckerComp.vue";
    import CheckerMark from "./elements/checkerMark.vue";
    import MoveArrows from "./elements/MoveArrows.vue";
    
    import TargetPosition from "./elements/TargetPosition.vue";
    import Cube from "./elements/Cube.vue";
    import Toast from "./cajas/Toast.vue";
    import EndDialog from "./cajas/EndDialog.vue";
    import MatchInfo from "./cajas/MatchInfo.vue";
    import Loading from "./cajas/Loading.vue";
    import Chrono from "./elements/Chrono.vue";
    import ClaimDialog from "./cajas/ClaimDialog.vue";
    import Point from "./elements/Point.vue";
    import CubeConfirmation from "./cajas/CubeConfirmation.vue";
    import ShareBoard from "../components/cajas/ShareBoard.vue";

    import { useI18n } from 'vue-i18n'
    import TipsMessages from "./cajas/TipsMessages.vue";
    import { updateLastSeen } from './vuejs/lobby';

    var abakLib = require('../assets/js/AbakEvolutionLibrary.js');
    
    var claimTimeOut = false;
    const ASSERT_MOVE_TIMEOUT = 15000; //15 seconds. This is just for claimed and wiped games, that is why is so big;

	export default {
		name: 'AbakBoard',
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },  
        emits: [ 
            'undo-clicked',
            'quit-match',
            'claim-match',
            'quit-visitor',
            'opponent-disconnected',
            'quit-game',
            'change-menu',
            'start-time-travel',
            'set-time-travel',
            'end-time-travel',
            'new-game',
            'share-board',
            'new-match',
            'pause-game',
            'action-event'
            ],
        components: {
            ActionContainer,
            CheckerComp,
            TargetPosition,
            Cube,
            Toast,
            EndDialog,
            Loading,
            CheckerMark,
            Chrono,
            ClaimDialog,
            TipsMessages,
            ShareBoard,
            Point,
            CubeConfirmation,
            MatchInfo,
            MoveArrows
        },

        props: {
            boardImageSize: Number,
            piedras: Object,
            id_animacion: Number,
            tabla: Object,
            G: Object,
            ctx: Object,
            moves: Object,
            matchData: Object,
            matchID: String,
            playerID: String,
            players: Object,
            player: Object,
            client: Object,
            preferences:Object,
            timeTravel:Object,
            analyseMove:Object,
            timeTravelState:Boolean,
            timeSyncDifference: Number,
            uIstate:Object,
            theme:Object
            },
        data: function() {
            return {                
                targetPositions: [null,null,null,null,null],
                selectedChecker: 0,
                checkersMarks: [null,null,null,null],
                tips: {
                    ammount: 0,
                    tips: {
                        checkers: Array(30).fill(null).map(function() { return { tips:[] , show:false } }),
                        list: []
                    }
                },
                showTipsBox: false,
                toast: {
                    active: false,
                    message: "",
                    playerID:null,
                    image:null,
                    type: "default",
                },
                lobby: {
                    games: {},
                    
                },
                localTimers: {
                    lastRoll:0,
                    lastClick:0,
                    lastMove:0,
                    turnstarted:0, 
                    turnTime:0,
                    connected:[0,0],
                    lastVisitorUpdated:0,
                },
                calculatedPath: "",
                starting: true,
                canclaim: false,
                showclaim: false,
                showCubeConfirmation:false,
                showMatchInfo:false,
                showShareGame:false,
                debug: {
                    dontClaim:false,
                },
                assertMoveTimeOutId:null,
                lastaction: {
                    action:"",
                    time:null,
                }
            }
        },

        computed: {
            isVisitor: function() {
                return this.playerID === null;
            },  
            getPlayerID: function() {
                return this.playerID || 0;
            },
            getOpponentID: function() {
                if (!this.playerID) return 2;
                let otherPlayer = (parseInt(this.playerID)+1)%2;
                return this.players[otherPlayer].id;
            },
            getMatchID: function() {
                return this.matchID;
            },
            getMoves: function() {
                return this.moves;
            },
            getplayerStage: function() {
                if (this.ctx.activePlayers===null || this.playerID===null) return "";
                return this.ctx.activePlayers[this.playerID];
            },            
            getOpponentStage: function() {
                let otherPlayer = (parseInt(this.playerID)+1)%2;
                if (this.ctx.activePlayers===null || this.playerID===null) return "";
                return this.ctx.activePlayers[otherPlayer];
            },        
            getCurrentPlayer: function() {
                if (!this.timeTravel.on) 
                    return this.ctx.currentPlayer;
                else {
                    try {
                        return this.G.turnslog.log[this.timeTravel.position].playerID;
                    }
                    catch {
                        return null;
                    }
                }              
            },
            getPhase: function() {
                return this.ctx.phase;
            },
            getOpponent: function() {
                return this.G.opponent;
            },
            getDiceRoll: function () {
                if (!this.timeTravel.on) 
                    return this.G.diceRoll;
                else {
                    return this.G.turnslog.log[this.timeTravel.position].diceRoll;
                }
            },
            getRollNumber: function () {
                return this.G.rollNumber;
            },
            getDoubleCube: function() {
                return this.G.doubleCube;
            },
            getdiceAvailable: function () {
                if (!this.timeTravel.on) 
                    return this.G.diceAvailable;
                else {
                    let dR = this.G.turnslog.log[this.timeTravel.position].diceRoll;
                    return [dR[0],dR[1],dR[0],dR[0]];
                }               
            },
            getNextMoves: function () {
                return this.G.nextMoves;
            },            
            getNextMoveToPlay: function () {
                return this.G.nextMoveToPlay;
            },            
            getMaxMovements: function () {
                return this.G.maxMovements;
            },  
            getCurrentMovements: function () {
                return this.G.currentMovements;
            },  
            getCheckersMap: function() {
                if (!this.timeTravel.on) 
                    return this.G.checkers_map;
                else {
                    if (!this.analyseMove.on) 
                        return abakLib.currentCheckersMap(abakLib.initialTablaByType(this.G.type,this.G.set),this.G.turnslog.log,this.timeTravel.position);
                    else {
                        if (this.analyseMove.moveID === null) {
                            return abakLib.currentCheckersMap(abakLib.initialTablaByType(this.G.type,this.G.set),this.G.turnslog.log,this.timeTravel.position);
                        }                            
                        else
                            return this.analyseMove.moves[this.analyseMove.moveID].checkers_map;
                    }
                }
            },
            getPoints: function() {
                return this.G.points;
            },
            altoCanvas: function() {
                return this.boardImageSize*0.6;
            },            
            anchoCanvas: function() {
                return this.boardImageSize*0.75;
            },
            unidadX: function() {
                return this.boardImageSize*0.75/100;
            },            
            unidadY: function() {
                return this.boardImageSize*0.5/100;
            },
            alturaDado: function() {
                let or = this.tabla.orientacion;
                if (or>0) return "20%";
                else return "60%";                
            },
            fontSize: function() {
                return 1.5*this.boardImageSize/10;
            },
            strokeWidth: function() {
                return this.boardImageSize/100;
            },
            maxTop: function() {
                return this.boardImageSize*0.6;
            },
            anchoColumna: function() {                 
                if (this.tabla.inicio ==3) return 6.67;
                return 12.5
            },                
            separacionColumna: function() { 
                if (this.tabla.inicio ==3) return 0.75;
                return 1.5
            },
            separacionColumna2: function() {                 
                return 0
            },
            altoPosicion: function() { 
                return 50;                
            },
            getImageSize: function() {
                return this.anchoColumna*this.unidadX;
            },
            surrenderPoints: function() {
                if (!this.playerID) return 0;
                return abakLib.surrenderPoints(this.G.checkers_map,this.playerID);
            },
            stats: function() {
                let tables = abakLib.convertCheckersMap(this.G.checkers_map);
                return {
                    distance:[abakLib.distancia_equipo(tables[0],1),abakLib.distancia_equipo(tables[0],2)]
                    };
            },
        },
        methods: {	            
            clickChecker: function(idchecker) {
               //console.log(abakLib.maxMoves(this.G.checkers_map,this.G.diceAvailable,this.playerID));
                this.localTimers.lastClick=Date.now();
                //console.log(this.getplayerStage);
                if (this.selectedChecker==idchecker) {
                    this.resetTargetPositions();
                    return;
                }
                this.resetTargetPositions();                

                if (this.timeTravel.on) {
                    this.showToast(this.t("ui.game.alerts.youraretimetraveling"),3000,'','',this.playerID);
                    return;
                }

                if (this.getplayerStage=="") {
                    this.showToast(this.t("ui.game.alerts.notyourtrun"),3000,'','',this.playerID);
                    return;
                }

                if (this.getMaxMovements==this.getCurrentMovements) {
                    this.showToast(this.t("ui.game.alerts.noavailabledice"),3000,'','',this.playerID);
                    return;
                }

                if (this.ctx.currentPlayer != this.playerID) {
                    this.showToast(this.t("ui.game.alerts.notyourtrun"),3000,'','',this.playerID);
                    return;
                }
                if (this.getplayerStage != "moveCheckers") {
                    this.showToast(this.t("ui.game.alerts.rolldice"),3000,'','',this.playerID);
                    return;
                }

                let tables = abakLib.convertCheckersMap(this.G.checkers_map); 
                if (this.G.checkers_map[idchecker-1].column<26)
                    idchecker = tables[1][this.G.checkers_map[idchecker-1].column][abakLib.ultima_piedra(tables[0],this.G.checkers_map[idchecker-1].column)];
                

                var vMoves = abakLib.validMoves(this.G.checkers_map,this.G.diceAvailable,this.G.generalDirection,idchecker,this.playerID);

                // Valid Moves threw Error, process them & comunicate them.
                if (vMoves.reason) {
                    this.showToast(this.t("ui.game.alerts."+vMoves.reason),3000);
                    this.selectedChecker=0;
                    // When the player has a guard semi-captured, and tried to click a checker not in the listo of possibilities
                    // show an arrow to reflect the checkers that must be pressed.
                    if (vMoves.reason=='endguardmove') {
                        for (let i in vMoves.points) {
                            this.checkersMarks[i]={                                
                                    position:[vMoves.points[i][0][0],vMoves.points[i][0][1],vMoves.points[i][1]],
                                    cclass:'playthis'+0,
                                    dice: vMoves.points[i][0][2],
                                    checker_class: this.G.checkers_map[idchecker-1].cclass,
                                 }
                            

                        }
                        setTimeout(()=>{this.resetCheckerMark();},3000);
                    }
                    return;
                }                
    
                this.selectedChecker=idchecker;

                //console.log(vMoves);
                if (vMoves.movimientos.length==1 && this.preferences.acelerators.autoMoveOnOneValid) {
                    let move = vMoves.movimientos[0];                    
                    let previousCurrentMovements = this.G.currentMovements;
                    let target = {
                        idchecker: idchecker,
                        column: move.column,
                        height: move.height,
                        action: move.action,
                        dice: move.dice,
                        druidAction: move.druidAction,
                        direction: move.direction
                    };
                    this.moves.clickTarget(target); 
                    this.selectedChecker = 0;
                    if (this.preferences.acelerators.autoDoneOnLastValid) {
                        if (this.G.maxMovements==(previousCurrentMovements+1)) {
                            let self = this;
                            setTimeout(()=> {self.moves.clickDone();},300);                            
                        }
                    }
                    return;
                }
                
                var i=0;

                let validMoveOutside = false;

                for (let mid in vMoves.movimientos) {
                    let move = vMoves.movimientos[mid];
                    let column = Math.max(Math.min(move.column,25),0);
                    this.targetPositions[i]={
                        idchecker: idchecker,
                        column: column,
                        height: move.height,
                        action: move.action,
                        dice: move.dice,
                        druidAction: move.druidAction,
                        direction: move.direction
                    };
                    if (column==25 || column==0) {
                        validMoveOutside = true;
                    }
                    i++;
                }

                if (this.preferences.helpers.showInvalidMoves) {
                    for (let mid in vMoves.noMovimientos) {
                        let move = vMoves.noMovimientos[mid];
                        let column = Math.max(Math.min(move.column,25),0);

                        if (!(validMoveOutside && (column == 0 || column == 25))) {
                            this.targetPositions[i]={
                                idchecker: idchecker,
                                column: column,
                                height: move.height,
                                action: move.action,
                                druidAction: move.druidAction,
                                reason: move.reason,
                                dice: move.dice,
                                direction: move.direction
                            };

                        }
                        i++;
                    }                
                }
            },
            clickTarget: function(targetID) {    
                this.localTimers.lastMove=this.localTimers.lastClick=Date.now();                
                let target = this.targetPositions[targetID];
                let previousCurrentMovements = this.G.currentMovements;
                this.resetTargetPositions();
                if (target.action == "move") {
                    this.moves.clickTarget(target);                                    
                    if (this.preferences.acelerators.autoDoneOnLastValid) {
                        if (this.G.maxMovements==previousCurrentMovements+1) {
                            this.moves.clickDone();
                        }
                    }
                }
                else {
                    this.showToast(this.t("ui.game.alerts."+target.reason),3000);
                }                                
                this.$emit("action-event");
            },
            updateLastSeen,
            repeatedAction: function(action) {
                if (this.lastaction.action==action) {                    
                    let timeDiff = (new Date().getTime() - this.lastaction.time.getTime())/1000;
                    if (timeDiff<1) {                        
                        //console.log("repeated!");
                        return true;
                    }
                }                
                this.lastaction.action=action;
                this.lastaction.time = new Date();
                return false;
            },
            clickRoll: function() {
                if (this.repeatedAction("roll")) return;
                this.updateLastSeen('game',{ id:this.matchID, type:this.G.type, opponent: this.G.opponent });
                if (this.preferences.helpers.showAdvices ) {
                    try {
                        this.tips.tips = this.checkAvailableTips();
                        this.flashAvailableTips();
                    }
                    catch(error) {
                        console.log(error);
                    }
                }
                this.localTimers.lastMove=this.localTimers.lastClick=this.localTimers.lastRoll=Date.now();
                this.resetTargetPositions();
                this.moves.clickRoll();     
                // Set Assert TimeOut Timer that will be clear if the phase changes.
                if (this.getOpponent.type=="HUMAN") this.assertMoveTimeOutId = setTimeout(()=> {
                    this.showToast(this.t("Error, Reloading."),3000);
                    setTimeout(()=>{location.reload()},3000);
                },ASSERT_MOVE_TIMEOUT);
                this.$emit("action-event");
            },
            clickRollStart: function() {
                if (this.repeatedAction("roll-start")) return;
                this.updateLastSeen('game',{ id:this.matchID, type:this.G.type, opponent: this.G.opponent });
                this.localTimers.lastMove=this.localTimers.lastClick=this.localTimers.lastRoll=Date.now();
                this.moves.clickRollStartDie();
                this.$emit("action-event");
            },
            clickDone: function(forceDone) {
                // Set Assert TimeOut Timer that will be clear if the phase changes.
                if (this.repeatedAction("done") && !forceDone ) return;
                if (this.getOpponent.type=="HUMAN") this.assertMoveTimeOutId = setTimeout(()=> {location.reload();},ASSERT_MOVE_TIMEOUT);
                this.resetTargetPositions();
                this.localTimers.lastClick=Date.now();
                let isFinished = abakLib.finishedGame(this.G.checkers_map);
                if (isFinished || this.G.currentMovements == this.G.maxMovements) {
                    this.localTimers.lastMove=this.localTimers.lastRoll=0;
                    this.moves.clickDone();
                }
                else {
                    let validMoves = abakLib.checkersWithValidMoves(this.G.checkers_map,this.G.diceAvailable,this.playerID,this.G.generalDirection);
                    //console.log(validMoves);
                    let i=0;

                    /* Player has not taken the correct path, must undo and show the sampleMove */
                    if ((validMoves.length==0)) {                        
                        this.clickUndo(true);
                        this.showToast(this.t("ui.game.alerts.betterpath"),1500);

                        setTimeout( ()=> {
                            let checkers_map = this.G.checkers_map;
                            let diceA = this.G.diceRoll.length == 4 ? [this.G.diceRoll[0],this.G.diceRoll[0],this.G.diceRoll[0],this.G.diceRoll[0]]: [this.G.diceRoll[0],this.G.diceRoll[1],0,0] ;
                            let maxMoves = abakLib.maxMoves(checkers_map,diceA,this.ctx.currentPlayer);                        
                            let LocalSampleMoves = abakLib.IAArrayMovesToJSON(maxMoves[1][0]);
                            for (let m in LocalSampleMoves) {
                                //console.log(LocalSampleMoves[m]);
                                let move = LocalSampleMoves[m];
                                let checker = abakLib.findCheckerById(move.checker_id);
                                //console.log(abakLib.findCheckerById(this.getCheckersMap,move.checker_id));
                                //let realStartColumn = move.startColumn > 25 ? 13 : move.startColumn;
                                this.checkersMarks[i]={                                
                                    position:[move.startColumn,move.startHeight,move.endColumn],
                                    cclass:'playthis'+m,
                                    dice: move.dice,
                                    checker_class: checker.cclass,
                                 }
                                i++;
                            }
                            let vt = this;
                            setTimeout(()=>{vt.resetCheckerMark()},3000);
                        },1500);
                        //let lastCM = this.G.turnslog.log[this.G.turnslog.log.length-1].checkers_map;
                        return;
                    }
                    /* Only show possible moves if there are just a few.  */
                    
                    else if (validMoves.length<=4) {
                        //console.log(validMoves);
                        for (let v in validMoves) {
                            if (i==4) {
                                break;
                            }
                            i++;
                            this.checkersMarks[i]={
                                position:[validMoves[v].column,validMoves[v].height],
                                cclass:'alert1'}
                        }
                        let vt = this;
                        setTimeout(()=>{vt.resetCheckerMark()},1000);
                    }
                    this.showToast(this.t("ui.game.alerts.movelimit"),1000);
                }
                this.$emit("action-event");
            },
            clickUndo(undoAll,firstcall=true) {
                if (this.repeatedAction("undo") && firstcall) return;
                this.resetTargetPositions();
                if (this.G.currentMovements==0 && firstcall) {
                    this.showToast(this.t("ui.game.alerts.nomovestoundo"),2000);
                    return;
                }
                this.localTimers.lastMove=this.localTimers.lastClick=Date.now();
                this.oldCM = this.getCheckersMap.slice();
                this.client.undo();
                //let vt = this;

                if (this.G.currentMovements>1 && undoAll) {
                    setTimeout( () => { this.clickUndo(true,false);} , 200);
                }
                this.$emit("action-event");
                /*
                setTimeout( () => {
                    let newCM = vt.getCheckersMap.slice();
                    for (let c in vt.oldCM) {
                        if (vt.oldCM[c].column != newCM[c].column ||
                            vt.oldCM[c].height != newCM[c].height)
                                { 
                                    if (undoAll) this.clickUndo(true,false);
                                }
                    }
                },100);
                */
            },
            attemptDouble: function() {
                let otherPlayer=(parseInt(this.ctx.currentPlayer)+1)%2;
                //console.log("attemptdouble",this.G.doubleCube.holder,otherPlayer);
                if (
                    this.G.doubleCube.holder != otherPlayer && 
                    this.getplayerStage=='rollorDouble' &&
                    this.ctx.phase == 'play' && 
                    this.G.type != 'practice'
                    ) 
                    this.showCubeConfirmation=true;
                else {
                    if (this.G.doubleCube.holder != otherPlayer) this.showToast(this.t("ui.game.alerts.notyourcube"),3000);                 
                    if (this.getplayerStage=='rollorDouble') this.showToast(this.t("ui.game.alerts.cubebeforeroll"),3000);                 
                    if (this.ctx.phase == 'play') this.showToast(this.t("ui.game.alerts.cubebeforeroll"),3000);
                    if (this.G.type == 'practice') this.showToast(this.t("ui.game.alerts.cubenotpractice"),3000);
                }
                this.$emit("action-event");
            },
            clickDouble: function() {
                if (this.repeatedAction("double")) return;
                this.localTimers.lastMove=this.localTimers.lastClick=Date.now();
                //console.log("Click Double");
                let otherPlayer=(parseInt(this.ctx.currentPlayer)+1)%2;
                //console.log(this.G.doubleCube.holder,parseInt(this.ctx.currentPlayer),otherPlayer);
                if ( this.G.doubleCube.holder != otherPlayer ) {
                    this.moves.clickDouble();
                }
                else {
                    //console.log("showtoast");
                    this.showToast(this.t("ui.game.alerts.notyourcube"),1000);                 
                }                
                this.$emit("action-event");
            },
            resetTargetPositions: function() {
                for (var st in [0,1,2,3,4]) { 
                    this.targetPositions[st] = null;
                    this.selectedChecker=0;
                }
            },
            resetCheckerMark: function(i=null) {
                if (i) {
                    this.checkersMarks[i]=null;
                    return;
                }
                for (let c in this.checkersMarks)
                this.checkersMarks[c]= null;
            },
            showToast: function(text,time,type="default",image=null,playerID=null) {
                this.toast.active = true;
                this.toast.text = text;                
                this.toast.type = type;
                this.toast.image = image;
                this.toast.playerID = playerID;
                setTimeout(()=>this.toast.active=false,time);
            },
            clickClaim: function() {
                if (this.repeatedAction("claim")) return;
                if (this.canclaim)
                    this.showclaim=true;
                else {
                    this.showToast("Wait 30 seconds to claim the game.",1000)
                }
            },
            checkclaim: function() {
                let canclaim = false;  
                /* Para poder hacer clases se puede suspdender */
                if (this.debug.dontClaim) return false;
                
                let timers = this.G.timers;

                let bothHaveConnected = (typeof this.matchData[0].isConnected != "undefined") && (typeof this.matchData[1].isConnected != "undefined");
                let bothAreConnected = (typeof this.matchData[0].isConnected) && (typeof this.matchData[1].isConnected);

                /* If both have not connected, cant claim */        
                if (!bothHaveConnected) return false;

                /* If opponent connected and wasnt connected, refresh timer */
                if (this.playerID=="0" 
                    && this.matchData[1].isConnected 
                    && this.localTimers.connected[1] == 0) 
                        this.localTimers.connected[1] = Date.now();


                let now = Date.now() + this.timeSyncDifference;
                
                if (this.getplayerStage=='defineWhoStarts') {
                    let maxTimeCompare = Math.max(
                        this.localTimers.connected[0],
                        this.localTimers.connected[1],
                        this.G.timers.gameStartedAt
                        );
                    let timeFromLastConnected = (now - maxTimeCompare)/1000;
                    let myRollDone = this.getDiceRoll[this.playerID] > 0;
                    if (!bothAreConnected || 
                        (timeFromLastConnected > this.G.timers.maxTimeOut && myRollDone ) ) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    let compareDate = timers.turnStartedAt;
                    if (compareDate && compareDate>0 && 
                        this.getplayerStage!='endedMatch' 
                        && this.getOpponent.type != "CPU") {
                        if (this.ctx.currentPlayer != this.playerID 
                            && this.getplayerStage !='respondToDouble') {    
                            if ((now - timers.turnStartedAt)/1000 > this.G.timers.maxTimeOut ) {
                                canclaim =true;
                            }
                        } 
                    }
                    return canclaim;
                }
            },
            checkAvailableTips: function() {
                //console.log("available tips.");
                let tables = abakLib.convertCheckersMap(this.G.checkers_map);
                let color = parseInt(this.playerID)+1;
                let preTips = abakLib.availableTips(tables[0],tables[1],color);
                var checkerTips = Array(30).fill(null).map(function() { return { tips:[] , show:false } });
                for (let t in preTips) {
                    //console.log(t,preTips[t].checkerId);
                    let index = parseInt(preTips[t].checkerId);
                    checkerTips[index].tips.push({ type: preTips[t].type });
                }
                return {ammount:preTips.length, list:preTips,checkers:checkerTips};
            },

            flashAvailableTips: function(index=null) {
                //console.log("flash",index);
                if (!index) {
                    for (let t in this.tips.tips.checkers) {
                        if (this.tips.tips.checkers[t].tips.length>0) {
                            //console.log("flashing:"+t);
                            this.tips.tips.checkers[t].show = true;
                            setTimeout( ()=>this.tips.tips.checkers[t].show = false,2500);
                        }
                    }
                }
                else {
                    this.tips.tips.checkers[index].show = true;
                    setTimeout( ()=>this.tips.tips.checkers[index].show = false,2500);
                }
            },
            updateTimers: function() {                                             
                this.canclaim=this.checkclaim();                 
                this.localTimers.turnTime = this.localTimers.turnstarted > 0 ? parseInt((Date.now() - this.localTimers.turnstarted)/1000) : 0;
                claimTimeOut = setTimeout(()=> {this.updateTimers()},500);
            },            

            startedUp: function() {
                this.starting = false;
            }

        },
        watch: {
            boardImageSize: function () {
                //console.log("Board Image Size Changes in the Board");
                this.starting = true;
                setTimeout(()=> {this.startedUp()},1000);
            },
            /* 
            If playerStage Changes, update timer turnstarted, and clear timeout timer (that asserts the roll.)
            */
            getplayerStage: function(stage) {                 
                
                if (this.getOpponent.type=="HUMAN") clearTimeout(this.assertMoveTimeOutId);
                if (stage=='rollorDouble' || typeof stage =='undefined' || stage== 'defineWhoStarts' )
                    this.localTimers.turnstarted = Date.now();                
            },       
            getCurrentPlayer: function() {
                /* If is Visitor, update last-seen on currentplayer */
                //console.log('check last seen',this.playerID);
                if (this.playerID===null) {
                    //console.log("Visitor Last Seen 1");
                    //console.log(Date.now(),this.localTimers.lastVisitorUpdated,(Date.now()-this.localTimers.lastVisitorUpdated)/1000>20);
                    if ((Date.now()-this.localTimers.lastVisitorUpdated)/1000>20) {
                        //console.log("Visitor Last Seen 2");
                        this.updateLastSeen('visitor', { id: this.matchID , type:this.G.type, opponent: this.G.opponent  });
                        this.localTimers.lastVisitorUpdated = Date.now();

                    }
                }                
            },
            getOpponentID: function(newId,oldId) {
                    //console.log("New ID Opponent",oldId,newId);
                    if (oldId=="" && typeof newId == "number")
                        this.localTimers.turnstarted = Date.now();
            },  
            timeTravelState: function(newTT,oldTT) {
                //console.log("TTChanged");
                if (!oldTT && newTT) this.resetTargetPositions();
            }
        },        
        mounted: function() {
            this.$nextTick(function () {
                this.updateTimers();
                /* Setup My Connecting time */
                this.localTimers.connected[this.playerID] = Date.now();
                /* Player2 wont have a connected time of player one, so we assume now */
                /* && we Start Turn Timer */
                if (this.playerID=='1') {
                    this.localTimers.connected[0] = Date.now();
                    this.localTimers.turnstarted = Date.now();
                }
        
                setTimeout(()=> {
                    this.startedUp();
                    },1000);
            });
        },
        unmounted: function() {
            clearTimeout(claimTimeOut);
        }        
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

	.contenedor-tabla {
        position:relative;
        background:v-bind('theme.board.background');
        /*filter: drop-shadow(2px 4px 6px black);*/
        /*border-top: 0.2em solid black;*/
        //border-bottom: 0.2em solid black;
        border: 0.3em solid v-bind('theme.board.border');
        /* box-sizing: border-box; */
        border-radius: 0.5em;
        margin:auto;
        div {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
        }

        img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
        }
    }    

    .pos1, .pos2 , .soldado , .dado {
        position:absolute;
    }
              
    
    .pointsBox1,.pointsBox0 {
        position:absolute;
        width:6.55%;
        height:10%;        
        display:flex;
        flex-direction: column;
        align-items: center;

        div {
            width:100%;
            text-align: center;
        }
        .distanceBox {
            flex-grow: 1;            
            display: flex;
            justify-content: center;
            align-items: center;
            font-size:0.7em;
            background:#33333366;
        }


    }

    .pointsBox0 {
        background:v-bind('theme.checkers[ preferences.ui.checkersColor=="1" ?  0 : 1 ].background');
        color:v-bind('theme.checkers[ preferences.ui.checkersColor=="1" ?  0 : 1  ].color');
        left:0;
        top:5%;
    }

    .pointsBox1 {
        background:v-bind('theme.checkers[ preferences.ui.checkersColor=="1" ?  1 : 0  ].background');
        color:v-bind('theme.checkers[ preferences.ui.checkersColor=="1" ?  1 : 0 ].color');;
        left:0;
        top:85%;
    }


</style>
