<template>
    <div 
        class="TurnLogContainer" 
        :class="{ pVertical : screenMode == 'vertical' }"
        v-if="!analyseMoveOn" 
        :style="{ 
            height: screenMode != 'vertical' ? boardSize*0.45 + 'px' : boardSize*0.55 + 'px' , 
            fontSize:  screenMode == 'vertical' ? '2em' : '1em'
            }"        
        >
            
        <div class="turnLogTitle" > 
            <div>{{$t('ui.game.subject.turnslog')}}  </div> 
            <!--div @click="$emit('analyse-game')" v-if="!analyseGameOn"
                class="gameStatsButton"
                >
                 <div>{{t('ui.game.action.analyse')}}</div> 
                 <img style='filter:invert(1);width:1em;' :src="require(`@/assets/img/buttons/stats.svg`)">
            </div --> 
            <div v-if='firstMoveDone' @click="clickItem(0,share=true)"
                class="gameStatsButton"
                >
                 <div>{{$t('lobby.action.share')}}</div> 
                 <img style='width:1em;' :src="require(`@/assets/img/buttons/share.svg`)">
            </div> 
            <!-- div class="shareMessageTurnsLog" v-if="shareMessage!=''"> 
                <div>{{ shareMessage }}</div>                
            </div -->
        </div>
        
        <div class="noLogs" v-if="logs.length==0"> 
            <div>{{$t('ui.game.messages.nologs')}}</div>
            <div> 
                <div> 
                    {{$t('ui.game.messages.clicklogformore')}} 
                    <div class="player1" style="align-items:center;padding:0.2em;margin:0.2em;">
                        <div class="logItemId">1</div>
                        <div style="display:flex;align-items:center;">
                            <Dice 
                                :dClass="diceColor(playerID)" 
                                :colorClases="theme.dice" 
                                :dNumber="1"
                            />
                            <Dice 
                                :dClass="diceColor(playerID)" 
                                :colorClases="theme.dice" 
                                :dNumber="1"
                            />

                        </div>                        
                        <div>
                            2                            
                        </div>
                        <div>
                            1.2s                            
                        </div>
                    </div>
                    {{$t('ui.game.messages.logdescription')}} 

                </div>
                <div> {{$t('ui.game.messages.requestmovestats')}}
                    <div><img style='filter:invert(1)' :src="require(`@/assets/img/buttons/stats.svg`)"></div>
                </div>
            </div>
        </div>
        
        <div 
            v-for="(log,i) in logs" :key="'logItem'+i"
            style="display:flex;font-size:0.7em;border-bottom:1px solid white;align-items:center;"                
            :class="{ selected: isSelected(i) }"
            :ref="'logItem'+i"
            :id ="'logItem'+i"
            

        >         

            <!-- ************************************************************************************************************------>
            <!-- Doubling Type **************************************************************************************************-->
            <!-- ************************************************************************************************************------>

            <div v-if="['double_accepted','double_rejected','double_attempt'].includes(log.type)" 
                :class = "{ player1 : log.playerID==checkersColor , player2: log.playerID!=checkersColor }" >  
                <div class="logItemId">{{logs.length-i}}</div>              
                <div v-if="log.type=='double_attempt'" style="display:flex;font-size:0.7em;flex-grow: 1;"> {{$t('ui.game.action.attemptdouble')}} </div>
                <div v-if="log.type=='double_attempt'" style="display:flex;margin-right:0.5em;">

                    <img style="width:1em;margin:0"
                                :src="require(`@/assets/img/dados/${theme.path}dd${log.doubleCube.value}.svg`)"
                            >
                    ↦
                    <img style="width:1em;margin:0"
                                :src="require(`@/assets/img/dados/${theme.path}dd${log.doubleCube.value*2}.svg`)"
                            >

                        </div>
                <div style="font-size:0.7em;flex-grow: 1;" v-if="log.type=='double_accepted'" >{{$t('ui.game.action.doubleaccepted')}}</div>
                <div style="font-size:0.7em;flex-grow: 1;" v-if="log.type=='double_rejected'">{{$t('ui.game.action.doublerejected')}}</div>
                <div>{{parseFloat(log.turnTime).toFixed(1)}}s</div>                  
            </div>


            <!-- ************************************************************************************************************------>
            <!-- Rolling Start ************** *******************************************************************************------>
            <!-- ************************************************************************************************************------>


            <div v-if="['roll_start_roll','roll_start_equals','roll_start_starts'].includes(log.type)"                                 
                style="width:100%;box-sizing:border-box;"
                >  
                <div v-if="log.type=='roll_start_roll'" style="display:flex;"
                    class = "turnBox" 
                    :class ="{ player1 : log.playerID==checkersColor , player2: log.playerID!=checkersColor }" 
                >
                    <!--img style="width:1em;margin:0"
                                :src="require(`@/assets/img/dados/dd${log.doubleCube.value}.svg`)"
                            -->
                    <div class="logItemId">{{logs.length-i}}</div>    
                    <div style="flex-grow: 1;font-size:0.7em"> {{$t('ui.game.action.startroll')}}</div>          
                    <div></div>
                    <Dice 
                        :dClass="diceColor(log.playerID)" 
                        :colorClases="theme.dice" 
                        :dNumber="log.diceRoll"
                    />
                </div>
                <div v-if="log.type=='roll_start_equals'" class = "turnBox"> 
                    <div class="logItemId">{{logs.length-i}}</div>              
                    <div style="flex-grow: 1;font-size:0.7em">{{$t('ui.game.action.startequals')}}</div>
                    <div>
                        <Dice 
                        :dClass="3" 
                        :colorClases="theme.dice" 
                        :dNumber="log.diceRoll[0]"
                        />
                        <Dice 
                            :dClass="3" 
                            :colorClases="theme.dice" 
                            :dNumber="log.diceRoll[1]"
                        />
                    </div>
                </div>
                <div v-if="log.type=='roll_start_starts'" class = "turnBox"
                    :class ="{ player1 : log.playerID==checkersColor , player2: log.playerID!=checkersColor }" 
                > 
                    <div class="logItemId">{{logs.length-i}}</div>              
                    <div style="flex-grow: 1;font-size:0.7em">{{$t('ui.game.action.startstarts')}}</div>
                    <div>
                        <Dice 
                            :dClass="diceColor(log.playerID)" 
                            :colorClases="theme.dice" 
                            :dNumber="log.diceRoll[0]"
                        />
                        <Dice 
                            :dClass="diceColor(log.playerID)" 
                            :colorClases="theme.dice" 
                            :dNumber="log.diceRoll[1]"
                        />

                    </div>
                </div>
                  
            </div>


            <!-- ************************************************************************************************************------>
            <!-- Move Type  -->
            <!-- ************************************************************************************************************------>

            <div v-if="log.type=='move'" :class = "{ player1 : log.playerID==checkersColor , player2: log.playerID!=checkersColor }">
                    <div class="logItemId">{{logs.length-i}}</div>
                    <div style="display:flex;" @click="clickItem(i)">
                        <div>
                            <Dice 
                                :dClass="diceColor(log.playerID)" 
                                :colorClases="theme.dice" 
                                :dNumber="log.diceRoll[0]"
                            />
                        </div>
                        <div>
                            <Dice 
                                :dClass="diceColor(log.playerID)" 
                                :colorClases="theme.dice" 
                                :dNumber="log.diceRoll[1]"
                            />
                        </div>
                    </div>                    
                    <div v-if="!isSelected(i)" @click="clickItem(i)"> {{log.moves.length}} </div>
                    <div @click="clickItem(i)" >{{parseFloat(log.turnTime).toFixed(1)}}s</div>
                    <div v-if="isSelected(i) && log.moves.length>0" class="statsButton" @click="$emit('analyse-move',{ turnID: this.logs.length-i-1 });" >
                        <img :src="require(`@/assets/img/buttons/stats.svg`)">                    
                    </div>
                    <div 
                        v-if="isSelected(i)"
                        style="display:flex;flex-wrap: wrap;align-items: center;width:100%;">                
                        <div 
                            v-for="(m,j) in log.moves" :key="'logItemMove'+i+j"  
                            style="
                                display: flex;
                                align-items: center;
                                flex-wrap:wrap;
                                margin:0.2em;
                                padding:0.1em 0.3em;
                                border-radius:0.5em;"
                        >
                            <CheckerSVG style="width:1.5em;margin:0 0.3em 0 0;" 
                                :cclass="checkers_map[m.idchecker-1].cclass"
                                :colors="theme.checkers[diceColor(checkers_map[m.idchecker-1].owner)]"
                                :selected="false"
                            />    
                                
                            <div style="display:flex;">
                                <div>{{m.startColumn}}</div>
                                <div>↦</div>
                                <div>{{m.endColumn}}</div>
                            </div>
                        </div>
                    </div>
            </div>

            <!--- End type --------------->

            <div v-if="log.type=='end'">
                End
            </div>

            
        </div>
    </div>
</template>

<script>

    import CheckerSVG from '../elements/CheckerSVG.vue';
    import Dice from '../elements/Dice.vue';

	export default {
		name: 'TurnsLog',
        props: [
            'playerID',
            'turnsLogData',
            'checkers_map',
            'timeTravel',
            'analyseMoveOn',
            'analyseGameOn',
            'players',
            'checkersColor',
            'screenMode',
            'boardSize',
            'theme'],
        data: function() {
            return {
                shareMessage:"",
                firstMoveDone:false
            }
        },
        components: {
            CheckerSVG,Dice
        },
        computed: {
            logs: function() {
                return this.turnsLogData.log.slice().reverse();
            },
            turnsAmmount: function () {
                return this.turnsLogData.log.length;
            },
            normalizedPlayerID: function() {
                let pID = this.playerID ? this.playerID : 0;
                let otherPlayer = (parseInt(this.playerID) + 1)%2;                
                if (this.checkersColor==1) {
                    return otherPlayer;
                }
                return pID;
            },
            timeTravelTurnId: function() {
                return this.timeTravel.position;
            },
        },
        emits: ['set-time-travel','analyse-move','analyse-game','share-current'],
        methods: {	

            diceColor: function(currentPlayer) {
                let playerID = this.normalizedPlayerID;
                let playerValue = (currentPlayer!=playerID) ? 1 : 0;
                return playerValue;
            },
            clickItem: function(i,share=false) {
                let turnID = this.logs.length-i-1;
                if (share) {
                    this.$emit('share-current',{ turnID: turnID });                    
                }
                if (this.timeTravel.position != turnID) {
                    this.$emit('set-time-travel',{ turnID: turnID });
                }
            },
            isSelected: function(i) {
                return this.timeTravel.on==true && this.timeTravel.position==(this.logs.length-i-1);
            }
            
        },
        watch: {
            timeTravelTurnId:function() {
                //console.log(this.timeTravel.position);
                if (this.timeTravel.position && !this.analyseMoveOn) {
                    let idElement = "logItem"+(this.logs.length - this.timeTravel.position);
                    let logElement = document.getElementById(idElement);
                    if (logElement.scrollIntoView)
                        logElement.scrollIntoView({ block: "center", inline: "nearest"});
                }
                //console.log("time travel turn changed");
            },
            logs:function() {
                if (this.firstMoveDone) return; 
                if (this.logs.length==0) return;
                if (this.logs[0].type=="move") this.firstMoveDone=true;
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }       
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .turnLogTitle {
        position:relative;
        text-align: center;
        font-size:1.2em;
        border-bottom: 0.1em solid $c13;        
    }

    .player1, .player2, .turnBox {
        display:flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding:0em 0.3em;
        width:100%;
        box-sizing: border-box;
    }

    .turnBox {
        div {
            display:flex;            
        }
        background:$c8;
    }

    .player1 {
        background:v-bind('theme.checkers[0].background');
        color:v-bind('theme.checkers[0].color');
        color:$checkerwhite-color;
    }

    .player2 {
        background:v-bind('theme.checkers[1].background');
        color:v-bind('theme.checkers[1].color');
    }

    .default {
        background-color:$checkerblack;
        padding:2%;        
        color:white;
    }

    img {
        width:3em;
        margin:0.5em;
    }

    .statsButton {
        background:$c6;
        display: flex;
        border-radius: 0.5em;
        margin:0.3em;
        img {
            width:1.2em;
            filter:invert(1);            
            margin:0.2em;
        }
    }

    .logItemId {
        padding:0.2em;
        background-color:rgb(0, 0, 0);
        color:rgb(255, 255, 255);
        border-radius: 1em;
        min-width: 1.5em;        
        margin-right:0.5em;
        text-align: center;
        justify-content: center;
    }

    .TurnLogContainer {
        overflow:auto;
        width:10em;
        background-color:v-bind('theme.elements.box1.background');
        margin-left:0.5em;
    }

    .selected {
        background-color:#3a3709;

    }


    .gameStatsButton {
        display:flex;
        align-items:center;
        justify-content:center;
        font-size:0.6em;
        width:50%;        
        background-color:v-bind('theme.buttons.default.background');
        margin:0.3em 0 0.3em 25%;
        border-radius: 0.3em;
        box-shadow: $box-shadow-normal;
        padding:0 0.5em;
        cursor: pointer;
    }
    
    .noLogs {
        display: flex;
        flex-direction: column;
        align-items: center;
        height:18em;

        >div:nth-child(2) {
            
            font-size: 0.6em;
            padding:0.2em;

            >div {
                text-align: center;
                background-color:v-bind('theme.elements.box2.background');
                color:v-bind('theme.elements.box2.color');
                border-radius: 0.5em;
                margin:0.5em;
                box-sizing: border-box;
                padding: 0.5em;
            }
        }
    }

    .pVertical {
        width:95%;
        max-height: 15em;
    }

    .shareMessageTurnsLog {
        background-color:$colorno;        
        position:absolute;
        z-index: 1000;
        min-height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2em 1em;
        margin:0.3em;
        border-radius: 1em;
    }

    .pVertical .shareMessageTurnsLog {
        padding: 1em 1em;
        min-height: auto;
        font-size: 0.8em;
    }

  /* width */
  ::-webkit-scrollbar {
  width: 0.6em;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: #00000044;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
  background: v-bind('theme.elements.scroll.bar');
  border-radius: 0.5em;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  background: v-bind('theme.elements.scroll.hover');
  }

</style>
