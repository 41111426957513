<template>
    <div class="analysisHelp" :style="'font-size:'+fontsize">                 
        <div class="moveAnalysisTitle" >
            <div>{{t('ui.game.messages.analysisindicators')}}</div>
            <div v-if="showCloseButton" @click="$emit('close-analysis-help')" >⤫</div>
        </div>
        <ul>
            <li>
                <div>W</div>
                <div>{{t('ui.game.messages.analysisw')}}</div>
            </li>                 
            <li v-if="type=='move'">
                <div>PX</div>
                <div>{{t('ui.game.messages.analysisp')}}</div>
            </li>
            <li> 
                <div>E</div>
                <div><b>Equity</b> {{t('ui.game.messages.analysiswequitydescription')}}
                    <ul>
                        <li><span>0</span> {{t('ui.game.messages.analysiswequityej1')}}</li>    
                        <li><span>1</span> {{t('ui.game.messages.analysiswequityej2')}}</li>    
                        <li><span>-2</span> {{t('ui.game.messages.analysiswequityej3')}}</li>    
                    </ul>      
                    <div> {{t('ui.game.messages.mathwise')}}:</div>
                    <div class="equityFormula">
                        <div> Eₙ = ƒ(W₀,P₁,P₂,P₃) </div>                            
                        <div> W₀×P₁×1 + W₀×P₂×2 + W₀×P₃×3 </div>
                        <div> W₀ = W - 0.5 </div>
                    </div>                       
                </div>
            </li>
            <li>
                <div>EL</div>
                <div> {{t('ui.game.messages.analysismovelost')}}</div>
            </li>
            <li >
                <div>C</div>
                <div> {{t('ui.game.messages.analysismovemoves')}}</div>
            </li>
        </ul>
    </div>
</template>

<script>

    import { useI18n } from 'vue-i18n'


	export default {
		name: 'AnalysisHelp',
        props: {
            type:String,
            fontsize:String,
            showCloseButton: {
                type:Boolean,
                default:true
            },
            boxWidth: {
                type:String,
                default:'10em'
            }
        },
        data: function() {
            return {
            }
        },
        computed: {
        },
        methods: {	

        },
        emits: ['close-analysis-help'],
        watch: {
            active: function () {               
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },           
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


    .analysisHelp {
        max-height:19em;
        width:10em;
        font-size: 0.85em;
        padding: 0.3em 0 0.3em 0.3em;
        box-sizing: border-box;
        >ul {
            padding-inline-start: 0em;
        }

        >ul>li {            
            display:flex;
            align-items: flex-start;
            padding:0.3em;
            font-size: 0.5em;

            div:nth-child(1) {
                background:$c3;    
                color:$c0;            
                padding: 0.2em;
                margin: 0.2em;                
                border-radius: 0.5em;            
            }            
        }
    }

    .moveAnalysisTitle {
        font-size:0.7em;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.2em solid $c11;
    }   

    .equityFormula {
        >div {
            background-color:$c0 !important;
            color:$c1 !important;
            padding:0.2em !important;
            border-radius: 0.2em !important;
            width: 70% !important;
            margin:0.3em 0 0 15% !important;
            text-align: center;
        }
    }
    
    @media (orientation: portrait) {
        .moveAnalysisTitle {
            font-size:1.5em;
        }
        .analysisHelp {
            width:90% !important;
            margin-left: 5%;
            margin-top:1em;
            overflow: auto;
            max-height: 10em !important;
            border-bottom: 0.1em solid $c12;
            >ul {
                font-size: 1.5em;
            }
        }


    }

</style>
