export default {
  "hall": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名人堂"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名人堂"])}
  },
  "piepagina": {
    "texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> 是一项史诗般的非盈利单人努力，旨在向人类传播这些惊人的规则。<br> 由智利软件开发者 <span>Samy Garib</span> 发明、开发、维护和资助。"])},
    "privacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])}
  },
  "lobby": {
    "subject": {
      "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手"])},
      "human": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人类"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务器"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["级别"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规则"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["颜色"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教程"])},
      "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教程"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左边"])},
      "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朋友"])},
      "players": {
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线"])},
        "invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐身玩家"])},
        "last_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "prefered_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偏好类型"])},
        "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离线"])},
        "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏中"])},
        "atlobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大厅中"])}
      },
      "friendship": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待处理"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停"])},
        "friendship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友谊"])},
        "notyet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你还没有朋友。 你可以从在线玩家部分请求友谊。"])},
        "requestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送友谊请求？"])},
        "newrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有一个新的友谊请求"])},
        "onlinefriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线好友"])}
      },
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公开"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比赛"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
      "game_visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏访客"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访客"])}
    },
    "alerts": {
      "nogamestojoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可以加入的游戏。"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak演化西洋双陆棋"])},
    "action": {
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观看"])},
      "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["练习"])},
      "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已玩"])},
      "won": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["胜利"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
      "sharein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享到"])},
      "cpypasteshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制并分享此链接"])},
      "shareabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与朋友分享Abak！"])},
      "shareboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享你的游戏"])},
      "howtoshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该工具将为您创建一个共享元素。你可以添加一个可选描述"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])},
      "contactsocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过您的首选社交网络联系我"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聊天"])}
    },
    "user": {
      "subject": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
        "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计"])},
        "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奖牌"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "howemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存时，您将收到带有验证链接的消息"])},
        "checkvalidationemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须验证您的电子邮件。 您可以尝试再次发送消息。"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])}
      },
      "action": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次发送"])}
      },
      "message": {
        "knownguestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您仅在此设备上注册。使用<b>社交登录</b>创建永久的多设备用户。"])},
        "guestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此用户仅绑定到此设备。如果您想以后恢复此用户，或在不同设备上继续游戏，请提供有效的电子邮件。"])}
      }
    },
    "messages": {
      "newinvitation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏邀请"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您接受，您将在另一场结束后继续此比赛。"])},
        "headding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有一个对战玩家的游戏邀请"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此邀请发送给所有活跃玩家。您的回答是匿名的。"])},
        "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此邀请直接发给您。"])}
      },
      "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可加入的比赛。"])},
      "p2pnomatchesoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的选项"])},
      "playandwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与服务器对战并等待邀请"])},
      "inviteonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向在线对战服务器的玩家发送邀请"])},
      "createhuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建游戏并等待玩家加入"])},
      "error": {
        "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接服务器时出错。"])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以报告此事件"])}
      },
      "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择语言"])},
      "unfinishedgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有游戏进行中。完成它或投降。"])},
      "wontunlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此难度级别无法解锁下一个练习。"])},
      "unlocklevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须解锁此练习级别。"])},
      "inviteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请 $$(player)$$ 玩游戏。"])},
      "nomoreplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可邀请的玩家。"])}
    },
    "hallfame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名人堂"])}
    },
    "chat": {
      "message": {
        "tooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息太短"])}
      }
    },
    "message": {
      "choosepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择练习级别"])},
      "newpracticelevelavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新的练习级别可用。在选择框中选择。"])},
      "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作中。请稍候"])},
      "becomeabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在此App中玩<b>Abak西洋双陆棋</b>和<b>埃及双陆棋</b>。"])},
      "becomeabakerhowto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要<b>解锁</b>所有功能，您必须成为<b>Abaker</b>。完成<b>练习</b>级别，或在高级或专家级别对战中赢得一场 abak 比赛。"])},
      "welcome": {
        "customize1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义用户"])},
        "customize2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改您的用户名和头像。"])},
        "stats1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计"])},
        "stats2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看您的玩家历史"])},
        "hall2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解最好的 Abakers"])},
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有时间看看这些工具。"])}
      },
      "caninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以邀请可用的玩家"])},
      "cantinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成为 Abaker 才能邀请其他玩家"])},
      "noonlineplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有在线玩家"])},
      "onlyabakersorfriendscaninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您不是 Abaker，您不能邀请不是您朋友的玩家。"])},
      "friendship": {
        "requestsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送请求"])},
        "requestexists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求已存在"])},
        "responsesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送响应"])}
      },
      "emptylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空列表"])}
    }
  },
  "contact": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系Abak Evolution"])},
    "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如需联系我，请使用以下方式"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在各种社交网络找到Abak Evolution，并在那里给我写信息。"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者发送电子邮件"])}
  },
  "reglas": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般规则"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加棋子的种类有其后果"])},
      "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个点只能堆叠5个棋子。"])},
      "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个点的最高棋子必须优先移动。"])},
      "porque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两条简单的通用规则被添加到西洋双陆棋中。"])},
      "startpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化起始位置"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加种类极大地改变了游戏。棋子的垂直位置很重要，因此影响了移动顺序。<span>Abak进化</span> 为 <span>经典西洋双陆棋</span> 添加了一个新维度。"])},
      "mismasreglas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak进化</span> 使用了西洋双陆棋的相同规则，但添加了5种士兵。这一小变化带来了显著的后果，并需要两条新的显而易见的自然规则。"])},
      "thisguys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和这些伙伴一起使用西洋双陆棋的相同规则。"])},
      "addsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["它添加了士兵的种类，每种都有不同的特性。"])},
      "startposcontenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak进化</span>的起始位置与西洋双陆棋非常相似，但增加了<span>种类</span>并且有4个棋子在后方。Nackgammon风格。"])},
      "ttd_titulo": null,
      "ttd_texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只需想象可能性"])}
    }
  },
  "pdf": {
    "rules": {
      "generales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这些规则对游戏机制略有改变。注意，一条是相当隐含的，但在经典的飞行棋中不需要强制执行，另一条已经在游戏的某些版本中存在。"])},
      "intro0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution Backgammon</b> 是一种飞行棋变体，增加了5类棋子，每类棋子都有其独特的功能和行为。"])},
      "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本文档解释<b>Abak Evolution Backgammon</b>的规则。需要预先了解经典飞行棋。"])},
      "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在原始规则上有一些小改动，一旦加入这些类别，这些变化就会显得自然和显而易见。"])},
      "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> 增加了5类士兵以及普通士兵。每种类别都有不同的属性。"])},
      "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在"])},
      "intro5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或在棋盘上贴一些你可以在这里找到的贴纸："])},
      "intro10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这些规则可在"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每类士兵都有一套专属属性，改变其行为。"])},
      "start0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起始位置"])},
      "start1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起始位置与飞行棋非常相似，但后部没有两个棋子，而是四个：\n<ul> <li>受伤的（⨯2）起始位置在后方 <span class='etiqueta-posicion'>[p.24-1]</span> <span class='etiqueta-posicion'>[p.23-1]</span>，每个棋子的最大骰子数面可以达到18点。</li>\n<li>Magician与他们一起尽可能地后退 <span class='etiqueta-posicion'>[p.24-2]</span>。它的行动范围是中场，所以最好等待。</li>\n<li>Guard (⨯2) 靠近家 <span class='etiqueta-posicion'>[p.13-3]</span> <span class='etiqueta-posicion'>[p.8-2]</span>，准备形成结构但尚未准备好移动，起始时它们在士兵下方。</li>\n<li>General在家中起始 <span class='etiqueta-posicion'>[p.6-4]</span>，帮助在两个方向上形成结构。</li>\n<li>其余的普通士兵起始位置是： <span class='etiqueta-posicion'>[p.23-2]</span><span class='etiqueta-posicion'>[p.13-1]</span><span class='etiqueta-posicion'>[p.13-2]</span><span class='etiqueta-posicion'>[p.13-4]</span><span class='etiqueta-posicion'>[p.8-1]</span><span class='etiqueta-posicion'>[p.8-3]</span><span class='etiqueta-posicion'>[p.6-1]</span><span class='etiqueta-posicion'>[p.6-2]</span><span class='etiqueta-posicion'>[p.6-3]</span> </li>\n</ul>\n"])},
      "bidimensional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b>将飞行棋变为<b>二维</b>游戏。这意味着棋子在列中的垂直位置很重要，因此<b>移动顺序</b>在回合中成为新因素。"])},
      "imagina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["想象可能性"])},
      "classic_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的队伍在经典飞行棋中的位置"])},
      "millions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相当于Abak Evolution中的上百万种组合。以下是其中的一小部分示例："])},
      "moves_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动顺序"])},
      "drastically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动顺序会极大地改变游戏结果。"])},
      "druid_traps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这种情况下，Magician困住了士兵。"])},
      "odd_captures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这种情况下，奇数受伤者捕获了士兵。"])},
      "encuentra_abak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["怎么玩"])},
      "redes_sociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在社交网络上找到<b>Abak Evolution</b>"])},
      "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载App在手机上玩。在手机的应用商店找到它。"])},
      "play_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开你的网络浏览器并指向这里："])},
      "capturar_guardia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用两个（非guard）士兵击中guard，你必须在一次回合中<b>连续移动</b>时用<b>两者同时</b>击中它。"])},
      "abak_vs_backgammon_titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak vs 经典飞行棋"])},
      "abak_vs_backgammon_cuerpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虽然Abak Evolution与经典飞行棋的唯一区别是增加了类别，但这对游戏产生了重大影响，并带来了两大后果。"])},
      "abak_vs_backgammon_combinaciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak有"])},
      "abak_vs_backgammon_combinaciones2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["场景对于任何给定的经典飞行棋位置"])},
      "subject": {
        "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵们"])}
      }
    }
  },
  "meta": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于Abak进化西洋双陆棋"])},
      "history": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化西洋双陆棋历史"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak诞生于1999年，经过一系列独特的事件和条件。"])}
      },
      "rant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化西洋双陆棋没有达到我的预期"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak没有成为我想象中的热门，但我绝不会放弃这个项目。"])}
      },
      "samy-garib": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib - Abak进化西洋双陆棋"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib是智利的软件开发者，也是Abak进化西洋双陆棋的发明者、设计师和开发者。"])}
      },
      "donate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捐赠以保持Abak进化西洋双陆棋的生存"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化西洋双陆棋是一个免费、无广告的单人项目，需要帮助。"])}
      },
      "thanks-to": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化西洋双陆棋感激一些人。"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["许多人参与了这个项目。从翻译帮助到大量的热情支持，一切都是好的。"])}
      }
    },
    "boardgame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化西洋双陆棋棋盘游戏"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系Abak进化西洋双陆棋"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak在许多社交媒体平台上。查看此处并找到联系我的方式。"])}
    },
    "learn": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习Abak进化西洋双陆棋"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习西洋双陆棋和Abak的资源。这里有PDF规则书、交互式教程等。"])}
    },
    "print": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打印Abak进化西洋双陆棋规则"])}
    },
    "resources": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习Abak进化的资源"])}
    },
    "rules": {
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵 - Abak进化西洋双陆棋职业"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他们的角色与普通西洋双陆棋士兵相同。"])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军 - Abak进化西洋双陆棋职业"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["唯一可以返回游戏的士兵。它主导战斗。"])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫 - Abak进化西洋双陆棋职业"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每队有两个守卫。他们比其他士兵更强壮，不容易被捕获。"])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法师 - Abak进化西洋双陆棋职业"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法师设陷对手，固定敌人，而不是捕捉它。"])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数伤兵 - Abak进化西洋双陆棋职业"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数伤兵只能移动偶数步。"])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数伤兵 - Abak进化西洋双陆棋职业"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数伤兵只能移动奇数步。"])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化西洋双陆棋的规则"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak有6类士兵：士兵、将军、法师、守卫，以及两类伤兵。"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规则 - Abak进化西洋双陆棋"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题 - Abak进化西洋双陆棋"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个常见问题解答是关于Abak与西洋双陆棋的区别。"])}
    },
    "abak": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化西洋双陆棋。 一个变种。"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化是一款两人抽象策略棋盘游戏。它是经典西洋双陆棋的变种，增加了棋子种类。"])}
    },
    "tutorial": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋与Abak进化教程"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是一个西洋双陆棋互动教程。您将学习棋盘、移动、酒吧和取棋。您还会找到高级教程和谜题。"])},
      "backgammon": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋教程"])}
      },
      "abak": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化教程"])}
      }
    }
  },
  "learn": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习中心"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解和学习Abak演变的资源"])},
      "contenido1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一些让你玩Abak演变的资源"])},
      "videoT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube频道"])},
      "videoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看看Youtube频道，有介绍视频和一些简短的教程。"])},
      "versusT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak vs 扑克游戏"])},
      "versusC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak与经典扑克牌游戏的对比"])},
      "PDFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF规则"])},
      "PDFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载一套打印文件，方便离线使用"])},
      "reglasT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规则书"])},
      "reglasC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一页纸上的完整Abak演变规则。打印或手机查看的参考工具"])},
      "tablaT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于你棋盘上"])},
      "tablaC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你实体棋盘上的一套贴纸"])},
      "specs": {
        "specs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规格"])},
        "typeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
        "typeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抽象<br>策略"])},
        "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
        "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家"])},
        "complexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复杂性"])},
        "mechanics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机制"])},
        "turnbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回合制"])},
        "dicedriven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰驱动"])},
        "perfectinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全信息"])},
        "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["困难"])}
      }
    },
    "faq": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可能有一个问题"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里找"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])}
    },
    "files": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一套多样的实用文件"])}
    },
    "blog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak演变博客"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于Abak不同主题的文章列表，主要用于SEO，但如果你有时间，也可以阅读。"])}
    },
    "introvideo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观看介绍视频"])}
    }
  },
  "intro": {
    "content": {
      "tituloheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak进化飞行棋"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak进化</span>是一种飞行棋变体，增加了<span>棋子的种类</span>，每种都具有独特的特性和<span>行为</span>。"])},
      "isaboardgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak进化</span>是一款两人对战的抽象策略棋盘游戏。要赢得这个经典飞行棋的重制版，您需要运气和最佳战术。"])},
      "backgammon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飞行棋<span>²</span>"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak进化 </span>  采用与飞行棋相同的规则，但增加了5种士兵。这一小小的改变带来了非凡的影响。"])},
      "gotoabakme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在这里学习和在线玩。"])},
      "abakapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您会发现一个功能齐全的网页应用程序，您可以在这里与强大的CPU对手或真实用户对战。"])},
      "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看我们的社交媒体账户"])},
      "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩"])},
      "printrules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在这里找到棋盘贴纸模板、规则的打印版本和其他工具："])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工具"])},
      "nonprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak进化</span>是一个史诗般的非营利单人项目，旨在将这些惊人的规则传播给全人类。<br> 由智利软件开发人员<span>Samy Garib</span>发明、开发、维护和资助。"])},
      "sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让"])},
      "entonces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["然后"])},
      "classbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于种类的飞行棋变体"])},
      "quees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于种类的飞行棋变体"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加种类极大地改变了游戏。棋子的垂直位置很重要，因此移动顺序也很重要。<span>Abak进化</span>为<span>经典飞行棋</span>增加了一个新的维度"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习"])},
      "moredetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多详情"])},
      "timagen": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个种类主义的飞行棋，就像你一样"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加强版飞行棋"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(飞行棋)²"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飞行棋，适合大人。"])}
      },
      "abakisto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak之于飞行棋就像国际象棋之于跳棋"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规则"])}
    },
    "privacy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息收集、使用和共享"])},
      "content": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一个在线平台，用于玩Abak规则，这是飞行棋规则的扩展。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一个单人、非专业、非利润目的的努力，旨在传播这些规则。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台是免费的，就像所有其他Abak进化资产一样。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有广告，有可选的注册功能。"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不与用户数据进行任何外部活动。用户数据仅用于存储用户状态和排名。"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不会与其他方共享用户信息。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅依赖于外部登录平台，如Facebook、Google和Twitter，使用Google的Firebase Auth服务作为连接枢纽。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不会在您的社交账户上发布任何信息，也不会读取您的联系人信息。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社交平台上的应用程序只会收集您的个人头像和姓名。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用Cookie存储与您的账户相关的数据。外部登录提供者使用他们自己的Cookie。"])}
      }
    }
  },
  "FAQ": {
    "preguntas": {
      "1": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution比西洋双陆棋更<span>复杂</span>吗?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案是肯定的，但你会发现游戏更加有趣和动态。通过为游戏添加职业，每个士兵都有独特的身份和特征。此外，Abak有更多的投骰子组合，因为移动的顺序可能会影响结果。"])}
      },
      "2": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution和经典西洋双陆棋有多<span>不同</span>?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础规则几乎相同，但添加职业使游戏发生重大变化。<ul> <li><span>新维度:</span> 棋子的垂直位置很重要。这创造了一个新维度：Y轴。在Abak中，你不能仅仅说一个点有3个白棋子，你需要指定每一个的顺序。</li> <li><span>更多组合:</span> 每个西洋双陆棋位置有(3.346.200)^2种可能的Abak Evolution位置。</li> <li><span>棋子有身份:</span> 你需要关注它们的个体能力。</li> <li><span>移动顺序重要:</span> 如果你先移动一个棋子而不是另一个，结果可能会有很大不同。</li> </ul>"])}
      },
      "3": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我该怎么学习玩Abak Evolution?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用本网站的资源从西洋双陆棋学习到Abak。游戏界面是一个很好的资源，因为它突出了规则。"])}
      },
      "4": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习Abak Evolution需要知道如何玩西洋双陆棋吗?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的。你需要至少基本了解经典西洋双陆棋的规则。"])}
      },
      "5": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为什么每个点限五颗棋子?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虽然经典西洋双陆棋中有这条规则，但并不常用。它增加了很多不稳定性，特别是在撤棋时。Abak使用它因为我喜欢，同时出于一个实用的原因：棋子的可见性。"])}
      },
      "6": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution比西洋双陆棋慢还是时间长?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一旦你熟悉了不同的士兵，游戏速度与经典西洋双陆棋相似。由于将军可以后退，有时候游戏会比西洋双陆棋更长（以回合计算）。"])}
      }
    },
    "header": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可能有问题"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里找到"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题解答"])}
    }
  },
  "tips": {
    "general-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军被困。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军非常重要，无法移动。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因为它可以后退，它在帮助伤员回家和保护你的家中起到关键作用。尽量放在前面并使其可用。"])}
    },
    "druid-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师被困。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师在游戏中起着重要作用。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果它限制了对手，你将获得很大的控制权。尽量放在前面并使其可用。"])}
    },
    "tooclose": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师离家太近。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师是致命武器，但……"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["它的强大力量在中场。不要离家太近，因为在那里它会失去力量，像普通士兵一样。"])}
    },
    "inmobilized-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有一个不能动的士兵。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的士兵在对手魔法师下面，但没有保护。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在对手放另一个士兵之前，尽量打击。"])}
    },
    "inmobilized-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有一个不能动的士兵。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的士兵在对手魔法师下面，你不能打击。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尽量也抓住他，并尽量控制你的游戏。"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用你的将军倒退来消耗骰子。"])}
    },
    "inmobilized-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有一个混乱。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你限制了他的魔法师，但他也限制了你。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计划好。你有优势。"])}
    },
    "inmobilizing-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你限制了对手的士兵。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他的士兵在你的魔法师下面，但你的魔法师没有保护。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尽量放另一个士兵在上面，保住你的位置。"])}
    },
    "inmobilizing-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你限制了对手的士兵。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你限制了对手的士兵，你的魔法师受保护。干得好！"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在一次掷骰中移动和打击。准备好士兵以备时机。"])}
    },
    "inmobilizing-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有一个混乱。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你限制了他的魔法师，但他也限制了你。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计划好。你处于劣势。"])}
    },
    "even-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数受伤被困"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的偶数受伤被困，无法移动。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你需要找到方法让对手释放它的位置。"])}
    },
    "even-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数受伤几乎被困"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的偶数受伤可能很快被困。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小心，尽快逃脱。"])}
    },
    "odd-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数受伤被困"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的奇数受伤被困，无法移动。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你需要找到方法让对手释放它的位置。"])}
    },
    "odd-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数受伤几乎被困"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的奇数受伤可能很快被困。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小心，尽快逃脱。"])}
    },
    "guard-not-on-floor": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的守卫未充分利用"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫可以单独占据一个点。要抓住他们，需要两个对手士兵在一个回合内攻击，这是一个非常低概率的事件。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最好放在地面水平，以便利用他们的全部力量。"])}
    }
  },
  "about": {
    "history": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我从未坐下尝试发明新游戏，这个想法自然出现。当时我在与朋友玩西洋双陆棋，因规则单调感到无聊，这个点子突然出现在我脑海中。这是在复活节岛，1999年9月。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我免费做这些事，因为我内心相信这个游戏是你所知的西洋双陆棋的继承者，如果在我有生之年成为现实，结果将足以作为报酬。因为我是软件工程师，我能够在空闲时间独自完成这些，并享受其中乐趣。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当我开发 WebApp 与世界分享我的规则时，我以为 Abak Evolution 一上线就会流行，西洋双陆棋玩家会接受新规则，心想“哦，我怎么没想到！”。结果出人意料：他们没有接受。到我写这篇文章时，Abak 上线已经四年了，数千美元的付费营销活动，最多只有几百名常规玩家。"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution 面对许多入门障碍，难以克服："])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个项目是一个史诗般的非营利独角戏。"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的名字是 Samy Garib，我是智利的软件工程师。"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢您的时间。"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自那一刻起已经过去了20多年，我投入了数千小时开发：规则、机制、应用、网站、营销内容等。"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所以，这就是我当前的战斗前线。我在空闲时间处理这些工作，优先学习内容，因为我认为这是最难的障碍。我花了太多时间在人工智能上，因为我喜欢它，尽管现在的引擎已经相当不错，不需要更强大的玩家。"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于 Abak Evolution"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简短的抱怨。"])},
      "g": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比经典西洋双陆棋更具挑战性，学习曲线陡峭。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋玩家非常投入并喜爱他们的游戏，他们视 Abak 为威胁或入侵者。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["许多人喜欢与人对战，而不是与电脑对战，而你需要足够的玩家才能实现这一点。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我没有足够的资源来扩大沟通和内容创作。"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用程序在西洋双陆棋体验中不是最前沿，缺少许多高级西洋双陆棋玩家会喜欢的工具。"])}
      },
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史"])},
      "thanks-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢"])},
      "rant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱怨"])}
    },
    "history1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我小时候学会了玩西洋双陆棋，并与家人和同学玩了很多。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后来，我教了许多朋友怎么玩。最终，我达到了没有涉及金钱就无法找到乐趣的水平。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1999年8月在复活节岛旅行时，由于无聊，我发明了 Abak。"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一个版本与现在这个非常不同，我教会朋友们如何玩后，他们没有一个继续玩。所以，这个游戏被我搁置了多年。"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2007年某天，在工作中，我教了一些同事如何玩西洋双陆棋，当他们拒绝与我继续玩钱的时候，无聊再一次促使我教他们曾经发明的游戏。"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["但首先我进行了一些改变使其更好（受伤者和一些限制）。"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在他们中取得了巨大成功。从那时起，每天午餐时间有几对人（通常是我）玩 Abak。"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这些同事鼓励我制作游戏的软件版本，但你知道生活有优先事项。"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多年过去了，直到2009年9月。我发生了一次事故，给了我整整两周的居家时间。"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["软件被创造出来了，充满了错误，耗费了很多努力和精力，结果又沉睡了几年。"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从那时起，多次激情燃烧让我重新开始处理它，直到我达到可以与世界分享的水平。"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我感到很幸运能够擅长编程和计算机。如果我不擅长，我绝不可能有机会让这一切成为可能，并发布一个免费的版本让你了解。"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所以，这里是一个人的表演（除图形外，特别感谢 www.vrweb.cl）"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["希望你喜欢它"])}
    },
    "donate": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
      "hh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MHRS"])},
      "devtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开发时间"])},
      "onlinesince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上线时间"])},
      "playedgames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已玩游戏"])},
      "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃用户"])},
      "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入"])},
      "donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前捐赠金额"])},
      "otherincomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他收入"])},
      "expenses_op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运营费用（月）"])},
      "adv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开支"])},
      "devtimeprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开发和内容创作"])},
      "estadisticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捐赠"])},
      "necesities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除了时间，我在很多不同领域有直接开支"])},
      "necesity": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营销开发（视频+图形）"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营销媒体（Adwords+Facebook）"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据中心（Amazon AWS）"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI开发（设计+翻译）"])}
      },
      "closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你喜欢这个游戏，并能提供帮助，那将是很大的帮助。提前感谢。"])}
    },
    "samy": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我是一个热情的软件开发人员，1973年出生在智利。作为巴勒斯坦后裔，我在强烈的阿拉伯文化中长大，Tawle（或西洋双陆棋）是其中的一个元素。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自1999年以来，我一直在自己的公司 VRWEB 工作，虽然我的主要角色是营销和销售，但我喜欢编程。Abak 是我的沙盒。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我是三个孩子的父亲，同时是四个人的厨师。"])}
    },
    "thanksto": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多年来，我得到了来自世界各地人们的很多帮助：翻译、技术建议、游戏玩法建议、粉丝艺术等。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在一个特别的类别中是那些，尽管他们没有帮助，但与我互动并表现出如此激情的他们是我目标的催化剂："])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后但同样重要的是，有一些人在惊人的开源项目中工作，这些项目构成了 Abak 平台的基础。"])}
    }
  },
  "ayuda": {
    "generales": {
      "delsoldado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵规则"])},
      "situaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情境"])},
      "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击士兵获取帮助"])},
      "highest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你必须先移动每个点的最高棋子。"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果有其他棋子在上面，不能移动该棋子。"])}
      },
      "5max": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个点最多只能堆叠5个棋子。"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在场上位置，每个点最多只能堆叠五个棋子。"])}
      }
    },
    "piedras": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与经典西洋双陆棋相同"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵是经典西洋双陆棋的普通棋子。每队有9个。"])}
      },
      "2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以前后移动"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军可以在有限制的情况下前后移动。每回合只能向一个方向移动，并且只能后退一次。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果前进，同一回合不能后退"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果后退，本回合不能再移动。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果该队的所有其他士兵都在队伍之家，将军不能后退。"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从酒吧移动后，可以后退。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果前进"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果后退"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能前进"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能后退"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以前进"])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卫士"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比其他棋子更强大"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卫士就像两个士兵叠加在一起，但可以被魔法师困住，并且可以被其他卫士或其他士兵打击。每队有2个。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["像两个士兵一样阻挡"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以被魔法师困住"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以被其他卫士打击"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两个士兵可以打击它"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要用两个（非卫士）士兵打击卫士，你必须能够在一个回合中<b>连续移动</b>用<b>两者</b>打击它。"])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["困住并扣住"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果魔法师单独在中场，可以困住敌人。被困住的士兵在魔法师上方时不能移动。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师困住了士兵"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师安全"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师被魔法师困住"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师将被打击"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在任何房子内，魔法师表现如同普通士兵。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师可以困住单个士兵"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师不能困住被堵塞的列"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中场"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师困住"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑棋的家"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白棋的家"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师打击"])}
      },
      "5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶伤兵"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只能走<span>偶数</span>步"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶伤兵只能走偶数步"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从酒吧出来时，可以走任意数步。"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下棋时，可以走任意数步。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶伤兵只能在走的距离是偶数时移动，或者在离开酒吧或下棋时，走任意数步。"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在点（<span>三角形</span>）之间移动时，两者总是相同的<span>颜色</span>。<span>因此符号</span>。"])}
      },
      "6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇伤兵"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只能走<span>奇数</span>步"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇伤兵只能走奇数步"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从酒吧出来时，可以走任意数步。"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下棋时，可以走任意数步。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇伤兵只能在走的距离是奇数时移动，或者在离开酒吧或下棋时，走任意数步。"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在点（<span>三角形</span>）之间移动时，两者总是不同的<span>颜色</span>。<span>因此符号</span>。"])}
      },
      "56": {
        "leavingorcaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从酒吧出来或下棋时，可以走任意数步。"])}
      }
    }
  },
  "ui": {
    "lobby": {
      "messages": {
        "connectingtoengine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接到引擎"])}
      },
      "welcome": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎!"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以创建访客用户，或输入您的电子邮件以拥有永久账户。"])},
        "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为访客玩"])},
        "guest_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要作为访客玩，请点击这里。此用户将仅绑定到此客户端。"])},
        "changelater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以稍后添加电子邮件以保存此用户。"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录或注册"])},
        "create_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要创建或找回现有账户，请输入您的电子邮件地址"])}
      },
      "auth": {
        "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入发送到您注册邮箱的4位代码"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证"])},
        "useemailtosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加电子邮件账户以认领此用户。"])}
      },
      "action": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
        "qrlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二维码登录"])},
        "loginguest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访客"])},
        "loginemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])}
      },
      "confirm": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出只会删除此设备的cookie。如果您有有效的注册邮箱，您仍然可以访问此用户。您确定要继续吗?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此用户将从数据库中完全删除，且不可永久访问。您确定要继续吗?"])}
      },
      "user": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户操作"])}
      },
      "message": {
        "useqr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在任何设备上扫描此二维码以使用此用户登录。"])},
        "closesession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭此设备上的会话"])},
        "logindifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在不同设备上登录"])},
        "permanentremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久删除用户"])},
        "notabakertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非Abaker"])},
        "notabakertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成为<b>Abaker</b>以获得发送邀请或创建公共游戏的权限。或者您可以交朋友并邀请他们一起玩。"])},
        "howtoabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要成为<b>Abaker</b>，您必须在<b>高级</b>或<b>专家</b>模式下与服务器比赛赢得一场<b>5分</b>比赛，或者完成<b>练习</b>级别。"])},
        "stakeforabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELO积分赌注只能在Abak游戏中进行。"])},
        "abakerrequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赢得 $$(left)$$ 场比赛以解锁下一级。"])}
      },
      "subject": {
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赌注"])}
      }
    },
    "preferences": {
      "acelerators": {
        "autoDoneOnLastValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在最后移动时自动结束回合。"])},
        "autoRoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮到你时自动掷骰子。"])},
        "autoMoveOnOneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当只有一个合法移动时自动移动。"])},
        "autoDoneOnNoValids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果掷出无效移动则自动结束回合。"])},
        "autoRollForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即使可能有操作，仍强制自动掷骰。"])}
      },
      "messages": {
        "showAdvices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示提示"])},
        "showInvalidMoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示无效移动"])},
        "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示游戏消息"])},
        "playSounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["播放音效"])},
        "allowVisitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许访客"])},
        "allowInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许邀请"])},
        "showPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示位置编号"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家可见性"])},
        "allowedGameTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请中允许的类型"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偏好设置"])}
    },
    "message": {
      "newmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有新消息"])},
      "retryingerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重试"])}
    },
    "game": {
      "alerts": {
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏结束"])},
        "notyourtrun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没轮到你"])},
        "opponentchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是对手的棋子"])},
        "nomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个棋子不能移动"])},
        "noavailabledice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有骰子可用。撤销或结束回合。"])},
        "movelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你还有可移动的棋子"])},
        "upperchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你只能移动每列最顶部的棋子"])},
        "betterpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你必须使用最多的合法移动。还有更好的路径。"])},
        "ckeckersatbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有棋子在起点。先移出它们。"])},
        "guardsemicaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你必须用这个棋子击打守卫。它不能移动。"])},
        "cantbearoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在所有棋子进入家之前，你不能把棋子拿出板。"])},
        "cantbearoffback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你不能拿出这个棋子，你后面还有其他棋子。"])},
        "notback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个棋子不能后退"])},
        "onlyoneback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军已经移动过，不能后退。"])},
        "wentbacknomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果将军后退，它就不能再移动。"])},
        "nomovebackteamhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有其他棋子都在家时，将军不能后退"])},
        "onlyodd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个棋子只能移动奇数步"])},
        "onlyeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这棋子只能移动偶数步"])},
        "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每列只能堆放五个棋子"])},
        "adversaryspoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手有两个棋子时，你不能移动到该位置"])},
        "nojane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你不能组合来击打这个守卫"])},
        "nojanenear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫可以被两个非守卫击打，但你没有其他士兵在附近。"])},
        "nomoremoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你没有更多的移动。拿起骰子。"])},
        "checkertraped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个棋子被魔法师困住了"])},
        "musfinishjane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在守卫之上，但没有完成击打。你不能结束移动。"])},
        "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "nomovesturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没法移动。"])},
        "endguardmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你必须结束在守卫之上"])},
        "notyourcube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["骰子不属于你"])},
        "cubebeforeroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你只能在你的回合掷骰子之前加倍。"])},
        "cubemaxvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["骰子已达到最大值"])},
        "nomovestoundo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可撤销的移动"])},
        "notips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有提示"])},
        "youraretimetraveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你不能在时间旅行时移动。"])},
        "onlyatend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你只能在游戏结束时或在基础级别时分析移动。"])},
        "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个功能对你的用户已禁用。"])},
        "cubenotpractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["练习模式下不能使用加倍骰"])},
        "noanalyzepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚不能在练习模式下分析"])},
        "noanalysestake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在有赌注的比赛中，直到游戏结束你不能分析移动。"])},
        "matchlosspoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELO分数损失"])}
      },
      "help": {
        "regularsoldier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通士兵"])},
        "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击士兵以获取帮助"])}
      },
      "messages": {
        "yourturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮到你。掷骰子。"])},
        "nowcanmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在你可以移动了。"])},
        "serversturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务器的回合"])},
        "waintingfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待"])},
        "torolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "toplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩"])},
        "waintingforopponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待对手"])},
        "secconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])},
        "drawingboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绘制棋盘"])},
        "nologs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚无日志"])},
        "checkercolorschanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["棋子颜色已更改"])},
        "fullscreenrequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全屏已更改"])},
        "showpointsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点数可见性已更改"])},
        "allowinvitationsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请许可已更改"])},
        "allowvisitorsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访客可见性已更改"])},
        "clicklogformore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以点击日志位置时间旅行到那回合，获取更多信息。"])},
        "requestmovestats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以请求该回合的统计数据，以便与神经网络的移动比较。"])},
        "logdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回合 # ，掷骰子， # 移动，回合时间（秒）。"])},
        "analysisindicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析指标"])},
        "analysisw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏胜利的概率"])},
        "analysisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏以1、2或3分结束的概率。"])},
        "analysisequity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股权"])},
        "analysiswequitydescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反映游戏未来的分值"])},
        "analysiswequityej1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果双方平局"])},
        "analysiswequityej2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果玩家赢1分"])},
        "analysiswequityej3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果玩家输2分"])},
        "mathwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数学上"])},
        "analysismovelost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回合中失去的股权测量（如果你选择了最佳合法移动则为0）"])},
        "analysismovemoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子的合法移动数量"])},
        "analysisgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析游戏"])},
        "analysisgamewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将需要一些时间，请稍候"])},
        "clickturndetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击一个点查看回合详情。"])},
        "harmlessdisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无害断开"])},
        "exaustedinvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请机会已用尽。你可以在这里等待其他用户连接或退出与电脑对战，同时等待邀请。"])},
        "exitnomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>太遗憾了！</span> 你可以在与服务器对战的同时等待邀请。"])},
        "notyetanalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你需要至少20个回合来请求棋盘分析。"])},
        "playersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线玩家"])},
        "invitationsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送邀请"])},
        "invitationdeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请被拒绝"])},
        "invitationacepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请被接受"])}
      },
      "dialogs": {
        "askdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加倍游戏？"])},
        "oppentdoubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手提议加倍或不加倍"])},
        "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加倍"])},
        "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
        "acept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])}
      },
      "action": {
        "nextgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一局"])},
        "newmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新比赛"])},
        "surrender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投降"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
        "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["声明"])},
        "dontclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不声明"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
        "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入"])},
        "attemptdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试加倍"])},
        "doubleaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
        "doublerejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒绝"])},
        "startroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子开始"])},
        "startequals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["骰子相同，重掷。"])},
        "startstarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏开始"])},
        "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])}
      },
      "ialevel": {
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专家"])}
      },
      "message": {
        "ifyoulose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你输了"])},
        "ifyouwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你赢了"])},
        "youwilllosematch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你将输掉比赛"])},
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏结束"])},
        "matchover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比赛结束"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["声明比赛"])},
        "nogameslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中没有游戏"])},
        "logtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏日志"])},
        "confirmdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你确定要加倍吗？"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改比赛"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复比赛"])},
        "analysysnotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个工具运行太昂贵，暂时不可用。你仍然可以从回合日志中分析单个移动。"])},
        "practiceover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["练习结束"])}
      },
      "subject": {
        "turnslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回合日志"])},
        "gameevolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏演变"])},
        "turns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回合"])},
        "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["棋盘"])},
        "accelerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加速器"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选项"])},
        "p2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人类"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规则"])},
        "matchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比赛信息"])},
        "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股权"])},
        "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["胜者"])},
        "newvisitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的游戏中有新访客。"])}
      },
      "tag": {
        "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图例"])},
        "playersmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家移动"])},
        "novalidmoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有有效移动"])},
        "forcedmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制移动"])},
        "gamedoubled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏加倍"])},
        "tieline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平局线 (0)"])}
      }
    }
  },
  "tutorials": {
    "intro": {
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这一系列教程中，你可以学习经典的西洋双陆棋规则和阿巴克进化规则。你想学习什么？"])},
      "lback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习西洋双陆棋。"])},
      "lback2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我不知道怎么下西洋双陆棋。我想从头学起。"])},
      "labak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习阿巴克。"])},
      "labak2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我会下西洋双陆棋，我想学习怎么玩阿巴克。"])},
      "whyask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这只是为了设置你稍后可以更改的过滤器。"])}
    },
    "options": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中等"])},
      "uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成"])}
    },
    "common": {
      "whitehome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白色家"])},
      "blackhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑色家"])},
      "outerboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外棋盘"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒吧"])},
      "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外面"])},
      "movesleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你必须做所有有效的移动。"])},
      "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
      "gotit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你明白了！"])},
      "bettermove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有一个更好的移动"])},
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["做你的移动。"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认继续。"])},
      "chcompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["章节完成"])}
    },
    "level": {
      "bp": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造一个封锁敌人的结构"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个结构由六个连续的方块组成。用它陷阱你的敌人"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子并创建一个结构来将你的对手困在你的家中。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阻止它"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飞行棋的重要战术目标是阻止你的对手，将它困在你的家中。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立一个堡垒"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的对手士兵被困在后面，但你有一些空位需要覆盖。如果你的对手掷出[d24]或[d22]，他将击中并可能逃跑。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个移动，使六个连续的方块阻止他的逃脱"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的对手可以逃跑。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在家里留下了一个空位，你的对手可以逃跑。再试一次，完成一个完整的封锁。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完美。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经建立了一个结构。这是一个由6个块完全困住士兵的结构。非常好！"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满堂红。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你也可以在家中完全建造一个结构，并捕获无法脱身的士兵。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捕获你的对手，并关闭你的家。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你没有完全关闭你的家。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你本来应该在家中建造6个方块，并完全困住一个被捕的敌人。再试一次。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完美。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经在你的家中建立了一个结构。非常好！"])}
      },
      "b3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除所有士兵"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习如何移除你的士兵。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你所有的士兵都在你的家里。现在你可以通过一个名为“移除”的过程将它们从棋盘上移除。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点[!0]"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要移除你的士兵，你需要将它们移到棋盘外，移到点[!0]"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正好落在[!0]"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵必须移动到确切的空格才能到达那里。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从更高的点移动。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果移动开始的点没有士兵，而在更高的点有士兵，你必须移动那些士兵。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从最高点移除"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果更高的点没有士兵，你可以并且必须移动最高点的士兵到点[!0]，将它移除。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前情况"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里，你在最高点的士兵在点[5]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你掷[d16]。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因为你在最高点的士兵在[5]，小于[6]，你可以从[5]移除它。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你掷[d15]。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以正好从[5]移动到[0]。你可以移除它。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你掷[d14]。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因为你在最高点的士兵在[5]，大于[4]，你必须从更高的点移动，从[5]到[1]。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你掷[d13][d12][d11]。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以选择移除一个士兵，或者在棋盘内进行有效的移动。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让我们开始吧。掷骰子。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在[6]上没有士兵，但因为[5]是你最高的位置，你可以移除一个士兵。掷[d14]后你将不得不移动。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再掷一次。"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在位置1有3个士兵。你可以将它们移除，也可以从后面移动一些士兵。"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再掷一次。"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你所有的士兵现在都在位置[6]之前。移除它们。"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当你所有的士兵都移除后，你就完成了游戏。"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这结束了双陆棋的教程。你可以去和服务器对战练习，或者在主菜单查看更高级的教程。"])}
      },
      "a8": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伤兵撤离"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['伤兵'] 类在 ['撤离'] 时可任意移动。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这个练习中 ['撤离'] 你的 ['伤兵']，并练习他们在这种情况下的行为。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伤兵撤离"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['伤兵'] 的移动受到限制，但在撤离时，可以如同普通士兵一样使用 ['任何骰子'] 值。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数伤兵"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此场景中，['奇数伤兵 [s61]'] 在位置 [4]。可用 [d16][d15][d14] 撤离（如果在顶部）。可移动 [d11] 和 [d13]。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数伤兵"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['偶数伤兵 [s51]'] 在位置 [4]。可以用 [d16][d15][d14] ['撤离']。可移动 [d12]。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动 ['偶数伤兵']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在你可以撤离 ['偶数伤兵']。['奇数伤兵'] 不能移动。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你完成了。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['奇数伤兵'] 不能移动 [d12]，但可以移动 [d13]。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在可以用 [d12] 撤离。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你完成了。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在你知道如何撤离你的 ['伤兵']。在 ['中等'] 难度部分寻找更多教程。"])}
      },
      "a4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习使用守卫的优势和劣势。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据本章的指示，学习如何利用守卫获得优势。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫是最强大的职业。它可以独自阻挡位置，但有一些限制。你有两个守卫。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孤独的士兵"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这种情况下，你有一个士兵对抗两个敌方守卫。你能逃脱吗？"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你掷出了[14, 15]，无法移动，因为两个守卫都在阻挡([19][d15] [20][d14])。你可以尝试移动一个士兵，看看会发生什么。点击确认继续。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一名野生士兵出现了"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫并不是不可战胜的。两个士兵可以打击它们。让我们添加一个同伴。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用两个士兵攻击"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要捕获一个守卫，你必须在两个连续移动中用两个士兵落在它上面。一起尝试吧。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在，尝试用两个士兵打击守卫。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的！确认你已经完成。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太好了！这是一种方法。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因为，当然，一个守卫可以打击另一个守卫。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["试试看"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这样你就不必盲目地相信我。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["击中他们两个！"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太棒了。你现在完成了。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["章节完成"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经了解了守卫的工作原理。"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是预期的移动。再试一次。"])}
      },
      "b2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃离对手的酒吧！"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你被捕了。学习如何从酒吧逃脱。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的对手逮捕了两名士兵并将他们送往酒吧。释放他们。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒吧"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒吧是棋盘中间的列（[#B0] 和 [#B1]）。它关押被捕的士兵。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒吧"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这个棋盘上你有两名被捕的士兵 [s11][s11]。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒吧里的士兵"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当一名玩家在对手的酒吧有士兵时，他必须在进行其他任何操作前释放他们。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子逃脱"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你从酒吧逃脱，进入敌人的家，从最远的地方开始，如骰子所示。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何逃脱"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这种情况下，位置 [19][dr6] 和 [20][dr5] 被阻塞，其他都是开放的。位置[!22][d13] 有一个孤兵。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你现在只能在位置 [!21][d14] 释放一个士兵。位置 [20][d15] 被阻塞。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因为你在酒吧 [B1] 留下了一名士兵，你不能使用另一个骰子移动。你必须等待。确认你的操作。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次掷骰子"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在你可以在位置 [!22][d13] 落地，并捕获你的对手。一旦所有士兵都离开酒吧，你可以用任何士兵移动[d16]。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太棒了。现在确认。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自由移动!"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很棒。现在你的士兵可以自由奔跑了。你已全部设置。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下一章中，你将学习如何脱离棋盘。"])}
      },
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将你的士兵带到安全地带。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习如何移动你的士兵。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在棋盘末端有一个士兵，没有敌人。投掷骰子并移动你的士兵，直到它到家，然后将其从棋盘上移除以保存。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跑回家"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳棋的目标是将所有的棋子（士兵）带回家。你通过掷两个骰子并根据每个骰子的点数移动士兵来实现这一点。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤离"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一旦所有士兵都回家，你可以将它们从棋盘上移除（撤离）。第一个将所有士兵从棋盘上移除的玩家获胜。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每次掷骰子，如果骰子的点数不同，你可以有两次移动；如果点数相同，你可以有四次移动。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击按钮掷骰子。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在移动士兵两次，每次点击士兵并选择落点列。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经完成了所有有效的移动。通过按钮确认你已经完成。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太棒了!"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经完成了你的回合。继续，直到你的士兵安全（撤离）。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的对手没有在玩，继续玩。掷骰子。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在你有一个双数，可以有四次移动，每次移动相同的格数。移动所有士兵。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成。确认你的移动。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的士兵现在在家。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当所有士兵都在家时，你可以将它们从棋盘上移除（撤离）。掷骰子。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动掷骰子。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在你可以选择棋盘外部移动你的士兵。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太棒了，你已经完成了游戏。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏结束"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就是这样。你已经移除了所有士兵并完成了游戏。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下一章中了解被挡住的位置。"])},
        "11b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！再来一次。用另一个骰子移动。"])}
      },
      "a6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数受伤者"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解关于['偶数受伤者']的信息，令人意料之中的噩梦。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的['偶数受伤者']迷失在棋盘后面，附近有许多敌人。把它从困境中解救出来，带回家并拯救它。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数受伤者"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['偶数受伤者']只能移动偶数： [d12][d14][d16]。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可阻挡"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此它很容易被阻挡，你必须一直照顾它。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可预测"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因为它总是移动['偶数']，你可以从远处阻挡它的路径（如你所见，它不会超过[20]）。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃离那里"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩一些骰子来逃离那里。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这次掷骰中，骰子[d15]可用，但不是['偶数']。你只能将[d14]移动到[20]。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你已经完成。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被阻挡"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在['偶数受伤者']完全['被阻挡']。没有骰子可让你脱离困境。但嘿，现在是对手的回合。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手的回合。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手正在玩"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手正在玩"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你好运！"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[d16]现在可用。交叉你的手指。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加油！不要指望现实生活会像这样。现在，跑吧。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你已经完成。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功！"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经带着你的['偶数受伤者']回到家。干得好！"])}
      },
      "a1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿巴克 与 西洋双陆棋"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习两个游戏如何相同，但也不同。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟随本教程学习如何阿巴克改进了它的前身。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是经典的西洋双陆棋棋盘。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每队等于15个士兵"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿巴克进化"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿巴克的初始位置略有不同。它从后方的4个士兵开始。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵类别"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当然，还有士兵的类别"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相同规则"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋的所有规则都适用于阿巴克。但每个类别增加其额外规则。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可双向移动"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↶ 将军 ↷"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使对手无法移动。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师 = 🔒 "])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比其他士兵更强"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫 ≅ 士兵+士兵"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数受伤士兵"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只按奇数移动"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数受伤士兵 ↷ d11 d13 d15"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数受伤士兵"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只按偶数移动"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偶数受伤士兵 ↷ d12 d14 d16"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y 轴"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有不同类别的士兵不仅添加了士兵的规则，还深刻影响游戏机制。"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y 轴"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里有一个例子。点19和点17有相同类别的士兵，但顺序不同。意思是士兵在一个点的高度很重要，所以添加了一个新的轴，Y。"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动顺序"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这也意味着移动的顺序在游戏中很重要，并且可能显著改变一轮的结果。"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捕获守卫！"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先移动你的普通士兵，然后移动你的魔法师，捕获守卫。"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你已完成。"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你应该捕获守卫。再试一次。"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫已捕获！"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你捕获了守卫。"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改变顺序。"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你先移动魔法师会发生什么？"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定住守卫！"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先移动你的魔法师，然后移动你的普通士兵，困住守卫。"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你已完成。"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果不如预期"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你应该用魔法师困住守卫。"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫已困住"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如你所见，相同的移动顺序不同会有不同的结果。"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["章节完成。"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下一章了解更多有关每个类别的信息。"])}
      },
      "a5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇怪的伤员"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习移动'奇怪的伤员'并忍受它的限制。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的'伤员'在棋盘后方失踪，还有许多敌人。把它从混乱中救出，带回家。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇怪的伤员"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇怪的伤员只能移动奇数: [d11][d13][d15]。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可阻挡"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此，极易被阻挡，你必须始终照顾它。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃离那里"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你将有'3次掷骰'来跨过对手。明智选择。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#1 掷骰"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这次掷骰[11,13]允许一次有效移动，但你可以选择哪个骰子。移动。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认完成。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#2 掷骰"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行你的移动。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认完成。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#3 掷骰"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这次，你有一个'偶数'无法移动。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认完成。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太好了，你逃脱了。继续掷骰以结束。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行你的移动。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认完成。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功！"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经带着你的'奇怪的伤员'回家了。干得好！"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哎呀。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的伤员现在完全被困住了，因为[d11]落在[20]，[d13]在[18]，[d15]在[16]，全部被阻挡。再试一次！"])}
      },
      "a2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习使用['魔法师']。最令人畏惧的战士。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按照本章的说明，学习如何使用你的守卫获得优势。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['魔法师']是一种需要练习才能掌握的强大工具。它['固定']敌方士兵而不是俘虏他们。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["它的力量只存在于['外板']上，在那里他可以固定士兵。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["俘虏"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在['内部区域']，魔法师表现得像一个['普通']士兵。它['俘虏']士兵并将他们送到酒吧。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同时移动[d14]和[d12]与['魔法师']，俘虏['普通']士兵，并固定['守卫']。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认以继续。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你没有完成双重操作。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你应该俘虏一个士兵[s12][22][d12]并固定守卫[s32][18][d14]。再试一次。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好，你困住并俘虏了一个士兵！"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在他的守卫[s32][18]不能移动。但他可能会用另一个士兵打击你的魔法师。哦不，现在轮到他了！"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手的回合。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手正在玩"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手正在玩"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的魔法师被俘虏了。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的魔法师在酒吧。掷骰子以脱身。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃离酒吧，用你的魔法师在[20]困住敌方士兵。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好。士兵被困住了。"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的魔法师仍然孤单且脆弱。但嘿，能有什么问题呢？"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手的回合。"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手正在玩"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手正在玩"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["混乱。"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在你的魔法师上面有一个魔法师。你不能移动它，但在它下面的士兵也不能移动。"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用你在[24]的士兵俘虏你的对手魔法师，并拯救你的魔法师。"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认以继续。"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完美。魔法师安全。"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的魔法师固定了一个士兵，并受到了保护，构建了一个阻挡点。"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不会永远这样。"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你总有一天会离开那个位置并释放士兵。更高级的教程有详细论述这一主题。"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["章节完成"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已完成本章。"])}
      },
      "a3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学习使用 ['将军']。最机智的士兵。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按照本章的说明，学习如何使用['将军']获得优势。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将军"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['将军']很机智。它可以['后退']和['前进']。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[#1] 一回合内只能朝一个方向移动。<br/>[#2] 只能后退一次。<br>[#3] 如果所有其他士兵都在家，它不能后退。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅一次后退。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果将军后退，本回合不能再移动。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击将军 [s21]。你可以看到它可以移动到4个不同的点。选择一个['后退']（最高点）。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好。再点击一次。你会看到它不能再移动了。移动其他棋子。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前进多次，但不后退。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当将军前进时，可以移动每个骰子点数，但不能后退。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意料之外。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请按消息中的指示完成本教程。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击将军[s21]。现在选择一个前进的点。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次点击将军[s21]。它不能后退，但可以继续前进。移动。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认继续。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是唯一离家的人。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果所有其他士兵都在家，将军不能后退。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果所有其他士兵都在家，将军不能后退。"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子。"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果点击你的将军，你可以看到它可以后退。确保用你的一般 ['士兵 [s11]'] 进入你的队伍家。"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次点击将军[s21]。没有其他队伍士兵在外，所以它不能后退，只能前进。"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认继续。"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["章节完成"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就是这样。你已完成本章。"])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["棋盘"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋棋盘描述。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速浏览西洋双陆棋棋盘。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["棋盘"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是西洋双陆棋棋盘。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋棋盘"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["它有24个位置，称为点，分为4个象限。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特殊位置"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加上栏杆，以及棋盘外的一个位置，用于安置被救士兵。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内棋盘"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家的内棋盘在右下象限。点数[1]到[6]"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内棋盘"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手的内棋盘在右上象限。点数[24]到[19]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外棋盘"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是无人区。点数[7]到[18]"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["栏杆"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["栏杆是安置被俘士兵的位置。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外棋盘"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外棋盘是放置移除的士兵的位置。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反转棋盘"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意棋盘可能会在X轴上反转，内棋盘在左侧。您可以在菜单中随时更改棋盘方向。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个玩家有15个士兵。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的：[s11]，对手：[s12]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相反方向"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家向相反方向移动。玩家1（[s11]）从24移动到1，朝向他的内棋盘。"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手的方向"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家2（[s12]）从1移动到24。"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰并移动"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏轮流进行，每个玩家掷两个骰子并移动他的士兵。"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带他们回家。"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏目标是将你的士兵带回家并移除他们。"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["章节完成。"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接下来学习如何移动士兵。"])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从对手的家中逃脱"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过对手的士兵逃出他的家并捕捉他的士兵。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有两个士兵在棋盘末端。避开对手并把他们带回家。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被阻挡的位置"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士兵可以到达对手没有阻挡的位置。当对手有两个或更多士兵在同一位置时，该位置被阻挡。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可加起来"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以用每个骰子移动不同的士兵或用两个骰子移动同一个士兵，但记住那是两个动作，每个动作都必须有一个开放位置。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你点击位置 [23] 的士兵，你会发现它不能移动任何一个骰子。因此它不能移动两个骰子的总和。你必须移动另一个士兵。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经完成了所有的移动。现在确认你完成了。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孤子"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果一个士兵单独处在一个位置（孤子），它可以被对手的士兵捕捉。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰子"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用一个士兵捕捉并落在对方士兵上。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已经把对手的士兵送到吧台！"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手被捕"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当玩家有士兵在吧台时，它必须返回棋盘才能继续玩。如果它无法从吧台逃脱，其他士兵不能移动。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下一章中学习如何从吧台逃脱。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你没有捕捉到那个士兵！"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你应该捕捉那个孤独的士兵。再试一次！"])}
      },
      "a7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在酒吧受伤"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['受伤'] 类可以在从 ['酒吧'] 进入时移动任意数量。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有你的['受伤被俘']。你会有['4次掷骰'] 机会带他们出去。你的对手不会移动。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃脱受伤"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['受伤'] 的移动受限，但在 ['酒吧'] 时，他们可以以 ['任意骰子'] 数值进入棋盘。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4次掷骰"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[13,12] ➞ [15,14] ➞ [16,16] ➞ [15,15]"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰 #1。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你点击酒吧中的受伤 [B0]，你可以看到两个都可以降落在 [22][d13] 和 [23][d12]。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你完成了。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3次掷骰"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ➞ [15,14] ➞ [16,16] ➞ [15,15]"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰 #2。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行你的移动。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你完成了。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2次掷骰"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ➞ [u5,u4] ➞ [16,16] ➞ [15,15]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰 #3。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行你的移动。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你完成了。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1次掷骰"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ➞ [u5,u4] ➞ [u6,u6] ➞ [15,15]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掷骰 #4。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行你的移动。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很好！确认你完成了。"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预期你带着两个受伤者回家。再试一次！"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功！"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你带着两个受伤者回家了。干得好！"])}
      },
      "bst": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开局移动"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西洋双陆棋已被破解。关于开局的最佳走法有一定的共识。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["做出最明智的开局决定，完成本教程。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哈勒是指两个不同兵士从两个不同点移动到同一目的地的走法。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这可能是最佳的开局走法。发挥你的最佳水平。"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试在[7]点建立封锁。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你已创建了一个重要的封锁。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黄金点"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在一个非常重要的位置建立封锁。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在家的黄金点处建立了一个封锁。一个重要的防守位置。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另一个重要点。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在一个相当不错的位置建立封锁。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在家的一个重要位置建立了封锁。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个有争议的点，但对此有一定的共识。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在一个相当不错的位置建立封锁。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在家的一个重要位置建立了封锁。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个有争议的点，但对此有一定的共识。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在一个相当不错的位置建立封锁。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你在家的一个重要位置建立了封锁。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃脱走法"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开局有些不太好的走法。你不会用它们建立封锁。"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["章节完成"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很快会有更多的开局！"])}
      },
      "g102": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正确使用你的守卫"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的守卫是很棒的工具，但你可能整场比赛都没用上他们的力量。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解守卫，解决一些谜题。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["守卫几乎是块。"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在棋盘底部独处时，他们做出不同。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绝对封锁"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面对单个敌人时，他们做出绝对封锁，如果那个敌人不是守卫。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12]完全被封锁。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["临时封锁"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面对多个敌人时，他们可能会被打击，但几率很小。他们是很好的临时结构。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] 用 [d25][d25] 和 [d26][d26] 打击"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他们不是魔法师的对手"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法师可以像困住其他士兵一样困住一个守卫。"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s42] 在 [6] 被封锁但可以在 [7] 困住"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你堆叠他们，可能会用不尽他们"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你想以后攻击，保持他们在块顶。"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尽量避免在他们身上堆叠士兵，最大化你的机会和整个军队的能力。"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["但在家时，保持他们在地面，这样在撤退时不会留下空位。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是你最糟糕的情况。"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封锁奇数受伤者。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["做出正确的移动，完全封锁受伤者。"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哎呀。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇数受伤者 [s61] 没有完全被封锁，重试。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["极好。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你完全封锁了奇数受伤者。"])}
      }
    }
  }
}