<template>
    <div class="privacyBox" @click="clickClose()">
        <div class="box textoLibre" >
            <div class="title">
                <div> Privacy Policy</div>
                <div> ⨯ </div>
            </div>
            <ul>
                <li><span>Abak Evolution</span> is a non-profit organization that does not collect or use your user information for any purposes other than to give you a great user experience.</li>
                <li><span>Abak Evolution</span> uses local cookies to recognice the user browser, and keep user preferences.</li>                        
                <li><span>Abak Evolution</span> uses third party cookies, for analytics purposes only.</li>                        
                <li><span>Abak Evolution</span> asks optionally and voluntarily for your e-mail address to claim a permanent user account.</li>
                <li>The requested e-mail address is only used for login purposes. It might be used for urgent comunications.</li>
                <li><span>Abak Evolution</span> does not share your email address with any third party.</li>
                <li>You can delete your user at anytime, removing your user e-mail effectively from <span>Abak Evolution's</span> database.</li>
            </ul>
        </div>
    </div>
</template>

<script> 

    export default {
    name: 'PrivacyPolicy',
    emits: [
        'colse-privacy'

    ],
    methods: {
        clickClose:function() {
            this.$emit("colse-privacy");
        }
    }
    }

</script>

<style lang="scss"> 

    .privacyBox {
        position:absolute;
        left:0;
        top:0;
        height:100%;
        background:#000000C0;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        flex-direction:column;
        align-items:center;
    }

    .box {
        background:$c6;
        opacity: 1;
        border-radius: 1em;
    }

    .textoLibre {
        text-align:left;
        margin:0.5em;
    }

    .title {
        display: flex;
        margin: 0 1em;
        width: 90%;
        margin-top:1em;
        
        div:first-child {
            flex:1;
        }

        div:nth-child(2) {
            background:red;
            border-radius: 1em;
            cursor:pointer;
            min-width: 1.3em;
            display: flex;
            text-align: center;
            justify-content: center;
        }
    }

</style>