//import { INVALID_MOVE, Stage } from 'boardgame.io/core';

const { updateMaxMoves } = require('./actions/updateMaxMoves.js');
const { clickRollStartDie } = require('./actions/rollToStart');
const { execIARoll } = require('./actions/iaPlayRoll');
const { execIADouble } = require('./actions/iaPlayDoubleDecide');
const { execIACalcMoves } = require('./actions/iaPlayCalculateMoves');
const { clickDone } = require('./actions/done');
const { quitMatch } = require('./actions/quitMatch');
const { quitGame } = require('./actions/quitGame');
const { clickRoll } = require('./actions/roll');
const { atemptDouble } = require('./actions/atemptDouble');
const { acceptDouble } = require('./actions/acceptDouble');
const { cancelDouble } = require('./actions/cancelDouble');
const { moveChecker } = require('./actions/moveChecker');
const { playNextMove } = require('./actions/playNextMove');
const { resetGame } = require('./actions/resetGame');
const { resetTurn } = require('./actions/resetTurn');
const { resetMatch } = require('./actions/resetMatch');


const AbakEvolution = {

  name: 'AbakEvolution',
  setup: (ctx,setupData) => (
    {       
      table_map: {},
      type: setupData ? setupData.type : 'abak',
      set: setupData ? setupData.set :  [1,1,1,1,1,1],
      checkers_map: [],
      stake: setupData ? setupData.stake : 10,
      matchPreferences: setupData ? setupData.matchPreferences: {
        allowDouble:true
      },
      points: {
        current: [0,0],
        lastGame: [0,0],
        goal: setupData ? setupData.goal : 5,
        games_record: [] 
      },

      timers: {
        game:[0,0],
        gameStartedAt:0,
        turnStartedAt:0,
        turnDoubledAt:0,
        turnDoubleRespondedAt:0,
        turnRolledAt:0,
        maxTimeOut: setupData ? setupData.maxTimeOut : 60,
      },

      doubleCube: {
        holder: null,
        value:1,
      },

      turnslog: {
        currentMoves:[],
        log:[]
      },

      diceRoll: [0,0],
      generalDirection:0,
      diceAvailable: [0,0,0,0],
      rollNumber: 1,
      maxMovements: null,
      currentMovements:0,  
      iaPlayStage: 'notPlaying',
      iaPlayOnError: {
        error: false,
        tries: 0
      },
      turn: {
        sampleMove: []
      },

      matchID: setupData ? setupData.matchID : "" ,

      matchNumber:1,
      
      opponent: {
        type: setupData ? setupData.opponent.type : "CPU",        
        level: setupData ? setupData.opponent.level : 4,       
      },     
      nextMoves: []
    }
      

    ),


  phases: {

      /* FASE QUIEN PARTE */
      
      defineWhoStarts: {  

        onBegin: (G) => {
          resetGame(G);
        },

        turn: {
          onBegin: (G,ctx) => {
          ctx.events.setActivePlayers(
            { 
              all: 'defineWhoStarts'
            });  
          },
          moveLimit: 0,
        },
        moves: { 
          clickRollStartDie: { client:false, move: (G,ctx) => { clickRollStartDie(G,ctx) } } , 
          quitMatch: { client:false, move: (G,ctx) => { quitMatch(G,ctx) }},
          quitGame: {client:false, move: (G,ctx) => { quitGame(G,ctx) }},  
        },
        start: true,
        next: 'play',
      },

      /* FASE JUGAR */

      play: {
        next: 'ended',
        turn: { 
          moveLimit: 0,
          stages: {
  
            /******************  Stage Roll Or Double ****************************/
            rollorDouble: {
              start: true,
              moves: {                  
                  clickRoll: { client:false, undoable:false, move: (G,ctx) => {  clickRoll(G,ctx) } },                                
                  clickDouble: { client:false, move:(G,ctx) => { atemptDouble(G,ctx) } },                 
                  quitMatch: { client:false, move: (G,ctx) => { quitMatch(G,ctx) }},
                  quitGame: {client:false, move: (G,ctx) => { quitGame(G,ctx) }},  
                  //claimMatch: { client:false, move: (G,ctx,matchData) => { claimMatch(G,ctx,matchData) }}
                },              
            },

            /******************  Stage Response to Double ****************************/            
            respondToDouble: { 
              moves: {
                clickConfirmDouble: { client:false, move: (G,ctx) => {  acceptDouble(G,ctx) } },        
                clickCancelDouble: { client:false, move: (G,ctx) => {  cancelDouble(G,ctx) } }
              },
            },

            doublingWait: {
              moves: { 
                //claimMatch: { client:false, move: (G,ctx,matchData) => { claimMatch(G,ctx,matchData) }}
              }
            },

            moveCheckers: {
                moves: {                 
                clickTarget: { client: true, undoable: true, 
                  move: (G,ctx,target) => {  moveChecker(G,ctx,target) } 
                },

                /* Turn Commit */
                clickDone: { client:false, 
                  move: (G,ctx) => { clickDone(G,ctx) }
                },  
                
                quitMatch: { client:false, move: (G,ctx) => { quitMatch(G,ctx) }},
                quitGame: {client:false, move: (G,ctx) => { quitGame(G,ctx) }}              
              },        
            },

            IAPlay: {
              moves: {
                decideDouble: {
                  client:false,
                  move: (G,ctx) => {
                      if (G.type == 'practice') {
                        G.iaPlayStage = 'rollDice';
                        return;
                      }
                      let response = execIADouble(G,ctx);
                      if ( !response )  {
                        console.log("decideDouble Error.");
                        G.iaPlayOnError.error = true;
                        G.iaPlayOnError.tries ++;
                      }
                      else {
                        //console.log("decideDouble OK.");
                        G.iaPlayOnError.error = false;
                        G.iaPlayOnError.tries=0;
                        if (response == 'not-doubled') {
                          G.iaPlayStage = 'rollDice';
                        }                      
                      }
                  }
                },
                rollDice: {
                  client:false,
                  move: (G,ctx) => {
                    let response = execIARoll(G,ctx);
                    if ( !response )  {
                      console.log("RollDice Error.");
                      G.iaPlayOnError.error = true;
                      G.iaPlayOnError.tries ++;
                    }
                    else {
                      //console.log("RollDice OK.");
                      G.iaPlayOnError.error = false;
                      G.iaPlayOnError.tries=0;
                      G.iaPlayStage = 'calculateMoves';
                    }
                  }
                },
                calculateMoves: {
                  client:false,
                  move: (G,ctx) => {
                      //G.iaPlayStage = "calculatingMoves";
                      let response = execIACalcMoves(G,ctx);
                      if ( !response )  {
                        console.log("CalcMoves Error.");
                        G.iaPlayStage = 'calculateMoves';
                        G.iaPlayOnError.error = true;
                        G.iaPlayOnError.tries ++;
                      }
                      else {
                        //console.log("CalcMoves OK.");
                        G.iaPlayOnError.error = false;
                        G.iaPlayOnError.tries = 0;
                        G.iaPlayStage = 'playMoves';
                        G.nextMoves = response;
                      }
                    }
                },
                playNextMove: { 
                  client:false, 
                  move: (G,ctx) => { playNextMove(G,ctx); }
                },
                resetIAError : { 
                  client:false, 
                  move:(G,ctx) => {
                    console.log(ctx); 
                    G.iaPlayOnError.tries = 0;
                   }
                },                
                quitGame : { client:false, move:{ quitGame } },                
              }
            }
          },          
          onEnd: (G,ctx) => {

            /* Set Timers */
            let turnTime = Date.now() - G.timers.turnStartedAt - (G.timers.turnDoubleRespondedAt - G.timers.turnDoubledAt);            
            let otherPlayer = (parseInt(ctx.currentPlayer)+1)%2;
            G.timers.game[parseInt(ctx.currentPlayer)] += turnTime/1000;  
            G.timers.game[otherPlayer] += (G.timers.turnDoubleRespondedAt - G.timers.turnDoubledAt)/1000;

            /* Push Log if is not a  */
            if (G.diceRoll[0]!=0&&G.diceRoll[0]!=0) {
              G.turnslog.log.push(
                {
                  type:"move",
                  playerID: ctx.currentPlayer, 
                  diceRoll:G.diceRoll,
                  maxMovements:G.maxMovements,
                  /*checkers_map:G.checkers_map,*/
                  moves: G.turnslog.currentMoves,
                  doubleCube:G.doubleCube,
                  turnTime: turnTime/1000
                });  
            }

            /* Reset Turn */
            resetTurn(G);   

         
          },
          onBegin: (G,ctx) => {
            //console.log("Begin Play");
            G.timers.turnStartedAt = Date.now();
            G.timers.turnDoubleRespondedAt = 0;
            G.timers.turnDoubledAt = 0;
            G.timers.turnRolledAt = 0;

            G.rollNumber++;
            // If this is the first Move after who starts.
            let netxStage = G.diceRoll[0] == 0 ? 'rollorDouble' : 'moveCheckers';            
            if (G.diceRoll[0]!=0) updateMaxMoves(G,ctx);
              //if (G.diceRoll[0]!=0) updateMaxMoves(G,ctx);
            if (ctx.currentPlayer==1 && G.opponent.type == "CPU") {      
              ctx.events.setActivePlayers({currentPlayer: 'IAPlay', others: 'IAPlay' }); 
              G.iaPlayStage = "decideDouble"
            }
            else {
              ctx.events.setActivePlayers({currentPlayer: netxStage});
            }
          }
        },
      },

      /* FASE JUEGO TERMINADO */

    endedGame: {
        next: 'endedMatch',
        turn: { 
          moveLimit: 0,
          onBegin: (G,ctx) => { 
            ctx.events.setActivePlayers(
              { 
                all: 'endedGameStage'
              });
            if (
              G.points.current[0]>=G.points.goal || 
              G.points.current[1]>=G.points.goal ) {
                ctx.events.endPhase();
              }
          },
          stages: { 
            endedGameStage: {
              moves: {
                
                clickContinue:{ 
                  client: false,
                  move: (G,ctx) => { 
                    ctx.events.setPhase('defineWhoStarts');
                }
              },  
                quitMatch: {client:false, move: quitMatch}
            }
          }
        }
      }
    },
    endedMatch: {
      next: 'endedMatch',
      turn: { 
        moveLimit: 0,
        onBegin: (G,ctx) => {
          ctx.events.setActivePlayers(
            { 
              all: 'endedMatchStage'
            });          
        },
        stages: { 
          endedMatchStage: {
            moves: {
              clickNewMatch: {
                  client:false,
                  move: (G,ctx) => {    
                    resetMatch(G);
                    ctx.events.setPhase('defineWhoStarts');
                  }
              },
            }
          }
        }
      }      
    },
    faltaError: {
      next: 'endedMatch',
      turn: {
        moveLimit:0,
        onBegin: (G,ctx) => {
          ctx.events.setActivePlayers(
            { 
              all: 'fatalError'
            });          
        },
        moves: {
          clickNewMatch: {
              client:false,
              move: (G,ctx) => {    
                resetMatch(G);
                ctx.events.setPhase('defineWhoStarts');
              }
          },
        }
      }
    }
  },     
};

module.exports = { AbakEvolution };