<template>
    <div class="playerContainer"
        :class="{ 
            vertical : vertical,
            colorWhite: playerID == 0,
            colorBlack: playerID == 1,
        }"
        
    >
        <div class="image" style = "position:relative;">
            <div class='badge'> <img style='width:1em;' :src="badgeImage" /></div>
            <img 
            :src="player.image"
            :style="{ width: imageSize + 'px' }"            
            />
        </div>       
        <div class="name">{{player.name}}</div>
        <div class='ranking'>{{player.ranking}}</div>
        
 
    </div>
</template>

<script>
	export default {
		name: 'PlayerBadge',
        props: [
            'player',
            'imageSize',
            'vertical',
            'playerID',
            'colors'
            ],
        data: function() {
            return {
            }
        },
        computed: {
            badgeImage() {
                return "/img/players/abaker/"+this.player.abaker+".svg"
            },
            vcolors() {
                if (this.colors) return this.colors;
                else {
                    return [         
                        {
                            background: "#ceca9d",
                            line: "#986941",
                            object1: "#250e08",
                            object2: "#40190f",
                            color: "#2e130c",
                            },
                            {
                            background: "#2e130c",
                            line: "#492a02",
                            object1: "#c89159",
                            object2: "#c89159",
                            color: "#ceca9d"
                        }
                    ]
                }
            }
        },
        methods: {	

        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .playerContainer {
        border-radius: 0.5em;
        background-color: $c17;
        display: flex;        
        align-items: center;
        padding:0.3em;
        /*margin:0.3em 0.5em;*/
        filter:drop-shadow(0.1em 0.1em 0.2em black);
        text-align: center;
        cursor:pointer;
    }

    .colorWhite {
        background:v-bind('vcolors[0].background');
        color:v-bind('vcolors[0].color');
    }

    .colorBlack {
        background:v-bind('vcolors[1].background');
        color:v-bind('vcolors[1].color');
    }
    

    .vertical {
        flex-direction: column;
        max-width: 5em;
        min-width: 4em;
    }

    .vertical div, .vertical img {
        margin-left:0;        
    }

    .image {
        background-color:$c10;
        box-sizing: border-box;                
        border-radius: 50%;
        width:2.3em;
        height:2.3em;
        display: flex;
        justify-content: center;
        align-items: center;     
        border:0.1em solid black;   
    }

     .image img {
     }

    .name {
        margin-left:1em;
    }

    .ranking {
        margin-left:1em;
        background-color: #00000066;
        padding:0.2em;
        border-radius: 0.2em;
        
    }

    .badge {
        position:absolute;
        top:-10%;
        left:80%;
        background-color:$c18;
        display:flex;
        border-radius: 1em;
        border:0.1em solid black;
    }

</style>
