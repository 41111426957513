export default {
  "background": "rgb(18, 27, 77)",
  "path": "",
  "board": {
    "background": "rgb(54, 125, 247)",
    "border": "rgb(0, 0, 0)",
    "bar": "rgb(0, 0, 0)",
    "points": [
      {
        "background": "rgb(92, 173, 255)",
        "border": "rgb(43, 149, 255)"
      },
      {
        "background": "rgb(18, 98, 219)",
        "border": "rgb(62, 118, 201)"
      }
    ]
  },
  "dice": [
    {
      "background": "rgb(255, 255, 255)",
      "border": "rgb(0, 0, 0)",
      "dot": "rgb(0, 0, 100)",
      "dotborder": "rgb(75, 75, 75)"
    },
    {
      "background": "rgb(46, 59, 163)",
      "border": "rgb(0, 0, 0)",
      "dot": "rgb(255, 255, 255)",
      "dotborder": "rgba(0, 0, 0, 0)"
    },
    {
      "background": "#666",
      "border": "#333",
      "dot": "#333",
      "dotborder": "#111"
    },
    {
      "background": "rgb(128, 9, 70)",
      "dot": "rgb(53, 53, 53)",
      "dotborder": "#300",
      "border": "#300"
    }
  ],
  "cube": {
    "background": "rgb(255, 255, 255)",
    "color": "rgb(45, 75, 166)"
  },
  "checkers": [
    {
      "background": "rgb(255, 255, 255)",
      "line": "rgb(100, 100, 250)",
      "object1": "rgb(0, 0, 100)",
      "object2": "#40190f",
      "color": "#2e130c"
    },
    {
      "background": "rgb(13, 21, 115)",
      "line": "rgb(212, 212, 212)",
      "object1": "rgb(222, 222, 222)",
      "object2": "#c89159",
      "color": "#ceca9d"
    }
  ],
  "buttons": {
    "default": {
      "background": "rgb(40, 53, 148)",
      "border": "rgb(81, 45, 167)",
      "hover": "gray",
      "off": "rgb(235, 29, 78)"
    },
    "light": {
      "background": "rgb(75, 75, 75)",
      "border": "rgb(75, 75, 75)",
      "hover": "gray",
      "off": "rgb(216, 27, 67)"
    },
    "soft": {
      "background": "rgb(170, 71, 188)",
      "border": "rgb(82, 21, 97)",
      "hover": "gray",
      "off": "#000"
    },
    "warning": {
      "background": "rgb(241, 98, 126)",
      "border": "black",
      "hover": "grey",
      "off": "#000"
    }
  },
  "elements": {
    "box1": {
      "background": "rgb(11, 67, 163)",
      "color": "white"
    },
    "box2": {
      "background": "rgb(2, 98, 176)",
      "color": "white"
    },
    "scroll": {
      "bar": "#555555",
      "hover": "#999999"
    },
    "chrono": {
      "total": {
        "background": "#9e8863",
        "color": "white"
      },
      "current": {
        "background": "#4e4833",
        "color": "white"
      }
    }
  }
}