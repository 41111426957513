
export default {
    background: 'black',
    path: '',
    board: {
      background: '#333333',
      border: '#444444',
      bar: '#444444',
      points: [
        {
          background: "#555",
          border: "#777"
        },
        {
          background: "#888",
          border: "#666"
        }
      ]
    },
    dice: [
      {
        background: "#eee",
        border: "grey",
        dot: "black",
        dotborder: "black",
      },
      {
        background: "#222",
        border: "grey",
        dot: "white",
        dotborder: "black",
      },
      {
        background: "grey",
        border: "black",
        dot: "white",
        dotborder: "white",
      },
      {
        background: "#770000",
        dot: "grey",
        dotborder: "#55555555",
        border: "grey"
      }
    ],
    checkers: [
      {
        background: "#eee",
        line: "#333",
        object1: "#333",
        object2: "#444",
        color: "#333",
      },
      {
        background: "#222",
        line: "#fff",
        object1: "#fff",
        object2: "#fff",
        color: "#ceca9d"
      }
    ],
    cube: {
      background: "white",
      color: "black",
    },
    buttons: {
      default: {
        background: '#010',
        hover:"gray",
        border:"red",
        off:"#200"
      },
      light: {
        background: '#333333',
        border:"red",
        hover:"gray",
        off:"#000"
      },
      soft: {
        background: '#ada224',
        border:"red",
        hover:"gray",
        off:"#000"
      },
      warning: {
        background:'#ada224',
        border:"red",
        hover:"grey",
        off:"#000"
      }

    },
    elements: {
      box1: {
        background:"gray",
        color:"white"
      },
      box2: {
        background:"#333",
        color:"white"
      },
      scroll: {
        bar:"#222222",
        hover:"#999999"
      },
      chrono: {
        total: {
          background:"#111",
          color:"white",  
        },
        current: {
          background:"#222",
          color:"white",  
        }
      }
    }
  }