<template>
<div>
    <div v-if="type=='graphic' || type=='both' " class="showEq"> 
        <svg :style="{ width: width, height: '2em'}" xmlns="http://www.w3.org/2000/svg" fill="white">    
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="50%" :style="{stopColor:colors[0],stopOpacity:1}" />
                <stop offset="100%" :style="{stopColor:colors[1],stopOpacity:1}" />
                </linearGradient>
            </defs>
            <rect x="0%" y="40%" width="100%" height="30%" fill="url(#grad1)" />
            <rect 
                :x="xpos+'%'" 
                y="0%" 
                style="fill:#111;stroke:#666"
                height="100%"
                width="2%"
            />
        </svg>
    </div>
    <div v-if="type=='numeric' || type=='both'" class="dataNumeric"> 
        <div> 
            {{values[0].toFixed(3)}}
        </div>
        <div> 
            {{values[1].toFixed(3)}}
        </div>
        <div> 
            {{values[2].toFixed(3)}}
        </div>

    </div>


</div>
</template>

<script>

export default {
  props: {
      values:Array,
      type:{
          type:String,
          default: 'numeric'
      },
      width:{
          type:Number,
          default: 100
      },
      
      },  
  data: function () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {
      length: function() { return this.values[2]-this.values[0] },
      xpos : function() { 
          if (this.values[2]==this.values[0]) return 50;
          return 99 * ((this.values[1] - this.values[0] ) / (this.values[2]-this.values[0] )) 
           },
      colors: function() {
        return [
            this.values[0]==this.values[2] ? "#00FF00": "#FF0000",
            "#00FF00"]
      }
    
  },
  methods: {
  }
}
</script>

<style lang="scss">
.showEq {
    border-radius: 0.3em;
    height:2em;
    text-align: center;
}


.dataNumeric {
    display:flex;    
    align-items: center;
    justify-content: center;
    margin-top:0.3em;
    >div {
        background:rgb(80, 11, 11);
        border-radius: 0.4em;
        padding:0 0.25em;
        margin: 0 0.4em;
    }
    >div:nth-child(2) {
        background:rgb(15, 15, 80);
        font-size: 1.3em;
    }
    >div:nth-child(3) {
        background:rgb(3, 94, 3);
    }

}
</style>