<template>

    <div class = "menuFrameContainer"
        :style="{ fontSize: screenMode=='vertical' ? '1.25em': '1em' }"
    >
        <div class="menuContainer">  
            <!-- ***************** HEADER ******************--->
            <div class="menuHeader">           
                <div class="menuHeaderOptions">
                    <div class="menuOptions" :class="{ vertical: screenMode=='vertical'}">
                        <div 
                            :class="{ menuActive: menuData.section == 'board' }"
                            @click="$emit('change-menu',{
                                menu:'configMenu',
                                data: {
                                    show:true,section:'board',
                                    stoneId:0,stonePage:0,
                                    }
                                })">{{t('ui.game.subject.board')}}
                        </div>                           
                        <div
                            :class="{ menuActive: menuData.section == 'acelerators' }"
                            @click="$emit('change-menu',{
                            menu: 'configMenu',
                            data: {
                                show:true,section:'acelerators',
                                stoneId:0,stonePage:0,
                            }
                            })">{{t('ui.game.subject.accelerators')}}
                        </div>
                        <div 
                            :class="{ menuActive: menuData.section == 'uiOptions' }"
                            @click="$emit('change-menu',{
                                menu: 'configMenu',
                                data: {
                                    show:true,section:'uiOptions',
                                    stoneId:0,stonePage:0,
                                    }                                    
                                })">{{t('ui.game.subject.options')}}
                        </div>
                        <div 
                            :class="{ menuActive: menuData.section == 'p2p' }"
                            @click="$emit('change-menu',{
                                menu:'configMenu',
                                data: {
                                    show:true,section:'p2p',
                                    stoneId:0,stonePage:0,
                                    }
                                })">{{t('ui.game.subject.p2p')}}
                        </div>
                        <div 
                            :class="{ menuActive: menuData.section == 'rules' }"
                            @click="$emit('change-menu',{
                                menu:'configMenu',
                                data: {
                                    show:true,section:'rules',
                                    stoneId:0,stonePage:0,
                                    }
                                })">{{t('ui.game.subject.rules')}}
                        </div>                     
                    </div>

                    <CloseButton @click="$emit('close-menu')" />

                </div>
            </div>
            <!-- ***************** OPTION ******************--->

            <!-- ***************** CONTAINERS ******************--->
            <div v-if="menuData.section=='acelerators'" class="contentContainer">
                <div class="menuYesNoOptions">
                    <div 
                        v-for="p,name,index in preferences.acelerators"
                        @click="
                            $emit('change-preference',
                                { 
                                    group:'acelerators',
                                    preference:name,
                                    value:!preferences.acelerators[name]
                                }
                            )"
                        :key="'h'+index"
                        class="menuYesNoOption"
                        >                    
                        <div style="max-width:25em;">{{t('ui.preferences.acelerators.'+name)}}</div>
                        <YesNoSwitch :key="'SwitchAcell'+index" :value="p" />
                    </div>
                </div>
            </div>


            <!-- ***************** CONTAINERS UI OPTIONS ******************--->
            <div v-if="menuData.section=='uiOptions'" class="contentContainer">
                <div class="menuYesNoOptions">
                    <div 
                        v-for="p,name,index in preferences.helpers"
                        @click="
                            $emit('change-preference',
                                { 
                                    group:'helpers',
                                    preference:name,
                                    value:!preferences.helpers[name]
                                }
                            )"
                        :key="'h'+index"
                        class="menuYesNoOption"
                        >                                        
                            <div style="max-width:25em;">{{t('ui.preferences.messages.'+name)}}</div>
                            <YesNoSwitch :key="'SwitchState'+index" :value="p" />
                        </div>
                </div>
            </div>

            <!-- ***************** P2P Options ******************--->

            <div v-if="menuData.section=='p2p'" class="contentContainer">
                <div class="menuYesNoOptions">
                    <div 
                        v-for="p,name,index in preferences.p2p"
                        :key="'h'+index"
                        class="menuYesNoOption"
                        >                                        
                            <div style="max-width:25em;">{{t('ui.preferences.messages.'+name)}}</div>
                            <YesNoSwitch 
                                v-if="(typeof p == 'boolean')" :key="'SwitchState'+index" :value="p" 
                                @click="clickPreference('p2p',name, !preferences.p2p[name])"                                
                                />
                            <div v-else class="optionsList" style="justify-content: flex-end;"> 

                                <div v-for="t in ['abak','classic']" 
                                    :key="t" 
                                    style="display:flex;align-items: center;"
                                >  

                                {{ t }}
                                <img :src="require('@/assets/img/symbols/type_'+t+'.svg')" />
                                <YesNoSwitch 
                                    :key="'SwitchState'+index"
                                    :value="preferences.p2p[name].indexOf(t) != -1"
                                    @click="clickMultiPreference('p2p', name, t )"
                                />
                                </div>
                            </div>
                        </div>
                </div>
            </div>

            <!-- ***************** CONTAINER RULES ******************--->
            <div v-if="menuData.section=='rules'" class="contentContainer">
                <div>
                    <GeneralRules 
                        :demoImageSize=fontSize*15
                        :menuData ="menuData"    
                        :screenMode="screenMode"
                        @change-menu="$emit('change-menu',$event)"
                    />
                </div>
            </div>

            <!-- ***************** CONTAINER BOARD ******************--->

            <div v-if="menuData.section=='board'" class="contentContainer"> 
                    <div class="menuYesNoOptions" style="display:flex;">
                        <div class="cpuOption" style="width:50%;" :class="{ longItem: screenMode == 'landscapefull' }">                   
                            <div style="display:flex;justify-content:space-around;">
                                <div>{{t('lobby.subject.color')}}</div>
                            </div>
                            <div class="optionsList">
                                <div 
                                    v-for='l in [0,1]' 
                                    :key="'g'+l"
                                    @click="$emit('change-preference',{ group:'ui',preference:'checkersColor',value:l})"
                                    class="Button goalButton"
                                    :class="{ optionActive: l == preferences.ui.checkersColor }"
                                >
                                    <div style="padding:0.3em;">
                                        <img style="width:3em;" :src="require(`@/assets/img/elements/checkercol${l+1}.svg`)">
                                    </div>                              
                                </div>
                            </div>
                        </div>     
                        <div class="cpuOption" style="width:50%;" :class="{ longItem: screenMode == 'landscapefull' }">                   
                            <div style="display:flex;justify-content:space-around;">
                                <div>{{t('lobby.subject.direction')}}</div>
                            </div>
                            <div class="optionsList">
                                <div 
                                    v-for='l in [-1,1]' 
                                    :key="'g'+l"
                                    @click="$emit('change-preference',{ group:'ui',preference:'direction',value:l})"
                                    class="Button goalButton"
                                    :class="{ optionActive: l == preferences.ui.direction }"
                                >
                                    <div style="padding:0.3em;">
                                        <img style="width:3em;" :src="require(`@/assets/img/elements/direction${2-Math.max(0,l)}.svg`)">
                                    </div>                              
                                </div>
                            </div>
                        </div>                           
                </div>  
                <div> 
                    <div class="menuYesNoOptions" style="display:flex;">
                        <div class="cpuOption" :class="{ longItem: screenMode == 'landscapefull' }" style="width:100%;">                   
                            <div style="display:flex;justify-content:space-around;">
                                <div>Dice Roll Animation</div>
                            </div>
                            <div class="optionsList">
                                <div 
                                    v-for="l in ['rotate','flash']" 
                                    :key="'g'+l"
                                    @click="$emit('change-preference',{ group:'ui',preference:'tipoAnimacionDados',value:l})"
                                    class="Button goalButton"
                                    :class="{ optionActive: l == preferences.ui.tipoAnimacionDados }"
                                >
                                    <div style="padding:0.3em;">
                                        {{ l }}
                                        <!-- img style="width:3em;" :src="require(`@/assets/img/elements/tipo_animacion_${l}.svg`)"-->
                                    </div>                              
                                </div>
                            </div>
                        </div>                           
                    </div>
                </div>
                <div class="menuYesNoOptions" style="margin-top:1em;">
                    <div>themes</div>
                    <div style="display:flex;flex-wrap: wrap;justify-content: center;"> 
                        <div 
                            v-for="p in themes"
                            :class="{ selected: p == preferences.ui.theme }"

                            style="border-radius: 0.5em;"
                            @click="
                                $emit('change-preference',
                                    { 
                                        group:'ui',
                                        preference:'theme',
                                        value:p
                                    }
                                )"
                            :key="'theme_'+p">  
                            <div 
                                style="margin:0.5em;padding: 0.5em;border-radius: 0.5em;display: flex;flex-direction: column; align-items: center;" 
                                :style="{ background: colors[p].background }"> 
                                <div >
                                    <svg width="3em" height="3em" viewBox="0 0 100 100">
                                    <!-- Square -->
                                        <rect width="100" height="100" rx="10" 
                                            :fill="colors[p].board.bar"                                
                                            />
                                        <rect x="10" y="10" width="80" height="80" rx="10" 
                                            :fill="colors[p].board.background"                                     
                                        />
                                        <polygon points="18,10 34,90 50,10" style="stroke-width:1;" :fill="colors[p].board.points[0].background" />
                                        <polygon points="50,10 66,90 82,10" style="stroke-width:1;" :fill="colors[p].board.points[1].background" />
                                        <circle cx="34" cy="30" r="12"  :fill="colors[p].checkers[0].background" />
                                        <circle cx="66" cy="30" r="12"  :fill="colors[p].checkers[1].background" />
                                    </svg>
                                </div>
                                <div> 
                                    {{p}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>         



        </div>
    </div>
</template>

<script>

    import GeneralRules from "../soldiers/GeneralRules.vue";
    import { useI18n } from 'vue-i18n';
    import YesNoSwitch from '../elements/YesNoSwitch.vue';
    import CloseButton from "../elements/CloseButton.vue";

	export default {
		name: 'ConfigMenu',
        props: ['text','preferences','fontSize','stoneHelp','menuData','screenMode','colors'],
        components: {GeneralRules, YesNoSwitch,CloseButton },
        data: function() {
            return {
                option:"default",
                title:"Menu Config",
                themes:Object.keys(this.colors),
            }
        },
        emits: ['close-menu','change-preference','change-menu','send-toast'],
        computed: {

        },
        methods: {	
            changeCheckerClass: function() {
                //console.log(event);
            },
            clickPreference:function(group,name,value) {
                this.$emit('change-preference',
                    { 
                        group:group,
                        preference:name,
                        value: value 
                    });
            },
            clickMultiPreference: function(cat,name,value) {    
                let list = JSON.parse(JSON.stringify(this.preferences[cat][name]));
                const index = list.indexOf(value);  
                if (index === -1) list.push(value); else list.splice(index, 1);
                if (list.length == 0 ) {
                    this.$emit('send-toast','you must keet at least one type');
                }
                else {
                    this.$emit('change-preference',
                    { 
                        group:'p2p',
                        preference:name,
                        value: list 
                    });
                }
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        }  

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .menuFrameContainer {
        position:absolute;
        width: 100%;           
        height: 100%;
        top:0;
        left:0;
        background:#000000AA;
    }

    .menuHeader {
        box-sizing: border-box;
        text-align: center;
        width: 100%;        
        background: $header-background;
        /*background: linear-gradient(  90deg , #1b1a17 0%, #795a20 100%);*/
        border-radius: 0.5em 0.5em 0 0;       
        border-bottom: 0.2em solid $header-bottom; 
    }

    .menuContainer {
        position:absolute;
        width: 95%;           
        height: 95%;
        top:2.5%;
        left:2.5%;
        border-radius: 0.5em;
        display:flex; 
        flex-direction: column;    
        align-items: center;
        justify-content: flex-start;
        background: $content-background;
        box-sizing: border-box;
    }

    .menuYesNoOptions {
        background:$sub-content-background;
        padding: 0.2em 1em;
        border-radius: 0.5em;
    }
    .menuYesNoOption {
        display:flex;
        justify-content:space-between;
        background:$box1-background;
        padding:0.1em 0.5em;
        border-radius: 0.5em;
        align-content: center;
        align-items: center;
        margin: 0.5em 0;        
    }

    .longItem {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    
    .menuHeaderOptions {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-left:5%;
        align-items: center;
    }

    .menuOptions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top:0.5em;
        >div {
            cursor: pointer;
        }
    }

    .menuOptions.vertical {
        >div {
        margin:0.3em;
        border-radius:0.3em;
        }
    }

    .menuOptions div {
        background-color:$tab-menu-normal-background;
        padding: 0.3em 1em;
        border-radius: 0.3em 0.3em 0 0;
        margin: 0 1em;
    }


    .menuActive {
        background-color: $tab2-menu-active-background !important;
    }


    .selected {
        background-color: $c3;
    }

    .contentContainer {
        padding:0.5em 1em;
        box-sizing: border-box;
        width:100%;
    }

    .contentTitle {
        text-align: center;
        font-size: 1.5em;
    }

    .cpuOption {
        background: $box1-background;
        color: $box1-color;
        border-radius:0.5em;
        margin:0.2em;
        padding:0.4em;
    }

    .optionsList {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .Button {
        background:desaturate($c13, 0%);
        margin:0.5em;
        padding:0.3em;
        border-radius: 0.5em;
        color:white;
    }

    @media (orientation: portrait) {
        .Button {
            margin: 0.1em 0.5em;
        }
    }

    

    .active {
        background:$c17;
        color:black;
        font-weight: bold;
    }

    .optionActive {
        background:$c17;
        border-radius: 1em;
    }



</style>
