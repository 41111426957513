export default {
  "FAQ": {
    "preguntas": {
      "1": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Является ли Abak Evolution более <span>сложной</span> игрой, чем нарды?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Честный ответ: да, но взамен вы получаете более увлекательную и динамичную игру. Добавляя классы в игру, вы придаете идентичность каждому солдату и должны учитывать его особенности. Кроме того, в Abak гораздо больше комбинаций для броска кубиков, так как порядок движения может повлиять на его результат."])}
      },
      "2": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насколько <span>отличается</span> Abak Evolution от классических нард?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные правила почти такие же, но добавление классов кардинально изменяет игру. <ul> <li><span>Новая измерение:</span> Вертикальная позиция шашки имеет значение. Это создает новое измерение: ось Y. Подумайте об этом так: в Abak вы не можете просто сказать, что в точке есть 3 белые шашки, вам нужно указать каждую в порядке.</li> <li><span>Больше комбинаций:</span> Каждая позиция в нардах имеет (3.346.200)² возможных позиций в Abak Evolution.</li> <li><span>Шашки имеют идентичность:</span> Вы должны учитывать их индивидуальные способности.</li> <li><span>Порядок движений имеет значение:</span> Если вы передвинете одну шашку перед другой, это может существенно изменить результат броска.</li> </ul>"])}
      },
      "3": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как я могу научиться играть в Abak Evolution?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте ресурсы на этом сайте, чтобы научиться играть, от нардов до Abak. Интерфейс игры является хорошим ресурсом, так как он подчеркивает правила."])}
      },
      "4": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нужно ли мне знать, как играть в нарды, чтобы научиться Abak Evolution?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да. Вам нужно хотя бы базовое знание правил классических нард."])}
      },
      "5": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему существует ограничение в 5 шашек на точку?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотя это правило существует в классических нардах, оно не часто используется. Оно добавляет много нестабильности, особенно при снятии шашек с доски. Abak использует его, потому что я люблю его, а также по практическим причинам: видимость шашек."])}
      },
      "6": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Является ли Abak Evolution медленнее или дольше, чем нарды?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как только вы привыкнете к различным солдатам, скорость игры будет примерно такой же, как в классических нардах. Поскольку генерал может двигаться назад, иногда игра длится дольше (по количеству ходов), чем нарды."])}
      }
    },
    "header": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас может быть вопрос"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите его здесь"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы"])}
    }
  },
  "about": {
    "history": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я никогда не садился, чтобы пытаться изобрести новые игры, это просто произошло. Я играл в нарды с другом, немного утомившись от однообразия правил, и эта идея пришла мне в голову. Это было на острове Пасхи, сентябрь 1999 года."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я делаю это бесплатно, потому что в глубине души верю, что эта игра является преемником нард, и если это станет реальностью в течение моей жизни, последствия будут более чем достаточными в качестве вознаграждения. Поскольку я являюсь программистом, я смог сделать это все самостоятельно в свое свободное время, получая удовольствие от процесса."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда я разрабатывал веб-приложение, чтобы поделиться своими правилами с миром, я думал, что Abak Evolution станет хитом в тот момент, когда он выйдет онлайн, что игроки в нарды примут новые правила, думая: «О, как это не пришло мне в голову!» Сюрприз, сюрприз: они не приняли. Когда я пишу это, прошло четыре полных года с тех пор, как Abak появился в сети, потрачены тысячи долларов на платные маркетинговые кампании, и все же у него не более пары сотен постоянных игроков."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution сталкивается со многими барьерами для входа, которые оказались трудно преодолимыми:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот проект - эпическое, некоммерческое, одиночное шоу."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меня зовут Сэми Гариб, я чилийский программист."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за ваше время."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С тех пор прошло более 20 лет, и я вложил тысячи часов в разработку: правил, механики, приложения, веб-сайта, маркетинговых материалов и т.д."])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таковы нынешние фронты моей битвы. Я работаю над ними в свободное время, приоритизируя учебные материалы, так как считаю, что это самый тяжелый барьер. Я трачу слишком много времени на искусственный интеллект, потому что просто люблю его, хотя движок уже играет довольно хорошо и не нуждается в более сильном игроке."])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О Abak Evolution"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краткая тирада."])},
      "g": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значительно более сложная, чем классические нарды, и имеет крутой кривой обучения."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игроки в нарды очень увлечены и любят свою игру, они видят Abak как угрозу или незваного гостя."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Многие люди любят играть в нарды с людьми, а не с компьютерами, и для этого нужна критическая масса игроков."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У меня нет достаточных ресурсов для масштабирования коммуникаций и создания контента."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение не является передовым в области нардов и не имеет многих инструментов, которые понравятся продвинутым игрокам в нарды."])}
      },
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
      "thanks-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарности"])},
      "rant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тирада"])}
    },
    "history1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я научился играть в нарды, когда был ребенком, и много играл с семьей и школьными товарищами."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позже в жизни я научил играть многих своих друзей. В конце концов, я достиг такого уровня, что не мог получать удовольствие от игры без денег."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я изобрел Abak во время поездки на остров Пасхи в августе 1999 года от скуки."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первая версия была очень отличной от текущей, и никто из моих друзей не продолжал играть в нее, как только я их научил. Поэтому она ушла в подвалы моего разума на долгие годы."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Однажды на работе в 2007 году я научил некоторых коллег играть в нарды, и когда они отказались играть со мной на деньги, скука снова привела меня к тому, чтобы научить их игре, которую я однажды изобрел."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Но сначала я внес изменения, чтобы сделать ее великой (раненые и некоторые ограничения)."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Она была таким успехом среди них. С тех пор каждый день кто-то (обычно с моим участием, все это нужно сказать) играет в Abak во время обеда на моей работе."])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти коллеги поощряли меня создать программную версию игры, но вы знаете, жизнь и ее приоритеты."])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так прошло много лет до сентября 2009 года. У меня был несчастный случай, который дал мне две полные недели дома."])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Было создано программное обеспечение, полное ошибок, которое потребовало столько усилий и энергии, что снова уснуло на годы."])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С тех пор несколько вспышек энтузиазма заставляли меня работать над ним, пока я не достиг уровня, на котором мог поделиться им с миром."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я чувствую себя таким счастливым, что хорош в программировании и компьютерах. Если бы я не был, у меня бы никогда не было возможности сделать это возможным и выпустить бесплатную версию, чтобы вы могли с ней познакомиться."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так вот оно, одиночное шоу (за исключением графики, большое спасибо www.vrweb.cl)"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надеюсь, вам понравилось"])}
    },
    "donate": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дни"])},
      "hh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧАСЫ"])},
      "devtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время разработки"])},
      "onlinesince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн с"])},
      "playedgames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыгранные игры"])},
      "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные пользователи"])},
      "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доходы"])},
      "donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма пожертвований на данный момент"])},
      "otherincomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие доходы"])},
      "expenses_op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операционные расходы (ежемесячно)"])},
      "adv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реклама"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы"])},
      "devtimeprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка и создание контента"])},
      "estadisticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожертвовать"])},
      "necesities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кроме моего времени, у меня есть прямые расходы по многим различным темам"])},
      "necesity": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка маркетинга (видео + графика)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медиа маркетинг (Adwords + Facebook)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр данных (Amazon AWS)"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка интерфейса (дизайн + переводы)"])}
      },
      "closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вам понравилась игра и вы можете помочь, это будет огромной помощью. Спасибо заранее."])}
    },
    "samy": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я увлеченный программист, родился в Чили в 1973 году. Как потомок палестинцев, я вырос с сильной арабской культурой, одним из элементов которой являются нарды."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я работаю в собственной компании с 1999 года, VRWEB, и хотя моя основная роль в маркетинге и продажах, я люблю кодировать. Abak - моя песочница."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я отец троих детей и повар для четверых."])}
    },
    "thanksto": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На протяжении многих лет мне помогали люди со всего мира: переводы, технические советы, советы по игре, фан-арт и т.д."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В особой категории находятся те, кто, даже не помогая, взаимодействовали со мной и проявляли такой энтузиазм, что они стали катализатором для моих целей:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["И последнее, но не менее важное, есть группа людей, которые работали над потрясающими проектами с открытым исходным кодом, которые составляют основу платформы Abak."])}
    }
  },
  "ayuda": {
    "generales": {
      "delsoldado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила класса"])},
      "situaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ситуации"])},
      "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите на солдата, чтобы получить помощь"])},
      "highest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала вы должны двигать самую верхнюю шашку каждой точки."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете двигать шашку, если на ней находится другая шашка."])}
      },
      "5max": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит 5 шашек на каждую точку."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В полевых позициях вы можете складывать только пять шашек на каждую точку."])}
      }
    },
    "piedras": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Солдат"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["как в классических нардах"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Солдат - это обычная шашка классических нард. У каждой команды их 9."])}
      },
      "2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерал"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["двигается вперед и назад"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерал может двигаться вперед и назад с некоторыми ограничениями. Он может двигаться только в одном направлении за ход и может идти назад только один раз."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если он идет вперед, он не может двигаться назад в том же ходу"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если он идет назад, он больше не может двигаться в течение хода."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Он не может двигаться назад, если все остальные солдаты его команды находятся в доме команды."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После выхода с бара он МОЖЕТ двигаться назад."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если он идет вперед"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если он идет назад"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не может идти вперед"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не может идти назад"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["может идти вперед"])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страж"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сильнее остальных"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стражи как два солдата вместе, но могут быть захвачены друидом и побиты другими стражами или другими солдатами, если оба могут ударить. У каждой команды их 2."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["блокирует как два солдата"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["может быть захвачен друидом"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["может быть побит другими стражами"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["двое солдат могут его побить"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы побить стража двумя (не стражами) солдатами, вы должны иметь возможность побить его <b>обоими</b> в <b>последовательных движениях</b> в течение хода."])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["захватывает и удерживает"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид может захватить противника, если он один в средней части поля. Захваченная шашка не может двигаться, пока друид находится над ней."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид захватил солдата"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид в безопасности"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид захвачен друидом"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид будет побит"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутри любого дома друид ведет себя как обычный солдат."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["друид может захватить одинокого солдата"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["друид не может захватить заблокированную колонну"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["средняя часть поля"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["друид захватывает"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["черный дом"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["белый дом"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["друид бьет"])}
      },
      "5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четный раненый"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["играет только <span>ЧЕТНЫЕ</span> числа"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четный раненый может играть только ЧЕТНЫЕ числа"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выходе с бара он может играть любое число."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При снятии с поля он может играть любое число."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четный раненый может двигаться только если пройденное расстояние является четным числом, или любое число, если он выходит с бара или снимается с поля."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При движении между точками (<span>треугольниками</span>) обе будут всегда <span>одного цвета</span>. <span>Поэтому символ</span>."])}
      },
      "6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечетный раненый"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["играет только <span>НЕЧЕТНЫЕ</span> числа"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечетный раненый может играть только НЕЧЕТНЫЕ числа"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выходе с бара он может играть любое число."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При снятии с поля он может играть любое число."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечетный раненый может двигаться только если пройденное расстояние является нечетным числом, или любое число, если он выходит с бара или снимается с поля."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При движении между точками (<span>треугольниками</span>) обе будут всегда <span>разного цвета</span>. <span>Поэтому символ</span>."])}
      },
      "56": {
        "leavingorcaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выходе с бара или снятии с поля он может двигаться на любое число."])}
      }
    }
  },
  "contact": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с Abak Evolution"])},
    "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вам нужно связаться со мной, используйте один из этих вариантов"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете найти Abak Evolution в различных социальных сетях и написать мне там."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или отправьте письмо на email"])}
  },
  "hall": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зал славы"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зал славы"])}
  },
  "intro": {
    "content": {
      "tituloheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> is a Backgammon variant that adds <span>classes of checkers</span> to the game, each with its own set of features and <span>unique behavior</span>."])},
      "isaboardgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> is a two-player abstract strategy board game. You will need luck and your best tactics if you want to win in this remake of the classical <span>Backgammon</span>."])},
      "backgammon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon<span>²</span>"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution </span>  uses the same rules of backgammon, but adds 5 classes of soldiers. This small change provides outstanding consequences."])},
      "gotoabakme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can learn and play online here."])},
      "abakapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will find a fully featured webapp where you can play against a strong CPU opponent or real people."])},
      "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at our social media accounts"])},
      "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
      "printrules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find stickers templates for your board, a printing version of the rules, and other tools here:"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "nonprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> is an epic, non-profit, one-man effort to spread these amazing rules to humanity. <br> Invented, developed, maintained and financed by <span>Samy Garib</span>, a Chilean Software Developer."])},
      "sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let"])},
      "entonces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then"])},
      "classbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a class-based backgammon variant"])},
      "quees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a class-based backgammon variant"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding classes changes drastically the game. The vertical position of a checker matters and therefor the movement's order. <span>Abak Evolution</span> adds a new dimension to <span> Classic Backgammon </span>"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn"])},
      "moredetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more details"])},
      "timagen": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a classist backgammon, just like you"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backgammon on steroids"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Backgammon)²"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon, for grown ups."])}
      },
      "abakisto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak is to BackGammon as Chess is to Checkers"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules"])}
    },
    "privacy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policies"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information Collection, Use, and Sharing"])},
      "content": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is an online platform to play the Abak Rules, a backgammon's Rules expansion."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is a one-man, non-professional, non-proffit effort to spread this rules."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform  is free, as all other Abak Evolution Assets. "])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Ads free, and with optional registration capabilities."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not perform any external activity with users's data. User Data is only used to store the user state and ranking."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not share user's information with other parties."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relies only on external login platforms sush as facebook, Google and Twitter, using Google's Firebase Auth Service as a conector hub."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will not publish any information on your social accounts, neither will read your contacts information. "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps on social platforms will only collect your personal avatar an name."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uses cookies to store your account related data. External login providers use their own cookies. "])}
      }
    }
  },
  "learn": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учебный центр"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы для понимания и изучения Abak Evolution"])},
      "contenido1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Несколько ресурсов, которые позволят вам играть в Abak Evolution"])},
      "videoT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал на YouTube"])},
      "videoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотрите канал на YouTube с вводным видео и короткими уроками."])},
      "versusT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak против нард"])},
      "versusC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О том, насколько отличается Abak от классических нард"])},
      "PDFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила в формате PDF"])},
      "PDFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачайте готовый к печати набор файлов для использования в автономном режиме"])},
      "reglasT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Книга правил"])},
      "reglasC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полные правила Abak Evolution на одной странице. Справочный инструмент для печати или проверки на вашем телефоне"])},
      "tablaT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для вашей доски"])},
      "tablaC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Набор наклеек для вашей физической доски"])},
      "specs": {
        "specs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Характеристики"])},
        "typeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
        "typeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абстрактная<br>стратегия"])},
        "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годы"])},
        "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игроки"])},
        "complexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сложность"])},
        "mechanics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Механика"])},
        "turnbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошаговая"])},
        "dicedriven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основе бросков кубиков"])},
        "perfectinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полная информация"])},
        "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сложная"])}
      }
    },
    "faq": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас может быть вопрос"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите его здесь"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы"])}
    },
    "files": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Набор полезных файлов"])}
    },
    "blog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блог Abak Evolution"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список статей на различные темы, связанные с Abak, в основном для целей SEO. Но если у вас есть время."])}
    },
    "introvideo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотрите вводное видео"])}
    }
  },
  "lobby": {
    "subject": {
      "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Противник"])},
      "human": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учебник"])},
      "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учебники"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лево"])},
      "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друзья"])},
      "players": {
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В сети"])},
        "invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["невидимые игроки"])},
        "last_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
        "prefered_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпочитаемые типы"])},
        "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не в сети"])},
        "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играет"])},
        "atlobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В лобби"])}
      },
      "friendship": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ожидании"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонено"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановлено"])},
        "friendship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дружба"])},
        "notyet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас еще нет друзей. Вы можете отправить запрос на дружбу онлайн-игрокам из раздела онлайн-игроков."])},
        "requestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить запрос на дружбу?"])},
        "newrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас новый запрос на дружбу"])},
        "onlinefriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друг в сети"])}
      },
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Матч"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
      "game_visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посетители игры"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посетители"])}
    },
    "alerts": {
      "nogamestojoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет игр для присоединения."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon"])},
    "action": {
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играть"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учиться"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть"])},
      "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться со мной"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практика"])},
      "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыграно"])},
      "won": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выиграно"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующий"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
      "sharein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться в"])},
      "cpypasteshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопируйте и поделитесь этой ссылкой"])},
      "shareabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделитесь Abak с друзьями!"])},
      "shareboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделитесь своей игрой"])},
      "howtoshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот инструмент создаст элемент для вас, чтобы поделиться. Вы можете добавить необязательное описание"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр"])},
      "contactsocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь со мной в предпочитаемой социальной сети"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чат"])}
    },
    "user": {
      "subject": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
        "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
        "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медали"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
        "howemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При сохранении вы получите сообщение с ссылкой для подтверждения"])},
        "checkvalidationemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны подтвердить свою электронную почту. Вы можете попробовать отправить сообщение снова."])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневно"])}
      },
      "action": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить снова"])}
      },
      "message": {
        "knownguestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы зарегистрированы только для этого устройства. Используйте <b>социальный логин</b>, чтобы создать постоянного пользователя для нескольких устройств."])},
        "guestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот пользователь связан только с этим устройством. Если вы хотите восстановить этого пользователя позже или продолжить свои игры на разных устройствах, предоставьте действующий адрес электронной почты."])}
      }
    },
    "messages": {
      "newinvitation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение сыграть"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы примете, вы продолжите этот матч после окончания другого."])},
        "headding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть приглашение присоединиться к игре против игрока"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это приглашение было выдано всем активным игрокам. Ваш ответ анонимный."])},
        "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это приглашение было выдано непосредственно вам."])}
      },
      "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет матчей для присоединения."])},
      "p2pnomatchesoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши варианты"])},
      "playandwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играть против сервера и ждать приглашения"])},
      "inviteonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить приглашения онлайн-игрокам, играющим против сервера"])},
      "createhuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать игру и ждать присоединения игрока"])},
      "error": {
        "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то не так, я не смог подключиться к серверу."])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете сообщить об этом инциденте по адресу"])}
      },
      "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать язык"])},
      "unfinishedgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть незаконченная игра. Завершите её или сдавайтесь."])},
      "wontunlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот уровень сложности не дает возможности разблокировать следующую практику."])},
      "unlocklevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны разблокировать этот уровень практики."])},
      "inviteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить $$(player)$$ на игру."])},
      "nomoreplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше нет доступных игроков для приглашения."])}
    },
    "hallfame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зал славы"])}
    },
    "chat": {
      "message": {
        "tooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение слишком короткое"])}
      }
    },
    "message": {
      "choosepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень практики"])},
      "newpracticelevelavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступен новый уровень практики. Выберите его в списке."])},
      "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работаем. Пожалуйста, подождите"])},
      "becomeabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете играть в <b>Abak Backgammon</b> и <b>Egyptian Backgammon</b> в этом приложении."])},
      "becomeabakerhowto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы <b>разблокировать</b> все функции, вы должны стать <b>Abaker</b>. Завершите уровни <b>практики</b> или выиграйте один матч abak, играя против продвинутого или экспертного уровня."])},
      "welcome": {
        "customize1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить пользователя"])},
        "customize2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените ваше имя пользователя и аватар."])},
        "stats1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
        "stats2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотрите свою историю как игрока"])},
        "hall2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Познакомьтесь с лучшими Abakers"])},
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взгляните на эти инструменты в любое время."])}
      },
      "caninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете пригласить доступных игроков"])},
      "cantinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете приглашать других игроков, пока не станете Abaker"])},
      "noonlineplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В сети нет игроков"])},
      "onlyabakersorfriendscaninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете создавать приглашения игрокам, которые не являются вашими друзьями, если вы не Abaker."])},
      "friendship": {
        "requestsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос отправлен"])},
        "requestexists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос уже существует"])},
        "responsesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ отправлен"])}
      },
      "emptylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пустой список"])}
    }
  },
  "meta": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О Abak Evolution Backgammon"])},
      "history": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak родился в 1999 году после уникальной цепи событий и условий."])}
      },
      "rant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как Abak Evolution Backgammon не стал тем, что я ожидал"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak не стал хитом, как я думал, но я никогда не откажусь от этого проекта."])}
      },
      "samy-garib": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сэми Гариб - Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сэми Гариб - чилийский разработчик программного обеспечения, изобретатель, дизайнер и разработчик Abak Evolution Backgammon."])}
      },
      "donate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожертвования для поддержки Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon - это однопользовательский проект, бесплатный и без рекламы, и нуждается в помощи."])}
      },
      "thanks-to": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon благодарен некоторым людям."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Многие люди были частью этого проекта. От помощи в переводе до невероятного энтузиазма. Всё хорошо."])}
      }
    },
    "boardgame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настольная игра Abak Evolution Backgammon"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak присутствует на многих сайтах социальных сетей. Ознакомьтесь с ними здесь и найдите способы связаться со мной."])}
    },
    "learn": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изучите Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы для изучения нардов и Abak. Найдите здесь книги правил в формате PDF, интерактивный учебник и многое другое."])}
    },
    "print": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распечатайте правила Abak Evolution Backgammon"])}
    },
    "resources": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы для изучения Abak Evolution"])}
    },
    "rules": {
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Солдат - класс в Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действует как обычный солдат в нардах."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерал - класс в Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единственный солдат, который может двигаться назад в игре. Он управляет боем."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стражи - класс в Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У каждой команды есть два стража. Они сильнее других солдат и их нелегко захватить."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид - класс в Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид захватывает противника, обездвиживая его, вместо того чтобы захватывать."])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четный раненый - класс в Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четный раненый может двигаться только на четные числа."])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечетный раненый - класс в Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечетный раненый может двигаться только на нечетные числа."])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak имеет 6 классов солдат: солдат, генерал, друид, стражи и два класса раненых."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила - Abak Evolution Backgammon"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ - Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот FAQ о различиях между Abak и нардами"])}
    },
    "abak": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon. Вариант."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution - это настольная игра для двух игроков, абстрактная стратегия. Вариант нард, добавляющий классы шашек к классической игре."])}
    },
    "tutorial": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учебник по нардам и Abak Evolution"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это интерактивный учебник по нардам. Вы узнаете о доске, движениях, баре и как снимать шашки. Вы также найдете продвинутые учебники с головоломками."])},
      "backgammon": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учебник по нардам"])}
      },
      "abak": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учебник по Abak Evolution"])}
      }
    }
  },
  "pdf": {
    "rules": {
      "generales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти правила немного изменяют механику игры. Обратите внимание, что одно из них довольно очевидно, но его не нужно было применять в классических нардах, а другое уже существует в некоторых версиях игры."])},
      "intro0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution Backgammon</b> - это вариант нард, который добавляет 5 классов шашек, каждая из которых имеет свои особенности и уникальное поведение."])},
      "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот документ объясняет правила <b>Abak Evolution Backgammon</b>. Требуется предварительное знание классических нард."])},
      "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть небольшие изменения по сравнению с оригинальными правилами, которые становятся естественными и очевидными, как только вы добавляете классы."])},
      "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> добавляет 5 классов солдат, плюс обычный. Каждый из этих классов имеет разные свойства."])},
      "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете играть в Abak Evolution в"])},
      "intro5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или наклеить наклейки на вашу доску, которые можно найти здесь:"])},
      "intro10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти правила доступны на"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый класс солдат имеет свои собственные свойства, которые изменяют его поведение."])},
      "start0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная позиция"])},
      "start1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная позиция очень похожа на нарды, но вместо двух шашек сзади, вы начинаете с 4:\n<ul> <li>Раненые (⨯2) начинают сзади <span class='etiqueta-posicion'>[п.24-1]</span> <span class='etiqueta-posicion'>[п.23-1]</span>, каждая размещена так, чтобы достичь 18-й точки с максимальным числом на кубике.</li>\n<li>Друид начинает с ними, как можно дальше назад <span class='etiquета-posicion'>[п.24-2]</span>. Его радиус действия - средняя часть поля, поэтому лучше подождать сзади.</li>\n<li>Стражи (⨯2) находятся рядом с домом <span class='etiquета-posicion'>[п.13-3]</span> <span class='etiquета-posicion'>[п.8-2]</span>, готовы формировать структуры, но не готовы двигаться, оба они в начале находятся под солдатом.</li>\n<li>Генерал начинает дома <span class='etiqueta-posicion'>[п.6-4]</span>, чтобы помочь формировать структуры в обоих направлениях.</li>\n<li>Остальные, обычные солдаты, начинают в: <span class='etiquета-posicion'>[п.23-2]</span><span class='etiqueta-posicion'>[п.13-1]</span><span class='etiqueta-posicion'>[п.13-2]</span><span class='etiqueta-posicion'>[п.13-4]</span><span class='етiquета-позиция'>[п.8-1]</span><span class='етiquета-позиция'>[п.8-3]</span><span class='етiquета-позиция'>[п.6-1]</span><span class='етикета-позиция'>[п.6-2]</span><span class='етикета-позиция'>[п.6-3]</span> </li>\n</ul>\n"])},
      "bidimensional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> превращает нарды в <b>двухмерную</b> игру. Это означает, что вертикальная позиция шашки в колонке имеет значение, и поэтому порядок <b>движений</b> в ходу становится новым фактором в игре."])},
      "imagina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представьте возможности"])},
      "classic_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта позиция вашей команды в классических нардах"])},
      "millions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эквивалентна миллионам комбинаций в Abak Evolution. Вот лишь небольшой пример:"])},
      "moves_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок движений"])},
      "drastically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок движений может кардинально изменить результат игры."])},
      "druid_traps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом сценарии друид захватывает солдата."])},
      "odd_captures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом сценарии нечётный раненый захватывает солдата"])},
      "encuentra_abak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как играть"])},
      "redes_sociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите <b>Abak Evolution в социальных сетях</b>"])},
      "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачайте приложение для игры на вашем телефоне. Найдите его в магазине приложений вашего телефона."])},
      "play_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Откройте веб-браузер и перейдите по следующему адресу:"])},
      "capturar_guardia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы побить стража двумя (не стражами) солдатами, вы должны быть в состоянии побить его <b>обоими</b> в <b>последовательных движениях</b> в течение хода."])},
      "abak_vs_backgammon_titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak vs Классические нарды"])},
      "abak_vs_backgammon_cuerpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотя единственное отличие Abak Evolution от классических нард - это добавленные классы, это значительно изменяет игру и имеет два основных последствия."])},
      "abak_vs_backgammon_combinaciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak имеет"])},
      "abak_vs_backgammon_combinaciones2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["возможных позиций для любой данной позиции в классических нардах"])},
      "subject": {
        "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Солдаты"])}
      }
    }
  },
  "piepagina": {
    "texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> - это эпическое, некоммерческое, одиночное усилие по распространению этих удивительных правил среди человечества. <br> Изобретено, разработано, поддерживается и финансируется <span>Сэми Гарибом</span>, чилийским разработчиком программного обеспечения."])},
    "privacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])}
  },
  "reglas": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие правила"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление классов шашек имеет последствия"])},
      "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В каждой точке может быть не более 5 шашек."])},
      "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала должна быть перемещена самая верхняя шашка каждой точки."])},
      "porque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К правилам нард добавляются два простых общих правила."])},
      "startpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная позиция в Abak Evolution"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление классов кардинально изменяет игру. Вертикальная позиция шашки имеет значение, и поэтому порядок движений. <span>Abak Evolution</span> добавляет новое измерение к <span> классическим нардам </span>"])},
      "mismasreglas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution </span> использует те же правила нард, но добавляет 5 классов солдат. Это небольшое изменение имеет значительные последствия и требует двух новых очевидных и естественных правил."])},
      "thisguys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Те же правила нард, с этими ребятами."])},
      "addsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавляются классы солдат, каждый с разными особенностями."])},
      "startposcontenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Начальная позиция в Abak Evolution</span> очень похожа на начальную позицию в нардах, но добавляет <span>классы</span> и имеет 4 шашки сзади. В стиле Nackgammon."])},
      "ttd_titulo": null,
      "ttd_texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто представьте возможности"])}
    }
  },
  "tips": {
    "general-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерал в ловушке."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерал очень важен и не может двигаться."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскольку он может двигаться назад, он очень полезен для помощи раненым вернуться домой и играет ключевую роль в максимизации ваших шансов на защиту и строительство дома. Постарайтесь держать его наверху и сделать его доступным."])}
    },
    "druid-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид в ловушке."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид может иметь большое влияние на игру."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если он обездвиживает противника, это даст вам много контроля над игрой. Постарайтесь держать его наверху и сделать его доступным."])}
    },
    "tooclose": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид слишком близко к вашему дому."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друид - смертельное оружие, но..."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Его большая сила находится в средней части поля. Старайтесь не приближаться к своему дому, потому что там он теряет свою силу и ведет себя как обычный солдат."])}
    },
    "inmobilized-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть обездвиженный солдат."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш солдат под друидом противника, но он не защищен."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте ударить его, прежде чем ваш противник поставит еще одного солдата сверху."])}
    },
    "inmobilized-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть обездвиженный солдат."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш солдат под друидом противника, и вы не можете его ударить."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте поймать его и контролировать игру как можно больше."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте вашего генерала, чтобы тратить кубики, двигаясь назад."])}
    },
    "inmobilized-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас беспорядок."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы обездвижили его друида, но он также обездвижил вас."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планируйте это. Вы в преимуществе."])}
    },
    "inmobilizing-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы обездвижили солдата противника"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Его солдат под вашим друидом, но ваш друид не защищен."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте поставить еще одного солдата сверху, чтобы защитить вашу позицию."])}
    },
    "inmobilizing-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы обездвижили солдата противника."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы обездвижили солдата противника, и ваш друид защищен. Отличная работа!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете бежать и бить в одном ходе. Держите солдат под рукой для нужного момента."])}
    },
    "inmobilizing-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас беспорядок."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы обездвижили его друида, но он также обездвижил вас."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планируйте это. Вы в невыгодном положении."])}
    },
    "even-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четный раненый в ловушке"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш четный раненый в ловушке и не может двигаться."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам нужно найти способ заставить вашего противника освободить его позицию."])}
    },
    "even-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четный раненый почти в ловушке"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш четный раненый может скоро попасть в ловушку."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте осторожны и постарайтесь уйти как можно скорее."])}
    },
    "odd-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечетный раненый в ловушке"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш нечетный раненый в ловушке и не может двигаться."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам нужно найти способ заставить вашего противника освободить его позицию."])}
    },
    "odd-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечетный раненый почти в ловушке"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш нечетный раненый может скоро попасть в ловушку."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте осторожны и постарайтесь уйти как можно скорее."])}
    },
    "guard-not-on-floor": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш страж недоиспользован"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стражи могут удерживать точку в одиночку. Чтобы их захватить, два солдата противника должны ударить их за один ход, что является событием с очень низкой вероятностью."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лучше держать их на уровне пола, чтобы использовать их полную мощь."])}
    }
  },
  "tutorials": {
    "intro": {
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this set of tutorials you can learn Classic Backgammon rules and Abak Evolution rules. What would you like to learn?"])},
      "lback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Backgammon.          \n"])},
      "lback2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't know how to play Backgammon. I wan't to learn from scratch. "])},
      "labak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Abak."])},
      "labak2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know how to play Backgammon, I want to learn how to play Abak. "])},
      "whyask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is just to set a filter you can change later."])}
    },
    "options": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncompleted"])}
    },
    "level": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring your soldier to safety."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to ['move'] your soldier. "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one soldier at the end of the board and no enemies. Roll the dice and move your soldier until it gets home, and then remove it from the board to save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run home"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon's objective is to bring all of your checkers (['soldiers']) home. You do that by rolling two dice [13,15] and moving a soldier the number of spaces each die tells you."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear Off"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once all your soldiers are home, you can ['remove'] them (['bear off']) from the board. The first player that ['bears off'] all of its soldiers from the board wins."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dice Roll"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each dice roll will give you two movements if the dice are of different value and four if they are equals."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the roll button to, obviously, roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now move the soldier twice, one for each die, by clicking on it and selecting the landing column."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have played all your valid moves. Confirm you have finished with the button."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent!"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed your turn. Keep it going until your soldier is safe (bore off)."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent is not playing, so play again. Roll."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you have a double that gives you four [!4] moves of [d13] spaces each. Move them all."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done. Confirm your move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your soldier is now at home."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When all your soldiers are at home, you can remove them from the board (beat them off). Roll your dice."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Roll."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can choose the ['outside'] of the board [!0] to move your soldier."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, you have finished the game."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Finished"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That’s it. You have removed 'all' of your soldiers and finished the game. "])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about blocked positions in the next chapter."])},
        "11b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! do it again. Move with the other dice."])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's home"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump across the opponent's soldiers to ['escape'] his ['home'] and ['capture'] his soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two soldiers ['[s11]×2'] at the end of the board. Avoid the adversary ['[s12]×5']  and bring them home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked Positions"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A soldier can land at any position the opponent does not block. A position is blocked when the opponent has two or more soldiers at it."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT the addition"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" You can move different soldiers with each die or the same soldiers with both die, but remember those are two movements, and each has to have an open position to land."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on the soldier in position [23], you will see it can't move any die. So it can't move the addition of both dice. You must move the other soldier."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made all your moves. Now confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blots"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a soldier is alone in a position (['a blot']), it can be captured by an opponent soldier who lands over it. "])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the soldier landing with one soldier over it."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have sent your opponent’s soldier to the bar!."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Captured."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has a soldier on the bar, it has to return to the board for the team to keep playing. If it can't escape from the bar, no other soldier can move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to escape from the bar in the next chapter."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't Capture the soldier!"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to capture the lonely soldier. Try again!"])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board description."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this quick tour on the Backgammon Board."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has [!24] ['positions'], called ['points'], divided in [!4] quadrants."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Positions"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus the Bar [#B0] [#B1], and a place outside the board [0] [25], for rescued soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player's Home Board is in the ['bottom-right'] quadrant. ['Points [1] to [6]']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponet's Home Board is on the ['top-right'] quadrant. ['Points [24] to [19]']"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is no man's land. ['Points [7] to [18]']"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['bar'] is the position where ['captured'] soldiers are kept. [#B0][#B1]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Board"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['out-board'] ['[!0]-[!25]'] is the position you put your ['beared off'] soldiers."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inverted Board"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that the board could be inverted on the X axis, homes being on the left side. You can always can change the board direction in the menu."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each player has [15] soldiers."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yours: [s11] , Opponent: [s12]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opposite Direction "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players move in opposite directions. Player 1 ([s11]) moves from [24] to [1], towards his home."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent’s Direction"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player 2 ([s12]) moves from [1] to [24]."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll and Move"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game is played in turns, each player roll two dice [d11][d12] and moves his soldiers."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring them home."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objective of the game is to bring your soldiers home ['Points [1] to [6]'], and once all of them are home, bear them off [0]."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to move soldiers next."])}
      },
      "b2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's the Bar!"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been [captured]. Learn how to escape from the [Bar]."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent has captured two soldiers and sent them to the bar. Release them."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bar is the column in the middle of the board ([#B0] and [#B1]). It holds soldiers that have been ['captured']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two ['captured'] soldiers in this board [s11][s11]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers in the Bar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has soldiers in the opponent’s bar, he ['must'] release them before he can make any other move."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll to Escape"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You escape from the bar entering the adversary's home from its farest place, as shown with the dice."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to escape."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this case, positions [19][dr6] and [20][dr5] are blocked, and the rest are open. Position [!22][d13] has a blot."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can release only one soldier now, at position [!21][d14]. Position [20][d15] is blocked."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because you left a soldier in the bar [B1], you can't use the other die to move. You will have to wait. Confirm your move."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again the dice"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can land at position [!22][d13], and capture your opponent. Once all your soldiers are off the bar, you can move [d16] with any soldier."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awesome. Now confirm."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free to move!."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Now your soldier can keep running free. You are all set."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the next chapter, you will learn how to bear off."])}
      },
      "b3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off all your soldiers"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn the rules of ['bearing off'] your ['soldiers']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of your soldiers are inside your home. Now you can ['remove '] them from the board in a process called ['bearing off ']."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point [!0]"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To bear off your soldiers, you move then outside the board, to ['point [!0]']"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land exactly in [!0]"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Soldier has to move the ['exact'] number of ['spaces'] to land there."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move from higher points."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is no soldier at the point were the movement should begin, and there are soldiers in a ['higher point'], you must move those soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off from the highest point"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are no soldiers in a ['higher point'], you can and must move the soldier in the highest point, to point [!0], bearing it off."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Situation"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here, your soldier in the highest point is in point [5]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d16]."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], less than [6], you bear off from [5]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d15]."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You land exactly at [0] from [5]. You can bear off."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d14]."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], more that [4], you have to move from the higher point, from [5] to [1]."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d13][d12][d11]."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose to bear off a soldier, or make a valid move inside the board."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's do This. Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have soldiers on [6], but being [5] the highest position you have, you can bear off one soldier. The [d14], you will have to move."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have 3 soldiers in position 1. You can bear them off, but you can also move some soldiers from behind."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your soldiers are now way before position [6]. bear them off."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have all your soldiers out, you have finished the game."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And this ['ends'] the Backgammon's tutorial. You can go and practice against the server, or check out more advanced tutorials in the main menu."])}
      },
      "a1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s Backgammon"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how both games are the ['same'], but way ['different']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with this tutorial instructions to learn how Abak improves its father."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the classic backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[!15] equals soldiers for each team"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Abak'] arranges its first position a little differently. It starts with [!4] soldiers in the back."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Classes"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And, off course, adds ['classes'] of soldiers"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same Rules"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rules of Backgammon apply to Abak. But each class adds its set of additional rules."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General [s21]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves in both directions"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↶ [s21] ↷"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid [s41]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizes the opponent."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s41] = 🔒 "])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards [s31]"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More powerful than the rest"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s31] ≅ [s11]+[s11]"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded [s61]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves odd numbers "])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s61] ↷ [d11] [d13] [d15]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded [s51]"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves even numbers "])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s51] ↷ [d12] [d14] [d16]"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having different classes of soldiers not only adds the soldier's rules to the game. It also affects deeply its ['mechanic's]. "])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you have an example. Points [19] and [17] have the same classes of soldiers, but in different order. Meaning the height of a soldier in a point matters, so it adds a new Axis, [!Y]"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order of Movements"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This also means that the movement's order matters in the game, and could change the result of a turn drastically ."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the guard!."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your regular soldier first [s11], then your druid [s41], to capture the guard [s32]."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture the guard. Try again."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Captured!."])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have captured the guard [s32]."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the order."])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would have happened if you had moved the druid [s41] first?"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilize the guard!."])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your druid first [s41], then your regular soldier [s11], to trap the guard [s32]."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not what expected"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to trap the guard [s32] with your druid [s41]."])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Trapped"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  As you can see, a different outcome for the same move but made in a different order."])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about each class in the next chapters."])}
      },
      "a2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['druid']. The most feared soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['druid'] is a powerful tool that needs practice to master. It ['immobilizes] the opponent's soldiers instead of capturing them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immobilized"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its power is only present in the ['outer-board'], where he can immobilize soldiers."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captured"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inside ['homes'], the druid behave as a ['regular'] soldier. It ['captures'] soldiers and sends them to the bar."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move both [d14] and [d12] with the ['druid'], to capture the ['regular'] soldier, and immobilize the ['guard']."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't make both actions."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture a soldier [s12][22][d12] and immobilize the guard [s32][18][d14]. Try again."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, You trapped and captured a soldier!"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now his guard [s32][18] can't move. But he could hit your druid with another soldier. Oh no, it’s his turn!."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid was captured."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is in the bar. Roll to get out."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the bar, and trape its soldier with your druid on [20]."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Soldier Trapped."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is still alone and vulnerable. But hey, what could go wrong?"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaos."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now your druid has a druid over.  You can't move it, but this soldier under it can't move either."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your soldier in [24] to capture your opponent druid, and save yours."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect. Druid is Safe."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid has a soldier immobilized, and is protected, building a blocking point."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not forever."])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have to leave that position and release the soldier someday. More advanced tutorials are available that address the topic."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed this chapter."])}
      },
      "a4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['guards'] strengths and weaknesses.\n"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guard"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The guard is the strongest class. It blocks the position by itself, but has some limitations. You have two of them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onle lonely soldier"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, you have one soldier against two enemy guards. Can you escape?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You rolled [14,15] and can't move because both guards are blocking ([19][d15] [20][d14]). You can try to move a soldier if you like, to see what happens. Click in Confirm to continue."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A wild soldier appears"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards are not invincible. Two soldiers can hit them. Let's add a companion."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attack with both"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To capture a guard, you must land over it, in two consecutive movements, with two soldiers. Let's do this."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, try to hit the guard with both soldiers."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes!. Confirm you are done."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent! That is one way to do it."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because, of course, a guard can hit another guard."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So you don't have to just blindly believe me."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hit them both!."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic. You are done now."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have learned how guards work. "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the expected movement. Try again."])}
      },
      "a3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['General']. The most resourceful soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your ['General'] to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['General'] is resourceful. It can go ['backward'] and ['forward']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[#1] In a turn, it can go only in one direction.<br/>[#2] Only one move backward.<br>[#3] It can't go back if all other soldiers are at home.'"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only once Backwards."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the General goes back, it can no longer move in the turn."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. You can see how it can move to 4 different points. Choose one ['backwards'] (highest points)"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good. Click again. You will see that it can't move anymore. Move the other checker."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward many, but not back."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the General goes forward, it can move every dice number, but can't go back."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not expected."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow the instructions in the messages to complete this tutorial."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. Now choose a point forward."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. It can't go back. But it can continue forward. Move."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the only one away from home."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if all the other soldiers is in its team are home."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if every other soldier is in its team home."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your General, you can see it can go back. Make sure to move with your regular ['soldier [s11]'] to enter your team's home."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. No other team soldier is outside, so it can't go back, only forward."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's it. You have completed this chapter."])}
      },
      "a5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to move the ['odd wounded'] and suffer its restrictions."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded can only move odd numbers: [d11][d13][d15]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that, it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have ['3 rolls'] to jump across your adversary. Choose wisely."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This roll [11,13] allows one valid move, but you can choose which die. Move."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This time, you have an ['even'] number you can't move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great, you have escaped. Roll to finish"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned with your ['odd wounded'] to your home. Great job!."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wounded is now fully trapped, because [d11] lands in [20], [d13] in [18] and [d15] in [16], all blocked. Try again!"])}
      },
      "a6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about the ['even wounded'], predictably nightmare."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['even wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['even wounded'] can only move even numbers: [d12][d14][d16]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictable"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because it always move ['even'] numbers, you can block its path from far away (as you can see now, it won't go farthest than [20])."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play some rolls to escape from there."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this roll, dice [d15] is open but is not an ['even'] number. You can only move [d14] to [20]."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now the ['even wounded'] is fully ['blocked']. No dice roll will get you out of there. But hey, it's the opponent’s turn."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky you!"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[d16] now is open. Cross your finger."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come on!. Don't expect real life to be like this. Now, run."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned to your home with your ['even wounded']. Great job!."])}
      },
      "a7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded at the Bar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when entering from the ['bar']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded captured']. You will get ['4 rolls'] to take them out. Your opponent is not moving."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Escape"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when in the ['bar'], they can enter the board with ['any dice'] value."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Rolls"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[13,12] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your wounded in the Bar [B0], you can see both can land in both positions [22][d13] [23][d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Rolls"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Rolls"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [16,16] ↦ [15,15]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Roll"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [u6,u6] ↦ [15,15]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #4."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to be home with both wounded. Try again!."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You returned home with both wounded. Good Job!."])}
      },
      "a8": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bearing Off"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when ['bearing off']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Bear off'] your ['wounded'] in this exercise, and practice how they behave in this situation."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bear Off"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when they bear off, they can do it with ['any dice'] value, as a regular soldier."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, ['odd wounded [s61]'] is on point [4]. Can bear off (if on top) with [d16][d15][d14]. Can move [d11] and [d13]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['even Wounded [s51]'] is on point [4]. Can ['bear off'] with [d16][d15][d14]. Can move [d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the ['even wounded']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can bear off the ['even wounded']. The ['odd wounded'] can't move."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['odd wounded'] can't move [d12], but can move [d13]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now it can bear off with [d12]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you know how to bear off your ['wounded']. Find more tutorials in the ['medium'] level section."])}
      },
      "g102": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your guards the right way"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your guards are amazing tools, but you might go through an entire game without using their powers."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this tour about Guards, and solve a few puzzles."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards are almost blocks."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They make a difference when alone in the base of the board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Block"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When facing a single adversary, they make an absolute block, if that adversary is not a guard."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] is fully blocked."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Block"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Against many enemies, they could be hit, but with meagerchances. They make a great temporary structure."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] hits with [d25][d25] and [d26][d26]"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are no match for the Druid"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid can trap a guard as with any othe soldier."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s42] is blocked on [6] but can trap in [7]"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you stack them, you might underuse them"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep them atop a block if you want to use them to attack later"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid stacking soldiers on them if possible to maximize your chances and use your full army capabilities"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But when they are at home. Keep it at ground level, so you won’t leave blots when bearing off."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your worst-case scenario."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block the Odd Wounded."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the right move to fully block the wounded"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded [s61] is not fully blocked, try again."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made a full block to retain the Odd Wounded."])}
      },
      "bst": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Moves"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon has been cracked. There is concens about the best moves for the opening roles."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the wisest decisions on opening moves, to finish this tutorial."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanne is a move of two different soldiers, from two different points, to the same destination."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is maybe the best possible opening move. Play your best."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to make a block on point [7]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have created an vital block."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Golden Point."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a very important position."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in the Golden Point of your home. An importan position to guard."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another important point."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape moves"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are other not-so-awesome moves at the start. You won't produce a block with them"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon more opnings!."])}
      },
      "bp": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a Prime and block the enemy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Prime is an structure of six consecutive blocks. Trap your enemy with one"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice and create a prime to trap your opponent inside your home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold it Back"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An important tactical objective of backgammon is to hold your opponent back, trapping it in your home."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a fort"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent soldier is trapped back, but you have some blots to cover. If your opponent rolls [d24] or [d22], he will hit and may run. "])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a move that will make six consecutive blocks and prevent his escape"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent can escape."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left a blot on your home and your opponent can escape. Try again and make a full block."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime. A structure of 6 blocks that fully traps soldiers. Very Good!."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full House."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also build a prime fully in your home, and have captured soldiers that can't get off."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture your opponent, and close your home."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't fully close your home."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to have built 6 blocks inside your home and fully trap a captured enemy. Try Again."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime inside your home. Very Good!."])}
      }
    },
    "common": {
      "whitehome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Home"])},
      "blackhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black Home"])},
      "outerboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outside"])},
      "movesleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must make all valid moves."])},
      "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
      "gotit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You got it!"])},
      "bettermove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a better move"])},
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
      "chcompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])}
    }
  },
  "ui": {
    "game": {
      "alerts": {
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра окончена"])},
        "notyourtrun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не ваш ход"])},
        "opponentchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта шашка не ваша"])},
        "nomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта шашка не может двигаться"])},
        "noavailabledice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше нет доступных кубиков. Отмените или завершите свой ход."])},
        "movelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас еще есть ходы"])},
        "upperchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете двигать только верхнюю шашку каждого столбца"])},
        "betterpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны сыграть максимальное количество допустимых ходов. Есть лучший путь."])},
        "ckeckersatbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть шашки на баре. Сначала введите их."])},
        "guardsemicaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны использовать эту шашку, чтобы ударить стража. Она не может двигаться."])},
        "cantbearoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете снимать шашки, пока все ваши шашки не окажутся дома."])},
        "cantbearoffback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете снять эту шашку, у вас есть другие сзади."])},
        "notback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта шашка не может идти назад"])},
        "onlyoneback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерал уже двигался, он не может идти назад."])},
        "wentbacknomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если Генерал идет назад, он больше не может двигаться."])},
        "nomovebackteamhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерал не может идти назад, если все другие шашки дома"])},
        "onlyodd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта шашка двигается только на нечетные числа"])},
        "onlyeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта шашка двигается только на четные числа"])},
        "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете сложить не более пяти шашек в каждом столбце"])},
        "adversaryspoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете двигаться на позицию, если у вашего противника есть 2 шашки"])},
        "nojane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете сделать комбинацию, чтобы ударить этого стража"])},
        "nojanenear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страж может быть побит двумя не-стражами, но у вас нет других солдат в пределах досягаемости."])},
        "nomoremoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас больше нет ходов. Бросьте кубики."])},
        "checkertraped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта шашка захвачена друидом"])},
        "musfinishjane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы находитесь над стражем, но не закончили его удар. Вы не можете завершить движение."])},
        "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бросьте кубики"])},
        "nomovesturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс. Нет ходов."])},
        "endguardmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны закончить движение над стражем"])},
        "notyourcube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кубик не принадлежит вам"])},
        "cubebeforeroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете удвоить только в свой ход, перед броском."])},
        "cubemaxvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кубик достиг максимального значения"])},
        "nomovestoundo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет ходов для отмены"])},
        "notips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Советов нет"])},
        "youraretimetraveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете двигаться во время путешествия во времени."])},
        "onlyatend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете анализировать ход только когда игра окончена или при игре на базовых уровнях."])},
        "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта функция отключена для вашего пользователя."])},
        "cubenotpractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кубик не активен в режиме практики"])},
        "noanalyzepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я пока не могу анализировать в режиме практики"])},
        "noanalysestake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете анализировать ходы в матче с ставкой, пока игра не закончена."])},
        "matchlosspoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потеря очков ELO"])}
      },
      "help": {
        "regularsoldier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обычный солдат"])},
        "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите на солдата, чтобы получить помощь"])}
      },
      "messages": {
        "yourturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш ход. Бросьте кубики."])},
        "nowcanmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теперь вы можете двигаться."])},
        "serversturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ход сервера"])},
        "waintingfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание"])},
        "torolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы бросить кубики"])},
        "toplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы играть"])},
        "waintingforopponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание противника"])},
        "secconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["секунды"])},
        "drawingboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рисование доски"])},
        "nologs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет журналов"])},
        "checkercolorschanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет шашек изменен"])},
        "fullscreenrequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полноэкранный режим изменен"])},
        "showpointsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость очков изменена"])},
        "allowinvitationsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение на приглашения изменено"])},
        "allowvisitorsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость посетителей изменена"])},
        "clicklogformore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете нажать на позицию журнала, чтобы вернуться к этому ходу и увидеть больше информации."])},
        "requestmovestats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете запросить статистику этого хода, чтобы сравнить ваш ход с нейронной сетью."])},
        "logdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ход # , Бросок кубиков, # Ходов, Время хода в секундах."])},
        "analysisindicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индикаторы анализа"])},
        "analysisw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вероятность выигрыша игры"])},
        "analysisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вероятность завершения игры в 1,2 или 3 очка."])},
        "analysisequity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справедливость"])},
        "analysiswequitydescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отражает будущую ценность в очках для игры"])},
        "analysiswequityej1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["если оба игрока в ничью"])},
        "analysiswequityej2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["если игрок выигрывает 1 очко"])},
        "analysiswequityej3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["если игрок проигрывает 2 очка"])},
        "mathwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Математически"])},
        "analysismovelost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мера потери справедливости за ход (0, если вы выбрали лучший допустимый ход)"])},
        "analysismovemoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество допустимых ходов для броска"])},
        "analysisgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ игры"])},
        "analysisgamewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["это займет некоторое время, пожалуйста, подождите"])},
        "clickturndetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите на точку, чтобы увидеть детали хода."])},
        "harmlessdisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безвредное отключение"])},
        "exaustedinvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможности приглашений исчерпаны. Вы можете подождать здесь, пока не подключится другой пользователь, или выйти и играть против компьютера и ждать приглашения."])},
        "exitnomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Так грустно!.</span> Вы можете подождать приглашения, играя против сервера."])},
        "notyetanalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам нужно как минимум 20 ходов, чтобы запросить анализ доски."])},
        "playersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игроки онлайн"])},
        "invitationsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка приглашения"])},
        "invitationdeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение отклонено"])},
        "invitationacepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение принято"])}
      },
      "dialogs": {
        "askdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удвоить игру?"])},
        "oppentdoubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Противник предлагает удвоение или ничего"])},
        "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удвоить"])},
        "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
        "acept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])}
      },
      "action": {
        "nextgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая игра"])},
        "newmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый матч"])},
        "surrender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сдаться"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
        "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявить"])},
        "dontclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не заявлять"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
        "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединиться"])},
        "attemptdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попытаться удвоить"])},
        "doubleaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принято"])},
        "doublerejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонено"])},
        "startroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бросить кубики для начала"])},
        "startequals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Равные кубики, повтор."])},
        "startstarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра начинается"])},
        "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализировать"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пауза"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])}
      },
      "ialevel": {
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легкий"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормальный"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продвинутый"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эксперт"])}
      },
      "message": {
        "ifyoulose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы проиграете"])},
        "ifyouwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы выиграете"])},
        "youwilllosematch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы проиграете матч"])},
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра окончена"])},
        "matchover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Матч окончен"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявить матч"])},
        "nogameslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет игр в журнале"])},
        "logtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал игр"])},
        "confirmdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удвоить?"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить матч"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить матч"])},
        "analysysnotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот инструмент слишком дорогой для запуска и не будет доступен в ближайшее время. Вы все еще можете анализировать отдельные ходы из журнала ходов."])},
        "practiceover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практика окончена"])}
      },
      "subject": {
        "turnslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал ходов"])},
        "gameevolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эволюция игры"])},
        "turns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ходы"])},
        "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доска"])},
        "accelerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ускорители"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опции"])},
        "p2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила"])},
        "matchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о матче"])},
        "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справедливость"])},
        "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Победитель"])},
        "newvisitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас новый посетитель в игре."])}
      },
      "tag": {
        "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легенда"])},
        "playersmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ход игрока"])},
        "novalidmoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет допустимых ходов"])},
        "forcedmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительный ход"])},
        "gamedoubled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра удвоена"])},
        "tieline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линия ничьи (0)"])}
      }
    },
    "preferences": {
      "acelerators": {
        "autoDoneOnLastValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически завершать ход при последнем допустимом ходе."])},
        "autoRoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто-бросок кубиков в ваш ход."])},
        "autoMoveOnOneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически двигаться, если только один допустимый ход доступен на выбранной шашке"])},
        "autoDoneOnNoValids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически завершать ход, если бросок не дает допустимых ходов"])},
        "autoRollForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительный авто-бросок даже при возможных действиях с кубиком."])}
      },
      "messages": {
        "showAdvices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать советы"])},
        "showInvalidMoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать недопустимые движения"])},
        "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать сообщения игры"])},
        "playSounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воспроизводить звуки"])},
        "allowVisitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить посетителей"])},
        "allowInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить приглашения"])},
        "showPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать номера позиций"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость игрока"])},
        "allowedGameTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные типы в приглашениях"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
    },
    "lobby": {
      "messages": {
        "connectingtoengine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение к движку"])}
      },
      "welcome": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать!"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете создать гостевого пользователя или ввести свой email, чтобы получить постоянного."])},
        "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играть как гость"])},
        "guest_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы играть как гость, нажмите здесь. Этот пользователь будет связан только с этим клиентом."])},
        "changelater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете добавить email позже, чтобы сохранить этого пользователя."])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти или зарегистрироваться"])},
        "create_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы создать или восстановить существующую учетную запись, введите свой email"])}
      },
      "auth": {
        "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите 4-значный код, отправленный на ваш зарегистрированный email"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
        "useemailtosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте учетную запись email, чтобы сохранить этого пользователя."])}
      },
      "action": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться"])},
        "qrlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-вход"])},
        "loginguest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гость"])},
        "loginemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])}
      },
      "confirm": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход просто удалит этот файл cookie с устройства. Вы все еще можете получить доступ к этому пользователю, если у вас зарегистрирован действующий email. Вы уверены, что хотите продолжить?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот пользователь будет полностью удален из базы данных и недоступен навсегда. Вы уверены, что хотите продолжить?"])}
      },
      "user": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия пользователя"])}
      },
      "message": {
        "useqr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Считайте этот QR-код на любом устройстве, на котором вы хотите войти с этим пользователем."])},
        "closesession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть сессию на этом устройстве"])},
        "logindifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти на другом устройстве"])},
        "permanentremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью удалить пользователя"])},
        "notabakertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не пекарь"])},
        "notabakertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Станьте <b>пекарем</b>, чтобы получить доступ к отправке приглашений или созданию публичных игр. Или вы можете подружиться и приглашать их играть."])},
        "howtoabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы стать <b>пекарем</b>, вы должны выиграть матч на <b>5 очков</b> против сервера в режиме <b>Продвинутый</b> или <b>Эксперт</b>, или завершить уровни <b>практики</b>."])},
        "stakeforabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставки очков ELO могут быть сделаны только в играх Abak."])},
        "abakerrequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выиграйте $$(left)$$ матчей, чтобы разблокировать следующий уровень."])}
      },
      "subject": {
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка"])}
      }
    },
    "message": {
      "newmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас новые сообщения"])},
      "retryingerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторная попытка"])}
    }
  }
}