<template >
    <div >

        <!-- ************************************************************************************************************------>
        <!-- Move List **************************************************************************************************-->
        <!-- ************************************************************************************************************------>


        <div v-if="analyseMove.on && !analyseMove.loading && !helpOn" class="MoveAnalysisContainer">
            <div class="moveAnalysisTitle" style="display:block;">  
                AI Opinion
            </div>
            <div class="moveAnalysisStats">  


                <div>                    
                    <div>#{{analyseMove.turnID+1}}</div>
                    <div :class="{ 
                        errorPerfect : eError>=-0.001 , 
                        errorModerate : eError<-0.001 && eError>= -0.01, 
                        errorMedium: eError<-0.01 && eError>= -0.1 , 
                        bigError: eError<-0.1 }">{{ eError }}</div>
                </div> 
                <div style="display:flex;flex-direction:column;justify-content:center;">
                    <div>
                        <img style="width:1em;margin:0"
                                :src="require(`@/assets/img/dados/d${normalizedPlayerID(analyseMove.moves[0].playerID)+1}${currentMove.diceRoll[0]}.svg`)"
                        >
                        <img style="width:1em;margin:0"
                                :src="require(`@/assets/img/dados/d${normalizedPlayerID(analyseMove.moves[0].playerID)+1}${currentMove.diceRoll[1]}.svg`)"
                        >                
                    </div>
                    <div class="ammountMoves">
                        {{analyseMove.moves.length}}
                    </div> 
                </div> 
                <div @click="helpOn=true"  class="closeButton">?</div>
                <div @click="$emit('end-move-analysis')"  class="closeButton">⤫</div>
            </div>        

            <div class="movesList"
            :style="{ height: screenMode != 'vertical' ? boardSize*0.40 + 'px' : boardSize*0.4 + 'px'}"
            > 
                <div v-for="(posib,i) in analyseMove.moves" :key="i" 
                    style="display:flex;flex-direction:column;font-size:0.7em;border-bottom:1px solid white;align-items:center;"
                    @click="$emit('select-move-analysis',{moveID: i})"
                    :class="{ selected: isSelected(i) , chosen: posib.chosen  }"
                    >            
                    <div class="equityBox" 
                        style="display:flex;justify-content:space-around;width:100%;"
                        :class="{chosenTitle: posib.chosen}"
                        > 
                        <div>{{i+1}}</div>
                        <div><span>E</span>{{posib.e.toFixed(4)}}</div>
                        <div><span>W</span>{{formatP(parseInt(posib.playerID) == 0 ? posib.w : 1-posib.w )}}</div>
                    </div>
                    <div v-if="isSelected(i)" class="pointsGrid">
                        <div class="gridTitle">P1</div>
                        <div class="gridTitle">P2</div>
                        <div class="gridTitle">P3</div>
                        <div>{{formatP(posib.p[0])}}</div>
                        <div>{{formatP(posib.p[1])}}</div>
                        <div>{{formatP(posib.p[2])}}</div>
                    </div>
                    <div v-if="isSelected(i)"
                        style="display:flex;flex-wrap: wrap;align-items: center;width:100%;">                
                        <div 
                            v-for="(m,j) in posib.moves" :key="'logItemMove'+i+j"  
                            style="
                                display: flex;
                                align-items: center;
                                flex-wrap:wrap;
                                margin:0.2em;
                                padding:0.1em 0.3em;
                                border-radius:0.5em;"
                        >
                            <img style="width:1.5em;margin:0 0.3em 0 0;"
                                :src="require(`@/assets/img/piedras/${checkerClass(m[4],normalizedPlayerID(posib.playerID))}.svg`)">                    
                            <div style="display:flex;">
                                <div>{{m[0]}}</div>
                                <div>↦</div>
                                <div>{{m[2]}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ************************************************************************************************************------>
        <!-- Loader **************************************************************************************************-->
        <!-- ************************************************************************************************************------>


        <div v-if="analyseMove.on && analyseMove.loading" class="MoveAnalysisContainer">
            <div class="Loader"> 
                Loading
            </div>
        </div>

        <!-- ************************************************************************************************************------>
        <!-- Help **************************************************************************************************-->
        <!-- ************************************************************************************************************------>


        <div v-if="analyseMove.on && !analyseMove.loading && helpOn" class="MoveAnalysisContainer">
            <AnalysisHelp 
            @close-analysis-help="helpOn=false;" 
            :type="'move'" 
            :fontsize="'1em;'" />            
        </div>    


    </div>
</template>

<script>

    import { useI18n } from 'vue-i18n'
    import AnalysisHelp from "./AnalysisHelp.vue";

	export default {
		name: 'MovesAnalysis',
        props: ['playerID','analyseMove','currentMove','show','checkersColor','screenMode','boardSize'],
        data: function() {
            return {
                helpOn:false
            }
        },
        components: {AnalysisHelp},
        computed: {
            eError: function() {
                let error = this.analyseMove.stats.chosene - this.analyseMove.stats.maxe;
                return error.toFixed(4);
            }            
        },
        emits: ['select-move-analysis','end-move-analysis'],
        methods: {	
            formatP: function(p) {
                return ((p * 100).toFixed(1)) + "%"
            },
            checkerClass: function(cClass,currentPlayer) {
                let playerValue = parseInt(currentPlayer) + 1;
                return `${cClass}${playerValue}`;
                //return this.require(`@/assets/img/dados/d${playerValue}${diceValue}.svg`);
            },
            isSelected: function(i) {
                return this.analyseMove.on==true && this.analyseMove.moveID==i;
            },
            normalizedPlayerID: function(playerID) {
                //let myPlayerID = this.playerID ? parseInt(this.playerID) : 0;                
                let otherPlayer = (parseInt(playerID) + 1)%2;                
                if (this.checkersColor==1 ) {
                    return parseInt(otherPlayer);
                }
                return parseInt(playerID);
            }            
        },
        watch: {
            show: function() {
                    this.helpOn = false;
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },                
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


    .moveAnalysisTitle {
        font-size:0.7em;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.2em solid $c11;
    }
    .moveAnalysisStats {
        font-size:0.6em;
        text-align: center;
        border-bottom: 0.2em solid $c11;
        margin:0.3em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left:0.5em;

        >div {
            background-color:$c5;
            padding:0.2em 0.5em;
            vertical-align: middle;            
            border-radius: 0.3em;
            margin:0.2em;
        }
    }

    .MoveAnalysisContainer {
        width:10em;
        background-color:$box4-background;
        box-sizing: border-box;
        width: 100%;
        margin-left:0.5em;

    }

    .player1, .player2 {
        display:flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding:0em 0.3em;
    }

    .player1 {
        background:$checkerwhite-background;
        color:$checkerwhite-color;
        width:100%;
    }

    .player2 {
        background:$checkerblack-background;
        color:$checkerblack-color;
        width:100%;
    }

    .default {
        background-color:$checkerblack;
        padding:2%;        
        color:white;
    }

    img {
        width:3em;
        margin:0.5em;
    }

    .logItemId {
        padding:0.2em;
        background-color:rgb(0, 0, 0);
        color:rgb(255, 255, 255);
        border-radius: 1em;
        min-width: 1.5em;        
        margin-right:0.5em;
        text-align: center;
    }



    .movesList {
        width: 100%;
        font-size: 0.7em;        
        overflow-y:auto;        
        overflow-x:hidden;
    }

    .selected {
        filter:brightness(1.25);
    }
    
    .equityBox {
        background: $c7;
        display: flex;
        align-items: center;

        div:nth-child(1) {
            background:$c8;
            padding:0.3em;
            border-radius: 0.5em;
            min-width: 1.1em;
            text-align: center;
            margin:0.2em;
        }

        div {
            span {
                background:$c4;
                border-radius: 0.3em;
                margin: 0em 0.3em;
                padding: 0.1em 0.3em;
            }
        }
    }

    .chosen {
        background: $c11;
    }

    .chosenTitle {
        background: $c12;
        border: 0.05em solid white;
    }

    .Loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height:20em;
    }


    .errorPerfect , .errorMedium , .bigError , .errorModerate {
        border-radius: 0.3em;
        padding:0.2em 0.5em;
    }

    .errorPerfect {
        background: green;
    } 
    .errorModerate {
        background: greenyellow;
        color:black;        
    }
    .errorMedium {
        background: orange;
        color:black;
    }
    .bigError {
        background:red;
    }

    .ammountMoves {
        background:$c3;
        color:$c0;
        border-radius:0.3em;
    }

    .closeButton {
        align-self:center;
        justify-self:flex-end;
        border-radius: 50% !important;
        display:flex;
        background:$colorno !important;
        width: 0.7em;
        width: 0.7em;
        justify-content: center;       
    }

    .analysisHelp {
        max-height:19em;
        width:10em;
        font-size: 0.85em;
        padding: 0.3em 0 0.3em 0.3em;
        box-sizing: border-box;
        >ul {
            padding-inline-start: 0em;
        }

        >ul>li {            
            display:flex;
            align-items: center;
            padding:0.3em;
            font-size: 0.5em;

            div:nth-child(1) {
                background:$c3;    
                color:$c0;            
                padding: 0.2em;
                margin: 0.2em;                
                border-radius: 0.5em;            
            }            
        }
    }

    .pointsGrid {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        text-align:center;
        width:100%;
        background:$c4;
        
        >div {
            border-radius: 1em;
            margin:0.2em;
        }
    }

    .gridTitle {
        background:$c8;
    }

    .color1 {
        background:$c4;
    }
    .color2 {
        background:$c4;
    }

    .color3 {
        background:$c4;
    }

    .color4 {
        background:$c4;
    }

    .equityFormula {
        background: $c5;
        color:$c1;
        padding: 0.2em;
        text-align: center;
        >div:nth-child(1) { background:$c5 !important; color:$c1 !important; } 
        >div:nth-child(2) {
            background: $c8 !important;
            font-size:1.3em;
            color: $c1 !important;
            padding:0.2em;
            border-radius: 0.5em;
        }
    }

    @media (orientation:portrait) {
        .MoveAnalysisContainer {
            font-size:2em;
            width:95%;
        }
    }

</style>
