<template>
    <div     
    v-show="timeLeft>0 || myturn"
    style="display: flex;align-items: center;"    
    >
        <div @click="animateClick()"
            :key="button_id" :id="button_id" 
            class="game-button"        
            :class = "{ timeUp: timeLeft<=0 }"
            >
            {{Math.round(timeLeft)}}
        </div>
    </div>
</template>

<script>
	export default {
		name: 'TimeOutButton',
        props: [ 'button_id','counting','imageSize','timeTotal' ,'timeStarted','myturn'],
        data: function() {
            return {
                currentTime: null,
                timeOutTimer: null,
            }
        },
        computed: {
            calcImageSize: function() {
                if (typeof (this.imageSize)== 'string') return this.imageSize;
                if (typeof (this.imageSize) == 'number') return this.imageSize + 'px';
                return "1em";
            },
            timeLeft: function() {
                let timeElapsed = (this.currentTime - this.timeStarted)/1000;
                let timeLeft = Math.max(this.timeTotal - timeElapsed,0);
                return timeLeft;
            }

        },
        watch: {
            counting: function() {                
                //else clearTimeout(this.timeOutTimer);
            }
        },
        methods: {	
            updateTimer: function() {
                this.currentTime=Date.now();
                //console.log("update timer",this.counting);
                this.timeOutTimer = setTimeout(this.updateTimer,200);
            },
            animateClick: function() {
                let jsonAnime = {
                    targets:`#${this.button_id}`,
                    keyframes: [
                        {scale: 0.3,invert:1},
                        {scale: 1.1,invert:0},
                        {scale: 1},
                    ],                    
                    duration:300,
                    easing: 'easeInOutSine',                                                
                };
                this.$anime(jsonAnime);  
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                this.updateTimer();
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
 
    .game-button {
        min-width: 1.2em;
        border-radius:1em;
        background-color:$c9;
        padding:0.2em;
        
        color:white;
        display:flex;
        justify-content: center;
        align-items: center;
        box-shadow:$box-shadow-normal;
        cursor:pointer;
        filter:scale(1);
        user-select: none;
    }
    @media (hover: hover) {
        .game-button:hover {
            background-color:$c8;
        }

    }

    .timeUp {
        background: rgb(99, 11, 11);
    }



</style>
