<template>
    <div 
        @click="animateClick()"
        :key="button_id" :id="button_id" class="game-button"
         :class="{ 
            dark: theme=='dark', 
            light: theme=='light' , 
            normal: theme==null, 
            disabled: disabled }"
        >
        <div v-if="text">{{ text }}</div>
            <img alt="button"
            :src="require(`@/assets/img/buttons/${image}.svg`)"
            :style ="{ width: imageSize+'px' }"                               
            />
    </div>
</template>

<script>
	export default {
		name: 'UIButton',
        props: { 
            button_id:String,
            text:String,
            image:String,
            imageSize:Number,
            theme:String,
            disabled: {
                type: Boolean,
                required:false,
                default:false,
            } 
            },
        data: function() {
            return {
            }
        },
        computed: {

        },
        methods: {	
            animateClick: function() {
                //console.log("animateclick",this.button_id);
                let jsonAnime = {
                    targets:`#${this.button_id}`,
                    keyframes: [
                        {scale: 0.3,invert:1},
                        {scale: 1.1,invert:0},
                        {scale: 1},
                    ],                    
                    duration:300,
                    easing: 'easeInOutSine',                                                
                };
                this.$anime(jsonAnime);  
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 
    .game-button {
        border-radius:0.5em;
        padding:0.5em 0.5em;        
        display:flex;
        align-items: center;
        justify-content: space-between;
        align-self:center;        
        cursor: pointer;
        >div,>img {
            margin:0 0.3em;
        }
    }

    .dark {
        background-color: #1b240f;
        filter:drop-shadow(0.1em 0.1em 0.2em black);
        color:white;
    }

    .ligth {
        background-color: #7b849f;
        filter:drop-shadow(0.1em 0.1em 0.2em black);
        color:black;

    }

    .normal {
        background-color: $button3-background;
        color: $button3-color;
        filter: $shadow1;
        color:black;

    }    

    @media (hover:hover) {
        :hover {
            filter:invert(1)
        }
        >:hover {
            filter:invert(0)
        }
    }



    .game-button img {
    }

    

    .disabled {
        img {
        filter:invert(1);

        }
    }

</style>
