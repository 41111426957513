<template>
		<div class=""> 
            <div>
                <div class="contenedorEquipo">
                    <div 
                        :class="{ selected: menuData.stoneId == 0 }"
                        @click="piedraSelected(0)"><img src="@/assets/img/piedras/02.svg"><div>&nbsp;</div>
                    </div>
                    <div 
                    :class="{ selected: menuData.stoneId == 2 }"
                            @click="piedraSelected(2)"><img src="@/assets/img/piedras/21.svg"><div>&nbsp;</div>
                    </div>
                    <div 
                        :class="{ selected: menuData.stoneId == 3 }"
                        @click="piedraSelected(3)"><img src="@/assets/img/piedras/31.svg"><div>×2</div>
                    </div>
                    <div 
                        :class="{ selected: menuData.stoneId == 4 }"
                        @click="piedraSelected(4)"><img src="@/assets/img/piedras/41.svg"><div>&nbsp;</div>
                    </div>
                    <div 
                        :class="{ selected: menuData.stoneId == 5 }"
                        @click="piedraSelected(5)"><img src="@/assets/img/piedras/51.svg"><div>&nbsp;</div>
                    </div>
                    <div 
                        :class="{ selected: menuData.stoneId == 6 }"
                        @click="piedraSelected(6)"><img src="@/assets/img/piedras/61.svg"><div>&nbsp;</div>
                    </div>
                    <div 
                        :class="{ selected: menuData.stoneId == 1 }"
                        @click="piedraSelected(1)"><img src="@/assets/img/piedras/11.svg"><div>×9</div>
                    </div>
                </div>
                <transition name="tooltiponoff">
                    <div class="tooltipPiedras" v-if="showTooltip">
                        <div class="ttpTitulo" v-if="tooltipTitle" v-html="tooltipTitle"></div>
                        <div class="ttpTexto" v-if="tooltipText" v-html="tooltipText"></div>
                        <div class="ttpImagen" v-if="tooltipTitle"><img style="height:100%;" :src="require('@/assets/img/piedras/ej'+tooltipId+'.svg')" /></div>
                    </div>
                </transition>
            </div>

            <div style="display:flex;justify-content:center;" :class="{ pVertical: screenMode=='vertical'}">
                <div class="briefView">
                    <div>
                        <div style="font-size:2em;">{{getStoneData.title}}</div>
                        <img v-if="getStoneData.image!=''" style="height:6em;" :src="require(`@/assets/img${getStoneData.image}`)">                    
                    </div>
                    <div class="texto-libre" v-html="getStoneData.text"></div>
                </div>
                <div class='stoneBoxes' 
                    v-if="getStoneData.boxes"                    
                    >
                    <div @click="stonePagePageMove(-1);">
                        <img style="width:2em;" :src="require(`@/assets/img/buttons/slide_backward.svg`)">
                    </div>
                            <div 
                                
                                v-for="b,j in getStoneData.boxes"                     
                                :key="'sectionBox'+j"
                                class="boxContent"
                            >                
                                    <div class="cabezaDemo">                            
                                        <div style="width:90%;margin-left:5%;" v-html="b.text"></div>
                                    </div>
                                    <div 
                                        :style="{width: screenMode!='vertical' ? demoImageSize*0.8+'px': demoImageSize*1+'px' }"
                                        >
                                        <img v-if="b.image" style="width:100%;"
                                        :src="require(`@/assets/img${b.image}`)"                            
                                        >
                                    </div>
                            </div>                        
                    <div @click="stonePagePageMove(+1);">
                        <img style="width:2em;" :src="require(`@/assets/img/buttons/slide_forward.svg`)">
                    </div>
                    </div>
                </div>
            </div>

</template>

<script>
    import { useI18n } from 'vue-i18n'

	export default {
        name: 'GeneralRules',
        props: [
            'demoImageSize',            
            'SectionRequest',
            'menuData',
            'screenMode'
            ],
        emits: ['change-menu'],
        data: function () {
            return {
                tooltipText: "",
                tooltipTitle: "",
                tooltipId:1,
                showTooltip:false,
                stoneHelp: {
                        0: {
                            title:this.t('reglas.generales.thisguys'),
                            text:"",
                            image:"",
                            boxes: [
                                { image: "/elements/team_transparent.svg", text: this.t("reglas.generales.addsclasses") },
                                { image: "/ejemplos/pop_ej_max_5.svg", text: this.t("reglas.generales.max5") },
                                { image: "/ejemplos/pop_ej_higest_first.svg", text: this.t("reglas.generales.highest") },
                                { image:"",text:this.t('ayuda.generales.clicksoldiertohelp')}
                            ]
                        },
                        1: {
                            title:this.t("ayuda.piedras.1.1"),
                            text:this.t("ayuda.piedras.1.2"),
                            image:"/piedras/ej1.svg",
                            boxes:[ {image:"/piedras/ej1.svg",text:this.t("ayuda.piedras.1.2")}]
                        },
                        2: {
                            title:this.t("ayuda.piedras.2.1"),
                            text:this.t("ayuda.piedras.2.2"),
                            image:"/piedras/ej2.svg",
                            boxes: [
                                { image: "/ejemplos/pop_ej_general1.svg", text: this.t("ayuda.piedras.2.4") },
                                { image: "/ejemplos/pop_ej_general2.svg", text: this.t("ayuda.piedras.2.5") },
                                { image: "/ejemplos/pop_ej_general3.svg", text: this.t("ayuda.piedras.2.7") },
                                { image: "/ejemplos/pop_ej_general4.svg", text: this.t("ayuda.piedras.2.6") },
                            ]
                        },
                        3: {
                            title:this.t("ayuda.piedras.3.1"),
                            text:this.t("ayuda.piedras.3.2"),
                            image:"/piedras/ej3.svg",
                            boxes: [
                                { image: "/ejemplos/pop_ej_guardias1.svg", text: this.t("ayuda.piedras.3.4") },
                                { image: "/ejemplos/pop_ej_guardias2.svg", text: this.t("ayuda.piedras.3.5") },
                                { image: "/ejemplos/pop_ej_guardias3.svg", text: this.t("ayuda.piedras.3.6") },
                                { image: "/ejemplos/pop_ej_guardias4.svg", text: this.t("ayuda.piedras.3.7") },
                            ]
                        },
                        4: {
                            //6,7,1,3,4,2
                            title:this.t("ayuda.piedras.4.1"),
                            text:this.t("ayuda.piedras.4.2"),
                            image:"/piedras/ej4.svg",
                            boxes: [
                                { image: "", text: this.t("ayuda.piedras.4.3") },
                                { image: "/ejemplos/pop_ej_druida1.svg", text: this.t("ayuda.piedras.4.4") },
                                { image: "/ejemplos/pop_ej_druida2.svg", text: this.t("ayuda.piedras.4.5") },
                                { image: "/ejemplos/pop_ej_druida3.svg", text: this.t("ayuda.piedras.4.6") },
                                { image: "/ejemplos/pop_ej_druida4.svg", text: this.t("ayuda.piedras.4.7") },
                                { image: "/ejemplos/pop_ej_druida5.svg", text: this.t("ayuda.piedras.4.8") },
                            ]
                        },
                        5: {
                            //6,7,1,3,4,2
                            title:this.t("ayuda.piedras.5.1"),
                            text:this.t("ayuda.piedras.5.2"),
                            image:"/piedras/ej5.svg",
                            boxes: [
                                { image: "/ejemplos/pop_ej_hpar1_1.svg", text: this.t("ayuda.piedras.5.3") },
                                { image: "/ejemplos/pop_ej_hpar2_2.svg", text: this.t("ayuda.piedras.5.4") },
                                { image: "/ejemplos/pop_ej_hpar2_1.svg", text: this.t("ayuda.piedras.5.5") },
                            ]
                        },
                        6: {
                            //6,7,1,3,4,2
                            title:this.t("ayuda.piedras.6.1"),
                            text:this.t("ayuda.piedras.6.2"),
                            image:"/piedras/ej6.svg",
                            boxes: [
                                { image: "/ejemplos/pop_ej_himpar1_1.svg", text: this.t("ayuda.piedras.6.3") },
                                { image: "/ejemplos/pop_ej_himpar2_2.svg", text: this.t("ayuda.piedras.6.4") },
                                { image: "/ejemplos/pop_ej_himpar2_1.svg", text: this.t("ayuda.piedras.6.5") },
                            ]
                        },
                    }


			}
		},
        components: { },
		methods: {
            piedraSelected: function(i) {
                this.$emit('change-menu',{
                    menu: 'configMenu',
                    data: {
                        show:true,section:this.menuData.section,
                        stoneId:i,
                        stonePage:0
                        }
                    }
                    );
            },
            startTooltip: function(i) {
                this.tooltipTitle = this.t('ayuda.piedras.'+i+'.1');
                this.tooltipText = this.t('ayuda.piedras.'+i+'.2');
                this.tooltipId = i;
                this.showTooltip = true;
            },
            stopTooltip: function() {
                this.showTooltip = false;
                this.tooltipText=this.t('reglas.generales.ttd_texto');
                this.tooltipTitle = "";
            },
            stonePagePageMove: function(direction) {
                let stonePageSelected = (this.menuData.stonePage+direction);
                let max = this.stoneHelp[this.menuData.stoneId].boxes.length-1;
                stonePageSelected = stonePageSelected > max ? stonePageSelected = 0 : stonePageSelected ;
                stonePageSelected = stonePageSelected < 0 ? stonePageSelected = max : stonePageSelected ;
                //console.log(max,stonePageSelected);
                this.$emit('change-menu',{
                    menu: 'configMenu',
                    data: {
                        show:true,section:this.menuData.section,
                        stoneId:this.menuData.stoneId,
                        stonePage:stonePageSelected
                        }
                    }
                    );
            }
		},
        computed: {    
            getStoneData: function() {
                let stone=this.stoneHelp[this.menuData.stoneId];
                let stonePageBox = [];
                if (stone.boxes.length>0) {
                    stonePageBox=stone.boxes[this.menuData.stonePage];
                }   
                let dataResult = {
                    image:stone.image,
                    title:stone.title,
                    text:stone.text,
                    boxes: [stonePageBox]
                }               
                return dataResult;
            }            
        },
        watch: {            
        },
        mounted: function() {
            this.tooltipText=this.t('reglas.generales.ttd_texto');
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        }  
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.cabezaDemo {
    padding:0 0 1em 0;
}

.texto-libre {    
    font-size: 2em;
    span {
        color:red;
    }
}

.contenedorEquipo {
    box-sizing: border-box;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;    
    background:$c17;
    filter:$shadow1;
    border-radius: 0.5em;
    padding: 0.3em;
    margin-bottom: 1em;
}

.contenedorEquipo div {
    position: relative;
    filter:$shadow2;
}

.contenedorEquipo div img {
    width:3em;
    margin: 0 0.5em;
}
.contenedorEquipo div div {
    position: absolute;
    left: 60%;
    top: 65%;
    font-weight: bold;
    font-size: 1.2em;
}

.stoneBoxes {
    width:60%;
    display:flex;
    justify-content:space-between;
    align-items: center;
    background-color: $box4-background;
    padding: 1em;
    border-radius: 0.5em;
}

.boxContent {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.pVertical {    
    flex-direction: column;
    font-size: 1em;
    >div {
        width: 90% !important;
    }
    >div:nth-child(2) {
        font-size:1.2em;        
    }
}

.briefView {
    width:40%;
    display:flex;
    flex-direction:column;
    text-align:center;
    padding:1em;
}

.selected {
    img {
        filter:brightness(1.5)
    }
}

@media (orientation: portrait) {
    .briefView {
        font-size:0.8em;
        flex-direction:row;
        justify-content: space-around;
        align-items: center;        

        >div:nth-child(2) {
            margin-left:1em;
            background-color: $c8;
            padding:0.3em;
            border-radius: 0.3em;
        }
    }
}

</style>
