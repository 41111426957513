export default {
  "ui": {
    "game": {
      "alerts": {
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El juego ya finalizó"])},
        "notyourtrun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es tu turno"])},
        "opponentchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta piedra no es tuya"])},
        "nomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta piedra no Mueve"])},
        "noavailabledice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes más dados. Recoge"])},
        "movelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía tienes jugadas por hacer"])},
        "upperchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo puedes jugar la primera piedra de cada columna"])},
        "betterpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes jugar la mayor cantidad de jugadas. Hay una ruta que lo permite."])},
        "ckeckersatbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes recuperar las piedras de la capilla para poder jugar"])},
        "guardsemicaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta piedra esta para hacer el jane, no puedes jugarla diferente"])},
        "cantbearoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes salvar piedras, mientras no están todas en tu casa"])},
        "cantbearoffback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes salvar piedras, porque tiene otras piedras atrás"])},
        "notback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esa piedra no puede ir hacia atrás"])},
        "onlyoneback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El General ya movió. No puede ir atrás"])},
        "wentbacknomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el General avanza hacia atrás, ya no puede seguir moviendo"])},
        "nomovebackteamhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedo ir atrás si el resto esta en casa"])},
        "onlyodd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta piedra sólo puede mover dados impares"])},
        "onlyeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta piedra sólo puede mover dados pares"])},
        "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo puedes apilar cinco piedras por columna"])},
        "adversaryspoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes soltar en territorio enemigo cuando hay dos  o más piedras suyas en una posición"])},
        "nojane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes alternativa para el jane sobre el bloque"])},
        "nojanenear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El guardia puede ser capturado por dos no-guardias, pero no tienes otro soldado al alcance."])},
        "nomoremoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene movimientos. Recoge."])},
        "checkertraped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está piedra está atrapada por el druida"])},
        "musfinishjane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te posaste sobre un guardia, y no la capturaste. No puedes recoger los  dados."])},
        "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tira los dados"])},
        "nomovesturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. No tienes movimientos."])},
        "endguardmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes terminar la jugada sobre el guardia."])},
        "notyourcube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cubo no es tuyo."])},
        "cubebeforeroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo puedes doblar en tu turno, antes de tirar."])},
        "cubemaxvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cubo está en su valor máximo."])},
        "nomovestoundo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay jugadas que deshacer"])},
        "notips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay sugerencias"])},
        "youraretimetraveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede mover mientras viajas en el tiempo."])},
        "onlyatend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo puedes analizar una movida cuando termina el juego, o mientras juegas con niveles básicos."])},
        "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta función no está habilitada para tu usuario."])},
        "cubenotpractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cubo no está habilitado en modo práctica"])},
        "noanalyzepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedo analizar una práctica."])},
        "noanalysestake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes analizar el movimiento en un Partido con apuesta hasta que se el juego termine."])},
        "matchlosspoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdida de puntos ELO"])}
      },
      "help": {
        "regularsoldier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldado Normal."])},
        "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click en un soldado para obtener ayuda."])}
      },
      "messages": {
        "yourturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tu turno, tira los dados."])},
        "nowcanmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puedes mover"])},
        "serversturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es el turno del servidor"])},
        "waintingfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando que"])},
        "torolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tire los dados"])},
        "toplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" juegue"])},
        "waintingforopponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando al contrincante "])},
        "secconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundos"])},
        "drawingboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibujando la Tabla"])},
        "nologs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay registros"])},
        "checkercolorschanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de Color de Piedras"])},
        "fullscreenrequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de FullScreen"])},
        "showpointsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de Visibilidad de Puntos"])},
        "allowinvitationsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de Permisos para Invitaciones"])},
        "allowvisitorsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de Visibilidad"])},
        "clicklogformore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes hacer click en una posición del registro para viajar a ese turno y revisar más información"])},
        "requestmovestats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes solicitar estadísticas del turno, para comparar tu movida con la propuesta de la Red Neuronal."])},
        "logdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# Turno, Dados,  # Movimientos, Tiempo del Turno en segundos."])},
        "analysisindicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicadores de Análisis"])},
        "analysisw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probabilidad de Ganar el Juego"])},
        "analysisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probabilidad que el juego termine en 1,2, o 3 puntos."])},
        "analysisequity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity"])},
        "analysiswequitydescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refleja el valor futuro del juego en puntos."])},
        "analysiswequityej1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ambos jugadores empatan"])},
        "analysiswequityej2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el jugador gana 1 punto."])},
        "analysiswequityej3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el jugador pierde 2 puntos."])},
        "mathwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matemáticamente"])},
        "analysismovelost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia entre el Equity elegido y el más alto posible. (0 Si jugaste la mejor alternativa)."])},
        "analysismovemoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de jugadas válidad para el tiro."])},
        "analysisgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizando el juego."])},
        "analysisgamewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esto toma bastante tiempo, por favor espera"])},
        "clickturndetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click en un punto para ver detalles"])},
        "harmlessdisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["harmlessdisconnect"])},
        "exaustedinvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se agotaron las alternativas de invtiaciones. Puedes esperar a otro usuario o salir y jugar contra el servidor mientras esperas una invitación."])},
        "exitnomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Que Lástima</span>. Intenta jugando contra el servidor y espera que alguien te invite!."])},
        "notyetanalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitas al menos 20 turnos para solicitar el análisis de la tabla."])},
        "playersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugadores Online"])},
        "invitationsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviando invitaciones"])},
        "invitationdeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación declinada"])},
        "invitationacepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación aceptada"])}
      },
      "dialogs": {
        "askdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queires doblar el juego?"])},
        "oppentdoubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El oponente ofrece doble o nada"])},
        "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doblar"])},
        "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
        "acept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      },
      "action": {
        "nextgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente Juego"])},
        "newmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo Partido"])},
        "surrender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendirse"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
        "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamar"])},
        "dontclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Reclamar"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
        "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse"])},
        "attemptdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrece Doblar"])},
        "doubleaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptado"])},
        "doublerejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazado"])},
        "startroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza Dado para Partir"])},
        "startequals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados Iguales, repetir."])},
        "startstarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comienza Juego"])},
        "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizar"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
      },
      "ialevel": {
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzado"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experto"])}
      },
      "message": {
        "ifyoulose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Si Pierdes"])},
        "ifyouwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Si ganas"])},
        "youwilllosematch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perderás el partido."])},
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego Finalizado"])},
        "matchover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partido Finalizado"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamar Partido"])},
        "nogameslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay juegos registrados."])},
        "logtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de Juegos"])},
        "confirmdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Seguro quieres doblar?"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar Partido"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir Partido"])},
        "analysysnotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta herramienta es muy cara de operar, y no estará disponible por un tiempo. Puedes analizar las jugadas individualmente desde el Registro de turnos."])},
        "practiceover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Práctica Finalizada"])}
      },
      "subject": {
        "turnslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro Turnos"])},
        "gameevolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolución del Juego"])},
        "turns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turnos"])},
        "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla"])},
        "accelerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceleradores"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
        "p2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humano"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas"])},
        "matchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del Partido"])},
        "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity"])},
        "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganador"])},
        "newvisitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay un nuevo visitante en tu juego."])}
      },
      "tag": {
        "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leyenda"])},
        "playersmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turno Jugador"])},
        "novalidmoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin movimientos válidos"])},
        "forcedmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimiento forzado"])},
        "gamedoubled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego Doblado"])},
        "tieline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea de empate (0)"])}
      }
    },
    "preferences": {
      "acelerators": {
        "autoDoneOnLastValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar el turno al mover el último movimiento válido"])},
        "autoRoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirar automáticamente los dados"])},
        "autoMoveOnOneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover inmediatamente si hay un único movimiento al seleccionar una piedra"])},
        "autoDoneOnNoValids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar el turno si al tirar los dados no hay movimientos válidos"])},
        "autoRollForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siempre tirar los dados automáticamente, incluso con posibilidad de doblar."])}
      },
      "messages": {
        "showAdvices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar Consejos"])},
        "showInvalidMoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibujar movimientos inválidos"])},
        "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar Mensajes de Estado"])},
        "playSounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar Sonidos"])},
        "allowVisitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir Espectadores"])},
        "allowInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir Invitaciones"])},
        "showPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar posición de las columnas"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad de Usuario"])},
        "allowedGameTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos Permitidos en Invitaciones"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferencias"])}
    },
    "lobby": {
      "messages": {
        "connectingtoengine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectando al Motor"])}
      },
      "welcome": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido!"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes crear un usuario invitado, o ingresar tu email para tener uno permanente."])},
        "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugar como Invitado"])},
        "guest_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para jugar como invitado, haz clic acá. Este usuario estará enlazado a este cliente."])},
        "changelater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes agregar un e-mail más tarde para guardarlo."])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar o Ingresar"])},
        "create_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crear o recuperar un usuario, ingresa tu dirección de e-mail"])}
      },
      "auth": {
        "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa el código de 4 digitos enviado a tu e-mail"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelar"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validar"])},
        "useemailtosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega un email para reclamar a este usuario."])}
      },
      "action": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Sesión"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
        "qrlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Login"])},
        "loginguest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitado"])},
        "loginemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])}
      },
      "confirm": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión sólo elimina un cookie. Puedes acceder a esta cuenta más tarde si registraste un e-mail. ¿ Seguro quieres continuar ?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este usuario será completamente eliminado del sistema, e irrecuperable. ¿ Seguro quieres continuar ?"])}
      },
      "user": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones de usuario"])}
      },
      "message": {
        "useqr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lee este código QR en cualquier dispositivo donde quieras ingresar con este usuario."])},
        "closesession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión en este dispositivo"])},
        "logindifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión en otro dispostivo"])},
        "permanentremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar usuario permantemente de la BBDD"])},
        "notabakertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No eres Abaker"])},
        "notabakertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para poder jugar contra humanos debes tener al menos nivel <b>Abaker</b>. También puedes hacer <b>amigos</b> e invitarlos a jugar."])},
        "howtoabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para convertirte en <b>Abaker</b>, Completa los niveles de <b>práctica </b>o gana un <b>partido de Abak </b> de al menos <b>5 puntos</b> contra el servidor jugando en <b>Avanzado</b> o <b>Experto</b>."])},
        "stakeforabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo se puede apostar ELO points en partidos de Abak"])},
        "abakerrequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gana $$(left)$$ partidos para desbloquear el siguiente nivel."])}
      },
      "subject": {
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuesta"])}
      }
    },
    "message": {
      "newmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes mensajes nuevos"])},
      "retryingerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reintentando"])}
    }
  },
  "ayuda": {
    "generales": {
      "delsoldado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de la Clase"])},
      "situaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situaciones"])},
      "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click en un soldado para obtener ayuda."])},
      "highest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes mover la piedra más alta de cada columna primero."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes mover una piedra, si ésta tiene otra piedra encima."])}
      },
      "5max": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay un límite de 5 piedras por columna."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la zona del campo, no puedes apilar más de 5 piedras."])}
      }
    },
    "piedras": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldado"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iguales al Backgamon clásico"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El soldado es una ficha normal del Backgammon clásico. Cada equipo tiene 9."])}
      },
      "2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueve hacia adelante y atrás"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El General puede mover hacia adelante y hacia atrás, con algunas condiciones. Sólo puede mover en una dirección por turno, y sólo puede mover una vez hacia atrás."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede mover hacia atrás si ya ha movido hacia adelante durante el turno"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si mueve hacia atrás, ya no puede mover otra vez durante el turno."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede mover atrás si todas las demás fichas de su equipo están en su casa."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si está entrando desde la barra, el siguiente movimiento PUEDE ser hacia atrás."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si mueve adelante"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si mueve atrás"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no puede ir adelante"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no puede ir atrás"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puede ir adelante"])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardia"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más poderosos que los demás"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los Guardias generan un bloque, como si fueran dos soldados unidos, pero pueden ser atrapados por el druida, capturados por otro guardia, o por dos soldados no-guardias si ambos caen sobre él. Cada equipo tiene 2."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloquea como si fueran dos soldados"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puede ser atrapado por el druida"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puede ser capturado por otro guardia"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dos soldados pueden capturarlo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para capturar un guardia con dos soldados (no-guardias), debes poder <b>pegar con ambos</b> sobre éste en <b>movimientos consecutivos</b> dentro del turno."])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druida "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atrapa y retiene"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Druida puede atrapar al adversario en el mediocampo si está solo. El soldado atrapado queda inmovilizado mientras el Druida esté encima."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druida tiene atrapado al soldado"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druida está seguro"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druida es atrapado por druida"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druida será capturado"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dentro de las casas, el druida se comporta como un soldado normal."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druida puede atrapar a un soldado solo"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druida no puede atrapar una columna bloqueada"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medio campo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druida atrapa"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casa negra"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casa blanca"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druida captura"])}
      },
      "5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Par"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solo juega dados <span>pares</span>"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Herido par sólo puede mover cuando la distancia de su desplazamiento es par, o cualquier número si esta entrando (desde la barra) o saliendo del tablero."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Herido Par sólo puede mover los dados que tienen valor par"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando escapa de la barra, puede mover cualquier dado."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando sale del tablero, puede mover cualquier dado."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al desplazarse entre <span>triángulos</span>, ambos siempre serán del <span>mismo color</span>. <span> De ahí el símbolo</span>."])}
      },
      "6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Impar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solo juega dados <span>impares</span>"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Herido Impar sólo puede mover los dados que tienen valor impar"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando escapa de la barra, puede mover cualquier dado."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando sale del tablero, puede mover cualquier dado."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Herido Impar sólo puede mover cuando la distancia de su desplazamiento es impar, o cualquier número si esta entrando (desde la barra) o saliendo del tablero."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al desplazarse <span>entre triángulos</span>, ambos siempre serán de <span>diferente color</span>. <span>De ahí el símbolo</span>."])}
      },
      "56": {
        "leavingorcaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuando está capturado, o saliendo del tablero, puede jugar cualquier dado."])}
      }
    }
  },
  "intro": {
    "content": {
      "tituloheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> es una variante del Backgammon que agrega <span>clases de soldados</span> al juego original, cada una con sus propias características y <span>comportamiento único</span>."])},
      "isaboardgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> es un juego de mesa de estrategia abstracta para dos jugadores. Necesitarás algo de suerte y mucha inteligencia para ganar en este remake del clásico <span>Backgammon</span>."])},
      "backgammon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon<span>²</span>"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution </span> utiliza las mismas reglas del backgammon, pero añade 5 clases de soldados. Este pequeño cambio tiene consecuencias excepcionales."])},
      "gotoabakme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes aprender y jugar online acá."])},
      "abakapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vas a encontrar una aplicación web donde podrás jugar contra un poderoso servidor, y contra personas."])},
      "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscanos en las redes sociales."])},
      "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugar"])},
      "printrules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes encontrar stickers para tu tabla, un manual de reglas listo para imprimir, y otras utilidades acá:"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramientas"])},
      "nonprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> es un esfuerzo épico, de un solo hombre, sin fines de lucro, por difundir estas increibles reglas en la humanidad. <br> Inventado, desarrollado, mantenido y financiado por <span>Samy Garib</span>, un desarrollador de software Chileno."])},
      "sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sean"])},
      "entonces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entonces"])},
      "classbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una variante de backgammon basada en clases"])},
      "quees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una variante de backgammon basada en clases"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al agregar clases el juego cambia enormemente. La altura de una ficha en la columna importa,  y por lo tanto el orden de los movimiento también. <span>Abak Evolution</span> agrega una nueva <span>dimensión al Backgammon Clásico</span>."])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprender"])},
      "moredetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más detalles"])},
      "timagen": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un backgammon clasista, tal como tú."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon en esteroides."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Backgammon)²"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon, para grandes."])}
      },
      "abakisto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak es al Backgammon, lo que el Ajedrez es a las Damas"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas"])}
    },
    "privacy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Políticas de Privacidad"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolección de Información y uso."])},
      "content": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is an online platform to play the Abak Rules, a backgammon's Rules expansion."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is a one-man, non-professional, non-proffit effort to spread this rules."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform  is free, as all other Abak Evolution Assets. "])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Ads free, and with optional registration capabilities."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not perform any external activity with users's data. User Data is only used to store the user state and ranking."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not share user's information with other parties."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relies only on external login platforms sush as facebook, Google and Twitter, using Google's Firebase Auth Service as a conector hub."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will not publish any information on your social accounts, neither will read your contacts information. "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps on social platforms will only collect your personal avatar an name."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uses cookies to store your account related data. External login providers use their own cookies. "])}
      }
    }
  },
  "FAQ": {
    "preguntas": {
      "1": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Es Abak Evolution más <span>complejo</span> que el Backgammon?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La respuesta sincera es sí, pero a cambio de eso el juego es mucho más entretenido y dinámico. Al incorporar clases agregas una identidad para cada soldado, y tienes que pensar en sus propias características. Adicionalmente Abak tiene más posibles jugadas para un tiro de dados, puesto que el orden de los movimiento podría modificar el resultado."])}
      },
      "2": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuán <span>diferente</span> es Abak Evolution del Backgammon Clásico?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las reglas base son prácticamente las mismas, pero al agregar clases el juego cambia enormemente.<ul><li><span>Una nueva dimensión:</span> La altura de una ficha en la columna importa. Esto crea una nueva dimensión en el juego: el eje Y. Piénsalo así: En Abak no basta decir que una columna tiene 3 fichas blancas, debes describir cada una, en orden.</li><li><span>Más combinaciones:</span> Cada posición de Backgammon tiene (3.346.200)² posiciones posibles en Abak.</li><li><span>Las fichas tienen identidad:</span> Debes preocuparte por cada ficha, según sus capacidades.</li><li><span>El orden de los movimientos importa:</span> Si mueves una ficha antes que la otra puedes afectar drámaticamente el resultado de la jugada</li></ul>"])}
      },
      "3": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo aprender a jugar Abak Evolution?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa los recursos de esta página para aprender a jugar, desde Backgammon, hasta Abak. La interfaz de usuario de la aplicación es muy útil puesto que indica las reglas."])}
      },
      "4": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Necesito saber jugar Backgammon para jugar Abak Evolution?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí. Debes tener al menos conocimientos básicos de las reglas del Backgammon Clásico."])}
      },
      "5": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué sólo se puede apilar 5 fichas por columna?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si bien esta regla existe en el Backgammon Clásico, no se aplica regularmente. Aporta mucha inestabilidad en el proceso final de juego. En Abak se aplica porque la regla es buena (yo jugaba así cuando inventé Abak) pero también por un motivo práctico: visibilidad de las fichas."])}
      },
      "6": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Es Abak Evolution más lento o más largo que el Backgammon Clásico?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que estás familiarizado con los diferentes soldados, la velocidad del juego es muy parecida al Backgammon Clásico. Por la capacidad que tiene el general de retrodecer, en ocaciones el juego es más largo (en cantidad de turnos) que el Backgammon."])}
      }
    },
    "header": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizás tienes alguna pregunta"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búscala acá"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas Frecuentes"])}
    }
  },
  "reglas": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas Generales"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar clases de soldados tiene consecuencias"])},
      "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada columna sólo puede apilar 5 soldados."])},
      "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se debe mover el soldado más arriba en la columna primero."])},
      "porque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dos reglas generales muy simples se añaden a las del Backgammon"])},
      "startpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición de Partida de Abak Evolution."])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al agregar clases el juego cambia enormemente. La altura de una ficha en la columna importa,  y por lo tanto el orden de los movimiento también. <span>Abak Evolution</span> agrega una nueva <span>dimensión al Backgammon Clásico</span>."])},
      "mismasreglas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution </span> utiliza las mismas reglas del backgammon, pero añade 5 clases de soldados. Este pequeño cambio tiene consecuencias excepcionales, y requiere dos reglas nuevas que son evidentes y se dan en forma natural."])},
      "thisguys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las mismas reglas del Backgammon, con estos tipos."])},
      "addsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega clases de soldados, cada una con diferentes características."])},
      "startposcontenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posición de partida de <span>Abak Evolution </span>es muy similar a la de Backgammon, pero incorpora las <span> clases </span> y usa 4 fichas atrás, al estilo Nackgammon."])},
      "ttd_titulo": null,
      "ttd_texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo imagina las posibilidades"])}
    }
  },
  "learn": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de Aprendizaje"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recursos para entender y aprender Abak Evolution"])},
      "contenido1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un grupo de recursos que te llevarán a jugar Abak Evolution"])},
      "videoT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de Youtube"])},
      "videoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dale una mirada al canal de youtube. Tiene un video introductorio, y videos cortos con tutoriales y ejemplos."])},
      "versusT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s BackGammon"])},
      "versusC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una mirada a cuan diferente es Abak Evolution del Backgammon clásico"])},
      "PDFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas en PDF"])},
      "PDFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga un archivo con las reglas listo para impimir para tus necesidades offline"])},
      "reglasT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro de Reglas"])},
      "reglasC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas completas de Abak Evolution en una sola página. Ideal para usarla como referencia, imprimiéndola, o directo en tu teléfono"])},
      "tablaT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para tu Tabla"])},
      "tablaC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un set de stickers para tu tabla física"])},
      "specs": {
        "specs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especificaciones"])},
        "typeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "typeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estrategia<br>Abstracta"])},
        "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Años"])},
        "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugadores"])},
        "complexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complejidad"])},
        "mechanics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecánica"])},
        "turnbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basado en turnos"])},
        "dicedriven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigido por dados"])},
        "perfectinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información perfecta"])},
        "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difícil"])}
      }
    },
    "faq": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizás tienes alguna pregunta"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búscala acá"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas Frecuentes"])}
    },
    "files": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una lista desordenada de archivos utlilitarios"])}
    },
    "blog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Blog"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una lista de articulos de temas relacionadoas con Abak. Más que nada para efectos de SEO. Pero si tiene tiempo, adelante."])}
    },
    "introvideo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve el video de introducción"])}
    }
  },
  "about": {
    "history": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de Abak Evolution"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un breve descargo."])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca me he sentado a tratar de inventar un juego de mesa. Un día estaba jugando Backgammon con un amigo, un poco aburrido por la monotonía de las reglas, y esta idea saltó a mi cabeza. Fue en Isla de Pascua, en Septiembre de 1999."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 20 años han pasado desde ese momento, y he invertido miles de horas desarrollando: las reglas, la mecánica, la aplicación, el sitio web, el contenido de marketing, etc."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hago esto en forma gratuita, porque creo en el fondo de mi corazón que este juego es el sucesor del Backgammon como lo conocemos, y se convierte en eso durante mi vida, las consecuencias serán una retribución suficiente. Gracias a ser desarrollador de software, tuve la posibilidad de hacer esto en mi tiempo libre, pásandolo bien en el proceso."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando estaba haciendo la aplicación para mostrar al mundo mi invención pensaba que al subirla a internet, inmediatamente los jugadores de Backgammon se iban a enamorar de la nuevas reglas, e iban a pensar “diablos, como no se me ocurrió esto a mi”. Sorpresa: no fue así. Mientras escribo esto, cuatro años completos desde que subí Abak, con miles de dolares en contenido y campañas de marketing, no tiene más de unos cientos de jugadores."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution enfrenta muchas barreras de entrada que han probado ser difíciles de superar:"])},
      "g": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es bastante más complejo que el Backgammon, y tiene una curva de aprendizaje muy pronunciada."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los jugadores de Backgammon aman su juego, y ven en Abak a un intruso, o una amenaza."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mucha gente quiere jugar contra humanos. Para lograr eso se necesita una masa crítica de jugadores."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tengo suficientes recursos para escalar las actividades de comunicaciones y contenido."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación no tiene todas las herramientas que buscan los usuarios avanzados de Backgammon"])}
      },
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esos son mis frentes de batalla actuales. Uso mi tiempo libre para hacerme cargo de ellos, priorizando por ahora la generación de contenido, que por lejos percibo como la mayor barrera de entrada. Gasto demasiado tiempo trabajando en la inteligencia artificial, mi parte favorita, aunque es más o menos innecesario por el momento puesto que el actual algoritmo juega suficientemente bien."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este proyecto es épico, de una persona, y sin fines de lucro."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Samy Garib, soy un desarrollador de software Chileno."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por tu tiempo."])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
      "thanks-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agradecimientos"])},
      "rant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargo"])}
    },
    "history1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendí a jugar backgammon cuando era un niño, y jugué muchísimo con mi familia y compañeros de colegio."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el transcurso de mi vida, le enseñe a jugar backgamon a muchos amigos. Eventualmente llegué a un nivel donde ya no disfrutaba jugar si no había dinero de por medio."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventé Abak en un viaje a Isla de Pascua, en 1999."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La primera versión era muy diferente a la actual, y cuando enseñaba a mis amigos a ninguno le interesaba tanto. Así que quedó dormida, varios años."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un día, en el trabajo, le enseñé a jugar Backgammon a unos compañeros, y cuando se rehusaron a seguir jugando por dinero, les mostré este juego que había inventado."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pero antes, le hice los cambios necesarios para hacerlo fantástico (agregué los heridos y algunas restricciones para el druida y el general)."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fue un éxito total. Desde ese día, al menos una pareja juega Abak a la hora de almuerzo en VRWEB."])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estos compañeros me empujaban a hacer una versión de software de estas reglas, pero la vida tiene siempre sus prioridades."])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varios años pasaron, hasta que en Septiembre de 2009 tuve un accidente que me dejó en casa 2 semanas."])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree una primera versión de este software, pero lleno de fallas y detalles, y requirió tanta energía que lo abandoné por años nuevamente."])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde entonces, varios empujones de entusiasmo me pusieron a trabajar, hasta que finalmente llegó a un nivel donde puedo mostrarlo."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me siento afortunado de tener las habilidades para comunicarme con los computadores y darles instrucciones. Si no fuera por eso, este proyecto no hubiera sido posible. Y no lo habrías podido conocer."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eso es, acá esta. Un proyecto de una persona. Épico. Gracias a www.vrweb.cl por las gráficas y el apoyo."])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espero que lo hayas disfrutado"])}
    },
    "donate": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["días"])},
      "hh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HH"])},
      "devtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo Desarrollo"])},
      "onlinesince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Desde"])},
      "playedgames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos Realizados"])},
      "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios Activos"])},
      "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos"])},
      "donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto Donado a la Fecha"])},
      "otherincomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros Ingresos"])},
      "expenses_op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de Operaciones"])},
      "adv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicidad"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos"])},
      "devtimeprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo y Creación Contenido"])},
      "estadisticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donar"])},
      "necesities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Además de mi tiempo, tengo gastos en diversos tópicos"])},
      "necesity": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing (Videos y Gráficas)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicidad (Adwords+Facebook)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DataCenter (Amazon AWS)"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo de UI (Diseño+Traducciones)"])}
      },
      "closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si te encantó el juego, y puedes ayudar, será de una inmenza ayuda. Gracias!!"])}
    },
    "samy": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy un apasionado desarrollador de software, nacido en Chile en 1973. Como decendiente Palestino, fui criado con muchos elementos de la cultural Arabe, siendo el Tawle (o Backgammon) uno de ellos.\n"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajo en mi propia empresa, VRWEB, desde 1999, y a pesar que mi rol es comercial, amo Programar. Abak es mi cajita de arena."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy padre de 3, y el cocinero de 4."])}
    },
    "thanksto": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A través de los años he tenido mucha ayuda de gente alrededor del mundo: traducciones, ayuda técnica, fan art, etc."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En una categoría especial están aquellos que, si bien no ayudan, han interactuado conmigo mostrando tal entusiasmo, que han sido catalizadores de mis objetivos."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por último, pero no menos importante, hay una cantidad gigantesca de personas que han trabajado en proyectos open source maravillosos y son la base de la plataforma Abak."])}
    }
  },
  "contact": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta Abak Evolution"])},
    "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si necesitas comunicarte conmigo usa estas alternativas"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes encontrar Abak Evolution en las redes sociales y escribirme desde ahí."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O enviarme un email"])}
  },
  "piepagina": {
    "texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> es un esfuerzo épico, de un solo hombre, sin fines de lucro, por difundir estas increibles reglas en la humanidad. <br> Inventado, desarrollado, mantenido y financiado por <span>Samy Garib</span>, un desarrollador de software Chileno."])},
    "privacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Políticas de Privacidad"])}
  },
  "lobby": {
    "subject": {
      "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente"])},
      "human": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humano"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial"])},
      "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutoriales"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restantes"])},
      "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigos"])},
      "players": {
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Línea"])},
        "invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugadores Invisible"])},
        "last_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
        "prefered_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos Preferidos"])},
        "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Conectado"])},
        "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugando"])},
        "atlobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])}
      },
      "friendship": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazados"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausados"])},
        "friendship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amistad"])},
        "notyet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no tienes amigos. Puedes solicitar amistad en la sección jugadores en línea."])},
        "requestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿ Enviar solicitud de amistad ?"])},
        "newrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes solicitudes de amistad nuevas"])},
        "onlinefriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigo en línea"])}
      },
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partido"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
      "game_visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitantes del juego"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitantes"])}
    },
    "alerts": {
      "nogamestojoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay juegos creados"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon"])},
    "action": {
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugar"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprender"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
      "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practicar"])},
      "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugados"])},
      "won": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganados"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
      "sharein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte en"])},
      "cpypasteshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia y comparte este enlace"])},
      "shareabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte Abak con tus amigos!"])},
      "shareboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte tu juego"])},
      "howtoshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta herramienta creará un elemento que puedes compartir con la foto de tu tabla. Puedes agregar una descripción opcional."])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
      "contactsocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame en tu red social preferida"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])}
    },
    "user": {
      "subject": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
        "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
        "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medallas"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "howemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al guardar, recibirás un mensaje con un enlace de validación."])},
        "checkvalidationemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No haz validado tu casilla. Intenta enviando nuevamente el mensaje."])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diariamente"])}
      },
      "action": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar"])}
      },
      "message": {
        "knownguestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás registrado sólo en este dispositivo. Usa el <b>Login Social</b> para crear un usuario permanente y multidispositivo."])},
        "guestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este usuario está almacenado enlazado al dispositivo. Si quieres recuperarlo después, es mejor que agregues un e-mail válido."])}
      }
    },
    "messages": {
      "newinvitation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación a jugar"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si aceptas, este partido lo podrás continuar cuanto termine el otro."])},
        "headding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes una invitación para unirte a un juego contra otro jugador."])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La invitación fue a jugadores activos. La respuesta es anónima."])},
        "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La invitación fue hecha directamente a ti."])}
      },
      "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay Partidos para unirse."])},
      "p2pnomatchesoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus opciones"])},
      "playandwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugar contra el Servicio y esperar una invitación"])},
      "inviteonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Invitaciones a usuarios activos que están jugando contra el Servidor."])},
      "createhuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un juego contra Humano y esperar a un contrincante."])},
      "error": {
        "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo anda mal, no me pude conectar al servidor."])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes reportar este indicente a"])}
      },
      "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Lenguaje"])},
      "unfinishedgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un juego en proceso. Termínalo o ríndete desde adentro."])},
      "wontunlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este nivel de juego no suma para desbloquear la próxima práctica."])},
      "unlocklevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes que desbloquear este nivel de práctica."])},
      "inviteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita a $$(player)$$ a jugar."])},
      "nomoreplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay más jugadores disponibles."])}
    },
    "hallfame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salón de la Fama"])}
    },
    "chat": {
      "message": {
        "tooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje demasiado corto."])}
      }
    },
    "message": {
      "choosepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de Práctica"])},
      "newpracticelevelavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo nivel de Práctica disponible. Seleccionalo en el selector."])},
      "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajando, por favor espere."])},
      "becomeabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes jugar <b>Abak Backgammon</b> o <b>Backgammon Egipcio </b> en esta App."])},
      "becomeabakerhowto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para <b>desbloquear</b> todas las características tienes que convertirte en <b>Abaker</b>. Completa los niveles de <b>práctica</b> , o un partido de Abak contra el servidor en Avanzado o Experto."])},
      "welcome": {
        "customize1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personaliza a tu usuario"])},
        "customize2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia su nombre y Avatar"])},
        "stats1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
        "stats2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa tu historia como jugador."])},
        "hall2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conoce a los mejores Abakers"])},
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dale una mirada a estas herramientas en algún momento."])}
      },
      "caninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can invite available players"])},
      "cantinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes invitar a otros humanos hasta que seas un Abaker"])},
      "noonlineplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay jugadores online"])},
      "onlyabakersorfriendscaninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes invitar jugadores que no son tus amigos si no eres Abaker"])},
      "friendship": {
        "requestsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud Enviada"])},
        "requestexists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud ya existe"])},
        "responsesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta enviada"])}
      },
      "emptylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista vacía"])}
    }
  },
  "hall": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salón de la Fama"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salón de la Fama"])}
  },
  "tips": {
    "general-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El general está atrapado."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El general es un soldado de gran importancia, y no puede mover."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como puede ir hacia atrás, es muy útil para prestar ayuda a los rezagados del fondo, y es clave para maximizar las posibilidades de armar y defender tu casa. Intenta siempre que sea el primer soldado de la columna, para garantizar su libre movimiento."])}
    },
    "druid-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El druida está atrapado."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El druida puede ser gravitante en el juego."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si atrapa a su oponente entrega un control total de la velocidad del partido. Es importante que esté siempre listo para atrapar, y para eso debes intentar mantenerlo como la primera piedra de la columna."])}
    },
    "tooclose": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El druida está muy cerca de casa."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El druida es un arma mortal, pero..."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su gran poder se encuentra en el medio campo. No acerques mucho este soldado a tu casa, puesto que dentro de ella no atrapa, sino que captura, como un soldado normal."])}
    },
    "inmobilized-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un soldado inmovilizado."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un soldado inmovilizado por el druida enemigo, pero éste no está protegido."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intenta capturarlo antes que tu oponente coloque un soldado encima."])}
    },
    "inmobilized-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un soldado inmovilizado."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un soldado inmovilizado por el druida enemigo, y no puedes quitarlo."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes intentar atraparlo tu también, y contener tu juego hasta que te libere. "])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa al general para gastar dados yendo atrás"])}
    },
    "inmobilized-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un enredo."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes inmovilizado a su druida, pero su druida tiene inmovilizado a un soldado tuyo."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifica bien como salir de este entuerto. Tienes una ventaja."])}
    },
    "inmobilizing-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizaste un soldado enemigo."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un soldado enemigo inmovilizado por tu druida, pero éste no está protegido."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intenta colocar un soldado encima para asegurar su posición."])}
    },
    "inmobilizing-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizaste un soldado enemigo."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizaste a un soldado enemigo con tu druida, y está protegido. Buena posición!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuerda que puedes soltar y comer en un mismo tiro. Ten soldados a mano para el momento oportuno."])}
    },
    "inmobilizing-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un enredo."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su druida tiene inmovilizado a tu druida, pero tu druida tiene inmovilizado a un soldado suyo."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifica bien como salir de este entuerto. Estás en desventaja."])}
    },
    "even-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Par atrapado"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu herido par está atrapado y no puede mover."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes que buscar una forma de obligar a tu oponente a liberar esa posición."])}
    },
    "even-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Par casi atrapado"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu herido par está en peligro de ser atrapado"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te cuidado e intenta escapar de ahí lo antes posible"])}
    },
    "odd-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Impar atrapado"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu herido impar está atrapado y no puede mover."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes que buscar una forma de obligar a tu oponente a liberar esa posición."])}
    },
    "odd-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Impar casi atrapado"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu herido impar está en peligro de ser atrapado"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te cuidado e intenta escapar de ahí lo antes posible"])}
    },
    "guard-not-on-floor": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sácale más provecho a tu guardia."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los guardias pueden mantener un punto estando solos. Para capturarlos, dos soldados deben pegar en el mismo tiro, con muy baja probabilidad."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo mejor es tenerlos a la altura del piso, y de esta forma podrás utilizar todo su poder."])}
    }
  },
  "pdf": {
    "rules": {
      "generales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas reglas cambian levemente la mecánica del juego en general. Note que de cierta forma una es implícita en el juego original (sin ser necesaria de aplicar), y la otra directamente existe en algunas versiones de Backgammon."])},
      "intro0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution Backgammon</b> es una variante del Backgammon. Agrega 5 clases de soldados, cada una con características y comportamientos únicos."])},
      "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este documento explica las reglas de <b>Abak Evolution Backgammon</b>. Requiere conocimiento previo de las reglas del backgammon clásico."])},
      "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existen leves cambios en las reglas al juego original, que resultan naturales y evidentes al agregar las clases."])},
      "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> utiliza como base las reglas del backgammon, y agrega otras correspondientes al comportamiento de cada clase."])},
      "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pudes jugar Abak Evolution en"])},
      "intro5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pega en tu tabla de backgammon stickers que puedes encontrar acá: "])},
      "intro10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas reglas están disponibles en "])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las diferentes clases de soldados en Abak tienen características que modifican su comportamiento."])},
      "start0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición de Partida"])},
      "start1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posición de partida es muy similar al backgammon, pero con 4 fichas atrás en vez de dos: \n<ul> \n<li>Los heridos (⨯2) parten atrás <span class='etiqueta-posicion'>[p.24-1] </span><span class='etiqueta-posicion'> [p.23-1]</span>, cada uno posicionado para llegar con el máximo dado posible al punto 18. </li>\n<li>El druida parte con ellos, lo más atrás posible <span class='etiqueta-posicion'>[p.24-2]</span>. Su radio de acción está en el medio campo, y es el que tiene que estar al acecho, siempre atrás.</li>\n<li>Los guardias (⨯2) están cerca de casa <span class='etiqueta-posicion'>[p.13-3]</span> <span class='etiqueta-posicion'>[p.8-2]</span> listos para formar semi-estructuras, pero no están listos para mover, quedan bajo una ficha cada uno.</li> \n<li>El general parte en casa <span class='etiqueta-posicion'>[p.6-4]</span>, para ayudar a formar estructuras adelante y atrás.</li>\n<li>El resto, los soldados normales (⨯9), parten en <span class='etiqueta-posicion'>[p.23-2]</span><span class='etiqueta-posicion'>[p.13-1]</span><span class='etiqueta-posicion'>[p.13-2]</span><span class='etiqueta-posicion'>[p.13-4]</span><span class='etiqueta-posicion'>[p.8-1]</span><span class='etiqueta-posicion'>[p.8-3]</span><span class='etiqueta-posicion'>[p.6-1]</span><span class='etiqueta-posicion'>[p.6-2]</span><span class='etiqueta-posicion'>[p.6-3]</span> </li>\n</ul>\n"])},
      "bidimensional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> convierte el backgammon clásico en un juego <b>bidimensional</b>. Esto significa que la altura de una piedra importa con respecto a las demás, y por lo tanto <b>el orden de los movimientos</b> es un nuevo factor en el juego."])},
      "imagina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagina las posibilidades"])},
      "classic_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta posición de tu equipo, en el Backgammon clásico"])},
      "millions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivale a millones de combinaciones en Abak Evolution. Una pequeña muestra:"])},
      "moves_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de Movimientos"])},
      "drastically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El orden de los movimientos puede cambiar drásticamente el resultado de la jugada"])},
      "druid_traps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el druida es el primero en mover [3], el soldado queda atrapado."])},
      "odd_captures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el herido mueve primero [1], entonces captura al soldado, y lo envía a la barra."])},
      "encuentra_abak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿ Cómo jugar ?"])},
      "redes_sociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra <b>Abak Evolution</b> en las redes sociales"])},
      "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga la aplicación para el teléfono desde tu tienda de aplicaciones."])},
      "play_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abre un navegador y apunta acá:"])},
      "capturar_guardia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para capturar un guardia con dos soldados (no-guardias), debes poder <b>pegar con ambos</b> sobre éste en <b>movimientos consecutivos</b> dentro del turno."])},
      "abak_vs_backgammon_titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak vs Backgammon Clásico"])},
      "abak_vs_backgammon_cuerpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si bien Abak Evolution solo se diferencia con el Backgammon Clásico por tener clases de soldados, esto realmente produce un cambio significativo en el juego, y tiene dos grandes consecuencias."])},
      "abak_vs_backgammon_combinaciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak tiene"])},
      "abak_vs_backgammon_combinaciones2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posibles posiciones por cada posición de Backgammon Clásico."])},
      "subject": {
        "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldados"])}
      }
    }
  },
  "tutorials": {
    "intro": {
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En estos tutoriales puedes aprender a jugar Backgammon Clásico y Abak Evolution. ¿ Qué te gustaría aprender ?"])},
      "lback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprender Backgammon"])},
      "lback2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sé jugar Backgammon y quireo aprender desde el comienzo."])},
      "labak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprender Abak"])},
      "labak2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya sé jugar Backgammon, quiero aprender a jugar Abak."])},
      "whyask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es solo para aplicar un filtro que después puedes cambiar."])}
    },
    "options": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Básico"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio"])},
      "uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incompletas"])}
    },
    "level": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trae a tu soldado a casa y sálvalo."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende como ['mover'] tu soldado."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un soldado al final de la tabla, sin enemigos. Muevelo hasta que llegue a casa y luego sálvalo sacándolo del tablero."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corre a Casa"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El objetivo del backgammon es traer a todas tus fichas (['soldados']) a casa. Esto se hace tirando dos dados [13,15] y moviendo el número de espacios que dice cada uno."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar soldados."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que todos tus soldados están dentro de tu casa, puedes ['removerlos'] de la tabla. El primer jugador que ['saca todos'] sus soldados, ['gana'] el juego."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento de Dados"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzas dos [12,13] dados, que te dan dos [2] movimientos si son diferentes, y cuatro  [4] si son iguales."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz click en el botón de lanzamiento."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora mueve tu soldado dos veces, uno por cada dado, haciendo click sobre éste, y seleccionando la columna de destino."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugaste todas tus jugadas válidas. Confirma que terminaste con el botón."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente!"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completaste el turno. Continua hasta que tu soldado esté a salvo."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu oponente no está jugando, así que lanza de nuevo."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora tienes un doble que te da cuatro [!4] movimientos de [d13] espacios. Muévelos todos."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo. Confirma tu movida."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu soldado está en casa."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando todos tus soldados están en Casa, puedes comenzar a sacarlos. Lanza los dados."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Lanzamiento."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puedes elegir la parte de ['afuera'] de tu casa [!0] para mover tu soldado."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente, haz finalizado el juego!."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego Finalizado."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo. Ya sacaste ‘todas’ tus fichas del tablero y el juego ha terminado."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende sobre los bloques en el próximo capítulo."])},
        "11b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien!. Hazlo de nuevo, juega el otro movimiento."])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escapa de la casa del oponente"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salta a través de los soldados del oponente para ['escapar'] de su casa y ['capturar'] su soldado."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes dos soldados al final de la tabla ['[s11]×2'], evita a los adversarios ['[s12]×5'] y trae tus soldados a casa."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiciones Bloqueadas"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un soldado puede mover a cualquier posición no bloqueada por su oponente. Una posición está bloqueada cuando el oponente tiene dos o más soldados en ella."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es la suma."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes mover diferentes soldados con cada dado, o el mismo soldado usando los dos dados, pero ten en cuenta que son dos movimientos independientes, y cada uno debe poder mover en una posición libre."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si haces clic en el soldado en la posición [23], verás que no puede mover ningún dado, porque ambas posiciones están bloqueadas. Por lo tanto no puede mover la suma de ambos. Debes mover el otro soldado."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiciste todas las movidas válidas. Confirma que estás listo."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indefensos"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si un soldado está solo en una posición, puede ser capturado por un soldado contrincante, si cae justo encima."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura al soldado moviendo sobre él."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has enviado a tu contrincante a la capilla!."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrincante Capturado."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando un jugador tiene soldados en la capilla, deben volver inmediatamente al tablero. Si no se pueden escapar de la capilla, no se puede mover ningún otro soldado."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende como escapar de la capilla en el siguiente capítulo."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No capturaste al soldado!"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se esperaba que movieras sobre el soldado solitario y lo capturaras. Inténtalo de nuevo."])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Tabla"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de la Tabla."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toma este tour rápido sobre la tabla de Backgammon."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Tabla"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es la tabla de Backgammon"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla de Backgammon"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiene [24] ['posiciones'], llamadas ['puntas'], divididas en [!4] cuadrantes."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiciones Especiales"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Además de la capilla [#B0] [#B1], y un lugar fuera de la tabla [0] [25], para los soldados rescatados."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La casa del jugador está en el cuadrante ['abajo-derecha'] . ['Puntas [1] a [6]']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa Oponente"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La casa del oponente está en el cuadrante ['arriba-derecha']. ['Puntas [24] a [19]']"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio Campo"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es tierra de nadie ['Puntas [7] a [18]']"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Capilla"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['capilla'] es la posición donde se dejan los soldados ['capturados']. [#B0][#B1]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuera de Tabla"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posición ['fuera de tabla'] ['[!0]-[!25]'] es donde dejas los soldados ['rescatados']."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla Invertida"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota que la tabla puede usarse invertida en el eje X. Estando las casa al lado derecho. En el menú puedes invertir la tabla cada vez que quieras."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldados"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada jugador tiene [15] soldados."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugador: [s11] , Oponente: [s12]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Opuesta"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los jugadores juegan en direcciones opuestas. El jugador 1 ([s11]) se mueve desde [24] a [1], hacia su propia casa."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Oponente"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El jugador 2 ([s12]) se mueve desde [1] a [24]."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza y mueve."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se juega por turnos, cada jugador lanza dos dados [d11][d12] y mueve sus soldados."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traelos a casa."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El objetivo del juego es traer tus soldados a casa ['Puntas [1] a [6]'], y cuando todos estén en casa, sacarlos del tablero [0]."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitulo Completado."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende como se mueven los soldados en el próximo capítulo."])}
      },
      "b2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escapa de la capilla del Oponente"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has sido [capturado], aprende como escapar de la [capilla]"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu oponente capturó dos de tus soldados y los envió a la capilla. Libéralos!."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La capilla"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La capilla es la columna en el medio de la tabla ([#B0] y[#B1]). Tiene los soldados que han sido ['capturados'] "])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La capilla"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes dos soldados ['capturados'] en esta tabla [s11][s11]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldados en la capilla"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando un jugador tiene soldados en la capilla del oponente, los ['debe'] liberar antes de poder hacer cualquier otra movida."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados para escapar"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El jugador escapa de la capilla entrando a la casa de su adversaro, desde su posición más lejana, como lo muestran los dados."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como escapar."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este caso las posiciones[19][dr6]  y [20][dr5] están bloqueadas, y el resto abiertas. La posición [!22][d13] tiene una piedra sola capturable."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puedes liberar un soldado,en la posición [!21][d14]. La posición [20][d15] está bloqueada."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como quedó un soldado en la capilla [B1], no puedes hacer el otro movimiento con otro soldado. Deberás esperar. Confirma tu jugada."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza nuevamente los dados"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puedes salir por la posición [!22][d13], y capturar a tu oponente. Como todos tus soldados están fuera de la capilla, puedes mover [d16] con cualquier soldado."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súper. Confirma."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libre para mover!"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente. Ahora tus soldados puede correr libres. Estás listo."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el próximo capítulo aprenderás como sacar soldados de la tabla."])}
      },
      "b3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva a todos tus soldados."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a ['salvar'] (sacar del tablero) a tus ['soldados']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos tus soldados están en tu casa. Tienes que ['removerlos'] del tablero para ['savarlos']."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición [!0]"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para sacar tus soldados del tablero, los mueves a la posición que está fuera de la tabla: [!0]"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes caer exáctamente en [!0]"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un soldado debe mover la cantidad ['exacta'] de ['espacios'] para caer ahí."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover desde posiciones más altas"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no hay soldados en la posición donde debería partir el movimiento, y hay soldados en una ['posición más alta'], debes mover esos soldados."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacar desde las posición más alta"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no hay soldados en una ['posición más alta'],puedes y debes mover los soldados de la posición más alta disponible , al punto [!0], sacándolos de la tabla."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación Actual"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acá, tu soldado en la posición más alta están en [5]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si lanzas un [d16]."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como tu soldado en la más alta posición está en [5], menor que [6], sacas desde [5]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si lanzas un [d15]."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caes exáctamente en [0] desde [5]. Puedes sacar."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si lanzas un [d14]."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como tu soldado en la más alta posición está en [5], más que [4], debes mover desde la posición más alta, de [5] a [1]."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si lanzas [d13][d12][d11]."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes elegir mover dentro de la tabla, o sacar al soldado que corresponde al punto."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados para ver un ejemplo."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes soldado en [6], pero como [5] es la posición más alta, puedes sacar un soldado. El [d14]  lo debes mover."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza de nuevo"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes 3 soldados en la posición [1]. Los puedes sacar, pero también puedes mover soldados desde atrás."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza de nuevo."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos tus soldados están muy por debajo de la posición [6], sálvalos todos."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuandos sacas todos tus soldados, el juego ha terminado."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y esto ['termina'] el tutorial de Backgammon. Puedes ir a practicar contra el servidor, o ver los otros tutoriales avanzados en el menú."])}
      },
      "a1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s Backgammon"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende como los dos juegos son ['iguales'], pero increíblemente ['diferentes']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue las instrucciones de este tutorial para aprender como Abak mejora a su padre."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es la tabla de Backgammon clásico"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[15] soldados iguales para cada equipo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Abak'] ordena la posición de partida un poco diferente, con [!4] soldados atrás."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Clases"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y, por supuesto, agrega['clases'] de soldados."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las mismas reglas"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las reglas del Backgammon clásico se aplican en Abak. Pero cada clase tiene su propio set de reglas adicionales."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General [s21]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueve en Ambas direcciones"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↶ [s21] ↷"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druida [s41]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmoviliza al oponente."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s41] = 🔒 "])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardias [s31]"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más poderosos que los demás"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s31] ≅ [s11]+[s11]"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Impar [s61]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo mueve dados impares"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s61] ↷ [d11] [d13] [d15]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Par [s51]"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo mueve dados pares"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s51] ↷ [d12] [d14] [d16]"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El eje Y"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tener diferentes clases de soldados, no solo agregar reglas nuevas. También afecta profundamente la ['mecánica'] del juego."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El eje Y"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acá tienes un ejemplo. Las posiciones [19] y [17] tienen las mismas clases de soldados, pero en diferente orden. Esto significa que la altura de un soldado en una posición importa, es decir, nace el eje [!Y]."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de los Movimientos"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto también significa que el orden de los movimientos importa, y puede cambiar una jugada drásticamente."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura el guardia!."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueve tu soldado normal [s11], y luego tu druida [s41], para capturar el guardia [s32]."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deberías haber capturado el guardia. Inténtalo de nuevo."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardia Capturado!."])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has capturado al guardia [s32]."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia el orden"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué hubiese pasado si hubieras movido el druida primero?"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmoviliza al guardia!."])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora mueve primero al druida [s41], y después tu soldado normal [s11], para atrapar al guardia [s32]."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es lo que esperaba."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se supone que debías mover tu druida primero y atrapara al guardia[s32] este."])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardia Atrapado"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como puedes ver, al alterar el orden se produce un movimiento completamente diferente."])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capítulo finalizado."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende más sobre cada clase en los siguiente capítulos."])}
      },
      "a2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Druida "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a usar el ['druida']. El soldado más temido."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue las instrucciones de este tutorial para aprender las características del druida, y aprovechar su inmenso poder."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Druida "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['druida'] es una herramienta muy poderosa, que requiere práctica para sacar provecho. ['Inmoviliza'] a su oponente, en vez de capturarlo."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizado"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su poder solo está presente en el [‘medio-campo’], donde puede inmovilizar soldados."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturado"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dentro de las ['casas'], el druida se comporta como un soldado normal ['captura'] a su oponente, y lo envía a la capilla."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueve ambos dados [d14] y [d12] con el ['druida'], para capturar al soldado ['normal'], e inmovilizar al ['guardia']."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma para continuar."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hiciste las dos acciones."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debías capturar al soldado [s12][22][d12] e inmovilizar al guardia [s32][18][d14]. Inténtalo de nuevo."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente, atrapaste a un soldado y capturaste a otro."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora su guardia [s32][18]  no puede mover, pero tu oponente puede capturar a tu druida porque está solo. Oh no, es tu turno!."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turno del Oponente."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente Jugando."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente Jugando."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu druida fue capturado."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu druida está en la capilla. Lanza los dados para salir."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escapa de la capilla, y atrapa a su soldado con tu druida en [20]"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente. Soldado atrapado."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu druida está aún solo y vulnerable. Pero hey, ¿que pude salir mal?"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turno del oponente."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente Jugando."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente Jugando."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caos."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora tu druida está atrapado por su druida. No lo puedes mover, pero el soldado bajo el tuyo tampoco!."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa tu soldado en [24] para capturar al druida de tu oponente y salvar el tuyo."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfecto. El druida está a salvo."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu druida tiene a un soldado inmovilizado. Está protegido, y armando un bloque en la posición."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No para siempre."])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventualmente tendrás que abandonar esa posición y liberar al guardia. Más sobre eso en los tutoriales avanzados."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capítulo finalizado."])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz completado este capítulo."])}
      },
      "a4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los Guardias"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a usar la fuerza de los ['guardias'] y su debilidad."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue este tutorial y aprende como usar los guardias para ganar ventaja."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Guardia"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El guardia es la clase más fuerte. Forma un bloque por si misma, como si fueran dos piedras, pero con algunas limitaciones. Tienes dos."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un soldado solitario"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este escenario, tienes un soldado contra dos guardias enemigos, ¿puedes escapar?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzaste [14,15] y no puedes mover porque están los guardias bloqueando ([19][d15] [20][d14]). Intenta mover tu soldado si quieres, para que veas el comportamiento del juego. Haz clic para continuar."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aparecen Refuerzos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los guardias no son invencibles. Dos soldados pueden capturarlo. Agreguemos a un compañero."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataca con ambos."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para capturar un guardia, debe caer sobre éste con dos soldados, en movimientos consecutivos. Veámos."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora trata de capturar al guardia con los dos soldados."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eso!. Confirma que terminaste."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien!. Esa es una manera de hacerlo."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porque, por supuesto, un guardia puede capturar a otro guardia."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pruébalo."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Así no tienes que simplemente creerme."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura a los dos!."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantástico. Estás listo."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capítulo finalizado."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz aprendido como funcionan los guardias."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No era el movimiento esperado. Intenta de nuevo."])}
      },
      "a3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a usar al general. El soldado más útil."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue las instrucciones de este capítulo para conoce las características del general, y como se usa."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El General"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ['general'] es muy útil. Puede ir hacia ['atrás'] y hacia ['adelante']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricciones"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[#1] En cada turno puede ir en una sola dirección.<br/>[#2] Solo puede hacer un movimiento atrás.<br>[#3] No puede ir atrás si el resto de su equipo está completo en casa.'"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez atrás."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el general va hacia atrás, ya no puede mover durante el turno."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en el general [s21]. Puede ver como puede mover hacia cuatro posiciones diferentes. Elige una hacia ['atrás'] (punto más alto)"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien. Haz click de nuevo. Verás que ya no puede mover en este turno. Mueve otro soldado."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para adelante muchos, pero no hacia atrás."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando el general mueve hacia adelante, puede mover todos los movimientos disponibles. Pero no puede ir atrás."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No era el movimiento esperado."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor sigue las instrucciones para poder completar el tutorial."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en el general. Ahora elige una posición hacia adelante."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en el general [s21] de nuevo. Ya no puede ir atrás, pero puede seguir hacia adelante. Mueve."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede ser el único fuera de casa."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El general no puede mover hacia atrás si todos los demás soldados de su equipo están en casa."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El general no puede mover hacia atrás si todos los demás soldados de su equipo están en casa."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si haces click en el general, puedes ver que puede ir hacia atrás. Asegúrate de mover el soldado regular para entrar a casa."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora haz clic en el general [s21] de nuevo. No hay otro soldado afuera, por lo que no puede ir hacia atrás."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capítulo finalizado."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz completado este capítulo."])}
      },
      "a5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido Impar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a mover el herido impar, y sufre sus restricciones."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes a tu herido impar en el fondo de la tabla, entre muchos enemigos. Sácalo de ahí, tráelo a casa, y sálvalo."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido Impar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido impar solo puede mover números impares: [d11][d13][d15]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por eso, es muy fácil de bloquear, y debes tenerlo en mente todo el tiempo."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escapa de ahí"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendrás ['3 lanzamientos'] para saltar a través de tu adversario. Elige sabiamente."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento #1"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este lanzamiento [11,13] te da una jugada válida, pero puedes elgir que dado (distancia) mover. Elige."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento #2"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz tus movimientos."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento #3"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este lanzamiento, tienes un dado impar que no puedes mover."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, haz escapado. Lanza para terminar."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueve."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exito!."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz retornado con tu herido impar a casa. Buen trabajo!"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu herido impar esta ahora completamente atrapado, porque [d11] cae en [20], [d13] en [18] y [d15] en [16], todos bloqueados. Inténtalo de nuevo!"])}
      },
      "a6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido Par"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende sobre el ['herido par'], y su predictibilidad de pesadilla."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes a tu ['herido par']  en el fondo de la tabla, entre muchos enemigos. Sácalo de ahí, tráelo a casa, y sálvalo."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido Par"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ['herido par'] solo puede mover dados pares: [d12][d14][d16]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es por esto que es muy fácil de bloquear, y debes tener constante atención sobre él."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predecible"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como siempre mueve número pares, puedes bloquearlo desde lejos (como puedes ver, no va a poder avanzar más allá de [20])."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escapa de ahí"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juega algunos turnos para salir de ahí."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este lanzamiento, el dado [d15] está abierto, pero no es un número ['par']. Sólo puedes mover [d14] a [20]."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora el ['herido par'] está completamente ['bloqueado']. Ningún dado te puede sacar de ahí. Pero le toca al oponente!."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turno del oponente."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente Jugando."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente Jugando."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suertudo!."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[d16] está abierta, cruza los dedos."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamos!. No esperes esto en la vida real. Ahora, corre y termina."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exito!."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz retornado con tu herido par a casa. Buen trabajo!"])}
      },
      "a7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heridos en la capilla."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las clases ['heridas'] pueden mover cualquier dado cuando entran de la ['capilla']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes tus ['heridos capturados']. Tendrás ['4 lanzamientos'] para sacarlos. Tu oponente no moverá."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El escape de los heridos."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los ['heridos'] tienen movimientos restringidos, pero cuando están en la ['capilla'], pueden entrar con ['cualquier'] valor de ['dado']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Lanzamientos"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[13,12] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento #1"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si hace click sobre los heridos en la capilla [B0], puedes ver que ambos puede salir con cualquier dado [22][d13] [23][d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Lanzamientos"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento #2"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz tus movimientos."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Lanzamientos"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [16,16] ↦ [15,15]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento #3"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz tus movimientos."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien, confirma tu movida."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Lanzamiento"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [u6,u6] ↦ [15,15]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanzamiento #4"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz tus movimientos."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien. Confirma tu jugada"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se esperaba que llegaras a casa con ambos heridos. Inténtalo de nuevo."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exito!."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llegaste con ambos heridos. Bien hecho!."])}
      },
      "a8": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heridos saliendo de la Tabla"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las clases ['heridas'] pueden mover cualquier dado al ['salir de la tabla']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Saca'] a tus ['heridos'] en este ejercicio, y practica como se comportan en esta situación."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvando a los Heridos"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los ['heridos'] tienen distintos movimientos, pero puede jugar cualquier valor de ['dado'] cuando salen de la tabla, como un soldado normal."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Impar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este escenario, el ['herido impar [s61]'] está en el punto [4]. Podría salir (si estuviera arriba) con [d16][d15][d14]. Puede mover con [d11] y[d13]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herido Pard"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ['Herido Par [s51]'] está en el punto [4]. Puede ['salir'] con [d16][d15][d14]. Puede mover [d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueve el ['herido par']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puedes sacar el ['herido par']. El ['herido impar'] no puede mover."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien!. Confirma que estás listo."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ['herido impar'] no puede mover [d12], pero puede mover [d13]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puede salir con [d12]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien!. Confirma que estás listo."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizado"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendiste como sacar a los ['heridos']. Busca más tutoriales en la sección nivel ['medio']."])}
      },
      "g102": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa tus guardias de la forma correcta"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus guardias son herramientas increíbles, pero podrías pasar todo un partido sin usar sus beneficios."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toma este tour acerca de los Guardias, y resuelve un problema."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los Guardias son casi como bloques."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace una diferencia cuando están solos en la base de la tabla."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque Absoluto"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando se enfrentan a un solo contrincante, hacen un bloque absoluto, siempre que ese contrincante no sea un Guardia."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] está completamente bloquado."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque temporal"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contra muchos enemigos, pueden ser capturados, pero con probabilidades muy bajas. Son una gran estructura temporal."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] captura con [d25][d25] y [d26][d26]"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No son un adversario para el Druida"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El druida puede atrapar a un guardia, tal como a cualquier otro soldado."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s42] está bloqueado en [6] pero puede atrapar en [7]"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If los apilas, podrías estarlos subutilizando"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenlos en la cima de la posición, si quieres atacar con ellos."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita apilar muchos soldados sobre ellos, para maximizar las capacidades de tu equipo."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pero cuando están en casa, ponlos siempre en la base, así no dejarás un soldado suelto cuando estés rescatando soldados."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es tu peor escenario."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquea al Herido Impar."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz la movida correcta, para atrapar completamente al herido impar"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido Impar [s61] no está completamente bloqueado, inténtalo de nuevo."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiciste un bloque y el Herido Impar está completamente bloqueado."])}
      },
      "bst": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimientos Iniciales"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Backgammon está ampliamente estudiado, y hay consenso sobre cuales son las mejores jugadas en la partida."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toma las mejores decisiones en la partida, para terminar este tutorial."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanne es un movimiento de dos soldados diferentes, desde diferentes posiciones, hacia un destino común."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es quizás la mejor partida. Haz tu mejor jugada."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intenta hacer un bloque en el punto [7]"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz creado un bloque importante"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El punto Dorado."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes hacer un bloque en una posición muy importante."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiciste un bloque en el punto dorado. Una importante posición de tu casa que debes cuidar."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro punto importante"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes hacer un bloque en una posición bastante importante."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiciste un bloque en un posición muy importante de tu casa."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un punto discutible, pero con bastante consenso."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes hacer un bloque en una posición bastante importante."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiciste un bloque en un posición muy importante de tu casa."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un punto discutible, pero con bastante consenso."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes hacer un bloque en una posición bastante importante."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiciste un bloque en un posición muy importante de tu casa."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimientos de Escape"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay otro movimientos que no forman bloques, pero son posibles partidas y tiene su mejor jugada."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitulo Completado."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto más aperturas!"])}
      },
      "bp": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz un Bloque y atrapa al adversario"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un Bloque de 6 posiciones contiguas se denomina, en inglés, Prime. Construye uno para retener a tu contrincante"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza el dado y crea un Prime para retener a tu contrincante dentro de la casa."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reténlo atrás"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un objetivo táctico muy importante en el Backgammon, es retener a tu adversario, atrapándolo dentro de tu casa."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz un fuerte"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiene un adversario atrás en tu casa, pero tienes algunos puntos débiles. Si tu oponente lanza [d24] o [d22], te capturará y puede escapar."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige el movimiento que deje 6 bloques consecutivos, y no lo dejes escapar."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu oponente puede escapar."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejaste al menos un punto que puede ser usado para escapar. Intenta de nuevo, y haz un bloque completo."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfecto."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construiste un bloque completo. Un Prime. Esta estructura deja a tu adversario sin ninguna combinación para escapar. Bien!"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa llena."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También puedes construir un Prime dentro de tu casa, y si hay soldados capturados no podrán salir."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura a tu oponente y cierra tu casa."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cerraste completamente tu casa."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenías que construir un bloque de 6 puntos consecutivos cerrados. Inténtalo de nuevo."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfecto."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construiste un bloque completo dentro de tu casa. El adversario no puede salir de la capilla."])}
      }
    },
    "common": {
      "whitehome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa Blanca"])},
      "blackhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa Negra"])},
      "outerboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio Campo"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capilla"])},
      "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afuera"])},
      "movesleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes hacer todos los movimientos válidos."])},
      "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanza los dados"])},
      "gotit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo hiciste!"])},
      "bettermove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay un movimiento mejor"])},
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz tus movimientos."])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirma para continuar."])},
      "chcompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capítulo Completado."])}
    }
  },
  "meta": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de Abak Evolution Backgammon"])},
      "history": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak nació en 1999, luego de una serie de eventos del azar"])}
      },
      "rant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como Abak Evolution Backgammon no fue lo que esperaba"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak no ha tenido el éxito que esperaba, pero nunca me voy a rendir. Tengo mis razones, leélas acá."])}
      },
      "samy-garib": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib - Desarrollador de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib es un desarrollador de software chileno. Diseñador, desarrollador e inventore de Abak Evolution."])}
      },
      "donate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dona para mantener Abak Evolution Backgammon vivo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon es un proyecto de una sola persona, gratis, y necesita ayuda."])}
      },
      "thanks-to": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon está agradecido de mucha gente"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muchas personas han sido indirectamente parte de este proyecto. Desde ayuda en las traducciones, a una demostración increible de entusiasmo. Todo suma."])}
      }
    },
    "boardgame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon - Juego de Mesa"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta a Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak está en muchas redes sociales. Dales una mirada acá, y encuentra formas de contactarte."])}
    },
    "learn": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende Abak Evolution Backgammon"])},
      "description": null
    },
    "print": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprime las reglas de Abak Evolution Backgammon"])}
    },
    "resources": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos para aprender Abak Evolution Backgammon"])}
    },
    "rules": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas - Abak Evolution Backgammon"])},
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El soldado - Una clase de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actua como un soldado regular de Backgammon."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El General - Una clase de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El único soldado que puede ir hacia atrás. Comanda la batalla."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los Guardias - Una clase de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada equipo tiene dos guardas, que son más fuertes y no pueden ser atrapados tan fácilmente"])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Druida - Una clase de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El druida atrapa a su oponente. Lo inmobiliza y retiene mientras se posa sobre el, en ves de capturarlo."])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido par - Una clase de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido par solo mueve dados pares."])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido impar - Una clase de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El herido impar solo puede mover dados impares."])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak tiene 6 clases of soldados: Soldado, General, Druida, Guardias, y los heridos, que son dos clases."])}
      }
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas Frecuentes - Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este FAQ se enfoca en cuan diferente es Abak Evolution Backgammon como variante.\n"])}
    },
    "abak": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon. Una Variante."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution es un juego de mesa de estrategia abstracta. Una variante del Backgammon que añade clases de jugadores al juego clásico."])}
    },
    "tutorial": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial de Backgammon y Abak Evolution"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es un tutorial interactivo de Backgammon y Abak Evolution. Aprenderás desde como es la tabla, como se mueven las fichas, que es la capilla y como se salvan tus soldados. Además hay juegos avanzados."])},
      "backgammon": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial de Backgammon"])}
      },
      "abak": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial de Abak Evolution"])}
      }
    }
  }
}