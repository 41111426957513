<template>
    <div 
        class="chatWidgetContainer" 
        :class="{ newMessages : hasNewMessages }"
        @click="$emit('click-chat-widget');">
        <img  v-if="hasNewMessages" :src="require('@/assets/img/buttons/chat-si.svg')"/>
        <img  v-else :src="require('@/assets/img/buttons/chat-no.svg')"/>
    </div>
</template>

<script>
	export default {
		name: 'ChatWidget',
        props: {
            newmessages: {
                type:Object,
                required:true
            },
            imageSize: {
                type:String,
                required:false,
                default:"2.5em"
            },
        },
        data: function() {
            return {   
            }
        },
        computed: {
            hasNewMessages: function() {
                return (Object.keys(this.newmessages).length > 0)
            }
        },
        emits: ['click-chat-widget'],
        watch: {
        },  
        methods: {	      
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.chatWidgetContainer {
    background-color: darken($c17,0%);
    border-radius: 1em;
    padding:0.3em;
    display:flex;
    box-shadow: $box-shadow-big;
    cursor: pointer;
    img {
        width:v-bind('imageSize');
    }
}

@media (hover: hover) {
    @media (hover: hover) {
        .chatWidgetContainer:hover {
            background: $c7;
        }
    }
}

@keyframes newmessages {
  from {background-color: $c15;}
  to {background-color: $c11;}
}
.newMessages {    
    animation-name: newmessages;
    animation-duration: 1s;
    animation-iteration-count: infinite;


}

</style>
