<template>
    <div class="introcontainer">
      <img style='height:10em;' src="/img/logos/abaklogosimplificado.svg"/>
      <h1>404 - Page Not Found</h1>
      <div> 
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
      <div class="whitemessage"> 
        <router-link to="/">HOME</router-link>
      </div>
    </div>
  </template>
  
<script>
    export default {
    name: "NotFound",
    metaInfo: {
        title: "404 - Page Not Found",
        metaTags: [
        // Add any necessary meta tags for the 404 page
        ],
    },
    };
</script>
<style> 

.introcontainer {
			display:flex;
            position:absolute;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 99%;
            height: 99%;
		}

		.introcontainer>div {
		}

		.teamContainer {
			width:80%;
		}

		.elements {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding:1em;
		}
        .progress {
            height: 2em;
			position: relative;
            border-radius: 0.5em;
            margin-top:1em;   
			width:80%;
        }
        .progress .color{
            position: absolute;
            background-color: #ffffff;
            width: 0px;
            height: 10px;
            border-radius: 15px;
            animation: progres 10s infinite linear;    
        }
  
		.texto {
			font-size:1.5em;
			color:white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;			
		}

		.texto b {
			color:#eae428;
		}

		.logo {
			width:80%;
		}


		.teamGeneralInterior {
			animation: team2 5s infinite ease-in-out;
		}

		.teamDruidaInterior {
			animation: team3 5s infinite ease-in-out;
		}

		.teamGuardInterior {
			animation: team4 5s infinite ease-in-out;
		}

		.teamParInterior {
			animation: team5 5s infinite ease-in-out;
		}

		.teamImparInterior {
			animation: team6 5s infinite ease-in-out;
		}


		@keyframes team2 {
			0% {
				stroke:#00000000;
			}
			10% {
				stroke:#00000000;
			}
			20% {
				stroke:#eae428;
			}
			90% {
				stroke:#ffffff;
			}
		}

		@keyframes team3 {
			0% {
				stroke:#00000000;
			}
			20% {
				stroke:#00000000;
			}
			30% {
				stroke:#eae428;
			}
			90% {
				stroke:#fff;
			}
		}

		@keyframes team4 {
			0% {
				stroke:#00000000;
			}
			30% {
				stroke:#00000000;
			}
			40% {
				stroke:#eae428;
			}
			90% {
				stroke:#fff;
			}
		}

		@keyframes team5 {
			0% {
				stroke:#00000000;
			}
			40% {
				stroke:#00000000;
			}
			50% {
				stroke:#eae428;
			}
			90% {
				stroke:#fff;
			}
		}

		@keyframes team6 {
			0% {
				stroke:#00000000;
			}
			50% {
				stroke:#00000000;
			}
			60% {
				stroke:#eae428;
			}
			90% {
				stroke:#fff;
			}
		}


        @keyframes fader{
          0%{
              transform: scale(1);              
            }
          50%{
            transform: scale(0.95);
          }
          100%{
            transform: scale(1); 
          }
  
        }
  
        @keyframes progres{
            0%{
              width: 0%;
            }
            25%{
                width: 25%;
            }
            50%{
                width: 50%;
            }
            75%{
                width: 75%;
            }
            100%{
                width: 100%;
            }
        }

    .whitemessage {
        background: white;
        padding: 1em;
        border-radius: 1em;
    }

		@media (min-width:961px)  { 
			.texto {
				font-size:2.5em;
			}
			.introcontainer {
			}
        }

        @media (min-width:1100px)  { 
			.texto {
				font-size:3em;
			}
			.introcontainer {
			}
        }

        @media (orientation: portrait) {
			.introcontainer {
				flex-direction: column;
			}
		}


</style>
  