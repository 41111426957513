<template>
    <div style="display: flex;align-content: center;align-items: center;">
        <div 
            v-show="!timeTravel.on && playerStage=='moveCheckers' && !rolling[0]" 
            class="movesLeft" 
            :class="{ movesLeftDone : maxMovements == currentMovements }">
            <div>{{maxMovements-currentMovements}}</div>
        </div>
        <div v-show="iaPlayStage=='calculateMoves'" class="calculatingMoves">
            <img style="width:1em;" :src="require(`@/assets/img/symbols/brain.svg`)"  /> 
        </div>
        <div class="contenedorDados" :class="{ 
                two: (localDiceRoll[0] != localDiceRoll[1] && localDiceRoll[1]!=0) || rolling[0] || rolling[1] && playerStage!='defineWhoStarts' ,
                four: localDiceRoll[0] == localDiceRoll[1] && localDiceRoll[1]!=0 && !rolling[0] && !rolling[1] && playerStage!='defineWhoStarts'
                }"
                >
            <Dice 
                id = "rollDice1"
                v-show='!(diceClass[0]==2 && localDiceRoll[0]==0) && !(localPlayerStage=="defineWhoStarts" && localDiceRoll[0]==0)'
                :style ="{ width: imageSize+'px' }"
                :dClass="diceClass[0]" 
                :dNumber="getDiceRoll(0)"
                :colorClases="theme"
             />
            <Dice 
                id = "rollDice2"
                v-show='!(diceClass[0]==2 && localDiceRoll[1]==0) && !(localPlayerStage=="defineWhoStarts" && localDiceRoll[1]==0)'
                :style ="{ width: imageSize+'px' }"
                :dClass="diceClass[1]" 
                :dNumber="getDiceRoll(1)" 
                :colorClases="theme"
                />
            <Dice             
                v-show='localDiceRoll[0]==localDiceRoll[1] && localDiceRoll[0]>0 && localDiceRoll[0]<7 && !rolling[0] && !rolling[1] && localPlayerStage!="defineWhoStarts"'            
                :style ="{ width: imageSize+'px' }"
                :dClass="diceClass[2]" 
                :dNumber="getDiceRoll(1)" 
                :colorClases="theme"/>
            <Dice 
                v-show='localDiceRoll[0]==localDiceRoll[1] && localDiceRoll[0]>0 && localDiceRoll[0]<7  && !rolling[0] && !rolling[1] && localPlayerStage!="defineWhoStarts"' 
                :style ="{ width: imageSize+'px' }"
                :dClass="diceClass[3]" 
                :dNumber="getDiceRoll(1)" 
                :colorClases="theme"
                />

        </div>
    </div>
</template>

<script>

    import Dice from "../elements/Dice.vue";

    export default {
		name: 'DadosTiro',
        props: [
            'diceRoll',
            'currentPlayer',
            'playerID',
            'playerStage',
            'opponentStage',
            'checkersColor',
            'imageSize',
            'diceAvailable',
            'currentMovements',
            'maxMovements',
            'rollNumber',
            'timeTravel',
            'iaPlayStage',
            'theme',
            'tipoAnimacion'],
        components: {Dice},
        data: function() {
            return {
                rolling: [false,false],                
                lastPlayerStage: { 
                    id:null,
                    timestamp:null
                    },
                lastOpponentStage: {
                    id:null,
                    timestamp:null
                },
                localDiceRoll: [0,0],
                localPlayerStage: null,
                animatingValue: 0,
            }
        },
        computed: { 
            diceClass: function() {  
                //console.log("calculate dice class");
                let diceOwner = this.localPlayerStage=="defineWhoStarts" ? parseInt(this.playerID) : parseInt(this.currentPlayer);                
                let otherPlayer=(parseInt(diceOwner)+1)%2;
                if (this.checkersColor != parseInt(this.playerID) ) [diceOwner,otherPlayer] = [otherPlayer,diceOwner];
                let debe = [
                    1,
                    1,
                    this.localDiceRoll[0]==this.localDiceRoll[1] && this.localPlayerStage!="defineWhoStarts" ? 1 : 0 ,
                    this.localDiceRoll[0]==this.localDiceRoll[1] && this.localPlayerStage!="defineWhoStarts" ? 1 : 0 
                    ];
                let clase= [diceOwner,diceOwner,diceOwner,diceOwner]
                if (this.localPlayerStage=="defineWhoStarts") {
                    clase[otherPlayer]=otherPlayer;
                }
                else {
                    for (let i=0;i<4;i++) {
                        if (this.diceAvailable[i]==0 && debe[i]==1) {
                            clase[i]=2;
                        }
                    }
                }
                return clase;
            }            
        },
        methods: {	
            getDiceRoll: function(index) {
                if (this.rolling[index]==false)
                    return this.localDiceRoll[index];
                else 
                    return this.animatingValue;
            },            
            diceRollExec: function(targets) {                
                let at = [];
                for (let t in targets) {
                    at.push(`#rollDice${parseInt(targets[t])+1}`);
                }

                let keyframes = [];
                let duration = 500;
                
                switch (this.tipoAnimacion) {
                    case "rotate":
                        keyframes = [
                            { rotate: '+=360',scale:1.3 },
                            { rotate: '+=360',scale:1 },
                            { rotate: 0 },
                        ];
                        duration = 800;
                        this.animatingValue = 7;
                        break;
                    case "flash":
                        keyframes =  [
                                { scale:0.1 },
                                { scale:1 },
                            ];
                        duration = 300;
                        this.animatingValue = 0;
                        break;
                }

                this.rollingStart=false;
                let jsonAnime = {
                        targets: at,
                        duration: duration,
                        easing: 'easeInOutSine',
                        keyframes: keyframes,
                        complete: () => { 
                            for (let i in targets) this.rolling[targets[i]]=false;                            
                        }
                    };
                this.$anime(jsonAnime);
            }
        },
        watch: {
            /* Dice Roll Watch exectues Animations */
            diceRoll: function(newValue,oldValue) {

                if (newValue[0]==oldValue[0] && newValue[1] == oldValue[1]) {
                    this.lastPlayerStage.id = this.playerStage;
                    this.lastOpponentStage.id = this.opponentStage;
                    this.lastPlayerStage.timestamp = Date.now();
                    this.lastOpponentStage.timestamp = Date.now();  
                    this.localPlayerStage = this.playerStage;
                    return;              
                }
                //console.log("Dice Roll Change",newValue,oldValue,this.playerStage,this.lastPlayerStage.id);



                let delay=0;
                let animate=true;
                let targets = [];                    
                for (let i in oldValue) {
                    if (oldValue[i]!=newValue[i] || 
                        (this.localPlayerStage !="defineWhoStarts" && this.lastPlayerStage.id !="defineWhoStarts")
                        ) {
                        targets.push(i);
                    }
                }

                /* Start Ambos en 0 0 porque eran iguales */
                if (
                    this.playerStage =="defineWhoStarts" && 
                    newValue[0]==0 && newValue[1]==0 && (oldValue[0]>0 || oldValue[1]>0)
                    ) {
                        let oldRepeatedDice = Math.max(oldValue[0],oldValue[1]);
                        let newDiceId = (oldValue[0]>0) ? 1: 0;
                        this.localDiceRoll = [oldRepeatedDice,oldRepeatedDice];
                        targets = [ newDiceId ];
                        //console.log("rolled, eran iguales!");
                        delay=2;
                        animate=true;
                    }
                /* Start Uno diferente de Cero */

                /* Recien Comenzado, Ambos diferentes de cero, recien comenzado */
                if (this.playerStage !="defineWhoStarts" && this.lastPlayerStage.id =="defineWhoStarts") {
                    //console.log("rolled, reciencito comenzado");
                    animate=true;
                    this.localDiceRoll = newValue;
                    delay= 1;
                }
                /* Normal, desde el segundo tiro. */
                if (
                    this.lastPlayerStage.id !="defineWhoStarts" && 
                    (this.playerStage=="moveCheckers" ||
                     this.opponentStage=="moveCheckers") 
                    ) {
                    //console.log("rolled, tiro normal");
                    animate=true;
                }


                if (this.timeTravel.on) animate=false;

                //console.log(animate,delay,targets);
                if (animate) {
                        for (let i in targets) this.rolling[targets[i]]=true;
                        this.rollingStart=true;                        
                        this.diceRollExec(targets);
                }
                setTimeout( ()=> {
                    this.localDiceRoll = newValue;
                    this.lastPlayerStage.id = this.playerStage;
                    this.lastOpponentStage.id = this.opponentStage;
                    this.lastPlayerStage.timestamp = Date.now();
                    this.lastOpponentStage.timestamp = Date.now();
                    this.localPlayerStage = this.playerStage;
                },delay*1000);
            }
        },
        mounted: function() {
            this.localDiceRoll = this.diceRoll;
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .dadotiro {max-height: 100%;

    }

    .contenedorDados {
        display:flex;
        flex-wrap:wrap;
    }

    .none img {
        margin:0.2em;
    }


    .two img {
        margin:0.2em;
    }

    .four {
        flex:1;
        width: 7.0em;
        height: 5.0em;
        transform: scale(0.8);
    }

    .four img {
        margin:0.2em;        
    }
    .movesLeft {
        background-color:$c13;
        width:1.5em;
        height:1.5em;
        justify-content: center;
        align-items: center;
        border-radius: 0.7em;
        padding:0.1em;
        display:flex;
    }

    .calculatingMoves {
        background-color:$c0;
        width:1.5em;
        height:1.5em;
        justify-content: center;
        align-items: center;
        border-radius: 1em;
        padding:0.1em;
        display:flex;
        animation: thinkingAnimation 1s infinite;
        border: 0.1em solid white;

    }

    @keyframes thinkingAnimation {
    0% {
        background-color: $c0;
    }
    50% {
        background-color: lighten($c0, 20%);
    }
    100% {
        background-color: $c0;
    }
}


    .movesLeftDone {
        background-color: $coloryes;
    }


</style>
