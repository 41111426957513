<template>
        <svg viewBox="0 0 40 40" v-if="!onbar">
            <g transform="translate(20,20)"> 
                <path d="m-20 0c0-11.046 8.9542-20 20-20s20 8.9542 20 20-8.9542 20-20 20-20-8.9542-20-20z" :fill="colors.background" />
                <path d="m-16 0c0-8.8367 7.1633-16 16-16s16 7.1633 16 16-7.1633 16-16 16-16-7.1633-16-16z" :stroke="colors.line" fill="none" />       
            </g>

            <!-- General -->
            <g v-if="cclass==2" id="sdsds" transform="translate(-29.101,-27.903)">
                <path id="path16" :fill="colors.object1" d="m36.601 47.903 10 6c-1.1781-0.725-1.9039-2.2548-2.205-4h9.41c-0.3015 1.7452-1.0269 3.275-2.205 4l10-6-10-6c1.1286 0.691 1.8657 2.2308 2.185 4h-9.37c0.3195-1.7692 1.0564-3.309 2.185-4l-10 6z"/>
            </g>


            <g v-if="cclass==3" id="sdfsdf" transform="translate(-29,-30)">
                <path id="path15578" :fill="colors.object1" d="m50.026 49.626c0-3.3137 2.6863-6 6-6s6 2.6863 6 6-2.6863 6-6 6-6-2.6863-6-6z"/>
                <path id="path15580" :fill="colors.object1" d="m36.026 49.626c0-3.3137 2.6863-6 6-6s6 2.6863 6 6-2.6863 6-6 6-6-2.6863-6-6z"/>
            </g>

            <g v-if="cclass==4" id="sfdsds" transform="translate(-30,-30)">
                <path id="path7383" :fill="colors.object1" d="m58.501 52.376c0-3.0867-2.3324-5.6312-5.33-5.965 0.2128 0.615 0.33 1.2776 0.33 1.965 0 3.3137-2.6863 6-6 6-0.2271 0-0.4495-0.0105-0.67-0.035 0.8131 2.3498 3.0437 4.035 5.67 4.035 3.3137 0 6-2.6863 6-6z"/>
                <path id="path7385" fill="none" d="m40.501 47.376c0-3.3137 2.6863-6 6-6s6 2.6863 6 6-2.6863 6-6 6-6-2.6863-6-6z" :stroke="colors.object1"/>
            </g>

            <g v-if="cclass==5" id="use8284" transform="translate(-29,-30)">
                <path d="m 53.726,41.075 4,18 -8,0 4,-18 z" id="path23666" :fill="colors.object1" />
                <path d="m 43.726,41.075 4,18 -8,0 4,-18 z" :fill="colors.object1" />
            </g>

            <g v-if="cclass==6" transform="translate(-29,-29)">
                <path d="m 53.4,39.952 4,16 -8,0 4,-16 z" id="path31729" :stroke="colors.object1" fill="none" stroke-width="1.5" />
                <path d="m 45.4,57.952 4,-18 -8,0 4,18 z" id="path31731" :fill="colors.object1" />
            </g>

            <g v-if ="selected" transform="translate(20,20)"> 
                <path d="m-20 0c0-11.046 8.9542-20 20-20s20 8.9542 20 20-8.9542 20-20 20-20-8.9542-20-20z" :fill="'#ff705066'" />
            </g>
        </svg>
        <svg v-else 
            viewBox="0 0 40 10"
            > 
            <rect 
                :fill="colors.background"
                :stroke="colors.line"                
                width="35"
                height="10"
                x="5"
                y="0"
                rx="3"
                />
        </svg>
</template>

<script>

	export default {
		name: 'CheckerSVG',
        props: ['colors','selected','cclass','onbar']
    }

</script>