/*************** Full Screen Functions **********************/
/* View in fullscreen */
function openFullscreen() {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
    }
}

/* Close fullscreen */
function closeFullscreen() {
    /*var elem = document.documentElement;*/
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
    }
}

/* Togle fullscreen */
export function togleFullScreen() {
    if (!document.fullscreenElement) {
        openFullscreen();
        setTimeout(()=>{this.calcSize()},1000);
    }
    else {
        closeFullscreen();
        setTimeout(()=>{this.calcSize()},1000);
    }
}