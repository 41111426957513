export default {
    background: '#142330', // Frame Background
    path: '',
    // Board Elements
    board: {
      background: '#d6c6a7', 
      border: '#593311',
      bar: '#593311',
      points: [
        {
          background: "#5c442c", // Odd placed Point
          border: "#5c442c" 
        },
        {
          background: "#916d49", // Even placed Point
          border: "#916d49"
        }
      ]
    },
    // Dice Set
    dice: [
      // Dice for player 1
      {
        background: "#ede7e1", 
        border: "#333", 
        dot: "#000", 
        dotborder: "#000" 
      },
      // Dice for player 2
      {
        background: "#222", 
        border: "#aaa", 
        dot: "#fff", 
        dotborder: "#000" 
      },
      // Disabled Dice
      {
        background: "grey",
        border: "black",
        dot: "white",
        dotborder: "white",
      },
      // Dice when Invalid move
      {
        background: "#900",
        dot: "#000",
        dotborder: "#000",
        border: "grey"
      }
    ],
    cube: {
      background: "#ede7e1", 
      color: "#2f4858", 
    },
    //Players Checkers Definition
    checkers: [
      //Player 1
      {
        background: "#ede7e1", 
        line: "#777", 
        object1: "#333", 
        object2: "#111", 
        color: "#000", 
      },
      //Player 2
      {
        background: "#111", 
        line: "#666", 
        object1: "#CCC", 
        object2: "#999", 
        color: "#fff" 
      }
    ],
    //Action Buttons
    buttons: {
      default: {
        background: '#4f3924', 
        border:"#2f4858", 
        hover:"#fcbf49", 
        off:"#200"
      },
      light: {
        background: '#4f3924',
        border:"#2f4858", 
        hover:"#fcbf49", 
        off:"#000"
      },
      soft: {
        background: '#0081a7', 
        border:"#2f4858", 
        hover:"#fcbf49", 
        off:"#000"
      },
      warning: {
        background:'#fcbf49', 
        border:"#2f4858", 
        hover:"#0081a7", 
        off:"#000"
      }
    },
    // UI Elements
    elements: {
      box1: {
        background:"#361f0a", 
        color:"#000"
      },
      box2: {
        background:"#2ff4858",
        color:"#fff" 
        },
        scroll: {
          bar:"#2f4858", 
          hover:"#0081a7"
        },
        chrono: {
          total: {
            background:"#ffdd94", 
            color:"#000",   
          },
          current: {
            background:"#2f4858", 
            color:"#fff",  
          }
        }
      }
    }