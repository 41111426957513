/* TODO: Calcular los puntos que hubiese perdido... */
var abakLib = require('../AbakEvolutionLibrary.js');

const playNextMove = (G,ctx) => { 
    let nextMove = G.nextMoves.shift();
    if (nextMove) {
        G.checkers_map = nextMove.checkers_map;
        G.turnslog.currentMoves.push(nextMove.moves);
    }
    else {
      ctx.events.endTurn();
    }
    let isFinished = abakLib.finishedGame(G.checkers_map);   
    //console.log(isFinished);               
    if (isFinished) {
      G.points.current[isFinished.winner]+=isFinished.points*G.doubleCube.value;
      G.points.games_record.push({
        'turns': G.turnslog.log.length,
        'winner':ctx.currentPlayer,
        'points':isFinished.points,
        'doubleCube':G.doubleCube,
        'reason':'finished'
      });
      ctx.events.setPhase('endedGame');
      //ctx.events.endPhase();     
    }
    else {
      if (G.nextMoves.length==0) {
        G.iaPlayStage = 'notPlaying';
        ctx.events.endTurn();
      }                    
    }          
}

module.exports = { playNextMove }