<template>
    <div style="width:100%">

        <!---------------------------------------------------------------------------------->
        <!----------------------------------- Filters -------------------------------------->
        <!---------------------------------------------------------------------------------->


        <div class="filtersMenu">  
            <div style="width:80%">
                    <DoubleRange v-if="limits[1]"
                        :min="limits[0]" 
                        :max="limits[1]" 
                        :minThreshold="0"
                        :maxThreshold="graphData.r.length"
                        @update:min="value => updateLimits(0,value)" 
                        @update:max="value => updateLimits(1,value)"
                    />
            </div>
            <div>

                <!--GameButton                                 
                    :type="'none'"
                    :button_id = "'switchDotValue'"
                    :text="''"
                    @click= "dotValue = dotValue == 'winner' ? 'equity': 'winner' "
                    :image="'graphswitchvalue'"
                /-->

                <GameButton 
                    v-for="(dp,i) in displayPlayer" :key=i
                    :type="'none'"
                    :button_id ="'TurnWatchButton'+i"
                    :image="'checker_col'+playerColor(i)"
                    :prefValue="dp"
                    @click="clickChangePlayerVisibility(i)"
                />
                
                <GameButton 
                    :type="'none'"
                    :button_id ="'TurnEqOpponent'"
                    :image="'visibility'"
                    :prefValue="showOpponentEquityRange"
                    @click="$emit('select-current',limits[0]);showOpponentEquityRange=!showOpponentEquityRange;displayPlayer[1]=true;"
                />   
                <GameButton                                 
                    :type="'none'"
                    :key="'back11'"
                    id = "back11"
                    :button_id = "'StateBack1'"
                    :text="''"
                    :image="'backward'"
                    @click="clickMovePoint(-1)"

                />
                <GameButton                                 
                        :type="'none'"
                        :key="'back12'"
                        :button_id = "'StateForwad1'"
                        :text="''"
                        :image="'forward'"
                        @click="clickMovePoint(1)"
                />                     
            </div>
        </div>

        <!---------------------------------------------------------------------------------->
        <!------------------------------------- Graph -------------------------------------->
        <!---------------------------------------------------------------------------------->
        <div style="text-align: center;" v-if="graphData.r && graphData.r.length>1"> 
            <svg 
                class="graphSVG" 
                :class="{
                    graphSVGMinimal : stateMinimal ,
                    graphTablet: screenMode == 'tablet',
                    graphVertical: screenMode == 'vertical'
                    }" 
                xmlns="http://www.w3.org/2000/svg" fill="white"
                >                   
                <rect x="0" y1=0 width="100%" height="100%" class="graphBackground" ></rect>
                
                
                <!-- Selected Turn -->
                    <line v-if="localCurrent && graphData.r[localCurrent]"
                        :x1="graphData.left+graphData.r[localCurrent][0]*graphData.ptXd+'%'" 
                        :y1="'0%'"  
                        :x2="graphData.left+graphData.r[localCurrent][0]*graphData.ptXd+'%'" 
                        :y2="'100%'" 
                        style="stroke:#00770040;stroke-width:5" />


                <!-- Horizontal Axis -->
                <line v-for="i in [25,50,75]" :key="i"
                    :x1="'0%'" 
                    :y1="i+'%'"  
                    :x2="'100%'" 
                    :y2="i+'%'" 
                    style="stroke:rgb(80,0,0);stroke-width:1" />                    
                
                <!-- Horizontal Axis On 0 -->
                <line 
                    :x1="'0%'" 
                    :y1="graphData.top+((graphData.maxEq)*graphData.ptYd)+'%'"  
                    :x2="'100%'" 
                    :y2="graphData.top+((graphData.maxEq)*graphData.ptYd)+'%'"  
                    style="stroke:rgb(0,80,0);stroke-width:1" />                           
                
                <!-- Vertical Axis -->
                <line v-for="i in [25,50,75]" :key="i"
                    :x1="i+'%'" 
                    :y1="'0%'"  
                    :x2="i+'%'" 
                    :y2="'100%'" 
                    style="stroke:rgb(100,30,0);stroke-width:1" />   

                <!-- Line -->
                <template v-for="(d,index) in graphData.r"  >
                    <line v-if="index>0" :key="d"
                        :x1="graphData.left+graphData.r[index-1][0]*graphData.ptXd+'%'" 
                        :y1="graphData.top+(graphData.maxEq-graphData.r[index-1][1])*graphData.ptYd+'%'"  
                        :x2="graphData.left+d[0]*graphData.ptXd+'%'" 
                        :y2="graphData.top+((graphData.maxEq-d[1])*graphData.ptYd)+'%'" 
                        style="stroke:rgb(200,220,60);stroke-width:2" />
                </template>

                <!-- Equity Range Lines -->
                <template v-for="(d,index) in graphData.r" >
                    <line v-if="index>0" :key="d"
                        :x1="graphData.left+d[0]*graphData.ptXd+'%'" 
                        :y1="graphData.top+(graphData.maxEq-d[2])*graphData.ptYd+'%'"  
                        :x2="graphData.left+d[0]*graphData.ptXd+'%'" 
                        :y2="graphData.top+((graphData.maxEq-d[3])*graphData.ptYd)+'%'" 
                        style="stroke-width:1%;"
                        :class="{
                            bigError: d[2]-d[1] > 0.1,
                            errorMedium: d[2]-d[1] > 0.05 && d[2]-d[1] <= 0.1,
                            errorModerate: d[2]-d[1] > 0.00 && d[2]-d[1] <= 0.05,
                            errorPerfect: d[2]== d[1]
                        }"/>
                </template>                    

                <!-- Double Lines -->
                <template v-for="(d,index) in graphData.doubles" >
                    <line v-if="index>0"  :key="d"
                        :x1="graphData.left+d[0]*graphData.ptXd+'%'" 
                        :y1="'0%'"  
                        :x2="graphData.left+d[0]*graphData.ptXd+'%'" 
                        :y2="'100%'" 
                        style="stroke:#45FFFF50;stroke-width:1" />
                </template>                    



                <!-- Dots -->

                <circle v-for="(d,i) in graphData.r" :key="d" 
                    :cx="graphData.left+d[0]*graphData.ptXd+'%'" 
                    :cy="graphData.top+((graphData.maxEq-d[1])*graphData.ptYd)+'%'" :r="dotSize()" 
                    :style="'fill:'+dotColor(d[7])+';stroke:'+dotStroke(d[5])+';stroke-width:1px'"
                    @click="clickCurrentPoint(i)"
                    />

                <!-- Texts -->
                <template v-for="(d,i) in graphData.r" >                                            
                    <text v-if="i%10==0 && i<graphData.r.length-10"
                        :x="graphData.left+d[0]*graphData.ptXd+'%'" 
                        :y="'98%'"                            
                        @click="clickCurrentPoint(i)"
                        style="font-size:0.5em"
                        :key="d"
                    >{{d[4]}}</text>
                </template>
                <text v-for="i in [25,50,75]" :key="i" x="5%" :y="(i+1)+'%'" style="font-size:0.5em"> 
                    {{(graphData.maxEq-graphData.diff*(i/100)  ).toFixed(1)}} </text>
            </svg>
        </div>

    </div>
</template>

<script>

    import DoubleRange from '../elements/DoubleRange.vue';
    import GameButton from '../buttons/GameButton.vue';
    //import ShowEquityError from '../elements/ShowEquityError.vue';

	export default {
		name: 'EquityChart',
        props: ['analysisData','stateMinimal','screenMode','checkersColor','playerID','currentPoint'],
        emits: ['select-current'],
        components: { DoubleRange,GameButton },
        methods: {
            clickCurrentPoint: function(point) {
                //console.log("clickpoint",point);
                const indexPoint = this.graphData.r[point][4];
                this.$emit("select-current",indexPoint);
                //this.currentPoint=point;
            },
            clickMovePoint: function(direction,point=null) {   
                let current = 0;
                if (point===null)             
                    current = parseInt(this.currentPoint) || 0;
                else current = point;
                let found = false;
                while (!found) {
                    current = current + 1 * direction;
                    if (!this.analysisData[current]) return;
                    if (this.analysisData[current].type == "move") {
                        let tPid = parseInt(this.analysisData[current].playerID);
                        if (this.displayPlayer[tPid]) {
                            this.$emit("select-current",current);
                            found = true;
                        }
                    }
                }
            },
            clickChangePlayerVisibility: function(player_id ) {
                let otherPlayer = (player_id + 1) % 2;
                if (this.displayPlayer[player_id] && !this.displayPlayer[otherPlayer] ) this.displayPlayer[otherPlayer]=true;
                this.displayPlayer[player_id]=!this.displayPlayer[player_id];
                if (player_id==1) this.showOpponentEquityRange=false;
                this.$emit('select-current',this.limits[0]);
            },
            playerColor: function(playerID) {
                return this.checkersColor == 0 ? parseInt(playerID) : (parseInt(playerID)+1)%2; 
            },
            dotColor: function(playerID) {
                let colors = ["#d8ba89ff","#663300ff"];
                return colors[this.playerColor(playerID)];
            },
            dotStroke: function(maxLength) {
                if (maxLength==0) return "#FF0000";
                if (maxLength==1) return "#FFFF00";
            },
            dotSize: function() {
                return this.stateMinimal ? '1%' : '1%';
            },     
            updateLimits(index,value) {                
                let temp = [this.limits[0],this.limits[1]];
                temp[index] = value;
                if (temp[1]-temp[0]<5) return;
                this.limits[index] = value;
                if (this.localCurrent > this.limits[0] || this.localCurrent < this.limits[1] ) {
                    return;
                }
                let tmpCurrentPoint = this.limits[index];
                let direction = index == 0 ? 1 : -1;
                this.clickMovePoint(direction,tmpCurrentPoint);
            },                   
        },
        data: function() {
            return {
                displayPlayer:[true,true],
                showOpponentEquityRange:false,
                limits:[0,0],
                dotValue: 'equity',
                localCurrent:null,
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                this.limits=[0,this.analysisData.length];
                this.localCurrent = this.currentPoint;
            });
        },
        watch: {
            currentPoint: function(newValue) {
                if (newValue == null) {
                    this.localCurrent = null;
                    return;
                }
                for (let i in this.graphData.r ) {
                    if (newValue == this.graphData.r[i][4]) {
                        this.localCurrent = i;
                        break;
                    }
                }                
            }
        },
        computed: {
            graphData: function() {
                //const EQUITY_CORRECTION = 1.2;
                //console.log("calculate graph data");
                let r = [[0,0,0,0,0,0,0,0,0]];
                let maxEq = -3;
                let minEq = 3;
                let doubles = [[0,1,null]];                
                if (!this.analysisData || this.analysisData.length==0 || this.limits[0]==this.limits[1] ) {                    
                    //console.log("retuning early");
                    return { 
                        maxEq: maxEq, 
                        minEq:minEq, 
                        r:r ,
                        ptXd:99/r.length ,  
                        ptYd: 90/(maxEq-minEq),
                        top: 5/(maxEq-minEq),
                        diff: (maxEq-minEq),
                        left: 100/r.length,
                        doubles:doubles
                    };
                }
                else {
                    let index = 0;
                    for (let d in this.analysisData) {
                        if (
                            ( this.limits[0]==null || d>=this.limits[0] ) && 
                            ( this.limits[1]==null || d<=this.limits[1] )
                            ) {
                            let data = this.analysisData[d];
                            console.log(index);
                            console.log(data.type,data.type==null);
                            if (data.type == "move" &&
                                 ((data.playerID==0 && this.displayPlayer[0]) || 
                                 (data.playerID==1 && this.displayPlayer[1])) 
                                 )  {
                                let mult = 1;
                                let player_turn = parseInt(data.playerID)==parseInt(this.playerID) ? true : false;
                                let p = data.mymove;
                                //let s = data.limits;
                                console.log("player_turn",player_turn);
                                
                                let smint = 0 , smaxt = 0;

                                let dotMaxVal = this.dotValue == 'equity' ? data.maxE : data.maxW - 0.5;
                                let dotMinVal = this.dotValue == 'equity' ? data.minE : data.minW - 0.5;

                                let dotVal = this.dotValue == 'equity' ? p.eq : p.w - 0.5;

                                if (!this.showOpponentEquityRange && !player_turn) {
                                    smint = dotVal*mult;
                                    smaxt = dotVal*mult;
                                }
                                else {
                                    smint = dotMinVal*mult;
                                    smaxt = dotMaxVal*mult;
                                }
                                
                                minEq = Math.min(minEq,dotVal*mult,smint);
                                maxEq = Math.max(maxEq,dotVal*mult,smaxt);
                                r.push([
                                    index+1,
                                    dotVal*mult,
                                    smaxt,
                                    smint,
                                    d,
                                    data.validmoves,
                                    data.diceRoll,
                                    data.playerID,
                                    data.best]);
                                index++;
                            }
                            if (data.type=="double_accepted") {
                                //console.log("double");
                                doubles.push([index+1,doubles[doubles.length-1][1]*2,null]);
                            }
                        }
                    }
                }
                return { 
                    maxEq: maxEq, //> 0 ? maxEq*EQUITY_CORRECTION: maxEq/EQUITY_CORRECTION, 
                    minEq: minEq, //< 0 ? minEq*EQUITY_CORRECTION: minEq/EQUITY_CORRECTION, 
                    r:r ,
                    ptXd:99/r.length ,  
                    ptYd: 90/(maxEq-minEq),
                    top: 5/(maxEq-minEq),
                    diff: (maxEq-minEq),
                    left: 100/r.length,
                    doubles:doubles
                };
            }
        }
	}
</script>

<style lang="scss" scoped>

    .graphSVG {
        height:12em;
        width:90%;
        border-radius:0.5em;
        box-shadow: $box-shadow-minimal;
    }

    .graphVertical {
        height:10em;
        margin-bottom: 1em;
    }

    .graphTablet {
        height:20em;
        margin-bottom: 1em;
    }

    .graphSVGMinimal {
        height:85%;
        width:90%;

    }

    .graphBackground {
        fill:#252525;
    }

    .errorPerfect {
        background: green;
        stroke-width: 1%;
        stroke:#00FF0088;
    } 
    .errorModerate {
        background: greenyellow;
        color:black;        
        stroke-width: 1%;
        stroke:#667700AA;
    }
    .errorMedium {
        background: orange;
        color:black;
        stroke-width: 1%;
        stroke:#FF8800AA;

    }
    .bigError {
        background:#FF0000;
        stroke-width: 1%;
        stroke:#FF0000AA;
    }


    .filtersMenu {
        display: flex;
        font-size: 2em;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom:0.5em;

        >div:nth-child(2) {
            display: flex;
            font-size: 0.8em;
            margin-left:1em;
            div {           
                border-radius: 0.5em;     
                width:1em;
                text-align: center;
                margin:0.1em;
                background:$c7;
                padding: 0.1em;
            }
        }

    }

    .firstContainer {
        display:flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;

        >div:nth-child(1) {
            background: $c5;
            border-radius: 0.3em;
            padding: 0 0.3em;
        }
    }


</style>