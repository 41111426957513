<template>

<div class="frameme">
    <div class="matchEndContainer"> 
        <div class="matchEndHeader" style="text-align:center;">
            <div>{{ t('ui.game.subject.matchinfo') }}</div>            
            <div class="pointsBadge" v-if="gameType=='abak'">
                <img :src="require('@/assets/img/symbols/stake.svg')"/> 
                {{ stake }}
            </div>
            <div class="pointsBadge">
                <img :src="require('@/assets/img/symbols/goal.svg')"/>
                {{points.goal}}
            </div>
        </div>
        <div  class="firstRow" v-if="!pleaseConfirm" >
            <div class="playersContainer">     
                <div style="width: 50%;flex-grow: 1;display: flex;flex-direction: column;align-items: center;">  
                    <PlayerBadge 
                        :direction="'1'" 
                        :player="players[0]" 
                        :imageSize="imageSize" 
                        :playerID="0"
                        :vertical="true"
                        :key="'MatchEndplayerBadge1'" 
                        :id="'MatchEndplayerBadge1'"/>
                    <div style="display:flex;justify-content:center;padding:0.4em;">
                        <div class="MatchEndPoints">{{points.current[0]}}</div>                        
                    </div>
                    <div class="surrenderResult" v-if="phase=='endedMatch'">
                        <img v-if='calculateNewRanking[0] > players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                        <img v-if='calculateNewRanking[0] < players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/down.svg')" />
                        <div> {{Math.abs(calculateNewRanking[0] - players[0].ranking) }}</div>
                        <div> {{calculateNewRanking[0]}}</div>
                    </div>

                </div>

                <div style="width: 50%;flex-grow: 1;display: flex;flex-direction: column;align-items: center;">  
                    <PlayerBadge 
                        :direction="'1'" 
                        :player="players[1]" 
                        :imageSize="imageSize" 
                        :playerID="1"
                        :vertical="true"
                        :key="'MatchEndplayerBadge2'" 
                        :id="'MatchEndplayerBadge2'"/>
                    <div style="display:flex;justify-content:center;padding:0.4em;">
                        <div class="MatchEndPoints">{{points.current[1]}}</div>
                    </div>                
                    <div class="surrenderResult" v-if="phase=='endedMatch' && !(otherPlayer==1 && opponent.type=='CPU')">
                        <img v-if='calculateNewRanking[1] > players[1].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                        <img v-if='calculateNewRanking[1] < players[1].ranking' style="width:1em;" :src="require('@/assets/img/symbols/down.svg')" />
                        <div> {{Math.abs(calculateNewRanking[1] - players[1].ranking) }}</div>
                        <div> {{calculateNewRanking[1]}}</div>
                    </div>                        
                </div>
            </div>
            <MatchLog
                :points="points"
                :players="players"
                :title="'Games log'" />
        </div>


        <div class="buttons">
            <div>        
                <GameButton 
                    :key="'ButtonMatchConfirm'" 
                    :id="'ButtonAcept'" 
                    :text="t('ui.game.action.close')" 
                    image='no'
                     @click="$emit('close-match-info')" />
            </div>
        </div>
    </div>
</div>
</template>

<script>

    import GameButton from "../buttons/GameButton.vue";
    import PlayerBadge from "./PlayerBadge.vue";
    import { useI18n } from 'vue-i18n'
    import MatchLog from "./MatchLog.vue";
    var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');

	export default {
		name: 'MatchInfo',
        components: {GameButton,PlayerBadge,MatchLog},
        props: ['points','players','matchData',
                'doubleCube','phase','imageSize','playerID',
                'surrenderPoints','opponent','stake','gameType'],
        data: function() {
            return {
                pleaseConfirm:false,
                action:null,
            }
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },         
        computed: {
            otherPlayer: function() {
                let playerID = this.playerID || 0;
                return  (parseInt(playerID)+1)%2;
            },
            calculateNewRanking: function() {
                //console.log("calculateRanking");
                if (this.points.current[0]>=this.points.goal) {
                    return lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,0,this.points.goal); 
                }
                return lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,1,this.points.goal);
                
            },    
            isMatchEnded: function() {
                return this.points.current[0]>=this.points.goal ||
                        this.points.current[1]>=this.points.goal;
            }                     
        },
        emits:['close-match-info'],
        methods: {	           
        },
        mounted: function() {
            this.$nextTick(function () {                
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .frameme {
        position:absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        margin:0;
        background-color: #000000AA;
        z-index: 10000;
    }

    .matchEndContainer { 
        box-sizing: border-box;
        position:absolute;
        width:60%;
        height:90%;
        background-color:$c14;
        left:20%;
        top:5%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius:0.5em;
        filter:drop-shadow(0.1em 0.2em 0.3em black);
        padding:0.5em 0.5em 1.5em 0.5em;
        z-index:100;
    }

    .buttons > div {
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;        
    }


    .gameDetailsContainer img {
        width:1.5em;
        margin:0.2em;
    }

    .gameDetails {
        display:flex;
        justify-content: space-around;
        border-bottom: 0.1em solid white;
        margin-bottom:0.1em;
    }

    .gameDetails > div {
        margin:0 0.5em;
    }

    .gameDetailsContainer {
        font-size:1em;
        max-height: 8em;
        overflow: auto;
        flex-grow: 0.3;
    }

    .playersContainer {
        display:flex;
        flex: 1 1 0px;
    }

    .firstRow {
        display:flex;
    }

    .matchEndHeader { 
        display:flex;
        justify-content: space-around;
        align-items: center;
        font-size:1.5em;
    }

    .pointsBadge {
        background-color:rgb(0, 0, 0);
        padding:0.3em;
        border-radius: 0.5em;
        min-width: 1.5em;
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;
        display: flex;
        

        img {
            width: 1em;
            filter:invert(1)
        }
    }

    .MatchEndPoints {
        background-color:rgb(89, 121, 189);
        padding:0.3em;
        border-radius: 50%;
        width: 1.5em;        
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;    
        display: flex;    
        justify-content: center;        
    }

    .cajaBotones {
        border-radius: 1em;
        width: 90%;
        margin-left:5%;
        box-sizing: border-box;

    }

    .surrenderResult {
        display:flex;
        justify-content:center;
        align-items: center;
        margin-top:0.5em;
        font-size:1.2em;
    }

    .surrenderResult > div:nth-child(3) {
        margin-left:1em;
        background: $c6;
        padding: 0em 0.5em;
        border-radius: 0.2em;
    }
    .playerContainerSurrender {
        width: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;        
        justify-content: flex-start;
        align-items: center;        
    }
    
    @media (orientation:portrait) {
        .matchEndContainer {
            width:95%;
            left:2.5%;
            font-size: 1.5em;
            height: 80%;
        }

        .matchEndHeader {
            font-size: 1em;
        }

        .firstRow {
            flex-direction: column;
        }

        .buttons div {
            flex-wrap: wrap;

            div {
                margin:0.5em;
            }
        }
        
    }

</style>
