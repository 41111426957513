export default {
  "FAQ": {
    "preguntas": {
      "1": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution, Tavla'dan daha <span>karmaşık</span> mı?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dürüst cevap evet, ancak karşılığında daha eğlenceli ve dinamik bir oyun elde ediyorsunuz. Oyuna sınıflar ekleyerek her bir askere bir kimlik kazandırıyor ve kendi özelliklerini düşünmeniz gerekiyor. Ayrıca, Abak'ta zar atışı için çok daha fazla kombinasyon var, çünkü hareket sırası sonucunu etkileyebilir."])}
      },
      "2": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution, Klasik Tavla'dan ne kadar <span>farklı</span>?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel kurallar neredeyse aynı, ancak sınıflar eklemek oyunu kökten değiştirir. <ul> <li><span>Yeni bir boyut:</span> Piyonun dikey pozisyonu önemlidir. Bu, yeni bir boyut oluşturur: Y ekseni. Şöyle düşünün: Abak'ta sadece bir noktada 3 beyaz piyon olduğunu söyleyemezsiniz, her birini sırayla belirtmeniz gerekir.</li> <li><span>Daha fazla kombinasyon:</span> Her Tavla pozisyonunun (3.346.200)² olası Abak Evolution pozisyonu vardır.</li> <li><span>Piyonlar kimliğe sahiptir</span>: Bireysel kapasitelerine dikkat etmeniz gerekir.</li> <li><span>Hareket sırası önemlidir:</span> Bir piyonu diğerinden önce hareket ettirirseniz, zar atışının sonucunu dramatik bir şekilde değiştirebilir.</li> </ul>"])}
      },
      "3": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'u nasıl öğrenebilirim?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavla'dan Abak'a kadar nasıl oynanacağını öğrenmek için bu web sitesindeki kaynakları kullanın. Oyun arayüzü kuralları vurguladığı için iyi bir kaynaktır."])}
      },
      "4": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'u öğrenmek için Tavla oynamayı bilmem gerekiyor mu?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet. En azından Klasik Tavla'nın temel kurallarını bilmeniz gerekir."])}
      },
      "5": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden bir noktada 5 piyon limiti var?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kural Klasik Tavla'da mevcut olsa da, sık sık kullanılmaz. Özellikle piyon kırarken çok fazla istikrarsızlık ekler. Abak bunu kullanır çünkü bu kuralı seviyorum ve ayrıca pratik bir neden için: piyon görünürlüğü."])}
      },
      "6": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution, Tavla'dan daha yavaş veya daha uzun mu?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı askerlerle tanıştıktan sonra, oyun hızı Klasik Tavla'ya oldukça benzer. General geri hareket edebildiği için bazen oyun (tur sayısı açısından) Tavla'dan daha uzun olur."])}
      }
    },
    "header": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir sorunuz olabilir"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burada bulabilirsiniz"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSS"])}
    }
  },
  "about": {
    "history": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni oyunlar icat etmek için hiç oturmadım, bu sadece ortaya çıktı. Bir arkadaşla Tavla oynuyordum, kuralların monotonluğundan sıkılmıştım ve bu fikir aklıma geldi. Bu, 1999 yılının Eylül ayında Paskalya Adası'ndaydı."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu ücretsiz yapıyorum çünkü kalbimde bu oyunun bildiğiniz Tavla'nın halefi olduğuna inanıyorum ve bu hayatım boyunca gerçekleşirse sonuçlar retribüsyon olarak fazlasıyla yeterli olacaktır. Yazılım mühendisi olduğum için tüm bunları boş zamanlarımda kendi başıma yapabildim ve süreçte eğlendim."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dünyayla kurallarımı paylaşmak için WebApp'i geliştirirken, Abak Evolution'un çevrimiçi olduğu an büyük bir hit olacağını düşündüm, Tavla oyuncularının yeni kuralları benimseyip 'Aman Tanrım, bu nasıl aklıma gelmedi!' diye düşüneceklerini sandım. Sürpriz, sürpriz: Olmadı. Bunu yazarken, Abak çevrimiçi olduktan sonra dört tam yıl geçti, binlerce dolarlık ücretli pazarlama kampanyaları yaptım, ancak yine de en fazla birkaç yüz düzenli oyuncusu var."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution, aşılması zor olduğunu kanıtlamış birçok giriş engeliyle karşı karşıya:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu proje destansı, kar amacı gütmeyen, tek kişilik bir gösteri."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benim adım Samy Garib, Şilili bir Yazılım Mühendisiyim."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman ayırdığınız için teşekkürler."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O andan itibaren 20 yıldan fazla zaman geçti ve binlerce saatimi kuralları, mekanikleri, uygulamayı, web sitesini, pazarlama içeriklerini vb. geliştirmek için harcadım."])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşte savaşımın şu anki cepheleri. Boş zamanlarımda bunlar üzerinde çalışıyorum, öğrenme içeriklerini önceliklendiriyorum, çünkü bence en büyük engel bu. Yapay Zekâya çok fazla zaman harcıyorum çünkü onu çok seviyorum, motor şu an oldukça iyi oynuyor olsa da daha güçlü bir oyuncuya ihtiyaç yok."])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Hakkında"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısa bir rant."])},
      "g": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasik Tavla'dan oldukça daha zorlu ve dik bir öğrenme eğrisine sahip."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavla oyuncuları oyunlarına çok bağlıdır ve Abak'ı bir tehdit veya davetsiz misafir olarak görürler."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birçok insan Tavla'yı insanlara karşı oynamayı sever, bilgisayarlara değil, ve bunu başarmak için kritik bir oyuncu kitlesine ihtiyaç vardır."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişimi ve içerik oluşturmayı ölçeklendirecek yeterli kaynağa sahip değilim."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama, tavla deneyiminde son teknoloji değil ve ileri düzey tavla oyuncularının isteyeceği birçok araçtan yoksun."])}
      },
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarihçe"])},
      "thanks-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkür"])},
      "rant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rant"])}
    },
    "history1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çocukken tavla oynamayı öğrendim ve ailemle ve okul arkadaşlarımla çok oynadım."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayatımın ilerleyen dönemlerinde birçok arkadaşıma tavla oynamayı öğrettim. Sonunda, para olmadan oynamaktan zevk alamayacağım bir seviyeye ulaştım."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak'ı Ağustos 1999'da Paskalya Adası'na yaptığım bir seyahatte, sıkıntıdan icat ettim."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk versiyon, şu ankinden çok farklıydı ve arkadaşlarımdan hiçbiri onlara nasıl oynanacağını öğrettikten sonra oynamaya devam etmedi. Bu yüzden, beynimin bodrum katında uzun yıllar boyunca saklandı."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir gün, 2007'de iş yerinde, bazı iş arkadaşlarıma tavla oynamayı öğrettim ve onlar para karşılığında benimle oynamayı reddettiklerinde, sıkıntı bana bir kez daha icat ettiğim oyunu öğretmeye yönlendirdi."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ama önce oyunu harika yapmak için değişiklikler yaptım (yaralılar ve bazı kısıtlamalar)."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aralarında büyük bir başarı oldu. O zamandan beri, iş yerimde öğle yemeği sırasında her gün bir çift (genellikle benimle, her şey söylenmeli) Abak oynar."])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ekip arkadaşlarım, oyunun bir yazılım versiyonunu yapmam için beni teşvik etti, ama biliyorsunuz, hayat ve öncelikleri."])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yani, birçok yıl geçti, ta ki Eylül 2009'a kadar. Bir kaza geçirdim ve evde iki tam hafta kaldım."])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir yazılım yaratıldı, hatalarla dolu ve o kadar çok çaba ve enerji aldı ki, yıllar boyunca yine uyudu."])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O zamandan beri, birkaç heyecan patlaması beni üzerinde çalışmaya yönlendirdi, ta ki dünya ile paylaşabileceğim bir seviyeye ulaşana kadar."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programlama ve bilgisayarlarda iyi olduğum için çok şanslı hissediyorum. Eğer olmasaydım, bunu mümkün kılma ve sizinle tanışmanız için ücretsiz bir versiyon yayınlama fırsatım asla olmazdı."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşte burada, tek kişilik bir gösteri (grafikler hariç, www.vrweb.cl'ye çok teşekkürler)"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umarım beğenmişsinizdir"])}
    },
    "donate": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gün"])},
      "hh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat"])},
      "devtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliştirme Süresi"])},
      "onlinesince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimiçi Olduğu Tarih"])},
      "playedgames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oynanan Oyunlar"])},
      "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Kullanıcılar"])},
      "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazançlar"])},
      "donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu Ana Kadar Bağışlanan Miktar"])},
      "otherincomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer Gelirler"])},
      "expenses_op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operasyon Giderleri (Aylık)"])},
      "adv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklam"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giderler"])},
      "devtimeprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliştirme ve İçerik Oluşturma"])},
      "estadisticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstatistikler"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yap"])},
      "necesities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamanımın yanı sıra, birçok farklı konuda doğrudan giderlerim var"])},
      "necesity": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pazarlama Gelişimi (Videolar + Grafikler)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pazarlama Medyası (Adwords+Facebook)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Merkezi (Amazon AWS)"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI Geliştirme (Tasarım+Çeviriler)"])}
      },
      "closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyunu beğendiyseniz ve yardımcı olabiliyorsanız, büyük bir yardım olur. Şimdiden teşekkürler."])}
    },
    "samy": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1973 yılında Şili'de doğmuş tutkulu bir yazılım geliştiricisiyim. Filistinli bir aileden geldiğim için güçlü bir Arap kültürüyle büyüdüm, Tawle (veya Tavla) bu kültürün unsurlarından biriydi."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1999 yılından beri kendi şirketim VRWEB'de çalışıyorum ve ana rolüm pazarlama ve satışta olsa da kod yazmayı seviyorum. Abak benim oyun alanım."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üç çocuk babasıyım ve dört kişilik bir ailenin aşçısıyım."])}
    },
    "thanksto": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllar boyunca, dünya çapında insanlardan çok fazla yardım aldım: çeviriler, teknik tavsiyeler, oyun oynama tavsiyeleri, hayran sanatı, vb."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özellikle, yardımcı olmasalar bile benimle etkileşimde bulunan ve bana böyle bir coşku gösteren, hedeflerime katalizör olan kişiler:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son olarak, Abak platformunun temelini oluşturan inanılmaz açık kaynak projelerinde çalışmış birçok insan var."])}
    }
  },
  "ayuda": {
    "generales": {
      "delsoldado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınıf Kuralları"])},
      "situaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durumlar"])},
      "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım almak için bir askere tıklayın"])},
      "highest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her noktadaki en yüksek pulunuzu önce hareket ettirmelisiniz."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üstünde başka bir pul varsa bir pulu hareket ettiremezsiniz."])}
      },
      "5max": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her noktada en fazla 5 pul limiti vardır."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saha pozisyonlarında, her noktada sadece beş pul istifleyebilirsiniz."])}
      }
    },
    "piedras": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asker"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klasik Tavla ile aynı"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asker, Klasik Tavla'nın Normal puludur. Her takımın 9 tane vardır."])}
      },
      "2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geriye ve ileriye hareket eder"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General bazı sınırlamalarla geriye ve ileriye hareket edebilir. Her turda sadece bir yönde hareket edebilir ve sadece bir kez geri gidebilir."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri giderse, aynı turda geri dönemez"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri giderse, o turda daha fazla hareket edemez."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm takım arkadaşları takım evindeyken geri hareket edemez."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar'dan hareket ettikten sonra geri hareket edebilir."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri hareket ederse"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri hareket ederse"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ileri gidemez"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geri gidemez"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ileri gidebilir"])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muhafız"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diğerlerinden daha güçlü"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muhafızlar, iki asker gibi hareket eder ama druid tarafından tuzağa düşürülebilir ve diğer muhafızlar veya iki asker tarafından vurulabilir. Her takımın 2 tane vardır."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iki asker gibi engeller"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid tarafından tuzağa düşürülebilir"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diğer muhafızlar tarafından vurulabilir"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iki asker onu vurabilir"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir muhafızı iki (muhafız olmayan) askerle vurmak için, her iki askerin de ardışık hareketlerle aynı turda onu vurabilmesi gerekir."])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuzaklar ve tutar"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid, ortada yalnız bir düşmanı tuzağa düşürebilir. Tuzaklanan asker, druid onun üzerindeyken hareket edemez."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid bir askeri tuzağa düşürdü"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid güvende"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid diğer bir druid tarafından tuzağa düşürüldü"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid vurulacak"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir evde, druid normal bir asker gibi davranır."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid tek bir askeri tuzağa düşürebilir"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid bloke edilmiş bir sütunu tuzağa düşüremez"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orta saha"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid tuzağa düşürür"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siyah ev"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beyaz ev"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid vurur"])}
      },
      "5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Yaralı"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sadece <span>ÇİFT</span> sayıları oynar"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Yaralı sadece ÇİFT sayıları oynayabilir"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar'dan çıktığında, herhangi bir sayıyı oynayabilir."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulu kırarken, herhangi bir sayıyı oynayabilir."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Yaralı sadece gidilen mesafe çift sayı olduğunda veya bar'dan çıkarken ya da pulu kırarken hareket edebilir."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noktalar arasında hareket ederken (<span>üçgenler</span>), her ikisi de her zaman <span>aynı renkte</span> olacaktır. <span>Bu yüzden sembolü</span>."])}
      },
      "6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Yaralı"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sadece <span>TEK</span> sayıları oynar"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Yaralı sadece TEK sayıları oynayabilir"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar'dan çıktığında, herhangi bir sayıyı oynayabilir."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulu kırarken, herhangi bir sayıyı oynayabilir."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Yaralı sadece gidilen mesafe tek sayı olduğunda veya bar'dan çıkarken ya da pulu kırarken hareket edebilir."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noktalar arasında hareket ederken (<span>üçgenler</span>), her ikisi de her zaman <span>farklı renklerde</span> olacaktır. <span>Bu yüzden sembolü</span>."])}
      },
      "56": {
        "leavingorcaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar'dan çıkarken veya pulu kırarken, herhangi bir sayıyı hareket ettirebilir."])}
      }
    }
  },
  "contact": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution ile İletişim Kurun"])},
    "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benimle iletişime geçmeniz gerekirse, bu alternatiflerden birini kullanın"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'u çeşitli sosyal ağlarda bulabilir ve bana oradan yazabilirsiniz."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya bir e-posta gönderin"])}
  },
  "hall": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şöhretler Salonu"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şöhretler Salonu"])}
  },
  "intro": {
    "content": {
      "tituloheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span>, her biri kendi özelliklerine ve <span>benzersiz davranışlarına</span> sahip <span>pul sınıfları</span> ekleyen bir Tavla çeşididir."])},
      "isaboardgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span>, iki oyunculu soyut bir strateji masa oyunudur. Bu klasik <span>Tavla</span> yeniden yapımında kazanmak istiyorsanız şansa ve en iyi taktiklerinize ihtiyacınız olacak."])},
      "backgammon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavla<span>²</span>"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span>, tavla kurallarını kullanır, ancak 5 asker sınıfı ekler. Bu küçük değişiklik, olağanüstü sonuçlar sağlar."])},
      "gotoabakme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buradan çevrimiçi öğrenebilir ve oynayabilirsiniz."])},
      "abakapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güçlü bir CPU rakibine veya gerçek insanlara karşı oynayabileceğiniz tam özellikli bir web uygulaması bulacaksınız."])},
      "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal medya hesaplarımıza göz atın"])},
      "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyna"])},
      "printrules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuralların basılabilir versiyonunu, tahtanız için çıkartma şablonlarını ve diğer araçları burada bulabilirsiniz:"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçlar"])},
      "nonprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span>, bu harika kuralları insanlığa yaymak için destansı, kar amacı gütmeyen, tek kişilik bir çabadır. <br> Şilili Yazılım Geliştirici <span>Samy Garib</span> tarafından icat edilmiş, geliştirilmiş, sürdürülmüş ve finanse edilmiştir."])},
      "sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ol"])},
      "entonces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonra"])},
      "classbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sınıf tabanlı bir tavla çeşidi"])},
      "quees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sınıf tabanlı bir tavla çeşidi"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınıflar eklemek oyunu kökten değiştirir. Bir piyonun dikey pozisyonu ve dolayısıyla hareket sırası önemlidir. <span>Abak Evolution</span>, <span>Klasik Tavla</span>'ya yeni bir boyut ekler"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğren"])},
      "moredetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla detay"])},
      "timagen": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bir sınıfist tavla, tıpkı senin gibi"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["steroidli tavla"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tavla)²"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büyükler için Tavla."])}
      },
      "abakisto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak, Tavla'nın dama ile satranç gibidir"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurallar"])}
    },
    "privacy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Politikaları"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi Toplama, Kullanma ve Paylaşma"])},
      "content": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Kuralları'nı, bir tavla kuralları genişlemesini oynamak için bir çevrimiçi platformdur."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bu kuralları yaymak için tek kişilik, profesyonel olmayan, kar amacı gütmeyen bir çabadır."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform ücretsizdir, diğer tüm Abak Evolution varlıkları gibi."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklamsızdır ve isteğe bağlı kayıt özelliklerine sahiptir."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kullanıcı verilerini yalnızca kullanıcı durumunu ve sıralamayı saklamak için kullanır. Kullanıcı verileri ile hiçbir dış faaliyet gerçekleştirmez."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kullanıcı bilgilerini diğer taraflarla paylaşmaz."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google'ın Firebase Auth Servisini bir bağlantı merkezi olarak kullanarak Facebook, Google ve Twitter gibi harici giriş platformlarına güvenir."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sosyal hesaplarınıza herhangi bir bilgi yayınlamaz veya kişi bilgilerinizi okumaz."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal platformlardaki uygulamalar sadece kişisel avatarınızı ve isminizi toplar."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hesabınıza ait verileri saklamak için çerezler kullanır. Harici giriş sağlayıcıları kendi çerezlerini kullanır."])}
      }
    }
  },
  "learn": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğrenme Merkezi"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'ı anlamak ve öğrenmek için kaynaklar"])},
      "contenido1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution oynamanızı sağlayacak bir dizi kaynak"])},
      "videoT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Kanalı"])},
      "videoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş videosu ve bazı kısa eğitimlerle birlikte Youtube kanalına göz atın."])},
      "versusT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s Tavla"])},
      "versusC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak'ın Klasik Tavla'dan ne kadar farklı olduğuna dair bir değerlendirme"])},
      "PDFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Kuralları"])},
      "PDFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimdışı ihtiyaçlarınız için yazdırmaya hazır dosya setini indirin"])},
      "reglasT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurallar Kitabı"])},
      "reglasC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'un tam kuralları tek bir sayfada. Yazdırmak veya telefonunuzda kontrol etmek için bir referans aracı"])},
      "tablaT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahtanız için"])},
      "tablaC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiziksel tahtanız için bir dizi çıkartma"])},
      "specs": {
        "specs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özellikler"])},
        "typeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
        "typeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyut<br>Strateji"])},
        "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllar"])},
        "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyuncular"])},
        "complexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karmaşıklık"])},
        "mechanics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mekanikler"])},
        "turnbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra tabanlı"])},
        "dicedriven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zar tabanlı"])},
        "perfectinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tam bilgi"])},
        "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zor"])}
      }
    },
    "faq": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir sorunuz olabilir"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burada bulabilirsiniz"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSS"])}
    },
    "files": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeşitli yardımcı dosyalar seti"])}
    },
    "blog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Blogu"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak ile ilgili çeşitli konular hakkında makaleler listesi, çoğunlukla SEO amaçlı. Ama zamanınız varsa."])}
    },
    "introvideo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanıtım videosunu izleyin"])}
    }
  },
  "lobby": {
    "subject": {
      "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakip"])},
      "human": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsan"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seviye"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurallar"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yön"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğitim"])},
      "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğitimler"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sol"])},
      "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaşlar"])},
      "players": {
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimiçi"])},
        "invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["görünmez oyuncular"])},
        "last_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem"])},
        "prefered_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercih edilen türler"])},
        "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimdışı"])},
        "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oynuyor"])},
        "atlobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobi"])}
      },
      "friendship": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklemede"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddedildi"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duraklatıldı"])},
        "friendship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaşlık"])},
        "notyet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz arkadaşınız yok. Çevrimiçi oyuncular bölümünden çevrimiçi oyunculara arkadaşlık isteği gönderebilirsiniz."])},
        "requestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaşlık isteği gönder?"])},
        "newrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir arkadaşlık isteğiniz var"])},
        "onlinefriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimiçi Arkadaş"])}
      },
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese Açık"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maç"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama"])},
      "game_visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun Ziyaretçileri"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziyaretçiler"])}
    },
    "alerts": {
      "nogamestojoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılacak oyun yok."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla"])},
    "action": {
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyna"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluştur"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğren"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzle"])},
      "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet Et"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benimle İletişime Geç"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkında"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıştırma"])},
      "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oynandı"])},
      "won": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazandı"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam Et"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaş"])},
      "sharein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaş"])},
      "cpypasteshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bağlantıyı kopyalayın ve paylaşın"])},
      "shareabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak'ı arkadaşlarınızla paylaşın!"])},
      "shareboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyununu paylaş"])},
      "howtoshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu araç, paylaşmanız için bir öğe oluşturacak. İsteğe bağlı bir açıklama ekleyebilirsiniz"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme"])},
      "contactsocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercih ettiğiniz sosyal ağda benimle iletişime geçin"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohbet"])}
    },
    "user": {
      "subject": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
        "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstatistikler"])},
        "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madalya"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
        "howemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydederken, doğrulama bağlantısı içeren bir mesaj alacaksınız"])},
        "checkvalidationemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postanızı doğrulamanız gerekiyor. Mesajı tekrar göndermeyi deneyebilirsiniz."])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçmiş"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günlük"])}
      },
      "action": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
        "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Gönder"])}
      },
      "message": {
        "knownguestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu cihaz için kayıtlısınız. Kalıcı ve çok cihazlı bir kullanıcı oluşturmak için <b>Sosyal Giriş</b> kullanın."])},
        "guestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcı sadece bu cihaza bağlıdır. Bu kullanıcıyı daha sonra geri almak veya farklı cihazlarda oyunlarınıza devam etmek istiyorsanız, geçerli bir e-posta sağlayın."])}
      }
    },
    "messages": {
      "newinvitation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun daveti"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul ederseniz, diğer maç sona erdikten sonra bu maça devam edeceksiniz."])},
        "headding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir oyuncuya karşı bir oyuna katılma davetiniz var"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu davet tüm aktif oyunculara gönderildi. Yanıtınız anonimdir."])},
        "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu davet doğrudan size gönderildi."])}
      },
      "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılacak maç yok."])},
      "p2pnomatchesoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenekleriniz"])},
      "playandwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucuya karşı oynayın ve bir davet bekleyin"])},
      "inviteonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucuya karşı oynayan çevrimiçi oyunculara davet gönder"])},
      "createhuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir oyun oluşturun ve bir oyuncunun katılmasını bekleyin"])},
      "error": {
        "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir şeyler ters gitti, sunucuya bağlanamadım."])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu durumu bildirebilirsiniz"])}
      },
      "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Seçin"])},
      "unfinishedgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam eden bir oyununuz var. Bitirin veya pes edin."])},
      "wontunlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu zorluk seviyesi bir sonraki alıştırma seviyesini açmak için yeterli değil."])},
      "unlocklevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alıştırma seviyesini açmalısınız."])},
      "inviteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$$(player)$$ adlı oyuncuyu davet et."])},
      "nomoreplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet edilecek başka oyuncu yok."])}
    },
    "hallfame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şöhretler Salonu"])}
    },
    "chat": {
      "message": {
        "tooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj çok kısa"])}
      }
    },
    "message": {
      "choosepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıştırma Seviyesi"])},
      "newpracticelevelavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Alıştırma Seviyesi Mevcut. Seçim kutusunda seçin."])},
      "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışıyor. Lütfen bekleyin"])},
      "becomeabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Tavla</b> ve <b>Mısır Tavlası</b> oynayabilirsiniz."])},
      "becomeabakerhowto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm özellikleri <b>kilidini açmak</b> için bir <b>Abaker</b> olmalısınız. <b>Alıştırma</b> seviyelerini tamamlayın veya ileri düzey veya uzman seviyelerine karşı bir abak maçı kazanın."])},
      "welcome": {
        "customize1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı Özelleştir"])},
        "customize2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı adınızı ve avatarınızı değiştirin."])},
        "stats1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstatistikler"])},
        "stats2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyuncu geçmişinizi kontrol edin"])},
        "hall2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En İyi Abakerları Tanıyın"])},
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu araçlara bir göz atın."])}
      },
      "caninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygun oyuncuları davet edebilirsiniz"])},
      "cantinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaker olana kadar diğer oyuncuları davet edemezsiniz"])},
      "noonlineplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimiçi oyuncu yok"])},
      "onlyabakersorfriendscaninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaker değilseniz, arkadaşlarınız olmayan oyunculara davet oluşturamazsınız."])},
      "friendship": {
        "requestsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstek Gönderildi"])},
        "requestexists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstek zaten mevcut"])},
        "responsesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt Gönderildi"])}
      },
      "emptylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş Liste"])}
    }
  },
  "meta": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla Hakkında"])},
      "history": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla Tarihi"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak, 1999 yılında benzersiz bir olaylar zinciri ve koşullar sonucunda doğdu."])}
      },
      "rant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla Beklediğim Gibi Olmadı"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak düşündüğüm gibi büyük bir hit olmadı, ancak bu projeden asla vazgeçmeyeceğim."])}
      },
      "samy-garib": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib - Abak Evolution Tavla"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib, Abak Evolution Tavla'nın mucidi, tasarımcısı ve geliştiricisi olan Şilili bir yazılım geliştiricidir."])}
      },
      "donate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla'nın Hayatta Kalması İçin Bağış Yapın"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla, tek kişilik bir projedir, ücretsizdir, reklamsızdır ve yardıma ihtiyaç duyar."])}
      },
      "thanks-to": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla Bazı Kişilere Minnettar."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birçok insan bu projenin bir parçası oldu. Çeviri yardımlarından büyük bir coşkuya kadar. Her şey güzel."])}
      }
    },
    "boardgame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla Masa Oyunu"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla ile İletişime Geçin"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak, birçok sosyal medya sitesinde yer almaktadır. Buradan onlara göz atın ve benimle iletişime geçmenin yollarını bulun."])}
    },
    "learn": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla'yı Öğrenin"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavla ve Abak'ı öğrenmek için kaynaklar. PDF kurallar kitabı, etkileşimli bir eğitim ve çok daha fazlasını burada bulabilirsiniz."])}
    },
    "print": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla Kurallarını Yazdırın"])}
    },
    "resources": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'u Öğrenmek İçin Kaynaklar"])}
    },
    "rules": {
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asker - Bir Abak Evolution Tavla Sınıfı"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasik tavla askeri gibi davranırlar."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General - Bir Abak Evolution Tavla Sınıfı"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyunda geri gidebilen tek askerdir. Mücadeleyi yönetir."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muhafızlar - Bir Abak Evolution Tavla Sınıfı"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her takımın iki Muhafızı vardır. Diğer askerlerden daha güçlüdürler ve kolayca yakalanamazlar."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid - Bir Abak Evolution Tavla Sınıfı"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid rakibini tuzağa düşürür, düşmanını yakalamak yerine onu hareketsiz hale getirir."])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Yaralı - Bir Abak Evolution Tavla Sınıfı"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Yaralı sadece çift sayılarla hareket edebilir."])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Yaralı - Bir Abak Evolution Tavla Sınıfı"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Yaralı sadece tek sayılarla hareket edebilir."])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla Kuralları"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak'ta 6 sınıf asker vardır: Asker, General, Druid, Muhafızlar ve iki sınıf olan yaralılar."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurallar - Abak Evolution Tavla"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSS - Abak Evolution Tavla"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu SSS, Abak ile Tavla arasındaki farklar hakkındadır."])}
    },
    "abak": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tavla. Bir Varyant."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution, iki oyunculu, soyut bir strateji masa oyunudur. Klasik oyuna sınıf ekleyen bir Tavla Varyantı."])}
    },
    "tutorial": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavla & Abak Evolution Eğitimi"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, interaktif bir tavla eğitimidir. Tahtayı, hareketleri, barı ve piyon kırmayı öğreneceksiniz. Ayrıca bulmacalarla gelişmiş eğitimler de bulacaksınız."])},
      "backgammon": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavla Eğitimi"])}
      },
      "abak": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Eğitimi"])}
      }
    }
  },
  "pdf": {
    "rules": {
      "generales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kurallar, oyun mekaniğinde küçük bir değişikliktir. Biri oldukça örtük olmasına rağmen klasik tavlada uygulanmasına gerek olmayan, diğeri ise oyunun bazı versiyonlarında zaten mevcut olan kurallardır."])},
      "intro0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution Tavla</b>, her biri kendi özelliklerine ve benzersiz davranışlarına sahip 5 pul sınıfı ekleyen bir Tavla çeşididir."])},
      "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu belge, <b>Abak Evolution Tavla</b>'nın kurallarını açıklar. Klasik Tavla bilgisi gerektirir."])},
      "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orijinal kurallarda küçük değişiklikler vardır, sınıfları eklediğinizde bunlar doğal ve belirgin hale gelir."])},
      "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> beş asker sınıfı ve normal pulu ekler. Bu sınıfların her birinin farklı özellikleri vardır."])},
      "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'u şu adreste oynayabilirsiniz:"])},
      "intro5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya tahtanıza buradan bulabileceğiniz çıkartmaları yapıştırabilirsiniz:"])},
      "intro10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kurallar mevcuttur"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her asker sınıfının davranışını değiştiren kendi özellikleri vardır."])},
      "start0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç Pozisyonu"])},
      "start1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç pozisyonu tavlaya çok benzer, ancak arkada iki pul yerine 4 pul ile başlarsınız:\n<ul> <li>Yaralılar (⨯2) arkada başlar <span class='etiqueta-posicion'>[p.24-1]</span> <span class='etiqueta-posicion'>[p.23-1]</span>, her biri maksimum zar yüzü sayısıyla 18 noktaya ulaşacak şekilde yerleştirilir. </li>\n<li>Druid onlarla birlikte başlar, mümkün olduğunca geride <span class='etiqueta-posicion'>[p.24-2]</span>. Etki alanı orta sahadır, bu yüzden geride beklemek daha iyidir.</li>\n<li>Muhafızlar (⨯2) eve yakın başlar <span class='etiqueta-posicion'>[p.13-3]</span> <span class='etiqueta-posicion'>[p.8-2]</span>, yapılar oluşturmaya hazır ama hareket etmeye hazır değildir, başlangıçta her ikisi de bir askerin altındadır.</li>\n<li>General evde başlar <span class='etiqueta-posicion'>[p.6-4]</span>, her iki yönde yapılar oluşturmaya yardımcı olmak için.</li>\n<li>Geri kalanlar, normal askerler, şu konumlarda başlar: <span class='etiqueta-posicion'>[p.23-2]</span><span class='etiqueta-posicion'>[p.13-1]</span><span class='etiqueta-posicion'>[p.13-2]</span><span class='etiqueta-posicion'>[p.13-4]</span><span class='etiqueta-posicion'>[p.8-1]</span><span class='etiqueta-posicion'>[p.8-3]</span><span class='etiqueta-posicion'>[p.6-1]</span><span class='etiqueta-posicion'>[p.6-2]</span><span class='etiqueta-posicion'>[p.6-3]</span> </li>\n</ul>\n"])},
      "bidimensional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b>, Tavlayı <b>iki boyutlu</b> bir oyuna dönüştürür. Bu, bir taşın sütundaki dikey pozisyonunun önemli olduğu ve dolayısıyla bir turdaki <b>hareket sırasının</b> oyunda yeni bir faktör olduğu anlamına gelir."])},
      "imagina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olasılıkları hayal edin"])},
      "classic_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takımınızın Klasik Tavla'daki bu pozisyonu"])},
      "millions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'da milyonlarca kombinasyona eşdeğerdir. Sadece küçük bir örnek:"])},
      "moves_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareket sırası"])},
      "drastically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketlerin sırası, oyunun sonucunu büyük ölçüde değiştirebilir."])},
      "druid_traps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu senaryoda, druid askeri tuzağa düşürür."])},
      "odd_captures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu senaryoda, tek yaralı askeri yakalar."])},
      "encuentra_abak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasıl oynanır"])},
      "redes_sociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution'u Sosyal Ağlarda Bulun</b>"])},
      "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonunuzda oynamak için uygulamayı indirin. Telefonunuzun uygulama mağazasında bulun."])},
      "play_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web tarayıcınızı açın ve buraya gidin:"])},
      "capturar_guardia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir muhafızı iki (muhafız olmayan) askerle vurmak için, her ikisini de <b>ardışık hareketlerle</b> aynı turda vurabilmelisiniz."])},
      "abak_vs_backgammon_titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak vs Klasik Tavla"])},
      "abak_vs_backgammon_cuerpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution'un Klasik Tavla'dan tek farkı eklenen sınıflar olmasına rağmen, bu oyunda önemli bir değişiklik yaratır ve iki ana sonuca yol açar."])},
      "abak_vs_backgammon_combinaciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak'ta"])},
      "abak_vs_backgammon_combinaciones2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mümkün pozisyonlar vardır, herhangi bir Klasik Tavla pozisyonu için."])},
      "subject": {
        "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Askerler"])}
      }
    }
  },
  "piepagina": {
    "texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span>, bu harika kuralları insanlığa yaymak için destansı, kar amacı gütmeyen, tek kişilik bir çabadır. <br> Şilili Yazılım Geliştirici <span>Samy Garib</span> tarafından icat edilmiş, geliştirilmiş, sürdürülmüş ve finanse edilmiştir."])},
    "privacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Politikaları"])}
  },
  "reglas": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Kurallar"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pul sınıfları eklemek sonuçlar doğurur"])},
      "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her noktaya en fazla 5 pul istiflenebilir."])},
      "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her noktanın en yüksek pulu önce hareket ettirilmelidir."])},
      "porque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavla'ya iki basit genel kural eklenir."])},
      "startpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Başlangıç Pozisyonu"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınıflar eklemek oyunu kökten değiştirir. Bir piyonun dikey pozisyonu önemlidir ve dolayısıyla hareket sırası da önemlidir. <span>Abak Evolution</span>, <span>Klasik Tavla</span>'ya yeni bir boyut ekler"])},
      "mismasreglas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span>, tavlanın aynı kurallarını kullanır, ancak 5 asker sınıfı ekler. Bu küçük değişiklik olağanüstü sonuçlar doğurur ve iki yeni belirgin ve doğal kural gerektirir."])},
      "thisguys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kurallarla birlikte, tavlanın aynı kuralları."])},
      "addsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı özelliklere sahip asker sınıfları ekler."])},
      "startposcontenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span>'ın başlangıç pozisyonu Tavla'nın başlangıç pozisyonuna çok benzer, ancak <span>sınıflar</span> ekler ve arkada 4 piyon vardır. Nackgammon tarzı."])},
      "ttd_titulo": null,
      "ttd_texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece olasılıkları hayal edin"])}
    }
  },
  "tips": {
    "general-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General tuzağa düştü."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General çok önemlidir ve hareket edemez."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri gidebildiği için, yaralıların eve gelmesine yardımcı olmak için oldukça kullanışlıdır ve evinizi savunma ve inşa etme şansınızı en üst düzeye çıkarmada kilit rol oynar. Onu üstte tutmaya ve kullanılabilir hale getirmeye çalışın."])}
    },
    "druid-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid tuzağa düştü."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid oyunda önemli bir rol oynayabilir."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir rakibi hareketsiz hale getirirse, oyunun kontrolünü ele almanızı sağlar. Onu üstte tutmaya ve kullanılabilir hale getirmeye çalışın."])}
    },
    "tooclose": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid evinize çok yakın."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid ölümcül bir silahtır, ancak ..."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büyük gücü orta sahadadır. Evinize çok yakın olmamaya çalışın, çünkü burada gücünü kaybeder ve normal bir asker gibi davranır."])}
    },
    "inmobilized-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketsiz bir askerin var."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Askeriniz rakip druidin altında, ancak korumasız."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakibiniz bir başka askerini üstüne koymadan önce onu vurmaya çalışın."])}
    },
    "inmobilized-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketsiz bir askerin var."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Askeriniz rakip druidin altında ve onu vuramazsınız."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onu da yakalamaya ve oyununuzu mümkün olduğunca sıkı tutmaya çalışın."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarları geri giderek harcamak için generalinizi kullanın."])}
    },
    "inmobilized-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir karışıklık var."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onun druidini hareketsiz hale getirdiniz, ancak o da sizi hareketsiz hale getirdi."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu planlayın. Avantajlısınız."])}
    },
    "inmobilizing-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakip askerini hareketsiz hale getirdiniz."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onun askeri druidinizin altında, ancak druidiniz korumasız."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozisyonunuzu korumak için üzerine başka bir asker koymaya çalışın."])}
    },
    "inmobilizing-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakip askerini hareketsiz hale getirdiniz."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakip askerini hareketsiz hale getirdiniz ve Druidiniz koruma altında. İyi iş!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aynı zar atışında koşabilir ve vurabilirsiniz. Doğru an için askerleri elinizde tutun."])}
    },
    "inmobilizing-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir karışıklık var."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onun druidini hareketsiz hale getirdiniz, ancak o da sizi hareketsiz hale getirdi."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu planlayın. Dezavantajlısınız."])}
    },
    "even-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Yaralı Tuzağa Düştü"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift yaralınız tuzağa düştü ve hareket edemiyor."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakibinizin pozisyonunu serbest bırakmasını sağlamak için bir yol bulmanız gerekiyor."])}
    },
    "even-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Yaralı Neredeyse Tuzağa Düştü"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift yaralınız yakında tuzağa düşebilir."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkatli olun ve mümkün olan en kısa sürede kaçmaya çalışın."])}
    },
    "odd-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Yaralı Tuzağa Düştü"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek yaralınız tuzağa düştü ve hareket edemiyor."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakibinizin pozisyonunu serbest bırakmasını sağlamak için bir yol bulmanız gerekiyor."])}
    },
    "odd-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Yaralı Neredeyse Tuzağa Düştü"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek yaralınız yakında tuzağa düşebilir."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkatli olun ve mümkün olan en kısa sürede kaçmaya çalışın."])}
    },
    "guard-not-on-floor": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muhafızınız yeterince kullanılmıyor."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muhafızlar bir noktayı tek başına tutabilirler. Onları yakalamak için rakip iki askerinin aynı turda vurması gerekir, bu da çok düşük olasılıklı bir olaydır."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onları tam güçlerini kullanabilmek için zemin seviyesinde tutmak en iyisidir."])}
    }
  },
  "tutorials": {
    "intro": {
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this set of tutorials you can learn Classic Backgammon rules and Abak Evolution rules. What would you like to learn?"])},
      "lback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Backgammon.          \n"])},
      "lback2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't know how to play Backgammon. I wan't to learn from scratch. "])},
      "labak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Abak."])},
      "labak2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know how to play Backgammon, I want to learn how to play Abak. "])},
      "whyask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is just to set a filter you can change later."])}
    },
    "options": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncompleted"])}
    },
    "level": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring your soldier to safety."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to ['move'] your soldier. "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one soldier at the end of the board and no enemies. Roll the dice and move your soldier until it gets home, and then remove it from the board to save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run home"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon's objective is to bring all of your checkers (['soldiers']) home. You do that by rolling two dice [13,15] and moving a soldier the number of spaces each die tells you."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear Off"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once all your soldiers are home, you can ['remove'] them (['bear off']) from the board. The first player that ['bears off'] all of its soldiers from the board wins."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dice Roll"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each dice roll will give you two movements if the dice are of different value and four if they are equals."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the roll button to, obviously, roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now move the soldier twice, one for each die, by clicking on it and selecting the landing column."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have played all your valid moves. Confirm you have finished with the button."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent!"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed your turn. Keep it going until your soldier is safe (bore off)."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent is not playing, so play again. Roll."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you have a double that gives you four [!4] moves of [d13] spaces each. Move them all."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done. Confirm your move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your soldier is now at home."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When all your soldiers are at home, you can remove them from the board (beat them off). Roll your dice."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Roll."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can choose the ['outside'] of the board [!0] to move your soldier."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, you have finished the game."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Finished"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That’s it. You have removed 'all' of your soldiers and finished the game. "])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about blocked positions in the next chapter."])},
        "11b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! do it again. Move with the other dice."])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's home"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump across the opponent's soldiers to ['escape'] his ['home'] and ['capture'] his soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two soldiers ['[s11]×2'] at the end of the board. Avoid the adversary ['[s12]×5']  and bring them home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked Positions"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A soldier can land at any position the opponent does not block. A position is blocked when the opponent has two or more soldiers at it."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT the addition"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" You can move different soldiers with each die or the same soldiers with both die, but remember those are two movements, and each has to have an open position to land."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on the soldier in position [23], you will see it can't move any die. So it can't move the addition of both dice. You must move the other soldier."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made all your moves. Now confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blots"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a soldier is alone in a position (['a blot']), it can be captured by an opponent soldier who lands over it. "])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the soldier landing with one soldier over it."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have sent your opponent’s soldier to the bar!."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Captured."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has a soldier on the bar, it has to return to the board for the team to keep playing. If it can't escape from the bar, no other soldier can move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to escape from the bar in the next chapter."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't Capture the soldier!"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to capture the lonely soldier. Try again!"])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board description."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this quick tour on the Backgammon Board."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has [!24] ['positions'], called ['points'], divided in [!4] quadrants."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Positions"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus the Bar [#B0] [#B1], and a place outside the board [0] [25], for rescued soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player's Home Board is in the ['bottom-right'] quadrant. ['Points [1] to [6]']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponet's Home Board is on the ['top-right'] quadrant. ['Points [24] to [19]']"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is no man's land. ['Points [7] to [18]']"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['bar'] is the position where ['captured'] soldiers are kept. [#B0][#B1]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Board"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['out-board'] ['[!0]-[!25]'] is the position you put your ['beared off'] soldiers."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inverted Board"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that the board could be inverted on the X axis, homes being on the left side. You can always can change the board direction in the menu."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each player has [15] soldiers."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yours: [s11] , Opponent: [s12]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opposite Direction "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players move in opposite directions. Player 1 ([s11]) moves from [24] to [1], towards his home."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent’s Direction"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player 2 ([s12]) moves from [1] to [24]."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll and Move"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game is played in turns, each player roll two dice [d11][d12] and moves his soldiers."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring them home."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objective of the game is to bring your soldiers home ['Points [1] to [6]'], and once all of them are home, bear them off [0]."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to move soldiers next."])}
      },
      "b2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's the Bar!"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been [captured]. Learn how to escape from the [Bar]."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent has captured two soldiers and sent them to the bar. Release them."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bar is the column in the middle of the board ([#B0] and [#B1]). It holds soldiers that have been ['captured']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two ['captured'] soldiers in this board [s11][s11]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers in the Bar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has soldiers in the opponent’s bar, he ['must'] release them before he can make any other move."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll to Escape"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You escape from the bar entering the adversary's home from its farest place, as shown with the dice."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to escape."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this case, positions [19][dr6] and [20][dr5] are blocked, and the rest are open. Position [!22][d13] has a blot."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can release only one soldier now, at position [!21][d14]. Position [20][d15] is blocked."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because you left a soldier in the bar [B1], you can't use the other die to move. You will have to wait. Confirm your move."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again the dice"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can land at position [!22][d13], and capture your opponent. Once all your soldiers are off the bar, you can move [d16] with any soldier."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awesome. Now confirm."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free to move!."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Now your soldier can keep running free. You are all set."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the next chapter, you will learn how to bear off."])}
      },
      "b3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off all your soldiers"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn the rules of ['bearing off'] your ['soldiers']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of your soldiers are inside your home. Now you can ['remove '] them from the board in a process called ['bearing off ']."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point [!0]"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To bear off your soldiers, you move then outside the board, to ['point [!0]']"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land exactly in [!0]"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Soldier has to move the ['exact'] number of ['spaces'] to land there."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move from higher points."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is no soldier at the point were the movement should begin, and there are soldiers in a ['higher point'], you must move those soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off from the highest point"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are no soldiers in a ['higher point'], you can and must move the soldier in the highest point, to point [!0], bearing it off."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Situation"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here, your soldier in the highest point is in point [5]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d16]."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], less than [6], you bear off from [5]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d15]."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You land exactly at [0] from [5]. You can bear off."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d14]."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], more that [4], you have to move from the higher point, from [5] to [1]."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d13][d12][d11]."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose to bear off a soldier, or make a valid move inside the board."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's do This. Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have soldiers on [6], but being [5] the highest position you have, you can bear off one soldier. The [d14], you will have to move."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have 3 soldiers in position 1. You can bear them off, but you can also move some soldiers from behind."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your soldiers are now way before position [6]. bear them off."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have all your soldiers out, you have finished the game."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And this ['ends'] the Backgammon's tutorial. You can go and practice against the server, or check out more advanced tutorials in the main menu."])}
      },
      "a1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s Backgammon"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how both games are the ['same'], but way ['different']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with this tutorial instructions to learn how Abak improves its father."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the classic backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[!15] equals soldiers for each team"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Abak'] arranges its first position a little differently. It starts with [!4] soldiers in the back."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Classes"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And, off course, adds ['classes'] of soldiers"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same Rules"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rules of Backgammon apply to Abak. But each class adds its set of additional rules."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General [s21]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves in both directions"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↶ [s21] ↷"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid [s41]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizes the opponent."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s41] = 🔒 "])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards [s31]"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More powerful than the rest"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s31] ≅ [s11]+[s11]"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded [s61]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves odd numbers "])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s61] ↷ [d11] [d13] [d15]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded [s51]"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves even numbers "])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s51] ↷ [d12] [d14] [d16]"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having different classes of soldiers not only adds the soldier's rules to the game. It also affects deeply its ['mechanic's]. "])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you have an example. Points [19] and [17] have the same classes of soldiers, but in different order. Meaning the height of a soldier in a point matters, so it adds a new Axis, [!Y]"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order of Movements"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This also means that the movement's order matters in the game, and could change the result of a turn drastically ."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the guard!."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your regular soldier first [s11], then your druid [s41], to capture the guard [s32]."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture the guard. Try again."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Captured!."])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have captured the guard [s32]."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the order."])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would have happened if you had moved the druid [s41] first?"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilize the guard!."])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your druid first [s41], then your regular soldier [s11], to trap the guard [s32]."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not what expected"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to trap the guard [s32] with your druid [s41]."])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Trapped"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  As you can see, a different outcome for the same move but made in a different order."])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about each class in the next chapters."])}
      },
      "a2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['druid']. The most feared soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['druid'] is a powerful tool that needs practice to master. It ['immobilizes] the opponent's soldiers instead of capturing them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immobilized"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its power is only present in the ['outer-board'], where he can immobilize soldiers."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captured"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inside ['homes'], the druid behave as a ['regular'] soldier. It ['captures'] soldiers and sends them to the bar."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move both [d14] and [d12] with the ['druid'], to capture the ['regular'] soldier, and immobilize the ['guard']."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't make both actions."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture a soldier [s12][22][d12] and immobilize the guard [s32][18][d14]. Try again."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, You trapped and captured a soldier!"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now his guard [s32][18] can't move. But he could hit your druid with another soldier. Oh no, it’s his turn!."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid was captured."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is in the bar. Roll to get out."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the bar, and trape its soldier with your druid on [20]."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Soldier Trapped."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is still alone and vulnerable. But hey, what could go wrong?"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaos."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now your druid has a druid over.  You can't move it, but this soldier under it can't move either."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your soldier in [24] to capture your opponent druid, and save yours."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect. Druid is Safe."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid has a soldier immobilized, and is protected, building a blocking point."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not forever."])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have to leave that position and release the soldier someday. More advanced tutorials are available that address the topic."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed this chapter."])}
      },
      "a4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['guards'] strengths and weaknesses.\n"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guard"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The guard is the strongest class. It blocks the position by itself, but has some limitations. You have two of them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onle lonely soldier"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, you have one soldier against two enemy guards. Can you escape?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You rolled [14,15] and can't move because both guards are blocking ([19][d15] [20][d14]). You can try to move a soldier if you like, to see what happens. Click in Confirm to continue."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A wild soldier appears"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards are not invincible. Two soldiers can hit them. Let's add a companion."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attack with both"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To capture a guard, you must land over it, in two consecutive movements, with two soldiers. Let's do this."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, try to hit the guard with both soldiers."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes!. Confirm you are done."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent! That is one way to do it."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because, of course, a guard can hit another guard."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So you don't have to just blindly believe me."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hit them both!."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic. You are done now."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have learned how guards work. "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the expected movement. Try again."])}
      },
      "a3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['General']. The most resourceful soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your ['General'] to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['General'] is resourceful. It can go ['backward'] and ['forward']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[#1] In a turn, it can go only in one direction.<br/>[#2] Only one move backward.<br>[#3] It can't go back if all other soldiers are at home.'"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only once Backwards."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the General goes back, it can no longer move in the turn."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. You can see how it can move to 4 different points. Choose one ['backwards'] (highest points)"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good. Click again. You will see that it can't move anymore. Move the other checker."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward many, but not back."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the General goes forward, it can move every dice number, but can't go back."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not expected."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow the instructions in the messages to complete this tutorial."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. Now choose a point forward."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. It can't go back. But it can continue forward. Move."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the only one away from home."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if all the other soldiers is in its team are home."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if every other soldier is in its team home."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your General, you can see it can go back. Make sure to move with your regular ['soldier [s11]'] to enter your team's home."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. No other team soldier is outside, so it can't go back, only forward."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's it. You have completed this chapter."])}
      },
      "a5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to move the ['odd wounded'] and suffer its restrictions."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded can only move odd numbers: [d11][d13][d15]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that, it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have ['3 rolls'] to jump across your adversary. Choose wisely."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This roll [11,13] allows one valid move, but you can choose which die. Move."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This time, you have an ['even'] number you can't move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great, you have escaped. Roll to finish"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned with your ['odd wounded'] to your home. Great job!."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wounded is now fully trapped, because [d11] lands in [20], [d13] in [18] and [d15] in [16], all blocked. Try again!"])}
      },
      "a6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about the ['even wounded'], predictably nightmare."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['even wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['even wounded'] can only move even numbers: [d12][d14][d16]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictable"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because it always move ['even'] numbers, you can block its path from far away (as you can see now, it won't go farthest than [20])."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play some rolls to escape from there."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this roll, dice [d15] is open but is not an ['even'] number. You can only move [d14] to [20]."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now the ['even wounded'] is fully ['blocked']. No dice roll will get you out of there. But hey, it's the opponent’s turn."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky you!"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[d16] now is open. Cross your finger."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come on!. Don't expect real life to be like this. Now, run."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned to your home with your ['even wounded']. Great job!."])}
      },
      "a7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded at the Bar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when entering from the ['bar']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded captured']. You will get ['4 rolls'] to take them out. Your opponent is not moving."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Escape"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when in the ['bar'], they can enter the board with ['any dice'] value."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Rolls"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[13,12] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your wounded in the Bar [B0], you can see both can land in both positions [22][d13] [23][d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Rolls"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Rolls"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [16,16] ↦ [15,15]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Roll"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [u6,u6] ↦ [15,15]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #4."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to be home with both wounded. Try again!."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You returned home with both wounded. Good Job!."])}
      },
      "a8": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bearing Off"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when ['bearing off']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Bear off'] your ['wounded'] in this exercise, and practice how they behave in this situation."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bear Off"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when they bear off, they can do it with ['any dice'] value, as a regular soldier."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, ['odd wounded [s61]'] is on point [4]. Can bear off (if on top) with [d16][d15][d14]. Can move [d11] and [d13]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['even Wounded [s51]'] is on point [4]. Can ['bear off'] with [d16][d15][d14]. Can move [d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the ['even wounded']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can bear off the ['even wounded']. The ['odd wounded'] can't move."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['odd wounded'] can't move [d12], but can move [d13]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now it can bear off with [d12]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you know how to bear off your ['wounded']. Find more tutorials in the ['medium'] level section."])}
      },
      "g102": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your guards the right way"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your guards are amazing tools, but you might go through an entire game without using their powers."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this tour about Guards, and solve a few puzzles."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards are almost blocks."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They make a difference when alone in the base of the board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Block"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When facing a single adversary, they make an absolute block, if that adversary is not a guard."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] is fully blocked."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Block"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Against many enemies, they could be hit, but with meagerchances. They make a great temporary structure."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] hits with [d25][d25] and [d26][d26]"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are no match for the Druid"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid can trap a guard as with any othe soldier."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s42] is blocked on [6] but can trap in [7]"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you stack them, you might underuse them"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep them atop a block if you want to use them to attack later"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid stacking soldiers on them if possible to maximize your chances and use your full army capabilities"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But when they are at home. Keep it at ground level, so you won’t leave blots when bearing off."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your worst-case scenario."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block the Odd Wounded."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the right move to fully block the wounded"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded [s61] is not fully blocked, try again."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made a full block to retain the Odd Wounded."])}
      },
      "bst": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Moves"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon has been cracked. There is concens about the best moves for the opening roles."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the wisest decisions on opening moves, to finish this tutorial."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanne is a move of two different soldiers, from two different points, to the same destination."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is maybe the best possible opening move. Play your best."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to make a block on point [7]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have created an vital block."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Golden Point."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a very important position."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in the Golden Point of your home. An importan position to guard."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another important point."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape moves"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are other not-so-awesome moves at the start. You won't produce a block with them"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon more opnings!."])}
      },
      "bp": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a Prime and block the enemy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Prime is an structure of six consecutive blocks. Trap your enemy with one"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice and create a prime to trap your opponent inside your home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold it Back"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An important tactical objective of backgammon is to hold your opponent back, trapping it in your home."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a fort"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent soldier is trapped back, but you have some blots to cover. If your opponent rolls [d24] or [d22], he will hit and may run. "])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a move that will make six consecutive blocks and prevent his escape"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent can escape."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left a blot on your home and your opponent can escape. Try again and make a full block."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime. A structure of 6 blocks that fully traps soldiers. Very Good!."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full House."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also build a prime fully in your home, and have captured soldiers that can't get off."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture your opponent, and close your home."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't fully close your home."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to have built 6 blocks inside your home and fully trap a captured enemy. Try Again."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime inside your home. Very Good!."])}
      }
    },
    "common": {
      "whitehome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Home"])},
      "blackhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black Home"])},
      "outerboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outside"])},
      "movesleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must make all valid moves."])},
      "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
      "gotit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You got it!"])},
      "bettermove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a better move"])},
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
      "chcompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])}
    }
  },
  "ui": {
    "game": {
      "alerts": {
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun Bitti"])},
        "notyourtrun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra Sizde Değil"])},
        "opponentchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pul sizin değil"])},
        "nomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pul hareket edemez"])},
        "noavailabledice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oynamak için zar kalmadı. Geri al veya turunu bitir."])},
        "movelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hala oynanacak hareketleriniz var"])},
        "upperchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her sütunun yalnızca üstteki pulunu hareket ettirebilirsiniz"])},
        "betterpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli hareketlerin maksimum miktarını oynamalısınız. Daha iyi bir yol var."])},
        "ckeckersatbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar'da pullarınız var. Önce onları içeri alın."])},
        "guardsemicaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pulu Muhafızı vurmak için kullanmalısınız. Hareket edemez."])},
        "cantbearoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm takımınız evde olana kadar pulları çıkaramazsınız."])},
        "cantbearoffback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pulları çıkaramazsınız, arkada diğerleri var."])},
        "notback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pul geri gidemez"])},
        "onlyoneback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General zaten hareket etti, geri gidemez."])},
        "wentbacknomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General geri giderse, artık hareket edemez."])},
        "nomovebackteamhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm diğer pullar evdeyse General geri gidemez"])},
        "onlyodd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pul yalnızca tek sayılarla hareket eder"])},
        "onlyeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pul yalnızca çift sayılarla hareket eder"])},
        "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her sütunda yalnızca beş pul istifleyebilirsiniz"])},
        "adversaryspoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakibinizin 2 pulu varsa bir konuma hareket edemezsiniz"])},
        "nojane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu muhafızı vurmak için bir kombinasyon yapamazsınız"])},
        "nojanenear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muhafız iki muhafız olmayan asker tarafından vurulabilir, ancak diğer askerleriniz ulaşamıyor."])},
        "nomoremoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başka hareketiniz yok. Zarları alın."])},
        "checkertraped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pul druid tarafından tuzağa düşürüldü"])},
        "musfinishjane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir muhafızın üzerindesiniz, ancak onu vurmayı bitirmediniz. Hareketi bitiremezsiniz."])},
        "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarları at"])},
        "nomovesturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. Hareket yok."])},
        "endguardmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketi muhafızın üzerinde bitirmelisiniz"])},
        "notyourcube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küp sizin değil"])},
        "cubebeforeroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece sıranızda, zar atmadan önce ikiye katlayabilirsiniz."])},
        "cubemaxvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küp maksimum değerinde"])},
        "nomovestoundo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri alınacak hareket yok"])},
        "notips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İpucu yok"])},
        "youraretimetraveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman yolculuğu yaparken hareket edemezsiniz."])},
        "onlyatend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hareketi yalnızca oyun bittiğinde veya temel seviyelerde oynarken analiz edebilirsiniz."])},
        "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlev kullanıcınız için devre dışı bırakıldı."])},
        "cubenotpractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küp, alıştırma modunda etkin değil"])},
        "noanalyzepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz alıştırma modunda analiz edemiyorum"])},
        "noanalysestake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Bahisli Maçta, oyun bitene kadar hareketleri analiz edemezsiniz."])},
        "matchlosspoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELO puan kaybı"])}
      },
      "help": {
        "regularsoldier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Asker"])},
        "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım almak için bir askere tıklayın"])}
      },
      "messages": {
        "yourturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra sizde. Zarları atın."])},
        "nowcanmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi hareket edebilirsiniz."])},
        "serversturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucunun Sırası"])},
        "waintingfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleniyor"])},
        "torolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zar atmak için"])},
        "toplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oynamak için"])},
        "waintingforopponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakip bekleniyor"])},
        "secconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saniye"])},
        "drawingboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahta Çiziliyor"])},
        "nologs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz Kayıt Yok"])},
        "checkercolorschanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pul Renkleri Değiştirildi"])},
        "fullscreenrequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tam Ekran Değiştirildi"])},
        "showpointsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Görünürlüğü Değiştirildi"])},
        "allowinvitationsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet İzni Değiştirildi"])},
        "allowvisitorsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziyaretçi Görünürlüğü Değiştirildi"])},
        "clicklogformore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir turdaki bilgiye zaman yolculuğu yapmak ve daha fazla bilgi görmek için bir kayıt konumuna tıklayabilirsiniz."])},
        "requestmovestats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketinizi Sinir Ağı ile karşılaştırmak için o turun istatistiklerini isteyebilirsiniz."])},
        "logdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur # , Zar Atışı, # Hareketler, Tur Süresi saniye olarak."])},
        "analysisindicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiz Göstergeleri"])},
        "analysisw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyunu Kazanma Olasılığı"])},
        "analysisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyunun 1, 2 veya 3 puanla bitme olasılığı."])},
        "analysisequity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşitlik"])},
        "analysiswequitydescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oyunun gelecekteki puan değeri yansıtılır"])},
        "analysiswequityej1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her iki oyuncu berabere kalırsa"])},
        "analysiswequityej2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oyuncu 1 puan kazanırsa"])},
        "analysiswequityej3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oyuncu 2 puan kaybederse"])},
        "mathwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matematiksel olarak"])},
        "analysismovelost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turda kaybedilen Eşitlik ölçüsü (geçerli en iyi hareketi seçtiyseniz 0)"])},
        "analysismovemoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zar atışı için geçerli hareketlerin miktarı"])},
        "analysisgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyunu Analiz Ediyor"])},
        "analysisgamewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bir süre alacak, lütfen bekleyin"])},
        "clickturndetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur detaylarını görmek için bir noktaya tıklayın."])},
        "harmlessdisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zararsız bağlantı kesildi"])},
        "exaustedinvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet olanakları tükendi. Burada başka bir kullanıcının bağlanmasını bekleyebilir veya bilgisayara karşı oynayıp bir davet bekleyebilirsiniz."])},
        "exitnomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Ne Yazık Ki!.</span> Sunucuya karşı oynarken bir davet bekleyebilirsiniz."])},
        "notyetanalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahta analizini istemek için en az 20 tur gerekir."])},
        "playersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimiçi Oyuncular"])},
        "invitationsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet Gönderiliyor"])},
        "invitationdeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet reddedildi"])},
        "invitationacepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet kabul edildi"])}
      },
      "dialogs": {
        "askdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyunu ikiye katlamak mı?"])},
        "oppentdoubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakip ikiye katlama veya hiç teklif ediyor"])},
        "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkiye Katla"])},
        "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddet"])},
        "acept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul Et"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])}
      },
      "action": {
        "nextgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki Oyun"])},
        "newmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Maç"])},
        "surrender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslim Ol"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])},
        "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çık"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talep Et"])},
        "dontclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talep Etme"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İncele"])},
        "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katıl"])},
        "attemptdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkiye Katlama Denemesi"])},
        "doubleaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul Edildi"])},
        "doublerejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddedildi"])},
        "startroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarları Atarak Başla"])},
        "startequals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşit Zar, tekrar."])},
        "startstarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun Başlıyor"])},
        "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiz Et"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duraklat"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrarla"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam Et"])}
      },
      "ialevel": {
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolay"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzman"])}
      },
      "message": {
        "ifyoulose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğer kaybederseniz"])},
        "ifyouwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğer kazanırsanız"])},
        "youwilllosematch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maçı kaybedeceksiniz"])},
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun Bitti"])},
        "matchover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maç Bitti"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maçı Talep Et"])},
        "nogameslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlarda oyun yok"])},
        "logtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun Kayıtları"])},
        "confirmdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkiye katlamak istediğinizden emin misiniz?"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maçı Değiştir"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maçı Tekrarla"])},
        "analysysnotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu araç çalıştırmak için çok pahalıdır ve bir süre kullanılamayacak. Yine de tur kayıtlarından tekli hareketleri analiz edebilirsiniz."])},
        "practiceover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıştırma Bitti"])}
      },
      "subject": {
        "turnslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur Kayıtları"])},
        "gameevolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun Gelişimi"])},
        "turns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turlar"])},
        "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahta"])},
        "accelerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlandırıcılar"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenekler"])},
        "p2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsan"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurallar"])},
        "matchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maç Bilgileri"])},
        "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşitlik"])},
        "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazanan"])},
        "newvisitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyununuza yeni bir ziyaretçi var."])}
      },
      "tag": {
        "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efsane"])},
        "playersmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyuncunun Hareketi"])},
        "novalidmoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli Hareket Yok"])},
        "forcedmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorunlu Hareket"])},
        "gamedoubled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyun İkiye Katlandı"])},
        "tieline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beraberlik Çizgisi (0)"])}
      }
    },
    "preferences": {
      "acelerators": {
        "autoDoneOnLastValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son hareket otomatik olarak tamamla."])},
        "autoRoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıranızda otomatik olarak zar at."])},
        "autoMoveOnOneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece bir yasal hareket varsa, dokunulan askeri otomatik olarak hareket ettir."])},
        "autoDoneOnNoValids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zar atışı geçerli hareket üretmezse turu otomatik olarak tamamla"])},
        "autoRollForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olası küp eylemleri olsa bile otomatik zar atmayı zorla."])}
      },
      "messages": {
        "showAdvices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İpuçlarını Göster"])},
        "showInvalidMoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz hareketleri çiz"])},
        "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oynanış mesajlarını göster"])},
        "playSounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesleri Çal"])},
        "allowVisitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziyaretçilere İzin Ver"])},
        "allowInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davetlere İzin Ver"])},
        "showPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozisyon numaralarını göster"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyuncu Görünürlüğü"])},
        "allowedGameTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davetlerde İzin Verilen Türler"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercihler"])}
    },
    "lobby": {
      "messages": {
        "connectingtoengine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motora Bağlanılıyor"])}
      },
      "welcome": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoşgeldiniz!"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir misafir kullanıcı oluşturabilir veya kalıcı bir kullanıcıya sahip olmak için e-posta adresinizi girebilirsiniz."])},
        "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misafir olarak oyna"])},
        "guest_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misafir olarak oynamak için buraya tıklayın. Bu kullanıcı yalnızca bu istemciye bağlı olacaktır."])},
        "changelater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcıyı kaydetmek için daha sonra bir e-posta ekleyebilirsiniz."])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap veya Kaydol"])},
        "create_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut bir hesabı oluşturmak veya geri almak için e-posta adresinizi girin"])}
      },
      "auth": {
        "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı e-posta adresinize gönderilen 4 haneli kodu girin"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iptal"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doğrula"])},
        "useemailtosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcıyı talep etmek için bir e-posta hesabı ekleyin."])}
      },
      "action": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["çıkış yap"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sil"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Dön"])},
        "qrlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Giriş"])},
        "loginguest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misafir"])},
        "loginemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])}
      },
      "confirm": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış yapmak sadece bu cihazın çerezini silecek. Kayıtlı geçerli bir e-posta hesabınız varsa hala bu kullanıcıya erişebilirsiniz. Devam etmek istediğinizden emin misiniz?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcı veritabanından tamamen silinecek ve sonsuza kadar erişilemez olacak. Devam etmek istediğinizden emin misiniz?"])}
      },
      "user": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Eylemleri"])}
      },
      "message": {
        "useqr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcıyla giriş yapmak istediğiniz herhangi bir cihazda bu QR Kodunu okuyun."])},
        "closesession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu cihazdaki oturumu kapat"])},
        "logindifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir cihazda giriş yap"])},
        "permanentremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı kalıcı olarak sil"])},
        "notabakertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaker Değil"])},
        "notabakertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davetler göndermek veya halka açık oyunlar oluşturmak için bir <b>Abaker</b> olun. Veya <b>arkadaş</b> edinin ve onları oynamaya davet edin."])},
        "howtoabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abaker</b> olmak için, <b>5 puanlık</b> bir maçı sunucuya karşı <b>Gelişmiş</b> veya <b>Uzman</b> modunda kazanın veya <b>alıştırma</b> seviyelerini tamamlayın."])},
        "stakeforabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELO puan bahisleri yalnızca Abak Oyunlarında yapılabilir."])},
        "abakerrequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki seviyeyi açmak için $$(left)$$ maç kazanın."])}
      },
      "subject": {
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahis"])}
      }
    },
    "message": {
      "newmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Mesajlarınız Var"])},
      "retryingerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Deneniyor"])}
    }
  }
}