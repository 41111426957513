const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

export function clickMessageDismiss(event) {
    let postData = { messageID : event.id };
    this.axios.post(
      `${WEBPROTOCOL}${APIURL}:${APIPORT}/messages/dismiss/`,
      postData).then((response) => { 
          console.log("message dismissed",response);
          this.uIstate.messages.messages.splice(-1);
          if (this.uIstate.messages.messages.length==0) this.uIstate.messages.show = false;
      }).catch(console.error); 
}

export function clickMessageAction(event) {
    if (event.action=="changeMenu") {
        this.changeMenu({menu:event.data.menu,data:event.data.data});
    }
}
