export default {
  "ui": {
    "game": {
      "alerts": {
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Game is over"])},
        "notyourtrun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not your Turn"])},
        "opponentchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker is not yours"])},
        "nomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker does not move"])},
        "noavailabledice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more dice to play. Undo or Finish your turn."])},
        "movelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You still have moves to play"])},
        "upperchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can move only the upper checker of each column"])},
        "betterpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must play the maximum ammount of valid moves. There is a better path."])},
        "ckeckersatbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have checkers at the bar. Enter them first."])},
        "guardsemicaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to use this checker to hit the Guard. It can’t move."])},
        "cantbearoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't bear checkers off until all your team is at home."])},
        "cantbearoffback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can’t bear off this checkers, you have others in the back."])},
        "notback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker can't go back"])},
        "onlyoneback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General has already moved, it can’t go back."])},
        "wentbacknomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the General goes back, it can no longer move."])},
        "nomovebackteamhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can not go back if all other checkers are at home"])},
        "onlyodd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker only moves odd numbers"])},
        "onlyeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker only moves even numbers"])},
        "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only stack five checkers on each column"])},
        "adversaryspoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not move to a position if your opponent has 2 checkers"])},
        "nojane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not make a combination to hit this guard"])},
        "nojanenear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The guard can be hit by two non-guards, but you don’t have other soldiers at reach."])},
        "nomoremoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have more movements. Pick up the dice."])},
        "checkertraped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker is trapped by the druid"])},
        "musfinishjane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are over a guard, but didn’t finish hitting it. You can’t finish the movement."])},
        "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice"])},
        "nomovesturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups. No movements."])},
        "endguardmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must end the move over the guard"])},
        "notyourcube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cube is not owned by you"])},
        "cubebeforeroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only double in your turn, before rolling."])},
        "cubemaxvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cube is in it’s max value"])},
        "nomovestoundo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No moves to Undo"])},
        "notips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tips available"])},
        "youraretimetraveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cant move while time travleing."])},
        "onlyatend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only analyse a move when the game is over, or while playing in basic levels."])},
        "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This function is disabled for your user."])},
        "cubenotpractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cube not enabled in practice mode"])},
        "noanalyzepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Can't analyse in Practice mode yet"])},
        "noanalysestake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can’t analyse moves in a Match that has a Stake, until the game is finished."])},
        "matchlosspoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELO points loss"])}
      },
      "help": {
        "regularsoldier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Soldier"])},
        "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a soldier to get help"])}
      },
      "messages": {
        "yourturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s your turn. Roll the dice."])},
        "nowcanmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can move."])},
        "serversturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server’s Turn"])},
        "waintingfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for"])},
        "torolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to roll the dice"])},
        "toplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to play"])},
        "waintingforopponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for opponent"])},
        "secconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secconds"])},
        "drawingboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawing the Board"])},
        "nologs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Logs Yet"])},
        "checkercolorschanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkers Color Changed"])},
        "fullscreenrequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FullScreen Changed"])},
        "showpointsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points Visibility changed"])},
        "allowinvitationsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation Allowance Changed"])},
        "allowvisitorsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor Visibility Changed"])},
        "clicklogformore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can click on a log position to time travel to that turn, and see more information."])},
        "requestmovestats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can request stats of that turn to compare your move with the Neural Network."])},
        "logdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn # , Dice Roll, # Moves, Turn Time in secconds."])},
        "analysisindicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Indicators"])},
        "analysisw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probability of Winning the game"])},
        "analysisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probability of the game ending in 1,2 or 3 points."])},
        "analysisequity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity"])},
        "analysiswequitydescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reflect the future value in points for the game"])},
        "analysiswequityej1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if both players are in a tie"])},
        "analysiswequityej2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if the player wins 1 point"])},
        "analysiswequityej3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if the player loses 2 points"])},
        "mathwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mathematically"])},
        "analysismovelost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meassure of Equity lost in the turn (0 if you chose the best valid movement)"])},
        "analysismovemoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammount of valid moves for the roll"])},
        "analysisgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysing the game"])},
        "analysisgamewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this will take a while, please wait"])},
        "clickturndetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a dot to see turn details."])},
        "harmlessdisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["harmlessdisconnect"])},
        "exaustedinvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation possibilities are exausted. You can wait here for another user to connect or quit and play angaist the computer and wait for an invitation."])},
        "exitnomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>So Sad!.</span> You can wait for an invitation while you play against the server."])},
        "notyetanalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need at least 20 turns to request the board analysis."])},
        "playersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players Online"])},
        "invitationsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending Invitation"])},
        "invitationdeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation declined"])},
        "invitationacepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation acepted"])}
      },
      "dialogs": {
        "askdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double the game?"])},
        "oppentdoubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent offers double or nothing"])},
        "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double"])},
        "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
        "acept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "action": {
        "nextgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Game"])},
        "newmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Match"])},
        "surrender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surrender"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quit"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim"])},
        "dontclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t Claim"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
        "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
        "attemptdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempt Double"])},
        "doubleaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
        "doublerejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
        "startroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolls Dice to Start"])},
        "startequals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equal Dice, repeat."])},
        "startstarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Starts"])},
        "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
      },
      "ialevel": {
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])}
      },
      "message": {
        "ifyoulose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you lose"])},
        "ifyouwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you win"])},
        "youwilllosematch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will lose the match"])},
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Over"])},
        "matchover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match Over"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Match"])},
        "nogameslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No games in Log"])},
        "logtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games Log"])},
        "confirmdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to double ?"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Match"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Match"])},
        "analysysnotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tool is two expensive to run, and will not available for a while. You can still analyse single moves from turns log."])},
        "practiceover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice Over"])}
      },
      "subject": {
        "turnslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turns Logs"])},
        "gameevolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Evolution"])},
        "turns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turns"])},
        "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board"])},
        "accelerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerators"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
        "p2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules"])},
        "matchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match Information"])},
        "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity"])},
        "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winner"])},
        "newvisitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a new visitor in you game."])}
      },
      "tag": {
        "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
        "playersmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players Move"])},
        "novalidmoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Valid Moves"])},
        "forcedmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forced Move"])},
        "gamedoubled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Doubled"])},
        "tieline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tie Line (0)"])}
      }
    },
    "preferences": {
      "acelerators": {
        "autoDoneOnLastValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish turn automatically at last move."])},
        "autoRoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Roll dice when your turn."])},
        "autoMoveOnOneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aut-move when only one legal move is aviable on a touched soldier"])},
        "autoDoneOnNoValids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish turn automatically if roll produces no valid moves"])},
        "autoRollForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force autoroll even on possible cube actions."])}
      },
      "messages": {
        "showAdvices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Tips"])},
        "showInvalidMoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw invalid movements"])},
        "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show gameplay messages"])},
        "playSounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Sounds"])},
        "allowVisitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Visitors"])},
        "allowInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Invitations"])},
        "showPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show position numbers"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Visibility"])},
        "allowedGameTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed Types in invitations"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])}
    },
    "lobby": {
      "messages": {
        "connectingtoengine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting to Engine"])}
      },
      "welcome": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create an guest user, or enter your email to have a permanent one."])},
        "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play as guest"])},
        "guest_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To play as a guest, click here. This user will be bound to this client only."])},
        "changelater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add an e-mail later to save this user."])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login or Sign In"])},
        "create_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create or retrieve an existing account, enter your e-mail address"])}
      },
      "auth": {
        "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the 4 digit code sent to your registered e-mail"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validate"])},
        "useemailtosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an email account to claim this user."])}
      },
      "action": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
        "qrlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Login"])},
        "loginguest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
        "loginemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])}
      },
      "confirm": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout will just delete this device cookie. You can still access this user if you have a valid e-mail account registered. Are you sure you want to continue ?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user will be fully erased from the database and inaccesible forever. Are you sure you want to continue ?"])}
      },
      "user": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Actions"])}
      },
      "message": {
        "useqr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read this QR Code on any device you want to login with this user."])},
        "closesession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close session in this device"])},
        "logindifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login in a different device"])},
        "permanentremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanently remove user"])},
        "notabakertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Abaker"])},
        "notabakertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become an <b>Abaker </b> to get access to send invitations or create public games. Or you can make <b>friends</b> and invite them to play."])},
        "howtoabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To become an <b>Abaker </b>, you must  win a <b>5 points</b> match against the server in <b>Advanced</b> or <b>Expert</b> mode, or complete the <b>practice</b> levels."])},
        "stakeforabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELO points stakes can only be made in Abak Games."])},
        "abakerrequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Win $$(left)$$ matches to unlock the next level."])}
      },
      "subject": {
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake"])}
      }
    },
    "message": {
      "newmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have new Messages"])},
      "retryingerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrying"])}
    }
  },
  "ayuda": {
    "generales": {
      "delsoldado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Rules"])},
      "situaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situations"])},
      "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a soldier to get help"])},
      "highest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must move the higest checker of each point first."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't move a checker, if it has another checker on top of it."])}
      },
      "5max": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There’s a 5 checker limit per point."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the field positions, you can only stack five checkers per point."])}
      }
    },
    "piedras": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldier"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["same as classic Backgammon"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Soldier is a Normal checker of Classic Backgammon. Each team has 9."])}
      },
      "2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moves backward and forward"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can move backward and forward with some limitations. It can travel in only one direction per turn, and can go backward only once."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it goes forward, it can't move back on the same turn"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it goes backward, it can no longer move during the turn."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It can't move backwards if all other soldiers of his team is in the team house."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After moving from the bar, it CAN move backward."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it moves forward"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it moves backward"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can't go forward"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can't go backward"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can go forward"])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more powerful than the rest"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards are like two soldiers together, but can be trapped by the druid and hit by other guards or other soldiers if both can hit. Each team has 2."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocks like two soldiers"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can be trapped by the druid"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can be hit by other guards"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["two soldiers can hit it"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To hit a guard with two (non-guard) soldiers, you must be able to hit it with <b>both of them</b> in <b>consecutive movements</b> during a turn."])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["traps and holds"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid can trap an adversary if it’s alone in midfield. The trapped soldier can't move while the druid is over it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid has trapped a soldier"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid is safe"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid is trapped by druid"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid will be hit"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inside any house, druid behaves as a regular soldier."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid can trap a single soldier"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid can’t trap a blocked column"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["midfield"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid traps"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["black house"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["white house"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["druid hits"])}
      },
      "5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["only plays <span>EVEN</span> numbers"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The even Wounded can only move if the traveled distance is an even number, or any number if it’s leaving the bar, or bearing off."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded can only play EVEN numbers"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When leaving the bar, it can play any number."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When bearing off, it can play any number."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While moving between points (<span>triangles</span>), both will always be of the <span>same color</span>. <span>Hence the symbol</span>."])}
      },
      "6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["only plays <span>ODD</span> numbers"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded can only play ODD numbers"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When leaving the bar, it can play any number."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When bearing off, it can play any number."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded can only move if the traveled distance is an odd number, or any number if it’s leaving the bar, or bearing off."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While moving between points (<span>triangles</span>), both will always be of <span>different colors</span>. <span>Hence the symbol</span>."])}
      },
      "56": {
        "leavingorcaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When leaving the bar, or bearing off, it can move any number."])}
      }
    }
  },
  "intro": {
    "content": {
      "tituloheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> is a Backgammon variant that adds <span>classes of checkers</span> to the game, each with its own set of features and <span>unique behavior</span>."])},
      "isaboardgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> is a two-player abstract strategy board game. You will need luck and your best tactics if you want to win in this remake of the classical <span>Backgammon</span>."])},
      "backgammon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon<span>²</span>"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution </span>  uses the same rules of backgammon, but adds 5 classes of soldiers. This small change provides outstanding consequences."])},
      "gotoabakme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can learn and play online here."])},
      "abakapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will find a fully featured webapp where you can play against a strong CPU opponent or real people."])},
      "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at our social media accounts"])},
      "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
      "printrules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find stickers templates for your board, a printing version of the rules, and other tools here:"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "nonprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> is an epic, non-profit, one-man effort to spread these amazing rules to humanity. <br> Invented, developed, maintained and financed by <span>Samy Garib</span>, a Chilean Software Developer."])},
      "sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let"])},
      "entonces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then"])},
      "classbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a class-based backgammon variant"])},
      "quees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a class-based backgammon variant"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding classes changes drastically the game. The vertical position of a checker matters and therefor the movement's order. <span>Abak Evolution</span> adds a new dimension to <span> Classic Backgammon </span>"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn"])},
      "moredetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more details"])},
      "timagen": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a classist backgammon, just like you"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backgammon on steroids"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Backgammon)²"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon, for grown ups."])}
      },
      "abakisto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak is to BackGammon as Chess is to Checkers"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules"])}
    },
    "privacy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policies"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information Collection, Use, and Sharing"])},
      "content": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is an online platform to play the Abak Rules, a backgammon's Rules expansion."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is a one-man, non-professional, non-proffit effort to spread this rules."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform  is free, as all other Abak Evolution Assets. "])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Ads free, and with optional registration capabilities."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not perform any external activity with users's data. User Data is only used to store the user state and ranking."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not share user's information with other parties."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relies only on external login platforms sush as facebook, Google and Twitter, using Google's Firebase Auth Service as a conector hub."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will not publish any information on your social accounts, neither will read your contacts information. "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps on social platforms will only collect your personal avatar an name."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uses cookies to store your account related data. External login providers use their own cookies. "])}
      }
    }
  },
  "FAQ": {
    "preguntas": {
      "1": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Abak Evolution more <span>complex</span> than Backgammon?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The honest answer is yes, but in exchange, you have a more entertaining and dynamic game. By adding classes to the game, you give an identity to each soldier and have to think of its own features. Additionally, Abak has much more combinations for a dice roll, because the movement's order might affect its outcome."])}
      },
      "2": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How <span>different</span> is Abak Evolution to Classic Backgammon?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The base rules are almost the same, yet adding classes changes drastically the game. <ul>     <li><span>A new dimension:</span> The vertical position of the checker matters. This creates a new dimension: the Y-axis. Think of it this way: In Abak you can't just say that a point has 3 white checkers, you need to specify each, in order.</li>     <li><span>More combinations:</span> Each Backgammon's position has (3.346.200)² possible Abak Evolution's positions.</li>     <li><span>Checkers have identity</span>: You have to take care of its individual capacities.</li>     <li><span>Movement's order matters:</span> If you move a checker before another one, it might change dramatically the outcome of the roll.</li> </ul>"])}
      },
      "3": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I learn to play Abak Evolution?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the resources in this web to learn how to play, from Backgammon to Abak. The game UI is a good resource since it highlights the rules. "])}
      },
      "4": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I need to know how to play Backgammon to learn Abak Evolution?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. You need at least basic knowledge of Classic Backgammon's rules. "])}
      },
      "5": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why is there a limit of 5 checkers per point?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although this rule exists in Classic Backgammon, it's not often used. It adds a lot of instability, especially when bearing off. Abak uses it because I love it, and also for a practical reason: checker visibility."])}
      },
      "6": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Abak Evolution slower or longer than Backgammon?"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you are familiarized with the different soldiers, the game speed is fairly similar to Classic Backgammon. Because the general can move backward, sometimes the game is longer (turns-wise) than Backgammon."])}
      }
    },
    "header": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might have a question"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find it here"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])}
    }
  },
  "reglas": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Rules"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding classes of checkers has consecuences"])},
      "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each point can only stack 5 checkers."])},
      "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The highest checker of each point must be moved first."])},
      "porque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two simple general rules are added to Backgammon's."])},
      "startpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Starting Position"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding classes changes drastically the game. The vertical position of a checker matters and therefor the movement's order. <span>Abak Evolution</span> adds a new dimension to <span> Classic Backgammon </span>"])},
      "mismasreglas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution </span>  uses the same rules of backgammon, but adds 5 classes of soldiers. This small change provides outstanding consequences and requires two new evident and natural rules."])},
      "thisguys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The same rules of Backgammon, with this guys."])},
      "addsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It adds classes of soldiers, with different features each."])},
      "startposcontenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span> Abak Evolution’s </span> starting position is very similar to Backgammon’s, but adds the <span>classes </span>and has 4 checkers at the back. Nackgammon’s style."])},
      "ttd_titulo": null,
      "ttd_texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just image the posibilities"])}
    }
  },
  "learn": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Center"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources to understand and learn Abak Evolution"])},
      "contenido1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A handfull of resources that will let you play Abak Evolution"])},
      "videoT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Channel"])},
      "videoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at the Youtube channel, with an introductory video, some and som short tutorials."])},
      "versusT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s BackGammon"])},
      "versusC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A take on how much different is Abak from the Classic Backgammon"])},
      "PDFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Rules"])},
      "PDFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download a ready to print set of files for your offline needs"])},
      "reglasT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules Book"])},
      "reglasC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The complete rules of Abak evolution in a single page. A reference tool for printing or checking in your phone"])},
      "tablaT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For your board"])},
      "tablaC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A set of stickers for your phisical board"])},
      "specs": {
        "specs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications"])},
        "typeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "typeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstract<br>Strategy"])},
        "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
        "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players"])},
        "complexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complexity"])},
        "mechanics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechanics"])},
        "turnbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn based"])},
        "dicedriven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dice driven"])},
        "perfectinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect information"])},
        "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficult"])}
      }
    },
    "faq": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might have a question"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find it here"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])}
    },
    "files": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An assorted set of utilitarian files"])}
    },
    "blog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Blog"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A list of articles about different subjects related to Abak, mostly for SEO purposes. But hey, if you have time."])}
    },
    "introvideo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch the introduction video"])}
    }
  },
  "about": {
    "history": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Abak Evolution"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief rant."])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I never sat down to try to invent new games, this just came out. I was playing Backgammon with a friend, kind of bored out by the monotony of the rules, and this idea popped out of my mind. It was on Easter Island, September of 1999. "])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 20 years have passed since that moment, and I have invested thousands of hours developing: the rules, the mechanics, the app, the website, the marketing contents, etc."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do this for free because I believe in my heart that this game is the successor of Backgammon as you know it and if that becomes a reality during my life, the consequences will be more than sufficient as retribution. Because I'm a software engineer I was able to do this all by myself in my spare time, having fun in the process."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When I was developing the WebApp to share my rules with the world, I thought Abak Evolution would be a hit the minute it went online, that Backgammon players would embrace the new rules thinking \"oh my, how this didn't occur to me!\". Surprise, surprise: they didn't. As I'm writing this, four full years have passed since Abak came online, thousand of dollars in paid marketing campaigns, and yet it has at most a couple of hundreds of regular players."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution faces many entry barriers that have proven to be difficult to overcome: "])},
      "g": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is fairly more challenging than Classic Backgammon and has a steep learning curve."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon players are very engaged and love their game, they see Abak as a threat or an intruder. "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many people like to play backgammon against humans, not computers, and you need a critical mass of players to achieve that. "])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have enough resources to scale up communications and content creation."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app is not the state of the art in backgammon experience and lacks a lot of tools advanced backgammon players would like."])}
      },
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So, there are the current fronts of my battle. I work on them in my spare time, prioritizing learning contents, as I think that is the heavier barrier of all. I spend too much time in Artificial Intelligence, because I just love it, even though the engine plays fairly well as of now and there is no need for a stronger player."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is an epic, nonprofit, one-man show."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Samy Garib, I'm a Chilean Software Engineer."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your time."])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "thanks-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledgment"])},
      "rant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rant"])}
    },
    "history1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I learned how to play backgammon when I was a kid, and played a lot with my family and schoolmates."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Later in life, I taught how to play to a lot of my friends. Eventually, I got to a level where I could find no joy playing it without money being involve."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I invented Abak on a trip to Easter Island on August 1999, out of boredom."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first version was very different from this one, and none of my friends kept playing it once I taught them how to. So, it went to the basement of my brain for long years. "])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One day, at work, on 2007, I taught some coworkers how to play backgammon, and when they refused to keep playing against me for money, boredom again lent me to teach them the game I had once invented."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But first I made the changes to make it great (the wounded, and some restrictions)."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was such a success among them. Since then, every day some couple (usually with me, everything has to be said) plays Abak at lunch time at my work."])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These teammates encouraged me to make a software version of the game, but you know life and its priorities."])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So, many years passed until September 2009. I had an accident that gave me two full weeks at home."])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A software was created, full of bugs, and took so much effort and energy, that slept for years again."])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since then, several burst of enthusiasm got me working on it, until I got to a level where I could share it with the world."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I feel I'm so lucky to be good at programming & computers. If I wouldn't I could never had had the opportunity to make this possible, and release a free version for you to get to know it."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So here it is, a one man show (save for the graphics, many thanks to www.vrweb.cl)"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hope you have enjoyed it"])}
    },
    "donate": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
      "hh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MHRS"])},
      "devtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Time"])},
      "onlinesince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Since"])},
      "playedgames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Played Games"])},
      "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Users"])},
      "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings"])},
      "donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Donated So Far"])},
      "otherincomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Incomes"])},
      "expenses_op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Expenses (Montly)"])},
      "adv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
      "devtimeprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development & Content Creation"])},
      "estadisticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
      "necesities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to my time, I have direct expenses in a lot of different topics"])},
      "necesity": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing Development (Videos + Graphics)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing Media (Adwords+Facebook)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DataCenter (Amazon AWS)"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI Development (Desing+Translations)"])}
      },
      "closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you loved the game, and can help, It would be a big big help. Thanks in advance."])}
    },
    "samy": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm a passionate software developer, born in Chile in 1973. As a Palestinian descendent, I was raised with a strong Arabic culture, with Tawle (or Backgammon) being one of the elements of it.                 \n"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I work in my own company since 1999, VRWEB, and although my main role is in marketing and sales, I love to code. Abak is my SandBox."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm the father of three, and the cook of four."])}
    },
    "thanksto": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Throughout the years, I have had much help from people worldwide: translations, technical advice, game-play advice, fan art, etc. "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In a particular category are those that, even though they don't help, have engaged with me and shown such enthusiasm that they are a catalyst to my goals:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last but not least, there is a bunch of people that have worked in amazing  open source projects that form the foundation of the Abak plataform."])}
    }
  },
  "contact": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Abak Evolution"])},
    "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need to contact me use one of these alternatives"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find Abak Evolution in a variety of social networks, and write me there."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or send an email"])}
  },
  "piepagina": {
    "texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> is an epic, non-profit, one-man effort to spread these amazing rules to humanity. <br> Invented, developed, maintained and financed by <span>Samy Garib</span>, a Chilean Software Developer."])},
    "privacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policies"])}
  },
  "lobby": {
    "subject": {
      "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent"])},
      "human": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial"])},
      "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorials"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
      "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends"])},
      "players": {
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
        "invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invisible players"])},
        "last_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "prefered_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefered types"])},
        "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
        "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing"])},
        "atlobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])}
      },
      "friendship": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused"])},
        "friendship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friendship"])},
        "notyet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You dont have friends yet. You can request friendship to online players, from the online players section."])},
        "requestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send friendship request ? "])},
        "newrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a new friendship request"])},
        "onlinefriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Friend"])}
      },
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match "])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "game_visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Visitors"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors"])}
    },
    "alerts": {
      "nogamestojoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No games to join."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon"])},
    "action": {
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
      "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Me"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice"])},
      "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Played"])},
      "won": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Won"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
      "sharein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share in"])},
      "cpypasteshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy & share this link"])},
      "shareabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Abak with your friends!"])},
      "shareboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your game"])},
      "howtoshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tool will create an element for you to share. You can add an optional description"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "contactsocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact me on your prefered soical network"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])}
    },
    "user": {
      "subject": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
        "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
        "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medals"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "howemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At save, you will receive a message with a validation link"])},
        "checkvalidationemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to verify your email. You can try to send the message again."])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])}
      },
      "action": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Again"])}
      },
      "message": {
        "knownguestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are registered for this device only. Use <b>Social Login</b> to create a permanent and multi-device user."])},
        "guestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is bound to this device only. If you want to recuperate this user later,  or continue your games in different devices, provide a valid e-mail."])}
      }
    },
    "messages": {
      "newinvitation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation to play"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you accept, you will resume this match after the other one ends."])},
        "headding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an invitation to join a game against a player"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invitation was issued to all active players. Your response is annonimus."])},
        "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invitation was issued directly to you."])}
      },
      "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matches to join."])},
      "p2pnomatchesoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Options"])},
      "playandwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play against the server and wait for an invitation"])},
      "inviteonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invitations to Online Players plaing against server"])},
      "createhuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a game, and wait for a player to join"])},
      "error": {
        "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something is off, I couldn’t connect to the server."])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can report this incident to"])}
      },
      "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
      "unfinishedgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a game in progress. Finish it, or surrender."])},
      "wontunlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This difficulty level doesn’t add up to unlock the next practice."])},
      "unlocklevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to unlock this practice level."])},
      "inviteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite $$(player)$$ to play."])},
      "nomoreplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more players available to invite."])}
    },
    "hallfame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall of Fame"])}
    },
    "chat": {
      "message": {
        "tooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message to Short"])}
      }
    },
    "message": {
      "choosepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice Level"])},
      "newpracticelevelavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Practice Level Available. Select it in the select box."])},
      "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working. Please wait"])},
      "becomeabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can play <b>Abak Backgammon</b> and <b>Egyptian Backgammon</b> in this App. "])},
      "becomeabakerhowto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To <b>unlock</b> all features you must become an <b>Abaker</b>. Complete the <b>practice</b> levels, or win one abak Match playing against advanced or expert levels."])},
      "welcome": {
        "customize1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize User"])},
        "customize2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your user name and avatar."])},
        "stats1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
        "stats2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out your history as a player"])},
        "hall2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get to know the Bests Abakers"])},
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at these tools sometime."])}
      },
      "caninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can invite available players"])},
      "cantinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can’t invite other players until you become an Abaker"])},
      "noonlineplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no players online"])},
      "onlyabakersorfriendscaninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can’t create invitations to players that are not your friends if you are not Abaker."])},
      "friendship": {
        "requestsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Sent"])},
        "requestexists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request already Exist"])},
        "responsesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Sent"])}
      },
      "emptylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty List"])}
    }
  },
  "hall": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall of fame"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall of fame"])}
  },
  "tips": {
    "general-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General is trapped."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General is very important and cannot move."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because it can go backwards, it’s quite useful to help the wounded come home, and it has a key role in maximizing your chances of defend and build your home. Try to keep it on top and make it available."])}
    },
    "druid-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid is trapped."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid can be gravitating in the game."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it immobilizes an opponent, it will give you a lot of control of the game. Try to keep it on top and make it available."])}
    },
    "tooclose": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid is too close to you home."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The druid is a mortal weapon, but …"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its big power is on midfield. Try not to get to close to your home, because in there, it looses its power and behave as a regular soldier."])}
    },
    "inmobilized-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an immobilized soldier."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your soldier is under your opponent druid, but it is unprotected."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to hit it before your opponent puts another soldier above."])}
    },
    "inmobilized-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an immobilized soldier."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your soldier is under your opponent druid, and you can not hit it."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to catch him to, and contain your game as much as possible."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your general to expend dice going backwards."])}
    },
    "inmobilized-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a mess."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have immobilized his druid, but he also has you."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan this out. You are in Advantage."])}
    },
    "inmobilizing-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have immobilized your opponent soldier"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["His soldier is under your druid,  but your druid is unprotected."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to put another soldier over it to save your position."])}
    },
    "inmobilizing-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have immobilized your opponent soldier."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have immobilized your opponent soldier, and your Druid is protected. Good Job!."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can run and hit on the same roll. Get soldiers at hand for the right moment."])}
    },
    "inmobilizing-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a mess."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have immobilized his druid, but he also has you."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan this out. You are in disadvantage."])}
    },
    "even-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded Trapped"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your even wounded is trapped and can’t move."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to find a way to make your opponent release its position"])}
    },
    "even-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded Almost Trapped"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your even wounded might be trapped soon."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful and try to escape as soon as you can."])}
    },
    "odd-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded Trapped"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your odd wounded is trapped and can’t move."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to find a way to make your opponent release its position"])}
    },
    "odd-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded Almost Trapped"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your odd wounded might be trapped soon."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful and try to escape as soon as you can."])}
    },
    "guard-not-on-floor": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your guard is underutilized"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards can hold a point alone. To capture them, two opponent soldiers must hit them in one turn, which is a very low-probability event."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is best to have them at floor level so that you can utilize their full power."])}
    }
  },
  "pdf": {
    "rules": {
      "generales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These rules are a little change in the game mechanics. Note that one is fairly implicit yet it didn't have to be enforced on classic backgammon, and the other one already exists in some versions of the game."])},
      "intro0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution Backgammon</b> is a Backgammon variant that adds 5 classes of checkers, each with its own set of features and unique behavior. "])},
      "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document explain the <b>Abak Evolution Backgammon</b>'s rules. It requires previous knowledge of Classic Backgammon."])},
      "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are small changes over the original rules, that end up being natural and evident once you add the classes."])},
      "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> adds 5 classes of soldiers, plus the regular one. Each of these classes has different properties."])},
      "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can play Abak Evolution in"])},
      "intro5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or put some stickers in your board that you can find here: "])},
      "intro10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rules are avaliable in"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each class of soldiers has its own set of properties, that modify its behavior."])},
      "start0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting Position"])},
      "start1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The strarting position is very similar to backgammon, but instead of two checkers in the back, you start with 4:\n<ul> <li>The wounded (⨯2) start at the back <span class='etiqueta-posicion'>[p.24-1]</span> <span class='etiqueta-posicion'>[p.23-1]</span>, each placed to reach the 18 point with its maximun dice face number. </li>\n<li>The druid starts with them, as far back as possible <span class='etiqueta-posicion'>[p.24-2]</span>. Its radio of action is the midfield, so it's better to wait back.</li>\n<li>The guards (⨯2) are close to home <span class='etiqueta-posicion'>[p.13-3]</span> <span class='etiqueta-posicion'>[p.8-2]</span>, ready to form structures but not ready to move, they are both under a soldier at start.</li>\n<li>The general starts at home <span class='etiqueta-posicion'>[p.6-4]</span>, to help forming structures on both directions.</li>\n<li>The rest, the normal soldiers, start at: <span class='etiqueta-posicion'>[p.23-2]</span><span class='etiqueta-posicion'>[p.13-1]</span><span class='etiqueta-posicion'>[p.13-2]</span><span class='etiqueta-posicion'>[p.13-4]</span><span class='etiqueta-posicion'>[p.8-1]</span><span class='etiqueta-posicion'>[p.8-3]</span><span class='etiqueta-posicion'>[p.6-1]</span><span class='etiqueta-posicion'>[p.6-2]</span><span class='etiqueta-posicion'>[p.6-3]</span> </li>\n</ul>\n"])},
      "bidimensional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Abak Evolution</b> transforms Backgammon in a <b>bidimensional</b> game. This means that the vertical position of a stone in the column matters, and therefor the <b>movements order</b> in a turn is a new factor in the game."])},
      "imagina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagine the posibilities"])},
      "classic_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This position of your team in a Classic Backgammon"])},
      "millions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is equivalent to millions of combinatios in Abak Evolution. Just a small sample:"])},
      "moves_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order of movements"])},
      "drastically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order of the movements can drastically change the outcome of the play."])},
      "druid_traps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, the druid traps the soldier."])},
      "odd_captures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, the odd wounded captures the soldier"])},
      "encuentra_abak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to play"])},
      "redes_sociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find <b>Abak Evolution on social Networks</b>"])},
      "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the App to play on your phone. Find it on your phone’s app store."])},
      "play_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open your web browser and point here:"])},
      "capturar_guardia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To hit a guard with two (non-guard) soldiers, you must be able to hit it with <b>both of them</b> in <b>consecutive movements</b> during a turn."])},
      "abak_vs_backgammon_titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak vs Classic Backgammon"])},
      "abak_vs_backgammon_cuerpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While Abak Evolution's only difference with Classic Backgammon is the added classes, this produces a significant change in the game and has two major consequences."])},
      "abak_vs_backgammon_combinaciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak has"])},
      "abak_vs_backgammon_combinaciones2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["possible positions for any given Classic Backgammon’s position"])},
      "subject": {
        "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers"])}
      }
    }
  },
  "tutorials": {
    "intro": {
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this set of tutorials you can learn Classic Backgammon rules and Abak Evolution rules. What would you like to learn?"])},
      "lback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Backgammon.          \n"])},
      "lback2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't know how to play Backgammon. I wan't to learn from scratch. "])},
      "labak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Abak."])},
      "labak2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know how to play Backgammon, I want to learn how to play Abak. "])},
      "whyask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is just to set a filter you can change later."])}
    },
    "options": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncompleted"])}
    },
    "level": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring your soldier to safety."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to ['move'] your soldier. "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one soldier at the end of the board and no enemies. Roll the dice and move your soldier until it gets home, and then remove it from the board to save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run home"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon's objective is to bring all of your checkers (['soldiers']) home. You do that by rolling two dice [13,15] and moving a soldier the number of spaces each die tells you."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear Off"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once all your soldiers are home, you can ['remove'] them (['bear off']) from the board. The first player that ['bears off'] all of its soldiers from the board wins."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dice Roll"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each dice roll will give you two movements if the dice are of different value and four if they are equals."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the roll button to, obviously, roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now move the soldier twice, one for each die, by clicking on it and selecting the landing column."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have played all your valid moves. Confirm you have finished with the button."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent!"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed your turn. Keep it going until your soldier is safe (bore off)."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent is not playing, so play again. Roll."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you have a double that gives you four [!4] moves of [d13] spaces each. Move them all."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done. Confirm your move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your soldier is now at home."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When all your soldiers are at home, you can remove them from the board (beat them off). Roll your dice."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Roll."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can choose the ['outside'] of the board [!0] to move your soldier."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, you have finished the game."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Finished"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That’s it. You have removed 'all' of your soldiers and finished the game. "])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about blocked positions in the next chapter."])},
        "11b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! do it again. Move with the other dice."])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's home"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump across the opponent's soldiers to ['escape'] his ['home'] and ['capture'] his soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two soldiers ['[s11]×2'] at the end of the board. Avoid the adversary ['[s12]×5']  and bring them home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked Positions"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A soldier can land at any position the opponent does not block. A position is blocked when the opponent has two or more soldiers at it."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT the addition"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" You can move different soldiers with each die or the same soldiers with both die, but remember those are two movements, and each has to have an open position to land."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on the soldier in position [23], you will see it can't move any die. So it can't move the addition of both dice. You must move the other soldier."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made all your moves. Now confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blots"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a soldier is alone in a position (['a blot']), it can be captured by an opponent soldier who lands over it. "])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the soldier landing with one soldier over it."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have sent your opponent’s soldier to the bar!."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Captured."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has a soldier on the bar, it has to return to the board for the team to keep playing. If it can't escape from the bar, no other soldier can move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to escape from the bar in the next chapter."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't Capture the soldier!"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to capture the lonely soldier. Try again!"])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board description."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this quick tour on the Backgammon Board."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has [!24] ['positions'], called ['points'], divided in [!4] quadrants."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Positions"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus the Bar [#B0] [#B1], and a place outside the board [0] [25], for rescued soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player's Home Board is in the ['bottom-right'] quadrant. ['Points [1] to [6]']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponet's Home Board is on the ['top-right'] quadrant. ['Points [24] to [19]']"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is no man's land. ['Points [7] to [18]']"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['bar'] is the position where ['captured'] soldiers are kept. [#B0][#B1]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Board"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['out-board'] ['[!0]-[!25]'] is the position you put your ['beared off'] soldiers."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inverted Board"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that the board could be inverted on the X axis, homes being on the left side. You can always can change the board direction in the menu."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each player has [15] soldiers."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yours: [s11] , Opponent: [s12]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opposite Direction "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players move in opposite directions. Player 1 ([s11]) moves from [24] to [1], towards his home."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent’s Direction"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player 2 ([s12]) moves from [1] to [24]."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll and Move"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game is played in turns, each player roll two dice [d11][d12] and moves his soldiers."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring them home."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objective of the game is to bring your soldiers home ['Points [1] to [6]'], and once all of them are home, bear them off [0]."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to move soldiers next."])}
      },
      "b2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's the Bar!"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been [captured]. Learn how to escape from the [Bar]."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent has captured two soldiers and sent them to the bar. Release them."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bar is the column in the middle of the board ([#B0] and [#B1]). It holds soldiers that have been ['captured']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two ['captured'] soldiers in this board [s11][s11]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers in the Bar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has soldiers in the opponent’s bar, he ['must'] release them before he can make any other move."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll to Escape"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You escape from the bar entering the adversary's home from its farest place, as shown with the dice."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to escape."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this case, positions [19][dr6] and [20][dr5] are blocked, and the rest are open. Position [!22][d13] has a blot."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can release only one soldier now, at position [!21][d14]. Position [20][d15] is blocked."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because you left a soldier in the bar [B1], you can't use the other die to move. You will have to wait. Confirm your move."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again the dice"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can land at position [!22][d13], and capture your opponent. Once all your soldiers are off the bar, you can move [d16] with any soldier."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awesome. Now confirm."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free to move!."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Now your soldier can keep running free. You are all set."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the next chapter, you will learn how to bear off."])}
      },
      "b3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off all your soldiers"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn the rules of ['bearing off'] your ['soldiers']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of your soldiers are inside your home. Now you can ['remove '] them from the board in a process called ['bearing off ']."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point [!0]"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To bear off your soldiers, you move then outside the board, to ['point [!0]']"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land exactly in [!0]"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Soldier has to move the ['exact'] number of ['spaces'] to land there."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move from higher points."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is no soldier at the point were the movement should begin, and there are soldiers in a ['higher point'], you must move those soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off from the highest point"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are no soldiers in a ['higher point'], you can and must move the soldier in the highest point, to point [!0], bearing it off."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Situation"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here, your soldier in the highest point is in point [5]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d16]."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], less than [6], you bear off from [5]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d15]."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You land exactly at [0] from [5]. You can bear off."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d14]."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], more that [4], you have to move from the higher point, from [5] to [1]."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d13][d12][d11]."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose to bear off a soldier, or make a valid move inside the board."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's do This. Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have soldiers on [6], but being [5] the highest position you have, you can bear off one soldier. The [d14], you will have to move."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have 3 soldiers in position 1. You can bear them off, but you can also move some soldiers from behind."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your soldiers are now way before position [6]. bear them off."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have all your soldiers out, you have finished the game."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And this ['ends'] the Backgammon's tutorial. You can go and practice against the server, or check out more advanced tutorials in the main menu."])}
      },
      "a1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s Backgammon"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how both games are the ['same'], but way ['different']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with this tutorial instructions to learn how Abak improves its father."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the classic backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[!15] equals soldiers for each team"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Abak'] arranges its first position a little differently. It starts with [!4] soldiers in the back."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Classes"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And, off course, adds ['classes'] of soldiers"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same Rules"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rules of Backgammon apply to Abak. But each class adds its set of additional rules."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General [s21]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves in both directions"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↶ [s21] ↷"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid [s41]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizes the opponent."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s41] = 🔒 "])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards [s31]"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More powerful than the rest"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s31] ≅ [s11]+[s11]"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded [s61]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves odd numbers "])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s61] ↷ [d11] [d13] [d15]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded [s51]"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves even numbers "])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s51] ↷ [d12] [d14] [d16]"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having different classes of soldiers not only adds the soldier's rules to the game. It also affects deeply its ['mechanic's]. "])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you have an example. Points [19] and [17] have the same classes of soldiers, but in different order. Meaning the height of a soldier in a point matters, so it adds a new Axis, [!Y]"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order of Movements"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This also means that the movement's order matters in the game, and could change the result of a turn drastically ."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the guard!."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your regular soldier first [s11], then your druid [s41], to capture the guard [s32]."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture the guard. Try again."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Captured!."])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have captured the guard [s32]."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the order."])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would have happened if you had moved the druid [s41] first?"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilize the guard!."])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your druid first [s41], then your regular soldier [s11], to trap the guard [s32]."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not what expected"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to trap the guard [s32] with your druid [s41]."])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Trapped"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  As you can see, a different outcome for the same move but made in a different order."])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about each class in the next chapters."])}
      },
      "a2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['druid']. The most feared soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['druid'] is a powerful tool that needs practice to master. It ['immobilizes] the opponent's soldiers instead of capturing them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immobilized"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its power is only present in the ['outer-board'], where he can immobilize soldiers."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captured"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inside ['homes'], the druid behave as a ['regular'] soldier. It ['captures'] soldiers and sends them to the bar."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move both [d14] and [d12] with the ['druid'], to capture the ['regular'] soldier, and immobilize the ['guard']."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't make both actions."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture a soldier [s12][22][d12] and immobilize the guard [s32][18][d14]. Try again."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, You trapped and captured a soldier!"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now his guard [s32][18] can't move. But he could hit your druid with another soldier. Oh no, it’s his turn!."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid was captured."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is in the bar. Roll to get out."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the bar, and trape its soldier with your druid on [20]."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Soldier Trapped."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is still alone and vulnerable. But hey, what could go wrong?"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaos."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now your druid has a druid over.  You can't move it, but this soldier under it can't move either."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your soldier in [24] to capture your opponent druid, and save yours."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect. Druid is Safe."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid has a soldier immobilized, and is protected, building a blocking point."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not forever."])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have to leave that position and release the soldier someday. More advanced tutorials are available that address the topic."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed this chapter."])}
      },
      "a4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['guards'] strengths and weaknesses.\n"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guard"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The guard is the strongest class. It blocks the position by itself, but has some limitations. You have two of them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onle lonely soldier"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, you have one soldier against two enemy guards. Can you escape?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You rolled [14,15] and can't move because both guards are blocking ([19][d15] [20][d14]). You can try to move a soldier if you like, to see what happens. Click in Confirm to continue."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A wild soldier appears"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards are not invincible. Two soldiers can hit them. Let's add a companion."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attack with both"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To capture a guard, you must land over it, in two consecutive movements, with two soldiers. Let's do this."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, try to hit the guard with both soldiers."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes!. Confirm you are done."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent! That is one way to do it."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because, of course, a guard can hit another guard."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So you don't have to just blindly believe me."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hit them both!."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic. You are done now."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have learned how guards work. "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the expected movement. Try again."])}
      },
      "a3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['General']. The most resourceful soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your ['General'] to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['General'] is resourceful. It can go ['backward'] and ['forward']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[#1] In a turn, it can go only in one direction.<br/>[#2] Only one move backward.<br>[#3] It can't go back if all other soldiers are at home.'"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only once Backwards."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the General goes back, it can no longer move in the turn."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. You can see how it can move to 4 different points. Choose one ['backwards'] (highest points)"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good. Click again. You will see that it can't move anymore. Move the other checker."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward many, but not back."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the General goes forward, it can move every dice number, but can't go back."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not expected."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow the instructions in the messages to complete this tutorial."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. Now choose a point forward."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. It can't go back. But it can continue forward. Move."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the only one away from home."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if all the other soldiers is in its team are home."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if every other soldier is in its team home."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your General, you can see it can go back. Make sure to move with your regular ['soldier [s11]'] to enter your team's home."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. No other team soldier is outside, so it can't go back, only forward."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's it. You have completed this chapter."])}
      },
      "a5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to move the ['odd wounded'] and suffer its restrictions."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded can only move odd numbers: [d11][d13][d15]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that, it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have ['3 rolls'] to jump across your adversary. Choose wisely."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This roll [11,13] allows one valid move, but you can choose which die. Move."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This time, you have an ['even'] number you can't move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great, you have escaped. Roll to finish"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned with your ['odd wounded'] to your home. Great job!."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wounded is now fully trapped, because [d11] lands in [20], [d13] in [18] and [d15] in [16], all blocked. Try again!"])}
      },
      "a6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about the ['even wounded'], predictably nightmare."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['even wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['even wounded'] can only move even numbers: [d12][d14][d16]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictable"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because it always move ['even'] numbers, you can block its path from far away (as you can see now, it won't go farthest than [20])."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play some rolls to escape from there."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this roll, dice [d15] is open but is not an ['even'] number. You can only move [d14] to [20]."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now the ['even wounded'] is fully ['blocked']. No dice roll will get you out of there. But hey, it's the opponent’s turn."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky you!"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[d16] now is open. Cross your finger."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come on!. Don't expect real life to be like this. Now, run."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned to your home with your ['even wounded']. Great job!."])}
      },
      "a7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded at the Bar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when entering from the ['bar']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded captured']. You will get ['4 rolls'] to take them out. Your opponent is not moving."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Escape"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when in the ['bar'], they can enter the board with ['any dice'] value."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Rolls"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[13,12] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your wounded in the Bar [B0], you can see both can land in both positions [22][d13] [23][d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Rolls"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Rolls"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [16,16] ↦ [15,15]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Roll"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [u6,u6] ↦ [15,15]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #4."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to be home with both wounded. Try again!."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You returned home with both wounded. Good Job!."])}
      },
      "a8": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bearing Off"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when ['bearing off']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Bear off'] your ['wounded'] in this exercise, and practice how they behave in this situation."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bear Off"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when they bear off, they can do it with ['any dice'] value, as a regular soldier."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, ['odd wounded [s61]'] is on point [4]. Can bear off (if on top) with [d16][d15][d14]. Can move [d11] and [d13]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['even Wounded [s51]'] is on point [4]. Can ['bear off'] with [d16][d15][d14]. Can move [d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the ['even wounded']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can bear off the ['even wounded']. The ['odd wounded'] can't move."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['odd wounded'] can't move [d12], but can move [d13]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now it can bear off with [d12]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you know how to bear off your ['wounded']. Find more tutorials in the ['medium'] level section."])}
      },
      "g102": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your guards the right way"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your guards are amazing tools, but you might go through an entire game without using their powers."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this tour about Guards, and solve a few puzzles."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards are almost blocks."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They make a difference when alone in the base of the board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Block"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When facing a single adversary, they make an absolute block, if that adversary is not a guard."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] is fully blocked."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Block"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Against many enemies, they could be hit, but with meagerchances. They make a great temporary structure."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] hits with [d25][d25] and [d26][d26]"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are no match for the Druid"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid can trap a guard as with any othe soldier."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s42] is blocked on [6] but can trap in [7]"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you stack them, you might underuse them"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep them atop a block if you want to use them to attack later"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid stacking soldiers on them if possible to maximize your chances and use your full army capabilities"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But when they are at home. Keep it at ground level, so you won’t leave blots when bearing off."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your worst-case scenario."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block the Odd Wounded."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the right move to fully block the wounded"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded [s61] is not fully blocked, try again."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made a full block to retain the Odd Wounded."])}
      },
      "bst": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Moves"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon has been cracked. There is concens about the best moves for the opening roles."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the wisest decisions on opening moves, to finish this tutorial."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanne is a move of two different soldiers, from two different points, to the same destination."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is maybe the best possible opening move. Play your best."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to make a block on point [7]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have created an vital block."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Golden Point."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a very important position."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in the Golden Point of your home. An importan position to guard."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another important point."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape moves"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are other not-so-awesome moves at the start. You won't produce a block with them"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon more opnings!."])}
      },
      "bp": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a Prime and block the enemy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Prime is an structure of six consecutive blocks. Trap your enemy with one"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice and create a prime to trap your opponent inside your home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold it Back"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An important tactical objective of backgammon is to hold your opponent back, trapping it in your home."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a fort"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent soldier is trapped back, but you have some blots to cover. If your opponent rolls [d24] or [d22], he will hit and may run. "])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a move that will make six consecutive blocks and prevent his escape"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent can escape."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left a blot on your home and your opponent can escape. Try again and make a full block."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime. A structure of 6 blocks that fully traps soldiers. Very Good!."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full House."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also build a prime fully in your home, and have captured soldiers that can't get off."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture your opponent, and close your home."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't fully close your home."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to have built 6 blocks inside your home and fully trap a captured enemy. Try Again."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime inside your home. Very Good!."])}
      }
    },
    "common": {
      "whitehome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Home"])},
      "blackhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black Home"])},
      "outerboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outside"])},
      "movesleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must make all valid moves."])},
      "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
      "gotit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You got it!"])},
      "bettermove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a better move"])},
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
      "chcompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])}
    }
  },
  "meta": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Abak Evolution Backgammon"])},
      "history": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon History"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak was born in 1999, after a unique chain of events and conditions."])}
      },
      "rant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How Abak Evolution Backgammon was not wat I expected"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak was not the hit a thought it would be, but I will never desist of this project."])}
      },
      "samy-garib": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib - Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samy Garib is as Chilean Software developer and the inventor, designer, and developer of Abak Evolution Backgammon."])}
      },
      "donate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate to keep Abak Evolution Backgammon alive"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon is a one-man-proyect, free, ads-free, and needs help."])}
      },
      "thanks-to": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon is grateful of some folks."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many people has been part of this project. From help in translation, to an amazing amount of entusiasm. All is good."])}
      }
    },
    "boardgame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon Board Game"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak is on many social media sites. Check them out here and find ways to contact me."])}
    },
    "learn": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources to learn Backgammon and Abak. Find here PDF rules books, an interactive tutorial, and much more."])}
    },
    "print": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print the Abak Evolution Backgammon Rules"])}
    },
    "resources": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources to learn Abak Evolution"])}
    },
    "rules": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules - Abak Evolution Backgammon"])},
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Soldier - An Abak Evolution Backgammon Class"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They act as a regular backgammon soldier."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General - An Abak Evolution Backgammon Class"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The only soldier that can go back in the game. It rules the fight."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards - An Abak Evolution Backgammon Class"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each team has two Guards. They are stronger than other soldiers and can't be captured so easily"])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid - An Abak Evolution Backgammon Class"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The druid traps it's opponent, inmovilizing its enemy, instead of capture it."])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded - An Abak Evolution Backgammon Class"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded can only move Even numbers."])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded - An Abak Evolution Backgammon Class"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded can only move odd numbers."])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules of Abak Evolution Backgammon"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak has 6 classes of soldiers: Soldier, General, Druid, Guards, and the wounded that are two clases."])}
      }
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ - Abak Evolution Backgammon"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This FAQ is about the differences between Abak and Backgammon"])}
    },
    "abak": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon. A Variant."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution is a two-player, abstract strategy board game. A Backgammon Variant that adds classes of checkers to the classic game."])}
    },
    "tutorial": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon & Abak Evolution Tutorial"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a backgammon interactive tutorial. You will learn about the board, movements, the bar and how to bear off. You will also find advanced tutorials with puzzles."])},
      "backgammon": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Tutorial"])}
      },
      "abak": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Tutorial"])}
      }
    }
  }
}