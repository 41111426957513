<template>
  <router-view/>
</template>

<style>

body {

    font-family: "Roboto", "Helvetica Neue", Arial;
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    text-align: left;
    background: rgb(46,98,69);
    background: linear-gradient(309deg, rgba(46,98,69,1) 0%, rgba(3,41,8,1) 100%);
    user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    -ms-user-select:none; 
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Signika-VariableFont_wght.ttf") format("truetype");
  
}


  /* width */
  ::-webkit-scrollbar {
  width: 0.6em;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: #00000044;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
  background: #b3b30c66;
  border-radius: 0.5em;
  }

  /* Handle on hover */
  @media (hover: hover) {
    ::-webkit-scrollbar-thumb:hover {
      background: #b3b30c;
    }
  }

</style>
