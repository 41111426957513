<template>
<div class="frameme">
    <div class="matchEndContainer" :class="{ matchEndedWon: isMatchEnded && didLocalPlayerWon , matchEndedLost : isMatchEnded && !didLocalPlayerWon }"> 
        <div class="matchEndHeader" style="text-align:center;">
            <div>{{ title }}</div>
            <div class="stakeBadge" v-if="gameType=='abak'">
                <img :src="require('@/assets/img/symbols/stake.svg')"/> 
                {{ stake }}
            </div>
            <div class="pointsBadge">
                <img :src="require('@/assets/img/symbols/goal.svg')"/>
                {{points.goal}}
            </div>
        </div>
        <div  class="firstRow" v-if="!pleaseConfirm && !unConnected && !newMatchConfirm" >
            <!-- Players Container -->
            <div class="playersContainer">     
                <div style="width: 50%;flex-grow: 1;display: flex;flex-direction: column;align-items: center;">  
                    <PlayerBadge 
                        :direction="'1'" 
                        :player="players[0]" 
                        :imageSize="imageSize" 
                        :playerID="0"
                        :vertical="true"
                        :key="'MatchEndplayerBadge1'" 
                        :id="'MatchEndplayerBadge1'"/>
                    <div style="display:flex;justify-content:center;padding:0.5em;">
                        <div class="MatchEndPoints">{{points.current[0]}}</div>
                    </div>
                    <div class="surrenderResult" v-if="phase=='endedMatch' && !isEmpty && gameType=='abak'">
                        <img v-if='calculateNewRanking[0] == players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                        <img v-if='calculateNewRanking[0] > players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                        <img v-if='calculateNewRanking[0] < players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/down.svg')" />
                        <div> {{Math.abs(calculateNewRanking[0] - players[0].ranking) }}</div>
                        <div> {{calculateNewRanking[0]}}</div>
                    </div>

                </div>
                <div style="width: 50%;flex-grow: 1;display: flex;flex-direction: column;align-items: center;">  
                    <PlayerBadge 
                        :direction="'1'" 
                        :player="players[1]" 
                        :imageSize="imageSize" 
                        :playerID="1"
                        :vertical="true"
                        :key="'MatchEndplayerBadge2'" 
                        :id="'MatchEndplayerBadge2'"/>
                    <div style="display:flex;justify-content:center;padding:0.5em;">
                        <div class="MatchEndPoints">{{points.current[1]}}</div>
                    </div>                
                    <div class="surrenderResult" v-if="phase=='endedMatch'&& !isEmpty && gameType=='abak' && !(otherPlayer==1 && opponent.type=='CPU')">
                        <img v-if='calculateNewRanking[1] == players[1].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                        <img v-if='calculateNewRanking[1] > players[1].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                        <img v-if='calculateNewRanking[1] < players[1].ranking' style="width:1em;" :src="require('@/assets/img/symbols/down.svg')" />
                        <div> {{Math.abs(calculateNewRanking[1] - players[1].ranking) }}</div>
                        <div> {{calculateNewRanking[1]}}</div>
                    </div>                        
                </div>
            </div>
            <!-- Match  Log -->
            <MatchLog
                v-if="!isEmpty"
                :points="points"
                :players="players"
                :title="'Games log'" />
            <div v-else style="min-width: 50%;text-align: center;margin-top: 1em;"> Empty Game  </div>
        </div>

        <div v-if="pleaseConfirm && !isMatchEnded && !isEmpty" class="matchSurrender"> 
            <div>{{t('ui.game.message.youwilllosematch')}}</div>
            <div>{{t('ui.game.alerts.matchlosspoints')}}:</div>
            <div class="surrenderResult">
                <img v-if='calculateNewRanking[0] == players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                <img v-if='calculateNewRanking[0] > players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/up.svg')" />
                <img v-if='calculateNewRanking[0] < players[0].ranking' style="width:1em;" :src="require('@/assets/img/symbols/down.svg')" />
                <div> {{-1*(calculateNewRanking[0] - players[0].ranking) }}</div>
                <div> {{calculateNewRanking[0]}}</div>
            </div>   
        </div>

        <!--- Unconnected Opponent Message  -->
        <div class="quitnoconnect" v-if="unConnected" v-html="t('ui.game.messages.exitnomatch')">
            
        </div>


        <div class="buttons" v-if="playerID!==null && !newMatchConfirm">
            <!-- Action Buttons -->
            <div v-if="!pleaseConfirm" style="padding-top:0.5em;">      

                <GameButton
                    v-if="!unConnected && opponent.type=='CPU'"
                     :key="'ButtonGameReview'" :id="'ButtonGameReview'" 
                    :text="t('ui.game.action.review')" 
                    :type="'light'"
                    image='visibility' @click="clickReviewGame()" />

                <GameButton
                    v-if="!unConnected && opponent.type=='CPU' && phase=='endedGame'"
                     :key="'ButtonGameReview'" :id="'ButtonGameReview'" 
                    :text="t('ui.game.action.pause')" 
                    :type="'light'"
                    image='pause' @click="clickPauseMatch()" />


                <GameButton v-if="phase=='endedGame'"
                     :key="'ButtonGameSurrender'" :id="'ButtonGameSurrender'" 
                    :text="t('ui.game.action.surrender')" 
                    :type="'light'"
                    image='surrender' @click="clickAction('quit')" />
                <GameButton v-if="phase=='endedGame'"                    
                    :key="'ButtonGameContinue'" 
                    :id="'ButtonGameContinue'" 
                    :type="'light'"
                    :text="t('ui.game.action.nextgame')" 
                    image='si' 
                    @click="clickNewGame();" 
                />

                <GameButton v-if="phase=='endedMatch'"
                    :key="'ButtonMatchSurrender'" :id="'ButtonMatchSurrender'" 
                    :text=" gameType!='practice' ? t('ui.game.action.quit') : t('ui.game.action.continue') " 
                    :type="'softwarn'"
                    image='quit' @click="clickAction('quit')" />

                <GameButton v-if="phase=='endedMatch' && !unConnected && opponent.type=='CPU' && gameType!='practice'"
                    :type="'light'"
                    :key="'ButtonMatchContinue'" :id="'ButtonMatchContinue'" 
                    :text="t('ui.game.action.newmatch')" image='si' @click="newMatchConfirm=true;" 
                />

            </div>
            <!-- Confirm Buttons -->
            <div v-else
                style="margin-top:1em;"
                class="cajaBotones"
            >        
                <div>{{t('ui.game.action.confirm')}}</div>
                <GameButton :key="'ButtonMatchConfirm'" :id="'ButtonAcept'" :text="t('ui.game.action.no')" image='no' @click="clickCancel()" />
                <GameButton :key="'ButtonMatchCancel'" :id="'ButtonAcept'" :text="t('ui.game.action.yes')" image='si' @click="clickConfirm()" />
            </div>
        </div>
        <div class="buttons" v-if="playerID===null && !newMatchConfirm"> 
            <div> 
                <GameButton v-if="phase=='endedMatch' && !unConnected && opponent.type=='CPU' && gameType!='practice'"
                    :type="'light'"
                    :key="'ButtonQuitVisitor'" :id="'ButtonQuitVisitor'" 
                    :text="t('ui.game.action.quit')" image='si' 
                    @click="$emit('quit-visitor')" 
                />
            </div>
        </div>
        <div class="newMatchConfirm" v-if="newMatchConfirm">
            <div> 
                <div style="display:flex;justify-content: space-between;align-items: center;margin: 0.2em 0em;"> 
                    <div> {{t('ui.game.message.repeat')}}</div>
                    <div v-if="!working"> 
                        <GameButton :key="'ButtonMatchRepeat'" :id="'ButtonMatchRepeat'" :text="t('ui.game.action.repeat')" 
                            image='si' 
                            :type="'light'"
                            @click="clickNewMatch()" />
                    </div>
                    <div v-else style="font-size:0.3em;margin-right:1em;"> <MiniLoader /> </div>
                </div>

                <div> 
                    <div class="newMatchConfirmData"> 
                        <div class="SetupMatchBadge"> 
                            <div>{{ t('lobby.subject.type')}}</div>
                            <div> 
                                <img :src="require('@/assets/img/symbols/type_'+gameType+'.svg')" alt="{{gameType}}"/>
                            </div>
                        </div>
                        <div class="SetupMatchBadge">
                            <div> 
                                <img style="filter:invert(1)" :src="require('@/assets/img/symbols/stake.svg')"/> 
                                <div>{{ t('ui.lobby.subject.stake') }}</div>
                            </div>
                            <div v-if="gameType=='abak'">{{ stake }}</div>
                            <div v-else>0</div>
                        </div>
                        <div class="SetupMatchBadge">
                            <div> 
                                <img style="filter:invert(1)" :src="require('@/assets/img/symbols/goal.svg')"/>
                                <div> {{ t('lobby.subject.goal')}} </div>
                            </div>
                            <div>{{points.goal}}</div>
                        </div>
                        <div class="SetupMatchBadge"> 
                            <div> 
                                <img :src="require('@/assets/img/symbols/level.svg')"/>
                                <div>{{ t('lobby.subject.level')}}</div>
                            </div>  
                            <div> 
                                <img alt="cpu avatar" :src="require(`@/assets/img/players/cpu/${opponent.level}.svg`)">
                            </div>                         
                        </div>  
                    </div>
                </div>
            </div>
            <div>                 
                <div style="display:flex;justify-content: space-between;"> 
                        <div> {{t('ui.game.message.modify')}}</div>
                        <div v-if="!working"> 
                            <GameButton :key="'ButtonMatchConfigure'" :id="'ButtonMatchConfigure'" :text="t('ui.game.action.modify')" 
                            image='si' 
                            :type="'light'"
                            @click="clickAction('quit',noconfirm=true)" />

                        </div>
                        <div v-else style="font-size:0.3em;margin-right:1em;"> <MiniLoader /> </div>

                </div>
            </div>
            <div style="display:flex;justify-content: flex-start;background: none;"> 
                <GameButton :key="'ButtonMatchCancel'" :id="'ButtonMatchCancel'" :text="t('ui.game.dialogs.cancel')" image='no' @click="newMatchConfirm=false" />
            </div>
        </div>
    </div>
    </div>
</template>

<script>

    //const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

    import GameButton from "../buttons/GameButton.vue";
    import PlayerBadge from "./PlayerBadge.vue";
    import MatchLog from "./MatchLog.vue";
    import { useI18n } from 'vue-i18n'
    import MiniLoader from "../elements/MiniLoader.vue";

    var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');

	export default {
		name: 'EndDialog',
        components: { GameButton, PlayerBadge, MatchLog, MiniLoader },
        props: ['points','players','matchData',
                'doubleCube','phase','imageSize','playerID',
                'surrenderPoints','opponent','stake','gameType'],
        data: function() {
            return {
                pleaseConfirm:false,
                action:null,
                newMatchConfirm:false,
                working:false
            }
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },         
        computed: {
            otherPlayer: function() {
                let playerID = this.playerID || 0;
                return  (parseInt(playerID)+1)%2;
            },
            unConnected: function() {
                return this.players[1].id=="";
            },  
            calculateNewRanking: function() {
                //console.log("calculateRanking");
                if (this.points.current[0]>=this.points.goal) {
                    return lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,0,this.points.goal,this.stake); 
                }
                return lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,1,this.points.goal,this.stake);
                
            },    
            didLocalPlayerWon: function() {
                return this.points.current[this.playerID]>=this.points.goal;
            },
            isMatchEnded: function() {
                return this.points.current[0]>=this.points.goal ||
                        this.points.current[1]>=this.points.goal;
            },
            isEmpty: function() {
                if (this.points.games_record.length==0) return true;
                return false;
            },
            title: function() {
                if (this.pleaseConfirm) {
                    if (this.isMatchEnded) {
                        return this.t('ui.game.action.quit');
                    }
                    else {
                        return this.t('ui.game.action.surrender');
                    }                    
                }
                if (this.phase=="endedGame") {
                    return this.t('ui.game.message.gameover')
                }
                else {
                    if (this.gameType != 'practice') {
                        return this.t('ui.game.message.matchover');
                    }
                    else {
                        return this.t('ui.game.message.practiceover');
                    }
                }
            },
        },
        watch: {
            phase: function(nphase) {
                if (nphase == "endedGame") {
                    console.log("EndedMatcht..go");
                    if (this.opponent.type =="HUMAN" && this.isMatchEnded ) {
                        setTimeout(()=> {this.$emit("quit-match");},2000);                        
                    }
                }
            }
        },
        emits:[
            'quit-match',
            'start-time-travel',
            'new-match',
            'new-game',
            'pause-game',
            'quit-visitor'
        ],
        methods: {	
            clickNewGame: function() {
                this.working = true;
                this.$emit("new-game");
            },
            clickNewMatch: function() {
                this.working = true;
                this.$emit("new-match");
            },
            clickPauseMatch: function() {
                this.$emit("pause-game");
            },
            clickAction: function (action, noconfirm=false) {
                if (action=='quit') {
                    if (this.unConnected || noconfirm || this.gameType == 'practice' ) {
                        this.$emit("quit-match" , { 
                            type: this.gameType, 
                            goal: this.points.goal,
                            stake: this.stake,  
                            opponent : this.opponent
                         });
                    }
                }                
                this.pleaseConfirm = true;
                this.action = action;
                //this.ctx.events.setPhase("defineWhoStarts");
            },
            clickConfirm: function() { 
                //console.log("confirm",this.phase,this.action);
                this.pleaseConfirm = false;
                if (this.action=="quit") {      
                    this.$emit("quit-match" , { 
                            type: this.gameType, 
                            goal: this.points.goal,
                            stake: this.stake,  
                            opponent : this.opponent
                         });
                }                                       
            },
            clickCancel: function() {
                this.pleaseConfirm = false;
                this.action = "";
            },
            clickReviewGame: function() {
                this.$emit('start-time-travel');                
            },
                     
        },
        mounted: function() {
            this.$nextTick(function () {   
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .frameme {
        position:absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        margin:0;
        background-color: #000000AA;
        z-index: 10000;
    }

    .matchEndContainer { 
        box-sizing: border-box;
        position:absolute;
        width:60%;
        height:90%;
        background-color:darken($c4,15%);
        left:20%;
        top:5%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius:0.5em;
        filter:drop-shadow(0.1em 0.2em 0.3em black);
        padding:0.5em 0.5em 1.5em 0.5em;
        z-index:100;
    }

    .matchEndedLost {
        background:darken(desaturate($colorno,20),15%);
    }

    .matchEndedWon {
        background:darken(desaturate($coloryes,10),0%);
    }

    .buttons > div {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;        
        >div {
            margin: 0.5em 0em;
        }        
    }


    .gameDetailsContainer img {
        width:1.5em;
        margin:0.2em;
    }

    .gameDetails {
        display:flex;
        justify-content: space-around;
        border-bottom: 0.1em solid white;
        margin-bottom:0.1em;
    }

    .gameDetails > div {
        margin:0 0.5em;
    }

    .gameDetailsContainer {
        font-size:1em;
        max-height: 8em;
        overflow: auto;
        flex-grow: 0.3;
    }

    .playersContainer {
        display:flex;
        flex: 1 1 0px;
        margin: 1em;
    }

    .firstRow {
        display:flex;
    }

    .matchEndHeader { 
        display:flex;
        justify-content: space-around;
        align-items: center;
        font-size:1.5em;
    }

    .pointsBadge {
        background-color:rgb(0, 0, 0);
        padding:0.3em;
        border-radius: 10%;
        min-width: 1.5em;
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;
        display: flex;
        img {
            filter:invert(1);
            margin-right: 0.2em;
            width: 1em;    
        }
    }
    .stakeBadge {
        background-color:rgb(0, 0, 0);
        padding:0.3em;
        border-radius: 10%;
        min-width: 1.5em;
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;
        display: flex;
        img {
            filter:invert(1);
            width: 1em;    
            margin-right: 0.2em;
        }
    }

    .MatchEndPoints {
        background-color:$c0;
        padding:0.3em;
        border-radius: 50%;
        width: 1.5em;        
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;    
        display: flex;    
        justify-content: center;        
    }

    .cajaBotones {
        border-radius: 1em;
        width: 90%;
        margin-left:5%;
        box-sizing: border-box;

    }

    .surrenderResult {
        display:flex;
        justify-content:center;
        align-items: center;
        margin-top:0.5em;
        font-size:1.2em;
    }

    .surrenderResult > div:nth-child(3) {
        margin-left:1em;
        background: $c6;
        padding: 0em 0.5em;
        border-radius: 0.2em;
    }
    .playerContainerSurrender {
        width: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;        
        justify-content: flex-start;
        align-items: center;        
    }

    .quitnoconnect {
        background-color: $c4;
        border-radius: 0.3em;
        font-size: 2em;
        padding: 1em;

        span {
            color:$c5;
        }
        
    }

    .pointsCurrent {
        display:flex;
        align-items:center;
        div {            
            background-color:$c4;
            display:flex;
            padding:0em 0.2em;
            margin: 0em 0.2em;
        }
    }

    .newMatchConfirm {
        display: flex;
        flex-grow: 1;
        align-items: center;
        align-content: center;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 1em;

        >div {
            background: $c8;
            width:90%;
            margin:0.1em 0em;
            padding:0.5em;
            border-radius: 0.5em;
        }

        .newMatchConfirmData {
            display: flex;
            justify-content: space-around;
            >div {
                background: $c6;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin: 0.2em;
                padding: 0.2em;
                border-radius: 0.3em;
                flex:0.25;
                >div:nth-child(1) {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
                >div:nth-child(2) {
                    display: flex;
                    font-size: 2em;
                    align-items: center;
                }
                img {
                    width: 1em;
                    margin: 0.2em 0;
                }
            }
        }
    }

    .matchSurrender {

        >div {
            text-align:center;
        }

        >div:nth-child(1) {
            width:50%;
            margin-left:25%;
            background-color:$c4;
            margin-bottom: 1em;
            padding: 0.4em;
            border-radius: 0.5em;
        }

        >div:nth-child(3) {
            font-size: 2em;
        }


    }
    
    @media (orientation:portrait) {
        .matchEndContainer {
            width:95%;
            left:2.5%;
            font-size: 1.5em;
            height: 80%;
        }

        .matchEndHeader {
            font-size: 1em ;
        }

        .firstRow {
            flex-direction: column;
        }

        .buttons div {
            flex-wrap: wrap;

            div {
                margin:0.5em;
            }
        }
        
    }

</style>
