<template>
    <div
        :key = chkId
        :id ="'chk'+chkId"
        :style="{ width: colWidth*XunitSize+'px' }"
        draggable="false" 
        >

        <CheckerSVG 
            :colors="vcolors[ownerSrc]"
            :selected="('chk_'+selectedChecker)==chkId"
            :cclass="cclass"
            :onbar="position[0]==0 || position[0]==25"
        />

         <img v-if ="tips && tips.show && tips.tips.length>0"
              :src="require(`@/assets/img/piedras/tip.svg`)"              
         />

    </div>
</template>

<script>
    
    //import anime from 'animejs';
    var posHelper = require('../../assets/js/positionHelpers');
    import CheckerSVG from './CheckerSVG.vue';

	export default {
		name: 'CheckerComp',
        props: ['chkId','position','cclass','owner',
                'direction','colWidth','colHeight','checkersColor',
                'XunitSize','YunitSize','maxTop',
                'playerID','starting','selectedChecker','tips',
                'forcePoistion','colors'
                ],
        components: { CheckerSVG },
        data: function() {
            return {
                calculatedPath: ""
            }
        },
        emits: ['change-path'],
        computed: {
            classSrc: function() {
                if (this.position[0]==0 || this.position[0]==25)
                    return "b";
                return this.cclass;
            },
            ownerSrc: function() {                                
                return this.checkersColor == parseInt(this.playerID)  ?  this.owner : (this.owner + 1) % 2;                
            },
            vcolors: function() {
                if (this.colors) return this.colors;
                return ( 
                    [
                        {
                        background: "#ceca9d",
                        line: "#986941",
                        object1: "#250e08",
                        object2: "#40190f",
                        color: "#2e130c",
                        },
                        {
                        background: "#2e130c",
                        line: "#492a02",
                        object1: "#c89159",
                        object2: "#c89159",
                        color: "#ceca9d"
                        }
                    ]
                    )
            }
        },
        methods: {	
            path: posHelper.path,
            x: posHelper.x,
            y: posHelper.y      
        },
        watch: {  
            /* Change checkers position if screen position changes */
            starting: function(newValue,oldValue) {
                        let playerID = this.playerID || 0;
                        if (newValue && !oldValue) {
                        let nx = this.x(this.position,this.direction);
                        let ny = this.y(this.position,playerID);  
                        let targets = '#chk'+this.chkId;
                        let duration = 0;
                        let jsonAnime = {
                            targets,
                            opacity: 1,
                            duration:duration,
                            easing: 'easeInOutSine',
                            translateX:(nx*this.colWidth)*this.XunitSize +"px",
                            translateY:(this.maxTop-(ny)*(this.colWidth*this.XunitSize)) +"px"
                        };
                        this.$anime(jsonAnime);
                    }
            },        
            direction: function(newDirection)  {
                let playerID = this.playerID || 0;
                let nx = this.x(this.position,newDirection);
                let ny = this.y(this.position,playerID); 
                let targets = '#chk'+this.chkId;
                let jsonAnime = {
                    targets,
                    opacity: 1,
                    duration:0,
                    easing: 'easeInOutSine',
                    translateX:(nx*this.colWidth)*this.XunitSize +"px",
                    translateY:(this.maxTop-(ny)*(this.colWidth*this.XunitSize)) +"px"
                };
                this.$anime(jsonAnime);  
            },
            position: { 
                deep: true,
                handler: function(newPosition,oldPosition) {
                    //console.log(newPosition,oldPosition);
                    let playerID = this.playerID || 0;
                    let type="move";
                    let jsonAnime = {};
                    let duration = 500;
                    let nx = this.x(newPosition,this.direction);
                    let ny = this.y(newPosition,playerID);                
                
                    if (
                        oldPosition[0] === null || 
                        oldPosition[1] === null ||
                        (oldPosition[0] == newPosition[0] && 
                        oldPosition[1] == newPosition[1])
                    ) {
                        if (!this.starting) {
                            type="none";
                            return;
                        }
                        else {
                            duration=0;
                        }
                    }

                    let targets = '#chk'+this.chkId;

                    if (type=="move") {

                        jsonAnime = {
                            targets,
                            opacity: 1,
                            duration:duration,
                            easing: 'easeInOutSine',
                            translateX:(nx*this.colWidth)*this.XunitSize +"px",
                            translateY:(this.maxTop-(ny)*(this.colWidth*this.XunitSize)) +"px"
                        };
                        this.$anime(jsonAnime);  
                    }
                    /*
                    if (type=="dance") {
                        //console.log(oldPosition,newPosition);    
                                        
                        let calculatedPath = this.path( {x:ox , y:oy }, { x:nx , y:ny });
                        this.$emit("path-changed",{ xpath: calculatedPath });
                        let path = anime.path("#movePath path");                    
                        jsonAnime = {
                            targets,
                            opacity: 1,
                            duration:500,
                            easing: 'easeInOutSine',
                            translateX:path('x'),
                            translateY:path('y')
                        };
                        anime(jsonAnime);  
                    }
                    */
                    if (type=="translate") { 
                        //console.log("hola");
                    }
                }
            }
        },
        mounted: function() {
            let targets = '#chk'+this.chkId;
            let playerID = this.playerID || 0;
            let jsonAnime = {};
            let duration = 0;
            let nx = this.x(this.position,this.direction);
            let ny = this.y(this.position,playerID);    
            jsonAnime = {
                        targets,
                        opacity: 1,
                        duration:duration,
                        easing: 'easeInOutSine',
                        translateX:(nx*this.colWidth)*this.XunitSize +"px",
                        translateY:(this.maxTop-(ny)*(this.colWidth*this.XunitSize)) +"px"
                    };
            this.$anime(jsonAnime);              
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    div {
        position: absolute;
    }

    img {
        position:absolute;
        width:100%;    
        left:0;
        top:0;    
    }

</style>