<template>
    <div class="closeButton">
        <img :src="require('@/assets/img/buttons/close.svg')"/>
    </div>
</template>

<script>
	export default {
		name: 'closeButton'
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .closeButton {
            padding: 0.2em;
            background-color: $c17;
            border-radius: 1em;
            height: 1em;
            width: 1em;
            margin:0.2em;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0em 0em 0.3em 0.1em  black;

            img {
                width: 100%;
            }

            @media ( hover: hover ) {
                :hover {
                    filter:invert(1)
                }
            }

        }

</style>
