<template>
    <div class="halloffame">
        <div class="hallHeader">
            <div class="headerTitle">
                    {{$t('lobby.hallfame.title')}}
            </div>
            <div class="headerContent">
                <div 
                    @click="time=30;type='allwinners';getHallOffFame()"
                    :class="{active: type=='allwinners' }"
                    >
                    <img :src="require('@/assets/img/buttons/medal.svg')"/>
                </div>
                <div 
                    @click="time=7;type='winners';getHallOffFame()"
                    :class="{active: time==7 && type=='winners'} "
                    >
                    <img :src="require('@/assets/img/buttons/week.svg')"/>
                </div>
                <div 
                    @click="time=30;type='winners';getHallOffFame()"
                    :class="{active: time==30 && type=='winners'}"
                    >
                    <img :src="require('@/assets/img/buttons/month.svg')"/>
                </div>
                <div 
                    @click="time=30;type='engaged';getHallOffFame()"
                    :class="{active: time==30 && type=='engaged'}"
                    >
                    <img style="filter:invert(1)" :src="require('@/assets/img/buttons/people.svg')"/>
                </div>
            </div>

            <CloseButton @click="$emit('close-hall')" /> 
        </div>


        <!-- WEEK/MONTH ENGAGED PLAYERS -->
        <div v-if="type=='engaged'" class="hallSectionContainer"> 
            <div style=""> 
                Most Active Players
            </div>
            <div  class="hallPlayersContainer">
                <div class="hallplayer" v-for="l in last" :key="'hfp-'+l">
                    <div class="playerInfo">
                        <div> 
                            <div><img :src='l.image'/></div>
                            <div><img :src="'/img/players/abaker/'+l.abaker+'.svg'"></div>             
                        </div>
                        <div> 
                            <div>{{l.name}}</div>                
                            <div><country-flag :country=l.country size='normal' :rounded=true style="margin:0"/></div>
                        </div>
                    </div>
                    <div class="playerMatchesCount">
                        <div><div>🎲</div><div>{{(l.games_number).toLocaleString()}}</div></div>
                        <div><div>🎌</div><div>{{(l.matches_number).toLocaleString()}}</div></div>
                    </div>
                </div>
                <div 
                    v-if="working"
                    style="margin:2em;text-align: center;width: 100%;font-size: 2em;"
                    >
                    <MiniLoader/>
                </div>
                <div 
                    v-if="!working && last.length==0"
                    style="margin:2em;text-align: center;width: 100%;font-size: 2em;"
                    >
                    Error: No Data 
                </div>
            </div>

            <div style="text-align: center; margin: 1em 0em;"> 
                Updated at: {{ dateModified ? formatDate(dateModified) : "" }}
            </div>

        </div>

        <!-- ALL TIME WINNERS -->
        <div v-if="type=='allwinners'" class="hallSectionContainer"> 
            <div style=""> 
                All Time Winners (ELO)
            </div>

            <div v-if="type=='allwinners'" class="hallPlayersContainer">
                <div class="hallplayer" v-for="l in allwinners" :key="'hfp-'+l">
                    <div class="playerInfo">
                        <div> 
                            <div><img :src='l.image'/></div>
                            <div><img :src="'/img/players/abaker/'+l.abaker+'.svg'"></div>             
                        </div>
                        <div> 
                            <div>{{l.name}}</div>                
                            <div><country-flag :country=l.country size='normal' :rounded=true style="margin:0"/></div>
                        </div>
                    </div>
                    <div class="playerMatchesCount">
                        <div><div>{{l.elo_total.toLocaleString()}}</div></div>
                    </div>
                </div>
                <div 
                    v-if="working"
                    style="margin:2em;text-align: center;width: 100%;font-size: 2em;"
                    >
                    <MiniLoader/>
                </div>
                <div 
                    v-if="!working && last.length==0"
                    style="margin:2em;text-align: center;width: 100%;font-size: 2em;"
                    >
                    Error: No Data 
                </div>
            </div>

            <div style="text-align: center; margin: 1em 0em;"> 
                Updated at: {{ dateModified ? formatDate(dateModified) : "" }}
            </div>


        </div>

        <!-- WEEK/MONTH WINNERS -->
        <div v-if="type=='winners'" class="hallSectionContainer"> 
            <div style=""> 
                Improvers {{ time }} days
            </div>

            <div class="hallPlayersContainer">
                <div class="hallplayer" v-for="l in winners" :key="'hfp-'+l">
                    <div class="playerInfo">
                        <div> 
                            <div><img :src='l.image'/></div>
                            <div><img :src="'/img/players/abaker/'+l.abaker+'.svg'"></div>             
                        </div>
                        <div> 
                            <div>{{l.name}}</div>                
                            <div><country-flag :country=l.country size='normal' :rounded=true style="margin:0"/></div>
                        </div>
                    </div>
                    <div class="playerMatchesCount">
                        <div><div>𝚫E</div><div>{{ (l.elowon - l.elolost).toLocaleString() }}</div></div>
                        <div><div>🎌</div><div>{{l.matches_number.toLocaleString()}}</div></div>
                        <div><div>⭐</div><div>{{matchesStrengthScore(l.m3,l.m4,l.m5,l.m6)}}</div></div>
                    </div>
                </div>
                <div 
                    v-if="working"
                    style="margin:2em;text-align: center;width: 100%;font-size: 2em;"
                    >
                    <MiniLoader/>
                </div>
                <div 
                    v-if="!working && last.length==0"
                    style="margin:2em;text-align: center;width: 100%;font-size: 2em;"
                    >
                    Error: No Data 
                </div>
            </div>

            <div style="text-align: center; margin: 1em 0em;"> 
                Updated at: {{ dateModified ? formatDate(dateModified) : "" }}
            </div>

        </div>

        <div class="footing">
            <div><div>🎲</div><div>Amount of Games</div></div>
            <div><div>🎌</div><div>Amount of Matches</div></div>
            <div><div>⭐</div><div>Difficulty level</div></div>
            <div><div>𝚫E </div><div>ELO Gain</div></div>
        </div>
    </div>
</template>

<script>
    
    
    const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');
    var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');
    import MiniLoader from '../elements/MiniLoader.vue';
    import CloseButton from '../elements/CloseButton.vue';

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return (
            [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            ].join('-') +
            ' ' +
            [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
            ].join(':')
        );
    }


	export default {
		name: 'HallFame',
        props: [ 'player' ],
        components: { MiniLoader, CloseButton },
        data: function() {
            return {
                raw: [],
                time: 7,
                working: false,
                type: "winners",
                ammount:20,
                dateModified:null,
            }
        },
        emits: ['close-hall'],
        computed: {
            allwinners: function() {
                return this.raw.slice()
                            .sort((a, b) => b.elo_total - a.elo_total)
                            .filter(a => a.elo_total > 500)
                            .slice(0,this.ammount);                
            },
            winners: function() {
                return this.raw.slice()
                            .sort((a, b) => ((b.elowon - b.elolost) - (a.elowon - a.elolost )) )
                            .filter(a => a.elowon - a.elolost > 0)
                            .slice(0,this.ammount);                
            },
            last: function() {
                return this.raw.slice(0,this.ammount);
            }
        },
        methods: {	
            getHallOffFame: function() {
                    this.raw = [];
                    this.working = true;                    
                    this.axios.post(
                        `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/get/hall-of-fame`,
                        { type:'last', value:this.time, player_id:this.player.id } ).then((response) => {
                            this.raw = response.data.r.result;
                            this.dateModified = new Date(response.data.t);
                            this.working = false;
                        }).catch((error) => {
                            console.error(error);
                            }
                        );
            },
            matchesStrengthScore: function(m3,m4,m5,m6) {
                let mm = Math.max(lobbyLib.ranking_cpu['3'],lobbyLib.ranking_cpu['4'],lobbyLib.ranking_cpu['5'],lobbyLib.ranking_cpu['6'])/10;
                let p = [lobbyLib.ranking_cpu['3']/mm,lobbyLib.ranking_cpu['4']/mm,lobbyLib.ranking_cpu['5']/mm,lobbyLib.ranking_cpu['6']/mm];
                let total = m3 + m4 + m5 + m6;                
                return Math.round(10*((m3/total)*p[0]+(m4/total)*p[1]+(m5/total)*p[2]+(m6/total)*p[3]))/10;
            },
            getDays: function(date) {
                let days= ( new Date().getTime() - new Date(date).getTime() ) / (1000 * 3600 * 24);
                return Math.round(days);
            },
            formatDate
        },
        mounted: function() {
            this.$nextTick(function () {
                this.getHallOffFame();
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .halloffame {
        position:absolute;
        width: 95%;           
        top:2.5%;
        left:2.5%;
        box-sizing: border-box;
        height: 95%;
        background:$g2;
        border-radius: 1em;
        box-shadow: $box-shadow-normal;
        display:flex;
        flex-direction: column;
    }

    .hallHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em 0 1em;
    }

    .headerTitle {
        font-size:1.5em;
    }

    .headerClose {
        background: $c0;
        padding: 0.5em;
        border-radius: 2em;
        width: 1.2em;
        line-height: 1.2em;
        display: flex;
        justify-content: center;
    }
    
    .headerContent {
        display: flex;
        flex:0.5;
        justify-content: space-around;
        align-self: flex-end;
        img {
            height: 2em;
            margin: 0 0.5em;            
        }

        div {
            background: #444444;
            padding-top: 0.3em;
            border-radius: 1em 1em 0 0;
            flex:1;
            margin: 0 0.5em;
            text-align: center;
            cursor: pointer;
        }

        div:hover {
            background:#FF000033;
        }
        .active {
            background:#00000055;
        }
    }


    .hallSectionContainer {
        background:#00000055;
        overflow-y: scroll;
        flex-grow: 1;
        >div:first-child {
            text-align: center;
            padding-top: 0.5em;
        }
    }

    .hallPlayersContainer {
        display: flex;
        flex-wrap: wrap;
        padding: 0em 0.2em;
        flex:1;
        align-items: flex-start;
        justify-content: center;
        .hallplayer {
            background-color:#00000066;
            img {
                width: 3em;
                height: 3em;
            }
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin:0.5em;
            padding:0.3em 1em;
            border-radius: 0.5em;
            flex:0.2;

            .playerMatchesCount {
                display: flex;
                justify-content: center;
                align-items: center;
                >div {
                    text-align: center;
                    border-radius: 0.2em;
                    background:$c8;
                    flex:0.3;
                    margin: 0 0.3em;
                    padding:0.1em 0.5em;
                }
            }

            .playerInfo {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                }

                >div:nth-child(1) {

                    align-items: flex-start;

                    >div:nth-child(1) {
                        img {
                            background:#FFFFFF77;
                            border-radius: 50%;
                            padding: 0.3em;
                        }
                    }

                    >div:nth-child(2) {
                        background: $c3;
                        border-radius: 1em;
                        display: flex;
                        width: 1.3em;
                        height: 1.3em;
                        margin-left: -0.7em;
                        align-items: center;
                        justify-content: center;                
                        img {
                            width:1em;
                        }
                    }
                }
            }

        }

    }

    .footing {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding:0.2em;
        >div {
            display:flex;
            align-items: center;
            >div:nth-child(1) {
            background:$c6;
            padding:0.2em;
            border-radius: 1em;
            margin-right:0.3em;
            }
        }
    }

</style>
