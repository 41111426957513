//var initTables = require('../helpers/initTables.js');
var abakLib = require('../AbakEvolutionLibrary.js');
const resetGame = function(G) {  
    //console.log("Reset Game Started");
    //console.log(G.type);
    G.rollNumber=0;
    G.diceRoll= [0,0];
    G.generalDirection=0;
    G.diceAvailable= [0,0,0,0];
    G.doubleCube = { holder:null,value:1};
    G.turnslog = { currentMoves : [], log: []};
    
    G.timers.game = [0,0];
    G.timers.gameStartedAt = Date.now();
    G.timers.turnStartedAt = 0;
    G.timers.turnDoubledAt = 0;
    G.timers.turnDoubleRespondedAt = 0;
    G.timers.turnRolledAt = 0;    
    
    G.checkers_map = abakLib.initialTablaByType(G.type,G.set);

    //const initTables = require('../helpers/initTables');
   // G.checkers_map = initTables.TablabetterPath;
        
    
}

module.exports = { resetGame }