//var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');
const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

import axios from 'axios'

export function clickInvitationDismiss(event) {
    let postData = { invitationID : event.id };
    axios.post(
        `${WEBPROTOCOL}${APIURL}:${APIPORT}/invitations/dismiss/`,
        postData).then((response) => {                         
            if (!response) return;
            //console.log("invitation dismissed",response);
            this.uIstate.invitations.invitations.splice(-1);
            if (this.uIstate.invitations.invitations.length==0) this.uIstate.messages.show = false;
        }).catch(console.error); 
}

export function clickInvitationJoin (event) {
    let postData = { invitationID : event.id };
    axios.post(
        `${WEBPROTOCOL}${APIURL}:${APIPORT}/invitations/accept/`,
        postData).then((response) => {   
            if (!response) return; 
            this.zone = "lobby";     
            this.joinLobbyMatch(event.session_id, "1");
            this.$forceUpdate();
            this.uIstate.messages.show = false;
            //console.log("invitation accepted",response);
        }).catch(console.error);           
}

export function invitationCreate () {
    let possibleInvitations = [];
    const newMatch = this.uIstate.invitations.config;

    if (this.uIstate.invitations.player_id && this.uIstate.invitations.type == "direct") {
        possibleInvitations = [ this.uIstate.invitations.player_id ];
    }
    else {
        possibleInvitations = this.getPosibleInvitations(newMatch.gameType);        
    }
    //console.log(newMatch);
    if (possibleInvitations.length == 0) return false;
    let opponent = possibleInvitations[0];    
    let postData = {
        session_id: this.lobby.currentMatch.id,
        receiver_id: opponent,
        sender_id: this.player.id,
        action: this.uIstate.invitations.type,
        game: {
            type: newMatch.gameType,
            maxTimeOut: newMatch.max_timeout,
            goal: newMatch.goal,
            stake: newMatch.stake
        }                       
      };
    //console.log(newInvitation);
    axios.post(
        `${WEBPROTOCOL}${APIURL}:${APIPORT}/invitations/create/`,
        postData).then((response) => { 
            //console.log(response);
            this.uIstate.invitations.invitedPlayers.push(
                { 
                    id:response.data.id,
                    player:opponent,
                    timestamp: Date.now(),
                    state:'created'
                }
            );            
            this.uIstate.invitations.state = 'invitationSent';
            this.uIstate.invitations.timeSent = Date.now();
            this.uIstate.invitations.readTick = 0;
            setTimeout(()=>this.checkInvitationResponse(),3000);
            //console.log("invitation created",response);
        }).catch(console.error);  
    return true;         
}

export function invitationCancel(invitationID) { 
    axios.post(
        `${WEBPROTOCOL}${APIURL}:${APIPORT}/invitations/cancel/`,
        {invitationID:invitationID}).then((response) => {    
            if (!response) return;
            console.log("canceled");
            if (this.uIstate.invitations.type == "active") {
                let invitationsleft = this.invitationCreate();
                console.log("left",invitationsleft);
                if (!invitationsleft) {
                    this.uIstate.invitations.state = 'exaustedInvitations';
                }    
            }
            else {
                this.uIstate.invitations.state = 'exaustedInvitations';
                this.uIstate.invitations.player_id=null;
            }

            //console.log(response);
        }).catch((error) =>{
            this.uIstate.invitations.state = 'exaustedInvitations';
            console.log(error);
        });            
}

export function checkInvitationResponse() {
    //console.log("Checking Invitations");
    if (this.uIstate.invitations.state == 'invitationSent') {
        //console.log("Waiting For Response");
        let invitation = this.uIstate.invitations.invitedPlayers[this.uIstate.invitations.invitedPlayers.length-1];
        let postData =  { invitationID: invitation.id };
        this.uIstate.invitations.readTick++;
        axios.post(
            `${WEBPROTOCOL}${APIURL}:${APIPORT}/invitations/check/`,
            postData).then((response) => {    
                //console.log(response);
                let invitationRes = response.data.invitation[0];

                if (invitationRes.state == 1 && invitationRes.response == 2 ) {
                    //console.log("La invitación fue aceptada, no tengo que seguir preguntando.");
                }

                if (invitationRes.state == 1 && invitationRes.response == 1 ) {
                    //console.log("La invitación fue rechazada, crear una nueva.");
                    this.uIstate.invitations.responseTick++;

                    if (this.uIstate.invitations.type=="active") {
                        let invitationsleft = this.invitationCreate();
                        if (!invitationsleft) {
                            this.uIstate.invitations.state = 'exaustedInvitations';
                            this.uIstate.invitations.player_id = null;
                            this.uIstate.invitations.type = null;
                            }
                    }
                    else {
                        this.uIstate.invitations.state = 'exaustedInvitations';
                        this.uIstate.invitations.player_id = null;
                        this.uIstate.invitations.type = null;
                    }
                }
                
                if (invitationRes.state == 0) {
                    this.uIstate.invitations.responseTick++;
                    let timeDiff = (Date.now() - invitation.timestamp)/1000;
                    //console.log("No ha sido Contestada",timeDiff);                    
                    if (timeDiff>30) {
                        //console.log("Seguir esperando");
                        this.invitationCancel(invitation.id);
                        //let invitationsleft = this.invitationCreate();
                        //if (!invitationsleft) this.uIstate.invitations.state = 'exaustedInvitations';    
                    }
                    else {                        
                        //console.log("Seguir esperando");
                        setTimeout(()=> this.checkInvitationResponse(),2000);                    
                    }
                }

            }).catch(console.error);         
    }
}

export function getPosibleInvitations(gameType=null) {
    console.log("Get possible invitations",gameType);
    //let matchInfo = Object.keys(this.uIstate.invitations.config).length > 0 ? this.uIstate.invitations.config : { 'gameType': this.type };
    let forcedGameType = null;
    if (gameType) forcedGameType = gameType;
    else if (this.uIstate.invitations.config) {
        if (this.uIstate.invitations.config.gameType)
            forcedGameType = this.uIstate.invitations.config.gameType;
    }
    //console.log("Forced Game Type",forcedGameType);
    const lastSeen = this.uIstate.lastSeen.list;
    const invitedPlayers = this.uIstate.invitations.invitedPlayers.map(obj => obj.player);
    //let invitedPlayers = this.uIstate.invitations.invitedPlayers;
    //let matches = this.lobby.availableMatches;

    let possibilities = [];
    

    /* People In lobby and visitors of a game */

    //console.log("Possible Invitation from Last Seen");

    for (let l in lastSeen) {
        //console.log(l,JSON.stringify(lastSeen[l]));
        const recelntlyRejected = lastSeen[l].rejected == 1;
        const recentlyInvited = invitedPlayers.includes(lastSeen[l].player_id);
        const isMe = this.player.id == lastSeen[l].player_id;
        const notGaming = lastSeen[l].last_action=='lobby' || lastSeen[l].last_action=='visitor';
        const allowedGameTypes = lastSeen[l].player_data.allowedGameTypes;
        const typeIsAllowed = forcedGameType ? allowedGameTypes.includes(forcedGameType) : true;
        //console.log(JSON.stringify(matchInfo),JSON.stringify(allowedTypes),typeIsAllowed);
        const gamingCPU = lastSeen[l].last_action=='game' 
            && lastSeen[l].match_data 
            && lastSeen[l].match_data.opponent 
            && lastSeen[l].match_data.opponent.type == 'CPU';
        //console.log(recelntlyRejected,isMe,notGaming,gamingCPU);
        //let gameType = 
        if (
            !recelntlyRejected 
            && !recentlyInvited 
            && !isMe 
            && (notGaming || gamingCPU)
            && (typeIsAllowed) )
            possibilities.push(lastSeen[l].player_id);
    }


    //console.log("Invitations So Far:", JSON.stringify(possibilities));

    return possibilities;

}

export function requestPlayerInvitation(seen) {

    console.log("request invitation seen",seen);
    if (!seen.player_data.invitable || seen.rejected==1 || (typeof (seen.match_data.opponent) =='object' && seen.match_data.opponent.type!='CPU')) {
        //console.log("Not Available");  
        this.showToast(this.$t('Not Invitable'));
        return;
    }
  
    //console.log("Reuqes");
    let isFriend = this.friendship.active.includes(seen.player_id);
    if (this.player.abaker || isFriend) {
        if (!seen.player_data.allowedGameTypes.includes(this.type))
            this.type = seen.player_data.allowedGameTypes[0];
        this.opponent.type = 'HUMAN';
        this.uiState.displayHumanInvite=true;
        this.uiState.lastSeenDetails=false;
    } else {
        this.showToast(this.$t('ui.lobby.message.onlyabakersorfriendscaninvite'));
    }
  }
  

export function inviteHumans(newInvitation) {
    const newMatch = newInvitation.match;
    console.log(newMatch);
    if (newInvitation.player_id) {
        console.log(newInvitation.player_id);
        this.uIstate.invitations.type = "direct";
        this.uIstate.invitations.player_id = newInvitation.player_id;
        this.uIstate.invitations.state = 'waitingMatchID';
        this.uIstate.invitations.readTick = 0;
        this.uIstate.invitations.responseTick = 0;
        this.createLobbyMatch(newMatch);
        this.$gtag.event('game',{'event_category':'engagment','event_label':'send-invitations'});    
    }
    else {
        console.log("Starting Human Invitations",newMatch);
        this.uIstate.invitations.player_id = null;
        this.uIstate.invitations.type = "active";
        let possibleInvitations = this.getPosibleInvitations(newMatch.type);    
        if (possibleInvitations.length>0) {        
            this.uIstate.invitations.state = 'waitingMatchID';
            this.uIstate.invitations.readTick = 0;
            this.uIstate.invitations.responseTick = 0;
            this.createLobbyMatch(newMatch);
            this.$gtag.event('game',{'event_category':'engagment','event_label':'send-invitations'});    
        }
        else {
            this.showToast(this.t('lobby.invitations.message.nomoreplayers'),4000);
            this.$gtag.event('game',{'event_category':'engagment','event_label':'send-invitations-no-more'});    
        }    
    }
}
