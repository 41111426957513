<template>

    <div class = "menuFrameContainer">
        <div class="menuContainer">  
            <!-- ***************** HEADER ******************--->
            <div class="content_container"> 
                Subscriptions.
                <div v-if="!paid" id="paypal-button-container"></div>
                <div v-else id="confirmation">
                    <div> Order complete! </div>
                    <div> {{ subscription }}</div>

                </div>
                
            </div>
        </div>
    </div>
</template>




<script>

    import { loadScript } from '@paypal/paypal-js';

    //var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');
    const { PAYPAL_CLIENT_ID,PAYPAL_SUBSCRIPTION_PLAN_ID } = require('../../assets/js/enviroment.js');

    export default {
		name: 'UserConfiguration',
        props: ['player_id','screenMode'],
        components: {  },
        beforeCreate: function() {
            loadScript(
                { 
                    'client-id': PAYPAL_CLIENT_ID, 
                    vault:true,
                    intent:"subscription"
                }).then((paypal) => {
                    paypal
                        .Buttons({
                            createSubscription: this.createSubscription,
                            onApprove: this.onApprove,                
                        })
                        .render('#paypal-button-container');
                }
            );
        },
        data: function() {
            return {         
                paid:false,     
                subscription: {}  
            }
        },
        emits: ['close'],
        computed: {
        },
        methods: {	
            createSubscription: function(data, actions) {
                console.log('Creating order...');
                return actions.subscription.create({
                    plan_id: PAYPAL_SUBSCRIPTION_PLAN_ID,
                    custom_id: this.player_id
                });
            },
            onApprove: function(data, actions) {
                console.log('Order approved...');
                console.log(data);
                console.log(actions);
                return actions.subscription.get().then((subscription) => {
                    console.log(subscription);
                    this.subscription = subscription;
                    this.paid = true;
                    console.log('Order complete!');
                });
            }
        },
        watch: {
        },
        mounted: function() {
        },
        setup() {
        }  
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .menuFrameContainer {
        position:absolute;
        width: 100%;           
        height: 100%;
        top:0;
        left:0;
        background:#000000AA;
    }

    .menuHeader {
        box-sizing: border-box;
        text-align: center;
        width: 100%;        
        background: $header-background;
        /*background: linear-gradient(  90deg , #1b1a17 0%, #795a20 100%);*/
        border-radius: 0.5em 0.5em 0 0;       
        border-bottom: 0.2em solid $header-bottom; 
    }

    .menuContainer {
        position:absolute;
        width: 95%;           
        height: 95%;
        top:2.5%;
        left:2.5%;
        border-radius: 0.5em;
        display:flex; 
        flex-direction: column;    
        align-items: center;
        justify-content: flex-start;
        background: $content-background;
        box-sizing: border-box;
    }

    .content_container {
        color:white !important;
        font-size:0.5em;
    }

    .menuYesNoOptions {
        background:$sub-content-background;
        padding: 1em;
        border-radius: 0.5em;
    }
    .menuYesNoOption {
        display:flex;
        justify-content:space-between;
        background:$box1-background;
        padding:0.5em;
        border-radius: 0.5em;
        align-content: center;
        align-items: center;
        margin: 0.5em 0;        
    }

    
    .menuHeaderOptions {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-left:5%;
        align-items: flex-end;
    }

    .menuOptions {
        display: flex;
        justify-content: center;
        margin-top:0.5em;
    }

    .menuOptions div {
        background-color:$tab-menu-normal-background;
        padding: 0.3em 1em;
        border-radius: 0.3em 0.3em 0 0;
        margin: 0 1em;
    }


    .menuActive {
        background-color: $tab2-menu-active-background !important;
    }

    .closeButton {
        padding: 0.5em;
        background-color: black;
        border-radius: 1em;
        height: 1em;
        width: 1em;
    }

    .closeButton img {
        width: 100%;
    }

    .selected, .imageSelected {
        background-color: chartreuse;
    }

    .contentContainer {
        padding:0.5em 1em;
        box-sizing: border-box;
        width:100%;
    }

    .contentTitle {
        text-align: center;
        font-size: 1.5em;
    }

    .avatarsBox {
        padding:0.5em;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        width:80%;
        margin-left:10%;
        box-sizing:border-box;
        margin: 0.7em 0 0.7em 10%;
        background-color: $c4;
        border-radius: 0.5em;
    }

    input {
        font-size:1em;
        background-color: rgb(209, 223, 215);
        border:1px solid white;
        border-radius: 0.5em;
        margin-left:0.5em;
    }

    .statsBox1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 1em;
        padding:1em;
        background:$c5;
        margin:0.5em;
        > div {
            display: flex;

            > div {
                background: $c8;
                margin:0.3em;
                padding:0.3em;
                border-radius: 0.5em;
                min-width: 3em;
                text-align: center;
            }
        
        }

    }

    .emailAdd {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        >div:nth-child(1) {            

        }
    }

    .emailWarning {
        background:white;
        color:black;
        text-align:center;
        padding:0.2em;
        border-radius: 0.3em;
        margin-top:0.4em;
        display: flex;
        align-items: center;
    }

    .statsBox2 {
        margin:0.5em;
        display: flex;
        align-items: center;
        border-radius: 1em;
        padding:1em;
        background:$c5;
        > div {
                background: $c8;
                margin:0.3em;
                padding:0.3em;
                border-radius: 0.5em;
                min-width: 3em;
                text-align: center;
        }
    }

    .userVertical {
        flex-direction: column-reverse;
    }

    .buttonReSend {
        background-color: $c6;
        color:$c1;
        margin-left: 1em;
        border-radius: 0.5em;
        box-shadow: $box-shadow-normal;
        padding:0.4em;
    }

    #paypal-button-container {
      margin: 30px 0;
    }
    #confirmation {
        margin-top: 1em;
        font-size: 2em;
    }    

</style>
