<template>
    <div v-if="active"
        class="toast"
        :class="getClass"       
    >
         <div> {{text}} </div>
         <img class="headImage" v-if="image" :src="image" />
         <div v-if="playerData" class="player"> 
            <div>
                <div> {{ playerData.name }} </div>
                <div><country-flag v-if="playerData.country" :country=playerData.country size='normal' :rounded=true  style="margin:0"/></div>
            </div>
            <div style="margin-top:0.5em;">
                <div class="badge">
                    <img :src="playerData.image" /> 
                </div>
                <div class="badge badge-small"> 
                    <img :src="'/img/players/abaker/'+playerData.abaker+'.svg'">
                </div>
            </div>
         </div>
    </div>
</template>

<script>


	export default {
		name: 'Toast',
        props: {
            active:Boolean,
            text:String,
            image:String,
            type:String,
            playerID:String,
            playerData:Object
            },
        data: function() {
            return {
            }
        },
        computed: {
            getClass : function() { 
                if (this.playerID!==null) {
                    return `player${this.playerID}`
                }
                if (!this.type || this.type=="")               
                    return "default"
                return this.type;
            }
        },
        methods: {	

        },
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .toast {
        position:absolute;
        width:70%;
        top:60%;
        left:13%;
        display:flex;
        align-items:center;
        justify-content:space-around;
        padding:2%;        
        box-shadow: 0 0 1.3em #0009;
        border-radius: 0.5em;
        text-align: center;
    }

    .top {
        top:20%;
        background-color:$c6;
    }

    .default {
        background-color:$c7;
        color:white;
    }

    .player1 {
        background:$checkerblack;
        color:$c1;
    }

    .lobbywarning {
        background-color:$colorno;
        margin-top:-21%;
        height:8em;
        font-size:2em;
    }

    .lobbywarning2 {
        background-color:$colorno;
        font-size:1.2em;
        top: 10%;
        position:absolute;
    }

    .info {
        background-color:$c4;
        font-size:1.2em;
        top: 10%;
        position:absolute;
    }

    


    .player0 {
        background:$checkerwhite;
        color:$c0;
    }

    .headImage {
        width:3em;
        margin:0.5em;
    }

    .badge {
        background:$c15;
        border:0.1em solid #0007;
        display:flex;
        justify-content: center;
        align-items: center;
        padding:0.2em;
        border-radius: 50%;
        height:1.5em;
        width:1.5em;
        img {
            height: 1.5em;
        }

    }

    .badge-small {
        height:1em;
        width:1em;
        margin:-0.5em -0.5em;
        align-self:flex-start;
        background:$c9;
        img {
            height: 1em;
        }

    }

    .player {
        background:$c17;
        border-radius: 0.5em;
        padding:0.3em;
        justify-self: flex-end;
        box-shadow: 0 0 0.1em #0009;
        >div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            width: 1.5em;
        }
    }

    @media (orientation:landscape) {
        .toast {
            width: 50%;
            left:20%;
        }

    }



</style>
