/* TODO: Calcular los puntos que hubiese perdido... */
var abakLib = require('../AbakEvolutionLibrary.js');

const quitGame = function (G,ctx) { 

    let firstGame = G.points.games_record.length == 0;
    let firstMove = G.turnslog.log.length == 0;

    if (firstGame && firstMove) {
        try {
            ctx.events.setPhase('endedMatch');
        }
        catch (error) {console.log}
        return;
    }    


    let surrenderPoints = abakLib.surrenderPoints(G.checkers_map,parseInt(ctx.currentPlayer));
    let otherPlayer = ctx.currentPlayer == "1" ? "0" : "1";
    G.points.current[otherPlayer]+=G.doubleCube.value*surrenderPoints;

    G.points.games_record.push(
        {
            'turns': G.turnslog.log.length,
            'winner':otherPlayer,
            'points':surrenderPoints,
            'doubleCube':G.doubleCube,
            'reason':'surrender'});
    try {
        ctx.events.setPhase('endedGame');
    }
    catch (error) {console.log}
}

module.exports = {  quitGame };