<template>

    <div class="mainContainer"> 

        <div>

            <!---------------------------------------------------------------------------------------------------------------------------------->
            <!------------------------------------------------- Last Seen Details (online players)---------------------------------------------->
            <!---------------------------------------------------------------------------------------------------------------------------------->

            <div class="players_online_open" v-if='lastSeenSelected==null'> 

                <!-- Header -->
                <div>
                    <div class="headerMenu" @click="section='online-players'" :class="{ active: section=='online-players'}"> 
                        <div> <img :src="require('@/assets/img/symbols/connected.svg')" /></div>
                        <div v-if="screenMode!=='vertical'" >
                            {{ $t('lobby.subject.players.online') }}
                        </div>
                        <div class="badge badge-title"> {{ lastSeen.list.length }} </div>
                    </div>
                    <div class="headerMenu" @click="section='friends'" :class="{ active: section=='friends'}"> 
                        <div> <img :src="require('@/assets/img/symbols/star.svg')" /></div>
                        <div v-if="screenMode!=='vertical'" >
                            {{ $t('lobby.subject.friends') }}</div>
                        <div class="badge badge-title"> {{ friendship.active.length }} </div>
                    </div>
                    <div class="headerMenu" @click="section='pending-friends'" :class="{ active: section=='pending-friends'}"> 
                        <div> <img :src="require('@/assets/img/symbols/removefriend.svg')" /></div>
                        <div v-if="screenMode!=='vertical'" >
                            {{ $t('lobby.subject.friendship.pending') }}
                        </div>
                        <div class="badge badge-title"> {{ friendship.pending.by_me.length }} </div>
                    </div>
                    <div v-if="zone=='game'" class="headerMenu" @click="section='game-visitors'" :class="{ active: section=='game-visitors'}"> 
                        <div> <img :src="require('@/assets/img/symbols/visibility.svg')" /></div>
                        <div v-if="screenMode!=='vertical'" >
                            {{ $t('lobby.subject.visitors') }}
                        </div>
                        <div class="badge badge-title"> {{ lastSeen.game_visitors.length }} </div>
                    </div>
                    <CloseButton @click="$emit('close');lastSeenSelected=null;"/>                
                </div>

                <!-- Last Seen List ( Online Players )-->

                <div class="players_online_content" v-if="section=='online-players'">

                    <!-- Messages for empty playes -->
                    <div v-if="lastSeen.list.length==0" style="flex-grow: 1;text-align: center;">
                        <div v-if="lastSeen.ammount==0">
                            {{$t('lobby.message.noonlineplayers')}}
                        </div>
                    </div>

                    <!-- -------------------------------------------------------------------------------------------- -->
                    <!-- Online Players List -->
                    <!-- -------------------------------------------------------------------------------------------- -->
                        <div v-if="screenMode=='vertical'" class="verticalContentTitle" >
                            {{ $t('lobby.subject.players.online') }}
                        </div>

                        <div 
                            v-for="seen,i in lastSeen.list" 
                            :key="i" 
                            class="players_online_player"
                            :class="{ lastSeenNotInvitable: !seen.player_data.invitable || seen.rejected==1 || (seen.match_data.opponent && seen.match_data.opponent.type=='HUMAN')}"> 
                            
                            <div 
                                v-if="seen.player_data.visible"
                                @click="selectPlayer('onlineplayer',seen.player_id)"> 
                                <div style="display: flex;align-items: center;justify-content: space-between;"> 
                                    <div>{{seen.player_data.name}}</div>
                                    <div style="margin-left:1em" v-if="friendship.active.includes(seen.player_id)">
                                        <img style="filter:invert(1)" :src="require('@/assets/img/symbols/star.svg')" />
                                    </div>
                                    <div style="margin-left:0em"> 
                                        <country-flag v-if="seen.player_data.country" :country=seen.player_data.country size='normal' :rounded=true  style="margin:0"/>
                                        <div v-else style="height:2em;"></div>
                                    </div>
                                </div>
                                <div> 
                                    <div> 
                                        <div class="badge badge-image"><img :src="seen.player_data.image" /></div>
                                        <div class="badge badge-abaker"><img :src="'/img/players/abaker/'+seen.player_data.abaker+'.svg'"></div>
                                    </div>
                                    <div v-if="seen.match_data.id" class="badge badge-action"> 
                                        <img :src="require('@/assets/img/symbols/type_'+seen.match_data.type+'.svg')" />
                                    </div>
                                    <div v-else class="badge badge-action">
                                        <img :src="require('@/assets/img/buttons/'+seen.last_action+'.svg')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Players Count -->
                        <div class="players_online_player" v-if="lastSeen.ammount - lastSeen.list.length > 0"> 
                            <div>
                                <div>+ {{ lastSeen.ammount - lastSeen.list.length  }} </div>
                                <div> 
                                    {{ $t('lobby.subject.players.invisible') }}    
                                </div>
                            </div>
                        </div>
                </div>


                <!-- -------------------------------------------------------------------------------------------- -->
                <!-- Friends All -->
                <!-- -------------------------------------------------------------------------------------------- -->

                <div class="players_online_content" v-if="section=='friends' || section=='online-friends'">

                    <div v-if="screenMode=='vertical'" class="verticalContentTitle" >
                            {{ $t('lobby.subject.friends') }}
                    </div>

                    <!-- Friends List -->


                    <template v-if="(section=='friends' && friendship.active) ||  (section=='online-friends' && friendship.online)">
                    
                        <div 
                            v-for="friend,i in (section=='friends' ? friendship.active: friendship.online )" 
                            :key="i" 
                            class="players_online_player"
                            :class="{ }"> 
                            <div 
                                @click="selectPlayer('friend',friend)"> 
                                <div style="display: flex;align-items: center;justify-content: space-between;"> 
                                    <div>{{friendship.friends[friend].name}}</div>
                                    <div style="margin-left:1em"> 
                                        <country-flag v-if="friendship.friends[friend].country" :country=friendship.friends[friend].country size='normal' :rounded=true  style="margin:0"/>
                                        <div v-else style="height:2em;"></div>
                                    </div>
                                </div>
                                <div> 
                                    <div> 
                                        <div class="badge badge-image"><img :src="friendship.friends[friend].image" /></div>
                                        <div class="badge badge-abaker"><img :src="'/img/players/abaker/'+friendship.friends[friend].abaker+'.svg'"></div>
                                    </div>
                                    <div v-if="friendship.online.includes(friend)">
                                        <img
                                            
                                            style='filter:invert(1);height:1em;' 
                                            :src="require('@/assets/img/symbols/connected.svg')" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </template>
                    <div v-else> 
                        ...
                    </div>
                    <div 
                        v-if="(section=='friends' && friendship.active.length == 0) || (section=='online-friends' &&  friendship.online.length == 0)" 
                        style="display:flex;justify-content: center;width:100%"
                        > 
                        <div style="text-align: center;"> {{ $t('lobby.subject.friendship.notyet') }} </div>
                    </div>
                    
                </div>

                <!-- -------------------------------------------------------------------------------------------- -->
                <!-- Pending Friends -->
                <!-- -------------------------------------------------------------------------------------------- -->

                <div class="players_online_content_table" v-if="section=='pending-friends'">


                <div v-if="screenMode=='vertical'" class="verticalContentTitle" >
                        {{ $t('lobby.subject.friendship.pending') }}
                </div>

                <!-- Pending Friends List -->

                <div class="headerPending"> 
                    <div :class="{ sactive: pendingSection=='pending' }" @click="pendingSection='pending'"> 
                        <div> {{$t('lobby.subject.friendship.pending')}} </div> 
                        <div> {{ friendship.pending.by_me.length }}</div>                            
                    </div> 
                    <div :class="{ sactive: pendingSection=='rejected' }" @click="pendingSection='rejected'"> 
                        <div> {{$t('lobby.subject.friendship.rejected')}} </div> 
                        <div> {{ friendship.rejected.length }}</div>                            
                    </div> 
                    <div :class="{ sactive: pendingSection=='paused' }" @click="pendingSection='paused'"> 
                        <div> {{$t('lobby.subject.friendship.paused')}} </div> 
                        <div> {{ friendship.pending.my_inactives.length }}</div>                            
                    </div> 
                </div>

                <table v-if="
                        pendingSection == 'pending' ? friendship.pending.by_me.length > 0 : 
                        pendingSection == 'paused' ? friendship.pending.my_inactives.length > 0 : 
                        friendship.rejected.length > 0 "> 
                    <tr> 
                        <td :colspan="screenMode=='vertical'? 4:7 " style="border-bottom:0.1em solid #fff7"> 
                        </td>
                    </tr>

                    <template v-for="friend,j in (
                        pendingSection=='pending' ? friendship.pending.by_me : 
                        pendingSection=='paused' ? friendship.pending.my_inactives : friendship.rejected ) " 
                        :key="'f'+j" 
                        :class="{ }"> 
                        <tr>
                            <td>
                                <div style="display: flex;justify-content: center;align-items: center;font-size:0.6em;"> 
                                    <div class="badge">
                                        <img :src="'/img/players/abaker/'+friendship.friends[friend].abaker+'.svg'">
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div style="display: flex;justify-content: center;align-items: center;">
                                    <img :src="friendship.friends[friend].image" />
                                </div>
                            </td>
                            <td style="display: flex;justify-content: center;align-items: center;"> 
                                <div>
                                    <country-flag v-if="friendship.friends[friend].country" :country=friendship.friends[friend].country size='normal' :rounded=true  style="margin:0"/>
                                    <div v-else style="height:2em;"></div>
                                </div>
                            </td>
                            <td style="width: 30%;">{{friendship.friends[friend].name}}</td>
                            <template v-if="screenMode!='vertical'">
                                <td> 
                                    <div class="rankingBox"> 
                                        {{ friendship.friends[friend].ranking }}
                                    </div>
                                    
                                </td>
                                <td>
                                    <div style="display:flex;align-items: center;"> 
                                        <div><img style="filter:invert(1)" :src="require('@/assets/img/symbols/calendar.svg')" /></div>
                                        <div> {{ friendship.friends[friend].age }} </div>
                                    </div> 
                                </td>
                                <td>
                                    <div class="poct_buttons" v-if="pendingSection=='pending'"> 
                                        <template v-if="!localchanged.includes(friend)"> 
                                            <div @click="requestAction('respond-friendship',{player1_id:friend,player2_id:player.id,response:'ACCEPTED'})"> Accept </div>
                                            <div @click="requestAction('respond-friendship',{player1_id:friend,player2_id:player.id,response:'REJECTED'})"> Decline </div>
                                        </template>
                                        <template v-else> 
                                                loading
                                        </template>
                                    </div>
                                    <div class="poct_buttons" v-if="pendingSection=='rejected'"> 
                                        <template v-if="!localchanged.includes(friend)"> 
                                            <div @click="requestAction('respond-friendship',{player1_id:friend,player2_id:player.id,response:'ACCEPTED'})"> Accept </div>
                                        </template>
                                        <template v-else> 
                                                loading
                                        </template>
                                    </div>
                                    <div class="poct_buttons" v-if="pendingSection=='paused'"> 
                                        <template v-if="!localchanged.includes(friend)"> 
                                            <div @click="clickModifyFriendship(friend)"> Activate </div>
                                        </template>
                                        <template v-else> 
                                                loading
                                        </template>
                                    </div>                            
                                </td>
                            </template>
                        </tr>
                        <tr v-if="screenMode=='vertical'" style="border-top: 0.2em solid #fff3;">
                            <td> 
                                <div class="rankingBox"> 
                                    {{ friendship.friends[friend].ranking }}
                                </div>
                                
                            </td>
                            <td>
                                <div style="display:flex;align-items: center;"> 
                                    <div><img style="filter:invert(1)" :src="require('@/assets/img/symbols/calendar.svg')" /></div>
                                    <div> {{ friendship.friends[friend].age }} </div>
                                </div> 
                            </td>

                            <td></td>

                            <td>
                                <div class="poct_buttons" v-if="pendingSection=='pending'"> 
                                    <template v-if="!localchanged.includes(friend)"> 
                                        <div @click="requestAction('respond-friendship',{player1_id:friend,player2_id:player.id,response:'ACCEPTED'})"> Accept </div>
                                        <div @click="requestAction('respond-friendship',{player1_id:friend,player2_id:player.id,response:'REJECTED'})"> Decline </div>
                                    </template>
                                    <template v-else> 
                                            loading
                                    </template>
                                </div>
                                <div class="poct_buttons" v-if="pendingSection=='rejected'"> 
                                    <template v-if="!localchanged.includes(friend)"> 
                                        <div @click="requestAction('respond-friendship',{player1_id:friend,player2_id:player.id,response:'ACCEPTED'})"> Accept </div>
                                    </template>
                                    <template v-else> 
                                            loading
                                    </template>
                                </div>
                                <div class="poct_buttons" v-if="pendingSection=='paused'"> 
                                    <template v-if="!localchanged.includes(friend)"> 
                                        <div @click="requestAction('respond-friendship',{player1_id:friend,player2_id:player.id,response:'ACCEPTED'})"> Activate </div>
                                    </template>
                                    <template v-else> 
                                            loading
                                    </template>
                                </div>
                            </td>                        
                        </tr>

                        <tr v-if="screenMode=='vertical'"> 
                            <td colspan="6" style="border-bottom:0.1em solid #fff7"> 
                            </td>
                        </tr>

                    </template>

                </table>
                <div v-else style="text-align: center;width: 100%;"> 
                    {{$t('lobby.message.emptylist')}}
                </div>

                </div>


            <!-- -------------------------------------------------------------------------------------------- -->
            <!-- Game Visitors -->
            <!-- -------------------------------------------------------------------------------------------- -->

            <div class="players_online_content" v-if="section=='game-visitors'">

                <div v-if="screenMode=='vertical'" class="verticalContentTitle" >
                        {{ $t('lobby.subject.game_visitors') }}
                </div>

                <!-- Visitors List -->

                <div 
                    v-for="visitor,i in completedVisitors" 
                    :key="i" 
                    class="players_online_player"
                    :class="{ }"> 
                    <div
                        @click="selectPlayer('onlineplayer',visitor.playerData.player_id)"> 
                        <div style="display: flex;align-items: center;justify-content: space-between;"> 
                            <div>{{visitor.playerData.player_data.name}}</div>
                            <div style="margin-left:1em"> 
                                <country-flag v-if="visitor.playerData.player_data.country" :country=visitor.playerData.player_data.country size='normal' :rounded=true  style="margin:0"/>
                                <div v-else style="height:2em;"></div>
                            </div>
                        </div>
                        <div> 
                            <div> 
                                <div class="badge badge-image"><img :src="visitor.playerData.player_data.image" /></div>
                                <div class="badge badge-abaker"><img :src="'/img/players/abaker/'+visitor.playerData.player_data.abaker+'.svg'"></div>
                            </div>
                        </div>
                    </div>
                </div>

                </div>




            </div>



            <!--------------------------------------------------------------------------------------------------------------------->
            <!----------------------------------------------- Selected Player  ---------------------------------------------------->
            <!--------------------------------------------------------------------------------------------------------------------->

            <div v-else style="width:100%"> 
                
                <div v-if="lastSeenSelected && selectedPlayer" class="SelectedPlayer"> 
                    <div> 
                        <div style="display:flex;align-items: center;"> 
                            <div style="margin:0 1em;background:#fff3;padding:0.3em;border-radius: 0.3em;">
                                Player
                            </div>
                            <div>{{selectedPlayer.player_data.name}}</div>
                            <country-flag v-if="selectedPlayer.player_data.country" :country=selectedPlayer.player_data.country size='big' :rounded=true style="margin:0 0 0 1em"/>
                        </div>
                        <CloseButton @click="lastSeenSelected=null;"/>                
                    </div>
                    <div> 
                        <div class="PlayerIntro">
                                <div class="introBadge"> 
                                    <div>
                                        <img :src="selectedPlayer.player_data.image" />
                                    </div>
                                    <div> 
                                        <img :src="'/img/players/abaker/'+selectedPlayer.player_data.abaker+'.svg'">                                            
                                    </div>
                                </div> 
                                <div v-if="selectedPlayer.player_data.ranking"> 
                                    <div style="text-align: center;"> {{ selectedPlayer.player_data.ranking }} </div>
                                    <div style="display:flex;align-items: center;"> 
                                        <img style="filter:invert(1)" :src="require('@/assets/img/symbols/calendar.svg')" /> 
                                        {{ Math.round(selectedPlayer.player_data.age) }}
                                    </div>
                                </div>
                        </div>
                        <div class="PlayerInfo"> 
                            <div> 
                                <div> {{$t('lobby.subject.players.last_action')}} </div>
                                <div style="display: flex;align-items: center;" v-if="(!selectedPlayer.match_data || !selectedPlayer.match_data.opponent) && selectedPlayer.last_action "> 
                                    <div> 
                                        {{ 
                                            selectedPlayer.last_action=='game' ? 
                                            $t('lobby.subject.players.playing'): 
                                            $t('lobby.subject.players.atlobby') 
                                        }}
                                    </div>
                                    <div class="badge badge-action" style="margin: 0.2em 0.3em;"> 
                                        <img :src="require('@/assets/img/buttons/'+selectedPlayer.last_action+'.svg')" />
                                    </div>
                                </div>
                                
                                <div v-if="!selectedPlayer.last_action">
                                    {{$t('lobby.subject.players.offline')}}
                                </div>
                                
                                <div v-if="selectedPlayer.match_data && selectedPlayer.match_data.opponent" style="display: flex;align-items: center;"> 
                                    {{ $t('lobby.subject.players.playing') }}
                                    <img :src="require('@/assets/img/symbols/type_'+selectedPlayer.match_data.type+'.svg')" />
                                </div>

                            </div>

                            <div v-if="selectedPlayer.player_data.allowedGameTypes"> 
                                <div>{{$t('lobby.subject.players.prefered_types')}}</div>
                                <div> 
                                    <div v-for="gameType in selectedPlayer.player_data.allowedGameTypes" :key="'allowedItems'+gameType">                            
                                        <img :src="require('@/assets/img/symbols/type_'+gameType+'.svg')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="flex-basis:100%;height:0;"> </div>
                        <div v-if="this.uiState.confirm" class="players_online_modal"> 
                            <div> {{  $t('lobby.subject.friendship.requestwarning') }} </div>
                            <div> 
                                <div @click="ejecuteConfirmation('request-friendship')"> {{  $t('ui.game.dialogs.acept') }} </div>
                                <div @click="cancelConfirmation()"> {{  $t('ui.game.dialogs.cancel') }} </div>
                            </div>
                        </div>
                        <div v-else class="playerActions">
                            <div v-if="selectedPlayer.last_action">
                                <div>{{$t('lobby.action.invite')}}</div>
                                <UIButtonSmall
                                    :image="'buttons/invitation'" 
                                    :imageSize="imageSize" 
                                    :theme="'light'"
                                    :smaller="true" 
                                    @click="requestAction('invite-play',selectedPlayer.player_id);"  
                                    :disabled="(!player.abaker && !friendship.active.includes(selectedPlayer.player_id)) || !selectedPlayer.player_data.invitable || selectedPlayer.rejected==1 || (selectedPlayer.match_data && selectedPlayer.match_data.opponent && selectedPlayer.match_data.opponent.type=='HUMAN') "                                  
                                />
                            </div>
                            <div v-if="selectedPlayer.match_data">
                                <div>{{$t('lobby.action.watch')}}</div>
                                <UIButtonSmall
                                    :image="'buttons/visibility'" 
                                    :imageSize="imageSize" 
                                    :theme="'light'"
                                    :smaller="true" 
                                    @click="requestAction('game-watch',selectedPlayer);"  
                                    :disabled="!selectedPlayer.match_data || !selectedPlayer.match_data.id || !selectedPlayer.player_data.watchable "                                  
                                />
                            </div>
                            <div v-if="friendship.active.includes(selectedPlayer.player_id)">
                                <div>Chat</div>
                                <UIButtonSmall
                                    :image="'buttons/chat'" 
                                    :imageSize="imageSize" 
                                    :theme="'light'"
                                    :smaller="true" 
                                    @click="requestAction('open-chat',selectedPlayer.player_id);"  
                                    :disabled="false"                                  
                                />
                            </div>


                            <template v-if="friendship.active.includes(selectedPlayer.player_id) || friendship.pending.my_inactives.includes(selectedPlayer.player_id)"> 
                                <div style="position:relative"> 
                                    <div> {{ $t('lobby.subject.friendship.friendship') }}   </div>
                                    <div>
                                        <YesNoSwitch 
                                            :value="friendship.friends[selectedPlayer.player_id].player1_active && friendship.friends[selectedPlayer.player_id].player2_active"
                                            @click="clickModifyFriendship(selectedPlayer.player_id)"
                                        />
                                    </div>
                                    <div 
                                        style="
                                            position:absolute;
                                            top:0;
                                            left:0;
                                            height: 100%;
                                            width: 100%;
                                            background-color:#000E;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;" 
                                        v-if="localchanged.includes(selectedPlayer.player_id)"> 
                                        <MiniLoader style="font-size: 0.2em;"/>
                                    </div>
                                </div>
                            </template>
                            <template v-else> 
                                <div v-if="!friendship.pending.by_me.includes(selectedPlayer.player_id)">
                                    <div> {{ $t('lobby.subject.friendship.friendship') }}   </div>
                                    <UIButtonSmall
                                        :image="'symbols/addfriend'" 
                                        :imageSize="imageSize" 
                                        :theme="'light'"
                                        :smaller="true" 
                                        @click="openConfirmation('request-friendship');"  
                                        :disabled="false"                                  
                                    />
                                </div>
                                <div v-else>
                                    <div> {{ $t('lobby.subject.friendship.friendship') }}   </div>
                                    <div> Player has requested friendship </div>
                                    <UIButtonSmall
                                        :image="'buttons/done'" 
                                        :imageSize="imageSize" 
                                        :theme="'light'"
                                        :smaller="true" 
                                        @click="$emit('respond-friendship',{player1_id:selectedPlayer.player_id,player2_id:player.id,response:'ACCEPTED'})"  
                                        :disabled="false"                                  
                                    />
                                    <UIButtonSmall
                                        :image="'buttons/close'" 
                                        :imageSize="imageSize" 
                                        :theme="'light'"
                                        :smaller="true" 
                                        @click="$emit('respond-friendship',{player1_id:selectedPlayer.player_id,player2_id:player.id,response:'REJECTED'})"  
                                        :disabled="false"                                  
                                    />
                                </div>
                            </template>

                        
                        </div>
                    </div>
                </div>
            </div>
        
            <div 
                v-if = "uiState.showWorking"
                style="
                    position: absolute;
                    top:0;
                    left:0;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #000C;
                    border-radius: 1em;
                    "> 
                <div>
                    <MiniLoader />
                </div>
            </div>
        </div>
    </div>
</template>



<script>

    import UIButtonSmall from "../buttons/UIButtonSmall.vue";
    import YesNoSwitch from "./YesNoSwitch.vue";
    import MiniLoader from "./MiniLoader.vue"
    import CloseButton from './CloseButton.vue';

	export default {
		name: 'OnlinePlayers',
        props: ['player','lastSeen','friendship','imageSize','initSection','onlinePlayersWorking','screenMode','zone'],
        components: { UIButtonSmall, YesNoSwitch,MiniLoader,CloseButton },
        data: function() {
            return {
                lastSeenSelected:null,
                friendSelected:null,
                section:'online-players',
                uiState: { 
                    showWorking:false, 
                    confirm:false
                },
                localchanged: [],
                pendingSection:'pending',
                pendingArray: null,
            }
        },
        computed: {
            selectedPlayer: function() {
                if (!this.lastSeenSelected) return null;
                else {
                    for (let seenIndex in this.lastSeen.list ) {
                        if (this.lastSeen.list[seenIndex].player_id == this.lastSeenSelected.player_id) {
                            return this.lastSeen.list[seenIndex];
                        }
                    }
                    //if not in last seen, search in friendship.
                    for (let friendIndex in this.friendship.friends ) {
                        if (friendIndex == this.lastSeenSelected.player_id) {
                            return { player_id:  parseInt(friendIndex), player_data: this.friendship.friends[friendIndex]};
                        }
                    }
                    return null;
                }
            },
            completedVisitors() {
                return this.lastSeen.game_visitors.map(visitor => {
                    const searchResult = this.searchPlayerLastSeen(visitor);
                    return {
                        playerData: this.lastSeen.list[searchResult]
                    };
                });
            }

            
        },
        emits: [
            'close',
            'invite-play',
            'game-watch',
            'open-chat',
            'selected',
            'request-friendship',
            'respond-friendship',
            'modify-friendship',
            'send-toast'
            ],
        methods: {	
            searchPlayerLastSeen:function(player_id) {
                for (let m in this.lastSeen.list) {                    
                    if (this.lastSeen.list[m].player_id==player_id) return m;
                }
                return null;
            },
            selectPlayer:function(type,player_id) {                
                    if (!player_id) {
                        this.lastSeenSelected = null;
                        this.$emit('selected',this.lastSeenSelected);
                        return;
                    }
                    else if(player_id!= this.player.id)  {
                        if (this.lastSeenSelected && this.lastSeenSelected.player_id == player_id) {
                            this.lastSeenSelected = null;
                        }
                        else {
                            if (type=='onlineplayer') {
                                let lastSeenIndex = this.searchPlayerLastSeen(player_id);                            
                                this.lastSeenSelected = {
                                    player_id: player_id,
                                    allowedGameTypes: this.lastSeen.list[lastSeenIndex].player_data.allowedGameTypes
                                }
                            }
                            else {
                                let index = null;
                                for (let seenIndex in this.lastSeen.list ) {
                                    if (this.lastSeen.list[seenIndex].player_id == player_id) {
                                        index = seenIndex;
                                    }
                                }
                                let aTypes = index !== null ? this.lastSeen.list[index].player_data.allowedGameTypes : [];
                                this.lastSeenSelected = {
                                    player_id: player_id,
                                    allowedGameTypes:aTypes
                                }
                            }
                        }
                        this.$emit('selected',this.lastSeenSelected);
                    }
                    else {
                        this.$emit('send-toast',{ text:'you are clicking on yourself :P', type:'info'});
                    }
            },
            openConfirmation: function(action) {
                this.uiState.confirm = true;
                this.uiState.action = action;
            },
            ejecuteConfirmation: function() {
                if (this.uiState.action=='request-friendship') {
                    this.requestAction(this.uiState.action,this.selectedPlayer);
                }
                this.cancelConfirmation();
            },
            cancelConfirmation: function() {
                this.uiState.confirm = false;
                this.uiState.action = '';
            },
            requestAction:function(action,player) {
                //console.log(action,player);
                switch (action) {
                    case 'game-watch':
                        if (!(player.match_data.id && player.player_data.watchable)) return;
                        this.$emit("close");
                        this.$emit(action,player.match_data.id);
                        break;

                    case 'invite-play':                        
                    case 'open-chat':                        
                        this.$emit(action,player);
                        break;


                    case 'request-friendship':
                        this.$emit(action,{ player1_id:this.player.id, player2_id: player.player_id });
                        this.localchanged.push(player.id);
                        break;

                    case 'respond-friendship':
                        this.$emit(action,player);
                        this.localchanged.push(player.player1_id);
                        break;
                }                

            },
            clickModifyFriendship: function(friend_id) {
                let player1_id= this.friendship.friends[friend_id].remitent ? this.player.id : friend_id;
                let player2_id= !this.friendship.friends[friend_id].remitent ? this.player.id : friend_id;
                const event = { 
                    player1_id: player1_id, 
                    player2_id: player2_id, 
                    active: !(this.friendship.friends[friend_id].player1_active && this.friendship.friends[friend_id].player2_active) ? 1 : 0,
                    side: player1_id == this.player.id ? 1 : 2 
                }
                this.$emit('modify-friendship', event);
                this.localchanged.push(friend_id);
            }
        },
        watch: {
            onlinePlayersWorking: function(newValue,oldValue) {
                if (newValue && !oldValue) this.uiState.showWorking = true;
                setTimeout(()=>{this.uiState.showWorking=false},200);
            },
            'lastSeen.updated': function(newUpdated,oldUpdated) {
                if (newUpdated!=oldUpdated){
                    this.localchanged = [];
                }
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                if (this.initSection) {
                    this.section = this.initSection;
                }
                else {
                    if (this.friendship.pending.by_me.length>0) 
                        this.section = 'pending-friends'
                }
            });
        }
	}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


    .mainContainer {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width: 100%;
        background-color:rgba(0, 0, 0, 0.6);
        >div {
            display:flex;
            background:$g5;
            padding:0.5em;
            margin: 1em;
            border-radius: 1em;
            height: 90vh;            
        }
    }
    .badge {
        background: #ad932c;
        display: flex;
        border-radius: 50%;
        padding: 0.1em;
    }

    .badge-image {
        background-color: $c10;
        border: 0.1em solid black;
        img {
            width:2.3em !important;
        }
    }

    .badge-abaker {
        background-color: $c18;
        border: 0.1em solid black;
        img {
            width:1.2em !important;
        }
        align-self: flex-start;
        margin-left: -0.5em;
        margin-top: -0.3em;

    }

    .badge-title {
        min-width: 1em;
        margin:0.1em 0 0.1em 0.5em;
        background: $c6;
        padding: 0em 0.1em;
        display: flex;
        border-radius: 50%;
        min-width: 1em;
        justify-content: center;
        align-items:  center;


    }

    .badge-action {
        border: 0.1em solid black;
        padding:0.3em;
        img {
            width:1.2em !important;
        }
    }

    .players_online_open {

        padding:0.3em;
        border-radius: 0.5em;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        >div:nth-child(1) { 
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            flex-wrap: wrap;
            font-size:1.2em;  
            img {
                width:1em;
                height:1em;
            }
        }


        .players_online_content {
            display: flex;
            flex-wrap: wrap;
            background-color:#0007;
            padding:1em 0.5em;
            border-radius: 0.5em;
            overflow-y: auto;
            align-content: flex-start;
            flex-grow: 1;
        }

        .players_online_player {
                display:flex;
                background: $c17;
                flex-direction: column;
                margin: 0.2em;
                padding: 0.1em 0.5em;
                align-items: center;
                border-radius: 0.5em;
                box-shadow: $box-shadow-normal;
                img {
                    width:1.5em;
                }

                //Player Data
                >div:nth-child(1) {
                    cursor:pointer;
                    display: flex;
                    flex-direction: column;
                    min-width: 8em;


                    >div:nth-child(2) {
                        display: flex;
                        justify-content:  space-between;
                        align-items: center;

                        >div:first-child {
                            display: flex;
                            align-items: center;

                            >div:last-child {
                            }
                        }
                    }
                }

                //Player Actions
                >div:nth-child(2) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    background: $c10;
                    border-radius: 1em;
                    margin-top:0.3em;

                    >div:last-child(2) {
                        margin: 0.2em;
                        padding: 0.1em;
                        border-radius: 1em;
                        background: $c0;
                        min-width: 1.2em;
                        display: flex;
                        justify-content: center;

                    }

                }

        }

    }

    .players_online_content_table {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content:flex-start;
        background-color:#0007;
        padding:1em 0.5em;
        border-radius: 0.5em;
        overflow-y: auto;
        flex-grow: 1;
        img {
            width: 2em;
        }

        table {
            width: 100%;
        }

        tr {
            width: 100%;
        }

        .poct_buttons {
            display: flex;
            justify-content: center;

            >div {
                background: $c6;
                cursor:pointer;
                box-shadow: $box-shadow-normal;
                margin:0.3em;
                padding:0.3em;
                border-radius: 0.5em;
            }
        }

        .rankingBox {
            background: $c8;
            border-radius: 0.3em;
            padding:0.3em;
            text-align: center;
        }

        .buttons-row {
            >div {
                background-color:$c16;
                display: flex;
                justify-content: center;
                margin:0.2em;
                border-radius: 0.3em;

                >div:last-child {
                    background: red;
                    border-radius: 50%;
                    min-width: 1em;
                    display: flex;
                    justify-content: center;
                    padding:0 0.2em;
                    margin-left:0.2em;
                }
            }
        }
    }

    

    .SelectedPlayer {

        background:#0000;
        border-radius: 0.5em;
        position: relative;

        >div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background:#0009;
            color:$c1;
            margin:0em 1em 0 1em;
            border-radius: 1em 1em 0 0;
            padding:0.3em 0.5em;
        }

        >div:last-child {

            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            margin:0 1em;
            background:$c5;
            padding:0.5em 0;
            align-items: center;
            border-radius: 0 0 1em 1em;

            .PlayerInfo {
                background:$c14;
                padding: 0.7em;
                border-radius: 0.5em;
                display: flex;
                flex-wrap: wrap;
                margin:0.3em;
                text-align: center;

                >div {
                    >div:last-child {
                        background: $c8;
                        border-radius: 0.3em;
                        padding:0 0.3em;
                        margin:0.4em 0.4em;
                        display: flex;
                        height: 2.3em;
                    }
                }

                >div:last-child{
                    >div:last-child{ 
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width:1.42em;
                        }
                    }
                }

            }

            .PlayerIntro {
                background:$c17;
                flex-grow: 0.2;
                padding:0.3em;
                border-radius: 0.5em;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .introBadge {
                    position: relative;
                    >div:first-child {
                        border:0.1em solid black;
                        border-radius: 50%;
                        display: flex;
                        padding:0.1em;
                        background:$c8;
                        img {
                            width: 3em;
                        }
                    }
                    >div:last-child {
                        position:absolute;
                        top:-0.1em;
                        left:2.5em;
                        width:1em;
                        background:$c7;
                        height:1em;                    
                        border:0.1em solid black;
                        border-radius: 50%;
                        img {
                            width: 1em;
                        }
                    }
                }

                >div:last-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    >div {
                        padding:0.3em;
                        margin: 0.2em 0;
                        border-radius: 0.4em;
                        background:$c6;
                    }
                }

            }

            .playerActions {
                display: flex;
                padding: 0.7em;
                border-radius: 0.5em;
                align-items: flex-start;
                text-align: center;
                flex-wrap: wrap;
                >div {
                    margin:0.5em
                }
            }
        
        }
    }
    
    .headerMenu {
        padding:0 0.5em;        
        background: lighten($c11,10%);
        border-radius: 0.5em 0.5em 0 0em;
        display: flex;
        align-items: center;

        >div:first-child {
            display: flex;
            align-items: center;
            >img {
                filter:invert(1)
            }
        }
    }

    .headerPending {
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;
        padding:0.2em;
        align-items: center;
        >div {
            margin:0.2em;
            display: flex;
            background:$c17;
            padding: 0 0.5em;
            border-radius: 0.5em;
            align-items: center;
            cursor:pointer;
            box-shadow: $box-shadow-normal;
            >div:last-child {                
                margin:0.1em 0 0.1em 0.5em;
                background: $c6;
                padding: 0em 0.1em;
                display: flex;
                border-radius: 50%;
                min-width: 1em;
                justify-content: center;
                align-items:  center;
            }

        }
    }

    .verticalContentTitle {
        flex-basis:100%;
        text-align: center;
        margin: 0.5em;
    }

    .active {
        padding:0 0.5em;
        background: darken($c11,10%);
    }
    .sactive {
        padding:0 0.5em;
        background: lighten($c17,20%) !important;
    }

    .players_online_modal {
        padding:0.5em;
        text-align: center;
        margin-top: 0.5em;
        >div:last-child {
            margin-top:1em;
            display: flex;
            justify-content: space-around;
            >div {
                background: $c9;
                border-radius: 0.5em;
                margin:0.2em;
                padding: 0.5em;
                box-shadow: $box-shadow-normal;
                cursor:pointer;
            }
            >div:hover {
                background:$c10;
            }
        }
    }

    @media (orientation: portrait) {
        .players_online_content,.players_online_content_table {
        }    
        .mainContainer>div {
            height: 95vh;            
        }
    }

</style>
