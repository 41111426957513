
import defaulttheme from './themes/default'
import dark from './themes/dark'
import classic from './themes/classic'
//import awesome from './themes/awesome'
import velvet from './themes/velvet'
import wood from './themes/wood'
import universe from './themes/universe'
import forest from './themes/forest1'
import ice from './themes/ice'
import natural from './themes/natural'
export default {
  'default':defaulttheme,
  dark,
  classic,
  wood,
  velvet,
  universe,
  forest,
  natural,
  ice
}