export default {
    "background": "#283845",
    "path": "",
    "board": {
      "background": "#ffffff",
      "border": "#283845",
      "bar": "#283845",
      "points": [
        {
          "background": "#b8d8d8",
          "border": "#7ea8be"
        },
        {
          "background": "#7ea8be",
          "border": "#5e7480"
        }
      ]
    },
    "dice": [
      {
        "background": "#fefbd8",
        "border": "#333",
        "dot": "#555",
        "dotborder": "#333"
      },
      {
        "background": "#5e7480",
        "border": "#aaa",
        "dot": "#fefbd8",
        "dotborder": "#fefbd8"
      },
      {
        "background": "#7ea8be",
        "border": "black",
        "dot": "#fefbd8",
        "dotborder": "#fefbd8"
      },
      {
        "background": "#f4a688",
        "dot": "#333",
        "dotborder": "#333",
        "border": "#f4a688"
      }
    ],
    "cube": {
      "background": "#fefbd8",
      "color": "#283845"
    },
    "checkers": [
      {
        "background": "#fefbd8",
        "line": "#aaa",
        "object1": "#333",
        "object2": "#555",
        "color": "#283845"
      },
      {
        "background": "#5e7480",
        "line": "#ddd",
        "object1": "#fefbd8",
        "object2": "#b8d8d8",
        "color": "#fefbd8"
      }
    ],
    "buttons": {
      "default": {
        "background": "#334747",
        "border": "#283845",
        "hover": "#f4a688",
        "off": "#5e7480"
      },
      "light": {
        "background": "#b8d8d8",
        "border": "#283845",
        "hover": "#f4a688",
        "off": "#333"
      },
      "soft": {
        "background": "#7ea8be",
        "border": "#283845",
        "hover": "#f4a688",
        "off": "#333"
      },
      "warning": {
        "background": "#f4a688",
        "border": "#283845",
        "hover": "#7ea8be",
        "off": "#333"
      }
    },
    "elements": {
      "box1": {
        "background": "#5e7480",
        "color": "#fefbd8"
      },
      "box2": {
        "background": "#b8d8d8",
        "color": "#283845"
      },
      "scroll": {
        "bar": "#283845",
        "hover": "#7ea8be"
      },
      "chrono": {
        "total": {
          "background": "#fefbd8",
          "color": "#283845"
        },
        "current": {
          "background": "#283845",
          "color": "#fefbd8"
        }
      }
    }
  }