export default {
    background: '#141414', // deep night color
    path: '',
    board: {
      background: '#4C2E0A', // tree bark color
      border: '#8D3B03', // brown earth color
      bar: '#8D3B03',
      points: [
        {
          background: "#9A7048", // dry leaves color
          border: "#9A7048"
        },
        {
          background: "#2E501E",
          border: "#2E501E" // forest green color
        }
      ]
    },
    dice: [
      {
        background: "#ECF0F1", // foggy color
        border: "#333",
        dot: "#000",
        dotborder: "#000"
      },
      {
        background: "#222",
        border: "#aaa",
        dot: "#fff",
        dotborder: "#000"
      },
      {
        background: "grey",
        border: "black",
        dot: "white",
        dotborder: "white",
      },
      {
        background: "#900",
        dot: "#000",
        dotborder: "#000",
        border: "grey"
      }
    ],
    cube: {
      background: "#ECF0F1", // foggy color
      color: "#2E501E", // forest green color
    },
    checkers: [
      {
        background: "#ECF0F1", // foggy color
        line: "#777",
        object1: "#3C1E0A", // tree bark color
        object2: "#141414", // deep night color
        color: "#000",
      },
      {
        background: "#141414", // deep night color
        line: "#666",
        object1: "#FAC078", // dry leaves color
        object2: "#2E501E", // forest green color
        color: "#fff"
      }
    ],
    buttons: {
      default: {
        background: '#6D4C3A', // bark color
        border:"#2E501E", // forest green color
        hover:"#F9D976", // warm lantern color
        off:"#200"
      },
      light: {
        background: '#6D4C3A', // tree bark color
        border:"#2E501E", // forest green color
        hover:"#F9D976", // warm lantern color
        off:"#000"
      },
      soft: {
        background: '#3A945B', // leaf green color
        border:"#2E501E", // forest green color
        hover:"#F9D976", // warm lantern color
        off:"#000"
      },
      warning: {
        background:'#F9D976', // warm lantern color
        border:"#2E501E", // forest green color
        hover:"#3A945B", // leaf green color
        off:"#000"
      }
    },
    elements: {
      box1: {
        background:"#6D4C3A", // tree bark color
        color:"#000"
      },
      box2: {
        background:"#2E501E", // forest green color
        color:"#fff"
      },
      scroll: {
        bar:"#2E501E", // forest green color
        hover:"#3A945B" // leaf green color
      },
      chrono: {
        total: {
          background:"#F9D976", // warm lantern color
          color:"#000",
        },
        current: {
            background:"#2E501E", // forest green color
            color:"#fff",  // foggy white
          }
        }
      }
    }
    
    
    
    
    
    
      