export default {
    "background": "#1a1a2e",
    "path": "",
    "board": {
      "background": "#e8f1f5",
      "border": "#16213e",
      "bar": "#16213e",
      "points": [
        {
          "background": "#e94560",
          "border": "#b0304e"
        },
        {
          "background": "#ff9a3c",
          "border": "#cc7a2c"
        }
      ]
    },
    "dice": [
      {
        "background": "#ffffff",
        "border": "#1b262c",
        "dot": "#0f3460",
        "dotborder": "#0f3460"
      },
      {
        "background": "#0f3460",
        "border": "#1b262c",
        "dot": "#ffffff",
        "dotborder": "#ffffff"
      },
      {
        "background": "#21e1e1",
        "border": "#1b262c",
        "dot": "#ffffff",
        "dotborder": "#ffffff"
      },
      {
        "background": "#ee6f57",
        "dot": "#1a1a2e",
        "dotborder": "#1a1a2e",
        "border": "#1b262c"
      }
    ],
    "cube": {
      "background": "#ffffff",
      "color": "#16213e"
    },
    "checkers": [
      {
        "background": "#ffffff",
        "line": "#16213e",
        "object1": "#0f3460",
        "object2": "#1a1a2e",
        "color": "#0f3460"
      },
      {
        "background": "#0f3460",
        "line": "#e8f1f5",
        "object1": "rgb(255, 219, 168)",
        "object2": "#ff9a3c",
        "color": "#ffffff"
      }
    ],
    "buttons": {
      "default": {
        "background": "#e94560",
        "border": "#16213e",
        "hover": "#ff9a3c",
        "off": "#16213e"
      },
      "light": {
        "background": "#e94560",
        "border": "#16213e",
        "hover": "#ff9a3c",
        "off": "#1a1a2e"
      },
      "soft": {
        "background": "#21e1e1",
        "border": "#16213e",
        "hover": "#ff9a3c",
        "off": "#1a1a2e"
      },
      "warning": {
        "background": "#ff9a3c",
        "border": "#16213e",
        "hover": "#e94560",
        "off": "#1a1a2e"
      }
    },
    "elements": {
      "box1": {
        "background": "#16213e",
        "color": "#ffffff"
      },
      "box2": {
        "background": "#21e1e1",
        "color": "#16213e"
      },
      "scroll": {
        "bar": "#16213e",
        "hover": "#21e1e1"
      },
      "chrono": {
        "total": {
          "background": "#ff9a3c",
          "color": "#16213e"
        },
        "current": {
          "background": "#16213e",
          "color": "#ff9a3c"
        }
      }
    }
  }