<template>
    <svg 
        id="team" 
        viewBox="0 0 362 63" 
        style="background-color:#ffffff00"  
        version="1.1" xml:space="preserve" 
        x="0px"  
        y="0px"  
        width="100%"  
        bordercolor="#666666"  borderopacity="1"  
        objecttolerance="10"  gridtolerance="10"  guidetolerance="10" 
        showgrid="false" >

        <g id="Layer%201">
            
            <g id="teamGeneral" class="teamGeneral">
                <g id="g4">
                    <path id="Ellipse"  d="M 1 31.5 C 1 14.6551 14.7968 1 31.8166 1 C 48.8364 1 62.6332 14.6551 62.6332 31.5 C 62.6332 48.3449 48.8364 62 31.8166 62 C 14.7968 62 1 48.3449 1 31.5 Z"  stroke="#56504a"    fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="Ellipse2"  d="M 6.4785 31.5 C 6.4785 17.6497 17.8226 6.4222 31.8166 6.4222 C 45.8106 6.4222 57.1547 17.6497 57.1547 31.5 C 57.1547 45.3503 45.8106 56.5778 31.8166 56.5778 C 17.8226 56.5778 6.4785 45.3503 6.4785 31.5 Z"  stroke="#56504a"    fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:1;" />
                </g>
                <path id="teamGeneralInterior1" class="teamGeneralInterior" d="M 12.0939 31.5 L 27.8721 40.8696 C 26.0132 39.7375 24.8681 37.3484 24.3929 34.6232 L 39.2403 34.6232 C 38.7645 37.3484 37.6199 39.7375 35.7611 40.8696 L 51.5392 31.5 L 35.7611 22.1304 C 37.5417 23.2094 38.7048 25.614 39.2086 28.3768 L 24.4246 28.3768 C 24.9286 25.614 26.0915 23.2094 27.8721 22.1304 L 12.0939 31.5 Z"  fill="#56504a" style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
            </g>

            <g id="g19">
                <g id="g12">
                    <path id="Ellipse3"  d="M 224.4327 31.5 C 224.4327 14.6551 238.2295 1 255.2494 1 C 272.269 1 286.0659 14.6551 286.0659 31.5 C 286.0659 48.3449 272.269 62 255.2494 62 C 238.2295 62 224.4327 48.3449 224.4327 31.5 Z"  stroke="#56504a" fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="Ellipse4"  d="M 229.9113 31.5 C 229.9113 17.6497 241.2554 6.4222 255.2494 6.4222 C 269.2434 6.4222 280.5873 17.6497 280.5873 31.5 C 280.5873 45.3503 269.2434 56.5778 255.2494 56.5778 C 241.2554 56.5778 229.9113 45.3503 229.9113 31.5 Z"  stroke="#56504a" fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:1;" />
                </g>
                <g id="g17">
                    <path id="teamGuardInterior1" class="teamGuardInterior"   d="M 241.2244 27.6725 C 241.2244 22.5993 245.4105 18.4867 250.5743 18.4867 C 255.7382 18.4867 259.9242 22.5993 259.9242 27.6725 C 259.9242 32.7458 255.7382 36.8585 250.5743 36.8585 C 245.4105 36.8585 241.2244 32.7458 241.2244 27.6725 Z"  fill="#56504a"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="teamGuardInterior2" class="teamGuardInterior" d="M 269.2744 35.3275 C 269.2744 30.6018 265.6395 26.7062 260.9683 26.1951 C 261.3 27.1367 261.4825 28.1512 261.4825 29.2035 C 261.4825 34.2768 257.2965 38.3894 252.1326 38.3894 C 251.7788 38.3894 251.4323 38.3733 251.0885 38.3358 C 252.3556 41.9332 255.8317 44.5133 259.9242 44.5133 C 265.0881 44.5133 269.2744 40.4007 269.2744 35.3275 Z"  stroke="#56504a"  fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                </g>
            </g>
            
            <g id="g29"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;">
                <g id="g23"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;">
                    <path id="Ellipse6"  d="M 149.4986 31.5 C 149.4986 14.6551 163.2955 1 180.3152 1 C 197.335 1 211.1317 14.6551 211.1317 31.5 C 211.1317 48.3449 197.335 62 180.3152 62 C 163.2955 62 149.4986 48.3449 149.4986 31.5 Z"  stroke="#56504a"    fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="Ellipse7"  d="M 154.9771 31.5 C 154.9771 17.6497 166.3212 6.4222 180.3152 6.4222 C 194.3092 6.4222 205.6533 17.6497 205.6533 31.5 C 205.6533 45.3503 194.3092 56.5778 180.3152 56.5778 C 166.3212 56.5778 154.9771 45.3503 154.9771 31.5 Z"  stroke="#56504a"    fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:1;" />
                </g>
                <g id="g27"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;">
                    <path id="teamParInterior1" class="teamParInterior"   d="M 171.6371 16.0402 L 178.5796 46.9598 L 164.6949 46.9598 L 171.6371 16.0402 Z"  fill="#56504a"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="teamParInterior2" class="teamParInterior"   d="M 188.9932 16.0402 L 195.9355 46.9598 L 182.0508 46.9598 L 188.9932 16.0402 Z"  fill="#56504a"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                </g>
            </g>

            <!-- Odd Wounded!! -->
            <g id="g39"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;">
                <g id="g33"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;">
                    <path id="Ellipse8"  d="M 299.3668 31.5 C 299.3668 14.6551 313.1637 1 330.1835 1 C 347.2031 1 361 14.6551 361 31.5 C 361 48.3449 347.2031 62 330.1835 62 C 313.1637 62 299.3668 48.3449 299.3668 31.5 Z"  stroke="#56504a"    fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="Ellipse9"  d="M 304.8453 31.5 C 304.8453 17.6497 316.1895 6.4222 330.1835 6.4222 C 344.1774 6.4222 355.5216 17.6497 355.5216 31.5 C 355.5216 45.3503 344.1774 56.5778 330.1835 56.5778 C 316.1895 56.5778 304.8453 45.3503 304.8453 31.5 Z"  stroke="#56504a" fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:1;" />
                </g>
                <g id="g37"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;">
                    <path id="teamDruidaInterior1" class="teamDruidaInterior" d="M 323.6093 47.2245 L 330.7313 15.7756 L 316.4872 15.7756 L 323.6093 47.2245 Z"  fill="#56504a"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="teamDruidaInterior2" class="teamDruidaInterior"  d="M 336.7577 15.7756 L 343.8798 47.2245 L 329.6355 47.2245 L 336.7577 15.7756 Z"  fill="#56504a"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                </g>
            </g>

            <!-- Guards !! -->
            <g id="g49">
                <g id="g43">
                    <path id="Ellipse10"  d="M 75.2888 31.5 C 75.2888 14.6551 89.0856 1 106.1054 1 C 123.1252 1 136.922 14.6551 136.922 31.5 C 136.922 48.3449 123.1252 62 106.1054 62 C 89.0856 62 75.2888 48.3449 75.2888 31.5 Z"  stroke="#56504a"    fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="Ellipse11"  d="M 80.7673 31.5 C 80.7673 17.6497 92.1114 6.4222 106.1054 6.4222 C 120.0995 6.4222 131.4435 17.6497 131.4435 31.5 C 131.4435 45.3503 120.0995 56.5778 106.1054 56.5778 C 92.1114 56.5778 80.7673 45.3503 80.7673 31.5 Z"  stroke="#56504a"    fill="none"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:1;" />
                </g>
                <g id="teamImparInterior1">
                    <path id="teamImparInterior2" class="teamImparInterior"  d="M 106.6533 31.5 C 106.6533 26.4091 110.8231 22.2822 115.9667 22.2822 C 121.1104 22.2822 125.2802 26.4091 125.2802 31.5 C 125.2802 36.5909 121.1104 40.7178 115.9667 40.7178 C 110.8231 40.7178 106.6533 36.5909 106.6533 31.5 Z"  fill="#56504a"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                    <path id="teamImparInterior1" class="teamImparInterior" d="M 86.9307 31.5 C 86.9307 26.4091 91.1004 22.2822 96.2441 22.2822 C 101.3878 22.2822 105.5576 26.4091 105.5576 31.5 C 105.5576 36.5909 101.3878 40.7178 96.2441 40.7178 C 91.1004 40.7178 86.9307 36.5909 86.9307 31.5 Z"  fill="#56504a"  style="stroke:#ffffff;stroke-opacity:1;fill:#dbd9d6;fill-opacity:0;stroke-width:2;" />
                </g>
            </g>
        </g>
    </svg>	   

</template>

<script>
	export default {
		name: 'TeamLogoAnimated',
	}
</script>

<style lang="scss" scoped>

    .teamGeneralInterior {
        animation: team2 5s infinite ease-in-out;
    }

    #teamDruidaInterior1 {
        animation: team3 5s infinite ease-in-out;
    }
    #teamDruidaInterior2 {
        animation: team31 5s infinite ease-in-out;
    }


    #teamGuardInterior1 {
        animation: team4 5s infinite ease-in-out;
    }

    #teamGuardInterior2 {
        animation: team41 5s infinite ease-in-out;
    }

    .teamParInterior {
        animation: team5 5s infinite ease-in-out;
    }

    .teamImparInterior {
        animation: team6 5s infinite ease-in-out;
    }

    @keyframes team2 {
        0% {
            stroke:#00000000;
        }
        10% {
            stroke:#00000000;
        }
        20% {
            stroke:#946238;
        }
        90% {
            stroke:#ffffff;
        }
        100% {
            fill-opacity: 0;
            stroke-opacity: 0;
        }
    }

    @keyframes team3 {
        0% {
            stroke:#00000000;
        }
        20% {
            stroke:#00000000;
        }
        30% {
            stroke:#b29b28;
        }
        90% {
            stroke:#fff;
        }
        100% {
            fill-opacity: 0;
            stroke-opacity: 0;
        }
    }

    @keyframes team31 {
        0% {
            fill-opacity: 0;
            stroke:#00000000;
        }
        20% {
            fill-opacity: 0;
            stroke:#00000000;
        }
        30% {
            fill-opacity: 0.5;
            stroke:#15441a;
        }
        90% {
            fill-opacity: 1;
            stroke:#fff;
        }
        100% {
            fill-opacity: 0;
            stroke-opacity: 0;
        }
    }

    @keyframes team4 {
        0% {
            stroke:#00000000;
        }
        30% {
            stroke:#00000000;
        }
        40% {
            stroke:#eae428;
        }
        90% {
            stroke:#fff;
        }
        100% {
            fill-opacity: 0;
            stroke-opacity: 0;
        }
    }

    @keyframes team41 {
        0% {
            fill-opacity: 0;
            stroke:#00000000;
        }
        30% {
            fill-opacity: 0;
            stroke:#00000000;
        }
        40% {
            fill-opacity: 0.5;
            stroke:#eae428;
        }
        90% {
            fill-opacity: 1;
            stroke:#fff;
        }
        100% {
            fill-opacity: 0;
            stroke-opacity: 0;
        }
    }

    @keyframes team5 {
        0% {
            stroke:#00000000;
        }
        40% {
            stroke:#00000000;
        }
        50% {
            stroke:#eae428;
        }
        90% {
            stroke:#fff;
        }
        100% {
            fill-opacity: 0;
            stroke-opacity: 0;
        }
    }

    @keyframes team6 {
        0% {
            stroke:#00000000;
        }
        50% {
            stroke:#00000000;
        }
        60% {
            stroke:#eae428;
        }
        90% {
            stroke:#fff;
        }
        100% {
            fill-opacity: 0;
            stroke-opacity: 0;
        }
    }


    @keyframes fader{
        0%{
            transform: scale(1);              
        }
        50%{
        transform: scale(0.95);
        }
        100%{
        transform: scale(1); 
        }

    }

</style>