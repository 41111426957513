<template>
    <div class="share-game-result-modal">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="header" @click="closeModal"> 
                <div>
                    <h3>{{$t('lobby.action.shareabak')}}</h3>
                </div>
                <div class="close_button">✖</div>
            </div>
          <div class="frame">
            <div> 
                <div class="share_preview">
                    <img :src="shareImage" height="630" width="1200"/>
                </div>
            </div>
            <div> 
                <div class="button_title">{{$t('lobby.action.sharein')}}</div>
                <div class="social-media-buttons">
                    <a :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`" target="_blank" class="social-media-button facebook-button">Facebook</a>
                    <a :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareLink)}`" target="_blank" class="social-media-button twitter-button">Twitter</a>
                    <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareLink)}`" target="_blank" class="social-media-button linkedin-button">LinkedIn</a>
                </div>
                <div class="button_title">{{$t('lobby.action.cpypasteshare')}}</div>
                <div class="link_holder"> 
                    <div>
                        <div v-if="share_message">{{share_message}}</div>
                        <a v-else :href="shareLink" target="_blank">{{ shareLink }}</a>
                    </div>
                    <div @click="copyToClipboard()"><img :src="require('@/assets/img/buttons/copy.svg')"></div>
                </div>
            </div>            
          </div>
        </div>
      </div>
  </template>
  
  <script>
  
  
  export default {
    name: 'ShareAbak',
    props: { },
    data() {
      return {
        shareImage: "https://www.abak.me/img/abak_screenshot.png",
        shareLink: "https://www.abak.me",
        share_message: "",
      };
    },
    methods: {
      closeModal() {
        this.$emit('close-share');
      },
      copyToClipboard() {
        var copyText = this.shareLink;        
        navigator.clipboard.writeText(copyText);
        this.share_message="Copied";
        setTimeout(() => this.share_message="",500);
      }

    }
  };
  </script>
  
  <style lang="scss" scoped>
  .share-game-result-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background: $g5;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    position: relative;
    margin:2rem;
    z-index: 10000;
  }

  .share_preview {
    margin-top:0.5em;
    display: flex;
    border-radius: 1em;
    background:$c7;
    img {
        max-width: 100%;
        height: auto;
        border-radius: 0.5em;
    }
  }

  .social-media-buttons {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    display: flex;
    background-color:$c1;
    padding:0.4em;
    border-radius: 0.3em;
    }

.social-media-button {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 0.5em;
  font-size: 1.3em;
}

.facebook-button {
  background-color: #3b5998;
}

.twitter-button {
  background-color: #1da1f2;
}

.linkedin-button {
  background-color: #0077b5;
}

.frame {
    display:flex;
    align-items: center;
    >div {
        width:100%;
    }
}
.link_holder {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 0.5rem;
    background-color:$c0;
    align-items: center;
    padding: 0.2em;
    border-radius: 0.5em;
    div {
        margin: 0 1em;
    }

    a {
        color:aliceblue;
    }

    img {
        height: 1.5em;
    }
}

.button {
    background:$c9;   
    width: 10em; 
    border-radius: 1em;  
    height: 2em;  
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background:$c8;   
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        margin:0;
    }
}

.close_button {
    background-color: $c0;
    padding:0.5em;
    border-radius: 1em;
}

.preshare {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button_title {
    margin-top: 0.5em;
    font-size: 1.2em;
}

@media(orientation: landscape )  {
    .social-media-buttons {
        flex-direction: column;
        a {
            margin:0.2em;
        }
    }

    .frame>div:nth-child(2) {
        margin-left: 1em;
    }
}

@media(orientation: portrait )  {
    .frame {
        flex-wrap: wrap;
    }
}


  </style>
  