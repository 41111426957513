<!-- FeedbackForm.vue -->
<template>
    <div class="share-game-result-modal" :class="{ vertical: screenMode == 'vertical' }">
      <div class="modal-background"></div>
      
      <div class="modal-content">
        <div class="header" @click="closeModal"> 
            <div>
                <h3>{{ $t('lobby.action.contact') }}</h3>
            </div>
            <div class="close_button">✖</div>
        </div>
        <div> {{ $t('lobby.action.contactsocial') }} </div>
        <div class="socialtag">@abakevolution</div>
                <div class="contenedorlogos" >
                    <div v-on:click="redirect('facebook');"><img style="width:100%;" src='@/assets/img/logo/facebook_icon.svg' alt="facebook icon"></div>
                    <div v-on:click="redirect('twitter');"><img style="width:100%;" src='@/assets/img/logo/twitter_icon.svg' alt="twitter icon"></div>
                    <div v-on:click="redirect('instagram');"><img style="width:100%;" src='@/assets/img/logo/instagram_icon.svg' alt="instagram icon"></div>
                    <div v-on:click="redirect('youtube');"><img style="width:100%;" src='@/assets/img/logo/youtube_icon.svg' alt="youtube icon"></div>
                    <div v-on:click="redirect('linkedin');"><img style="width:100%;" src='@/assets/img/logo/linkedin_icon.svg' alt="linkedin icon"></div>
                </div>                 
      </div>
  </div>
</template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "FeedbackForm",
    data() {
      return {
        feedback: {
          name: "",
          email: "",
          message: "",
        },
      };
    },
    props: ['screenMode'],
    emits: ['close'],
    methods: {
      async submitFeedback() {
        try {
          const response = await axios.post(
            "https://your-remote-server.com/api/feedback",
            this.feedback
          );
  
          if (response.status === 200) {
            alert("Feedback sent successfully!");
            this.feedback.name = "";
            this.feedback.email = "";
            this.feedback.message = "";
          } else {
            alert("An error occurred while sending your feedback.");
          }
        } catch (error) {
          alert("An error occurred while sending your feedback.");
        }
      },
      closeModal() {
        this.shareImage = "";
        this.shareLink = "";
        this.$emit('close');
      },
      redirect: function(target) {
                let urls={
                    linkedin:"https://www.linkedin.com/company/abak-evolution/",
                    twitter:"https://twitter.com/abakevolution/",
                    facebook:"https://www.facebook.com/abakevolution/",
                    instagram:"https://www.instagram.com/abakevolution/",
                    youtube:"https://www.youtube.com/channel/UCnp5RL6uzIbvw0JZ3BE5Emw"
                }
                window.location.href = urls[target];
            }
    },
  };
  </script>
  
  <style lang='scss' scoped>


.vertical {
  font-size:1.4em;
}
  .feedback-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    form>div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label,
  input,
  textarea {
    margin-bottom: 1rem;
  }
  
  button {
    align-self: center;
  }

  .share-game-result-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

  }
  
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background: $g5;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    position: relative;
    margin:2rem;
    z-index: 10000;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:1em;

    h3 {
        margin:0;
    }
  }

  .contenedorlogos {
        display:flex;
        justify-content: center;
    }
    .contenedorlogos >div {
        background-color: white;
        padding:0.5em;
        display: flex;
        border-radius: 1em;
        box-sizing: border-box;
        width:20%;
        max-width:3em;
        margin:0.5em;
        cursor:pointer;

      img {
          width:1em;
        }
    }

    .contenedorlogos>div:hover {
        background-color: yellow;
    }

    .socialtag {
      background:$c5;
      font-size: 1.2em;
      padding:0.5em;
      border-radius: 0.5em;
    }

.close_button {
    background-color: $c0;
    padding:0.5em;
    border-radius: 1em;
}


.button_title {
    margin-top: 0.5em;
    font-size: 1.2em;
}


.social-media-buttons {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    display: flex;
    background-color:$c1;
    padding:0.4em;
    border-radius: 0.3em;
    }

.social-media-button {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 0.5em;
  font-size: 1.5em;
}

.facebook-button {
  background-color: #3b5998;
}

.twitter-button {
  background-color: #1da1f2;
}

.linkedin-button {
  background-color: #0077b5;
}


@media(orientation: landscape )  {
    .social-media-buttons {
        flex-direction: column;
        a {
            margin:0.2em;
        }
    }

    .frame>div:nth-child(2) {
        margin-left: 1em;
    }
}

@media(orientation: portrait )  {
    .frame {
        flex-wrap: wrap;
    }
}


  </style>
  