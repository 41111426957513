<template>
<div class="TipsFrame">
    <div class="TipsMessage">
        <div class="TipsMessageHeader">
            <div></div>
            <div class="dotContainer">
                <template v-for="checkerTips,k in validCheckersTips"
                :key="'htip'+k">  
                <div             
                    v-for="tip,i in checkerTips" :key="i" class="tipContentSelector"
                    :class="{selected: localSelected==k*100+i}"
                    @click="clickPage(k,i)"
                    >●</div>
                </template>
            </div>
            <div>
                <div class="closeButton" style="justify-self:flex-end;" @click="$emit('close-tips')">
                    <img :src="require('@/assets/img/buttons/close.svg')"/>
                </div>
            </div>
        </div>
        <div class="TipsMessageContet">        
            <template v-for="checkerTips,k in validCheckersTips"
            :key="'tip'+k">
                <template v-for="tip,i in checkerTips" :key="i">                                
                    <div class="tipContentType" v-if="localSelected==k*100+i">
                        <div class="tipContentTitle">{{t("tips."+tip.type+".1")}}</div>
                        <div class="tipContentBody"> 
                            <div> 
                                <div>{{t("tips."+tip.type+".2")}}</div>
                                <div>{{t("tips."+tip.type+".3")}}</div>
                            </div>
                            <div><img :src="require('@/assets/img/ejemplos/tip-'+tip.type+'.svg')"></div>
                        </div>
                    </div>
                </template>            
            </template>
        </div>
    </div>
</div>

</template>

<script>

    import { useI18n } from 'vue-i18n'

	export default {
		name: 'TipsMessages',
        props: ['tips','selectedTipPage'],
        data: function() {
            return {
                localSelected:0
            }
        },
        emits: ['close-tips','change-tip'],
        computed: {
            validCheckersTips: function() {
                let validTips = []
                for (let t in this.tips) {
                    if (this.tips[t].tips.length>0) 
                        validTips.push(this.tips[t].tips);
                }
                return validTips;
            }
        },
        methods: {
            clickPage: function(k,i) {
                this.localSelected=k*100+i;
            }
        },
        
        mounted: function() {
            this.$nextTick(function () {
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },          
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .TipsFrame {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top:0;
        background:#00000065;
        z-index:100;
    }
    .TipsMessage {
            position: absolute;
            left: 15%;
            width: 70%;
            height: 60%;
            top:15%;
            background:$c6;
            z-index:100;
            box-shadow: $box-shadow-normal;
            border-radius: 1em;            
        }

    .tipContentType {
        display: flex;   
        flex-direction: column;     
    }

    .tipContentTitle {
        background:$c7;  
        text-align: center;  
        padding:0.3em;
        font-size: 1.3em;
    }
    .tipContentBody {
        display: flex;
        background:$c6;
        justify-content: space-around;
        padding: 1em;
        
        >div {
            padding:0.2em;
        }

        img {
            width:100%;
            border-radius: 0.5em;
            max-height: 10em;
            background:$c10;
        }

        >div:first-child {
            width: 60%;
            >div:first-child {
                font-size:1.2em;
            }
            >div:last-child {
                padding-top:1em;
                font-weight: lighter;
            }
        }

        >div:last-child {
            width: 40%;
        }
    }


    .TipsMessageHeader {
        display: flex;
        justify-content: space-between;
        padding:0.3em 1em;
    }

    .dotContainer {
        display: flex;
        align-content: center;
        padding:0.3em;

        div {
            margin: 0 0.3em;
            padding: 0.1em;
            width: 1.2em;
            text-align: center;
            align-items: center;
            background: $c6;
            
            border-radius: 50%;
        }
    }

    .selected {
        filter:invert(1);
    }

    .closeButton {
        padding: 0.5em;
        background-color: black;
        border-radius: 1em;
        height: 1em;
        width: 1em;
        img {
            width:1em;
        }
    }

    @media (orientation: portrait) {
        .tipContentBody {
            flex-direction: column;
            >div:first-child {
                width: 100% !important;
            }
            >div:last-child {
                width: 80% !important;
                text-align: center;
                margin-left:10%;
                margin-top:10%;
            }
        }
    }

</style>
