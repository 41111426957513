/*

This methods provides tools for the Chat functionality.
Chat is handled by an object defined in Play.vue. At the same level, 
the getPlayerStatus() function defined in lobby.js retrieves the messages and set some variables.

*/

const { APIURL, APIPORT, WEBPROTOCOL } = require('../../assets/js/enviroment.js');

// Set UI variables to open the chat box.
export function openChat(friend_id) {  
    this.uIstate.lastSeen.show = false;
    this.uIstate.chat.show = true;
    //this.uIstate.chat.newmessages = false;
    if (friend_id) {
      this.uIstate.chat.selected_chat = friend_id;
    }
    //this.uIstate.chat.lastRead = this.uIstate.chat.lastFetched;  
    this.actionEvent();  
    if (this.zone == 'game')
      this.updateLastSeen('game',{ id:this.matchID, type:this.getG.type, opponent: this.getG.opponent });           
    else {
      this.updateLastSeen('lobby');
    }
  }

// Set UI variables to close the chat box.
export function closeChat() {        
    this.uIstate.chat.lastRead = this.lastContextMessageID(this.contextMessages());
    this.$cookie.setCookie("ui.chat.lastRead",this.uIstate.chat.lastRead);
    this.$cookie.setCookie("ui.chat.lastReadbyChat",this.uIstate.chat.lastReadbyChat);
    this.uIstate.chat.show=false;
    this.uIstate.chat.newmessages = {};
    this.uIstate.chat.matchID = null;
    this.uIstate.chat.selected_chat = null;
    //console.log("close",this.uIstate.chat.lastRead);
}

// Calculates whether there are new messages.
/*
export function newChatMessages() {
    let lastMessageID = this.lastContextMessageID(this.contextMessages());
    return lastMessageID > this.uIstate.chat.lastRead;
}
*/

function chatCounterPart( chatElement , player_id ) {
  if (chatElement.session_id) return chatElement.session_id;
  else {
    return chatElement.receiver_id == 0 ? 
        chatElement.receiver_id : 
        chatElement.sender_id != player_id ? 
        chatElement.sender_id : 
        chatElement.receiver_id; 
    }
}

// Send Message
export function sendGlobalChatMessage(event) {  
    //console.log(event);
    let data = { 
      sender_id: this.player.id,
      text: event.text,
      receiver_id: event.receiver_id || 0,
      session_id: event.session_id
    };
    let lastchatid = this.uIstate.chat.messages[this.uIstate.chat.messages.length-1].id || 0;
    this.uIstate.chat.messages.push(
      {
        date_sent:Date.now(),
        id:lastchatid+1,
        receiver_id: event.receiver_id || 0,
        rimage:null,
        rname:null,
        sender_id:this.player.id,
        simage:this.player.image,
        sname:this.player.name,
        scountry:this.player.country,
        session_id:event.session_id,
        symbol:null,
        text:event.text,
        temporal:true
      }
    );
    //if (this.player) data = { player: this.player , last_action: zone };
    //if (this.players && this.playerID) data = { player: this.players[this.playerID] , last_action: zone };
    this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/chat/send`,data).then( 
      response => {
        let counterpart = chatCounterPart(data,this.player.id);
        let chat_id = response.data.id;
        this.uIstate.chat.lastReadbyChat[counterpart]=chat_id;
    });
    this.actionEvent();  
    if (this.zone == 'game')
      this.updateLastSeen('game',{ id:this.matchID, type:this.getG.type, opponent: this.getG.opponent });           
    else {
      this.updateLastSeen('lobby');
    }
  }


export function lastContextMessageID(contextMessages) {
    if ( contextMessages.length == 0 ) return 0;
    return contextMessages[contextMessages.length-1].id;                
}      

export function contextMessages() {
    let returnMessages =  [];
    let messages = this.uIstate.chat.messages;
    for (let m in messages) {
        let pass=true;
        
        if (this.zone=='game') {
            pass= this.uIstate.chat.filters.onlythisgame &&  messages[m].session_id != this.matchID ? false : pass;
            //pass= this.uIstate.chat.filters.showvisitors &&  messages[m].receiver_id != this.player.id ? false : pass;
        }

        if (this.zone=='lobby') { 
          pass= messages[m].session_id ? false : pass;
        }

        pass= this.uIstate.chat.filters.onlytome &&  messages[m].sender_id != this.player.id ? false : pass;
        if (pass) returnMessages.push(messages[m]);
    }
    //this.uIstate.chatLastId = returnMessages[returnMessages.length-1].id
    return returnMessages;
}