<template>

    <div :style="{background: theme.board.bar}" style="padding:0.5em;"> 
        <div class='contenedor-tabla' 
            @click="clickBoard()"            
            :style="{ width: anchoCanvas + 'px', height: altoCanvas + 'px' , background: theme.board.background }"
            >
            
            <!-- *********************************  Columnas de la tabla Grande  ***************************************************-->
            <Point :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'25'" :anchoColumna="anchoColumna"
                   :left ="0" :top="0" inverted=false
                   :showPoints ="false"
                   :colors="theme.board"
            />

            <Point :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'0'" :anchoColumna="anchoColumna"
                   :left ="0" :top="50" inverted=false
                   :showPoints ="false"
                   :colors="theme.board"
            />

            <Point :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'26'" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*7" :top="50" inverted=false
                    :showPoints ="false"
                    :colors="theme.board"
            />

            <Point :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'27'" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*7" :top="0" inverted=false
                    :showPoints ="false"
                    :colors="theme.board"
            />

            <Point :type="'c'" :typeColor="null" 
                   :playerID ="getPlayerID"
                   :index="'25'"
                   :left ="(anchoColumna+separacionColumna2)*14" :top="0" inverted=false
                    :showPoints ="false"
                    :colors="theme.board"
            />

            <Point :type="'c'" :typeColor="null" 
                   :playerID ="getPlayerID"
                   :index="'0'"
                   :left ="(anchoColumna+separacionColumna2)*14" :top="50" inverted=false
                    :showPoints ="false"
                    :colors="theme.board"
            />

            <Point 
                v-for="i in [1,2,3,4,5,6]" :key ="'pos_0'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="i%2+1"                 
                :index="i+12" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="0" :inverted="true"
                :showPoints ="false"
                :direction="1"
                :colors="theme.board"

            />

            <Point 
                v-for="i in [1,2,3,4,5,6]" :key ="'pos_1'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="(i+1)%2+1"                 
                :index="19-(i+6)" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="50" :inverted="false"
                :showPoints ="false"
                :direction="1"
                :colors="theme.board"
            />

            <Point 
                v-for="i in [8,9,10,11,12,13]" :key ="'pos_0'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="(i+1)%2+1"                 
                :index="i+11" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="0" :inverted="true"
                :showPoints ="false"
                :direction="1"
                :colors="theme.board"
            />

            <Point 
                v-for="i in [8,9,10,11,12,13]" :key ="'pos_1'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="i%2+1"                 
                :index="8-(i-6)" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="50" :inverted="false"
                :showPoints ="false"
                :direction="1"
                :colors="theme.board"
            />

            <template v-if="!showInitial">
                <svg v-for="(m,i) in moves" :key="'flecha'+i"
                    class="SVGFlecha"                                
                >
                    <line 
                            :x1="(x([m[0],m[1]+1],1)*anchoColumna+anchoColumna/2)*unidadX+'px'" 
                            :y1="(maxTop - y([m[0],m[1]+2],playerID)*anchoColumna*unidadX)+'px'"  
                            :x2="(x([m[2],m[1]+1],1)*anchoColumna+anchoColumna/2)*unidadX+'px'" 
                            :y2="(maxTop - y([m[2],m[1]+2],playerID)*anchoColumna*unidadX)+'px'"  
                            style="stroke:#77cc0999;stroke-width:2%" />
                    <circle
                            :cx="(x([m[2],m[1]+1],1)*anchoColumna+anchoColumna/2)*unidadX+'px'" 
                            :cy="(maxTop - y([m[2],m[1]+2],playerID)*anchoColumna*unidadX)+'px'"  
                            :r="'1%'"
                            style="stroke:rgb(255, 251, 0);stroke-width:3%" />
                </svg>
            </template>

            <!-- *********************************  Piedras  ***************************************************-->
            
            <CheckerComp 
                v-for="checker in getCheckersMap"
                    :key ="objectId+'chkmini_'+checker.id"
                    :chkId="objectId+'chkmini_'+checker.id"
                    
                    :position="[checker.column,checker.height]"
                    :cclass="checker.cclass"
                    :owner="checker.owner"
                    
                    :direction="'1'"
                    :checkersColor="checkersColor"

                    :tips ="null"
                    
                    :colWidth=anchoColumna
                    :colHeight=altoPosicion
                    :XunitSize=unidadX
                    :YunitSize=unidadY
                    :maxTop=maxTop
                    
                    :playerID="getPlayerID"
                    
                    :starting="true"
                    :selectedChecker="false"
                    :forcePoistion="true"

                    :colors="theme.checkers"
            />

            <div v-if="diceRoll && currentPlayer!==null"
                class="diceRoll"
            >
                <Dice :dClass="playerID" :dNumber="diceRoll[0]" :colorClases="theme.dice" />
                <Dice :dClass="playerID" :dNumber="diceRoll[1]" :colorClases="theme.dice" />
            </div>

            <Cube v-if="doubleCube"
                :key="'CubeButton'"
                :id="'CubeButton'"
                :doubleCube='doubleCube'
                :PlayerID="playerID"
                :direction="1"
                :colWidth="anchoColumna*unidadX"
                :colors="theme.cube"
            />
    </div>                  
</div>

</template>

<script>
    import CheckerComp from "./elements/CheckerComp.vue";
    import Cube from "./elements/Cube.vue";
    import Point from "./elements/Point.vue";
    import Dice from './elements/Dice.vue';
    var posHelper = require('../assets/js/positionHelpers');

	export default {
		name: 'AbakMiniBoard',
        emits: [ ],
        components: {
            CheckerComp, 
            Point,
            Cube,
            Dice
            },
        props: {
            objectId: String,
            boardImageSize: Number,
            checkers_map: Object,
            checkers_map_initial: Object,
            playerID: Number,
            checkersColor: Number,
            moves: Object,
            diceRoll:Object,
            currentPlayer:String,
            doubleCube:Object,
            theme:Object
            },
        data: function() {
            return {   
                tabla: {direccion:1,inicio:3} , 
                checkerReload : false,           
                showInitial: false 
            }
        },

        computed: {
            getCheckersMap: function() {
                if (
                    this.showInitial
                    && typeof this.checkers_map_initial !="undefined" 
                    && this.checkers_map_initial) {
                    return this.checkers_map_initial;                                             
                }
                return this.checkers_map;                                         
            },
            getPlayerID: function() {
                return this.playerID;
            },
            altoCanvas: function() {
                return this.boardImageSize*0.6;
            },            
            anchoCanvas: function() {
                return this.boardImageSize*0.75;
            },
            unidadX: function() {
                return this.boardImageSize*0.75/100;
            },            
            unidadY: function() {
                return this.boardImageSize*0.5/100;
            },
            alturaDado: function() {
                let or = this.tabla.orientacion;
                if (or>0) return "20%";
                else return "60%";                
            },
            fontSize: function() {
                return 1.5*this.boardImageSize/10;
            },
            strokeWidth: function() {
                return this.boardImageSize/100;
            },
            maxTop: function() {
                return this.boardImageSize*0.6;
            },
            anchoColumna: function() {                 
                if (this.tabla.inicio ==3) return 6.67;
                return 12.5
            },                
            separacionColumna: function() { 
                if (this.tabla.inicio ==3) return 0.75;
                return 1.5
            },
            separacionColumna2: function() {                 
                return 0
            },
            altoPosicion: function() { 
                return 50;                
            },
            getImageSize: function() {
                return this.anchoColumna*this.unidadX;
            },
        },
        methods: {	
            x: posHelper.x,
            y: posHelper.y,
            clickBoard: function () {
                this.showInitial = ! this.showInitial;
            }
        }     
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.contenedor-tabla {
        position:relative;
        background-color:$c7;    
    }    

    .pos1, .pos2 , .soldado , .dado {
        position:absolute;
    }
              
    
    .SVGFlecha {
        position:absolute;
        height: 100%;
        width: 100%;
        z-index: 1000;
    }

    .diceRoll {
            position: absolute;
            left:40%;
            top:42.5%;
            width:50%;
            display: flex;
            img {
                width: 15%;
                height: 15%;
            }
    }

</style>
