<template>
    <div class="cubeConfirm" v-if="state=='show'">
        <div class="cubeButtons" >
            <GameButton 
                :key="'ButtonRollStart'" 
                :id="'ButtonRollStart'" 
                :button_id="'ButtonRollStart'" 
                :text="''" :imageSize="'2em'" image='no' 
                :theme="buttonsColors"
                @click="$emit('cube-cancel');"/>
            <div>{{t('ui.game.message.confirmdouble')}}</div>
            <GameButton 
                :key="'ButtonRollStart'" 
                :id="'ButtonRollStart'" 
                :button_id="'ButtonRollStart'" 
                :text="''" :imageSize="'2em'" image='done' 
                :theme="buttonsColors"
                @click="$emit('cube-confirm');"/>

        </div>
        <div class="diceMulti">
            <div>
                <img :src="require(`@/assets/img/dados/dd${doubleCube.value==1 ? '00' : doubleCube.value }.svg`)">  
            </div>
            <div style="font-size:2em;">↦</div>
            <div>
                <img :src="require(`@/assets/img/dados/dd${doubleCube.value*2}.svg`)">  
            </div>
        </div>
    </div>
</template>

<script>

    import GameButton from "../buttons/GameButton.vue";
    import { useI18n } from 'vue-i18n'

	export default {
		name: 'CubeConfirmation',
        props: ['state','doubleCube','opponent','elementsColors','buttonsColors'],
        components: {GameButton},
        emits: ['cube-confirm','cube-cancel'],
        data: function() {
            return {
            }
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },           
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"  scoped>

    .cubeConfirm {
        position:absolute;
        border-radius: 0.5em;
        top:40%;
        width: 80%;
        left:10%;
        background-color: v-bind('elementsColors.box1.background');
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding:1em;
        box-sizing: border-box;
    }

    .cubeButtons {
        display: flex;
        justify-content: space-around;
        align-content: center;
        align-items: center;
    }

    .diceMulti {
        display: flex;
        margin-top:1em;
        justify-content: center;
        align-content: center;
        align-items: center;
        img {
            width: 2em;
        }
    }

</style>
