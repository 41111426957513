<template>
<div class="mainApp" :style="{ fontSize: fontSize + 'px'}">
  <div v-if="player.id!=''">
      <div class="mainFrame">
        <div v-if="zone=='game'">
          <div v-if="state" class="gameContainer" 
            :class="{ 
                      tabletMode: uIstate.screenMode=='tablet',
                      verticalMode: uIstate.screenMode=='vertical',
                    }">
            <div class="leftContainer" :class="{ horizontal : uIstate.screenMode=='tablet'}">
              <GamePlayersContainer 
                :currentPlayers="currentPlayers"
                :playerID="playerID"
                :imageSize="getImageSize"
                :screenMode="uIstate.screenMode"
                :checkersColor="preferences.ui.checkersColor"
                :preferences="preferences"
                :theme="themeColors"
                @request-full-screen="togleFullScreen();"
                @change-menu="changeMenu($event)"
                @switch-pref="switchPreference($event)"
                @super-acelerator="superAcelerator()"
                @show-toast="showToast($event.title,3000,'top')"
                />
            </div>
            <div class="rightContainer" :class="{ 
                      tabletMode: uIstate.screenMode=='tablet',
                      verticalMode: uIstate.screenMode=='vertical',
                    }">                
              <AbakBoard         
                  :G="state.G" 
                  :moves="client.moves" 
                  :matchData="client.matchData"
                  :ctx="state.ctx"
                  :boardImageSize="boardSize"
                  :piedras=[{id:1,c:1,t:1}]
                  :id_animacion=1
                  :tabla= {direccion:1,inicio:3}
                  :playerID = playerID
                  :matchID = matchID
                  :players = currentPlayers
                  :client = client
                  :player = player
                  :preferences = preferences

                  :timeTravel = uIstate.timeTravel
                  :timeTravelState = uIstate.timeTravel.on
                  
                  :analyseMove = uIstate.analyseMove

                  :timeSyncDifference = timeSyncDifference

                  :uIstate="uIstate"
                  :theme="themeColors"
                
                  @undo-clicked="onUndoClicked()"

                  @quit-match="quitLobbyMatch($event)"
                  @quit-visitor="quitLobbyNoEnd()"                  
                  @quit-game="quitGame()"
                  @pause-game="quitLobbyNoEnd(this.lobby.currentMatch)"
                  
                  @change-menu="changeMenu($event)"
                  
                  @opponent-disconnected="canClaim()"
                  @claim-match="claimMatch()"
                  
                  @start-time-travel ="startTimeTravel()"
                  @set-time-travel ="setTimeTravel($event.turnID)"
                  @end-time-travel ="endTimeTravel()"
                  @new-game="newGame()"
                  @new-match="newMatch()" 
                  @action-event="actionEvent();"
              />
              <div class="gameControls">                
                <div style="display:flex;margin:0.5em;justify-content:space-around;font-size:1.5em;align-items: center;"> 
                  <ChatWidget 
                      @click-chat-widget=" uIstate.chat.show = true; " 
                      :newmessages="uIstate.chat.newmessages"
                      :imageSize= " uIstate.screenMode == 'vertical' ? '1.7em' : '1.3em' "
                  />  
                  <OnlineWidget v-if=" uIstate.screenMode=='vertical' "
                        style="font-size:0.8em;"
                        :online="uIstate.lastSeen.ammount"   
                        :pending="uIstate.friendship.pending.by_me.length || 0"   
                        :friends="uIstate.friendship.online.length"  
                        :visitors="uIstate.lastSeen.game_visitors.length" 
                        :zone="zone"
                        :type="'normal'"
                        @click="openOnlinePlayers()"            
                        :showTitle="false"
                    />           
                  <GameButton 
                    :button_id="'RulesButton'"
                    :imageSize="uIstate.screenMode=='vertical' ? '1.7em' : '1.3em' "
                    :type="'light'"

                    :image="'rules'"
                    :theme="themeColors.buttons"
                    @click="uIstate.configMenu = {show:true,section:'rules',stoneId:0,stonePage:0} "
                  />
                  <GameButton 
                    :button_id="'ConfigMenuButton'"
                    :imageSize="uIstate.screenMode=='vertical' ? '1.7em' : '1.3em' "
                    :type="'light'"
                    :image="'settings'"                    
                    :theme="themeColors.buttons"

                    @click=" uIstate.configMenu.show=true; uIstate.configMenu.section='acelerators' "
                  />
                </div>
                <div style="display:flex;margin:0.5em;justify-content:space-around;align-items: center;align-content: center;"> 
                  <OnlineWidget v-if="uIstate.screenMode!='vertical'"
                        style="font-size:0.7em;"
                        :online="uIstate.lastSeen.ammount"   
                        :pending="uIstate.friendship.pending.by_me.length || 0"   
                        :friends="uIstate.friendship.online.length"  
                        :visitors="uIstate.lastSeen.game_visitors.length" 
                        :zone="zone"
                        @click="openOnlinePlayers()"            
                        :showTitle="false"
                    />           
                </div>
                <TurnsLog 
                  :turnsLogData="state.G.turnslog"
                  :checkers_map="state.G.checkers_map"
                  :playerID="playerID"
                  :timeTravel = uIstate.timeTravel
                  :analyseMoveOn = uIstate.analyseMove.on
                  :analyseGameOn="uIstate.analyseGame.on"
                  :screenMode="uIstate.screenMode"
                  :players="currentPlayers"
                  :checkersColor="preferences.ui.checkersColor"
                  :boardSize="boardSize"
                  :theme="themeColors"

                  @set-time-travel ="setTimeTravel($event.turnID)"
                  @analyse-move="requestAnalysis(getG,state.ctx,$event.turnID)"
                  @analyse-game="clickRequestGameAnalysis()"                  
                  
                />

                <MovesAnalysis 
                  :playerID="playerID"
                  :currentMove="getG.turnslog.log[uIstate.analyseMove.turnID]"                  
                  :analyseMove = uIstate.analyseMove
                  :show="uIstate.analyseMove.on"
                  :boardSize="boardSize"
                  :checkersColor="preferences.ui.checkersColor"
                  @select-move-analysis="setAnalysisMove($event.moveID)"
                  @end-move-analysis="quitAnalysis()"
                  :screenMode="uIstate.screenMode"
                /> 

                <Chart v-if="uIstate.analyseGame.on"
                  :analyseGame="uIstate.analyseGame"
                  :showStatus="uIstate.analyseGame.on"
                  :playerID = playerID
                  :checkersColor="preferences.ui.checkersColor"                        
                  :iturnsLog="getG.turnslog.log"
                  :boardImageSize="boardSize"
                  :screenMode="uIstate.screenMode"
                  :gameType="getG.type"
                  :classesSet="getG.set"
                  @click-turn='chartClickTurn($event)'                     
                  @close-game-analysis=" uIstate.analyseGame.on=false; "
                />
                
              </div>
            </div>
            <!--Chat 
                :chatActive = "uIstate.chat.on"
                :playerID = playerID 
                :chatMessages="client.chatMessages" 
                :initalActive="false"
                @chat-message="sendMessage($event)"
                @chat-close="uIstate.chat.on=false"
            /-->
          </div>          
          
        </div>

      <div v-if="zone=='lobby'" style="width:100%;">
        <Lobby 
          :lobby = "lobby"  
          :player = "player"  
          :imageSize="getImageSize"
          :screenMode="uIstate.screenMode"
          :timeSyncDifference = timeSyncDifference
          :lastSeen = uIstate.lastSeen
          :chat= uIstate.chat
          :working = "uIstate.isWorking"
          :preGameConf = "uIstate.preGameConf"
          :invitables = "uIstate.invitations.invitables"
          :friendship = "uIstate.friendship"
          :requestedAction = "uIstate.requestedLobbyAction"

          @create-match="createLobbyMatch($event);"
          @join-current-match="joinCurrentMatch()"
          @join-match="joinLobbyMatch($event.matchID,$event.playerID);"
          @open-hall="openHallOfFame()"
          @open-stats="openUserStats()"
          @change-menu="changeMenu($event)"
          @change-preference="changePreference($event)"
          @request-full-screen="togleFullScreen();"
          @user-login="openLogin()"   
          @goto-url="gotoURL($event)"   
          @start-wacthing-games="getLobbyMatches(1,$event.type);"              
          @stop-wacthing-games="stopLobbyMatches();"      
          @refresh-player-info="refreshPlayerInfo();"              
          @request-invitations="inviteHumans($event);"    
          @click-chat-widget="openChat();"
          @action-event="actionEvent();"
          @change-welcomed="setWelcomed($event)"
          @open-chat="openChat($event)"
          @close-chat="closeChat()"
          @open-last-seen="openOnlinePlayers()"
        />
      </div>

          <HallFame v-if="zone=='fame'" :player="player" @close-hall="zone='lobby'"/>    

          <UserStats 
            v-if="uIstate.userStats.show " 
            @close="uIstate.userStats.show=false "
            :player="player " 
            :screenMode="uIstate.screenMode "
            :imageSize="getImageSize "
            :theme="colors['default']"
          />        

          <UserConfiguration 
            v-if="uIstate.userMenu.show" 
            :player="player" 
            :menuData="uIstate.userMenu"
            :screenMode="uIstate.screenMode"
            :imageSize="getImageSize"
            @close="uIstate.userMenu.show=false "
            @update-user="updatePlayer($event)"
            @change-menu="changeMenu($event)"
          />
          <UserSubscription v-if="false==true" 
            :player="player"
            :screenMode="uIstate.screenMode"
          />

          <ConfigMenu 
            :screenMode="uIstate.screenMode"
            v-if="uIstate.configMenu.show"      
            :menuData ="uIstate.configMenu"    
            :preferences ="preferences"
            :fontSize = fontSize
            :colors="colors"
            @close-menu="uIstate.configMenu.show=false "
            @change-menu="changeMenu($event) "
            @change-preference="changePreference($event) "
            @send-toast="showToast($event,2000,'top') "
          />

          <ChatGlobal v-show="uIstate.chat.show"
              @chat-close="closeChat()"
              @send-message="sendGlobalChatMessage($event)"
              :chat="uIstate.chat" 
              :show="uIstate.chat.show"
              :player_id="player.id"
              :zone="zone"
              :friendship="uIstate.friendship"
              :requestedFriend="uIstate.chat.selected_chat"
              :matchID="matchID"
              @send-invitation="requestLobbyAction('send-invitation-friend',$event)"
          />
          
          <Messages 
                v-if="uIstate.messages.show"
                :messages="uIstate.messages.messages"
                :invitations="uIstate.invitations.invitations"
                @close="uIstate.messages.show=false "
                @message-read="clickMessageDismiss($event) "
                @invitation-join="clickInvitationJoin($event) "
                @invitation-dismiss=" clickInvitationDismiss($event) "
                @message-action=" clickMessageAction($event) "
            />

          <OnlinePlayers 
              v-if="uIstate.lastSeen.show"
              :lastSeen="uIstate.lastSeen"
              :player="player"
              :imageSize="getImageSize"
              :friendship="uIstate.friendship"
              :onlinePlayersWorking="uIstate.lastSeen.working"
              :screenMode="uIstate.screenMode"
              :zone="zone"

              @close="uIstate.lastSeen.show=false; "
              @selected="uIstate.lastSeen.selected=$event "
              
              @invite-play="requestLobbyAction('send-invitation-friend',$event)"
              @request-friendship ="requestFriendship($event)"
              @respond-friendship="respondFriendship($event.player1_id,$event.player2_id,$event.response)"                            
              @modify-friendship="modifyFriendship($event.player1_id,$event.player2_id,$event.active,$event.side)"                    
              @send-toast="sendNotificaction($event.text,3000,$event.type)"        

              @open-chat="openChat($event)"
              @game-watch="joinLobbyMatch($event,null)"
          />        

          <transition name="slide-fade" mode="in-out">            
            <keep-alive>
              <Loading 
                v-if="!UIstartedUp || player.id=='' || (zone=='game' && !state)" 
                :key="'mainLoading'"
                :id="'mainLoading'"                
                :text="player.id=='' ? 
                  t('ui.lobby.messages.authenticateuser') :
                  t('ui.lobby.messages.connectingtoengine')  
              "/>
            </keep-alive>
          </transition>

          <Toast 
                  :text ="uIstate.toast.text"
                  :active ="uIstate.toast.active"
                  :type ="uIstate.toast.type"
                  :image ="uIstate.toast.image"
                  :playerData="uIstate.toast.playerData"
                  :playerID ="null"
                  @click =" uIstate.toast.active=false "
          />

      </div>

  </div>
  <!------------------ No user Created --------------------->
  <div v-else style="width:100%;"> 
    <div 
      v-if="uIstate.isFatalError"
      style="font-size:1.5em;display:Flex;flex-direction:column;justify-content:center;align-items:center;">
      <div>Fatal Error</div>      
      <div style="background-color:white;padding:0.5em;border-radius:0.3em;color:black;" onclick="location.href=WEBPROTOCOL+'play.abak.me'">
        reload
      </div>
    </div>
    <div v-if="uIstate.initFinished">
      <UserAuth 
        :player="player"
        :imageSize="getImageSize"
        @attempt-generate-code="atemptEmailAuth($event.email)"
        @attempt-guest-auth="atemptAuth()"
        @attempt-validate="atemptValidateCode($event)"
        @cancel-validate="player.login.auth.status='none'"
      />
    </div>
  </div>

  <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "WebApplication",
      "applicationCategory":"Game",
      "operatingSystem":"All",
      "applicationSubCategory":"Board Game",
      "downloadUrl":"https://play.abak.me",
      "screenshot":"https://www.abak.me/img/abak_screenshot.svg",
      "browserRequirements":"All Modern Browsers work well, even on mobile phones",
      "name":"Abak Evolution Backgammon",
      "description":"Abak Evolution is a Bakgammon variant, that implements classes of checkers, with different features each.",
      "author":{
        "@type": "Person",
        "name": "Samy Garib",
          "nationality" : {
          "@type": "Country",
            "name": "Chile"
              }
          },
      "datePublished":"2016-11-06",
      "dateCreated":"1999-09-08",
      "aggregateRating":{
        "@type":"AggregateRating",
        "ratingValue":4.7,
        "ratingCount":25
          }
      }
  </component>

</div>
</template>

<script>

import { AbakEvolution } from "../assets/js/AbakEvolution.js";
var lobbyLib = require('../assets/js/lobby/lobbyLib.js');

const { ENGINEURL, ENGINEPORT, WEBPROTOCOL, WELCOME_LEVELS,INACTIVITY_TIME_TO_BACKGROUND,TIME_TO_STOP_UPDATING,TIME_TO_RE_INFORM_ONLINE_PLAYER } = require('../assets/js/enviroment.js');

import Loading from '../components/cajas/Loading.vue'
import OnlinePlayers from "../components/elements/OnlinePlayers.vue";
import AbakBoard from "../components/AbakBoard.vue";
import Lobby from "../components/Lobby.vue";
import UserAuth from "../components/UserAuth.vue";
//import Chat from "../components/cajas/Chat.vue";
import GamePlayersContainer from "../components/cajas/GamePlayersContainer.vue";
import TurnsLog from "../components/cajas/TurnsLog.vue";
import MovesAnalysis from "../components/cajas/MovesAnalysis.vue";
import GameButton from "../components/buttons/GameButton.vue";
import ConfigMenu from "../components/cajas/ConfigMenu.vue";
import UserConfiguration from "../components/cajas/UserConfiguration.vue";
import HallFame from "../components/cajas/HallFame.vue";
import UserStats from "../components/cajas/UserStats.vue";
import Messages from '../components/cajas/Messages.vue';
import Toast from '../components/cajas/Toast.vue';
import Chart from '../components/cajas/Chart.vue';
import ChatGlobal from "../components/cajas/ChatGlobal.vue";
import ChatWidget from "../components/elements/ChatWidget.vue";
import OnlineWidget from "../components/elements/OnlineWidget.vue";
import { useI18n } from 'vue-i18n'
import { Client,LobbyClient } from "boardgame.io/client";
import { SocketIO } from 'boardgame.io/multiplayer';
import { clickInvitationDismiss, clickInvitationJoin, inviteHumans, 
         getPosibleInvitations,invitationCreate,invitationCancel, checkInvitationResponse } from "../components/vuejs/invitations.js"
import { atemptAuth, atemptEmailAuth, atemptValidateCode, refreshPlayerInfo, getPlayerStatus,
         getLobbyMatches,processLobbyMatches,stopLobbyMatches,createLobbyMatch,joinCurrentMatch,joinLobbyMatch, quitLobbyMatch, quitLobbyNoEnd,
         returnToUnfinishedGames2,checkMatch, matchCredentialsGetDB, matchCredentialsSaveDB, claimMatch , newGame, newMatch , resetUiState, updateLastSeen,
         requestFriendship, respondFriendship, modifyFriendship        
        } from "../components/vuejs/lobby.js"
import {sendGlobalChatMessage,lastContextMessageID, contextMessages, openChat, closeChat} from '../components/vuejs/chat.js'
import { startTimeTravel,setTimeTravel,endTimeTravel } from "../components/vuejs/timeTravel.js"
import { requestAnalysis,setAnalysisMove,quitAnalysis,requestGameAnalysis } from "../components/vuejs/analysis.js"
import { updateLocalPreferences,savePreferences,updatePlayer,updatePlayerFlags,playerUpdateError } from "../components/vuejs/players.js"
import { togleFullScreen } from "../components/vuejs/fullscreen.js"
import { clickMessageDismiss,clickMessageAction } from "../components/vuejs/messages.js"
import UserSubscription from '../components/cajas/UserSubscription.vue';

import themeColorSheet from '../assets/themes/themes'
//import { getItem } from "node-persist";
//import TurnsLog from '../components/cajas/TurnsLog.vue';

//var lobbySavePrefferencesTimeout = null;

export default {
  name: 'Play',
  components: {
    Loading,
    Lobby,
    AbakBoard,
    ChatGlobal,
    GamePlayersContainer,
    TurnsLog,
    MovesAnalysis,
    GameButton,
    ConfigMenu,
    HallFame,
    UserStats,
    UserConfiguration,
    UserAuth,
    Messages,
    Chart,
    Toast,
    ChatWidget,
    UserSubscription,
    OnlinePlayers,
    OnlineWidget
  },
  setup() {
      const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
      })
      return { t }
  },  
  /* Initial Data */
  data() {
     return {       
      uiVersion: null,
      client: null,       
      unsubscribe: null, 
      state: null ,
      UIstartedUp:false,
      matchID: null,  //MatchID is the session_id in the database.
      match_id: null, // match_id is the ID in the match table on the database.
      playerID:null , 
      lobby: {
        client:null,
        availableMatches: [],
        currentMatch: {
          id:"",
          credential:""
        },
      },
      boardSize:0,
      fontSize:0,
      player: {        
        id:"",
        name:"",
        image: "",
        email: "",
        visible: true,
        watchable: true,
        invitable: true, 
        allowedGameTypes: ['abak','classic'],  
        ranking: 500,            
        type: "none",
        login: {
          auth: {
            status: "none",
            email: "",
            code : ["","","",""],
            error: 0,
          }
        }
      },
      preferences: {
          acelerators: {
            autoMoveOnOneValid: false,
            autoDoneOnNoValids: false,
            autoDoneOnLastValid: false,
            autoRoll: false,
            autoRollForce: false
          },
          ui: {
            theme: 'default',
            boardSize: 'normal',
            locale: '',
            direction: 1,
            checkersColor: 0,
            tipoAnimacionDados: 'flash'
          },
          helpers: {            
            showAdvices:true,
            showInvalidMoves:true,
            showNotifications:true,
            playSounds:true,
            showPoints:false
            },
          p2p: {
            allowVisitors: true,
            allowInvitations: true, 
            visible:true,
            allowedGameTypes:['abak','classic'],
          },
          aceleratorsTemp: {
            on:false,
            autoMoveOnOneValid: false,
            autoDoneOnNoValids: false,
            autoDoneOnLastValid: false,
            autoRoll: false,
            autoRollForce: false
          },
      },
      zone: 'lobby',
      colors: themeColorSheet,   
      uIstate: {
        initFinished: false,
        isFatalError: false,
        isOnBackground: false,
        isWorking:false,
        preGameConf: {
          opponent: {
            type:null,
            level:null
          },
          goal: null,
          stake: null,
          practice_level:null
        },
        lastaction:Date.now(),
        timeouts: {
          getStatus:null,
          lobbyMaches:null,
          lobbySavePrefferences:null
        },
        configMenu: {
          show:false,
          section:"",
          stoneId:0,
          stonePage:0,
        },
        userMenu: {
          show:false,
          section:"",
          error: {
            error:false,
            type:0,
          }
        },
        userStats: {
          show: false,
        },
        screenMode:"tablet", //tablet,landscapefull,landscapeshort,vertical
        claimMenu:false,
        showHelp:false,
        timeTravel: {
            on:false,
            position:0,
        },
        analyseMove: {
          on:false,
          moves:[],
          turnID:0,
          moveID:null,
          stats:{},
          loading:false,
          requestComplexity:0,
        },
        analyseGame: {
          on:false,
          loading:false,
          data:null,
        },        
        messages: {
          lastRead:0,
          messages: [],
          show:false,
        },
        chat: {
          lastFetched:0,
          lastRead: { global:0, game:0 },
          lastReadbyChat: {},
          newmessages: {},
          messages: [],
          show:false,
          selected_chat:null,
          /*on:false,*/
          filters: {
            onlythisgame:true,
            onlytome:false,
            showvisitors:false,
          }
        },
        invitations: {
          lastRead:0,
          invitations: [],
          config: {},
          state: "inactive",
          timeSent: 0,
          player_id:null,
          readTick:0,
          responseTick:0,
          invitedPlayers: [],
          type:null
        },
        friendship: {
          friends: {},
          online: [],
          active: [],
          rejected: [],
          pending: { 
            by_me:[], 
            by_friend:[],
            my_inactives:[] 
          },
          informed: {},
        },
        toast: {
          active:false,
          text:"",
          type:'lobbywarning',
          playerData: null,
        },
        notifications: {
          list: [],
          working: false
        },
        lastSeen: {
          list:[],
          ammount:null,
          updated:null,
          show:false,
          selected:null,
          game_visitors:[],
          working:false
        },
        requestedLobbyAction: null
      },
      timeSyncDifference:0,  
    };
  },
  /* Methods */
  methods: {

      /* Lobby Functions */
      getPlayerStatus,
      getLobbyMatches,
      processLobbyMatches,
      stopLobbyMatches,
      createLobbyMatch,
      joinCurrentMatch,
      joinLobbyMatch,
      quitLobbyMatch,
      quitLobbyNoEnd,
      claimMatch,
      resetUiState,
      newGame,
      newMatch,
      
      updateLastSeen,
      returnToUnfinishedGames2,
      checkMatch,
      atemptAuth,
      atemptEmailAuth,
      atemptValidateCode,
      refreshPlayerInfo, 
      matchCredentialsGetDB,
      matchCredentialsSaveDB,

      /* Invitations */
      clickInvitationDismiss, 
      clickInvitationJoin,
      inviteHumans,
      invitationCreate,
      getPosibleInvitations,
      checkInvitationResponse,
      invitationCancel,

      /* Messages */
      clickMessageDismiss,
      clickMessageAction,

      /* Player Settings */
      updateLocalPreferences,
      savePreferences,
      updatePlayer,
      updatePlayerFlags,
      playerUpdateError,

      /* Full Screen */
      togleFullScreen,

      /* Time TRAVEL */
      startTimeTravel,
      setTimeTravel,
      endTimeTravel,

      /* Moves Analysis */
      requestAnalysis,
      setAnalysisMove,
      quitAnalysis,
      requestGameAnalysis,

      //FirendShip
      requestFriendship,
      respondFriendship,    
      modifyFriendship,        
 

      closeWindow: function() {
        if (this.unsubscribe !== null && this.zone == 'game') {
          try {
            this.unsubscribe();
            if (this.client)
              this.client.stop();
          }
          catch(error) {
            this.sendLog('main','closeWindow',error);
          }
        } 
        //quitLobbyMatch();
      },
      onUndoClicked : function() {
          try {            
            this.client.undo();
          } catch(error) {
            //console.log(error);
          }
          this.actionEvent();            
      },
      clickRequestGameAnalysis: function() {
        //console.log(this.getG);
        if (this.getG && this.getG.type == 'practice') {
          this.showToast(this.t("ui.game.alerts.noanalyzepractice"),3000,'','',0);
            return;
        }

        if (this.getG && this.getG.turnslog.log.length<20) {
          this.showToast(this.t('ui.game.messages.notyetanalysis'),2000);
          return;
        }
        this.requestGameAnalysis();
        //this.requestGameAnalysis(this.getG);
        this.updateLastSeen('game',{ id:this.matchID, type:this.G.type, opponent: this.G.opponent });
        this.actionEvent();  
      },
      requestLobbyAction: function(action,event) {
          console.log(action,event,this.zone);
          this.uIstate.lastSeen.show = false;
          this.uIstate.chat.show = false;

          if (this.zone == "game") {
            this.zone = "lobby";  
            this.uIstate.isWorking = true;
            if (this.unsubscribe) {
                  this.unsubscribe();
                  this.client.stop();  
            }
          }

          setTimeout(()=>{ 
              this.uIstate.requestedLobbyAction = {
              action:action,
              event:event
            }          
          }, 300);

          setTimeout(()=>{ 
            this.uIstate.requestedLobbyAction = null;
            this.uIstate.isWorking = false;
          }, 600);
      },
      setWelcomed: function(event) {
        this.player.welcomed=WELCOME_LEVELS;
        if (event.value) {
          this.updatePlayerFlags( { welcomed: event.value });
        }

      },
      // Chat Functions 

      openChat, 
      closeChat,
      sendGlobalChatMessage,
      lastContextMessageID,
      contextMessages,

    // Preference

    changePreference: function(event) {
        //console.log("change prefferences",event);
        this.preferences[event.group][event.preference]=event.value;
        if (event.group=='p2p') {
          if (event.preference == 'allowInvitations') this.player.invitable = event.value;
          if (event.preference == 'visible') this.player.visible = event.value;
          if (event.preference == 'watchable') this.player.watchable = event.value;
          if (event.preference == 'allowedGameTypes') this.player.allowedGameTypes = event.value; 
          if (event.preference == 'allowVisitors') this.player.watchable = event.value; 
        }
        clearTimeout(this.uIstate.timeouts.lobbySavePrefferences);
        let self = this;
        this.uIstate.timeouts.lobbySavePrefferences = setTimeout(()=> {self.savePreferences()},3000);      
        this.actionEvent();  
      },

    switchPreference: function(event) {
        if (!event.values)
          this.preferences[event.group][event.preference]=!this.preferences[event.group][event.preference];
        else {
          //console.log("Change Values On");
          let values = event.values.split("-");
          //console.log(values);
          let prefValue = this.preferences[event.group][event.preference];
          //console.log(prefValue);
          let currentIndex = 0;
          for (let i in values) {
            if (parseInt(values[i]) == prefValue) {
              currentIndex = i;
              break;
            }
          }
          //console.log(currentIndex, values[currentIndex],values.length);
          let newIndex = (parseInt(currentIndex)+1) % (values.length);
          //console.log(newIndex);
          this.preferences[event.group][event.preference] = values[newIndex];
        }
        this.actionEvent();
      },
      
    superAcelerator() {
        if (this.preferences.aceleratorsTemp.on) {
            this.preferences.acelerators.autoRoll = this.preferences.aceleratorsTemp.autoRoll;
            this.preferences.acelerators.autoRollForce = this.preferences.aceleratorsTemp.autoRollForce;
            this.preferences.aceleratorsTemp.on = false;
            this.showToast("Super Aceleration Off",3000,'top');
        }
        else {
          this.preferences.aceleratorsTemp.autoRoll = this.preferences.acelerators.autoRoll;
          this.preferences.aceleratorsTemp.autoRollForce = this.preferences.acelerators.autoRollForce;
          this.preferences.acelerators.autoRoll = true;
          this.preferences.acelerators.autoRollForce = true;
          this.preferences.aceleratorsTemp.on = true;
          this.showToast("Super Aceleration On",3000,'top');
        }
      },
      
    changeMenu: function(event) {
        //console.log(event);
        this.uIstate[event.menu]=event.data;        
        let gt_name = this.zone=='game' ? 'game': 'lobby';
        if (event.data.show) {
          if (event.data.section=='rules') {
            this.$gtag.event(gt_name+'-rules',{'event_category':'engagment','event_label':'class-'+event.data.stoneId});
          }
          else {
            this.$gtag.event(gt_name,{'event_category':'engagment','event_label':'open-'+event.data.section});
          }
        }
        this.actionEvent();
      },
     
      gotoURL: function(path) {
        window.location.href = path;        
      },

      quitGame: function() {
        this.actionEvent();
        this.client.moves.quitGame();        
      },
      
      actionEvent: function() {
        this.uIstate.lastaction=Date.now();
      },
      openHallOfFame: function () {        
        this.zone='fame';
        this.updateLastSeen('lobby');
        this.$gtag.event('lobby',{'event_category':'engagment','event_label':'hall-of-fame'});    
        this.actionEvent();
      },
      openOnlinePlayers: function() {
        this.updateLastSeen('lobby');
        this.$gtag.event('lobby',{'event_category':'engagment','event_label':'online-players'});  
        this.uIstate.lastSeen.show = true;  
        this.actionEvent();
      },
      openUserStats: function() {
        this.zone='lobby';
        this.updateLastSeen('lobby');
        this.$gtag.event('lobby',{'event_category':'engagment','event_label':'user-stats'});  
        this.uIstate.userStats.show = true;  
        this.actionEvent();
      },
      openRules: function() {        
        this.uIstate.configMenu = {show:true,section:'rules',stoneId:0,stonePage:0};
        this.$gtag.event('game',{'event_category':'stone-info','event_label':'class-0'});    
        this.actionEvent();
      },
      openLogin: function() {
        this.updateLastSeen('lobby');
        this.uIstate.userMenu.show=true;
        this.uIstate.userMenu.section='user';
        this.$gtag.event('lobby',{'event_category':'engagment','event_label':'open-user-detail'});
        this.actionEvent();
      },
      /* Rendering Functions */
      showToast: function(text,time,type="default",image=null,playerID=null,playerData=null) {
        this.uIstate.toast.active = true;
        this.uIstate.toast.text = text;                
        this.uIstate.toast.type = type;
        this.uIstate.toast.image = image;
        this.uIstate.toast.playerID = playerID;
        this.uIstate.toast.playerData = playerData;
        setTimeout(()=>this.uIstate.toast.active=false,time);
      },
      sendNotificaction:function(text,time,type="default",image=null,playerID=null,playerData=null) {
        this.uIstate.notifications.list.push({ text,time,type,image,playerID,playerData });
        console.log("Send Notification",text,time,type);
        if (!this.uIstate.notifications.working) {
          //console.log("Not Working, work");
          this.uIstate.notifications.working = true;
          setTimeout(()=>{this.processNotificaction()},500);
        }
      },
      processNotificaction: function() {
        //console.log("Process",JSON.stringify(this.uIstate.notifications.list));
        const n = this.uIstate.notifications.list.pop();
        this.showToast(n.text,n.time,n.type,n.image,n.playerID,n.playerData);
        if (this.uIstate.notifications.list.length > 0 ) {
          //console.log("Some Left, schedule");
          setTimeout(()=> { this.processNotificaction() },4000);
        }
        else {
          this.uIstate.notifications.working = false;
        }
      },
      calcSize: function() {

        let prop = window.innerWidth/window.innerHeight;
        //console.log("Calc Size Called P:"+prop);
        const LANDSCAPELIMITLARGE = 1.9;
        const LANDSCAPELIMITSMALL = 1.65;
        const TABLETLIMITTABLET = 1.3;
        const TABLETLIMITSMALL = 0.9;
        
        if (prop>LANDSCAPELIMITLARGE) {
          this.uIstate.screenMode = "landscapefull";
          this.boardSize = Math.floor(window.innerWidth);
          this.fontSize = this.boardSize/60;
          }
        if (prop>LANDSCAPELIMITSMALL) {
            this.boardSize = Math.floor(window.innerHeight/0.62);
            if (prop<=LANDSCAPELIMITLARGE) {
              this.uIstate.screenMode = "landscapeshort"
              this.boardSize = Math.floor(window.innerHeight/0.7);
              this.fontSize = this.boardSize/50;
            }
        }
        else {
          if (prop<TABLETLIMITSMALL) {            
            this.uIstate.screenMode = "vertical";
            this.boardSize = Math.floor(window.innerWidth/0.8);
            this.fontSize = this.boardSize/40;
          }
          else {
            if (prop<TABLETLIMITTABLET) {
              this.uIstate.screenMode = "tablet";
              this.boardSize = Math.floor(window.innerHeight/0.8);
              this.fontSize = this.boardSize/50;
            }
            else {
              this.uIstate.screenMode = "tablet";
              this.boardSize = Math.floor(window.innerHeight/0.8);
              this.fontSize = this.boardSize/45;
            }
          }
        }
        
        
      },
      changeScreenSize: function () {        
        this.calcSize();
      },
      changeVisibility: function() {
          if (document.visibilityState === 'visible') {
            //console.log(this.uIstate.isOnBackground);
            if (this.uIstate.isOnBackground===false) {              
              location.reload();           
            }
            else {               
              clearTimeout(this.uIstate.isOnBackground);
            }
          }
          else {
            this.uIstate.isOnBackground = setTimeout(()=>{
              try {
                if (this.unsubscribe) {
                  this.unsubscribe();
                  this.client.stop();  
                }
                this.uIstate.isOnBackground = false;
             }
              catch(error) {
                console.log(error);
              }
            },INACTIVITY_TIME_TO_BACKGROUND*1000);
          }

      },
      checkIfActivePlayer: function() {
        let inactiveTime = (Date.now() - this.uIstate.lastaction)/1000;
        if ( inactiveTime > TIME_TO_STOP_UPDATING ) {
          if (this.uIstate.timeouts.getStatus) {
            clearTimeout(this.uIstate.timeouts.getStatus);
            this.uIstate.timeouts.getStatus = null;
          }
        }
        else {
          if (!this.uIstate.timeouts.getStatus) {
            this.uIstate.timeouts.getStatus = setTimeout(()=>this.getPlayerStatus(1),4000);
          }
        }
        setTimeout(()=>{this.checkIfActivePlayer()},10000);
      },
      chartClickTurn: function(event) {
        this.setTimeTravel(event.turnID);
        if(event.close) { this.uIstate.analyseGame.on = false }
      }
  },
  computed: {
      currentPlayers: function() {    
        if (!this.state) return null;            
        var returnValue = [];
          
          try {
              for (let i=0;i<2;i++) {            
                if (this.getG && this.getG.opponent.type=="CPU" && i==1) {
                  returnValue.push({ 
                      id: 100+this.getG.opponent.level,
                      name:"CPU",
                      image:`/img/players/avatars/${100+this.getG.opponent.level}.svg`,
                      ranking: lobbyLib.ranking_cpu[this.getG.opponent.level],
                      abaker:10
                  });
                }
                else {
                    if (typeof this.client.matchData[i].data != "undefined") {
                        returnValue.push({ 
                            id:this.client.matchData[i].data.id,
                            name:this.client.matchData[i].name,
                            image:this.client.matchData[i].data.image,
                            ranking:this.client.matchData[i].data.ranking,
                            abaker:this.client.matchData[i].data.abaker || 0,
                            });
                    }
                    else { returnValue.push({ id:"",name:"",image:"",abaker:4}); }
                }
              }
          } catch (error) {
            //console.log(error)
          }
          return returnValue; 
      },   
      getPoints : function() {  
        if (!this.state) return null;        
        try {
            return { ...this.state.G.points };            
        }
        catch(error) {
           //console.log(error)
        }
        return { current: [0,0], goal: 0 }
          
      },     
      getG: function() {
        if (!this.state) return null;
        try  {
          return this.state.G;            
        }          
        catch(error) { 
          //console.log(error)
          }
        return {}        
      },
      getImageSize: function() {
        return this.boardSize*0.75/100*6.67;
      },
      lastChatFetched: function() {
        return this.uIstate.chat.lastFetched;
      },
      themeColors: function() {
        return this.colors[this.preferences.ui.theme]
      }
  },
  watch: {
    zone: function(newZone,oldZone) {
      /* if we are returning to the lobby, Set Current Game Data So the UI 
      Know it has to redirect instead of create a new game. */
      if (newZone == 'lobby' && oldZone == 'game') {  
        //setTimeout(()=> this.checkUnfinished(),1000);
      }
      /* On change of status update last seen */
      if (newZone == 'lobby') this.updateLastSeen(newZone);
    },

    // Check Visitors of my game, so I can send a notification.
    'uIstate.lastSeen.game_visitors': function(newList,oldList) {
      let newElements =  newList.filter(element => !oldList.includes(element));
      if (newElements.length>0) {
        if (newElements.length==1 && newElements[0] != this.player.id) {
          this.sendNotificaction(this.$t('ui.game.subject.newvisitor'),2000,"info");
        }
      }
    },
    // Check Online Friends, so I can send a notification.
    'uIstate.friendship.online': function(newList) {
      for (let of in newList ) {
        // If I havent notified this user yet
        if (!(Object.keys(this.uIstate.friendship.informed).includes(newList[of].toString()))) {
          this.uIstate.friendship.informed[newList[of]] = {
            date: new Date().getTime(),
            last: 1
          }
          this.sendNotificaction(this.$t('lobby.subject.friendship.onlinefriend'),2000,"info",null,null,this.uIstate.friendship.friends[newList[of]]);
        }
        else {
          // If already notified but a lot of time has happened and there is a new login.
          const timeDiff = (new Date().getTime() - this.uIstate.friendship.informed[newList[of]].date)/1000;
          if ( timeDiff > TIME_TO_RE_INFORM_ONLINE_PLAYER && this.uIstate.friendship.informed[newList[of]].last == 0) {
            this.sendNotificaction(this.$t('lobby.subject.friendship.onlinefriend'),3000,"info",null,null,this.uIstate.friendship.friends[newList[of]]);
            this.uIstate.friendship.informed[newList[of]].last = 1;
          }
        }
      }
      for (let op in this.uIstate.friendship.informed) {
        if (this.uIstate.friendship.online.includes(op))
          this.uIstate.friendship.informed[op].last = 0;
      }
    }
  },
  sockets: {

  },
  created() {
    window.addEventListener('beforeunload', () => {this.closeWindow()});

    window.addEventListener('touchmove', 
      (event) => {
        if (event.touches.length > 1) {
          event.preventDefault();
        } }, { passive: false }
    );    

    this.calcSize();
  },
  
  mounted() {
    const playerID = "0";
    const matchID = "";

    let chatLastRead = this.$cookie.getCookie("ui.chat.lastRead");
    if (chatLastRead) {      
      this.uIstate.chat.lastRead = chatLastRead;
    }

    let lastReadbyChat = this.$cookie.getCookie("ui.chat.lastReadbyChat");
    if (lastReadbyChat) {      
      this.uIstate.chat.lastReadbyChat = lastReadbyChat;
      //console.log(lastReadbyChat);
    }


    window.addEventListener("resize", () => { this.changeScreenSize() } );
    document.addEventListener("visibilitychange", () => { this.changeVisibility() } );

    this.client = Client({
      game: AbakEvolution,
      matchID: matchID,
      multiplayer: SocketIO({ server: `${WEBPROTOCOL}${ENGINEURL}:${ENGINEPORT}` }),
      playerID,
    });

    this.lobby.client = new LobbyClient({ server: `${WEBPROTOCOL}${ENGINEURL}:${ENGINEPORT}` });

    
    this.$nextTick(function () {    
      this.uIstate.timeouts.getStatus = setTimeout(()=>this.getPlayerStatus(1),2000);
      setTimeout(()=> {this.UIstartedUp = true;} ,1000);
      setTimeout(()=> {this.checkIfActivePlayer()}, 1000 );
    });

    if (this.$cookie.getCookie("abk_")) {
      localStorage.setItem("abk_", this.$cookie.getCookie("abk_"));
      this.atemptAuth();      
    }    
    else {
      if (localStorage.getItem("abk_")) {
        let abk_st_auth = localStorage.getItem("abk_");
        console.log(abk_st_auth);
        if (abk_st_auth.length>3) {
          document.cookie = "abk_=" + abk_st_auth + "; domain=.abak.me; path=/; secure; SameSite=None; max-age=" + (3650*24*60*60);
         this.atemptAuth();
        }
      }
      else {
        this.uIstate.initFinished=true;
      }
    }

    //this.updateLastSeen('lobby');

  },
  unmounted()   {
    window.removeEventListener("resize",() => {this.changeScreenSize()});  
  }
}



</script>

<style scoped>

.mainApp {
  overflow: hidden;
  position:absolute;
  width: 100%;
  height: 100%;
  left:0px;
  top:0px;
  display:flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /*background: linear-gradient(309deg, rgba(46,98,69,1) 0%, rgba(3,41,8,1) 100%);*/
  background: v-bind("zone=='game' ? themeColors.background: 'inherit'");
}

.mainFrame {
  width:100%;
  box-sizing: border-box;
}

.gameContainer {
  display: flex;
  justify-content: center;
}

.gameContainer.tabletMode {
  flex-direction: column;
}

.gameContainer.verticalMode {
  flex-direction: column;
}

.rightContainer {
  display:flex;
}

.rightContainer.verticalMode {
  flex-direction: column;
}

.gameControls {

}

.leftContainer {
  display: flex;
  justify-content:space-between;
}

.leftContainer>div {
  display:flex;
  align-items:center;
  flex-direction:column;
  justify-content:space-between;
  width:100%;
}

.leftContainer.horizontal {
  flex-direction:row;
}


 
    /* Enter and leave animations can use different */
    /* durations and timing functions.   */
                
       
    .slide-fade-leave-active {
        transition: all 1s ease-out;
    }
    
    .slide-fade-enter-from, .slide-fade-leave-to {
        opacity: 0.5;
        transform:translateY(-100%);
    }              

    .slide-fade-enter-to, .slide-fade-leave-from {
        opacity: 1;
        transform:translateY(0);
    }       

@media (orientation: portrait) {
  .mainApp {
    align-items: flex-start;
  }
  
  .leftContainer>div {
    width: 100%;
  }
}

</style>
