<template>

    <div class="abakers"> 
        <div class="element" v-for="ab in abakers" :class="{ selected: abaker==ab , minAbaker: minAbaker==ab }" :key="'abaker'+ab">
            <img :src="'/img/players/abaker/'+ab+'.svg'">
        </div> 
    </div>

</template>

<script>
export default {
    name: 'PlayerAbaker',
    data: function() {
        return {
            abakers: [ 0,1,2,3 ],
            minAbaker: 1,
        }
    },
    props: [ 'abaker']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


    .abakers {
        display: flex;
        justify-content: center;        
    }

    .element {
        background-color: $c7;
        border-radius: 50%;
        display: flex;
        padding: 0.5em;
        margin: 0 0.3em;
        >img {
            height: 2em;
        }
        box-sizing: border-box;
        border: 0.2em solid $c8;
    }

    .selected {
        background-color: $c3;
        border: 0.2em solid $coloryes;
    }

    .minAbaker {
        border: 0.2em solid $colorno;
    }

</style>