<template>
    <div class="langFrame">
        <div class="langBox">
            <div style="display:flex;justify-content:space-between;align-items:center;width:80%;">
                <div style="font-size:1em;">{{t('lobby.messages.selectlanguage')}}</div>
                <div @click="$emit('close')" class="closeButton">
                    <img :src="require('@/assets/img/buttons/close.svg')"/>
                </div>            
            </div>
            <div class="langContainer">
                <div 
                    v-for="l in locales" :key="'langSel'+l"
                    @click="$emit('locale-selected',{locale:l});"
                    > 
                    <img :src="require(`@/assets/img/flags/lang-${l}.svg`)">
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import { useI18n } from 'vue-i18n'

export default {
    name: 'LocaleSelector',
    data: function() {
        return {
        }
    },
    emits: ['close','locale-selected'],
    computed: {            
            },
    methods: {	
            },
    mounted: function() {
                this.$nextTick(function () {
                });
            },
    props: [ 'locales',
            ],
    setup() {
        const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
        })
        return { t }
    },  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.langFrame {
    position:absolute;
    left:0px;
    top:0px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0.5em;
    
}

.langBox {
    position:absolute;
    width:50%;
    height:50%;
    top:25%;
    left:25%;
    background:$c4;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1em;
    box-sizing: border-box;
    align-items: center;
    border-radius: 1em;

    img {
        width:3em;
        border-radius:0.5em;
    }

    .langContainer {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 0.5em;
        div {
            margin:0.5em;
            background-color:$c6;
            display: flex;
            padding:0.1em;
            box-shadow: $box-shadow-normal;
            border-radius: 0.4em;
        };
    };


    .closeButton {
        padding: 0.5em;
        background-color: black;
        border-radius: 1em;
        height: 1em;
        width: 1em;
    }

    .closeButton img {
        width: 100%;
    }    
}


    @media (orientation: portrait) {
    
        .langBox {
            width:90%;
            left:5%;
            top:10%;  
            font-size:2em;      
        }
        
    }


</style>
