<template>
    <div
        :key="button_id" :id="button_id" 
        class="game-button"
        :class="{ smaller: smaller, disabled:disabled}">
        <img 
            :src="require(`@/assets/img/${image}.svg`)"
        />
    </div>
</template>

<script>
	export default {
		name: 'UIButtonSmall',
        props: [ 'button_id','text', 'image', 'imageSize' ,'smaller','disabled' ],
        data: function() {
            return {
            }
        },
        computed: {

        },
        methods: {	

        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
 
    .game-button {
        display: inline-block;
        border-radius: 0.3em;
        background-color: #577b23;
        border: 0.1em solid #577b23;
        padding: 0.4em;
        height: 1.5em;
        vertical-align: middle;
        margin: 0.5em;
        cursor: pointer;
        filter:drop-shadow(0.1em 0.1em 0.2em black);
    }

    .game-button {
        @media (hover:hover) {
            :hover {
                filter:invert(0)
            }
        }

    }
    .game-button img {
        height: 100%;
        filter: invert(1);
    }


    .smaller {
        padding: 0.1em;
    }

    .disabled {
        background: #AAA;
        border-color: #33333377;

        img {
            filter: invert(1) opacity(0.2);
        }
    }

</style>
