
<template>
    <div>

        <div v-if="screenMode=='vertical'" 
            :style="{ fontSize:  screenMode == 'vertical' ? '2em' : '1em'}"> 
            <div class="buttonsHolder"  :class="{ phorizontal: containerDirection=='horizontal' }"  >
                <div style='display:flex;justify-content:space-around;'> 
                    <GameButton 
                        @click = "alertMessage(t('ui.game.messagest.fullscreenrequested'));$emit('request-full-screen')"
                        :key="'requestFS'" 
                        :image="'fullscreen'" 
                        :text="''"
                        :type="'light'"
                        :theme="theme.buttons"

                    />
                    <GameButton 
                            @click = "$emit('super-acelerator');localSuperAcelerator=!localSuperAcelerator"
                            :key="'superAcelerator'" 
                            :image="'bolt'" 
                            :text="''"
                            :prefValue="localSuperAcelerator"
                            :type="'normal'"
                            :theme="theme.buttons"
                        />
                    <GameButton 
                        @click ="alertMessage(t('ui.game.messages.checkercolorschanged'));$emit('switch-pref',{ group: 'ui', preference: 'checkersColor' , values: '0-1' });"
                        :key="'requestFS'" 
                        :image="'zoomout'" 
                        :text="''"
                        :type="'light'"
                        :prefValue="preferences.ui.checkersColor"
                        :imageValue="'img/elements/checker_col'"
                        :theme="theme.buttons"

                    />
                </div>      
                <div style="position:relative;flex-grow:1">
                    <div class="logo"> <img  :src="require('@/assets/img/abaklogosimplificado.svg') ">  </div>
                    <div v-if="message.show" class="messageContainer" > {{message.text}} </div>
                </div>


                
                <div style='display:flex;justify-content:space-around;'> 
                    <GameButton 
                        @click ="alertMessage(t('ui.game.messages.showpointsswitched'));$emit('switch-pref',{ group: 'helpers', preference: 'showPoints' })"
                        :key="'requestFS'" 
                        :image="'label'" 
                        :text="''"
                        :type="'light'"
                        :prefValue="preferences.helpers.showPoints"
                        :theme="theme.buttons"

                    />
                    <GameButton 
                        @click="alertMessage(t('ui.game.messages.allowinvitationsswitched'));$emit('switch-pref',{ group: 'p2p', preference: 'allowInvitations' })"
                        :key="'requestZoomIn'" 
                        :image="'invitation'" 
                        :text="''"
                        :type="'light'"
                        :prefValue="preferences.p2p.allowInvitations"
                        :theme="theme.buttons"

                    />
                    <GameButton 
                        @click="alertMessage(t('ui.game.messages.allowvisitorsswitched'));$emit('switch-pref',{ group: 'p2p', preference: 'allowVisitors' })"
                        :key="'requestFS'" 
                        :image="'visibility'" 
                        :text="''"
                        :type="'light'"
                        :prefValue="preferences.p2p.allowVisitors"
                        :theme="theme.buttons"
                    />
                </div>
            </div>           
        </div>


        <div v-if="currentPlayers" 
            class="playersConainer"
            :class="{ phorizontal: containerDirection=='horizontal' }">        

            <div :key="'playerBadgetable0'"
                class="playerFrame"
                :class="{ phorizontal: containerDirection=='horizontal' , playerStatsReversed : playerID == '1' }"
            >
                <PlayerBadge v-if="currentPlayers[otherPlayer].id!=''" 
                    :player="currentPlayers[otherPlayer]" 
                    :imageSize="imageSize"
                    :vertical="screenMode != 'vertical' && screenMode != 'tablet'"
                    :playerID="playerFace(otherPlayer)"
                    :colors="theme.checkers"
                    :key="'playerBadge0'" :id="'playerBadge0'"/>
                <div v-if="currentPlayers[playerFace(otherPlayer)].id==''">Wait</div>
            </div>


            <div v-if="screenMode!='vertical'"> 
                <div class="buttonsHolder"  :class="{ phorizontal: containerDirection=='horizontal' }"  >
                    <div style='display:flex;justify-content:space-around;'> 
                        <GameButton 
                            @click = "alertMessage(t('ui.game.messagest.fullscreenrequested'));$emit('request-full-screen')"
                            :key="'requestFS'" 
                            :image="'fullscreen'" 
                            :text="''"
                            :type="'light'"
                            :theme="theme.buttons"
                            

                        />
                        <GameButton 
                            @click = "$emit('super-acelerator');localSuperAcelerator=!localSuperAcelerator"
                            :key="'superAcelerator'" 
                            :image="'bolt'" 
                            :text="''"
                            :prefValue="localSuperAcelerator"
                            :type="'light'"
                            :theme="theme.buttons"
                        />
                        <GameButton 
                            @click ="alertMessage(t('ui.game.messages.checkercolorschanged'));$emit('switch-pref',{ group: 'ui', preference: 'checkersColor' , values: '0-1' });"
                            :key="'requestFS'" 
                            :image="'zoomout'" 
                            :text="''"
                            :type="'light'"
                            :prefValue="preferences.ui.checkersColor"
                            :imageValue="'img/elements/checker_col'"
                            :theme="theme.buttons"

                        />
                    </div>      
                    <div style="position:relative;flex-grow:1">
                        <div class="logo"> <img  :src="require('@/assets/img/abaklogosimplificado.svg') ">  </div>
                        <div v-if="message.show" class="messageContainer" > {{message.text}} </div>
                    </div>
                    
                    <div style='display:flex;justify-content:space-around;'> 
                        <GameButton 
                            @click ="alertMessage(t('ui.game.messages.showpointsswitched'));$emit('switch-pref',{ group: 'helpers', preference: 'showPoints' })"
                            :key="'requestFS'" 
                            :image="'label'" 
                            :text="''"
                            :type="'light'"
                            :prefValue="preferences.helpers.showPoints"
                            :theme="theme.buttons"

                        />
                        <GameButton 
                            @click="alertMessage(t('ui.game.messages.allowinvitationsswitched'));$emit('switch-pref',{ group: 'p2p', preference: 'allowInvitations' })"
                            :key="'requestZoomIn'" 
                            :image="'invitation'" 
                            :text="''"
                            :type="'light'"
                            :prefValue="preferences.p2p.allowInvitations"
                            :theme="theme.buttons"

                        />
                        <GameButton 
                            @click="alertMessage(t('ui.game.messages.allowvisitorsswitched'));$emit('switch-pref',{ group: 'p2p', preference: 'allowVisitors' })"
                            :key="'requestFS'" 
                            :image="'visibility'" 
                            :text="''"
                            :type="'light'"
                            :prefValue="preferences.p2p.allowVisitors"
                            :theme="theme.buttons"
                        />
                    </div>

                </div>           
            </div>
            <div :key="'playerBadgetable1'"
                class="playerFrame"
                :class="{ phorizontal: containerDirection=='horizontal' }"
            >
                <PlayerBadge v-if="currentPlayers[thisPlayer] && currentPlayers[thisPlayer].id!=''" 
                    :player="currentPlayers[thisPlayer]" 
                    :imageSize="imageSize"
                    :vertical="screenMode != 'vertical' && screenMode != 'tablet'"
                    :playerID="playerFace(thisPlayer)"
                    :colors="theme.checkers"
                    :key="'playerBadge'+thisPlayer" :id="'playerBadge'+thisPlayer"/>
                <div v-if="currentPlayers[playerFace(thisPlayer)].id==''">Wait</div>
            </div>
        </div> 

    </div>
</template>

<script>

    import PlayerBadge from './PlayerBadge.vue';
    import GameButton from '../buttons/GameButton.vue';
    import { useI18n } from 'vue-i18n'

	export default {
		name: 'GamePlayersContainer',
        components: { PlayerBadge,GameButton },
        emits: [
            'request-full-screen',
            'change-menu',
            'request-zoom-in',
            'request-zoom-out',
            'switch-pref',
            'super-acelerator',
            'show-toast',
            ],
        props: ['currentPlayers','playerID','imageSize',
                'screenMode','checkersColor','preferences','theme'],
        data: function() {
            return {
                message: {
                    text: "",
                    show: false
                },
                localSuperAcelerator:false
            }
        },
        computed: {  
            thisPlayer: function() {
                return this.playerID ? parseInt(this.playerID) : 0;
            },
            otherPlayer: function() {
                let playerID = this.playerID ? parseInt(this.playerID) : 0;
                return (playerID+1)%2;
            },
            containerDirection: function() {
                return this.screenMode == 'vertical' || this.screenMode == 'tablet' ? 'horizontal' : 'vertical'
            }
        },
        methods: {	
            playerFace: function(playerID) {
                if (this.checkersColor==1) return (parseInt(playerID)+1)%2;                
                return parseInt(playerID);
            },
            alertMessage: function(text) {
                //this.message.text = text;
                //this.message.show = true;
                this.$emit('show-toast',{title:text});
                //setTimeout(() => this.message.show = false , 1000);
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },                  
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    img {
        width:3em;
        margin:0.5em;
    }

    .playerFrame {
        margin:0.3em 0.5em;
        display:flex;
        flex-direction: column;
    }

    .playersConainer {

        display:flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-around;
        margin-right:0.3em;
        width:100%;
    }    

    .playerStats {
        flex-direction:column;
        align-items:center;
    }

    .playerStatsReversed {
        flex-direction:column-reverse !important;
        align-items:center !important;
    }

    .logo  {
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        img { 
            width: 3em;
            margin: 0;
        }
    }

    .messageContainer
    {
        position:absolute;
        top:7.5%;
        left:7.5%;
        height:85%;
        width:85%;
        background:$c4;
        color:$c1;
        border-radius: 0.5em;
        box-shadow: $box-shadow-normal;
        font-size:0.6em;
        padding: 0.3em;
        box-sizing: border-box;
        display:flex;
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .buttonsHolder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;        
    }


    .phorizontal {
        flex-direction: row;
        align-items: center;
    }


</style>
