<template>
    <div class="matchEndContainer"> 
        <div class="matchEndHeader" style="text-align:center;">
            <div>{{ t('ui.game.message.claim') }}</div>
            <div class="pointsBadge">{{points.goal}}</div>
        </div>
        <div  class="firstRow">
            <div class="playersContainer">     
                <div class="playerMacroContainer">  
                    <PlayerBadge 
                        :direction="'1'" 
                        :player="players[playerID]" 
                        :imageSize="imageSize" 
                        :playerID="playerID"
                        :key="'MatchEndplayerBadge1'" 
                        :id="'MatchEndplayerBadge1'"
                        :vertical='true'
                        />
                    <div style="display:flex;justify-content:center;">
                        <div class="MatchEndPoints">{{points.goal}}</div>
                    </div>
                </div>

                <div class="playerMacroContainer">  
                    <PlayerBadge 
                        :direction="'1'" 
                        :player="players[otherPlayer]" 
                        :imageSize="imageSize" 
                        :playerID="otherPlayer"
                        :key="'MatchEndplayerBadge2'" 
                        :id="'MatchEndplayerBadge2'"
                        :vertical='true'
                        />
                    <div style="display:flex;justify-content:center;">
                        <div class="MatchEndPoints">{{points.current[otherPlayer]}}</div>
                    </div>                
                </div>
            </div>
            <div class="gameDetailsContainer">
                <div style="text-align:center;" class="gameDetails"> {{t('ui.game.message.logtitle')}}</div>
                <div v-if="points.games_record.length==0"> {{t('ui.game.message.nogameslog')}}</div>
                <div v-for="(p,i) in points.games_record.slice().reverse()" :key="p" class="gameDetails">
                    <div style="display:flex;align-items:center;">
                        <div>{{numberOfGames-i}}</div>
                        <img :src="players[p.winner].image">
                    </div>                    
                    <div style="display:flex;align-items:center;">
                        <div>{{p.points}}</div>
                        <span>x</span>
                        <img :src="doubleCubeSrc(p.doubleCube)">
                    </div>
                    <img :src="reasonSrc(p.reason)">
                </div>
            </div>
        </div>


        <div class="buttons" v-if="playerID!==null">
            <div v-if="!pleaseConfirm" style="padding-top:0.5em;">      
                <GameButton                    
                    :key="'ButtonClose'" 
                    :id="'ButtonClose'" 
                    :type="'light'"
                    :text="t('ui.game.action.close')" 
                    image='close' 
                    :imageSize="imageSize*0.8"
                    @click="clickClose();" 
                />
                <GameButton                    
                    :key="'ButtonCloseNoClaim'" 
                    :id="'ButtonCloseNoClaim'" 
                    :type="'light'"
                    :text="t('ui.game.action.dontclaim')" 
                    image='dontclaim' 
                    :imageSize="imageSize*0.8"
                    @click="clickQuitNoClaim();" 
                />
                <GameButton                    
                    :key="'ButtonClaim'" 
                    :id="'ButtonClaim'" 
                    :type="'light'"
                    :text="t('ui.game.action.claim')" 
                    image='claim2' 
                    :imageSize="imageSize*0.8"
                    @click="clickAction('claim');" 
                />
            </div>
            <div v-else
                style="margin-top:1em;"
                class="cajaBotones"
            >        
                <div>{{t('ui.game.action.confirm')}}</div>
                <GameButton :key="'ButtonMatchConfirm'" :id="'ButtonMatchConfirm'" :text="t('ui.game.action.no')" image='no' @click="clickCancel()" />
                <GameButton :key="'ButtonMatchCancel'" :id="'ButtonMatchCancel'" :text="t('ui.game.action.yes')" image='si' @click="clickConfirm()" />
            </div>
        </div>
    </div>
</template>

<script>

    import GameButton from "../buttons/GameButton.vue";
    import PlayerBadge from "./PlayerBadge.vue";
    import { useI18n } from 'vue-i18n'
    var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');

	export default {
		name: 'ClaimDialog',
        components: {GameButton,PlayerBadge},
        props: ['points','players','phase','imageSize','playerID','screenMode'],
        data: function() {
            return {
                pleaseConfirm:false,
                action:null,
            }
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },         
        computed: {
            otherPlayer: function() {
                let playerID = this.playerID || 0;
                return  (parseInt(playerID)+1)%2;
            },
            numberOfGames: function() {
                return this.points.games_record.length;
            },
            calculateNewRanking: function() {
                let otherPlayer = (parseInt(this.playerID)+1)%2;
                let rl = lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,otherPlayer,this.points.goal);
                let rw = lobbyLib.playersNewRanking(this.players[0].ranking,this.players[1].ranking,this.playerID,this.points.goal);
                return [rl,rw];
            },    
        },
        emits:['claim-match','claim-close'],
        methods: {	            
            clickAction: function (action) {
                this.pleaseConfirm = true;
                this.action = action;
                //this.ctx.events.setPhase("defineWhoStarts");
            },
            clickConfirm: function() {                 
                this.pleaseConfirm = false;
                if (this.action=="claim") {      
                    this.$emit("claim-match");
                }                                       
            },
            clickClose: function() {
                this.$emit("claim-close");
            },
            clickCancel: function() {
                this.pleaseConfirm = false;
                this.action = "";
            },
            clickQuitNoClaim:function() {
                location.reload();
            },
            doubleCubeSrc: function(doubleCube) {
                if (!doubleCube.holder) {
                    return require("@/assets/img/dados/dd00.svg")
                }
                else {
                    return require(`@/assets/img/dados/dd${doubleCube.value}.svg`);
                }
            },
            reasonSrc: function(reason) {
                return require(`@/assets/img/actions/${reason}.svg`);
            }
        },
        mounted: function() {
            this.$nextTick(function () {                
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .matchEndContainer { 
        box-sizing: border-box;
        position:absolute;
        width:80%;
        height:70%;
        background-color:#072b17;
        left:10%;
        top:10%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius:0.5em;
        filter:drop-shadow(0.1em 0.2em 0.3em black);
        padding:0.5em 0.5em 1.5em 0.5em;
        z-index:10;
    }

    .buttons > div {
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;        
    }

    img {
        width:3em;
        margin:0.5em;
    }

    .gameDetailsContainer img {
        width:1.5em;
        margin:0.2em;
    }

    .gameDetails {
        display:flex;
        justify-content: space-around;
        border-bottom: 0.1em solid white;
        margin-bottom:0.1em;
    }

    .gameDetails > div {
        margin:0 0.5em;
    }

    .gameDetailsContainer {
        font-size:1em;
        max-height: 8em;
        overflow: auto;
    }

    .playersContainer {
        display:flex;
        flex: 1 1 0px;
    }

    .firstRow {
        display:flex;
    }

    @media (orientation:portrait) {
        .firstRow {
            flex-direction:column;

            >div:last-child {
                margin:0.5em 0.5em 0.5em 0.5em;
            }
        }
    }

    .matchEndHeader { 
        display:flex;
        justify-content: space-around;
        align-items: center;
        font-size:1.5em;
    }

    .pointsBadge {
        background-color:rgb(0, 0, 0);
        padding:0.3em;
        border-radius: 50%;
        min-width: 1.5em;
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;
    }

    .MatchEndPoints {
        background-color:rgb(89, 121, 189);
        padding:0.3em;
        border-radius: 50%;
        width: 1.5em;        
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;    
        display: flex;    
        justify-content: center;        
    }

    .cajaBotones {
        border-radius: 1em;
        width: 90%;
        margin-left:5%;
        box-sizing: border-box;

    }

    .surrenderResult {
        display:flex;
        justify-content:center;
        align-items: center;
    }

    .surrenderResult > div:nth-child(3) {
        margin-left:1em;
    }
    .playerContainerSurrender {
        width: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;        
        justify-content: flex-start;
        align-items: center;        
    }
    
    .playerMacroContainer {
        width: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

</style>
