var abakLib = require('../AbakEvolutionLibrary.js');

/* Perform move Checker Operation. */
const moveChecker = function(G,ctx,target) {  

     let tables = abakLib.convertCheckersMap(G.checkers_map);
     let startColumn = G.checkers_map[target.idchecker-1].column;      
     let startHeight = G.checkers_map[target.idchecker-1].height;
     let checkerOwner = G.checkers_map[target.idchecker-1].owner+1;                  
     let checkerClass = G.checkers_map[target.idchecker-1].cclass;                
     
     // Perform Movement.
     let table_result = abakLib.moveChecker(tables[0],tables[1],target.idchecker,checkerOwner,target.column);
     
     // if General is moving not from the bar, set general direction.
     if (checkerClass == 2 && startColumn!=26 && startColumn!=27 ) 
         G.generalDirection = target.direction;

    // Create new CheckersMap.
     let new_checkers_map = abakLib.convertTableMapstoCheckerMap(table_result[0],table_result[1]);
     
     // Only change values of effectively changed checkers, to avoid rerendering .
     for (let c in G.checkers_map) {
       
       // Validate Integrity (has never failed)
       if (new_checkers_map[c].id == G.checkers_map[c].id) {
         if (
           new_checkers_map[c].column != G.checkers_map[c].column ||
           new_checkers_map[c].height != G.checkers_map[c].height
           ) {
               G.checkers_map[c].column = new_checkers_map[c].column;
               G.checkers_map[c].height = new_checkers_map[c].height;
           }                        
       }
       else {
         console.log("Error en Transcripcion CheckerMAP");
       }
     }
 
     // Set Used Die.
     let diceIndex = 0;
     for (let p in G.diceAvailable) {
       if (G.diceAvailable[p]==target.dice[0]) {
         G.diceAvailable[p]=0;
         diceIndex = p;
         break;
       }
     }

     //Add to current Movements.
     G.currentMovements++;

     //Push to Turns Log.
     G.turnslog.currentMoves.push({
       idchecker:target.idchecker,
       startColumn:startColumn,
       startHeight:startHeight,
       endColumn:target.column,
       dice:diceIndex
     });

     /*
     let isFinished = abakLib.finishedGame(G.checkers_map);

     if (isFinished) {
       console.log(isFinished);
       G.points.current[isFinished.winner]+=isFinished.points*G.doubleCube.value;
       G.points.games_record.push({
           'winner':ctx.currentPlayer,
           'points':isFinished.points,
           'doubleCube':G.doubleCube,
           'reason':'finished'
         });

         ctx.events.setPhase('endedGame');     
     } 
     */       
}

module.exports = { moveChecker };