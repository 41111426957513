<template>
    <div class="online_widget" :class=" { notifyflash: flash }">
        <div v-if="showTitle"> Online </div>
        <div v-if="wrapTitle" style="height:0;flex-basis: 100%;"> </div>
        <div>
            <img style="" :src="require('@/assets/img/buttons/people.svg')" />
            <div> {{ online !== null ? online : "?" }} </div>
        </div>
        <div v-if="type!='minimal'">
            <img style="" :src="require('@/assets/img/symbols/star.svg')" />
            <div> {{ online !== null ? friends : "?"  }} </div>
        </div>        
        <div v-if="type!='minimal' && zone=='game'">
            <img style="" :src="require('@/assets/img/symbols/visibility.svg')" />
            <div> {{ visitors }} </div>
        </div>        
        <div class="pendingBadge" v-if="pending>0"> {{ pending }} </div>
    </div> 
</template>

<script>

    //import BOARD_BAR1 from "../../js/constants.js";
    //import BOARD_BAR2 from "../../js/constants.js";

	export default {
		name: 'OnlineWidget',
        props: {
            online: {
                type: [Number,null],
                required: true,
                default:null
            },
            friends: {
                type: [Number,null],
                required: true,
                default:null
            },
            pending: {
                type: [Number,null],
                required: true,
                default:null
            },
            showTitle: {
                type: Boolean,
                default: false
            },
            wrapTitle: {
                type: Boolean,
                default: false
            },
            visitors: {
                type: [Number,null],
                default:0
            },
            type: {
                type: String,
                default: 'normal'
            },
            zone: {
                type:String,
                default:"lobby",
            }
        },
        data: function() {
            return {
                flash: false
            }
        },
        computed: {
        },
        watch: {
            visitors: function(newVisitors,oldVisitors) {
                if (newVisitors>oldVisitors && this.zone == 'game' ) {
                    this.flash = true;
                    setTimeout(()=>{this.flash=false},2000);
                }
            }
        },
        methods: {	
        },
        mounted: function() {
            this.$nextTick(function () {
                //console.log("online",this.online);
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @media (hover: hover) {
        .online_widget:hover {
                background-color: $c7;
                color:$c0;
            }
    }    

    img {
        width:1.5em;
        filter:invert(1);
    }
    .online_widget {
        position:relative;
        display: flex;
        background-color: $c6;
        border-radius: 0.5em;
        align-items: center;
        justify-content: center;
        min-height: 2em;
        padding:0.3em;
        cursor:pointer;
        flex-wrap: wrap;
        box-shadow: $box-shadow-normal;
        >div {
            display: flex;
            margin:0em 0.5em;
            align-items: center;
            >div:last-child {
                margin-left: 0.3em;
                background: #0006;
                min-width: 1.2em;
                display: flex;
                justify-content: center;
                border-radius: 50%;
                font-size: 1.2em;
                padding:0.2em;
            }
        }

        .pendingBadge {
            font-size:0.7em;
            background: red;
            margin-top:-0.9em;
            min-width: 1em;
            border-radius: 0.5em;
            display: flex;
            justify-content: center;
        }
    }

    .badge {
        background: $c9;
        display: flex;
        border-radius: 50%;
        padding: 0.1em;
    }

    @keyframes notifyflash {
        from {
            background-color: $c15;
        }
        to {
            background-color: $c11;
        }
    }
    .notifyflash {    
        animation-name: notifyflash;
        animation-duration: 1s;
        animation-iteration-count: infinite;


    }
 
</style>
