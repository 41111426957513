
export default {
  
    background: 'inherit', // Frame Background
    path: '',
    // Board Elements.
    board: {
        background: '#9e8863',
        border: '#4d3025',
        bar: '#4d3025',
        //The Board has 18 Points, colors has to contrast teams checkers. Odd and event placed point should be of noticable differnt color. 
        points: [
          // Odd placed Point
          {
            background: "#ac784a",
            border: "#230d07"
          },
          // Even placed Point
          {
            background: "#a03639",
            border: "#230d07"
          }
        ]
      },
      // Dice Set
      dice: [
        // Dice for player 1. Should be Very similar to checkers for player 1.
        {
          background: "#e3e0b8",
          border: "#a6a16d",
          dot: "black",
          dotborder: "black",
        },
        // Dice for player 1. Should be Very similar to checkers for player 2.
        {
          background: "#492a02",
          border: "#290a02",
          dot: "white",
          dotborder: "",
        },
        // Disabled Dice
        {
          background: "#666",
          border: "#333",
          dot: "#333",
          dotborder: "#111",
        },
        // Dice when Invalid move
        {
          background: "#A00",
          dot: "black",
          dotborder: "#300",
          border: "#300"
        }
      ],
      cube: {
        background: "#ceca9d",
        color: "#2e130c",
      },
      //Players Checkers Definition
      checkers: [
        //Player 1
        {
          background: "#ceca9d",
          line: "#986941",
          object1: "#250e08",
          object2: "#40190f",
          color: "#2e130c",
        },
      //Player 2
      {
          background: "#2e130c",
          line: "#492a02",
          object1: "#c89159",
          object2: "#c89159",
          color: "#ceca9d"
        }
      ],
      //Action Buttons
      buttons: {
        default: {
          background: '#453521',
          border:"red",
          hover:"gray",
          off:"#300"
        },
        light: {
          background: '#69621c',
          border:"red",
          hover:"gray",
          off:"#000"
        },
        soft: {
          background: '#69621c',
          border:"black",
          hover:"gray",
          off:"#000"
        },
        warning: {
          background:'#ada224',
          border:"black",
          hover:"grey",
          off:"#000"
        }
      },
      // UI Elements
      elements: {
        box1: {
          background:"#393a25",
          color:"white"
        },
        box2: {
          background:"#985d19",
          color:"white"
        },
        scroll: {
          bar:"#555555",
          hover:"#999999"
        },
        chrono: {
          total: {
            background:"#9e8863",
            color:"white",  
          },
          current: {
            background:"#4e4833",
            color:"white",  
          }
        }
      }
}