<template>
    <div class="chatContainer">
        <div class="chatFrame">
            <div class="chatContent">
                <div class="chatHeader">
                    <div style="font-size:2em;">Chat </div>
                    <div style="position: relative"> 
                        <div style="">
                            <OptionsList
                            :options="sendersForMe"           
                            @option-selected="selected_chatter_id=$event"
                            :initValue = "selected_chatter_id ? selected_chatter_id.toString() : '0' "
                            :newMessages = "chat.newmessages"
                            :matchID = "matchID"
                            :zone="zone"
                            />
                        </div>
                    </div>     
                    <CloseButton @click="$emit('chat-close')" /> 
                </div>       
                <div class="chatMessagesContainer" >

                    <div v-if="isChatFriendOnline && zone!='game'" class="chatInternalNotification"> 
                        <div @click="$emit('send-invitation',selected_chatter_id)">  
                            Invite {{friendship.friends[selected_chatter_id].name}} to play 
                        </div>
                    </div>
                    <div 
                        v-for="m in filtered_messages" 
                        :key='"Mess_"+m.id'
                        class="chatMessage"
                        :class="{messageReverse: player_id==m.sender_id}"
                    >                        
                        <div class="senderBox" :class="{messageReverse: player_id==m.sender_id,verticalReverse: player_id==m.sender_id}">
                            <div style="min-width:2.5em;text-align: center;">{{formatedDateDiff(Date.now(),m.date_sent)}}</div>
                            <div class="sender">
                                <div style="display:none">{{m.sender_id}}</div>
                                <div><img style="width:1.5em;" :src="m.simage" /></div>
                                <div>{{m.sname}}</div>
                                <div style="display: flex;">
                                    <country-flag :country=m.scountry size='normal' :rounded=true style="margin:-1em;"/>
                                </div>
                            </div>
                        </div>
                        <div class="text" :class="{verticalReverse: player_id==m.sender_id}" style="flex:1;">{{m.text}}</div>
                    </div>
                    <div ref="dummyBottom" class="dummy" >&nbsp;</div>
                </div>
                <div class="submitForm">
                    <div>
                        <div style="display: flex;align-items: flex-end;">
                            <textarea 
                                cols="30" 
                                rows="3"  
                                @keyup="onKeyUp($event)" 
                                type:text 
                                v-model="currentMessage"
                                style="font-size:1em;"
                                name='myMessage'
                                ref="myMessage" >
                            </textarea>
                        </div>
                        <div style="text-align: center;display: flex; flex-direction: column;flex-grow: 1;justify-content: space-between;"> 
                            <div 
                                style="padding:0.3em;align-self: flex-start;"
                                :style=" 254 - currentMessage.length >= 0 ?  'background-color: green;':'background-color: brown;' "> 
                                {{ 254 - currentMessage.length  }}
                            </div>
                            <button  @click="sendMessage()">Send</button>
                        </div>
                    </div>

                    <div class="emojiContainter">
                        <div>
                            <div @click="typeEmoji('👌')">👌</div>
                            <div @click="typeEmoji('✊')">✊</div>
                            <div @click="typeEmoji('👍')">👍</div>
                            <div @click="typeEmoji('👎')">👎</div>
                            <div @click="typeEmoji('👏')">👏</div>
                        </div>
                        <div>
                            <div @click="typeEmoji('🎲')">🎲</div>
                            <div @click="typeEmoji('😌')">😌</div>
                            <div @click="typeEmoji('😛')">😛</div>
                            <div @click="typeEmoji('😥')">😥</div>
                            <div @click="typeEmoji('😡')">😡</div>
                        </div>
                    </div>
                </div>
                <div v-show="toast.length>0" style="width:100%;text-align:center;">
                    {{toast}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import OptionsList from "../elements/OptionsList.vue"
    import CloseButton from "../elements/CloseButton.vue";

    const AWAITTOSCROLLATOPEN = 300;
    const AWAITTOSCROLLATNEWMESSAGE = 100;
	export default {
		name: 'ChatGlobal',
        //props: ['chat','player_id','mode','matchID'],
        props: ['chat','show','player_id','req_chatter_id','friendship','zone','requestedFriend','matchID'],
        components: {
            OptionsList,
            CloseButton
        },
        data: function() {
            return {   
                currentMessage:"",
                toast:"",
                selected_chatter_id: null,
                filtered_messages: []
            }
        },
        computed: {
            sendersForMe: function() {
                let sfm = [];
                let addedIndex = [];
                for (let m in this.chat.messages) {
                    if (this.chat.messages[m].receiver_id == this.player_id) {
                        if (!addedIndex.includes(this.chat.messages[m].sender_id)) {
                            sfm.push({ 
                                friend_id: this.chat.messages[m].sender_id,
                                name: this.chat.messages[m].sname, 
                                country: this.chat.messages[m].scountry,
                                image: this.chat.messages[m].simage,
                                last_chat_id : this.chat.messages[m].id
                            });
                            addedIndex.push(this.chat.messages[m].sender_id);
                        }
                        else {
                            let pId = sfm.find(item => item.sender_id === this.chat.messages[m].sender_id);
                            if (typeof(pId)!=='undefined') pId.last_chat_id = this.chat.messages[m].id;
                            //sfm[this.chat.messages[m].sender_id].last_chat_id = this.chat.messages[m].id
                        }
                    }
                    else {
                        if (this.chat.messages[m].receiver_id === 0) {
                            let pId = sfm.find(item => item.sender_id === this.chat.messages[m].sender_id);
                            if (typeof(pId)!=='undefined') pId.last_chat_id = this.chat.messages[m].id;
                        }
                    }
                }
                for (let f in this.friendship.active) {
                    const player_id = parseInt(this.friendship.active[f]);
                    if (!addedIndex.includes(player_id)) {
                        sfm.push({
                            friend_id:player_id, 
                            name: this.friendship.friends[player_id].name, 
                            image: this.friendship.friends[player_id].image,
                            last_chat_id : null
                        });
                        addedIndex.push(player_id);
                    }
                }
                return sfm;
            },
            chatLastId: function() {
                if (this.chat.messages.length>0)
                    return this.chat.messages[this.chat.messages.length-1].id;
                return 0;                
            },
            chatChanged: function() {
                return this.show;
            },
            isChatFriendOnline: function() {
                return this.friendship.online.includes(this.selected_chatter_id);
            }
        },
        watch: {
            //TODO: This Scrollers arent working if the component is hidden with v-if. 
            // That is why it is hidden with V-SHOW. It might work in onmounted thoug, and performance will be improved.
            chatLastId: function() {
                this.filtered_messages = this.filterMessages();
                this.scrollToBottom(AWAITTOSCROLLATNEWMESSAGE);
            },
            chatChanged: function(chatShow) {
                if (chatShow) {
                    this.scrollToBottom(AWAITTOSCROLLATOPEN);
                    //console.log("Chat Changed",this.zone,this.requestedFriend,this.matchID);
                    if (this.zone == 'game') this.selected_chatter_id = this.matchID;
                    else {
                        if (!this.requestedFriend) this.selected_chatter_id = 0;
                        else this.selected_chatter_id = this.requestedFriend;
                    } 

                    // If selected chater does not have new messages but there are new messages, select one with new messages.                    
                    if (!this.chat.newmessages[this.selected_chatter_id]) {
                        const newMessagesKeys = Object.keys(this.chat.newmessages);
                        if (newMessagesKeys.length > 0) {
                            this.selected_chatter_id = newMessagesKeys[0];
                        }
                    }

                    this.filtered_messages = this.filterMessages();
                }
            },
            selected_chatter_id: function() {
                this.filtered_messages = this.filterMessages();
                this.scrollToBottom(AWAITTOSCROLLATNEWMESSAGE);
            },
            requestedFriend: function(newRequest) {
                if (newRequest) {
                    this.selected_chatter_id = newRequest;
                }                
            }
        },
        emits: ['send-message','chat-close','send-invitation'],
        methods: {	
            onKeyUp: function(event) {
                if (event.key=="Enter")
                    this.sendMessage();
            },
            sendMessage: function() {
                if (this.currentMessage.length<3) {
                    this.toast=this.$t('lobby.chat.message.tooshort');
                    setTimeout(()=>{this.toast=''},2000);
                    return;
                }
                if (this.currentMessage.length>254) {
                    this.toast=this.$t('lobby.chat.message.toolong');
                    setTimeout(()=>{this.toast=''},2000);
                    return;
                }

                let receiver_id = 0;
                let session_id = null;
                if (/^\d+$/.test(this.selected_chatter_id)) {
                    receiver_id = this.selected_chatter_id;
                }
                else {
                    session_id = this.selected_chatter_id;
                }
                
                this.$emit('send-message',{ 
                    text: this.currentMessage, 
                    player_id: this.player_id , 
                    receiver_id: receiver_id,
                    session_id: session_id 
                } );
                this.currentMessage='';
            },
            filterMessages: function() {
                let filtered_messages = [];
                for (let m in this.chat.messages) {
                    if (this.selected_chatter_id == this.matchID ) {
                        if (this.matchID == this.chat.messages[m].session_id) {
                            filtered_messages.push(this.chat.messages[m]);
                        }
                    }
                    else {
                        if ( this.selected_chatter_id == 0) {
                            if (this.chat.messages[m].receiver_id == 0 && !this.chat.messages[m].session_id )
                                filtered_messages.push(this.chat.messages[m]);
                        }                    
                        else if ( 
                            (this.chat.messages[m].sender_id == this.selected_chatter_id &&
                            this.chat.messages[m].receiver_id == this.player_id ) ||
                            (this.chat.messages[m].receiver_id == this.selected_chatter_id &&
                            this.chat.messages[m].sender_id == this.player_id )
                            ) {
                            filtered_messages.push(this.chat.messages[m]);
                        }
                    }
                }   
                //filtered_messages = filtered_messages.splice(-10,);
                return filtered_messages;
            },
            formatedDateDiff: function(dateEnd,dateStart) {
                if (typeof dateStart != 'object') dateStart = new Date(dateStart);
                if (typeof dateEnd != 'object') dateEnd = new Date(dateEnd);
                let timedif = (dateEnd.getTime() - dateStart.getTime())/1000;
                if (timedif<60) return `${parseInt(timedif)} s`;
                if (timedif<60*60) return `${parseInt(timedif/60)} m`;
                if (timedif<60*60*24) return `${parseInt(timedif/60/60)} h`;
                return `${parseInt(timedif/60/60/24)} d`;
            },    
            typeEmoji: function(text) {
                this.currentMessage+=text;
                this.$refs.myMessage.focus();
            },
            scrollToBottom: function(time) {
                if (this.$refs.dummyBottom) {
                        setTimeout(
                            ()=>{
                                this.$refs.dummyBottom.scrollIntoView(
                                    {
                                        behavior: "smooth", 
                                        block: "end", 
                                        inline: "end"
                                    });
                            },
                            time);
                    }
            }        
        },
        mounted: function() {     
            this.$nextTick(function () {
                if (this.req_chatter_id)        
                    this.selected_chatter_id = this.req_chatter_id;
                else {
                    this.selected_chatter_id = 0;
                }
                this.filtered_messages = this.filterMessages();

                //this.$refs.dummyBottom.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .chatHeader {
        display:flex;
        justify-content:space-between;
        width:100%;
        align-items: center;

        select {
            font-size:1.2em;
            padding: 0.3em;
            border-radius: 0.5em;
            background: $c11;
        }

        option {
            border-radius: 1em;
            background: #0004;
        }
    }

    .chatFrame {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width: 100%;
        background-color:rgba(0, 0, 0, 0.6);
    }

    .chatContent {
        background: $content-background;
        margin:2.5%;
        display: flex;
        flex-direction: column;
        border-radius:1em;
        box-sizing: border-box;
        padding:0.5em;
    }

    .chatContent {
        >div:nth-child(2) {
            height:15em;

            };
    }

    .chatMessagesContainer {   
        //padding:0.5em;
        border-radius: 0.5em;
        background: $sub-content-background;
        margin-top:1em;
        margin-bottom:1em;
        height:80%;
        overflow-y:scroll;
        position:relative;


        .sender , .receiver {
            padding: 0.2em;
            border-radius: 0.2em;
            margin:0.2em;
            display: flex;
            align-items: center;
            >div {
                margin:0 0.3em;
            }
        }

        .senderBox {
            display: flex;
            align-items: center;
        }

        .sender {
            background:$c8;
        }

        .receiver {
            background:$c9;
        }

        .chatMessage {
            margin:0.5em; 
            display:flex;
            justify-content: space-around;            
            align-items: flex-start;
            padding:0.2em;
        }

        .text {
            background-color:$c13;
            margin:0.2em;
            padding:0.5em;
            border-radius: 0.3em 1em 1em 0.3em;
        }


        .messageReverse {
            flex-direction: row-reverse;
            .text {
                border-radius: 1em 0.3em 0.3em 1em;
                }    
        }

    }


    .chatButton {
        border:1px solid black;
        background:red;
        width:3em;
        
    }


    input,button {
        font-size:1.5em;
        border-radius: 0.2em;
    }

    input {
    }

    button {
        background-color:$c10;
        margin-left:1em;
        box-shadow: $box-shadow-minimal;
    }

    .emojiContainter {
        display:flex;
        align-items: center;
        flex-direction: column;
        >div {
            display: flex;
        }
    }

    .submitForm {
        background:$c12;
        border-radius: 0.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        >div:first-child {
            display: flex;
            >div:first-child {
                flex-grow: 1;
            }
        }
        >div {
            margin:0.5em;
        }
    }

    .chatInternalNotification {
        position: sticky;
        top:1em;
        display: flex;
        justify-content: center;
        align-items: center;
        >div {
            background:$c18;
            margin:0.2em;
            padding:0.5em;
            border-radius: 0.5em;
        }
    }

    @media (orientation:portrait) {

        .chatContent {
            height:95%;
        }
        .chatContent {
            >div:nth-child(2) {
                height:90%;
                };        
        }

        .chatMessagesContainer {

            .chatMessage {
                flex-direction: column;

            }

            .verticalReverse {
                align-self:flex-end;
            }
        }

        .submitForm {
            flex-direction: column;
        }

        .emojiContainter {
            font-size:1.5em;
            margin-bottom:0.5em;
        }
        
    }

</style>
