
const ranking_cpu = { 3:500, 4:1000, 5:1500, 6:5000 };

const eloRules = {
    type: {
        abak:1,
        classic:0,
    },
    opponent: {
        type: {
            'CPU': 1,
            'HUMAN':1,
        }
    }
};



const playersNewRanking = function(r1,r2,winner,goal,stake=20) {
    //console.log(r1,r2,winner,goal);
    let puntos_por_partido=2*stake*(goal);
    let puntos=1;
    let base=0;
    let minimo=300;
    let nr1=r1-base;    
    let nr2=r2-base;
    if (nr1<base) nr1=base;
    if (nr2<base) nr2=base;
    let nt1=nr1/(nr1+nr2);
    let nt2=nr2/(nr1+nr2);
    let pt1=Math.floor(nt1*puntos_por_partido);
    let pt2=Math.floor(nt2*puntos_por_partido,0);
    let res1;
    let res2;
    if(winner==0) {
        res1=Math.max(r1+pt2*puntos,base);
        res2=Math.max(r2-pt2*puntos,base);
        }
    else {
        res1=parseInt(Math.max(r1-pt1*puntos,base));
        res2=Math.max(r2+pt2*puntos,base);
        }
    return [Math.max(res1,minimo),Math.max(res2,minimo)];
}

module.exports = { ranking_cpu, eloRules, playersNewRanking };