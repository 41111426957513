export const ogBasicData = [
    {
        name: 'og:title',
        content: 'Abak Evolution Backgammon'
    },
    {
        name: 'og:description',
        content: 'Abak Evolution is a two-player, abstract strategy board game. A Backgammon Variant that adds classes of checkers to the classic game.'
    },
    {
        name: 'og:type',
        content: 'website'
    },
    {
        name: 'og:image',
        content: 'https://www.abak.me/img/abak_screenshot.png',
        itemprop: 'image'
    },

    { 
        name: 'og:image:width',   
        content:     '1200', 
    },

    { 
        name: 'og:image:height',   
        content:     '630', 
    },

    { 
        name: 'og:image:type',   
        content:     'image/png', 
    },

    { 
        name: 'og:image:alt',   
        content: 'Abak Evolution Backgammon', 
    },
    
    {
        name: 'og:url',
        content: 'https://play.abak.me/'
    },
    {
        name: 'twitter:card',
        content: 'sumary'
    },

    {
        name: 'twitter:site',
        content: '@abakevolution'
    },        
];

export const ogTutorialsData = [
    {
        name: 'og:title',
        content: 'Tutorials - Abak Evolution Backgammon'
    },
    {
        name: 'og:description',
        content: 'In this interactive tutorial you can learn Backgammon and Abak Evolution through a series of lessons and puzzles. It works on any device, including mobiles and TVs.'
    },
    {
        name: 'og:type',
        content: 'website'
    },
    {
        name: 'og:image',
        content: 'http://www.abak.me/img/abak_screenshot.png'
    },
    {
        name: 'og:url',
        content: 'https://play.abak.me/tutorials'
    },
    {
        name: 'twitter:card',
        content: 'sumary'
    },

    {
        name: 'twitter:site',
        content: '@abakevolution'
    },        
];


export const metaBasicData = {}

metaBasicData.en = {
    play:[
        {
            name: 'title',
            content: 'Play Abak Evolution Backgammon.'
        },
        {
            name: 'description',
            content: 'Play Abak and Backgammon Online with people around the world, or challenge the sever. Get judged by the AI and learn from your blunders.'
        },
    ],
    tutorial:{
        all:[
                {
                    name: 'title',
                    content: 'Tutorials - Abak Evolution Backgammon.'
                },
                {
                    name: 'description',
                    content: 'Learn how to play Backgammon and Abak Evolution in this interactive tutorial, that will guide you from zero.'
                },
            ],
            0: [
                {
                    name: "title",
                    content: "The Board - Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Backgammon Board description."
                }
            ],
            1: [
                {
                    name: "title",
                    content: "Bring your soldier to safety. - Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Learn how to move your soldier. "
                }
            ],
            2: [
                {
                    name: "title",
                    content: "Escape from the opponent's home - Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Jump across the opponent's soldiers to escape his home and capture his soldier."
                }
            ],
            3: [
                {
                    name: "title",
                    content: "Escape from the opponent's the Bar! - Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "You have been captured. Learn how to escape from the Bar."
                }
            ],
            4: [
                {
                    name: "title",
                    content: "Bear off all your soldiers - Backgammon Tutorial"
                },
                {                
                    name: "description",
                    content: "Learn how to bear off (remove from board) your soldiers."
                }
            ],
            5: [
                {
                    name: "title",
                    content: "Abak v/s Backgammon - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Learn how both games are the same, but way different."
                }
            ],
            6: [
                {
                    name: "title",
                    content: "The Odd Wounded - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Learn to move the odd wounded and suffer its restrictions."
                }
            ],
            7: [
                {
                    name: "title",
                    content: "The Even Wounded - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Learn about the even wounded, predictably nightmare."
                }
            ],
            8: [
                {
                    name: "title",
                    content: "Wounded at the Bar - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Wounded classes can move any number when entering from the bar."
                }
            ],
            9: [
                {
                    name: "title",
                    content: "Wounded Bearing Off - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Wounded classes can move any number when bearing off."
                }
            ],
            10: [
                {
                    n5me: "title",
                    content: "The Guards - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Learn to use the guards strengths and weaknesses.\n"
                }
            ],
            11: [
                {
                    n6me: "title",
                    content: "The Druid - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Learn to use the druid. The most feared soldier."
                }
            ],
            12: [
                {
                    n7me: "title",
                    content: "The General - Abak Evolution Backgammon Tutorial"
                },
                {
                    name: "description",
                    content: "Learn to use the General. The most resourceful soldier."
                }
            ]
        },
};
metaBasicData.es = {
    play:[
        {
            name: 'title',
            content: 'Jugar Abak Evolution Backgammon.'
        },
        {
            name: 'description',
            content: 'Juega Abak Evolution Online, con otros jugadores alrededor del mundo, o desafía al servidor. Aprende de tu errores siendo juzgado por la inteligencia artificial.' },
    ],
    tutorial:{
        all:[
            {
                name: 'title',
                content: 'Tutoriales - Abak Evolution Backgammon.'
            },
            {
                name: 'description',
                content: 'Aprende a jugar Backgammon y Abak en este tutorial interactivo, que te guiará desde cero.' }],
        0: [
            {
                name: "title",
                content: "La Tabla - Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Descripción de la Tabla."
            }
        ],
        1: [
            {
                name: "title",
                content: "Trae a tu soldado a casa y sálvalo. - Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Aprende como mover tu soldado."
            }
        ],
        2: [
            {
                name: "title",
                content: "Escapa de la casa del oponente - Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Salta a través de los soldados del oponente para escapar de su casa y capturar su soldado."
            }
        ],
        3: [
            {
                name: "title",
                content: "Escapa de la capilla del Oponente - Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Has sido capturado, aprende como escapar de la capilla"
            }
        ],
        4: [
            {
                name: "title",
                content: "Salva a todos tus soldados. - Backgammon Tutorial"
            },
            {                
                name: "description",
                content: "Aprende a salvar (sacar del tablero) a tus soldados"
            }
        ],
        5: [
            {
                name: "title",
                content: "Abak v/s Backgammon - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Aprende como los dos juegos son iguales, pero increíblemente diferentes."
            }
        ],
        6: [
            {
                name: "title",
                content: "El herido Impar - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Aprende a mover el herido impar, y sufre sus restricciones."
            }
        ],
        7: [
            {
                name: "title",
                content: "El herido Par - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Aprende sobre el herido par, y su predictibilidad de pesadilla."
            }
        ],
        8: [
            {
                name: "title",
                content: "Heridos en la capilla. - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Las clases heridas pueden mover cualquier dado cuando entran de la capilla."
            }
        ],
        9: [
            {
                name: "title",
                content: "Heridos saliendo de la Tabla - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Las clases heridas pueden mover cualquier dado al salir de la tabla."
            }
        ],
        10: [
            {
                name: "title",
                content: "Los Guardias - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Aprende a usar la fuerza de los guardias y su debilidad."
            }
        ],
        11: [
            {
                name: "title",
                content: "El Druida  - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Aprende a usar el druida. El soldado más temido."
            }
        ],
        12: [
            {
                name: "title",
                content: "El General - Abak Evolution Backgammon Tutorial"
            },
            {
                name: "description",
                content: "Aprende a usar al general. El soldado más útil."
            }
        ]
    },
};

