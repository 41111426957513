<template>
    <div class="messageContainer"> 
        <div  class="firstRow">


                <!--   Messsages -->
                <div class="messageList" v-if="messages.length>0 && invitations.length==0">                    
                    <div :key="'msg'+messages[messages.length-1].id" class="messagesListContainer">
                        <div class="messageCHeader">
                            <div class="messageSender" v-if="messages[messages.length-1].sender_id>0"> 
                                <img style="width:1.5em;" :src="messages[messages.length-1].sender_image">
                                <div>{{messages[messages.length-1].sender_name}}</div>
                            </div>
                            <div> {{ formatedDateDiff(new Date(), messages[messages.length-1].sent_time ) }} </div>                        
                            <div style="flex-grow:1;"> </div>
                            <GameButton :key="'ButtonMessageDismiss'" :id="'ButtonMessageDismiss'" 
                                        :text="t('ui.game.action.close')" image='no' @click="clickMessageDismiss(messages.length-1)" 
                                        :type="'none'"
                                        style="margin:0.5em;"
                                        />
                        </div>
                        <div class="messageBody">    
                            <div v-html="messages[messages.length-1].title !='' ? messages[messages.length-1].title : messages[messages.length-1].mttitle" style="font-size:1.5em;margin-bottom:0.5em;"></div>
                            <div> 
                                <div v-if="messages[messages.length-1].image && messages[messages.length-1].image.length>3"> 
                                    <img :src="'/img/symbols/'+messages[messages.length-1].image+'.svg'" />
                                </div>
                                <div v-html="messages[messages.length-1].text ? messages[messages.length-1].text : messages[messages.length-1].mttext"></div>
                                    <div v-if="messageAction"> 
                                        <GameButton :key="'ButtonMessageAction'" :id="'ButtonMessageAction'" 
                                                :text="messageAction.data.text" 
                                                :image="messageAction.data.icon"
                                                @click="clickMessageAction(messageAction.action,messageAction.data)" 
                                                :type="'light'"
                                                style="margin:0.5em;font-size:1.5em;"
                                                
                                                />
                                    </div>
                            </div>                           
                        </div>
                    </div>                    

                </div> 



                <!--   Invitations -->
                <div class="messageList" v-if="invitations.length>0">
                    <div class="invitationBody"> 
                        <div class="invitationText"> 
                            <h1 style="margin-top:0;"> {{t('lobby.messages.newinvitation.title')}} </h1>
                            <h3> {{t('lobby.messages.newinvitation.headding')}} </h3>  
                            <div class="invitationTips"> 
                                <div>{{t('lobby.messages.newinvitation.tip')}}</div>
                                <div v-if="invitations[invitations.length-1].action=='active'">{{t('lobby.messages.newinvitation.active')}}</div>
                                <div v-if="invitations[invitations.length-1].action=='direct'">{{t('lobby.messages.newinvitation.direct')}}</div>
                            </div>                          
                        </div>
                        <div :key="'inv'+invitations[invitations.length-1].id" class="invitationListContainer">
                            <div class="invitationSender">                                                                 
                                <img :src="invitations[invitations.length-1].image">
                                <div>{{invitations[invitations.length-1].name}}</div>
                                <div>{{invitations[invitations.length-1].elo}}</div>                            
                            </div>
                            <div class="invitationType">
                                <div> 
                                    <div>
                                    <!--div>{{$t("lobby.subject.type")}}</div -->
                                     <div>
                                        <img style="width:2em;" :src="require('@/assets/img/symbols/type_'+gameObject.type+'.svg')" alt="{{gameObject.type}}"/>
                                     </div>
                                    </div>
                                    <div>
                                        <img style="width:1.2em;" :src="require('@/assets/img/symbols/stake.svg')" alt="stake"/>
                                        <div>{{gameObject.stake}}</div>
                                    </div>
                                </div>
                                <div> 
                                    <div>
                                        <img style="width:1.2em;" :src="require('@/assets/img/symbols/start.svg')" alt="{{gameObject.type}}"/>
                                        <div>{{gameObject.goal}}</div>
                                    </div>
                                    <div>
                                        <div>{{gameObject.maxTimeOut}}s</div>
                                    </div>
                                </div>    
                            </div>                    
                        </div>                    
                    </div>
                </div>                
        </div>

        <div class="buttons">
            <div v-if="!pleaseConfirm && invitations.length>0" style="padding-top:0.5em;">      

                <div class="cajaBotones">
                    <GameButton :key="'ButtonInvitationDismiss'" :id="'ButtonInvitationDismiss'" :text="t('ui.game.action.dismiss')" image='no' @click="clickInvitationDismiss( invitations[invitations.length-1].id )" :type="'none'" />
                    <GameButton :key="'ButtonInvitationConfirm'" :id="'ButtonInvitationConfirm'" :text="t('ui.game.action.join')" image='si' @click="clickInvitationJoin( invitations[invitations.length-1].id,invitations[invitations.length-1].session_id )" :type="'none'" />
                </div>

            </div>
            <div v-if="pleaseConfirm"
                style="margin-top:1em;"
                class="cajaBotones"
            >        
                <GameButton :key="'ButtonMatchConfirm'" :id="'ButtonMatchConfirm'" :text="t('ui.game.action.no')" image='no' @click="clickCancel()" />
                <GameButton :key="'ButtonMatchCancel'" :id="'ButtonMatchCancel'" :text="t('ui.game.action.yes')" image='si' @click="clickConfirm()" />
            </div>
        </div>
    </div>
</template>

<script>

    import GameButton from "../buttons/GameButton.vue";
    import { useI18n } from 'vue-i18n'

	export default {
		name: 'Messages',
        components: {GameButton},
        props: ['messages','invitations'],
        data: function() {
            return {
                pleaseConfirm:false,
                action:null,
            }
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },         
        computed: {
            gameObject: function() {
                if (this.invitations.length>0) {
                    try {
                        return JSON.parse(this.invitations[this.invitations.length-1].value);
                    }
                    catch {
                        return {}
                    }
                }
                return {}
            },
            messageAction:function() {
                try {
                    if (this.messages[this.messages.length-1].action.length>3) {
                        return { 
                              action:this.messages[this.messages.length-1].action, 
                              data:JSON.parse(this.messages[this.messages.length-1].action_value)
                            };
                    }
                    else return null
                }
                catch {
                    return null;
                }
            },
        },
        emits:['message-read','close','invitation-join','message-action','invitation-dismiss'],
        methods: {
            clickAction: function (action) {
                this.pleaseConfirm = true;
                this.action = action;
                //this.ctx.events.setPhase("defineWhoStarts");
            },
            clickConfirm: function() {                 
                this.pleaseConfirm = false;
                if (this.action=="claim") {      
                    this.$emit("claim-match");
                }                                       
            },
            clickCancel: function() {
                this.pleaseConfirm = false;
                this.action = "";
            },
            clickMessageDismiss: function() {
                this.$emit("message-read", {index: this.messages.length-1, id:this.messages[this.messages.length-1].id});
            },
            clickInvitationDismiss: function(id) {
                this.$emit("invitation-dismiss", {id:id} );
            },
            clickInvitationJoin: function(id,session_id) {
                this.$emit("invitation-join", {id:id,session_id:session_id} );
            },
            formatedDateDiff: function(dateEnd,dateStart) {
                if (typeof dateStart != 'object') dateStart = new Date(dateStart);
                let timedif = (dateEnd.getTime() - dateStart.getTime())/1000;
                if (timedif<60) return `${parseInt(timedif)} s`;
                if (timedif<60*60) return `${parseInt(timedif/60)} m`;
                if (timedif<60*60*24) return `${parseInt(timedif/60/60)} h`;
                return `${parseInt(timedif/60/60/24)} d`;
            },
            clickMessageAction:function(action,data) {
                this.$emit('message-action',{action,data});
                this.clickMessageDismiss();
            }
        },
        mounted: function() {
            this.$nextTick(function () {                
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .messageContainer { 
        box-sizing: border-box;
        position:absolute;
        width:80%;
        height:70%;
        background-color:#072b17;
        left:10%;
        top:10%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius:0.5em;
        filter:drop-shadow(0.1em 0.2em 0.3em black);
        padding:0.5em 0.5em 1.5em 0.5em;
    }

    .buttons > div {
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;        
    }

    .playersContainer {
        display:flex;
        flex: 1 1 0px;
    }

    .firstRow {
        display:flex;
        width:100%;
    }

    .messageHeader { 
        display:flex;
        justify-content: space-around;
        align-items: center;
        font-size:1.5em;
    }


    
    .playerMacroContainer {
        width: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .messageList {
        width: 100%;
        box-sizing: border-box;
    }

    .messagesListContainer {
        display:flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

    }

    .messageCHeader {
        display: flex;
        width: 90%;
        align-items: center;
    }

    .messageSender {
        display:flex;
        align-items: center;
        background: $c7;
        padding:0.3em;
        border-radius: 0.5em;
        margin:1em;        

    }

    .messageBody {
        width: 100%;
        background: $box1-background;
        display:flex;
        align-items: center;
        padding:1em;   
        box-sizing: border-box;
        flex-direction: column; 
        border-radius: 1em;    

        >div:last-child {

            >div {
                margin:0em 1em;
            }

            display: flex;
            align-items: center;

            >div:first-child img {
                width: 5em;
            }
        }
    }

    .cajaBotones {        
        display:flex;
        justify-content: space-around;
        width: 100%;
    }

    .invitationBody {
        display:flex;
        padding: 1em;
    }

    .invitationText {
        margin-right: 1em;
    }

    .invitationListContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        background: $c4;
        border-radius: 1em;
        justify-content: center;
    }

    .invitationSender {
        display: flex;
        flex-direction: column;
        align-items: center;
        background:$checkerblack-background;
        color:$checkerblack-color;
        border-radius: 1em;
        padding:0.5em;
        img {
            width: 2em;
        }
    }

    .invitationType {
        display: flex;  
        justify-content: space-around;   
        align-items: center;     
        flex-direction: column;
        
        >div {
            display: flex;
            >div {
                display: flex;
                flex:0.2;
                min-width: 2em;  
                justify-content: center;
                align-items: center;      
                border-radius: 0.5em;
                padding: 0.2em 0.2em;
                background:$c11;
            }
        }
    }

    .invitationTips {
        >div:last-child {
            background:$c8;
            padding:0.3em;
            border-radius: 0.5em;
            margin:1em 0.2em; 
        }
    }

    @media (orientation:portrait) {
        .messageContainer {
            font-size:1.3em;
        }

        .invitationBody {
            flex-direction: column;
        }

        .invitationListContainer {
            width:100%;
            flex-direction: row;
            justify-content: space-around;
        }

        .invitationType {
            flex-direction: column;
            flex:0.5;

            >div {
                margin:0.3em;
            }
        }

        .invitationText {
            margin:0;
        }

        .messageBody {
            >div:last-child {
                flex-direction: column;
            }
        }

        
    }

</style>
