<template>
    <div v-show="options!==null && options.column>=0 && options.column<=25" class="selectedPosition" :id="'selectedPosition_'+ppid"
     :style="xy"
    >
        <img               
              :src ="require(`@/assets/img/board/ps1.svg`)"
              :style ="{ 
                  height: colHeight*YunitSize + 'px' , 
                  width: colWith*XunitSize + 'px',                                 
                }"
        >         
        <Dice 
                style = "width:50%;position:absolute;left:25%;"
                :style=" { top: options && options.column && distanceToHome>12 ? '60%' : '30%' }"
                :colorClases="colors" 
                :dNumber="diceValueSrc"
                :dClass="diceTypeSrc"
        />
        <div 
            v-if="options && options.druidAction && options.druidAction>0" 
            :style=" { top: options && options.column && distanceToHome>12 ? '80%' : '10%' }"
            style="
                position: absolute;
                left:25%;
                text-align: center; 
                width:50%;"> 
            <template v-if="options.druidAction==2"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock">
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                    <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                </svg>            
            </template>
            <template v-if="options.druidAction==1"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-jail">
                    <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
                    <line x1="8" y1="2" x2="8" y2="22" />
                    <line x1="16" y1="2" x2="16" y2="22" />
                </svg>
            </template>

            
        </div>
    </div>
</template>

<script>

    //import BOARD_BAR1 from "../../js/constants.js";
    //import BOARD_BAR2 from "../../js/constants.js";
    var posHelper = require('../../assets/js/positionHelpers');
    import Dice from './Dice.vue';

	export default {
		name: 'TargetPosition',
        props: ['ppid','options','XunitSize','YunitSize',
                'colWith','colHeight','maxTop','playerID','direction','checkersColor','colors'
               ],
        data: function() {
            return {
            }
        },
        components: {
            Dice
        },
        computed: {
            diceTypeSrc: function() {
                if (!this.options) return 3;
                let response;
                let playerID = parseInt(this.playerID) || 0;
                if (this.checkersColor != parseInt(this.playerID) ) playerID = ( playerID + 1 ) % 2;
                if (this.options.action=="move") {                    
                    response = playerID;
                } 
                if (this.options.action=="cantmove") response = 3;
                return response;
            },
            diceValueSrc: function() {
                if (!this.options) return 1;
                return this.options.dice[0];
            },
            xy: function() {
                if (!this.options) return "";
                let playerID = this.playerID || 0;
                let nX=posHelper.x([this.options.column,1],this.direction);                
                let nY=posHelper.targetY(this.options.column,playerID);
                return `transform:translate(${ nX*this.colWith*this.XunitSize }px,${ (this.maxTop-nY*this.colWith*this.YunitSize) }px);`;
            },
            distanceToHome:function() {
                let column = this.options.column || 0;
                if (column>25) return 26;
                if (this.playerID=="1") return 25-column;
                if (this.playerID=="0") return column;   
                return 0;             
            }
        },
        methods: {	
        },
        watch: {
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .selectedPosition {
        position:absolute;
    }

    img {
        width:100%;
        height:100%;
    }
     


</style>
