<template>

    <div class = "menuFrameContainer">
        <div class="menuContainer">  
            <!-- ***************** HEADER ******************--->
            <div class="menuHeader">           
                <div class="menuHeaderOptions">
                    <div class="menuOptions">
                        <div key="umo1"
                            :class="{ menuActive: menu.section == 'stats' }"
                            @click="menuSelected('stats')"
                        ><img :src="require('@/assets/img/buttons/stats.svg')" /></div>
                        <div key="umo2"
                            :class="{ menuActive: menu.section == 'lmatches' }"
                            @click="menuSelected('lmatches')"
                        ><img :src="require('@/assets/img/buttons/matcheslogs.svg')" /></div>
                        <div key="umo3"
                            :class="{ menuActive: menu.section == 'medals' }"
                            @click="menuSelected('medals')"
                        ><img :src="require('@/assets/img/buttons/medal.svg')" /></div>
                        <div key="umo4"
                            :class="{ menuActive: menu.section == 'daily' }"
                            @click="menuSelected('daily')"
                        ><img :src="require('@/assets/img/buttons/chartup.svg')" /></div>
                    </div>
                    <CloseButton @click="$emit('close')"/>
                </div>
            </div>
            <!-- ***************** ENF OPTIONS ******************--->

            <!-- ***************** USER MAtches List ******************--->
            <div v-if="menu.section=='lmatches'" class="contentContainer">
                <div class="matchesList"
                   v-if="data['lmatches'] !==null && !uIstate.fetching && !uIstate.showGameReview">   
                   <div v-if="data['lmatches']['index'].length==0" style="text-align: center;margin-top:2em;">No Finished matches yet</div>             
                   <div v-for="mid in data['lmatches']['index']" :key="'dmatch'+mid" >
                    <div class="matchInfo">
                            <div class="date">
                                <DateTimeDisplay :datetime="data['lmatches']['list'][mid].match.mcreated" />
                            </div>
                            <div>
                                <div><img style="width:1.5em;" :src="require('@/assets/img/symbols/type_'+data['lmatches']['list'][mid].match.gameType+'.svg')" /></div>
                                <div> <img style='width:1em;filter:invert(1)' :src="require('@/assets/img/symbols/goal.svg')"/>  {{data['lmatches']['list'][mid].match.goal}}</div>
                                <div> <img style='width:1em;filter:invert(1)' :src="require('@/assets/img/symbols/stake.svg')"/>  {{data['lmatches']['list'][mid].match.stake}}</div>
                            </div>
                            <div>
                                <img v-if="0 < data['lmatches']['list'][mid].match.deltaELO" style='height:1em;' :src="require('@/assets/img/symbols/up.svg')" />
                                <img v-if="0 > data['lmatches']['list'][mid].match.deltaELO" style='height:1em;' :src="require('@/assets/img/symbols/down.svg')" />
                                <span v-if="0 != data['lmatches']['list'][mid].match.deltaELO"> {{Math.abs(data['lmatches']['list'][mid].match.deltaELO)}}</span>
                                <span v-if="0 == data['lmatches']['list'][mid].match.deltaELO"></span>
                            </div>
                            <div v-if="screenMode=='vertical'" style="flex:1 1 100%"></div>
                            <div v-if="data['lmatches']['list'][mid].match.opponentType=='CPU'" class="playersContainer">
                                <div>
                                    <div> 
                                        <div>{{data['lmatches']['list'][mid].match.p1name}}</div>
                                        <div><img style="width:1em;" :src="data['lmatches']['list'][mid].match.p1image" /></div>
                                    </div>
                                    <div :class = "{ winner: data['lmatches']['list'][mid].match.p1winner==1, looser: data['lmatches']['list'][mid].match.p1winner!=1 }" > 
                                        <div> 
                                            {{data['lmatches']['list'][mid].match.p1points}}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div> 
                                        <div>{{txtLevelName[data['lmatches']['list'][mid].match.opponentLevel]}}</div>
                                        <div><img style="width:1em;" :src="'/img/players/avatars/10'+data['lmatches']['list'][mid].match.opponentLevel+'.svg'"/></div>
                                    </div>
                                    <div :class = "{ winner: data['lmatches']['list'][mid].match.p2winner==1, looser: data['lmatches']['list'][mid].match.p2winner!=1 }"> 
                                        <div> {{data['lmatches']['list'][mid].match.p2points}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="playersContainer">
                                <div>
                                    <div>
                                        <div>{{data['lmatches']['list'][mid].match.p1name}}</div>
                                        <div><img style="width:1em;" :src="data['lmatches']['list'][mid].match.p1image" /></div>
                                    </div>
                                    <div :class = "{ winner: data['lmatches']['list'][mid].match.p1winner==1, looser: data['lmatches']['list'][mid].match.p1winner!=1 }"> 
                                        {{data['lmatches']['list'][mid].match.p1points}}
                                    </div>
                                </div>
                                <div>
                                    <div> 
                                        <div> {{data['lmatches']['list'][mid].match.p2name}} </div>
                                        <div><img style="width:1em;" :src="data['lmatches']['list'][mid].match.p2image" /></div>
                                    </div>
                                    <div :class = "{ winner: data['lmatches']['list'][mid].match.p2winner==1, looser: data['lmatches']['list'][mid].match.p2winner!=1 }"> 
                                        {{data['lmatches']['list'][mid].match.p2points}}
                                    </div>
                                </div>
                            </div>
                            <div class="switchButton" @click="uIstate.filters.matchDetail==mid ? uIstate.filters.matchDetail=null: uIstate.filters.matchDetail=mid">
                                <img v-if="uIstate.filters.matchDetail!=mid" style='width:1.4em' :src="require('@/assets/img/buttons/visitor.svg')" />
                                <img v-else style='width:1.2em' :src="require('@/assets/img/buttons/close.svg')" />
                            </div>
                        </div>
                        <!-- List Games of Match .  -->
                        <div v-if ="uIstate.filters.matchDetail==mid" class="gameDetail" >
                            <template
                                v-for="game,gid in data['lmatches']['list'][mid].games" :key="'dgame-'+mid+'-'+gid"       
                                >
                                    <div>{{gid+1}}</div>
                                    <div class="date"> 
                                        <div>
                                            <DateTimeDisplay :datetime="game.gcreated" :showDate="false"/>
                                        </div>
                                    </div>
                                    <div>{{game.turns}}</div>
                                    <div class="score checkerwhite" >
                                        {{game.winner == 0 ? game.points: 0 }}
                                        <span v-if="game.winner == 0"> × </span>
                                        <img v-if="game.winner == 0" style="width:1.2em" :src="doubleCubeSrc(game.cube)"/>
                                    </div>
                                    <div class="score checkerblack" >
                                        {{game.winner == 1 ? game.points: 0 }}
                                        <span v-if="game.winner == 1"> × </span>
                                        <img v-if="game.winner == 1" style="width:1.2em" :src="doubleCubeSrc(game.cube)"/>
                                    </div>
                                    <div><img style="width:1.2em" :src="reasonSrc(game.reason)"/></div>                                    
                                    <div class="switchButton" @click="uIstate.gameReview={ on:true, gid:gid, mid:mid };">
                                        <img style='width:1.4em' :src="require('@/assets/img/buttons/stats.svg')" />
                                        
                                    </div>
                                    <!--div> </div -->

                            </template>
                        </div>
                    </div>
                </div>

                <div v-if="uIstate.gameReview.on"> 
                    <GameReview 
                        @close-game-analysis="uIstate.gameReview.on=false;"
                        :game="data['lmatches']['list'][uIstate.gameReview.mid].games[uIstate.gameReview.gid]"
                        :match="data['lmatches']['list'][uIstate.gameReview.mid].match"
                        :match_id="uIstate.gameReview.mid"
                        :boardImageSize="imageSize*10"
                        :screen-mode="screenMode"
                        :player="player"
                        :theme="theme"
                    />
                </div>

            </div>

            <!-- ***************** USER Stats ******************--->
            <div v-if="menu.section=='stats'" class="contentContainer">
                <div 
                   v-if="data['stats'] !==null && !uIstate.fetching" 
                   style="display:flex;flex-direction:column;">
                    <div style="display:flex;justify-content:center;flex-wrap: wrap;"> 
                        <div class="statsBox1" style="display:flex;flex-direction:column;align-items:center;"> 
                            <div> Age </div> 
                            <div> <div>  {{ daysRegistered }} day </div> </div>
                            
                        </div>
                        <div class="statsBox1">  
                            <div>Total</div>
                            <div style="display:flex;"> 
                                <div style="display:flex;flex-direction:column;align-items:center;"><div>Games</div><div>{{data['stats'].games_number}}</div></div>
                                <div style="display:flex;flex-direction:column;align-items:center;"><div>Matches</div><div>{{data['stats'].matches_number}}</div></div>
                            </div>
                        </div> 
                        <div style="display:flex;justify-content: space-around;"> 
                            <div class="statsBox1"> 
                                <div>By Type</div>
                                <div> 
                                    <div> <div>P2P</div><div>{{data['stats'] ? data['stats'].matchesp2p : ""}}</div></div>
                                    <div> <div>L3</div><div>{{data['stats'] ? data['stats'].matchesl3: ""}}</div></div>
                                    <div> <div>L4</div><div>{{data['stats'] ? data['stats'].matchesl4: ""}}</div></div>
                                    <div> <div>L5</div><div>{{data['stats'] ? data['stats'].matchesl5: ""}}</div></div>
                                    <div> <div>L6</div><div>{{data['stats'] ? data['stats'].matchesl6: ""}}</div></div>
                                </div>
                            </div>
                        </div>                          
                    </div>               
                    <div  style="display:flex;justify-content: space-around;flex-wrap: wrap;">         
                        <div class="statsBox1">
                            <div>Points</div>
                            <div>
                                <div> <div>Won</div><div>{{data['stats'].points_won}}</div></div>
                                <div> <div>Lost</div><div>{{data['stats'].points_lost}}</div></div>
                            </div>
                        </div>

                        <div class="statsBox1">
                            <div>Games</div>
                            <div>
                                <div> <div>Won</div><div>{{data['stats'].games_won}}</div></div>
                                <div> <div>Lost</div><div>{{data['stats'].games_lost}}</div></div>
                            </div>
                        </div>

                        <div class="statsBox1">
                            <div>Matches</div>
                            <div>
                                <div> <div>Won</div><div>{{data['stats'].matches_won}}</div></div>
                                <div> <div>Lost</div><div>{{data['stats'].matches_lost}}</div></div>
                            </div>
                        </div> 
                    </div>

                </div>
                <div v-else style="display:flex;align-items:center;justify-content:center;padding-top:10%;">
                    <MiniLoader :size="3"/>
                </div>
            </div>

            <!-- ***************** MEDALS CONTAINER ******************--->
            <div v-if="menu.section=='medals'" class="contentContainer">
                <div style="display:flex;justify-content:center;align-items:center;">
                    Soon
                </div>
            </div>
            <!-- ***************** DAYLY CONTAINER ******************--->
            <div v-if="menu.section=='daily'" class="contentContainer">
                <div class="DailyCharts">
                    <div>
                        <Bar 
                        :chart-data="dailyToChart(data.daily,['games_number','matches_number'])"
                        :chart-id="'23rwer'"
                        :width="imageSize*10"
                        :height="imageSize*8"
                        />
                    </div>
                    <div>
                        <Line 
                            :chart-data="dailyToChart(data.daily,['elo_won','elo_lost'],true)"
                            :chart-id="'23rwer2'"
                            :width="imageSize*10"
                            :height="imageSize*8"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>




<script>

    import MiniLoader from '../elements/MiniLoader.vue';
    import { Line, Bar } from 'vue-chartjs'
    import {  Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale,BarElement, PointElement, CategoryScale } from 'chart.js'
    import GameReview from './GameReview.vue';
    import DateTimeDisplay from '../elements/DateTimeDisplay.vue';
    import CloseButton from '../elements/CloseButton.vue';

    const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

    ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    BarElement,
    CategoryScale,    
    )

    export default {
		name: 'UserStats',
        props: ['player','imageSize','screenMode','theme'],
        components: { MiniLoader, Line, Bar, GameReview, DateTimeDisplay, CloseButton },
        data: function() {
            return {    
                menu: {
                    section:"stats"
                },
                uIstate: {
                    fetching:false,
                    fetched: {
                        daily:false,
                        user:false,
                        stats:false,
                        mlist:false,
                    },
                    filters: {
                        matchDetail: null,
                    },
                    gameReview: {
                        on:false,
                        gid:null,
                        mid:null
                    },
                },
                data: {
                    stats:null,
                    lmatches:null,
                    daily:null,
                    medals:null,
                    },
                txtLevelName: {
                    3:this.$t(`ui.game.ialevel.3`),
                    4:this.$t(`ui.game.ialevel.4`),
                    5:this.$t(`ui.game.ialevel.5`),
                    6:this.$t(`ui.game.ialevel.6`)
                },
            }            
        },
        emits: ['close'],
        computed: {
            daysRegistered: function() {
                if (!this.player.date_registered) return 0;
                let diff = (Date.now() - new Date(this.player.date_registered))/1000;
                return parseInt(diff/60/60/24);
            },
        },
        methods: {	
            menuSelected: function(section) {
                this.menu.section = section;
                if (this.uIstate.fetched[section]) {
                    return;
                }
                this.getPlayerStats();                
            },
            getPlayerStats: function() {
                this.uIstate.fetching = true;
                //console.log("getstats");
                if (!this.player.authToken) return;
                let postData = {
                    playerID: this.player.id,
                    section: this.menu.section,
                    params: {
                        maxTime:60
                    }
                }
                this.axios.post(
                    `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/get/stats`,
                    postData).then((response) => {
                    //console.log(response.data.result);
                    this.data[this.menu.section] = this.parseData(this.menu.section,response.data.result);
                    setTimeout(()=>this.uIstate.fetching = false,100);
                    this.uIstate.fetched[this.menu.section] = true;
                }).catch((error) => {
                    console.log(error);                    
                    });     
            },  
            convertStatsData(data) {
                let r = { 'all':data[0] };
                for (let n in data) {
                    if (n>0) {
                        r['all']['games_number']+=data[n]['games_number'];
                        r['all']['matches_number']+=data[n]['matches_number'];
                        r['all']['points_won']+=data[n]['points_won'];
                        r['all']['points_lost']+=data[n]['points_lost'];
                        r['all']['games_won']+=data[n]['games_won'];
                        r['all']['games_lost']+=data[n]['games_lost'];
                        r['all']['matches_won']+=data[n]['matches_won'];
                        r['all']['matches_lost']+=data[n]['matches_lost'];
                        r['all']['m3']+=data[n]['m3'];
                        r['all']['m4']+=data[n]['m4'];
                        r['all']['m5']+=data[n]['m5'];
                        r['all']['m6']+=data[n]['m6'];
                        r['all']['elowon']+=data[n]['elowon'];
                        r['all']['elolost']+=data[n]['elolost'];
                    }
                    r[data[n].type] = data[n];
                }
                return r;
            },
            parseData: function(section,data) {
                //let newData;
                switch(section) {
                    case "stats":
                        //console.log(data);
                        //newData = this.convertStatsData(data);
                        //console.log(newData);
                        //return newData;
                        return data[0];
                    case "lmatches":
                        return this.parseMatches(data);
                    case "medals":
                        return data;
                    case "daily":
                        return data;
                }
            },
            parseMatches: function(data) {
                let result = {

                }
                let index = [];
                for (let i in data) {
                    let mg = data[i];
                    if (typeof result[mg['mid']] === 'undefined' ) {
                        let deltaELO = mg['tokeni'] && mg['tokenf'] ? mg['tokenf'] - mg['tokeni'] : 0;
                        result[mg['mid']] = { 
                            match: {
                                p1id:mg['p1id'],
                                p2id:mg['p2id'],
                                p1name:mg['p1name'],
                                p2name:mg['p2name'],
                                p1image:mg['p1image'],
                                p2image:mg['p2image'],
                                p1points:mg['p1points'],
                                p2points:mg['p2points'],
                                p1winner:mg['p1winner'],
                                p2winner:mg['p2winner'],
                                opponentType:mg['opponentType'],
                                opponentLevel:mg['opponentLevel'],
                                gameType:mg['gameType'],
                                goal:mg['goal'],
                                mcreated:new Date(mg['mcreated']),
                                deltaELO:deltaELO,
                                stake:mg['stake']                                
                            },
                            games: []                            
                        };
                        index.push(mg['mid']);
                    }
                    result[mg['mid']].games.push(
                        {
                            gcreated:new Date(mg['gcreated']),
                            turns:mg['turns'] ,
                            winner:mg['winner'],
                            points:mg['points'],
                            reason:mg['reason'],
                            cube:mg['cube'],
                            gid:mg['gid'],
                        }
                    );
                }
                return {index:index,list:result};
            },
            getDatesInRange: function(startDate, endDate) {
                let currentDate = startDate;
                const dates = [];
                dates.push(new Date(currentDate));
                while (currentDate <= endDate) {
                    currentDate.setDate(currentDate.getDate() + 1);
                    dates.push(new Date(currentDate));
                }
                return dates;
            },
            findDate: function(date,data) {
                let di = date.getDate() + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
                for (let d in data) {
                    let dr = new Date(data[d].date);
                    let df = dr.getDate() + "-" + (dr.getMonth()+1) + "-" + dr.getFullYear();
                    if (df == di) {
                        return d;
                    }
                }
                return null;

            },
            dailyToChart: function(data,categories,minus=false) { 
                let r = {
                    labels: [],
                    datasets: []
                }

                let catColors = ['green','#FFAA00'];

                r.datasets.push(
                    { 
                        label:categories[0],
                        data:[],
                        fill:true,
                        tension:0.1,
                        backgroundColor: catColors[0],
                        borderColor: catColors[0],
                        pointBackgroundColor: catColors[0],
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: catColors[0],
                        pointHoverBorderColor: catColors[0],                            
                    });

                if (!minus) {
                    r.datasets.push({ 
                            label:categories[1],
                            data:[],
                            backgroundColor: catColors[1],
                            borderColor: catColors[1],
                            pointBackgroundColor: catColors[1],
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: catColors[1],
                            pointHoverBorderColor: catColors[1],                            
                            }
                        );
                }
                
                let minDate=new Date();
                let maxDate=new Date();

                for (let i in data) {
                    let d = data[i];
                    let date = new Date(d.date);   
                    minDate = new Date(Math.min(minDate,date));
                }
                
               //console.log(minDate,maxDate);    
                let datesArray = this.getDatesInRange(minDate,maxDate);
                //console.log(datesArray);
                for (let i in datesArray ) {   
                    let date = datesArray[i];
                    r.labels.push(date.getDate() + "-" + (date.getMonth()+1).toString().padStart(2,"0")); 
                    let datai = this.findDate(date,data);  
                    if (datai!==null) {
                        if (!minus) {
                            r.datasets[0].data.push(data[datai][categories[0]]);
                            r.datasets[1].data.push(data[datai][categories[1]]);                        
                        }
                        else {
                            if (r.datasets[0].data.length>0) {
                                let dataOld = r.datasets[0].data[r.datasets[0].data.length-1];
                                let dataNow = data[datai][categories[0]]-data[datai][categories[1]];
                                r.datasets[0].data.push(dataNow+dataOld);                        
                            }
                            else {
                                r.datasets[0].data.push(data[datai][categories[0]]-data[datai][categories[1]]);                        
                            }
                        }
                    }  
                    else {
                        if (!minus) {
                            r.datasets[0].data.push(0);
                            r.datasets[1].data.push(0);
                        }
                        else {
                            if (r.datasets[0].data.length>0) {
                                let dataOld = r.datasets[0].data[r.datasets[0].data.length-1];
                                r.datasets[0].data.push(dataOld);
                            }
                            else 
                                r.datasets[0].data.push(0);
                        }
                    }               
                }
                return r;
            },
            doubleCubeSrc: function(doubleCube) {
                if (doubleCube==1) {
                    return require("@/assets/img/dados/dd00.svg")
                }
                else {
                    return require(`@/assets/img/dados/dd${doubleCube}.svg`);
                }
            },
            reasonSrc: function(reason) {
                return require(`@/assets/img/actions/${reason}.svg`);
            },  
        },
        watch: {
        },
        mounted: function() {
            this.$nextTick(function () {
                this.uIstate.fetching = true;
                this.getPlayerStats()
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .menuFrameContainer {
        position:absolute;
        width: 100%;           
        height: 100%;
        top:0;
        left:0;
        background:#000000AA;
    }

    .menuHeader {
        box-sizing: border-box;
        text-align: center;
        width: 100%;        
        background: $header-background;
        /*background: linear-gradient(  90deg , #1b1a17 0%, #795a20 100%);*/
        border-radius: 0.5em 0.5em 0 0;       
        border-bottom: 0.2em solid $header-bottom; 
    }

    .menuContainer {
        position:absolute;
        width: 96%;           
        height: 96%;
        top:2%;
        left:2%;
        border-radius: 0.5em;
        display:flex; 
        flex-direction: column;    
        align-items: center;
        justify-content: flex-start;
        background: $content-background;
        box-sizing: border-box;
    }

    .menuYesNoOptions {
        background:$sub-content-background;
        padding: 1em;
        border-radius: 0.5em;
    }
    .menuYesNoOption {
        display:flex;
        justify-content:space-between;
        background:$box1-background;
        padding:0.5em;
        border-radius: 0.5em;
        align-content: center;
        align-items: center;
        margin: 0.5em 0;        
    }

    
    .menuHeaderOptions {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-left:5%;
        align-items: center;        
    }

    .menuOptions {
        display: flex;
        justify-content: center;
        margin-top:0.5em;
        flex-grow: 1;
        
        img {
            width: 1.8em;
            margin: 0 0.5em;            
         }
    }

    .menuOptions >div {
        background-color:#444;
        border-radius: 1em 1em 0 0;
        margin: 0 0.5em;
        display: flex;
        padding: 0.3em;
        cursor:pointer;
        box-shadow: 0.02em -0.05em 0.3em black;

    }

    @media (hover:hover) {
            .menuOptions>div:hover {
                filter:invert(1)
            }
    }


    .menuActive {
        background-color: $tab2-menu-active-background !important;

        img {
            filter:invert(0)
        }
    }

    .selected, .imageSelected {
        background-color: chartreuse;
    }

    .contentContainer {
        padding:0.5em 1em;
        box-sizing: border-box;
        width:100%;
        overflow-y: auto;
    }

    .contentTitle {
        text-align: center;
        font-size: 1.5em;
    }

    .avatarsBox {
        padding:0.5em;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        width:80%;
        margin-left:10%;
        box-sizing:border-box;
        margin: 0.7em 0 0.7em 10%;
        background-color: $c4;
        border-radius: 0.5em;
    }

    input {
        font-size:1em;
        background-color: rgb(209, 223, 215);
        border:1px solid white;
        border-radius: 0.5em;
        margin-left:0.5em;
    }

    .statsBox1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 1em;
        padding:1em;
        background:$c5;
        margin:0.5em;
        > div {
            display: flex;

            > div {
                background: $c8;
                margin:0.3em;
                padding:0.3em;
                border-radius: 0.5em;
                min-width: 3em;
                text-align: center;
            }
        
        }

    }

    
    .statsBox2 {
        margin:0.5em;
        display: flex;
        align-items: center;
        border-radius: 1em;
        padding:1em;
        background:$c5;
        > div {
                background: $c8;
                margin:0.3em;
                padding:0.3em;
                border-radius: 0.5em;
                min-width: 3em;
                text-align: center;
        }
    }

    .userVertical {
        flex-direction: column-reverse;
    }

    .matchesList {
        display: flex;
        flex-direction: column;
        padding:0.5em;
        border-radius: 0.5em;
    }


    
    .gameDetail {
        display: grid;
        row-gap:0.3em;
        grid-template-columns: 5% 28% 10% 11% 11% 7% 7%;
        justify-content: space-around;
        background:transparentize($c9, 0.6);
        padding:0.5em;
        border-radius: 0.3em;
        margin:0.3em;

        >div {
            padding:0.3em;
            text-align: center;
            display: flex;
            justify-content: center;
            border-radius: 0.5em;
        }

        .score {
            display: flex;
            align-items: center;
        }
    }

    .matchInfo {
        align-items: center;
        display: flex;
        justify-content: space-around;
        background: #00000036;
        padding: 0.5em;
        border-radius: 0.5em;
        margin-bottom: 0.3em;        
        >div:nth-child(2) {
            display: flex;
            >div {
                display: flex;
                min-width: 2em;
                background: $c0;
                padding: 0.3em;
                border-radius: 0.9em;
                width:1.5em;
                text-align: center;
            }
            flex:0.1;
        }

        .playersContainer {
            flex:0.3;
            display:flex;

            >div {
                display: flex;
                margin:0.2em;
                background:$c8;
                border-radius: 0.3em;
                justify-content: center;
                text-align: center;
                padding:0 0.3em;
                align-items: center;
                >div:last-child {
                    background:$c16;
                    display: flex;
                    margin-left:1em;
                    justify-content: center;
                    padding:0.3em;
                    border-radius: 1em;
                    width:1em;
                }
                .winner {
                    background: greenyellow !important;
                    color:$c0;
                }

                .looser {
                    background:$colorno !important;
                }
            }
            >div:nth-child(1) {
                flex: 0.4;
                background: $checkerwhite;
                color:$c0;
            }
            >div:nth-child(2) {
                flex: 0.4;
                background: $checkerblack;
            }

        }
    }


    .date {
            display: flex;
            align-items: center;
            justify-content: center;
            >div {
                background:$c9;
                color:$c0;
                margin:0.1em 0.3em;
                padding:0.1em 0.3em;
                border-radius: 0.2em;
            }
        }

    .checkerwhite {
        background:$checkerwhite !important;
        color:$c0;
    }

    .checkerblack {
        background:$checkerblack !important;
    }

    .switchButton {
        background:$c7;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0.3em;
        border-radius: 1em;
    }

    .DailyCharts {
        display: flex;
        justify-content: space-around;
        align-items: center;
        >div {
            flex:0.4;
            background-color: white; 
            border-radius: 0.5em;
            margin:1em;
            padding:0.5em;
        }
    }

    @media (orientation:portrait) {
        .date {
            flex-direction: column;
        }

        .matchInfo {
            flex-wrap: wrap;
        }

        .DailyCharts {
            flex-direction: column;
            >div {
                max-width: 90%;
            }
        }
        
    }

</style>
