<template>
    <div class="loadingFrame">
         <div class="loadingLogo">
                <svg width="100%" height="100%" fill-rule="evenodd" stroke-linejoin="round" stroke-width="28.222" preserveAspectRatio="xMidYMid" version="1.2" viewBox="0 0 11289 11289" >
                    <defs class="TextShapeIndex"/>
                    <defs class="EmbeddedBulletChars"/>
                    <g transform="matrix(1.8859 0 0 1.8859 1832.2 -313.11)">
                        <g class="SlideGroup" transform="matrix(.92917 0 0 .89687 -7953.2 -893.54)">
                            <path d="m12895 6004.9c-14 79-64 270-393 281h-300c-58-6-137-40-188-104-71-90-111-143-111-379v-1909c-64 79-165 159-332 175-485 29-909-11-1392 106-673 162-1368 758-1384 2076v40c0 90 0 379-13 594-19 204-130 308-223 366h2135c294 0 458-103 628-300 130 197 286 281 438 300 1369.1 301.2 1135-1246 1135-1246zm-3145 281c13-857 299-1117 731-1215 130-26 300-26 483-26v914s0 327-443 327z" fill="#fff" fill-rule="evenodd"/></g><g class="com.sun.star.drawing.ClosedBezierShape" transform="matrix(.92917 0 0 .89687 -2899.9 -2091.4)" fill-rule="evenodd" stroke-linejoin="round" stroke-width="28.222"><rect class="BoundingBox" x="5102" y="4064" width="1129" height="1129" fill="none"/><path d="m5813 4321 159 160c91 74 181 98 258 101l-610 610c-3-77-24-167-101-258l-160-159c-90-74-180-98-257-101l610-610c0 77 24 165 101 257z" fill="#fff"/>
                    <rect class="BoundingBox" x="3581" y="4088" width="1130" height="1126" fill="none"/>
                    <path d="m4293 4343 159 159c93 74 181 98 258 101l-610 610c0-77-24-167-101-257l-160-159c-92-75-180-99-257-99l610-610c3 74 27 162 101 255z" fill="#fff"/>
                    <rect class="BoundingBox" x="4343" y="3083" width="1126" height="1129" fill="none"/>
                    <path d="m5052 3340 159 160c90 74 180 98 257 101l-610 610c0-77-24-167-98-258l-160-159c-90-74-180-98-257-101l610-610c0 77 24 167 99 257z" fill="#fff"/>
                    </g></g></svg>                
         </div>
         <div>{{text}}</div>
         <div :class="{notreload:!reload}">
            <button class="reload" @click="doReload()">
                <img style="width:1em;" src="@/assets/img/buttons/reload.svg" />
                <div>reload</div>
            </button>
         </div>
    </div>
</template>

<script>
	export default {
		name: 'Loading',
        props: ['text'],
        data: function() {
            return {
                reload:false
            }
        },
        computed: {

        },
        methods: {	
            doReload: function() {
                location.reload();
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                setTimeout(()=>this.reload=true,10000)
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .loadingFrame {
        position:absolute;
        display:flex; 
        width: 100%;           
        flex-direction: column;    
        align-items: center;
        justify-content: center;
        left:0;
        top:0;
        height: 100%;
        background:$g1;
    }

    .loadingLogo {
        height: 7em;
        width:7em;
    }

    .notreload {
        display:none;
    }

    .reload {
        padding:0 0.3em;
        margin:0.5em;

        font-size:2em;
        display: flex;
        align-items: center;
        >div {
            margin:0 0.2em;
        }
        border-radius: 1em;
        box-shadow: 3px 3px 3px black;
    }


</style>
