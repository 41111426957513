      /*************** Time TRAVEL Functions **********************/
export function startTimeTravel() {
        this.uIstate.timeTravel.on = true;
        this.uIstate.timeTravel.position = this.state.G.turnslog.log.length-1;        
        
}

export function setTimeTravel(turnID) {
        //console.log("setTimetravel",turnID);
        this.quitAnalysis();
        let currentTimeTravel = this.uIstate.timeTravel.position;
        /*
        let minTT = 0;
        for (let i in this.state.G.turnslog.log) {
          if (this.state.G.turnslog.log[i].type=="roll_start_starts") {
            minTT = i;
            break;
          }
        }        
        console.log(minTT);
        */
        //console.log(this.state.G.turnslog.log[turnID]);
        if ( turnID < (this.state.G.turnslog.log.length) /* && turnID > minTT*/ ) {
                // Lower Limit Check
                if (this.state.G.turnslog.log[turnID].type=="roll_start_starts") {
                  return;
                }
                // Check if requested ID has moves, else select one with moves in the direction of the displacement 
                let destTurnId = turnID;
                if (!this.state.G.turnslog.log[turnID].moves) {
                  if (this.state.G.turnslog.log[turnID].type=="double_attempted") {
                    if (currentTimeTravel<turnID) {
                      destTurnId-=1;
                    }                        
                    else {
                      destTurnId+=2;
                    }
                      
                  }                      
                  else {
                    if (currentTimeTravel<turnID) {
                      destTurnId+=2;
                    }
                    else {
                      destTurnId-=2;
                    }
                  }
                  
                }
                if ( destTurnId < this.state.G.turnslog.log.length )
                  this.uIstate.timeTravel.position = destTurnId;
                this.uIstate.timeTravel.on = true;
                }
        
            
    }

export function endTimeTravel() {
  this.uIstate.timeTravel.on = false;
  this.uIstate.analyseMove.on = false;
  this.uIstate.timeTravel.position = 0;
}
