import { createRouter, createWebHistory } from 'vue-router'
import Play from '../views/Play.vue'
import NotFound from "../views/NotFound.vue";
import AuthCodeView from '../views/AuthCodeView.vue'
import RemoveUser from '../views/RemoveUser.vue';
import { ogBasicData , metaBasicData } from './meta-config'

let locale= (navigator.language || navigator.userLanguage).split("-")[0];

if (!['es','en','ar','tr','ru','ch'].includes(locale)) {
  locale = 'en';
}

const ignoredPaths = ["/share/"];


const routes = [
  {
    path: '/',
    name: 'Play',
    component: Play,
    meta: {
      title: "Play - Abak Evolution Backgammon.",
      metaTags: [ ...ogBasicData, ...metaBasicData[locale]['play'] ]      
    }

  },
  {
    path: '/auth/vu',
    name: 'Validate Code',
    component: AuthCodeView,
    meta: {
      title: "Validate Code - Abak Evolution Backgammon.",
      metaTags: [ ...ogBasicData, ...metaBasicData[locale]['play'] ]      
    }
  },
  {
    path: '/remove',
    name: 'Remove User',
    component: RemoveUser,
    meta: {
      title: "Validate Code - Abak Evolution Backgammon.",
      metaTags: [ ...ogBasicData, ...metaBasicData[locale]['play'] ]      
    }
  },  
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 - Page Not Found",
      metaTags: [
        // Add any necessary meta tags for the 404 page
      ],
    },
    beforeEnter: (to, from, next) => {
      if (ignoredPaths.includes(to.path)) {
        next(false); // Cancel the current navigation
      } else {
        next(); // Proceed to the catch-all route
      }
    },
  },  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
