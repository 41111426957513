<template>
    <div :style="{ top: cubePosition + '%' , left : cubeLeft + '%' }" >
        <svg xmlns="http://www.w3.org/2000/svg" :width="cubeWidth+'px'" :height="cubeWidth+'px'">
            <rect x="0" y="0" rx="15%" ry="15%" :width="cubeWidth+'px'" :height="cubeWidth+'px'" :fill="vcolors.background" stroke="#333" stroke-width="2" />
            <text x="50%" y="75%" font-family="Arial" :font-size="texSize" text-anchor="middle" :fill="vcolors.color">{{ doubleCube.value == 1 ? 64 : doubleCube.value }}</text>
        </svg>        
    </div>
</template>

<script>
	export default {
		name: 'Cube',
        props: ['doubleCube','PlayerID','direction','colWidth','colors'],
        data: function() {
            return {
            }
        },
        computed: {
            cubePosition: function() {
                if (this.doubleCube.holder===null) return 45;
                if (this.doubleCube.holder == parseInt(this.PlayerID)) return 60;
                return 30;
            },
            cubeLeft: function() {
                if (this.direction == -1) return 93.8;
                else return 0.3;
            },
            cubeWidth: function() {
                return this.colWidth*0.8;
            },
            texSize: function() {
                return this.colWidth*0.6;
            },
            vcolors: function() {
                if (this.colors) return this.colors;
                return {
                    background:"white",
                    color:"black"
                }
            }

        },
        methods: {	

        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    img {
        width:100%;
    }

    div {
        width:6%;
        position:absolute;
    }

</style>
