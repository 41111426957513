export default {
  "FAQ": {
    "preguntas": {
      "1": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لعبة Abak Evolution أكثر <span>تعقيدًا</span> من لعبة الطاولة؟"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجابة الصادقة هي نعم، ولكن في المقابل، تحصل على لعبة أكثر متعة وديناميكية. بإضافة الفئات إلى اللعبة، تمنح هوية لكل جندي وتفكر في ميزاته الخاصة. بالإضافة إلى ذلك، تحتوي لعبة Abak على المزيد من التوليفات لرمي النرد، لأن ترتيب الحركة قد يؤثر على النتيجة."])}
      },
      "2": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما مدى <span>اختلاف</span> لعبة Abak Evolution عن لعبة الطاولة الكلاسيكية؟"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القواعد الأساسية تكاد تكون نفسها، ولكن إضافة الفئات تغير اللعبة بشكل جذري. <ul>     <li><span>بُعد جديد:</span> موضع القطعة العمودي مهم. هذا يخلق بُعدًا جديدًا: المحور Y. فكر في الأمر بهذه الطريقة: في Abak لا يمكنك القول فقط أن نقطة ما تحتوي على 3 قطع بيضاء، بل يجب عليك تحديد كل منها بالترتيب.</li>     <li><span>المزيد من التوليفات:</span> كل موضع في لعبة الطاولة الكلاسيكية يحتوي على (3.346.200)² مواضع ممكنة في لعبة Abak Evolution.</li>     <li><span>القطع لها هوية</span>: يجب أن تأخذ في الاعتبار قدراتها الفردية.</li>     <li><span>ترتيب الحركة مهم:</span> إذا قمت بتحريك قطعة قبل الأخرى، فقد يغير ذلك بشكل كبير نتيجة الرمية.</li> </ul>"])}
      },
      "3": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يمكنني تعلم لعب Abak Evolution؟"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدم الموارد الموجودة على هذا الموقع لتعلم كيفية اللعب، من لعبة الطاولة إلى Abak. واجهة المستخدم للعبة هي مورد جيد لأنها تبرز القواعد."])}
      },
      "4": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أحتاج إلى معرفة كيفية لعب الطاولة لتعلم Abak Evolution؟"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم. تحتاج إلى معرفة أساسية على الأقل بقواعد لعبة الطاولة الكلاسيكية."])}
      },
      "5": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا هناك حد أقصى من 5 قطع لكل نقطة؟"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على الرغم من أن هذه القاعدة موجودة في لعبة الطاولة الكلاسيكية، إلا أنها لا تُستخدم غالبًا. إنها تضيف الكثير من عدم الاستقرار، خاصة عند الخروج. يستخدم Abak هذه القاعدة لأنني أحبها، وأيضًا لسبب عملي: رؤية القطع."])}
      },
      "6": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لعبة Abak Evolution أبطأ أو أطول من لعبة الطاولة؟"])},
        "respuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بمجرد أن تتعرف على الجنود المختلفين، فإن سرعة اللعبة تكون مشابهة إلى حد كبير للعبة الطاولة الكلاسيكية. لأن الجنرال يمكنه التحرك للخلف، أحيانًا تكون اللعبة أطول (من حيث الأدوار) من لعبة الطاولة."])}
      }
    },
    "header": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قد يكون لديك سؤال"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عنه هنا"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])}
    }
  },
  "about": {
    "history": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم أجلس أبداً لمحاولة اختراع ألعاب جديدة، هذا ظهر فجأة. كنت ألعب الطاولة مع صديق، وكنت أشعر بالملل من رتابة القواعد، وفجأة خطرت لي هذه الفكرة. كان ذلك في جزيرة الفصح في سبتمبر 1999."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفعل ذلك مجاناً لأنني أؤمن في قلبي أن هذه اللعبة هي الخليفة للعبة الطاولة كما تعرفها وإذا أصبح ذلك حقيقة خلال حياتي، فإن العواقب ستكون أكثر من كافية كمكافأة. لأنني مهندس برمجيات تمكنت من القيام بكل هذا بنفسي في وقت فراغي، مستمتعاً بالعملية."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عندما كنت أطور تطبيق الويب لمشاركة قواعدي مع العالم، كنت أعتقد أن Abak Evolution ستحقق نجاحاً فورياً بمجرد أن تصبح متاحة على الإنترنت، وأن لاعبي الطاولة سيحتضنون القواعد الجديدة قائلين \"يا إلهي، كيف لم تخطر لي هذه الفكرة!\". المفاجأة: لم يفعلوا. بينما أكتب هذا، مضت أربع سنوات كاملة منذ أن أصبحت Abak متاحة على الإنترنت، وصرفت آلاف الدولارات في حملات تسويق مدفوعة، ومع ذلك لديها في أفضل الأحوال بضع مئات من اللاعبين المنتظمين."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواجه Abak Evolution العديد من الحواجز التي ثبت أنها صعبة التغلب عليها:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المشروع هو عرض ملحمي غير ربحي يقوم به شخص واحد."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسمي سامي غريب، أنا مهندس برمجيات تشيلي."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكراً لوقتك."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرت أكثر من 20 عاماً منذ تلك اللحظة، واستثمرت آلاف الساعات في تطوير: القواعد، الآليات، التطبيق، الموقع الإلكتروني، محتويات التسويق، إلخ."])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه هي الجبهات الحالية لمعركتي. أعمل عليها في وقت فراغي، مفضلاً المحتويات التعليمية، لأنني أعتقد أن هذا هو الحاجز الأثقل على الإطلاق. أقضي وقتاً طويلاً في الذكاء الاصطناعي، لأنني أحبه، حتى وإن كان المحرك يلعب بشكل جيد في الوقت الحالي ولا حاجة إلى لاعب أقوى."])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول Abak Evolution"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حديث قصير."])},
      "g": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر تحدياً بكثير من الطاولة الكلاسيكية وله منحنى تعلم حاد."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاعبي الطاولة ملتزمون جداً ويحبون لعبتهم، يرون Abak كتهديد أو دخيل."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كثير من الناس يحبون لعب الطاولة ضد البشر، وليس الكمبيوتر، وتحتاج إلى كتلة حرجة من اللاعبين لتحقيق ذلك."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لدي موارد كافية لتوسيع الاتصالات وإنشاء المحتويات."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطبيق ليس على أحدث طراز في تجربة الطاولة ويفتقر إلى الكثير من الأدوات التي يرغب فيها لاعبي الطاولة المتقدمين."])}
      },
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
      "thanks-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشكر"])},
      "rant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حديث قصير"])}
    },
    "history1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلمت كيفية لعب الطاولة عندما كنت طفلاً، ولعبت كثيراً مع عائلتي وزملاء المدرسة."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في وقت لاحق من الحياة، علمت الكثير من أصدقائي كيفية اللعب. في النهاية، وصلت إلى مستوى لم أعد أستمتع باللعب دون أن يكون هناك مال متورط."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخترعت Abak في رحلة إلى جزيرة الفصح في أغسطس 1999، بسبب الملل."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كانت النسخة الأولى مختلفة جداً عن هذه، ولم يستمر أي من أصدقائي في لعبها بعد أن علمتهم كيفية اللعب. لذلك، ذهبت إلى زاوية في عقلي لسنوات طويلة."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في يوم من الأيام، في العمل، في عام 2007، علمت بعض الزملاء كيفية لعب الطاولة، وعندما رفضوا الاستمرار في اللعب ضدي بالمال، قادني الملل مرة أخرى إلى تعليمهم اللعبة التي اخترعتها سابقاً."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لكن أولاً أجريت التغييرات لجعلها رائعة (الجريحين وبعض القيود)."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كانت ناجحة جداً بينهم. منذ ذلك الحين، يلعب كل يوم بعض الزملاء (عادةً معي، يجب أن أقول كل شيء) Abak في وقت الغداء في عملي."])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شجعني هؤلاء الزملاء على صنع نسخة برمجية من اللعبة، لكنك تعرف الحياة وأولوياتها."])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لذا، مرت سنوات عديدة حتى سبتمبر 2009. تعرضت لحادث منحني أسبوعين كاملين في المنزل."])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء برنامج مليء بالأخطاء، واستغرق الكثير من الجهد والطاقة، ونمت لفترة سنوات أخرى."])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منذ ذلك الحين، حصلت على دفعات من الحماس دفعتني للعمل عليها، حتى وصلت إلى مستوى يمكنني مشاركتها مع العالم."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أشعر أنني محظوظ جداً لأنني جيد في البرمجة والحواسيب. لو لم أكن كذلك، لما أتيحت لي الفرصة لجعل هذا ممكنًا وإصدار نسخة مجانية لتتعرف عليها."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لذا، ها هي، عرض شخص واحد (باستثناء الرسومات، شكر كبير لـ www.vrweb.cl)"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آمل أن تكون قد استمتعت بها."])}
    },
    "donate": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام"])},
      "hh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات"])},
      "devtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت التطوير"])},
      "onlinesince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح على الإنترنت منذ"])},
      "playedgames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الألعاب التي تم لعبها"])},
      "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين النشطين"])},
      "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرباح"])},
      "donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبرع به حتى الآن"])},
      "otherincomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر دخل أخرى"])},
      "expenses_op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نفقات التشغيل (شهرياً)"])},
      "adv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلانات"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفقات"])},
      "devtimeprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطوير المحتوى وإنشائه"])},
      "estadisticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبرع"])},
      "necesities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالإضافة إلى وقتي، لدي نفقات مباشرة في العديد من المواضيع المختلفة"])},
      "necesity": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطوير التسويق (فيديوهات + رسومات)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائط التسويق (Adwords + Facebook)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مركز البيانات (Amazon AWS)"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطوير واجهة المستخدم (تصميم + ترجمات)"])}
      },
      "closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا أحببت اللعبة ويمكنك المساعدة، فستكون مساعدة كبيرة. شكراً مقدماً."])}
    },
    "samy": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا مطور برمجيات شغوف، ولدت في تشيلي عام 1973. كوني من أصل فلسطيني، نشأت بثقافة عربية قوية، وكانت الطاولة (أو Backgammon) أحد عناصرها."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمل في شركتي الخاصة منذ عام 1999، VRWEB، وعلى الرغم من أن دوري الرئيسي هو في التسويق والمبيعات، إلا أنني أحب البرمجة. Abak هي ساحة اللعب الخاصة بي."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا والد لثلاثة أطفال، وطباخ لأربعة."])}
    },
    "thanksto": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على مر السنين، تلقيت الكثير من المساعدة من الناس في جميع أنحاء العالم: ترجمات، نصائح تقنية، نصائح حول اللعب، فنون المعجبين، إلخ."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في فئة خاصة هم أولئك الذين، على الرغم من أنهم لا يساعدون، قد تفاعلوا معي وأظهروا حماسًا كبيرًا لدرجة أنهم كانوا محفزًا لأهدافي:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وأخيراً وليس آخراً، هناك مجموعة من الأشخاص الذين عملوا في مشاريع مفتوحة المصدر مذهلة تشكل أساس منصة Abak."])}
    }
  },
  "ayuda": {
    "generales": {
      "delsoldado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قواعد الفئة"])},
      "situaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواقف"])},
      "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر على الجندي للحصول على المساعدة"])},
      "highest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك تحريك أعلى قطعة لكل نقطة أولاً."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك تحريك قطعة إذا كانت هناك قطعة أخرى فوقها."])}
      },
      "5max": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك حد أقصى من 5 قطع لكل نقطة."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في مراكز اللعب، يمكنك فقط تكديس خمس قطع لكل نقطة."])}
      }
    },
    "piedras": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجندي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثل لعبة الطاولة الكلاسيكية"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجندي هو قطعة عادية في لعبة الطاولة الكلاسيكية. لكل فريق 9 قطع."])}
      },
      "2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنرال"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتحرك للأمام والخلف"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للجنرال التحرك للأمام والخلف مع بعض القيود. يمكنه التحرك في اتجاه واحد فقط في كل دور، ويمكنه العودة للخلف مرة واحدة فقط."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تحرك للأمام، لا يمكنه العودة للخلف في نفس الدور"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تحرك للخلف، لا يمكنه التحرك مرة أخرى في الدور."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنه التحرك للخلف إذا كانت جميع الجنود الأخرى في منزل الفريق."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد التحرك من الشريط، يمكنه التحرك للخلف."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تحرك للأمام"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تحرك للخلف"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنه التحرك للأمام"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنه التحرك للخلف"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنه التحرك للأمام"])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحارس"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقوى من البقية"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحراس مثل جنود مزدوجة، لكن يمكن أن يتم اصطيادهم بواسطة الكاهن وتعرضهم لضربات من حراس أو جنود آخرين إذا كان بإمكان كلاهما الضرب. لكل فريق 2."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحصن مثل جنود مزدوجة"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن اصطياده بواسطة الكاهن"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن ضربه بواسطة حراس آخرين"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن لجنديين ضربه"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لضرب حارس بجنديين (غير الحراس)، يجب أن تكون قادرًا على ضربه بكلاهما في حركات متتالية خلال دور."])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يصطاد ويحتجز"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للكاهن اصطياد عدو إذا كان وحده في منتصف الميدان. الجندي المأسور لا يمكنه التحرك بينما يكون الكاهن فوقه."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن قد اصطاد جندي"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن في أمان"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن مصطاد بواسطة الكاهن"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن سيتم ضربه"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخل أي منزل، يتصرف الكاهن كجندي عادي."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن يمكنه اصطياد جندي واحد"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن لا يمكنه اصطياد عمود مسدود"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتصف الميدان"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن يصطاد"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيت الأسود"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيت الأبيض"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن يضرب"])}
      },
      "5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصاب بالزوجي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يلعب فقط الأرقام الزوجية"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصاب بالزوجي يمكنه لعب الأرقام الزوجية فقط"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الخروج من الشريط، يمكنه لعب أي رقم."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الخروج، يمكنه لعب أي رقم."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصاب بالزوجي يمكنه التحرك فقط إذا كانت المسافة المقطوعة زوجية، أو أي رقم إذا كان يترك الشريط أو يخرج."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أثناء التحرك بين النقاط (المثلثات)، ستكون كلاهما دائمًا من نفس اللون. لذلك الرمز."])}
      },
      "6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصاب بالفردي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يلعب فقط الأرقام الفردية"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصاب بالفردي يمكنه لعب الأرقام الفردية فقط"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الخروج من الشريط، يمكنه لعب أي رقم."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الخروج، يمكنه لعب أي رقم."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصاب بالفردي يمكنه التحرك فقط إذا كانت المسافة المقطوعة فردية، أو أي رقم إذا كان يترك الشريط أو يخرج."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أثناء التحرك بين النقاط (المثلثات)، ستكون كلاهما دائمًا من ألوان مختلفة. لذلك الرمز."])}
      },
      "56": {
        "leavingorcaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الخروج من الشريط، أو الخروج، يمكنه التحرك بأي رقم."])}
      }
    }
  },
  "contact": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بـ Abak Evolution"])},
    "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت بحاجة إلى الاتصال بي، استخدم إحدى هذه البدائل"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك العثور على Abak Evolution في مجموعة متنوعة من شبكات التواصل الاجتماعي، والكتابة لي هناك."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو أرسل بريدًا إلكترونيًا"])}
  },
  "hall": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاعة الشهرة"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاعة الشهرة"])}
  },
  "intro": {
    "content": {
      "tituloheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution Backgammon"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> هو إصدار من لعبة الطاولة يضيف <span>فئات جديدة من القطع</span> إلى اللعبة، كل منها بميزات خاصة وسلوك <span>فريد</span>."])},
      "isaboardgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> هي لعبة لوحية تكتيكية مجردة ثنائية اللاعبين. ستحتاج إلى الحظ وأفضل تكتيكاتك إذا كنت تريد الفوز في هذا الإصدار الجديد من لعبة <span>الطاولة</span> الكلاسيكية."])},
      "backgammon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاولة<span>²</span>"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> تستخدم نفس قواعد الطاولة، ولكن تضيف 5 فئات من الجنود. هذا التغيير البسيط يؤدي إلى نتائج رائعة."])},
      "gotoabakme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التعلم واللعب عبر الإنترنت هنا."])},
      "abakapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستجد تطبيق ويب متكامل حيث يمكنك اللعب ضد خصم كمبيوتر قوي أو أشخاص حقيقيين."])},
      "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألق نظرة على حساباتنا على وسائل التواصل الاجتماعي"])},
      "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العب"])},
      "printrules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك العثور على قوالب ملصقات للوحة اللعب، ونسخة للطباعة من القواعد، وأدوات أخرى هنا:"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأدوات"])},
      "nonprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Abak Evolution</span> هي جهد غير ربحي ملحمي من شخص واحد لنشر هذه القواعد المدهشة للبشرية. <br> اخترعها، وطورها، وصيانتها، وموّلها <span>سامي غريب</span>، مطور برمجيات تشيلي."])},
      "sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دع"])},
      "entonces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثم"])},
      "classbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار من الطاولة يعتمد على الفئات"])},
      "quees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار من الطاولة يعتمد على الفئات"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الفئات يغير اللعبة بشكل جذري. يهم الموقف الرأسي للقطع وبالتالي ترتيب الحركات. <span>Abak Evolution</span> تضيف بُعدًا جديدًا إلى <span>الطاولة الكلاسيكية</span>"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم"])},
      "moredetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد من التفاصيل"])},
      "timagen": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طاولة ذات تمييز طبقي، مثلك تمامًا"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طاولة معززة"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(الطاولة)²"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاولة، للكبار."])}
      },
      "abakisto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak هي بالنسبة للطاولة كما الشطرنج بالنسبة للداما"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القواعد"])}
    },
    "privacy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسات الخصوصية"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع المعلومات، استخدامها ومشاركتها"])},
      "content": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هي منصة عبر الإنترنت للعب قواعد Abak، وهي توسعة لقواعد الطاولة."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هي جهد فردي غير مهني وغير ربحي لنشر هذه القواعد."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنصة مجانية، مثل جميع موارد Abak Evolution الأخرى."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خالية من الإعلانات، مع إمكانية التسجيل الاختياري."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تقوم بأي نشاط خارجي ببيانات المستخدمين. تُستخدم بيانات المستخدم فقط لتخزين حالة المستخدم والترتيب."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تشارك معلومات المستخدمين مع أطراف أخرى."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعتمد فقط على منصات تسجيل الدخول الخارجية مثل فيسبوك، جوجل، وتويتر، باستخدام خدمة Firebase Auth من جوجل كموصل."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لن تنشر أي معلومات على حساباتك الاجتماعية، ولا ستقرأ معلومات جهات الاتصال الخاصة بك."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطبيقات على المنصات الاجتماعية ستجمع فقط صورتك الشخصية واسمك."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستخدم الكوكيز لتخزين بيانات حسابك. يستخدم موفرو تسجيل الدخول الخارجيون كوكيزهم الخاصة."])}
      }
    }
  },
  "learn": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مركز التعلم"])},
      "frase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موارد لفهم وتعلم Abak Evolution"])},
      "contenido1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة من الموارد التي ستتيح لك لعب Abak Evolution"])},
      "videoT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قناة يوتيوب"])},
      "videoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألق نظرة على قناة يوتيوب، مع فيديو تمهيدي وبعض الدروس القصيرة."])},
      "versusT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak ضد BackGammon"])},
      "versusC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة على مدى اختلاف Abak عن لعبة الطاولة الكلاسيكية"])},
      "PDFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قواعد PDF"])},
      "PDFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنزيل مجموعة ملفات جاهزة للطباعة لاحتياجاتك دون اتصال"])},
      "reglasT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتاب القواعد"])},
      "reglasC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القواعد الكاملة لتطور Abak في صفحة واحدة. أداة مرجعية للطباعة أو التحقق على هاتفك"])},
      "tablaT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعبتك"])},
      "tablaC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة من الملصقات للوحك الفعلي"])},
      "specs": {
        "specs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواصفات"])},
        "typeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
        "typeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجردة<br>استراتيجية"])},
        "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنوات"])},
        "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللاعبين"])},
        "complexity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعقيد"])},
        "mechanics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الميكانيكا"])},
        "turnbased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على أساس الأدوار"])},
        "dicedriven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعتمد على النرد"])},
        "perfectinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات كاملة"])},
        "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صعب"])}
      }
    },
    "faq": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قد يكون لديك سؤال"])},
      "titulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عنه هنا"])},
      "titulo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])}
    },
    "files": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة متنوعة من الملفات المفيدة"])}
    },
    "blog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدونة Abak Evolution"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة بالمقالات حول مواضيع مختلفة تتعلق بـ Abak، معظمها لأغراض تحسين محركات البحث. ولكن مهلاً، إذا كان لديك وقت."])}
    },
    "introvideo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد فيديو المقدمة"])}
    }
  },
  "lobby": {
    "subject": {
      "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصم"])},
      "human": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنسان"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخادم"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهدف"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القواعد"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاتجاه"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج التعليمي"])},
      "tutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرامج التعليمية"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليسار"])},
      "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصدقاء"])},
      "players": {
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متصل"])},
        "invisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاعبين غير مرئيين"])},
        "last_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدث الأخير"])},
        "prefered_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنواع المفضلة"])},
        "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متصل"])},
        "playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يلعب"])},
        "atlobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الردهة"])}
      },
      "friendship": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الانتظار"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرفوض"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلق"])},
        "friendship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصداقة"])},
        "notyet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك أصدقاء حتى الآن. يمكنك طلب الصداقة من اللاعبين المتصلين، من قسم اللاعبين المتصلين."])},
        "requestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال طلب صداقة؟"])},
        "newrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك طلب صداقة جديد"])},
        "onlinefriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صديق متصل"])}
      },
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مباراة"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
      "game_visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زوار اللعبة"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزوار"])}
    },
    "alerts": {
      "nogamestojoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد ألعاب للانضمام."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطور لعبة الطاولة أبك"])},
    "action": {
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العب"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
      "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم"])},
      "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد"])},
      "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بي"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تدريب"])},
      "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لعبت"])},
      "won": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاز"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمر"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك"])},
      "sharein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك في"])},
      "cpypasteshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ وشارك هذا الرابط"])},
      "shareabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك لعبة أبك مع أصدقائك!"])},
      "shareboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك لعبتك"])},
      "howtoshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستنشئ هذه الأداة عنصراً للمشاركة. يمكنك إضافة وصف اختياري"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاينة"])},
      "contactsocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بي على الشبكة الاجتماعية المفضلة لديك"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دردشة"])}
    },
    "user": {
      "subject": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم"])},
        "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحصائيات"])},
        "medals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الميداليات"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
        "howemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الحفظ، ستتلقى رسالة بها رابط التحقق"])},
        "checkvalidationemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك التحقق من بريدك الإلكتروني. يمكنك محاولة إرسال الرسالة مرة أخرى."])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يومي"])}
      },
      "action": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال مرة أخرى"])}
      },
      "message": {
        "knownguestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت مسجل لهذا الجهاز فقط. استخدم <b>تسجيل الدخول الاجتماعي</b> لإنشاء مستخدم دائم ومتعدد الأجهزة."])},
        "guestwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المستخدم مرتبط بهذا الجهاز فقط. إذا كنت ترغب في استعادة هذا المستخدم لاحقًا، أو متابعة ألعابك على أجهزة مختلفة، قدم بريدًا إلكترونيًا صالحًا."])}
      }
    },
    "messages": {
      "newinvitation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة للعب"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا قبلت، ستستأنف هذه المباراة بعد انتهاء المباراة الأخرى."])},
        "headding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك دعوة للانضمام إلى لعبة ضد لاعب"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إصدار هذه الدعوة لجميع اللاعبين النشطين. ردك مجهول."])},
        "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إصدار هذه الدعوة مباشرة إليك."])}
      },
      "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مباريات للانضمام."])},
      "p2pnomatchesoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خياراتك"])},
      "playandwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العب ضد الخادم وانتظر دعوة"])},
      "inviteonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال دعوات للاعبين المتصلين الذين يلعبون ضد الخادم"])},
      "createhuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء لعبة، وانتظر انضمام لاعب"])},
      "error": {
        "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك خطأ ما، لم أتمكن من الاتصال بالخادم."])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الإبلاغ عن هذا الحادث إلى"])}
      },
      "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اللغة"])},
      "unfinishedgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك لعبة جارية. أكملها، أو استسلم."])},
      "wontunlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المستوى من الصعوبة لا يكفي لفتح التمرين التالي."])},
      "unlocklevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك فتح مستوى التمرين هذا."])},
      "inviteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة $$(player)$$ للعب."])},
      "nomoreplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد المزيد من اللاعبين المتاحين للدعوة."])}
    },
    "hallfame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاعة المشاهير"])}
    },
    "chat": {
      "message": {
        "tooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة قصيرة جدًا"])}
      }
    },
    "message": {
      "choosepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى التمرين"])},
      "newpracticelevelavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى تمرين جديد متاح. اختره من صندوق الاختيار."])},
      "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري العمل. يرجى الانتظار"])},
      "becomeabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك لعب <b>أبك الطاولة</b> و<b>الطاولة المصرية</b> في هذا التطبيق."])},
      "becomeabakerhowto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ل<b>فتح</b> جميع الميزات يجب أن تصبح <b>أباكر</b>. أكمل <b>مستويات التمرين</b>، أو فز بمباراة أبك واحدة ضد مستويات متقدمة أو خبراء."])},
      "welcome": {
        "customize1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخصيص المستخدم"])},
        "customize2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير اسم المستخدم والصورة الرمزية الخاصة بك."])},
        "stats1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحصائيات"])},
        "stats2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من تاريخك كلاعب"])},
        "hall2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعرف على أفضل أباكر"])},
        "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألقي نظرة على هذه الأدوات في وقت ما."])}
      },
      "caninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك دعوة اللاعبين المتاحين"])},
      "cantinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك دعوة لاعبين آخرين حتى تصبح أباكر"])},
      "noonlineplayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد لاعبون متصلون"])},
      "onlyabakersorfriendscaninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك إنشاء دعوات للاعبين الذين ليسوا أصدقائك إذا لم تكن أباكر."])},
      "friendship": {
        "requestsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الطلب"])},
        "requestexists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب موجود بالفعل"])},
        "responsesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الرد"])}
      },
      "emptylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة فارغة"])}
    }
  },
  "meta": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول تطور لعبة الطاولة أبك"])},
      "history": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولدت لعبة أبك في عام 1999، بعد سلسلة فريدة من الأحداث والظروف."])}
      },
      "rant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف لم يكن تطور لعبة الطاولة أبك كما توقعت"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تكن لعبة أبك النجاح الذي كنت أعتقد أنه سيكون، لكنني لن أتخلى عن هذا المشروع أبدًا."])}
      },
      "samy-garib": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سامي غريب - تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سامي غريب هو مطور برمجيات تشيلي ومخترع ومصمم ومطور لعبة تطور لعبة الطاولة أبك."])}
      },
      "donate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبرع للحفاظ على تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطور لعبة الطاولة أبك هو مشروع فردي، مجاني، بدون إعلانات، ويحتاج إلى مساعدة."])}
      },
      "thanks-to": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطور لعبة الطاولة أبك يشكر بعض الأشخاص."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد كان العديد من الأشخاص جزءًا من هذا المشروع. من المساعدة في الترجمة إلى كمية مذهلة من الحماس. كل شيء جيد."])}
      }
    },
    "boardgame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لعبة الطاولة تطور أبك"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بتطور لعبة الطاولة أبك"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبك موجود على العديد من مواقع التواصل الاجتماعي. تحقق منها هنا وابحث عن طرق للتواصل معي."])}
    },
    "learn": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم تطور لعبة الطاولة أبك"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موارد لتعلم لعبة الطاولة وأبك. ابحث هنا عن كتب قواعد PDF، برنامج تعليمي تفاعلي، والكثير غيرها."])}
    },
    "print": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة قواعد تطور لعبة الطاولة أبك"])}
    },
    "resources": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موارد لتعلم تطور أبك"])}
    },
    "rules": {
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجندي - فئة في تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتصرف كجندي عادي في لعبة الطاولة."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنرال - فئة في تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجندي الوحيد الذي يمكنه العودة في اللعبة. يحكم القتال."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحراس - فئة في تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل فريق لديه حارسان. إنهم أقوى من الجنود الآخرين ولا يمكن أسرهم بسهولة."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن - فئة في تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن يحاصر خصمه، مما يجعله غير قادر على الحركة بدلاً من أسره."])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجريح الزوجي - فئة في تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجريح الزوجي يمكنه التحرك بأرقام زوجية فقط."])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجريح الفردي - فئة في تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجريح الفردي يمكنه التحرك بأرقام فردية فقط."])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قواعد تطور لعبة الطاولة أبك"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتضمن لعبة أبك 6 فئات من الجنود: الجندي، الجنرال، الكاهن، الحراس، والجريحين الذين يمثلون فئتين."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قواعد - تطور لعبة الطاولة أبك"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة - تطور لعبة الطاولة أبك"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الأسئلة الشائعة تتعلق بالفروق بين لعبة أبك ولعبة الطاولة"])}
    },
    "abak": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطور لعبة الطاولة أبك. نسخة معدلة."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطور أبك هي لعبة استراتيجية تجريدية ثنائية اللاعبين. نسخة معدلة من لعبة الطاولة تضيف فئات من المشيرين إلى اللعبة الكلاسيكية."])}
    },
    "tutorial": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل لعبة الطاولة وتطور أبك"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا دليل تفاعلي للعبة الطاولة. ستتعلم فيه عن اللوح، الحركات، الشريط وكيفية التحمل. ستجد أيضًا دروسًا متقدمة مع الألغاز."])},
      "backgammon": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل لعبة الطاولة"])}
      },
      "abak": {
        "sufix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل تطور أبك"])}
      }
    }
  },
  "pdf": {
    "rules": {
      "generales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه القواعد هي تغيير طفيف في ميكانيكا اللعبة. لاحظ أن أحدها ضمني إلى حد ما ولكن لم يكن يجب تطبيقه على لعبة الطاولة الكلاسيكية، والآخر موجود بالفعل في بعض نسخ اللعبة."])},
      "intro0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>تطور لعبة الطاولة أبك</b> هو نسخة من لعبة الطاولة تضيف 5 فئات من المشيرين، كل منها بخصائصها الفريدة وسلوكها المميز."])},
      "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المستند يشرح قواعد <b>تطور لعبة الطاولة أبك</b>. يتطلب معرفة مسبقة بلعبة الطاولة الكلاسيكية."])},
      "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك تغييرات صغيرة على القواعد الأصلية، التي تصبح طبيعية وواضحة بمجرد إضافة الفئات."])},
      "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>تطور لعبة الطاولة أبك</b> يضيف 5 فئات من الجنود، بالإضافة إلى الجندي العادي. كل من هذه الفئات لديها خصائص مختلفة."])},
      "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك لعب تطور لعبة الطاولة أبك في"])},
      "intro5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو وضع بعض الملصقات على لوحتك التي يمكنك العثور عليها هنا:"])},
      "intro10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه القواعد متاحة في"])},
      "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل فئة من الجنود لديها مجموعة من الخصائص التي تعدل سلوكها."])},
      "start0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضع البداية"])},
      "start1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضع البداية مشابه جداً للعبة الطاولة، ولكن بدلاً من وضع قطعتين في الخلف، تبدأ بـ 4:\n<ul> <li>يبدأ الجرحى (⨯2) في الخلف <span class='etiqueta-posicion'>[p.24-1]</span> <span class='etiqueta-posicion'>[p.23-1]</span>، وكل واحد منها موضوع للوصول إلى النقطة 18 بأكبر عدد من وجوه النرد. </li>\n<li>يبدأ الكاهن معهم، بأقصى قدر ممكن في الخلف <span class='etiqueta-posicion'>[p.24-2]</span>. نطاق عمله هو الوسط، لذا من الأفضل الانتظار في الخلف.</li>\n<li>الحراس (⨯2) قريبون من المنزل <span class='etiqueta-posicion'>[p.13-3]</span> <span class='etiqueta-posicion'>[p.8-2]</span>، مستعدين لتشكيل الهياكل لكن ليسوا مستعدين للتحرك، كلاهما تحت جندي في البداية.</li>\n<li>يبدأ الجنرال في المنزل <span class='etiqueta-posicion'>[p.6-4]</span>، للمساعدة في تشكيل الهياكل في كلا الاتجاهين.</li>\n<li>الباقي، الجنود العاديين، يبدأون في: <span class='etiqueta-posicion'>[p.23-2]</span><span class='etiqueta-posicion'>[p.13-1]</span><span class='etiqueta-posicion'>[p.13-2]</span><span class='etiqueta-posicion'>[p.13-4]</span><span class='etiqueta-posicion'>[p.8-1]</span><span class='etiqueta-posicion'>[p.8-3]</span><span class='etiqueta-posicion'>[p.6-1]</span><span class='etiqueta-posicion'>[p.6-2]</span><span class='etiqueta-posicion'>[p.6-3]</span> </li>\n</ul>\n"])},
      "bidimensional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>تطور لعبة الطاولة أبك</b> يحول لعبة الطاولة إلى لعبة <bثنائية الأبعاد</b>. هذا يعني أن الموضع العمودي للحجر في العمود مهم، وبالتالي فإن <b>ترتيب الحركات</b> في الدور هو عامل جديد في اللعبة."])},
      "imagina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخيل الاحتمالات"])},
      "classic_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الموضع لفريقك في لعبة الطاولة الكلاسيكية"])},
      "millions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعادل ملايين من التركيبات في تطور لعبة الطاولة أبك. مجرد عينة صغيرة:"])},
      "moves_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب الحركات"])},
      "drastically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن أن يغير ترتيب الحركات بشكل كبير نتيجة اللعب."])},
      "druid_traps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذا السيناريو، الكاهن يحاصر الجندي."])},
      "odd_captures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذا السيناريو، الجريح الفردي يأسر الجندي"])},
      "encuentra_abak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية اللعب"])},
      "redes_sociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن <b>تطور لعبة الطاولة أبك على الشبكات الاجتماعية</b>"])},
      "download_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنزيل التطبيق للعب على هاتفك. ابحث عنه في متجر التطبيقات الخاص بهاتفك."])},
      "play_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افتح متصفح الويب الخاص بك وانتقل إلى هنا:"])},
      "capturar_guardia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لضرب الحارس باستخدام جنديين (غير الحارس)، يجب أن تكون قادرًا على ضربه بـ <b>كلاهما</b> في <b>حركات متتالية</b> خلال الدور."])},
      "abak_vs_backgammon_titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبك مقابل لعبة الطاولة الكلاسيكية"])},
      "abak_vs_backgammon_cuerpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بينما الفرق الوحيد بين تطور لعبة الطاولة أبك ولعبة الطاولة الكلاسيكية هو الفئات المضافة، فإن هذا ينتج عنه تغيير كبير في اللعبة وله نتيجتان رئيسيتان."])},
      "abak_vs_backgammon_combinaciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطور لعبة الطاولة أبك لديه"])},
      "abak_vs_backgammon_combinaciones2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواقف ممكنة لأي موقف في لعبة الطاولة الكلاسيكية"])},
      "subject": {
        "soldados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنود"])}
      }
    }
  },
  "piepagina": {
    "texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>تطور لعبة الطاولة أبك</span> هو جهد ملحمي، غير ربحي، لشخص واحد لنشر هذه القواعد المذهلة للبشرية. <br> اخترعها، طورها، صيانتها، ومولها <span>سامي غريب</span>، مطور برمجيات تشيلي."])},
    "privacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسات الخصوصية"])}
  },
  "reglas": {
    "generales": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القواعد العامة"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فئات من المشيرين لها نتائج"])},
      "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن لكل نقطة أن تحتوي فقط على 5 مشيرين."])},
      "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحريك أعلى مشير في كل نقطة أولاً."])},
      "porque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم إضافة قاعدتين عامتين بسيطتين إلى قواعد لعبة الطاولة."])},
      "startpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضع البداية في تطور لعبة الطاولة أبك"])},
      "cambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الفئات يغير اللعبة بشكل جذري. موضع المشير العمودي مهم وبالتالي ترتيب الحركات. <span>تطور لعبة الطاولة أبك</span> يضيف بعداً جديداً إلى <span>لعبة الطاولة الكلاسيكية</span>"])},
      "mismasreglas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>تطور لعبة الطاولة أبك</span> يستخدم نفس قواعد لعبة الطاولة، لكنه يضيف 5 فئات من الجنود. هذا التغيير الصغير ينتج عنه نتائج مذهلة ويتطلب قاعدتين جديدتين واضحتين وطبيعيتين."])},
      "thisguys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نفس قواعد لعبة الطاولة، مع هؤلاء الفئات."])},
      "addsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يضيف فئات من الجنود، كل منها بخصائص مختلفة."])},
      "startposcontenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضع البداية في <span>تطور لعبة الطاولة أبك</span> مشابه جداً للعبة الطاولة، لكنه يضيف <span>الفئات</span> ويحتوي على 4 مشيرين في الخلف. بأسلوب ناكجمون."])},
      "ttd_titulo": null,
      "ttd_texto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخيل فقط الاحتمالات"])}
    }
  },
  "tips": {
    "general-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنرال محاصر."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنرال مهم جداً ولا يمكنه التحرك."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لأنه يمكنه التحرك إلى الخلف، فإنه مفيد جداً لمساعدة الجرحى على العودة إلى المنزل، وله دور رئيسي في زيادة فرصك في الدفاع وبناء منزلك. حاول أن تبقيه في الأعلى وتجعله متاحاً."])}
    },
    "druid-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن محاصر."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن يمكن أن يكون مؤثراً في اللعبة."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا قام بشل حركة الخصم، فسيمنحك الكثير من السيطرة على اللعبة. حاول أن تبقيه في الأعلى وتجعله متاحاً."])}
    },
    "tooclose": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن قريب جداً من منزلك."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاهن هو سلاح فتاك، ولكن..."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوته الكبيرة في الوسط. حاول ألا تقترب كثيراً من منزلك، لأنه هناك، يفقد قوته ويتصرف كجندي عادي."])}
    },
    "inmobilized-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك جندي مشلول الحركة."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنديك تحت كاهن خصمك، لكنه غير محمي."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول ضربه قبل أن يضع خصمك جندياً آخر فوقه."])}
    },
    "inmobilized-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك جندي مشلول الحركة."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنديك تحت كاهن خصمك، ولا يمكنك ضربه."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول الإمساك به أيضاً، واحتواء لعبتك بقدر الإمكان."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدم الجنرال لإنفاق النرد بالتحرك للخلف."])}
    },
    "inmobilized-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك فوضى."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد شللت حركة كاهنه، لكنه أيضاً شل حركتك."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطط لهذا. أنت في وضع متميز."])}
    },
    "inmobilizing-single": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد شللت حركة جندي خصمك."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنديه تحت كاهنك، لكن كاهنك غير محمي."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول وضع جندي آخر فوقه لحماية موقعك."])}
    },
    "inmobilizing-block": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد شللت حركة جندي خصمك."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد شللت حركة جندي خصمك، وكاهنك محمي. عمل جيد!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التحرك والضرب في نفس الدور. احصل على الجنود في متناول اليد للحظة المناسبة."])}
    },
    "inmobilizing-mess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك فوضى."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد شللت حركة كاهنه، لكنه أيضاً شل حركتك."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطط لهذا. أنت في وضع غير متميز."])}
    },
    "even-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجرحى الزوجي محاصر."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجرحى الزوجي الخاص بك محاصر ولا يمكنه التحرك."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحتاج إلى إيجاد طريقة لجعل خصمك يفرج عن موقعه."])}
    },
    "even-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجرحى الزوجي يكاد يكون محاصراً."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قد يكون جرحاك الزوجي محاصراً قريباً."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كن حذراً وحاول الهروب في أقرب وقت ممكن."])}
    },
    "odd-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجرحى الفردي محاصر."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجرحى الفردي الخاص بك محاصر ولا يمكنه التحرك."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحتاج إلى إيجاد طريقة لجعل خصمك يفرج عن موقعه."])}
    },
    "odd-quite-trapped": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجرحى الفردي يكاد يكون محاصراً."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قد يكون جرحاك الفردي محاصراً قريباً."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كن حذراً وحاول الهروب في أقرب وقت ممكن."])}
    },
    "guard-not-on-floor": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحارس الخاص بك غير مستغل بشكل كامل."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للحراس الاحتفاظ بالنقطة وحدهم. لاحتجازهم، يجب أن يضربهم جنديان من الخصم في دور واحد، وهو حدث ذو احتمال منخفض جداً."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من الأفضل أن يكونوا على مستوى الأرض حتى تتمكن من استغلال قوتهم بالكامل."])}
    }
  },
  "tutorials": {
    "intro": {
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this set of tutorials you can learn Classic Backgammon rules and Abak Evolution rules. What would you like to learn?"])},
      "lback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Backgammon.          \n"])},
      "lback2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't know how to play Backgammon. I wan't to learn from scratch. "])},
      "labak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn Abak."])},
      "labak2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know how to play Backgammon, I want to learn how to play Abak. "])},
      "whyask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is just to set a filter you can change later."])}
    },
    "options": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncompleted"])}
    },
    "level": {
      "1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring your soldier to safety."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to ['move'] your soldier. "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one soldier at the end of the board and no enemies. Roll the dice and move your soldier until it gets home, and then remove it from the board to save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run home"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon's objective is to bring all of your checkers (['soldiers']) home. You do that by rolling two dice [13,15] and moving a soldier the number of spaces each die tells you."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear Off"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once all your soldiers are home, you can ['remove'] them (['bear off']) from the board. The first player that ['bears off'] all of its soldiers from the board wins."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dice Roll"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each dice roll will give you two movements if the dice are of different value and four if they are equals."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the roll button to, obviously, roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now move the soldier twice, one for each die, by clicking on it and selecting the landing column."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have played all your valid moves. Confirm you have finished with the button."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent!"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed your turn. Keep it going until your soldier is safe (bore off)."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent is not playing, so play again. Roll."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you have a double that gives you four [!4] moves of [d13] spaces each. Move them all."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done. Confirm your move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your soldier is now at home."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When all your soldiers are at home, you can remove them from the board (beat them off). Roll your dice."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Roll."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can choose the ['outside'] of the board [!0] to move your soldier."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, you have finished the game."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Finished"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That’s it. You have removed 'all' of your soldiers and finished the game. "])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about blocked positions in the next chapter."])},
        "11b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! do it again. Move with the other dice."])}
      },
      "3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's home"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump across the opponent's soldiers to ['escape'] his ['home'] and ['capture'] his soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two soldiers ['[s11]×2'] at the end of the board. Avoid the adversary ['[s12]×5']  and bring them home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked Positions"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A soldier can land at any position the opponent does not block. A position is blocked when the opponent has two or more soldiers at it."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT the addition"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" You can move different soldiers with each die or the same soldiers with both die, but remember those are two movements, and each has to have an open position to land."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on the soldier in position [23], you will see it can't move any die. So it can't move the addition of both dice. You must move the other soldier."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made all your moves. Now confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blots"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a soldier is alone in a position (['a blot']), it can be captured by an opponent soldier who lands over it. "])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the soldier landing with one soldier over it."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have sent your opponent’s soldier to the bar!."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Captured."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has a soldier on the bar, it has to return to the board for the team to keep playing. If it can't escape from the bar, no other soldier can move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to escape from the bar in the next chapter."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't Capture the soldier!"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to capture the lonely soldier. Try again!"])}
      },
      "4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board description."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this quick tour on the Backgammon Board."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Board"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon Board"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has [!24] ['positions'], called ['points'], divided in [!4] quadrants."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Positions"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus the Bar [#B0] [#B1], and a place outside the board [0] [25], for rescued soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player's Home Board is in the ['bottom-right'] quadrant. ['Points [1] to [6]']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Board"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponet's Home Board is on the ['top-right'] quadrant. ['Points [24] to [19]']"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is no man's land. ['Points [7] to [18]']"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['bar'] is the position where ['captured'] soldiers are kept. [#B0][#B1]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Board"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['out-board'] ['[!0]-[!25]'] is the position you put your ['beared off'] soldiers."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inverted Board"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that the board could be inverted on the X axis, homes being on the left side. You can always can change the board direction in the menu."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each player has [15] soldiers."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yours: [s11] , Opponent: [s12]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opposite Direction "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players move in opposite directions. Player 1 ([s11]) moves from [24] to [1], towards his home."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent’s Direction"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player 2 ([s12]) moves from [1] to [24]."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll and Move"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game is played in turns, each player roll two dice [d11][d12] and moves his soldiers."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring them home."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objective of the game is to bring your soldiers home ['Points [1] to [6]'], and once all of them are home, bear them off [0]."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how to move soldiers next."])}
      },
      "b2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the opponent's the Bar!"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been [captured]. Learn how to escape from the [Bar]."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent has captured two soldiers and sent them to the bar. Release them."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bar is the column in the middle of the board ([#B0] and [#B1]). It holds soldiers that have been ['captured']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two ['captured'] soldiers in this board [s11][s11]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldiers in the Bar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a player has soldiers in the opponent’s bar, he ['must'] release them before he can make any other move."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll to Escape"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You escape from the bar entering the adversary's home from its farest place, as shown with the dice."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to escape."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this case, positions [19][dr6] and [20][dr5] are blocked, and the rest are open. Position [!22][d13] has a blot."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll The Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can release only one soldier now, at position [!21][d14]. Position [20][d15] is blocked."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because you left a soldier in the bar [B1], you can't use the other die to move. You will have to wait. Confirm your move."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again the dice"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can land at position [!22][d13], and capture your opponent. Once all your soldiers are off the bar, you can move [d16] with any soldier."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awesome. Now confirm."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free to move!."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Now your soldier can keep running free. You are all set."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the next chapter, you will learn how to bear off."])}
      },
      "b3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off all your soldiers"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn the rules of ['bearing off'] your ['soldiers']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of your soldiers are inside your home. Now you can ['remove '] them from the board in a process called ['bearing off ']."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point [!0]"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To bear off your soldiers, you move then outside the board, to ['point [!0]']"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land exactly in [!0]"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Soldier has to move the ['exact'] number of ['spaces'] to land there."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move from higher points."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is no soldier at the point were the movement should begin, and there are soldiers in a ['higher point'], you must move those soldiers."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear off from the highest point"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are no soldiers in a ['higher point'], you can and must move the soldier in the highest point, to point [!0], bearing it off."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Situation"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here, your soldier in the highest point is in point [5]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d16]."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], less than [6], you bear off from [5]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d15]."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You land exactly at [0] from [5]. You can bear off."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d14]."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because your soldier in the highest point is in [5], more that [4], you have to move from the higher point, from [5] to [1]."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you Roll [d13][d12][d11]."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose to bear off a soldier, or make a valid move inside the board."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's do This. Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have soldiers on [6], but being [5] the highest position you have, you can bear off one soldier. The [d14], you will have to move."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have 3 soldiers in position 1. You can bear them off, but you can also move some soldiers from behind."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Again."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your soldiers are now way before position [6]. bear them off."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have all your soldiers out, you have finished the game."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And this ['ends'] the Backgammon's tutorial. You can go and practice against the server, or check out more advanced tutorials in the main menu."])}
      },
      "a1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak v/s Backgammon"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn how both games are the ['same'], but way ['different']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with this tutorial instructions to learn how Abak improves its father."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the classic backgammon board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[!15] equals soldiers for each team"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abak Evolution"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Abak'] arranges its first position a little differently. It starts with [!4] soldiers in the back."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Classes"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And, off course, adds ['classes'] of soldiers"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same Rules"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rules of Backgammon apply to Abak. But each class adds its set of additional rules."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General [s21]"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves in both directions"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["↶ [s21] ↷"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druid [s41]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmovilizes the opponent."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s41] = 🔒 "])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards [s31]"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More powerful than the rest"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s31] ≅ [s11]+[s11]"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded [s61]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves odd numbers "])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s61] ↷ [d11] [d13] [d15]"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded [s51]"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only moves even numbers "])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s51] ↷ [d12] [d14] [d16]"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having different classes of soldiers not only adds the soldier's rules to the game. It also affects deeply its ['mechanic's]. "])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Y Axis"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you have an example. Points [19] and [17] have the same classes of soldiers, but in different order. Meaning the height of a soldier in a point matters, so it adds a new Axis, [!Y]"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order of Movements"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This also means that the movement's order matters in the game, and could change the result of a turn drastically ."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture the guard!."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your regular soldier first [s11], then your druid [s41], to capture the guard [s32]."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture the guard. Try again."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Captured!."])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have captured the guard [s32]."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the order."])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would have happened if you had moved the druid [s41] first?"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilize the guard!."])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move your druid first [s41], then your regular soldier [s11], to trap the guard [s32]."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not what expected"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to trap the guard [s32] with your druid [s41]."])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guard Trapped"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  As you can see, a different outcome for the same move but made in a different order."])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about each class in the next chapters."])}
      },
      "a2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['druid']. The most feared soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['druid'] is a powerful tool that needs practice to master. It ['immobilizes] the opponent's soldiers instead of capturing them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immobilized"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its power is only present in the ['outer-board'], where he can immobilize soldiers."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captured"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inside ['homes'], the druid behave as a ['regular'] soldier. It ['captures'] soldiers and sends them to the bar."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move both [d14] and [d12] with the ['druid'], to capture the ['regular'] soldier, and immobilize the ['guard']."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't make both actions."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to capture a soldier [s12][22][d12] and immobilize the guard [s32][18][d14]. Try again."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent, You trapped and captured a soldier!"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now his guard [s32][18] can't move. But he could hit your druid with another soldier. Oh no, it’s his turn!."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid was captured."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is in the bar. Roll to get out."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from the bar, and trape its soldier with your druid on [20]."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent. Soldier Trapped."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid is still alone and vulnerable. But hey, what could go wrong?"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaos."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now your druid has a druid over.  You can't move it, but this soldier under it can't move either."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your soldier in [24] to capture your opponent druid, and save yours."])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect. Druid is Safe."])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your druid has a soldier immobilized, and is protected, building a blocking point."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not forever."])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have to leave that position and release the soldier someday. More advanced tutorials are available that address the topic."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed this chapter."])}
      },
      "a4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['guards'] strengths and weaknesses.\n"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your guards to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guard"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The guard is the strongest class. It blocks the position by itself, but has some limitations. You have two of them."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onle lonely soldier"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, you have one soldier against two enemy guards. Can you escape?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You rolled [14,15] and can't move because both guards are blocking ([19][d15] [20][d14]). You can try to move a soldier if you like, to see what happens. Click in Confirm to continue."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A wild soldier appears"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guards are not invincible. Two soldiers can hit them. Let's add a companion."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attack with both"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To capture a guard, you must land over it, in two consecutive movements, with two soldiers. Let's do this."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, try to hit the guard with both soldiers."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes!. Confirm you are done."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent! That is one way to do it."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because, of course, a guard can hit another guard."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So you don't have to just blindly believe me."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hit them both!."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic. You are done now."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have learned how guards work. "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the expected movement. Try again."])}
      },
      "a3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to use the ['General']. The most resourceful soldier."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag along with the instructions in this chapter, and learn how you use your ['General'] to gain an advantage."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['General'] is resourceful. It can go ['backward'] and ['forward']."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[#1] In a turn, it can go only in one direction.<br/>[#2] Only one move backward.<br>[#3] It can't go back if all other soldiers are at home.'"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only once Backwards."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the General goes back, it can no longer move in the turn."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. You can see how it can move to 4 different points. Choose one ['backwards'] (highest points)"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good. Click again. You will see that it can't move anymore. Move the other checker."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward many, but not back."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the General goes forward, it can move every dice number, but can't go back."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not expected."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow the instructions in the messages to complete this tutorial."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21]. Now choose a point forward."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. It can't go back. But it can continue forward. Move."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not the only one away from home."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if all the other soldiers is in its team are home."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The General can't move back if every other soldier is in its team home."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your General, you can see it can go back. Make sure to move with your regular ['soldier [s11]'] to enter your team's home."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the General [s21] again. No other team soldier is outside, so it can't go back, only forward."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's it. You have completed this chapter."])}
      },
      "a5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to move the ['odd wounded'] and suffer its restrictions."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded can only move odd numbers: [d11][d13][d15]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that, it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will have ['3 rolls'] to jump across your adversary. Choose wisely."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This roll [11,13] allows one valid move, but you can choose which die. Move."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This time, you have an ['even'] number you can't move."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great, you have escaped. Roll to finish"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you are done."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned with your ['odd wounded'] to your home. Great job!."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wounded is now fully trapped, because [d11] lands in [20], [d13] in [18] and [d15] in [16], all blocked. Try again!"])}
      },
      "a6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about the ['even wounded'], predictably nightmare."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['even wounded'] lost at the back of the board, along with many enemies. Get it out of that mess, bring it home, and save it."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Even Wounded"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['even wounded'] can only move even numbers: [d12][d14][d16]."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockable"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because of that it is very easy to block, and you have to take care of it all the time."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictable"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because it always move ['even'] numbers, you can block its path from far away (as you can see now, it won't go farthest than [20])."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape from there"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play some rolls to escape from there."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this roll, dice [d15] is open but is not an ['even'] number. You can only move [d14] to [20]."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now the ['even wounded'] is fully ['blocked']. No dice roll will get you out of there. But hey, it's the opponent’s turn."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent's turn."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent Playing"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky you!"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[d16] now is open. Cross your finger."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come on!. Don't expect real life to be like this. Now, run."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have returned to your home with your ['even wounded']. Great job!."])}
      },
      "a7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded at the Bar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when entering from the ['bar']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have your ['wounded captured']. You will get ['4 rolls'] to take them out. Your opponent is not moving."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Escape"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when in the ['bar'], they can enter the board with ['any dice'] value."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Rolls"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[13,12] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #1."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click on your wounded in the Bar [B0], you can see both can land in both positions [22][d13] [23][d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Rolls"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [15,14] ↦ [16,16] ↦ [15,15]"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #2."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Rolls"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [16,16] ↦ [15,15]"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #3."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Roll"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[u3,u2] ↦ [u5,u4] ↦ [u6,u6] ↦ [15,15]"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll #4."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were expected to be home with both wounded. Try again!."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You returned home with both wounded. Good Job!."])}
      },
      "a8": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bearing Off"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Wounded'] classes can move any number when ['bearing off']."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['Bear off'] your ['wounded'] in this exercise, and practice how they behave in this situation."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wounded Bear Off"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ['wounded'] have restricted movements, but when they bear off, they can do it with ['any dice'] value, as a regular soldier."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd Wounded"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this scenario, ['odd wounded [s61]'] is on point [4]. Can bear off (if on top) with [d16][d15][d14]. Can move [d11] and [d13]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even Wounded"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['even Wounded [s51]'] is on point [4]. Can ['bear off'] with [d16][d15][d14]. Can move [d12]."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the ['even wounded']"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can bear off the ['even wounded']. The ['odd wounded'] can't move."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["['odd wounded'] can't move [d12], but can move [d13]."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now it can bear off with [d12]"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good!. Confirm you are done."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you know how to bear off your ['wounded']. Find more tutorials in the ['medium'] level section."])}
      },
      "g102": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your guards the right way"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your guards are amazing tools, but you might go through an entire game without using their powers."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take this tour about Guards, and solve a few puzzles."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Guards are almost blocks."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They make a difference when alone in the base of the board."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Block"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When facing a single adversary, they make an absolute block, if that adversary is not a guard."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] is fully blocked."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Block"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Against many enemies, they could be hit, but with meagerchances. They make a great temporary structure."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s12] hits with [d25][d25] and [d26][d26]"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are no match for the Druid"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Druid can trap a guard as with any othe soldier."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[s42] is blocked on [6] but can trap in [7]"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you stack them, you might underuse them"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep them atop a block if you want to use them to attack later"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid stacking soldiers on them if possible to maximize your chances and use your full army capabilities"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But when they are at home. Keep it at ground level, so you won’t leave blots when bearing off."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your worst-case scenario."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block the Odd Wounded."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the right move to fully block the wounded"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Odd wounded [s61] is not fully blocked, try again."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have made a full block to retain the Odd Wounded."])}
      },
      "bst": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Moves"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backgammon has been cracked. There is concens about the best moves for the opening roles."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the wisest decisions on opening moves, to finish this tutorial."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanne is a move of two different soldiers, from two different points, to the same destination."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is maybe the best possible opening move. Play your best."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to make a block on point [7]."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have created an vital block."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Golden Point."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a very important position."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in the Golden Point of your home. An importan position to guard."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another important point."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A debatable point, but there is some consensus about it"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make a block in a fairly good position."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made a block in an important position of your home."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape moves"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are other not-so-awesome moves at the start. You won't produce a block with them"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon more opnings!."])}
      },
      "bp": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a Prime and block the enemy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Prime is an structure of six consecutive blocks. Trap your enemy with one"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the dice and create a prime to trap your opponent inside your home."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold it Back"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An important tactical objective of backgammon is to hold your opponent back, trapping it in your home."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a fort"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent soldier is trapped back, but you have some blots to cover. If your opponent rolls [d24] or [d22], he will hit and may run. "])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a move that will make six consecutive blocks and prevent his escape"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent can escape."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left a blot on your home and your opponent can escape. Try again and make a full block."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime. A structure of 6 blocks that fully traps soldiers. Very Good!."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full House."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also build a prime fully in your home, and have captured soldiers that can't get off."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture your opponent, and close your home."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't fully close your home."])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were supposed to have built 6 blocks inside your home and fully trap a captured enemy. Try Again."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have built a prime inside your home. Very Good!."])}
      }
    },
    "common": {
      "whitehome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Home"])},
      "blackhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black Home"])},
      "outerboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outer Board"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outside"])},
      "movesleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must make all valid moves."])},
      "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll the Dice"])},
      "gotit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You got it!"])},
      "bettermove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a better move"])},
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your moves."])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to continue."])},
      "chcompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter Completed"])}
    }
  },
  "ui": {
    "game": {
      "alerts": {
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللعبة انتهت"])},
        "notyourtrun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس دورك"])},
        "opponentchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المشير ليس لك"])},
        "nomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المشير لا يتحرك"])},
        "noavailabledice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا مزيد من النرد للعب. تراجع أو أنهي دورك."])},
        "movelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يزال لديك حركات للعب"])},
        "upperchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحريك المشير العلوي فقط من كل عمود"])},
        "betterpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن تلعب الحد الأقصى من الحركات الصالحة. هناك مسار أفضل."])},
        "ckeckersatbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك مشيرون على الشريط. أدخلهم أولاً."])},
        "guardsemicaptured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عليك استخدام هذا المشير لضرب الحارس. لا يمكنه التحرك."])},
        "cantbearoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك إخراج المشيرين حتى يكون كل فريقك في المنزل."])},
        "cantbearoffback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك إخراج هذا المشير، لديك آخرون في الخلف."])},
        "notback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المشير لا يمكنه العودة"])},
        "onlyoneback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تحرك الجنرال بالفعل، لا يمكنه العودة."])},
        "wentbacknomoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا عاد الجنرال للخلف، لا يمكنه التحرك بعد الآن."])},
        "nomovebackteamhome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن للجنرال العودة إذا كان كل المشيرين الآخرين في المنزل"])},
        "onlyodd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المشير يتحرك بأرقام فردية فقط"])},
        "onlyeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المشير يتحرك بأرقام زوجية فقط"])},
        "max5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تكديس خمسة مشيرين فقط في كل عمود"])},
        "adversaryspoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك التحرك إلى موضع إذا كان لدى خصمك 2 مشير"])},
        "nojane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك صنع تركيبة لضرب هذا الحارس"])},
        "nojanenear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن ضرب الحارس بجنديين غير حارسين، ولكن ليس لديك جنود آخرون في المتناول."])},
        "nomoremoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك المزيد من الحركات. التقط النرد."])},
        "checkertraped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المشير محاصر بواسطة الكاهن"])},
        "musfinishjane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت فوق حارس، ولكن لم تكمل ضربه. لا يمكنك إنهاء الحركة."])},
        "rolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارمِ النرد"])},
        "nomovesturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوبس. لا حركات."])},
        "endguardmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن تنهي الحركة فوق الحارس"])},
        "notyourcube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكعب ليس لك"])},
        "cubebeforeroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك مضاعفة فقط في دورك، قبل الرمي."])},
        "cubemaxvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكعب في أقصى قيمته"])},
        "nomovestoundo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا حركات للتراجع عنها"])},
        "notips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد نصائح متاحة"])},
        "youraretimetraveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك التحرك أثناء السفر عبر الزمن."])},
        "onlyatend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحليل الحركة فقط عندما تنتهي اللعبة، أو أثناء اللعب في المستويات الأساسية."])},
        "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الوظيفة معطلة لمستخدمك."])},
        "cubenotpractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكعب غير متاح في وضع التدريب"])},
        "noanalyzepractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنني التحليل في وضع التدريب بعد"])},
        "noanalysestake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك تحليل الحركات في مباراة بها رهان، حتى تنتهي اللعبة."])},
        "matchlosspoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خسارة نقاط ELO"])}
      },
      "help": {
        "regularsoldier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جندي عادي"])},
        "clicksoldiertohelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر على الجندي للحصول على المساعدة"])}
      },
      "messages": {
        "yourturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنه دورك. ارمِ النرد."])},
        "nowcanmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التحرك الآن."])},
        "serversturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دور الخادم"])},
        "waintingfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار"])},
        "torolldice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لرمي النرد"])},
        "toplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعب"])},
        "waintingforopponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار الخصم"])},
        "secconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثواني"])},
        "drawingboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم اللوحة"])},
        "nologs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد سجلات بعد"])},
        "checkercolorschanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير ألوان المشيرين"])},
        "fullscreenrequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير وضع الشاشة الكاملة"])},
        "showpointsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير رؤية النقاط"])},
        "allowinvitationsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير السماح بالدعوات"])},
        "allowvisitorsswitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير رؤية الزوار"])},
        "clicklogformore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك النقر على موضع السجل للسفر عبر الزمن إلى ذلك الدور، ورؤية المزيد من المعلومات."])},
        "requestmovestats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك طلب إحصائيات ذلك الدور لمقارنة حركتك بالشبكة العصبية."])},
        "logdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدور # ، رمي النرد، # الحركات، زمن الدور بالثواني."])},
        "analysisindicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤشرات التحليل"])},
        "analysisw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احتمالية الفوز باللعبة"])},
        "analysisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احتمالية انتهاء اللعبة بنقطة واحدة أو نقطتين أو ثلاث نقاط."])},
        "analysisequity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنصاف"])},
        "analysiswequitydescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعكس القيمة المستقبلية للنقاط في اللعبة"])},
        "analysiswequityej1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كان اللاعبان متعادلين"])},
        "analysiswequityej2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا فاز اللاعب بنقطة واحدة"])},
        "analysiswequityej3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا خسر اللاعب نقطتين"])},
        "mathwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رياضياً"])},
        "analysismovelost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قياس فقدان الإنصاف في الدور (0 إذا اخترت أفضل حركة صالحة)"])},
        "analysismovemoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحركات الصالحة للرمي"])},
        "analysisgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحليل اللعبة"])},
        "analysisgamewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيستغرق هذا بعض الوقت، يرجى الانتظار"])},
        "clickturndetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر على النقطة لرؤية تفاصيل الدور."])},
        "harmlessdisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انفصال غير ضار"])},
        "exaustedinvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استنفاد إمكانية الدعوات. يمكنك الانتظار هنا حتى يتصل مستخدم آخر أو الانسحاب واللعب ضد الكمبيوتر وانتظار دعوة."])},
        "exitnomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>مؤسف!</span> يمكنك الانتظار لدعوة أثناء اللعب ضد الخادم."])},
        "notyetanalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحتاج إلى ما لا يقل عن 20 دور لطلب تحليل اللوحة."])},
        "playersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللاعبون المتصلون"])},
        "invitationsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الدعوة"])},
        "invitationdeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم رفض الدعوة"])},
        "invitationacepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم قبول الدعوة"])}
      },
      "dialogs": {
        "askdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مضاعفة اللعبة؟"])},
        "oppentdoubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصم يعرض مضاعفة أو لا شيء"])},
        "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مضاعفة"])},
        "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض"])},
        "acept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])}
      },
      "action": {
        "nextgame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللعبة التالية"])},
        "newmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مباراة جديدة"])},
        "surrender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استسلام"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
        "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خروج"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادعاء"])},
        "dontclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تدعي"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة"])},
        "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجاهل"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضمام"])},
        "attemptdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاولة مضاعفة"])},
        "doubleaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم القبول"])},
        "doublerejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الرفض"])},
        "startroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمي النرد للبداية"])},
        "startequals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النرد متساوٍ، كرر."])},
        "startstarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدء اللعبة"])},
        "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحليل"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيقاف مؤقت"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمرار"])}
      },
      "ialevel": {
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سهل"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عادي"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقدم"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خبير"])}
      },
      "message": {
        "ifyoulose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا خسرت"])},
        "ifyouwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا فزت"])},
        "youwilllosematch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستخسر المباراة"])},
        "gameover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهت اللعبة"])},
        "matchover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهت المباراة"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادعاء المباراة"])},
        "nogameslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مباريات في السجل"])},
        "logtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الألعاب"])},
        "confirmdouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد أنك تريد مضاعفة؟"])},
        "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المباراة"])},
        "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة المباراة"])},
        "analysysnotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الأداة مكلفة للغاية لتشغيلها، ولن تكون متاحة لفترة من الوقت. لا يزال بإمكانك تحليل الحركات الفردية من سجل الأدوار."])},
        "practiceover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهى التدريب"])}
      },
      "subject": {
        "turnslog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الأدوار"])},
        "gameevolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطور اللعبة"])},
        "turns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأدوار"])},
        "board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللوحة"])},
        "accelerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسرعات"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخيارات"])},
        "p2p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنسان"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القواعد"])},
        "matchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المباراة"])},
        "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنصاف"])},
        "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفائز"])},
        "newvisitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك زائر جديد في لعبتك."])}
      },
      "tag": {
        "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسطورة"])},
        "playersmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حركة اللاعبين"])},
        "novalidmoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا حركات صالحة"])},
        "forcedmove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حركة إجبارية"])},
        "gamedoubled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللعبة مضاعفة"])},
        "tieline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط التعادل (0)"])}
      }
    },
    "preferences": {
      "acelerators": {
        "autoDoneOnLastValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنهاء الدور تلقائيًا عند آخر حركة صالحة."])},
        "autoRoll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمي النرد تلقائيًا عند دورك."])},
        "autoMoveOnOneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحرك التلقائي عندما تكون هناك حركة قانونية واحدة فقط متاحة على الجندي المحدد"])},
        "autoDoneOnNoValids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنهاء الدور تلقائيًا إذا لم ينتج عن الرمي أي حركات صالحة"])},
        "autoRollForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرض الرمي التلقائي حتى عند وجود إجراءات المكعب المحتملة."])}
      },
      "messages": {
        "showAdvices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض النصائح"])},
        "showInvalidMoves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم الحركات غير الصالحة"])},
        "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض رسائل اللعبة"])},
        "playSounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشغيل الأصوات"])},
        "allowVisitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بالزوار"])},
        "allowInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بالدعوات"])},
        "showPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض أرقام المواضع"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤية اللاعب"])},
        "allowedGameTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنواع المسموح بها في الدعوات"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفضيلات"])}
    },
    "lobby": {
      "messages": {
        "connectingtoengine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاتصال بالمحرك"])}
      },
      "welcome": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحباً!"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إنشاء مستخدم ضيف، أو إدخال بريدك الإلكتروني للحصول على واحد دائم."])},
        "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللعب كضيف"])},
        "guest_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعب كضيف، انقر هنا. سيتم ربط هذا المستخدم بهذا العميل فقط."])},
        "changelater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إضافة بريد إلكتروني لاحقاً لحفظ هذا المستخدم."])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول أو إنشاء حساب"])},
        "create_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإنشاء أو استعادة حساب موجود، أدخل عنوان بريدك الإلكتروني"])}
      },
      "auth": {
        "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الرمز المكون من 4 أرقام المرسل إلى بريدك الإلكتروني المسجل"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
        "useemailtosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف حساب بريد إلكتروني لمطالبة هذا المستخدم."])}
      },
      "action": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة"])},
        "qrlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول عبر QR"])},
        "loginguest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضيف"])},
        "loginemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])}
      },
      "confirm": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيؤدي تسجيل الخروج فقط إلى حذف ملف تعريف الارتباط لهذا الجهاز. لا يزال بإمكانك الوصول إلى هذا المستخدم إذا كان لديك حساب بريد إلكتروني صالح مسجل. هل أنت متأكد أنك تريد المتابعة؟"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم مسح هذا المستخدم بالكامل من قاعدة البيانات ولن يكون متاحاً للأبد. هل أنت متأكد أنك تريد المتابعة؟"])}
      },
      "user": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات المستخدم"])}
      },
      "message": {
        "useqr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقرأ رمز QR هذا على أي جهاز تريد تسجيل الدخول به باستخدام هذا المستخدم."])},
        "closesession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق الجلسة في هذا الجهاز"])},
        "logindifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول في جهاز مختلف"])},
        "permanentremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إزالة المستخدم بشكل دائم"])},
        "notabakertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس أباكر"])},
        "notabakertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كن <b>أباكر</b> للوصول إلى إرسال الدعوات أو إنشاء الألعاب العامة. أو يمكنك تكوين <b>أصدقاء</b> ودعوتهم للعب."])},
        "howtoabaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتصبح <b>أباكر</b>، يجب عليك الفوز بمباراة <b>5 نقاط</b> ضد الخادم في وضع <b>المتقدم</b> أو <b>الخبير</b>، أو إكمال مستويات <b>التدريب</b>."])},
        "stakeforabak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن وضع رهانات نقاط ELO فقط في ألعاب أبك."])},
        "abakerrequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فوز $$(left)$$ مباريات لفتح المستوى التالي."])}
      },
      "subject": {
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رهان"])}
      }
    },
    "message": {
      "newmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك رسائل جديدة"])},
      "retryingerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة المحاولة"])}
    }
  }
}