//import { match } from 'core-js/fn/symbol';
var abakLib = require('../../assets/js/AbakEvolutionLibrary.js');
const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

export function requestAnalysis(G,ctx,turnID) {

    if (G.type == 'practice') {
        this.showToast(this.t("ui.game.alerts.noanalyzepractice"),3000,'','',0);
        return;
    }


    const isEnded = ctx.phase == 'endedGame' || ctx.phase == 'endedMatch';
    const hasStake = G.stake>0 && G.type == 'abak';

    if (G.type == 'practice') {
        this.showToast(this.t("ui.game.alerts.noanalyzepractice"),3000,'','',0);
        return;
    }

    if (!isEnded && hasStake) {
        this.showToast(this.t("ui.game.alerts.noanalysestake"),3000,'','',0);
        return;
    }
    

    this.uIstate.analyseMove.on =true;
    this.uIstate.analyseMove.loading = true;
    let PrevMoveTurnID = turnID-1;

    for (let i = PrevMoveTurnID; i>=0; i-- ) {
        if (G.turnslog.log[i].checkers_map) {
            PrevMoveTurnID = i;
            break;
        }
    }
    
    //let checkers_map = G.turnslog.log[PrevMoveTurnID].checkers_map;
    let checkers_map = abakLib.currentCheckersMap(abakLib.initialTablaByType(G.type,G.set),G.turnslog.log,PrevMoveTurnID);
    //let result_checkers_map = G.turnslog.log[turnID].checkers_map;
    let result_checkers_map = abakLib.currentCheckersMap(abakLib.initialTablaByType(G.type,G.set),G.turnslog.log,turnID);
    let playerID = G.turnslog.log[turnID].playerID;
    let data = {
        playerID: playerID,
        diceRoll: G.turnslog.log[turnID].diceRoll,
        action:"stats",
        checkers_map: checkers_map,
        level:6,
        gameType:G.type,
        goal:G.points.goal,
        currentPoints:G.points.current,
        doubleCube:G.doubleCube
    };

    let self = this;

    fetch(WEBPROTOCOL+APIURL+":"+APIPORT+"/matches/moves-stats/", {
        method: "POST", headers: {'Content-Type': 'application/json'} ,
        body: JSON.stringify(data)
    }).then(res => {
        return res.json();
    }).then(
        function(res) {
            try {
                let moves = res.result;
                self.uIstate.analyseMove.turnID = turnID;
                self.uIstate.analyseMove.moveID = null;
                let movesObject = processMoves(moves,checkers_map,result_checkers_map,playerID);            
                self.uIstate.analyseMove.moves = movesObject.moves;            
                self.uIstate.analyseMove.stats = movesObject.stats;
                self.uIstate.analyseMove.loading = false;
            }
            catch(error) {
                console.log(error);
            }
        }
    );
    this.$gtag.event('game',{'event_category':'engagment','event_label':'move-analysis'});
}

export function requestGameAnalysis() {
    this.uIstate.analyseGame.on =true;
    this.uIstate.analyseGame.loading = true;
}

/*
export function requestGameAnalysis(G) {
    
    this.uIstate.analyseGame.on =true;

    if (this.uIstate.analyseGame.data!=null) {
        if (G.turnslog.log.length<=this.uIstate.analyseGame.data.length)
            return;
    } 
    
    this.uIstate.analyseGame.loading = true;


    let complexity = 0;
    for (let l in G.turnslog.log) {
        if (G.turnslog.log[l].diceRoll[0]==G.turnslog.log[l].diceRoll[1]) complexity+=6;
        else complexity+=1;
    }

    console.log("complexity",complexity);
    this.uIstate.analyseGame.requestComplexity = complexity;


    let data = {
        log:G.turnslog.log,
        config: {
            gameType:G.type,
            goal:G.points.goal,
            currentPoints:G.points.current,
            classes_set:G.set    
        }
    };

    let self = this;

    fetch(WEBPROTOCOL+APIURL+":"+APIPORT+"/matches/game-stats/", {
        method: "POST", headers: {'Content-Type': 'application/json'} ,
        body: JSON.stringify(data)
    }).then(res => {
        return res.json();
    }).then(
        function(res) {
            try {                
                //console.log("recieved response");
                //console.log(res);
                self.uIstate.analyseGame.data = res.result;
                self.uIstate.analyseGame.loading = false;
            }
            catch(error) {
                console.log(error);
            }
        }
    );
    this.$gtag.event('game',{'event_category':'engagment','event_label':'game-analysis'});

}
*/

/*
export function requestGameAnalysisDo() {
    
    if (this.analyseGame.data!=null) {
        if (this.turnslog<=this.analyseGame.data.length)
            return;
    } 
    if (this.analyseGame.data.length>1) return;

    this.analyseGame.loading = true;


    let complexity = 0;
    for (let l in this.iturnsLog) {
        if (this.iturnsLog[l].diceRoll[0]==this.iturnsLog[l].diceRoll[1]) complexity+=6;
        else complexity+=1;
    }

    this.analyseGame.requestComplexity = complexity;


    let data = {
        log:this.iturnsLog,
        config: {
            gameType:this.match.gameType,
            goal:this.match.goal,
            currentPoints:[0,0],
            classes_set:'111111'    
        },
        game_id:this.game.gid,
        match_id:this.match_id
    };

    let self = this;

    fetch(WEBPROTOCOL+APIURL+":"+APIPORT+"/matches/game-stats/", {
        method: "POST", headers: {'Content-Type': 'application/json'} ,
        body: JSON.stringify(data)
    }).then(res => {
        return res.json();
    }).then(
        function(res) {
            if (!res.error) {
                try {                
                    self.analyseGame.data = res.result;
                    self.analyseGame.loading = false;
                    self.limits[1] = res.length;
                }
                catch(error) {
                    console.log(error);
                }
            }
            else {
                console.log("error")
            }
        }
    ).catch( error => {
        console.log(error);
        self.analyseGame.loading = false;
    });
    this.$gtag.event('game',{'event_category':'engagment','event_label':'game-analysis'});

}
*/

export function requestGameAnalysisFlask() {
    
    /*
    if (this.analyseGame.data!=null) {
        if (this.turnslog<=this.analyseGame.data.length)
            return;
    } 
    */
    if (this.analyseGame.data.length>1) return;

    this.analyseGame.loading = true;


    let complexity = 0;
    for (let l in this.iturnsLog) {
        if (this.iturnsLog[l].diceRoll[0]==this.iturnsLog[l].diceRoll[1]) complexity+=6;
        else complexity+=1;
    }

    this.analyseGame.requestComplexity = complexity;


    let data = {
        player_side:0,
        game_type: this.match.gameType,
        game_id:this.game.gid,
        match_id:this.match_id,
        player_id:this.player.id
    };

    let self = this;

    fetch(WEBPROTOCOL+APIURL+":"+APIPORT+"/matches/game-stats/", {
        method: "POST", headers: {'Content-Type': 'application/json'} ,
        body: JSON.stringify(data)
    }).then(res => {
        return res.json();
    }).then(
        function(res) {
            try {                
                console.log("recieved response");
                console.log(res);
                if (!res.error) {
                    self.analyseGame.data = res;
                    self.limits[1] = res.length;
                }
                else {
                    self.analyseGame.error = true;
                }
                self.analyseGame.loading = false;
            }
            catch(error) {
                console.log(error);
            }
        }
    ).catch(error => { 
        console.log(error);
        self.analyseGame.loading = false; 
        self.analyseGame.error = true; 
    });
    this.$gtag.event('game',{'event_category':'engagment','event_label':'game-analysis'});

}


export function setAnalysisMove(moveID) {     
    this.uIstate.analyseMove.moveID = this.uIstate.analyseMove.moveID ==  moveID ? null : moveID;
}


export function quitAnalysis() {
    this.uIstate.analyseMove.on = false;
    this.uIstate.analyseMove.moveID = null;
}


function processMoves(movesList,checkersMap,result_checkers_map,playerID) {
    // console.log("processMoves");
    let ABKplayerID = parseInt(playerID)+1;        
    let returnValue = [];
    // console.log(movesList);
    let initTable = abakLib.convertCheckersMap(checkersMap);  
    let stats = { maxw:0 , maxe:-10, minw:1, mine:10, chosenw:0 , chosene:0 }
    for (let mindex in movesList) {   
        let tables = JSON.parse(JSON.stringify(initTable));
        let moves = movesList[mindex].m;
        //let table_map = tables[0];
        //let table_ids = tables[1];

        for (let m in moves) {      
            let pid = tables[1][moves[m][0]][moves[m][1]+1];
            let cClass = Math.abs(tables[0][moves[m][0]][moves[m][1]+1]);            
            let pos = moves[m][2];
            //response.bestMove[m].idchecker = pid;
            abakLib.moveChecker(tables[0],tables[1],pid,ABKplayerID,pos);
            moves[m].push(cClass); // Add the class to the movement to use it in render time.
        }
        let new_checkers_map = abakLib.convertTableMapstoCheckerMap(tables[0],tables[1]);

        let sameM = sameMove(new_checkers_map,result_checkers_map);

        stats.maxw = Math.max( movesList[mindex].w , stats.maxw);
        stats.minw = Math.min( movesList[mindex].w , stats.minw);
        stats.maxe = Math.max( movesList[mindex].e , stats.maxe);
        stats.mine = Math.min( movesList[mindex].e , stats.mine);

        if (sameM) {
            stats.chosenw = movesList[mindex].w;
            stats.chosene = movesList[mindex].e;
        }

        let result  = {
            moves: moves, 
            playerID: playerID,
            checkers_map: new_checkers_map, 
            e: movesList[mindex].e , 
            w: movesList[mindex].w , 
            p: movesList[mindex].p ,
            chosen: sameM
        };
        returnValue.push(result);         
    }

    return {moves: returnValue.sort(compare).reverse(), stats:stats};

}

function sameMove(m1,m2) {    
    let t1=abakLib.convertCheckersMap(m1)[0];
    let t2=abakLib.convertCheckersMap(m2)[0];
    //console.log(t1);
    for (let c=0;c<28;c++) {
        for (let a=1;a<=15;a++) {
            if (t1[c][a]!=t2[c][a]) {
                //console.log(c,a,t1[c][a],t2[c][a]);
                return false;
            }
        }
    }
    //console.log("Chosen");
    return true;
}

function compare( a, b ) {
    if ( a.e < b.e ){
      return -1;
    }
    if ( a.e > b.e ){
      return 1;
    }
    return 0;
}