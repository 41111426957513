export default {
    background: '#802e4d',
    path: '',
    board: {
      background: '#7a4056', // sandy beach color
      border: '#541d32', // deep sea color
      bar: '#541d32',
      points: [
        {
          background: "#b57535", // sea blue
          border: "#8a5724" // bright sand color
        },
        {
          background: "#916d49",
          border: "#664a2e"
        }
      ]
    },
    dice: [
      {
        background: "#c7afc5", // sea color
        border: "#30082d", // bright sand color
        dot: "#30082d", // seashell white
        dotborder: "#000" // black for contrast
      },
      {
        background: "#30082d", // bright sand color
        border: "#c7afc5", // sea color
        dot: "#c7afc5", // black for contrast
        dotborder: "#30082d" // seashell white
      },
      {
        background: "grey",
        border: "black",
        dot: "white",
        dotborder: "white",
      },
      {
        background: "red",
        dot: "yellow",
        dotborder: "green",
        border: "grey"
      }
    ],
    cube: {
      background: "#ede7e1", // bright sand color
      color: "#2f4858", // sea color
    },
    checkers: [
      {
        background: "#c7afc5", // sea color
        line: "#9c5797", // bright sand color
        object1: "#333", // sandy beach color
        object2: "#111", // sea blue
        color: "#000", // bright sand color
      },
      {
        background: "#30082d", // bright sand color
        line: "#61235c", // sea color
        object1: "#888", // sea blue
        object2: "#AAA", // sea blue
        color: "#fff" // sea color
      }
    ],
    buttons: {
      default: {
        background: '#540e31', // sea blue
        border:"#2f4858", // deep sea color
        hover:"#fcbf49", // bright sand color
        off:"#200"
      },
      light: {
        background: '#540e31', // sandy beach color
        border:"#2f4858", // deep sea color
        hover:"#fcbf49", // bright sand color
        off:"#000"
      },
      soft: {
        background: '#540e31', // sea blue
        border:"#2f4858", // deep sea color
        hover:"#fcbf49", // bright sand color
        off:"#000"
      },
      warning: {
        background:'#fcbf49', // bright sand color
        border:"#2f4858", // deep sea color
        hover:"#0081a7", // sea blue
        off:"#000"
      }
    },
    elements: {
      box1: {
        background:"#361f0a", // bright sand color
        color:"#000" // black for contrast
      },
      box2: {
        background:"#2ff4858", // deep sea color
        color:"#fff" // seashell white
        },
        scroll: {
          bar:"#2f4858", // deep sea color
          hover:"#0081a7" // sea blue
        },
        chrono: {
          total: {
            background:"#ffdd94", // sandy beach color
            color:"#000",  // black for contrast  
          },
          current: {
            background:"#2f4858", // deep sea color
            color:"#fff",  // seashell white  
          }
        }
      }
    }