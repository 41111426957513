
const resetMatch = function(G) {  
    G.points = {
        current: [0,0],
        goal: G.points.goal,
        games_record: [],        
    }
    G.matchNumber++;
}

module.exports = { resetMatch }